import { Button, FormControl, Grid, Input, makeStyles } from "@material-ui/core";
import OtpTimer from "otp-timer";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../../assets/images/doctall_logo.png";
import mark from "../../../assets/images/mark.png";
import { showNotification } from "../../../components/common-notification/CommonNotification";
import "../../../components/Landing/Landing.scss";
import styles from "../../../components/Landing/styles";
import {
  onResetPasscode, onVerifyOTP
} from "../../../services/auth-services/authServices";
import "../../Auth/auth.scss";

const useStyles = makeStyles((theme) => styles(theme));

function VerificationCode({ stepChange, VerifyStatus }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const _userInfo = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  // login_verification_code

  const { state } = useLocation();
  const location = useLocation();

  // guestState: guestState,
  // planAmount1:planAmount1,
  // planQuantity:planQuantity,
  // diseaseUUID:diseaseUUID,
  // Plantitle: Plantitle,
  // Plansubtitle: Plansubtitle,
  // questionsSummary: questionsSummary,

  console.log(location, "ahxgavhv")
  if (JSON.stringify(_userInfo.registrationPayLoad) === '{}') {
    console.log(_userInfo, "_userInfo_userInfo")
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event, _userInfo, "_savedData")
    // navigate('/checkouts', {
    //   state: {
    //     guestState: location && location.state && location.state.guestState,
    //     //
    //     planAmount1: location && location.state && location.state.planAmount1,
    //     planQuantity: location && location.state && location.state.planQuantity,
    //     diseaseUUID: location && location.state && location.state.diseaseUUID,
    //     Plantitle: location && location.state && location.state.Plantitle,
    //     Plansubtitle: location && location.state && location.state.Plansubtitle,
    //     questionsSummary: location && location.state && location.state.questionsSummary,
    //   },
    // })
    try {
      const { verificationCode } = event.target.elements;
      let _savedData = _userInfo.userInfo;
      // let verify = "loginVerify";
      // let verify1 = "RegisterVerify";
      // if(JSON.stringify(_userInfo.registrationPayLoad) === '{}'){
      //   _savedData = _userInfo.userInfo;
      //   localStorage.setItem(verify, "verify")
      // }
      // else {
      //   _savedData = _userInfo.registrationPayLoad;

      //   localStorage.setItem(verify1, "verify1")
      // }
      console.log(_savedData.mobile, "_savedData.mobile")
      let payLoad = { mobile: _savedData.mobile, otp: verificationCode.value };
      let response = await onVerifyOTP(payLoad);
      if (((response && response.status === 200) || response.status === 201)) {
        showNotification("success", "Success !", "Code verified successfully");
        // if (location && location.state && location.state.id === "ED") {
        //   // stepChange()
        //   navigate('checkouts', {
        //     state: {
        //       guestState: location && location.state && location.state.guestState,
        //       //
        //       planAmount1: location && location.state && location.state.guestState,
        //       planQuantity: location && location.state && location.state.guestState,
        //       diseaseUUID: location && location.state && location.state.guestState,
        //       Plantitle: location && location.state && location.state.guestState,
        //       Plansubtitle: location && location.state && location.state.guestState,
        //       questionsSummary: location && location.state && location.state.guestState,
        //     },
        //   })
        // } else {
          navigate("/");
        // }
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        showNotification("error", "Failed !", "" + error.response.data.message);
      }
    }
  };

  const reSendVerificationCode = async () => {
    try {
      let _savedDeta = _userInfo.registrationPayLoad;
      let resetCodePayLoad = {
        email: _savedDeta.email,
        mobile: _savedDeta.mobile,
      };
      let response = await onResetPasscode(resetCodePayLoad);
      if ((response && response.status === 200) || response.status === 201) {
        showNotification(
          "success",
          "Success",
          "Verification Code Sent Successfully"
        );
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        showNotification("error", "Failed !", "" + error.response.data);
      }
    }
  };

  return (
    <div className="login_page">
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <div className="Login_img_box">
            <img src={mark} alt="login_image" className="Login_img" />
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className="login_container">
            <div className="doctall_logo">
              <Link to="/">
                <img src={logo} alt="login_image" />
              </Link>
            </div>
            <h1 className="Login_heading">Confirm verification code</h1>
            <p className="login_para">
              Enter the code you just received in your mail.
            </p>
            <div className="form_section">
              <form onSubmit={handleSubmit}>
                <FormControl variant="standard" fullWidth>
                  <label
                    htmlFor="verificationCode"
                    className={classes.inputLabel}
                  >
                    Verification Code*
                  </label>
                  <Input
                    type="text"
                    required
                    name="verificationCode"
                    className={classes.inputField}
                    disableUnderline={true}
                    inputProps={{
                      maxLength: 6,
                      minLength: 6,
                    }}
                  />

                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.subscribeButton}
                    style={{ backgroundColor: "#C53B20" }}
                  >
                    Confirm code
                  </Button>
                </FormControl>
              </form>

              <div style={{ paddingTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <p
                      style={{
                        color: "#667085",
                        fontSize: "14px",
                        textAlign: "left",
                        marginTop: "0px",
                        fontFamily: "Visuelt Pro",
                      }}
                    >
                      If you don't receive your code after three minutes, click
                      on{" "}
                      <span
                        style={{
                          color: "#B4331A",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        onClick={reSendVerificationCode}
                      >
                        Resend code.
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={3}>
                    <div className="resendCode">
                      <p style={{ fontWeight: "bold" }}>
                        <OtpTimer
                          textColor="#B4331A"
                          seconds={30}
                          minutes={3}
                          background={"none"}
                          buttonColor={"#4D777C"}
                          text=" "
                        />
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default VerificationCode;
