import {
  Grid, IconButton, makeStyles, Typography, useMediaQuery, useTheme
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import QLogo from "../../assets/images/QLogo.svg";
import QWelcome from "../../assets/images/QWelcome.png";
import { ED_ROUTE_PATH, HL_ROUTE_PATH, PE_ROUTE_PATH } from "../../components/common/utils.js";
import EDQuestionnaire from "./EDQuestionnaire.js";
import HLQuestionnaire from "./HLQuestionnaire";
import PEQuestionnaire from "./PEQuestionnaire";
import styles from "./styles";
import "./styles.js";

const useStyles = makeStyles((theme) => styles(theme));

const intro = [
  "We are going to ask you some medical questions, kindly provide the responses",
  "So we can build a personalized medical plan specifically for you",
];

const QuestionnaireType = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { selectedDisease } = useParams();
  let navigate = useNavigate();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const [index, setIndex] = useState(0);
  const [questionSection, setQuestionSection] = useState(false);

  useEffect(() => {
    if (selectedDisease !== ED_ROUTE_PATH) {
      let id = 0;
      if (index >= intro.length) {
        setQuestionSection(true);
      } else {
        const tick = () => setIndex(index + 1);

        id = setInterval(tick, 2000);
      }
      return () => clearInterval(id);

    }

  }, [index]);

  console.log(selectedDisease, ED_ROUTE_PATH, "selectedDisease")
  return (
    <Grid container className={classes.quesContainer}>
      <Grid item className={classes.quesHeader}>
        <IconButton component={Link} to="/" style={{ padding: "0px" }}>
          <img src={QLogo} alt="Logo" />
        </IconButton>
      </Grid>
      {selectedDisease === ED_ROUTE_PATH && questionSection === false ?
        <Grid container className={classes.quesContent}>
          <EDQuestionnaire disease={"Erectile Dysfunction"} />
        </Grid>
        :
        <>
          {selectedDisease != ED_ROUTE_PATH &&
            <Grid container className={classes.quesContent}>
              {questionSection ? (
                <>
                  {selectedDisease === PE_ROUTE_PATH ? (
                    <PEQuestionnaire disease={"Premature Ejaculation"} />
                  ) : selectedDisease === HL_ROUTE_PATH ? (
                    <HLQuestionnaire disease={"Hair Loss"} />
                  ) : null}
                </>
              ) : (
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ position: "relative" }}
                >
                  <img src={QWelcome} alt="Welcome" />
                  <Typography
                    style={{
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "36px",
                      lineHeight: "44px",
                      color: "#101828",
                      letterSpacing: "-0.02em",
                      paddingTop: "32px",
                      paddingBottom: "12px",
                    }}
                  >
                    Welcome,
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "24px",
                      lineHeight: "32px",
                      color: "#101828",
                    }}
                    align={matchesXS ? "center" : undefined}
                  >
                    {intro[index % intro.length]}
                  </Typography>
                  <hr
                    style={{
                      height: "8px",
                      background: "#004451",
                      width: index === 0 ? "50%" : "100%",
                      position: "absolute",
                      bottom: "0px",
                      border: "none",
                      left: "0px",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          }
        </>
      }

    </Grid>
  );
};

export default QuestionnaireType;
