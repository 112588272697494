import axios from "axios";

export const getDoctallLivingArticles = (categoryId, pageNumber) => {
    if (categoryId && pageNumber) {
        return axios.get(`https://doctall.com/living/wp-json/wp/v2/posts?categories=${categoryId}`)
    } else {
        return axios.get(`https://doctall.com/living/wp-json/wp/v2/posts?context=view`)
    }

}

export const getDoctallPediaArticles = (categoryId, pageNumber) => {
    if (categoryId) {
        return axios.get(`https://doctall.com/pedia/wp-json/wp/v2/healthfacts?categories=${categoryId}`)
    } else {
        return axios.get(`https://doctall.com/pedia/wp-json/wp/v2/healthfacts?categories=94`)

    }
}


export const getDoctallLivingImages = async (_media) => {
    let response = await axios.get(`https://doctall.com/living/wp-json/wp/v2/media/${_media}`)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to get Images");
}

export const getDoctallPediaImages = async (_media) => {
    let response = await axios.get(`https://doctall.com/pedia/wp-json/wp/v2/media/${_media}`)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to get Images");
}




export const getCountryCodes = async (_media) => {
    let response = await axios.get(`https://ipapi.co/json/`)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to get code");
}

export const validateEmail = (emailAddress) => {
    let validEmail = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[A-Za-z]+$/.test(emailAddress)
    return validEmail;
}







