import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Input,
  Button,
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import styles from "./../styles";
import QLogo from "../../../assets/images/QLogo.svg";
import chevronRight from "../../../assets/images/chevronRight.svg";
import chevronDownQ from "../../../assets/images/chevronDownQ.svg";
import chevronUp from "../../../assets/images/chevronUp.svg";
import { useLocation } from "react-router-dom";
import Account from "./../Account";
import Shipping from "./Shipping";
import Payment from "./Payment";
import OrderConfirmed from "./../OrderConfirmed";
import DiscountCode from "./DiscountCode";
import { showNotification } from "../../../components/common-notification/CommonNotification";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EdCardheader from '../../../pages/Questionnaire/util/EdCardheader';
// import chevronRight from "../../../assets/images/chevronRight.svg";
// import chevronUp from "../../../assets/images/chevronUp.svg";
// import chevronDownQ from "../../../assets/images/chevronDownQ.svg";
// import DiscountCode from "../../../pages/Checkout/DiscountCode";
import ArrowUp from '../../../assets/images/ArrowUp.png';
import ArrowDown from '../../../assets/images/ArrowDown.png';
import InputBase from './../../Questionnaire/util/inputbutton';
import InputBase1 from './../../Questionnaire/util/inputbutton1';
import './checkout.css';
import axios from 'axios';


const useStyles = makeStyles((theme) => styles(theme));

const Checkout = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [expanded, setExpanded] = React.useState("Shipping");
  const [orderConfirm, setOrderConfirm] = useState(false);
  const [shippingCharge, setShippingCharge] = useState(0);
  const { selectedDisease } = useParams();
  const _userInfo = useSelector((state) => state.authReducer);
  const { state } = useLocation();
  const { userSelectedPlan, diseaseUUID, questionsSummary, planDetails, disease, uuidDaata, Plantitle, Plansubtitle, guestState, planAmount1, planQuantity } = state
    ? state
    : {};
  console.log(_userInfo && _userInfo.userInfo && _userInfo.userInfo, "planDetails")
  const [accountDetails, setAccountDetails] = useState(null);
  const [shippingDetails, setShippingDetails] = useState(null);
  const [totalAmt, setTotalAmt] = useState();

  const [promoCodeVisibility, setPromoCodeVisibility] = useState(false);
  const [appliedCoupanId, setAppliedCoupanId] = useState("");
  const [couponAppliedFlag, setCouponsAppliedFlag] = useState(false);
  const [walletAppliedFlag, setwalletAppliedFlag] = useState(false);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [couponAmount, setCouponAmount] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState(Number(planQuantity));
  const [treatmentPrice, setTreatmentPrice] = useState();
  const [planAmount, setPlanAmount] = useState();



  // user/wallethistory/wallet/CN-2747DC0D
  const userUuuid = _userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid;
  const [wallAmount, setWallAmount] = useState(0)
  const [wallAmount1, setWallAmount1] = useState(0)

  const WalletAmount = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}user/wallethistory/wallet/${userUuuid}`)
      .then((res) => {
        console.log(res.data.amount, "hhajdhdh")
        setWallAmount(res.data.amount)
        setWallAmount1(res.data.amount)

      })
      .catch((err) => {
        console.log(err, "hhajdhdh")

      })
  }

  useEffect(() => {
    WalletAmount()
  }, [])

  const handleClose = (value) => {
    setOrderConfirm(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {

    if (panel === "Account" && _userInfo.userInfo) {

    } else {
      setExpanded(isExpanded ? panel : false);
    }
  };


  // useEffect(() => {
  //   if (questionsSummary && questionsSummary.shippingCarges) {
  //     setShippingCharge(questionsSummary.shippingCarges);
  //   }
  // }, [questionsSummary]);

  console.log(disease, "disease")
  useEffect(() => {
    if (disease !== "Diabetes") {
      if (userSelectedPlan && userSelectedPlan.userSelectedQuantity) {
        setSelectedQuantity(userSelectedPlan.userSelectedQuantity)
        setTreatmentPrice(userSelectedPlan.price)
      }
    }
    else {
      if (planDetails) {
        setSelectedQuantity(1)
        setTreatmentPrice(planDetails.price)
      }
    }
  }, [userSelectedPlan]);

  const [duplicateWallet, setDuplicateWallet] = useState('')
  const [wallet, setWallet] = useState(0)

  const handleWalletCode = (event) => {
    setwalletAppliedFlag(false)
    setDuplicateWallet(event.target.value)
  }
  const handleWalletSubmit = () => {
    if (adStatus === false) {
      showNotification('error', "Failed !!", "Please Add Shipping Details In Order To Apply Wallet Amount !")
      throw new Error("Failed");
    }
    else if (Number(duplicateWallet) <= Number(wallAmount1) && Number(duplicateWallet) >= 1 && (couponAppliedFlag == true ? Number(duplicateWallet) < Number(discountedAmount) : Number(duplicateWallet) < Number(totalAmt))) {
      let payLoad = {
        totalamount: totalAmt,
        amount: duplicateWallet,
        user_uuid: userUuuid
      }
      axios.post(`${process.env.REACT_APP_API_BASE_URL}user/wallethistory/validateForPay/from/wallet`, payLoad, {
        headers: {
          'x-auth-token': _userInfo && _userInfo.userInfo && _userInfo.userInfo.token
        }
      })
        .then((res) => {
          setwalletAppliedFlag(true)
          setWallet(duplicateWallet)
          setWallAmount(wallAmount1 - duplicateWallet)
        })
        .catch((err) => {
          showNotification("warning", "Failed !!", "Please provide Vallid Data !")
        })

    }
    else {
      showNotification("warning", "Failed !!", "Please provide Vallid Data !")
    }

  }
  const handleGuestWalletSubmit = () => {
    showNotification("warning", "Failed !!", "To do this Operation Please Go With Login !",)
  }

  useEffect(() => {
    let totalAmount = 0;
    let _amt = Number(selectedQuantity) * Number(planAmount1)
    setPlanAmount(_amt)
    totalAmount = _amt
    setTotalAmt(totalAmount + Number(shippingCharge))
    // if (disease !== "Diabetes") {
    //   totalAmount = Number(_amt) + Number(shippingCharge);
    // }
    // else {
    //   totalAmount = Number(_amt);
    // }
    // setTotalAmt(totalAmount);
  }, [selectedQuantity, shippingCharge])


  const _onIncreaseQuantity = () => {

    let allowedQuantity = 0;
    let message = ''
    if (disease === "Hairloss") {
      allowedQuantity = 3
      message = `Duration (Months) can not be more than 3`
    } else {
      allowedQuantity = 10
      message = `Quantity can not be more than 10`
    }
    if (selectedQuantity >= allowedQuantity) {
      showNotification("error", "Failed !!", " " + message)
    } else {
      let _quantity = Number(selectedQuantity + 1);
      setSelectedQuantity(_quantity);
    }
  }


  const _onDecreaseQuantity = () => {
    if (selectedQuantity > 1) {
      let _quantity = Number(selectedQuantity - 1);
      setSelectedQuantity(_quantity);
    } else {
      console.log("no less than 1")
    }
  };

  const [adStatus, setAdstatus] = useState(false)
  console.log(expanded, "expanded")
  return (
    <>
      <Helmet>
        <title>Doctall Purchase Checkoutghjkml</title>
        <meta name="description" content="Pay for treatments, medications and tests on Doctall. Your payments are secure." />
      </Helmet>
      <Grid container className={classes.checkoutContainer}>
        <Grid item className={classes.checkoutHeader}>
          <IconButton component={Link} to="/" style={{ padding: "0px" }}>
            <img src={QLogo} alt="Logo" />
          </IconButton>
        </Grid>
        <Grid container className={classes.checkoutContent} direction="column">
          {disease !== "Diabetes" ?
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              style={{
                width: matchesXS ? "100%" : "475px",
                height: "35px",
                marginTop: "40px",
                paddingLeft: "0px",
              }}
            >
              <Typography className={classes.notexpanded}>Questions</Typography>
              <img src={chevronRight} alt="right arrow" />
              <Typography
                className={
                  expanded === "Account" ? classes.expanded : classes.notexpanded
                }
              >
                Account
              </Typography>
              <img src={chevronRight} alt="right arrow" />
              <Typography
                className={
                  expanded === "Shipping" ? classes.expanded : classes.notexpanded
                }
              >
                Shipping Address
              </Typography>
              <img src={chevronRight} alt="right arrow" />
              <Typography
                className={
                  expanded === "Payment" ? classes.expanded : classes.notexpanded
                }
              >
                Payments
              </Typography>
              <img src={chevronRight} alt="right arrow" />
            </Grid>
            :
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              style={{
                width: matchesXS ? "100%" : "475px",
                height: "35px",
                marginTop: "40px",
                paddingLeft: "0px",
              }}
            >
              <Typography
                className={
                  expanded === "Account" ? classes.expanded : classes.notexpanded
                }
              >
                Account
              </Typography>
              <img src={chevronRight} alt="right arrow" />
              <Typography
                className={
                  expanded === "Shipping" ? classes.expanded : classes.notexpanded
                }
              >
                Home Sample Collection Address
              </Typography>
              <img src={chevronRight} alt="right arrow" />
              <Typography
                className={
                  expanded === "Payment" ? classes.expanded : classes.notexpanded
                }
              >
                Payments
              </Typography>
              <img src={chevronRight} alt="right arrow" />
            </Grid>
          }

          <Grid
            item
            container
            style={{ marginTop: "25px", width: "100%", backgroundColor: "#F7FBF8" }}
            direction={matchesXS ? "column" : "row"}
            alignItems={matchesXS ? "center" : undefined}
            id="wrapper-grid"
          >
            <Grid
              className="container"
              item
              xl={6}
              lg={6}
            // className={classes.detailsContainer}
            // style={{
            //   width: matchesXS ? "350px" : "563px",
            //   alignSelf: "center",
            //   marginRight: matchesXS ? "0px" : "40px",
            // }}
            >
              {/* <Account
                expanded={expanded}
                handleChange={handleChange}
                setAccountDetails={(_details) => setAccountDetails(_details)}
                handleExpand={(event) => setExpanded(event)}
              /> */}

              <Shipping
                expanded={expanded}
                handleChange={handleChange}
                setShippingDetails={(_details) => setShippingDetails(_details)}
                handleExpand={(event) => setExpanded(event)}
                setShippingCharge={(shippingPrice) =>
                  setShippingCharge(shippingPrice)
                }
                questionsSummary={questionsSummary}
                disease={disease}
                guestState={guestState}
                setAdstatus={(item) => setAdstatus(item)}
              />

              <Payment
                expanded={expanded}
                handleChange={handleChange}
                planInfo={userSelectedPlan}
                questionsSummary={questionsSummary}
                payableAmount={
                  couponAppliedFlag === true ? discountedAmount - wallet : totalAmt - wallet
                }
                basePlan={planDetails}
                shippingDetails={shippingDetails}
                guestState={guestState}
                couponId={appliedCoupanId}
                finalQuantity={selectedQuantity}
                uuidDaata={uuidDaata}
                disease={disease}

                wallAmount1={totalAmt}
                usedamount={duplicateWallet}
              />
            </Grid>
            <Grid
              className="container"
              item
              // xs={10}
              // sm={12}
              // md={12}
              xl={6}
              lg={6}
              // className={classes.detailsContainer}
              // style={{
              //   width: matchesXS ? "350px" : "563px",
              //   alignSelf: "center",
              //   marginRight: matchesXS ? "0px" : "40px",
              // }}
              style={{ width: "80%" }}
            >
              <div style={{ backgroundColor: "#fff", float: "left" }} >
                <div item
                  // className="col-md-6 col-xl-6 col-lg-6 col-xs-12" 
                  style={{ padding: "20px 10px 20px 20px" }}>

                  <div>
                    <h3 style={{ fontSize: "20px", fontFamily: "Visuelt Pro", fontWeight: "bold", alignItem: "left", paddingBottom: "50px", color: "#002931", paddingTop: "10px" }}>Order  Summary</h3>
                  </div>

                  <Card elevation={0} xs={12} sm={12} md={12} style={{ width: "620px", marginBottom: "20px", backgroundColor: "#fff", }}>
                    <CardContent>
                      <Grid item container style={{
                        direction: "column",
                        justifyContent: "space between",
                        alignItems: "flex-start",
                        Width: "100%"
                      }}
                      >
                        <Grid item >
                          <Typography gutterBottom variant="h5"
                            style={{
                              width: "650px",
                            }} >

                            <Typography gutterBottom variant="h5" style={{
                              color: "#002931",
                              fontFamily: "Visuelt Pro",
                              fontStyle: "normal",
                              FontSize: "20px",
                              fontWeight: "400",
                              color: "#002931",
                            }}>
                              <div
                                style={{ border: "2px solid #D9EDF1", width: "90%", height: "18vh" }} >
                                <div>
                                  <div>
                                    <span style={{ width: "20%", height: "17vh", backgroundColor: "#F2F8F9", float: "left" }} />
                                  </div>

                                  <div style={{ float: "left", textAlign: "left", paddingLeft: "15px", }}>
                                    <p
                                      style={{ fontSize: "20px", fontWeight: "700", fontFamily: "Visuelt Pro", paddingTop: "10px", color: "#002931", }}
                                    >{Plantitle}</p>
                                    <p
                                      style={{ color: " #065986", fontSize: "14px", fontWeight: "700", fontFamily: "Visuelt Pro", }}
                                    >{Plansubtitle}
                                    </p>

                                    <div style={{
                                      display: "flex",
                                      alignItem: "center",
                                      justifyContent: "center",
                                      padding: "0px 15px",
                                      border: "2px solid #00839B",
                                      float: "left",
                                      borderRadius: "10px",
                                      marginTop: "5px",
                                    }}>
                                      <div
                                        style={{
                                          color: "#065986",
                                          fontWeight: "500",
                                          fontSize: "20px",
                                          fontFamily: "Visuelt Pro",
                                          paddingTop: "6px",

                                        }}>
                                        <p>{selectedQuantity}</p>
                                      </div>

                                      <div style={{
                                        cursor: "pointer",
                                        paddingTop: "0px",
                                        paddingLeft: "30px",
                                        display: "inline-grid",
                                        justifyContent: "center"
                                      }}>
                                        <img width={"70%"} src={ArrowUp} alt="up" onClick={_onIncreaseQuantity} />
                                        <img width={"70%"} src={ArrowDown} alt="down" onClick={_onDecreaseQuantity} />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ float: "right", textAlign: "right", padding: "10px" }}>
                                  <p style={{ color: "#065986", fontSize: "20px", fontFamily: "Visuelt Pro", fontWeight: "500" }}>₦{planAmount}</p>
                                  {/* <del style={{ color: "#FF5C3C", fontSize: "15px", fontFamily: "Visuelt Pro", fontWeight: "500" }}>₦6,100.00</del> */}
                                </div>

                              </div>
                            </Typography>

                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card elevation={0} style={{ width: "620px", backgroundColor: "#fff", }}>
                    <CardContent>
                      <Grid item container style={{
                        direction: "column",
                        justifyContent: "space between",
                        alignItems: "flex-start",
                        Width: "100%"
                      }}
                      >
                        <Grid item >
                          <Typography gutterBottom variant="h5"
                            style={{
                              width: "650px",
                            }} >
                            <Typography gutterBottom variant="h5" style={{
                              color: "#002931",
                              fontFamily: "Visuelt Pro",
                              fontStyle: "normal",
                              FontSize: "20px",
                              fontWeight: "400",
                              color: "#002931",
                            }}>
                              <div
                                style={{ width: "90%", textAlign: "center" }} >
                                <div>
                                  <div>
                                    <p
                                      style={{
                                        float: "left", textAlign: "center", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro", paddingLeft: "20px",
                                        fontWeight: "700"
                                      }}>
                                      Shipping
                                    </p>
                                  </div>
                                  <div>
                                    <p style={{
                                      float: "right", textAlign: "center", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro", paddingRight: "20px",
                                      fontWeight: "700"
                                    }}>
                                      ₦ {shippingCharge}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card elevation={0} style={{ width: "620px", backgroundColor: "#fff", }}>
                    <CardContent>
                      <Grid item container style={{
                        direction: "column",
                        justifyContent: "space between",
                        alignItems: "flex-start",
                        Width: "100%"
                      }}
                      >
                        <Grid item >
                          <Typography gutterBottom variant="h5"
                            style={{
                              width: "650px",
                            }} >
                            <Typography>
                              <div>
                                <p style={{
                                  fontSize: "20px", color: "#FF5C3C", fontFamily: "Visuelt Pro", paddingBottom: "10px",
                                  fontWeight: "600", paddingLeft: "15px",
                                }}>Coupon code</p>
                              </div>
                              <div>
                                <p style={{ fontSize: "18px", color: "#717782", fontFamily: "Visuelt Pro", fontWeight: "400", paddingLeft: "15px", }}>Apply Doctall discount promo code below</p>
                              </div>
                            </Typography>
                            <DiscountCode
                              totalAmount={totalAmt}
                              close={() => {
                                setPromoCodeVisibility(!promoCodeVisibility);
                              }}
                              finalAmount={(amount) => {
                                setDiscountedAmount(amount);
                                setCouponAmount(Number(totalAmt - amount));
                              }}
                              couponAppliedFlags={(event) => {
                                setCouponsAppliedFlag(event);
                              }}
                              blockCouponid={(couponId) => {
                                setAppliedCoupanId(couponId);
                              }}
                              diseaseUuid={diseaseUUID}

                              guestState={guestState}
                              adStatus={adStatus}
                            />

                            {/* <InputBase /> */}

                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>



                  <Card elevation={0} style={{ width: "620px", backgroundColor: "#fff", }}>
                    <CardContent>
                      <Grid item container style={{
                        direction: "column",
                        justifyContent: "space between",
                        alignItems: "flex-start",
                        Width: "100%"
                      }}
                      >
                        <Grid item >
                          <Typography gutterBottom variant="h5"
                            style={{
                              width: "650px",
                            }} >
                            <Typography>
                              {guestState === false &&
                              <Typography>
                                <div>
                                  <p style={{
                                    fontSize: "20px", color: "#FF5C3C", fontFamily: "Visuelt Pro", paddingBottom: "10px",
                                    fontWeight: "600", paddingLeft: "15px",
                                  }}>Cash bonus from wallet</p>
                                </div>
                                <div>
                                  <p style={{ fontSize: "18px", color: "#717782", fontFamily: "Visuelt Pro", fontWeight: "400", paddingLeft: "15px", }}>You currently have <span style={{ color: "#191716", fontWeight: "400" }}>₦{wallAmount}</span> in your wallet balance </p>
                                </div>
                              </Typography>
                              }
                              {/* <InputBase1 /> */}
                              <div >
                                {guestState === false &&
                                  <Grid item style={{ padding: "16px" }}>
                                    <Input
                                      type="number"
                                      placeholder="Amount to pay from Wallet"
                                      className={classes.checkoutInput}
                                      onChange={handleWalletCode}
                                      value={duplicateWallet}
                                      disableUnderline={true}
                                      style={{ width: "90%", height: '100%' }}
                                    />

                                    <Button
                                      variant="contained"
                                      className={classes.createAccount1}
                                      style={{ width: '25%', marginLeft: '64%', marginTop: '-12%',backgroundColor:"#00839B",color:"#fff",fontFamily:"Visuelt Pro",
                                      fontSize:"16px",border:"1px solid #00839B" }}
                                      onClick={handleWalletSubmit}
                                    >
                                      Pay from Wallet{" "}
                                    </Button>
                                  </Grid>
                                }
                                {/* {guestState === true &&
                                  <Grid item style={{ padding: "18px" }}>
                                    <Input
                                      type="number"
                                      placeholder="Amount to pay from Wallet"
                                      className={classes.checkoutInput}
                                      disableUnderline={true}
                                      style={{ width: "90%", height: '100%' }}
                                    />

                                    <Button
                                      variant="contained"
                                      className={classes.createAccount1}
                                      style={{ width: '25%', marginLeft: '64%', marginTop: '-12%',backgroundColor:"#00839B",color:"#fff" ,fontFamily:"Visuelt Pro",
                                    fontSize:"16px",border:"1px solid #00839B"}}
                                      onClick={handleGuestWalletSubmit}
                                    >
                                      Pay from Wallet{" "}
                                    </Button>
                                  </Grid>
                                } */}
                              </div>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>



                  <Typography style={{
                    width: "100%", padding: "35px 0px 45px 0px", backgroundColor: "#F2F8F9",
                    border: "1px solid #D9EDF1",
                  }}>
                    <div>
                      <p style={{
                        float: "left", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",
                        fontWeight: "600", paddingLeft: "20px", alignItems: "center",
                      }}>Total</p>
                    </div>
                    <div>
                      <p style={{
                        float: "right", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",
                        fontWeight: "600", paddingRight: "20px", alignItems: "center",
                      }}>
                        ₦ {totalAmt}
                      </p>
                    </div>
                  
                  {walletAppliedFlag === false && couponAppliedFlag === true && discountedAmount >= 0 && (
                    <>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        // className={classes.summaryDetails}
                        style={{ marginBottom: "16px", marginTop:"35px" }}
                      >
                        <Typography style={{float: "left", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingLeft: "20px", alignItems: "center",}} >
                          Discounted Amount
                        </Typography>
                        <Typography style={{float: "right", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingRight: "20px",textAlign:"left"}} >
                          ₦ {couponAmount}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        // className={classes.summaryDetails}
                      >
                        <Typography style={{float: "left", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingLeft: "20px", alignItems: "center",}} >
                          Amount to pay
                        </Typography>
                        <Typography style={{float: "right", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingRight: "20px", alignItems: "center",textAlign:"left"}} >
                          ₦ {discountedAmount}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {walletAppliedFlag === true && couponAppliedFlag === false && wallet >= 0 && (
                    <>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        // className={classes.summaryDetails}
                        style={{ marginBottom: "16px" ,marginTop:"35px" }}
                      >
                        <Typography style={{float: "left", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingLeft: "20px", alignItems: "center",}} >
                          Discounted Amount
                        </Typography>
                        <Typography style={{float: "right", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingRight: "20px", alignItems: "center",textAlign:"left"}} >
                          ₦ {wallet}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        // className={classes.summaryDetails}
                      >
                        <Typography style={{float: "left", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingLeft: "20px", alignItems: "center",}} >
                          Amount to pay
                        </Typography>
                        <Typography style={{float: "right", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingRight: "20px", alignItems: "center",textAlign:"left"}} >
                          ₦ {totalAmt - wallet}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {walletAppliedFlag === true && couponAppliedFlag === true && wallet >= 0 && discountedAmount >= 0 && (
                    <>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        // className={classes.summaryDetails}
                        style={{ marginBottom: "16px",marginTop:"35px"  }}
                      >
                        <Typography style={{float: "left", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingLeft: "20px", alignItems: "center",}} >
                          Discounted Amount
                        </Typography>
                        <Typography style={{float: "right", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingRight: "20px", textAlign:"left"}} >
                          ₦ {Number(couponAmount) + Number(wallet)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        // className={classes.summaryDetails}
                      >
                        <Typography style={{float: "left", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingLeft: "20px", alignItems: "center",}} >
                          Amount to pay
                        </Typography>
                        <Typography style={{float: "right", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",fontWeight: "600", paddingRight: "20px", textAlign:"left"}} >
                          ₦ {discountedAmount - wallet}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  </Typography>
                  {/* {walletAppliedFlag} */}
                </div>
              </div>
            </Grid>
            {/* <Grid
              item
              container
              className={classes.summaryContainer}
              direction="column"
              alignSelf="flex-start"
            >
              <Grid
                item
                container
                style={{
                  paddingLeft: matchesXS ? "16px" : "33px",
                  paddingRight: matchesXS ? "16px" : undefined,
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "20px",
                    lineHeight: "30px",
                    color: "#002931",
                    paddingBottom: "20px",
                  }}
                >
                  Order Summary
                </Typography>
                <Grid
                  item
                  container
                  direction={matchesXS ? "column" : undefined}
                  style={{
                    width: matchesXS ? "320px" : "523px",
                    height: matchesXS ? "auto" : "106px",
                    marginBottom: "50px",
                  }}
                >
                  <Grid
                    item
                    style={{
                      height: "106px",
                      width: "120px",
                      background: "#F2F8F9",
                    }}
                  >
                    {userSelectedPlan && userSelectedPlan.product_image && <img style={{
                      height: "106px",
                      width: "120px",
                      background: "#F2F8F9",
                    }} src={userSelectedPlan.product_image}></img>}
                  </Grid>
                  <Grid
                    item
                    container
                    style={{
                      width: matchesXS ? "320px" : "402px",
                      paddingTop: "22px",
                      paddingLeft: matchesXS ? "0px" : "32px",
                    }}
                  >
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      style={{ paddingBottom: "9px" }}
                    >
                      <Typography className={classes.summaryHeading}>
                        {disease !== "Diabetes" ? userSelectedPlan && userSelectedPlan.brand_name : planDetails && planDetails.plan_name }
                        <br></br>
                        {disease !== "Diabetes" ? `` : planDetails && planDetails.short_info }
                      </Typography>
                      <Typography className={classes.summaryHeading}>
                        
                        ₦ {planAmount}
                      </Typography>
                    </Grid>
                    {disease !== "Diabetes" &&
                      <Grid item container>

                        <Grid
                          item
                          container
                          direction="column"
                          style={{ width: "89px" }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Visuelt Pro",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#344054",
                              paddingBottom: "5px",
                            }}
                          >
                            {disease === "Hairloss" ? "Duration (Months)" : "Size"}
                          </Typography>

                          <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="space-evenly"
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #C6E2E7",
                              boxSizing: "border-box",
                              height: "45px",
                              width: "89px",
                            }}
                          >
                            <Typography className={classes.planInfoFormTitle}>
                              {selectedQuantity}
                            </Typography>
                            <Grid
                              item
                              container
                              direction="column"
                              style={{ width: "9px" }}
                            >
                              <IconButton
                                disableTouchRipple
                                className={classes.iconButton}
                                onClick={_onIncreaseQuantity}
                                style={{ marginBottom: "12px" }}
                              >
                                <img src={chevronUp} alt="down" />
                              </IconButton>
                              <IconButton
                                disableTouchRipple
                                className={classes.iconButton}
                                onClick={_onDecreaseQuantity}
                              >
                                <img src={chevronDownQ} alt="down" />
                              </IconButton>
                            </Grid>
                          </Grid>

                        </Grid>

                      </Grid>
                    }
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="space-between"
                  className={classes.summaryDetails}
                  style={{ marginBottom: "20px" }}
                >
                  <Typography
                    className={classes.summaryHeading}
                    style={{ fontSize: "16px", lineHeight: "24px" }}
                  >
                    Subtotal
                  </Typography>
                  <Typography
                    className={classes.summaryHeading}
                    style={{ fontSize: "16px", lineHeight: "24px", marginRight: matchesXS ? undefined : "-15px" }}
                  >
                    ₦{planAmount}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  className={classes.summaryDetails}
                  style={{
                    marginBottom: "20px",
                    height: promoCodeVisibility ? "max-content" : null,
                  }}
                >
                  <Typography
                    className={classes.summaryHeading}
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#FF5C3C",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPromoCodeVisibility(!promoCodeVisibility);
                      setCouponsAppliedFlag(false);
                    }}
                  >
                    Add discount code
                  </Typography>

                  {promoCodeVisibility && (
                    <Grid
                      style={{
                        marginTop: "3%",
                        marginBottom: "3%",
                        marginLeft: "1%",
                        width: "100%",
                        marginRight: "-30px",
                      }}
                    >
                      <DiscountCode
                        totalAmount={totalAmt}
                        close={() => {
                          setPromoCodeVisibility(!promoCodeVisibility);
                        }}
                        finalAmount={(amount) => {
                          setDiscountedAmount(amount);
                          setCouponAmount(Number(totalAmt - amount));
                        }}
                        couponAppliedFlags={(event) => {
                          setCouponsAppliedFlag(event);
                        }}
                        blockCouponid={(couponId) => {
                          setAppliedCoupanId(couponId);
                        }}
                        diseaseUuid={planDetails.diseases_uuid}
                      />
                    </Grid>
                  )}
                </Grid>
                {disease !== "Diabetes" &&
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    className={classes.summaryDetails}
                    style={{ marginBottom: "63px" }}
                  >
                    <Typography
                      className={classes.summaryHeading}
                      style={{ fontSize: "16px", lineHeight: "24px", }}
                    >
                      Shipping
                    </Typography>
                    <Typography
                      className={classes.summaryHeading}
                      style={{ fontSize: "16px", lineHeight: "24px", marginRight: matchesXS ? undefined : "-25px" }}
                    >
                      ₦ {shippingCharge}
                    </Typography>
                  </Grid>
                }
              </Grid>
              <Grid
                item
                container
                style={{
                  width: "100%",
                  background: "#F2F8F9",
                  border: "1px solid #D9EDF1",
                  boxSizing: "border-box",
                  borderRadius: "4px",
                  height: "auto",
                  paddingLeft: matchesXS ? "16px" : "33px",
                  paddingRight: matchesXS ? "16px" : undefined,
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
                alignItems="center"
              >
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  className={classes.summaryDetails}
                  style={{ marginBottom: "16px" }}
                >
                  <Typography className={classes.summaryHeading}>
                    Total
                  </Typography>
                  <Typography className={classes.summaryHeading} style={{ marginRight: matchesXS ? undefined : "-40px" }}>
                    ₦ {totalAmt}
                  </Typography>
                </Grid>

                {couponAppliedFlag === true && discountedAmount >= 0 && (
                  <>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      className={classes.summaryDetails}
                      style={{ marginBottom: "16px" }}
                    >
                      <Typography className={classes.summaryHeading}>
                        Discounted Amount
                      </Typography>
                      <Typography className={classes.summaryHeading}>
                        ₦{couponAmount}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      className={classes.summaryDetails}
                    >
                      <Typography className={classes.summaryHeading}>
                        Amount to pay
                      </Typography>
                      <Typography className={classes.summaryHeading}>
                        ₦{discountedAmount}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        <OrderConfirmed open={orderConfirm} onClose={handleClose} />
      </Grid>
    </>
  );
};

export default Checkout;
