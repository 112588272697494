import React, { useEffect, Component, useState } from "react";
import EdCardheader from '../../../pages/Questionnaire/util/EdCardheader';
import chevronRight from "../../../assets/images/chevronRight.svg";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import PasswordField from 'material-ui-password-field';
import mark from "../../../assets/images/mark.png";
import EdShipping from '../../../pages/Questionnaire/util/EdShipping';
import './EdCheckout.css';
import { getCountryCodes } from "../../../services/doctall-living/staticServices";
import actions from "../../../redux/actions";
import {
    Grid,
    IconButton,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import styles from "./../../Checkout/styles";
import { padding } from "@mui/system";
import Checkbox from '@mui/material/Checkbox';
import { useNavigate, useLocation } from "react-router-dom";
import Verification from "./../../Auth/RegistrationPages/VerificationCodethroughLogin"
import SignupLogin from "./../../../pages/Checkout/newEDcheckout/Account"

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles((theme) => styles(theme));


//   const classes = useStyles();

const EdCheckout = ({ guestState, questionsSummary, planAmount1, planQuantity, Plantitle, Plansubtitle, diseaseUUID, handleStepChange }) => {

    console.log(guestState, questionsSummary, planAmount1, planQuantity, Plantitle, Plansubtitle, diseaseUUID, "sgdgugsdauwad")
    // const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();
    // const theme = useTheme();
    const [country, setCountry] = useState("ng");
    const [phoneNumber, setPhoneNumber] = useState();
    const _userInfo = useSelector((state) => state.authReducer);
    const [uuid1, setUuid1] = React.useState('')
    const [logtype, setLogtype] = React.useState('')
    const [nphone1, setNphone1] = useState();

    const [verifyStatus, setverifyStatus] = useState(false)

const [guestStates, setguestStates] = useState(false);
    const location = useLocation();

    console.log(location,"dghsvahgshj")

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { authActions } = actions;

    useEffect(() => {
        if ((_userInfo && _userInfo.userInfo && _userInfo.userInfo.token !== null) && (_userInfo && _userInfo.userInfo && _userInfo.userInfo.verified == true) && LoginType != "facebook" && LoginType != "google") {
                 navigate("/checkouts", {
        state: {
          planDetails :location && location.state && location.state.pDetailes,
          userSelectedPlan: location && location.state && location.state.selectedPDetailes,
          planInfo: location && location.state && location.state.planDetails,
          disease: '',
          disease_name : location && location.state && location.state.disease_name,
          uuidDaata: location && location.state && location.state.uuid,
          guestState: guestStates,
          //
          planAmount1: location && location.state && location.state.planAmount,
          planQuantity: location && location.state && location.state.planQuantity,
          diseaseUUID: location && location.state && location.state.diseaseUUID,
          Plantitle: location && location.state && location.state.title,
          Plansubtitle: location && location.state && location.state.subTitle,
          questionsSummary: location && location.state && location.state.data,
        },
      });
        }
    });

const GuestCheckout = () => {
    setguestStates(true)
    navigate("/checkouts", {
        state: {
          planDetails :location && location.state && location.state.pDetailes,
          userSelectedPlan: location && location.state && location.state.selectedPDetailes,
          planInfo: location && location.state && location.state.planDetails,
          disease: '',
          uuidDaata: location && location.state && location.state.uuid,
          guestState: true,
          //
          planAmount1: location && location.state && location.state.planAmount,
          planQuantity: location && location.state && location.state.planQuantity,
          diseaseUUID: location && location.state && location.state.diseaseUUID,
          Plantitle: location && location.state && location.state.title,
          Plansubtitle: location && location.state && location.state.subTitle,
          questionsSummary: location && location.state && location.state.data,
        },
      });
}

    const [reserror, setReserror] = useState(false);


    const errorstyle = {
        border: reserror ? "1px solid #E04E47" : "1px solid #ADC2C2 ",
        outline: "none !important",
        borderRadius: "5px",
        marginBottom: "20px",
    };

    useEffect(() => {
        getCountryCode();
    }, []);
    const reSendVerificationCode = async () => {
        try {
            let _savedDeta = _userInfo && _userInfo.userInfo;
            let resetCodePayLoad = {
                email: _savedDeta.email,
                mobile: _savedDeta.mobile,
            };
            let response = await onResetPasscode(resetCodePayLoad);
            if ((response && response.status === 200) || response.status === 201) {
                showNotification(
                    "warning",
                    "Warning",
                    "Need to Verify First"
                );
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                showNotification("error", "Failed !", "" + error.response.data);
            }
        }
    };

    const stepChange = () => {
        handleStepChange(false)
    }
    let LoginType = _userInfo && _userInfo.userInfo && _userInfo.userInfo.login_type
    // useEffect(() => {
    //     if ((_userInfo && _userInfo.userInfo && _userInfo.userInfo.token !== null) && (_userInfo && _userInfo.userInfo && _userInfo.userInfo.verified == true) && LoginType != "facebook" && LoginType != "google") {
    //         // navigate("/");
    //         // handleStepChange(false)
    //         // setverifyStatus(true)
    //         setTimeout(navigate("/login_verification_code", {
    //             state: {
    //                 id: 'ED',
    //                 guestState: guestState,
    //                 planAmount1: planAmount1,
    //                 planQuantity: planQuantity,
    //                 diseaseUUID: diseaseUUID,
    //                 Plantitle: Plantitle,
    //                 Plansubtitle: Plansubtitle,
    //                 questionsSummary: questionsSummary,
    //             }
    //         }), 1500);

    //     }
    //     else if ((_userInfo && _userInfo.userInfo && _userInfo.userInfo.token !== null) && (_userInfo && _userInfo.userInfo && _userInfo.userInfo.verified == false)) {
    //         reSendVerificationCode()
    //         // setTimeout(navigate("/login_verification_code",{ state: {
    //         //     VNav : "ED",
    //         //     onFun : stepChange
    //         // } }), 1500);
    //     }
    // }, [_userInfo]);

    const getCountryCode = async () => {
        try {
            let response = await getCountryCodes();
            if (response) {
                setCountry(response.data.country_code.toLowerCase());
            }
        } catch (error) {
            console.log(error);
        }
    };
    const [num, setNum] = useState(false)
    const logoutFun = () => {
        setModalShow(false)
        dispatch(authActions.userLogout())
    }
    const mobileUpdate = (values) => {
        values.preventDefault();
        console.log(values)
        // let phnNum = values.target.elements.phoneNUmber.value;
        var payload = {
            "uuid": uuid1,
            "login_type": logtype,
            "mobile": nphone1
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}user/auth/update/facebook/google/mobile`, payload, {
            headers: { "x-auth-token": status }
        })
            .then((res) => {
                localStorage.setItem("phoneNumber", nphone1);
                navigate("/");
                showNotification('success', "Success !!", "Logged in successfully")
            })
            .catch((err) => {
                setModalShow(false)
            })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(event, phoneNumber, "phoneNumber.length")
        let { passcode } = event.target.elements;
        let payLoad = {
            username: phoneNumber,
            password: passcode.value,
            type: "loginwithcredential",
        };
        console.log(payLoad, "phoneNumber.length")
        // if(passcode.value.length >= 1){
        //   dispatch(authActions.handleLogin(payLoad));
        // }


        dispatch(authActions.handleLogin(payLoad, (res) => {
            console.log(res, "verified")
        }));
        // var cipherUsername = encrypt(payLoad.username);
        // var cipherPassword = encrypt(payLoad.password);

        // const cookieOptions = { path: '/', secure: process.env.REACT_APP_COOKIE_SECURE, domain: process.env.REACT_APP_COOKIE_DOMAIN }

        // setCookie(usernameKey, cipherUsername, cookieOptions);
        // setCookie(passcodeKey, cipherPassword, cookieOptions);

        addFireBaseAnalytics(payLoad);
    };


    return (
        <div>
            {verifyStatus === false &&
                <>
                <div>
          <Grid md={6} xs={12} sm={12} lg={12}>
            <EdCardheader />
          </Grid>
        </div>
                    <Grid
                        item
                        container
                        justifyContent="start"
                        alignItems="left"
                        style={{
                            padding: "50px 80px",
                            height: "35px",
                            // marginBottom:"20px",
                            backgroundColor: "#F7FBF8"
                        }}
                    >
                        <Typography
                            style={{
                                color: "rgba(0, 41, 49, 0.5)",
                                fontFamily: "Visuelt Pro",
                                fontSize: "16px",
                                fontWeight: "400",
                            }}
                        >Questions</Typography>
                        <img src={chevronRight} alt="right arrow" />
                        <Typography
                            style={{
                                color: "rgba(0, 41, 49, 1)",
                                fontFamily: "Visuelt Pro",
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#002931",
                            }}
                        >
                            Account
                        </Typography>
                        <img src={chevronRight} alt="right arrow" />
                        <Typography

                            style={{
                                color: "rgba(0, 41, 49, 0.5)",
                                fontFamily: "Visuelt Pro",
                                fontSize: "16px",
                                fontWeight: "400",
                            }}
                        >
                            Shipping Address
                        </Typography>
                        <img src={chevronRight} alt="right arrow" />
                        <Typography

                            style={{
                                color: "rgba(0, 41, 49, 0.5)",
                                fontFamily: "Visuelt Pro",
                                fontSize: "16px",
                                fontWeight: "400",
                            }}
                        >
                            Payments
                        </Typography>
                        <img src={chevronRight} alt="right arrow" />
                    </Grid>
                    <div id="wrapper-main" style={{ backgroundColor: "#F7FBF8", height:"100%" }}>
                        <div id="wrapper-grid" >
                            <div class="container">

                                <SignupLogin stepChange={stepChange}/>
                                {/* <Grid
                                    alignItems="center" justifyContent="center" direction="column" >
                                    <div style={{ backgroundColor: "#fff", }}>
                                        <Grid col-md-4 col-12
                                            style={{
                                                // display:"flex" ,justifyContent: "center", alignContent: "center",
                                                // width:"70%",
                                                //    padding:"10px 30px"
                                            }}>
                                            <div>
                                                <p style={{ fontSize: "20px", fontWeight: "700", color: " #002931", marginBottom: "25px" }}>Returning User</p>
                                            </div><br />
                                            <div>
                                                <form onSubmit={handleSubmit}
                                                    style={{ width: "70%" }}
                                                >
                                                    <label
                                                        htmlFor="fname"
                                                        className="label"
                                                        style={{ marginBottom: "20px" }}
                                                    >
                                                        Phone number
                                                    </label>

                                                    <PhoneInput
                                                        style={errorstyle}
                                                        required
                                                        className="inputPhone"
                                                        country={country}
                                                        value={phoneNumber}
                                                        onChange={setPhoneNumber}
                                                        name="phone"
                                                    />
                                                    <br /><br />
                                                    <div className='login_page'
                                                        style={{ width: "70%" }}
                                                    >
                                                        <Grid container spacing={2} />
                                                        <label
                                                            className="label"
                                                            style={{ marginBottom: "20px", color: "white", fontSize: "20px", fontWeight: "bold" }}
                                                        >
                                                            passcode
                                                        </label>
                                                        <PasswordField
                                                            required
                                                            name='passcode'
                                                            placeholder="Enter your passcode"
                                                            disableUnderline={true}
                                                            hintText="only  4 characters"
                                                            inputProps={{
                                                                maxLength: 4,
                                                                minLength: 4
                                                            }}
                                                            errorText="Your password is too short"
                                                            visible={false}
                                                            className="input_box"
                                                        />
                                                    </div>
                                                    <div style={{ width: "70%" }} md={6} xs={12} sm={12}>
                                                        <div style={{ float: "left", color: "#344054", fontSize: "15px", fontFamily: "Visuelt Pro", fontWeight: "500" }}>
                                                            <span>
                                                                <Checkbox {...label} style={{ width: "20px", height: "20px", }} />  Remember for 30 days
                                                            </span>
                                                        </div>
                                                        <div style={{ float: "right", color: "#B4331A", fontSize: "15px", fontFamily: "Visuelt Pro", fontWeight: "500", cursor: "pointer" }}>
                                                            <span>
                                                                Forgot passcode
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: "40px" }}>

                                                        <button

                                                            style={{
                                                                width: "70%",
                                                                border: "1px solid orangered",
                                                                backgroundColor: "orangered",
                                                                padding: "10px 40px",
                                                                borderRadius: "10px",
                                                                fontSize: "17px",
                                                                color: "#fff",
                                                                textAlign: "center",
                                                                cursor: "pointer",
                                                            }}
                                                            type="submit"
                                                        >
                                                            Sign in
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>


                                            <div>


                                                <div>
                                                    <div style={{ width: "70%", marginTop: "17px", marginRight: "90px", }}>
                                                        <p style={{ fontSize: "14px", textAlign: "center", fontWeight: "400", fontFamily: "Visuelt Pro", color: "#667085", }}>
                                                            Don’t have an account?
                                                            <span
                                                                style={{ fontSize: "14px", fontWeight: "500", fontFamily: "Visuelt Pro", color: "#B4331A", cursor: "pointer" }}
                                                            > Sign up
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid> */}
                            </div>


                            <div className="container" 
                            style={{height:"71vh"}}
                            >
                                <Grid alignItems="center">
                                    <Grid
                                        style={{
                                            // width:"80%",
                                            background: "#fff",
                                            padding:"30px 0px 100px 40px", 
                                            // marginLeft:"40px",        
                                        }}>
                                        <div>
                                            <p style={{ fontSize: "19px", fontWeight: "700", color: "#002931" }}>No account yet?</p><br /> <br />
                                            {/* <a href="/EdShipping" style={{ textDecoration: "none", color: "#ffffff" }}> */}
                                            <p className="checkout-btn"
                                                style={{
                                                    width: "70%",
                                                    backgroundColor: "#00839B",
                                                    border: "2px solid #00839B",
                                                    fontSize: "15px",
                                                    padding: "10px 60px",
                                                    borderRadius: "10px",
                                                    color: "#fff",
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                    fontFamily: "Visuelt Pro",
                                                }}
                                                onClick={GuestCheckout}
                                            >
                                                Checkout as a Guest
                                            </p>
                                            {/* </a> */}
                                            <br /><br />
                                            <p className="checkout-text" style={{ width: "70%", fontSize: "14px", fontWeight: "500", color: "#667085", textAlign: "center" }}>
                                                With guest checkout, you’ll miss out on member benefits.
                                                <br />But don’t worry you can always join later
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>


                        </div>
                    </div>
                    {/* </div> */}

                </>
            }
            {/* {verifyStatus === true &&
                <Verification
                    stepChange={stepChange}
                    VerifyStatus={verifyStatus}
                />
            } */}
        </div>
    )

};

export default EdCheckout;