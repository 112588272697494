import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core";
import { DataGrid } from '@material-ui/data-grid';
import {
    Grid,
    Button,
    Typography,
    IconButton,
    useMediaQuery,
    CardContent,
    Card,
    CardActions,
    Hidden,
    FormControl,
    Input,
    Checkbox,
} from "@material-ui/core";
import styles from "./styles";
import doctor from "../../../assets/images/doctorImages.png";
import FilterListIcon from '@material-ui/icons/FilterList';


const useStyles = makeStyles((theme) => styles(theme));

const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'date', headerName: 'DATE', width: 200 },
    { field: 'appointmenttype', headerName: 'APPOINTMENT TYPE', width:280 },
    { field: 'doctor',headerName: 'DOCTOR',width: 250},
    {field: 'status',headerName: 'STATUS',
    //   description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
    
    },
  ];
  
  const rows = [
    { id: 1, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    { id: 2, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    { id: 3, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    { id: 4, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    { id: 5, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    { id: 6, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    { id: 7, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    { id: 8, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    { id: 9, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    { id: 10, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    // { id: 11, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    // { id: 12, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
    // { id: 13, date:"13 Nov, 2022", appointmenttype:"See a Doctor", doctor:"Dr John Stephen", status:"booked" },
  
  ];
  




export default function Appointments() {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const custommatchesXL = useMediaQuery("(min-width:1500px)");
    const custommatchesMDX = useMediaQuery("(max-width:1380px)");

    return (
        <>
            {/* <DashboardHeader/> */}
            <div>
                <Grid container direction="column"
                    className={classes.AppointmetContainer}
                    alignItems={matchesXS ? "center" : undefined}
                >
                    <Grid item container >
                        <Grid md={6}>
                            <Typography className={classes.AptMainHeading}>Appointments</Typography>
                        </Grid>
                        <Grid md={6} align="right">
                            <Button className={classes.addAptButton}>
                                + New Appointment
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className={classes.upcomingAptContainer}>
                        <Typography className={classes.AptSubHeading}>Upcoming Appointments &nbsp;&nbsp; <span style={{ color: "#FF5C3C", fontSize: "14px" }}>See All</span></Typography>
                        <Grid item container>
                            <Grid md={4}>
                                <Card className={classes.AptCards}>
                                    <Grid item container style={{ padding: "20px" }}>
                                        <Grid md={8}>
                                            <Typography style={{ fontSize: "14px", color: "#5B7B83" }}> Appointment Type </Typography>
                                        </Grid>
                                        <Grid md={4} align="right">
                                            <Button className={classes.AptBookStatus}>Booked</Button>
                                        </Grid>
                                        <Typography className={classes.FindDrSpecialist}>Find a Doctor/Specialist</Typography>
                                    </Grid>
                                    <hr style={{ color: "#E4EEF0", background: "#E4EEF0", margin: "0px 20px" }} />
                                    <Grid item container style={{ padding: "20px" }}>
                                        <Grid md={8}>
                                            <Typography className={classes.dateAndTime}>
                                                13 Nov, 2022
                                            </Typography>
                                        </Grid>
                                        <Grid md={4} align="right">
                                            <Typography className={classes.dateAndTime}>
                                                06:30pm
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Grid item container style={{ background: "#F3F8FA", padding: "20px" }} >
                                        <Grid md={2}>
                                            <img src={doctor} />
                                        </Grid>
                                        <Grid md={10}>
                                            <Typography className={classes.DrName}>Dr John Stephen</Typography>
                                            <Typography className={classes.DrSpecialist}>Psychologist</Typography>

                                        </Grid>

                                    </Grid>


                                </Card>
                            </Grid>

                            <Grid md={4}>
                                <Card className={classes.AptCards}>
                                    <Grid item container style={{ padding: "20px" }}>
                                        <Grid md={8}>
                                            <Typography style={{ fontSize: "14px", color: "#5B7B83" }}> Appointment Type </Typography>
                                        </Grid>
                                        <Grid md={4} align="right">
                                            <Button className={classes.AptBookStatus}>Booked</Button>
                                        </Grid>
                                        <Typography className={classes.FindDrSpecialist}>Find a Doctor/Specialist</Typography>
                                    </Grid>
                                    <hr style={{ color: "#E4EEF0", background: "#E4EEF0", margin: "0px 20px" }} />
                                    <Grid item container style={{ padding: "20px" }}>
                                        <Grid md={8}>
                                            <Typography className={classes.dateAndTime}>
                                                13 Nov, 2022
                                            </Typography>
                                        </Grid>
                                        <Grid md={4} align="right">
                                            <Typography className={classes.dateAndTime}>
                                                06:30pm
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Grid item container style={{ background: "#F3F8FA", padding: "20px" }} >
                                        <Grid md={2}>
                                            <img src={doctor} />
                                        </Grid>
                                        <Grid md={10}>
                                            <Typography className={classes.DrName}>Dr John Stephen</Typography>
                                            <Typography className={classes.DrSpecialist}>Psychologist</Typography>

                                        </Grid>

                                    </Grid>


                                </Card>
                            </Grid>

                            <Grid md={4}>
                                <Card className={classes.AptCards}>
                                    <Grid item container style={{ padding: "20px" }}>
                                        <Grid md={8}>
                                            <Typography style={{ fontSize: "14px", color: "#5B7B83" }}> Appointment Type </Typography>
                                        </Grid>
                                        <Grid md={4} align="right">
                                            <Button className={classes.AptBookStatus}>Booked</Button>
                                        </Grid>
                                        <Typography className={classes.FindDrSpecialist}>Find a Doctor/Specialist</Typography>
                                    </Grid>
                                    <hr style={{ color: "#E4EEF0", background: "#E4EEF0", margin: "0px 20px" }} />
                                    <Grid item container style={{ padding: "20px" }}>
                                        <Grid md={8}>
                                            <Typography className={classes.dateAndTime}>
                                                13 Nov, 2022
                                            </Typography>
                                        </Grid>
                                        <Grid md={4} align="right">
                                            <Typography className={classes.dateAndTime}>
                                                06:30pm
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Grid item container style={{ background: "#F3F8FA", padding: "20px" }} >
                                        <Grid md={2}>
                                            <img src={doctor} />
                                        </Grid>
                                        <Grid md={10}>
                                            <Typography className={classes.DrName}>Dr John Stephen</Typography>
                                            <Typography className={classes.DrSpecialist}>Psychologist</Typography>

                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </div>
            <Grid 
             direction="column"
             className={classes.AppointmetContainer}
             alignItems={matchesXS ? "center" : undefined}>
                   <Grid item container>
                        <Grid md={6}>
                        <Typography className={classes.AptSubHeading}>Appointment History &nbsp;&nbsp; <span style={{ color: "#FF5C3C", fontSize: "14px",background:"#FFF0ED",padding:"6px 12px",borderRadius:"10px" }}>240 appointments</span></Typography>
                        <Typography className={classes.DrSpecialist}>Keep track of all the appointments and durations</Typography>
                        </Grid>
                        <Grid md={6} align="right" style={{display:"flex"}}>
                        <input
                            className={classes.Search}
                            size="large"
                            placeholder="Search for payments"
                            InputStyle={{ outline: "none !important" }}
                        />
                        <Button variant="outlined" startIcon={<FilterListIcon />} style={{marginLeft:"15px",width:"25%",height:"44px"}}>
                           Filters
                        </Button>
                        </Grid>
                    </Grid>
             </Grid>    
            <div style={{ height: "800px", width: '90%',  padding:"30px", }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
    
        </>
    )
}
