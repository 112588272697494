import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  IconButton,
  useMediaQuery,
  CardContent,
  Card,
  CardActions,
  Hidden,
  FormControl,
  Input,
  Checkbox,
  Tabs,
  Tab
} from "@material-ui/core";
import Footer from '../Layout/Footer/Footer';
import { Dialog } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import Header from '../Layout/Header/Header';
import { _redirect } from "../Redirect/Redirect";
import styles from "./styles";
import Treatment from "../popup/TreatmentPopup";
import Blogs from '../Landing/components/Blogs';
import ParentSlider from '../Landing/components/PartnerSlider';
import NewsletterForm from '../Landing/components/NewsletterForm';
import AppLink from "../Landing/components/AppLink";
import Popularproduct from "../Landing/components/PopularProduct";
import PopularTests from "../Landing/components/PopularTests";
import Result from "../../assets/images/Result.png";
import Right from "../../assets/images/Right.png";
import Delivery from "../../assets/images/Delivered.svg";
import { useDispatch, useSelector } from 'react-redux';
import action from '../../redux/actions'
import DiagnosticTests from './DiagnosticTests';
import ComingSoon from '../coming-soon/ComingSoon';
import ComingSoonDoctallTest from '../coming-soon/ComingSoonDoctallTest';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => styles(theme));
// const testing = require("Doctall Tests: Buy lab tests online in Lagos, Abuja, Kano, PH and others")
export default function Testing() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const custommatchesXL = useMediaQuery("(min-width:1500px)");

  const [open, setOpen] = useState(false);
  const [diagnosisTestType, setDiagnosisTestType] = useState('pathology');

  const { diagnosticTestAction } = action;
  const dispatch = useDispatch();
  const _diagnosticTest = useSelector(state => state.diagnosticsTestReducer)
//   useEffect(() => {
//     document.title =" Doctall Tests: Buy lab tests online in Lagos, Abuja, Kano, PH and others"
//     document.head.innerHTML+=`
    
   
//     <meta name='description' content='Order lab tests and packaged diagnostic panels online at discounted prices. Compare prices with trusted labs near you, request home sample collection, and receive downloadable results. Order blood tests for fertility, STIs, hormonal checks, and much more'/>
//     ` 
// },[])

const handleSubmit = async (event) => {
  event.preventDefault();
  try {
      const { userEmail } = event.target.elements
      let payLoad = {
          'email': userEmail.value,
      }
      let response = await subscribeDoctallPharmacy(payLoad)
      if (response && response.status === 200 || response.status === 201) {
          showNotification('success', "Success !!", "You have suceesfully subscribed to Newsletter")
          event.target.reset();
      }
  } catch (error) {
      if (error && error.response) {
          showNotification('error', "Failed !!", "" + error.response.data)
      }
  }

}
  useEffect(() => {
    dispatch(diagnosticTestAction.getDiagnosticTests(diagnosisTestType))
  }, [diagnosisTestType])


  const TreatmentPopupOpen = () => {
    setOpen(true);
  };

  const TreatmentPopupClose = () => {
    setOpen(false);
  };
  let comingSoonHead = 'Looking to buy tests online at the best prices?';
  let comingSoonSubHead = 'Something is coming soon for you!'
  let description = 'Compare prices, buy lab tests online from over 15 trusted diagnostic centres and shop health screening packages for diabetes, thyroid, wellness, fertility and many more.';

  return (

// <ComingSoonDoctallTest
//    head={comingSoonHead}
//    subHead={comingSoonSubHead}
//    description={description}
// />


    <div>
      <Helmet>
        <title>Doctall Tests: Buy lab tests online in Lagos, Abuja, Kano, PH and others</title>
        <meta 
        name='description' 
        content='Order lab tests and packaged diagnostic panels online at discounted prices. Compare prices with trusted labs near you, request home sample collection, and receive downloadable results. Order blood tests for fertility, STIs, hormonal checks, and much more'
        />
      </Helmet>
      <Dialog
        style={{ borderRadius: "0px" }}
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        scroll={"body"}
        onClose={TreatmentPopupClose}
       >
        <Treatment
          onClose={() => setOpen(false)}
        />
      </Dialog>
      <Header />
      <Grid container>
        <Grid
          item
          container
          direction="column"
          className={classes.contactHeader}
         >
          <Typography style={{color: "#FF5C3C",fontSize: "14px", fontWeight: "500", lineHeight: "20px", fontFamily: "Visuelt Pro", fontStyle: "normal", marginTop: "-25px"}}>COMING SOON</Typography>
          <Typography className={classes.contactTitle}>

          Looking to buy tests online at the best prices?
            {/* {matchesSM ? null : <br />}access to medical tests */}
          </Typography>
          <p style={{
            color: "#002931",
            fontWeight: "500",
            marginTop: "4%",
            width: matchesXS ? "350px" : undefined,
            justifyContent: "center",
            fontSize: "36px",
            paddingBottom: "10px",
            // lineHeight: "72px",
          }}>
           Something is coming soon for you!</p>
           <p style={{
            color: "#002931",
            fontWeight: "400",
            marginTop: "3%",
            justifyContent: "center",
            fontSize: "20px",
            lineHeight: "30px",
            width: matchesXS ? "350px": "550px",
            fontFamily: "Visuelt Pro",
            paddingBottom: "10px",
          }}>
           Compare prices, buy lab tests online from over 15 trusted diagnostic centres and shop health screening packages for diabetes, thyroid, wellness, fertility and many more.</p>
           <Typography className={classes.joinNewsLater}>Join our waiting list</Typography>
           <form onSubmit={handleSubmit}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' ,width: matchesXS ? "100%" : "38%", padding: "5px"}}>

                                <Input
                                    type="email"
                                    required
                                    name='userEmail'
                                    placeholder="Enter your email"
                                    className={classes.inputField}
                                    disableUnderline={true}
                                />

                                <button variant="contained" type='submit' className={classes.comingSoonButton}>
                                    Get started
                                </button>

                            </div>
                        </form>

                        <Typography className={classes.comingSoonSmallText}>
                            We care about your data in our  <Link to="/privacy-policy"> <span style={{ color: '#848484', cursor: 'pointer', textDecoration: 'none', }}> privacy policy</span></Link>
                        </Typography>
        </Grid>
        </Grid>
      {/* </Grid>
      <Grid item
        container
        direction="column"
        className={classes.howItWorkContainer}
        alignItems={matchesSM ? "center" : undefined}>
        <Grid
          item
          container
          justifyContent={matchesSM ? "center" : "space-between"}
         >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "700",
              paddingBottom: "8px",
            }}
          >
            How it works
          </Typography>
        </Grid >
        <hr

          style={{
            width: "100px",
            height: "2px",
            background: "#FF8D77",
            borderRadius: "60px",
            marginTop: "9px",
            marginBottom: "36px",
            border: "none",

          }}

        />
        <Grid container>
          <Grid md={4} lg={4} sm={12} xs={12}>
            <Grid>
              <Typography>
                <img src={Right} />
              </Typography>
              <Typography
                style={{
                  color: "#002931",
                  fontWeight: "500",
                  justifyContent: "center",
                  fontSize: "24px",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                Choose the right test for you
              </Typography>
              <Typography style={{ width: "350px", color: "#002931", fontSize: "16px", paddingBottom: "30px" }}>We have a wide range of tests to cover your health needs. Shop ffrom a wide range of tests and allow us get it to you.</Typography>
            </Grid>
          </Grid>
          <Grid md={4} lg={4} sm={12} xs={12}>
            <Grid>
              <Typography>
                <img src={Delivery} />
              </Typography>
              <Typography
                style={{
                  color: "#002931",
                  fontWeight: "500",
                  justifyContent: "center",
                  fontSize: "24px",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                Delivered to your door
              </Typography>
              <Typography style={{ width: "350px", color: "#002931", fontSize: "16px", paddingBottom: "30px" }}>We’ll get your test samples to your door, right in the comfort of your home.</Typography>
            </Grid>
          </Grid>
          <Grid md={4} lg={4} sm={12} xs={12} >
            <Grid>
              <Typography>
                <img src={Result} alt="images" />
              </Typography>
              <Typography
                style={{
                  color: "#002931",
                  fontWeight: "500",
                  justifyContent: "center",
                  fontSize: "24px",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                Receive results
              </Typography>
              <Typography style={{ width: "350px", color: "#002931", fontSize: "16px", paddingBottom: "30px" }}>Check out your downloable results on your Doctall mobile app. </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

     
      <Grid
        item
        container
        direction="column"
        className={classes.popularProductContainer}
        alignItems={matchesSM ? "center" : undefined}
       >
        <Grid
          item
          container
          justifyContent={matchesSM ? "center" : "space-between"}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "700",
              paddingBottom: "8px",
            }}
          >
            Some of our lab packages
          </Typography>

        </Grid >
        <hr

          style={{
            width: "100px",
            height: "2px",
            background: "#FF8D77",
            borderRadius: "60px",
            marginTop: "9px",
            marginBottom: "16px",
            border: "none",

          }}

        />
        <Hidden mdUp>
          <Grid
            item
            container
            justifyContent={matchesMD ? "center" : "space-between"}
          >
            <></>
            <Button
              variant="contained"
              className={classes.viewProductButton}
              style={{ marginRight: "0px" }}
            >
              View all products
            </Button>
          </Grid>
        </Hidden>

        <Grid
          item
          container
          justifyContent={matchesSM ? "center" : "space-between"}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "700",
              paddingBottom: "8px",
            }}
          >
            Choose your test
          </Typography>
        </Grid >
        <hr

          style={{
            width: "100px",
            height: "2px",
            background: "#FF8D77",
            borderRadius: "60px",
            marginTop: "9px",
            marginBottom: "16px",
            border: "none",

          }}

        />

        <Grid item>
          <Button
            variant="contained"
            className={classes.raditionButton}
            style={diagnosisTestType === "pathology" ? { backgroundColor: '#FF5C3C', marginRight: "20px", color: 'white' } : { marginRight: "20px" }}
            onClick={() => setDiagnosisTestType('pathology')}
          >
            PATHOLOGY
          </Button>
          <Button
            variant="contained"
            className={classes.raditionButton}
            onClick={() => setDiagnosisTestType('radiology')}
            style={diagnosisTestType === "radiology" ? { backgroundColor: '#FF5C3C', marginRight: "20px", color: 'white' } : { marginRight: "20px" }}
          >
            RADIOLOGY
          </Button>
        </Grid>



        <div style={{
          paddingTop: "4.8rem",
          marginBottom: "5.7rem", display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start', flexWrap: 'wrap'
        }}>
          <DiagnosticTests />
        </div>


        <Grid
          item
          container
          justifyContent={"center"}
          style={{ marginBottom: '5%' }}
        >

          <Button
            variant="contained"
            className={classes.viewProductButton}
            style={{ marginRight: "0px" }}
            onClick={() => _redirect('DMP-POPULAR')}
          >
            View more
          </Button>
        </Grid>

      

        <Grid item style={{ paddingTop: "4.8rem", marginBottom: "5.7rem" }}>
          <Popularproduct />
        </Grid>
        <Grid item style={{ paddingTop: "4.8rem", marginBottom: "5.7rem" }}>
          <Typography
            variant="h3"
            style={{
              paddingBottom: "2.5rem",
              textAlign: matchesSM ? "center" : undefined,
            }}
          >
            Choose your test
          </Typography>

          <PopularTests />
        </Grid>
      
        <AppLink />
      </Grid>
      
      <Grid
        item
        container
        className={classes.healthyLivingContainer}
        direction="column"
      >
        <Typography
          variant="h3"
          style={{
            paddingBottom: "2.5rem",
            textAlign: matchesSM ? "center" : undefined,
          }}
        >
          HEALTHY LIVING
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            paddingBottom: "3.6rem",
            textAlign: matchesSM ? "center" : undefined,
          }}
        >
          How we can help you manage your health
        </Typography>
        <Grid
          container
          direction={matchesSM ? "column" : "row"}
          alignItems={matchesSM ? "center" : undefined}
          justifyContent={custommatchesXL ? "center" : undefined}
          item
        >
          <Grid
            item
            style={{
              marginRight: matchesSM ? "0px" : "24px",
              marginBottom: "24px",
            }}
          >
            <Card classes={{ root: classes.wellnessCard }}>
              <CardContent>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-around"
                  alignItems="flex-start"
                >
                  <Typography variant="h2" style={{ paddingBottom: "8px" }}>
                    Worried about something?
                    <br /> Need a diagnosis?
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ paddingBottom: "35px" }}
                  >
                    Speak to one of our doctors <br /> now
                  </Typography>
                  <Button
                    variant="contained"
                    className={classes.livingButton}
                    onClick={() => _redirect("CONSUMER-FADN")}
                  >
                    SEE A DOCTOR
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            style={{
              marginRight: matchesSM ? "0px" : "24px",
              marginBottom: "24px",
            }}
          >
            <Card classes={{ root: classes.specialistCard }}>
              <CardContent>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-around"
                  alignItems="flex-start"
                >
                  <Typography
                    variant="h2"
                    style={{ paddingBottom: matchesSM ? "24px" : "8px" }}
                  >
                    Seen a doctor and know what treatment you need?
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ paddingBottom: "35px" }}
                  >
                    Get your medications <br />and enjoy 65% off
                  </Typography>
                  <Button
                    variant="contained"
                    className={classes.livingButton}
                    onClick={TreatmentPopupOpen}
                  >
                    BUY TREATMENTS
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            style={{
              marginBottom: "24px",
            }}
          >
            <Card classes={{ root: classes.diagnosisCard }}>
              <CardContent>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-around"
                  alignItems="flex-start"
                >
                  <Typography variant="h2" style={{ paddingBottom: "8px" }}>
                    Doctor told you need a test?
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ paddingBottom: "35px" }}
                  >
                    Book your follow-up tests <br /> or screenings
                  </Typography>
                  <Button
                    variant="contained"
                    className={classes.livingButton}
                    onClick={() => _redirect("DMP-LOGIN")}
                  >
                    GET TESTED
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
     
      <Grid
        item
        container
        direction="column"
        className={classes.blogContainer}
      >
        <Grid item>
          <Blogs />
        </Grid>
      </Grid>
     
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        className={classes.partnerContainer}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.partnerInnerContainer}
        >
          <Typography
            className={classes.partnerTitle}
            textAlign="center"
            style={{ paddingBottom: "42px" }}
          >
            OUR PARTNERS
          </Typography>
          <Grid item style={{ width: "100%" }}>
            <ParentSlider />
          </Grid>
        </Grid>
      </Grid>
     
      <Grid
        item
        container
        direction={matchesMD ? "column" : "row"}
        className={classes.newsLetterContainer}
        alignItems={matchesMD ? "center" : undefined}
      >
        <Grid item className={classes.newsLetterContent} md>
          <Typography variant="h1" style={{ paddingBottom: "24px" }}>
            Sign up for the latest health news direct to your inbox!
          </Typography>
          <Typography
            paragraph
            style={{ paddingBottom: "20px" }}
            className={classes.newLetterSubHeading}
          >
            The Doctall newsletter  brings the best health information that is really important to you - direct to your inbox.
          </Typography>
          <Typography paragraph className={classes.newLetterSubHeading}>
            We can also send you the latest and emerging health trends that will help keep your mind and body in good shape. So sign up today.
          </Typography>
        </Grid>
        <Grid
          item
          container
          className={classes.newsLetterForm}
          md
          justifyContent={custommatchesXL ? "center" : undefined}
        >
          <NewsletterForm />
        </Grid>
      </Grid> */}

      <Footer />
    </div>
  )
}
