import authActions from "./authActions";
import categoryActions from './categoryActions';
import treatmentPlanAction from "./treatmentPlanAction";
import blogActions from './blogActions'
import questionnaireActions from "./questionnaireActions";
import doctorsActions from './doctorsActions'
import locationsActions from "./locationsActions";
import diagnosticTestAction from "./diagnosticTestAction";
import packageActions from "./packageActions";
import influencersActions from './registrationAction';

const actions = {
    authActions,
    categoryActions,
    blogActions,
    treatmentPlanAction,
    questionnaireActions,
    doctorsActions,
    locationsActions,
    diagnosticTestAction,
    packageActions,
    influencersActions,
};

export default actions;
