import dWQoverviewLooper from "../../../assets/images/dWQoverviewLooper.svg";
import homeCareLooper from "../../../assets/images/homeCareLooper.svg";
import homeCareBG from "../../../assets/images/homeCareBG.svg";
import purchaseBg from "../../../assets/images/dWQPurchaseBG.svg";
// import premiumServices from "../../../assets/images/premiumServices.svg";
import doctallRing from "../../../assets/images/doctallRing.svg";
import TreatLooper from "../../../assets/images/BuyTPlooper.svg";
import CallAppointment from "../../../assets/images/Call.png";
import Purchase from "../../../assets/images/Purchase.png";
import looper from "../../../assets/images/Looper-5.svg";

let premiumServices =
  "https://s3.eu-west-1.amazonaws.com/doctall.storage.com/new-doctall/premiumServices.svg";

const styles = (theme) => ({
  dWQContainer: {
    width: "100%",
  },
  dWQBanner: {
    paddingTop: "9.4rem",
    paddingLeft: "9.6rem",
    height: "auto",
    paddingBottom: "5.8rem",
    backgroundPosition: "85% -32%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  dWQTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "60px",
    lineHeight: "72px",
    letterSpacing: "-0.02em",
    color: "#002931",
    [theme.breakpoints.down("xs")]: {
      fontSize: "48px",
      lineHeight: "60px",
    },
  },
  dWQDesc: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "32px",
    color: "#002931",
    width: "494px",
    [theme.breakpoints.down("xs")]: {
      width: "250px",
    },
  },
  dWQbannerButton: {
    ...theme.typography.secondaryButton,
    width: "313px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "12px 20px",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    [theme.breakpoints.down("xs")]: {
      width: "250px",
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
  dWQbannerTestimonial: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "32px",
    color: "#00839B",
  },
  dWQbannerTestiUser: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#004451",
  },
  dWQTreatmentContainer: {
    width: "100%",
    height: "auto",
    paddingTop: "8.1rem",
    paddingBottom: "25px",
    paddingLeft:"8px",
    paddingRight:"8px",
  },
  dWQTreatmentTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "38px",
    color: "#002931",
  },
  dWQTreatmentSubtitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#002931",
    margin: "0px 0px 30px",

    [theme.breakpoints.down("sm")]: {
      margin: "32px 10px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "32px 10px",
    },
  },
  dWQTreatmentCardContainer: {
    width: "100%",
    paddingLeft: "13.7rem",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
    },
  },
  dWQtreatmentCard: {
    width: "530px",
    Height: "auto",
    background: "#ffffff",
    border: "1px solid #C6E2E7",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      width: "330px",
      height: "auto",
    },
  },
  dWQplanInfoCardContent: {
    padding: "0px",
    paddingLeft: "30px",
    paddingRight: "31px",
    paddingTop: "24.5px",
    paddingBottom: "0px",
  },

  dWQplaInfoDetails: {
    width: "100%",
    height: "auto",
    background: "#FFFFFF",
    borderRadius: "4px",
    marginBottom: "28px",
    padding: "20px 24px",

    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },

  dWQCardtTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "38px",
    color: "#ffffff",
  },
  dWQCardtSubtitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "32px",
    width: "223.3px",
    paddingBottom: "18px",
  },
  dWQCardRating: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#005F71",
    paddingLeft: "8px",
  },
  dWQDetail: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
  },
  dWQtreatmentButton: {
    ...theme.typography.secondaryButton,
    fontFamily: "Visuelt Pro",
    width: "471px",
    marginBottom: "36px",
    [theme.breakpoints.down("xs")]: {
      width: "270px",
    },
  },
  customerCareContent: {
    marginLeft: "32px",
    marginRight: "76px",
    width: "673px",
    [theme.breakpoints.down("xs")]: {
      width: "270px",
      marginLeft: "16px",
      marginRight: "16px",
    },
  },
  customerCareButton: {
    ...theme.typography.secondaryButton,
    height: "48px",
  },
  customerCareTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "38px",
  },
  customerCareSubtitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "32px",
  },
  processContainer: {
    width: "100%",
    height: "auto",
    paddingLeft: "9.8rem",
    paddingTop: "6.6rem",
    paddingBottom: "8.5rem",
    background: "rgba(242, 248, 249, 0.5)",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "16px",
      paddingRight: "0px",
    },
  },
  processHeading: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "38px",
    paddingBottom: "9px",
  },
  dWQprocessCardContainer: {
    width: "100%",
    //marginRight: "9.5rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
    },
  },
  dWQprocessCard: {
    width: "384px",
    height: "auto",
    background: "inherit",
    boxShadow: "none",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  dWQprocessCardContent: {
    padding: "0px",
  },
  processcardTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#002931",
    paddingTop: "24px",
    paddingBottom: "12px",
  },
  processcardDesc: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#002931",
  },
  dWQoverviewContainer: {
    width: "100%",
    height: "auto",
    background: "#004451",
    paddingLeft: "9.7rem",
    paddingTop: "8.5rem",
    backgroundImage: `url(${dWQoverviewLooper})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left bottom",
    position: "relative",
    paddingBottom: "11.2rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2.4rem",
    },
  },
  dWQoverviewHeading: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#FFFFFF",
    paddingBottom: "56px",
  },
  dWQoverviewSubTitle1: {
    width: "377px",
    background: "rgba(0, 41, 49, 0.5)",
    borderTop: "3px solid #FF5C3C",
    cursor: "pointer",
    height: "75px",
    paddingTop: "23.5px",
    paddingLeft: "29.5px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#FFFFFF",
    marginRight: "9.5rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      width: "300px",
      marginBottom: "32px",
    },
  },
  dWQoverviewDesc1: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#FFFFFF",
    width: "665px",
    [theme.breakpoints.down("sm")]: {
      width: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  dWQoverviewSubTitle2: {
    width: "377px",
    cursor: "pointer",
    height: "75px",
    paddingTop: "23.5px",
    paddingLeft: "29.5px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#FFFFFF",
    marginRight: "9.5rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      width: "250px",
      marginBottom: "32px",
    },
  },
  dWQoverviewDesc2: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
  },
  dWqWellbeing: {
    width: "100%",
    background: "rgba(242, 248, 249, 0.5)",
    paddingTop: "92px",
    paddingLeft: "97px",

    paddingBottom: "8.4rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "1.6rem",
      textAlign: "center",
      paddingBottom: "0.4rem",
    },
  },
  dWQWellbeingTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",

    color: "#002931",
  },
  dWQWellbeingDesc: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "32px",
    width: "700px",
    paddingBottom: "36px",
    [theme.breakpoints.down("sm")]: {
      width: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  dWQOfferTitle: {
    width: "100%",
    height: "76px",
    background: "#FFFFFF",
    border: "1px solid #C6E2E7",
    boxSizing: "border-box",
    borderRadius: "8px",
    paddingRight: "24px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#002931",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",

    "&:hover": {
      color: "#002931",
      background: "#FFFFFF",
    },

    "&.MuiToggleButton-root.Mui-selected": {
      color: "#FFFFFF",
      background: "#004451",
    },
  },

  buttonGroup: {
    "&:not(:last-child)": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    },
    "&:not(:first-child)": {
      borderLeft: "1px solid #C6E2E7",
      marginLeft: "0px",
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
  },
  buttonGroupVertical: {
    width: "280px",
    "&:not(:last-child)": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
    "&:not(:first-child)": {
      borderTop: "1px solid #C6E2E7",
      marginLeft: "0px",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
  },
  planIntroContainer: {
    background: "#FFFFFF",
    borderRadius: "8px",
    height: "auto",
    maxWidth: "124.7rem",
    marginBottom: "32px",
    // paddingLeft: "6.5rem",
    paddingTop: "6rem",
    paddingBottom: "6rem",
    paddingRight: "6.45rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
    },
  },
  plainIntroInd: {
    width: "523px",
  },
  introTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#101828",
    paddingBottom: "8px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      padding:"12px",
    },
  },
  introContent: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#667085",
  },
  proceedContainer: {
    width: "100%",
    paddingTop: "7.1rem",
    paddingLeft: "9.6rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
    },
  },
  dWQhomeCare: {
    position: "relative",
    display: "flex",
    height: "391px",
    width: "45.5%",
    background: "#004451",
    borderRadius: "10px",
    backgroundImage: `url(${CallAppointment})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    paddingLeft: "5rem",
    paddingTop: "7.45rem",
    paddingRight: "10.6rem",
    [theme.breakpoints.up("xl")]: {
      width: "605px",
    },

    [theme.breakpoints.down("md")]: {
      width: "500px",
      backgroundImage: `url(${homeCareLooper})`,
    },

    [theme.breakpoints.down("sm")]: {
      width: "32.8rem",
      backgroundImage: `url(${homeCareLooper})`,
      // paddingRight: "24px",
      marginLeft: "0px",
      paddingLeft: "24px",
      height: "350px",
    },

    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  homeCareTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#FFFFFF",
    paddingBottom: "8.3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  homeCareButton: {
    ...theme.typography.secondaryButton,
    position: "absolute",
    bottom: "6.6rem",
  },
  dWQpurchase: {
    width: "45.5%",

    position: "relative",
    // top: "-12px",
    display: "flex",
    height: "391px",
    background: "#004451",
    borderRadius: "10px",
    backgroundImage: `url(${homeCareLooper}),url(${purchaseBg})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    paddingLeft: "5rem",
    paddingTop: "7.45rem",
    paddingRight: "10.6rem",
    [theme.breakpoints.up("xl")]: {
      width: "605px",
    },
    [theme.breakpoints.down("md")]: {
      width: "500px",
      backgroundImage: `url(${homeCareLooper})`,
    },
    [theme.breakpoints.down("sm")]: {
      width: "32.8rem",
      backgroundImage: `url(${homeCareLooper})`,
      paddingRight: "24px",
      paddingLeft: "24px",
      height: "350px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },

  TreatmentPlanContainer: {
    width: "605px",
    height: "586px",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
    borderRadius: "16px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "330px",
      height: "520px",
    },
  },
  TreatmentPlanCardContent: {
    padding: "0px",
  },
  AvailablePlansContainer: {
    "&:not(last-child)": {
      marginRight: "32px",
    },
  },
  TreatmentPlanTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "32px",
    /* identical to box height, or 133% */

    color: "#002931",
  },
  StartButton: {
    ...theme.typography.secondaryButton,
    width: "585px",
    position: "absolute",
    bottom: "36px",
    padding: "10px 18px",
    [theme.breakpoints.down("sm")]: {
      width: "480px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "310px",
    },
  },
  premiumServicesContainer: {
    marginLeft: "48.5px",
    maxWidth: "1150px",
    height: "386px",
    background: "rgba(242, 248, 249, 0.5)",
    border: "1px solid rgba(174, 215, 223, 0.2)",
    boxSizing: "border-box",
    borderRadius: "4px",
    backgroundImage: `url(${premiumServices}),url(${doctallRing})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      marginLeft: "0px",
    },
  },
  callbackButton: {
    ...theme.typography.secondaryButton,
    width: "181px",
  },
  specialistDoctor: {
    width: "392px",
    height: "auto",
    background: "#FFFFFF",
    border: "1px solid rgba(198, 226, 231, 0.5)",
    boxSizing: "border-box",
    borderRadius: "5px",
    paddingTop: "26px",
    paddingLeft: "25px",
    [theme.breakpoints.down("xs")]: {
      width: "288px",
      paddingLeft: "16px",
      // marginLeft:"100px",
     
    },
  },
  specialistDocButton: {
    ...theme.typography.primaryButton,
    padding: "15px 30px 14px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "15px",
  },
  blogCard: {
    width: "384px",
    minHeight: "528px",
    boxShadow: "none",
    borderRadius: "0px",

    //background: "rgba(242, 248, 249, 0.5)",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      height: "auto",
    },
  },
  cardSetter: {
    padding: "0px",
  },
  cardBody: {
    ...theme.typography.title,
    fontWeight: "400",
    color: "#002931",
    marginBottom: "32px",
    width: "100%", //384px
    lineHeight: "24px",
    wordBreak: "break-word",

    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    MozBoxOrient: "vertical;",
    MsBoxOrient: "vertical",
    BoxOrient: "vertical",
    WebkitLineClamp: "3",
    MozLineClamp: "3",
    MsLineClamp: "3",
    LineClamp: "3",
    overflow: "hidden",
  },
  blogButton: {
    ...theme.typography.primaryButton,
    padding: "12px 20px",
    width: "119px",
    height: "48px",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  treatmentFormTitle: {
    color: "#FFFFFF",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    paddingBottom: "8px",
  },
  optionSelect: {
    background: "#FFFFFF",
    border: "1px solid #C6E2E7",
    boxSizing: "border-box",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    marginLeft: "0px",
  },
  optionSelectMultiBrand: {
    background: "#FFFFFF",
    border: "1px solid #C6E2E7",
    boxSizing: "border-box",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    marginLeft: "0px",
  },
  optionSelectLabel: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
  },
  iconButton: {
    width: "9px",
    height: "4.5px",
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
  },
  navLink: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    paddingLeft: "16px",
    color: "#002931",
  },

  shareIcon: {
    width: "82.48px",
    height: "82.48px",
    background: "#00839B",
    borderRadius: "50px",
  },
  socialIcons: {
    height: "100px",
    width: "366px",
    background: "#00839B",
    borderRadius: "60px",
  },

  planInfoFormTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#002931",
  },

  TreatmentPlanIfNotSure: {
    padding: "50px 137px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px",
    },
  },
  NotSureTPHeading: {
    color: " #002931",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "38px",
  },
  SpeakwithSubHeading: {
    fontSize: "18px",
    lineHeight: "32px",
    color: "#002931",
    fontWeight: "400",
    [theme.breakpoints.down("xs")]: {
      // width: "350px",
      // textAlign: "center",
    },
  },
  SpeakwithCbtn: {
    background: "#FF5C3C",
    color: "#fff",
    width: "257px",
    fontSize: "16px",
    padding: "12px 20px",
    border: "1px solid #FF5C3C",
    borderRadius: "8px",
    cursor: "pointer",
    fontFamily: "Visuelt Pro",
  },
  PlanInfoAccordion: {
    width: "100%",
    height: "auto",
    padding: "24px",
    background: "#F2F8F9",
    borderRadius: "4px",
  },
  InfoHeading: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#002931",
  },

  // Drug popup style
  DrugPopHeading: {
    color: "#002931",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "32px",
  },

  DrugPopSubHeading: {
    color: "#002931",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  DrugPopSubHeading1: {
    color: "#002931",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "50px",
    },
  },
  DrugPopPara: {
    color: "#002931",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400px",
  },
  howToUseDrugSubHeading: {
    color: "#002931",
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: "500",
  },
  howToUseDrugPara: {
    color: "#002931",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
  },
  bookDoctor: {
    ...theme.typography.secondaryButton,
    width: "352px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "12px 20px",
    color: "#ffffff",
    marginBottom: "28px",
    [theme.breakpoints.down("xs")]: {
      width: "255px",
    },
  },
  DrugPopupFisrSection: {
    display: "block",
    backgroundImage: `url(${looper})`,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  // Treatment popup style
  cardHead: {
    background: "#D9EDF1",
    // borderRadius:"20px",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },

  cardTreat: {
    margin: "10px 0px",
    padding: "0px",
    position: "static",
    width: "100%",
    // height: "484px",
    background: "#F2F8F9",
    borderRadius: "12px",
    backgroundImage: `url(${TreatLooper})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
  },
  cardTreat1: {
    margin: "10px 0px",
    padding: "0px",
    position: "static",
    width: "100%",
    // height: "484px",
    background: "#F2F8F9",
    borderRadius: "12px",
    backgroundImage: `url(${TreatLooper})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
  },
  cardTreat2: {
    margin: "10px 0px",
    padding: "0px",
    position: "static",
    width: "100%",
    // height: "484px",
    background: "#F2F8F9",
    borderRadius: "12px",
    backgroundImage: `url(${TreatLooper})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
  },
  TreatCard: {
    width: "280px",
    // height: "484px",
    boxShadow: "none",
    borderRadius: "0px",
    // background: "rgba(242, 248, 249, 0.5)",
    [theme.breakpoints.down("xs")]: {
      // width: "300px",
      // height: "600px",
    },
  },
  TreatButton: {
    // ...theme.typography.primaryButton,
    padding: "12px 20px",
    width: "209px",
    height: "48px",
    boxSizing: "border-box",
    // boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: "500",
    background: "#FF5C3C",
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: "24px",
    margin: "10px",
  },
  TreatHeading: {
    color: "#002931",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "32px",
  },
  appointContainer: {
    paddingLeft: "92px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "60px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
  },
});

export default styles;
