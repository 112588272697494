import React, { useEffect, useState, useRef  } from "react";
import { useParams } from "react-router-dom";
import diseases from "./utils/disease";
import DiseaseWithQuestionaire from "./DIseaseWithQues/DiseaseWithQuestionaire";
import DiseaseDirectCheckout from "./DiseaseDirectCheckout/DiseaseDirectCheckout";
import Applayout from "../../components/Layout/layout/Applayout";
import { useDispatch, useSelector } from "react-redux";
import { getDiesesIds } from "./utils/diseasesIds";
import actions from "../../redux/actions";


const Disease = (props) => {
  const dispatch = useDispatch();
  const { treatmentPlanAction } = actions;

  const { selectedDisease } = useParams();
  const [disease, setDisease] = useState({});
  // const [offerSelected, setOfferSelected] = useState("Lab Test Package");
  const [offerSelected, setOfferSelected] = useState("Speciality Doctor");
  useEffect(() => {
    let selectedD = diseases.filter(
      (disease) => disease.routePath === selectedDisease
    );
    // console.log(selectedD, "selectedD")
    //Get dynamic section from backend and store in selectedD respective section
    setDisease(selectedD[0]);
    dispatch(treatmentPlanAction.getTreatmentPlans(getDiesesIds(selectedD[0] && selectedD[0].name)));
  }, [selectedDisease]);

  console.log(offerSelected,"disease")

  return (
    <>
      <Applayout>
        <div>
          {(disease && disease.name === "Erectile Dysfunction") ||
            disease && disease.name === "Premature Ejaculation" ||
            disease && disease.name === "Hair Loss" ? (
            <DiseaseWithQuestionaire disease={disease} offerSelected={offerSelected} setOfferSelected={setOfferSelected}/>
          ) : (
            <DiseaseDirectCheckout disease={disease} offerSelected={offerSelected} setOfferSelected={setOfferSelected}/>
          )}
        </div>
      </Applayout>
    </>
  );
};

export default Disease;
