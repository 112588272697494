import React, { useState } from "react";
import {
  Grid,
  useMediaQuery,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core";
import tPlanBg from "../../../assets/images/tPlanBG.svg";
import PlanMaybe from "./options/PlanMaybe";
import PlanNo from "./options/PlanNo";
import PlanYes from "./options/PlanYes";
import styles from "./styles";
const useStyles = makeStyles((theme) => styles(theme));

const TreatmentPlan = () => {
  const [option, setOption] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const handleOption = (event, newOption) => {
    setOption(newOption);
  };

  return (
    <>
      {option === null ? (
        <Grid container>
          <Grid
            item
            container
            lg={5}
            md={12}
            className={classes.tPlanBG}
            justifyContent={matchesMD ? "center" : undefined}
          >
            <img
              src={tPlanBg}
              alt="bg"
              style={{ width: matchesSM ? "300px" : undefined }}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            lg={7}
            md={12}
            className={classes.tPlanContent}
          >
            <Grid item className={classes.tPlanDesc}>
              To provide you with best treatment plan, we would like to know
              about your Diabetes, kindly answer the following question;
            </Grid>
            <Typography
              style={{
                fontSize: matchesSM ? "18px" : "30px",
                fontWeight: "500",
                lineHeight: matchesSM ? "20px" : "38px",
                color: "#002931",
              }}
            >
              Are you suffering from Diabetes?
            </Typography>
            <Grid item>
              <ToggleButtonGroup
                value={option}
                exclusive
                onChange={handleOption}
                orientation="vertical"
                className={classes.tPlanOptionGroup}
              >
                <ToggleButton
                  className={classes.tPlanOption}
                  value="yes"
                  selected={option === "yes"}
                  disableFocusRipple
                >
                  Yes, I have Diabetes
                </ToggleButton>
                <ToggleButton
                  className={classes.tPlanOption}
                  value="maybe"
                  selected={option === "maybe"}
                  disableFocusRipple
                >
                  I’m not really sure
                </ToggleButton>
                <ToggleButton
                  className={classes.tPlanOption}
                  value="no"
                  selected={option === "no"}
                  disableRipple
                >
                  No, I don’t have Diabetes
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      ) : option === "yes" ? (
        <PlanYes />
      ) : option === "maybe" ? (
        <PlanMaybe close={() => setOption(null)} />
      ) : option === "no" ? (
        <PlanNo close={() => setOption(null)} />
      ) : null}
    </>
  );
};

export default TreatmentPlan;
