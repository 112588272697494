import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import styles from "../styles";
import { Divider, makeStyles, useTheme } from "@material-ui/core";
import CallBackPopUp from "../../callbackPopUp/CallBackPopUP";
import prevQuestionIcon from "../../../assets/images/prevQuestion.svg";
import LinearProgressBar from "../../../components/ui/LinearProgress";
import EDheader from "./EdCardheader"
// import CardMedia from '@mui/material/CardMedia';
import { showNotification } from "../../../components/common-notification/CommonNotification.js";
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Grid } from '@mui/material';
// import  '../util/QuestionCard';
import Switches from './Switches';
import './EdCard.css';
import Switch from '@mui/material/Switch';
// import ED1questions  from "./Question";
import {
   IconButton
} from "@material-ui/core";

// import { useEffect, useState } from 'react';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const useStyles = makeStyles((theme) => styles(theme));

const QuestionCard1 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState("");
  const [seeDoctor, setSeeDoctor] = useState(false);
  const [callBackModel, setCallBackModel] = useState(false);
  const [step, setStep] = useState(1);
  const prevQuestion = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    if (seeDoctor) {
      window.scrollTo(0, focusPoint.current.offsetTop);
    }
  }, [seeDoctor]);

  const handleChange = (event) => {

  };


  const handleChangeAge = (event) => {
    let answer = event.target.value.toString().trim();
    if (answer === "I am under 18 years old" || answer === "I am between 70 and above years old") {
      setSeeDoctor(true)
    } else {
      handleChange(event.target.value);
      setSeeDoctor(false)
      stepUpdate(2);
    }
  };
  const Unchecked = ({ label }) => (
    <Grid className={classes.quesOption}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );
  const Checked = ({ label }) => (
    <Grid className={classes.quesChecked}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );

  const ED1questions = [
    {
      ques: "Have you been advised by your Doctor to refrain from strenuous activity, such as exercise or sex?",
      subques: [

      ]
    },
    {
      ques: "Do you get chest pain or shortness of breath walking short distances or up a flight of stairs?",
      subques: [

      ]
    },
    {
      ques: "Do you have any of the following?",
      subques: [
        {
          test: "Inflammation or infection of your penis"

        },
        {
          test: "Severe curvature of the penis"

        },
        {
          test: "The opening of the penis (the urethra) is on the underside of the penis"

        },
        {
          test: "A urethra that is abnormally tight"

        }
      ]
    },
    {
      ques: "Have you ever suffered an allergic reaction (rash, swelling etc.) after taking medication for erectile dysfunction? ",
      subques: [

      ]
    }
  ]

  return (
    <>

{/* 
      <Grid 
      style={{backgroundColor: "#E5E5E5",
     }}
      > */}
      <EDheader />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{backgroundColor:"#F7FBF8"}}
        
      >

        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          style={{
            paddingTop:"30px",
            paddingBottom:"50px", 
          }}
        >

          {step === 0 ? null : (
            <Grid item style={{ width: "114px", marginRight: "39px" }}>
              <IconButton>
                <img src={prevQuestionIcon} alt="prev" onClick={prevQuestion} />
              </IconButton>
            </Grid>
          )}

          <Grid item>
            <LinearProgressBar
              value={(step - -99) * 1}
            />
          </Grid>


        </Grid>



        {ED1questions.map((item,index) => {
            let name = `seeDoctor-${index}`;
          return <Card elevation={0} style={{ width: "700px", marginBottom: "20px", backgroundColor: "#F7FBF8", }}>
            <CardContent>
              <Grid item container style={{
                direction: "column",
                justifyContent: "space between",
                alignItems: "flex-start",
                Width: "100%"
              }}
              >
                <Grid item style={{ width: "50%", float: "left" }}  >
                  <Typography gutterBottom variant="h5" style={{
                    width: "446px",
                    color: "#002931",
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "28px",
                    // Align: "Left",
                    // VerticalAlign: "Center",
                    // ParagraphSpacing: "24px",
                  }} component="div">

                    {item.ques}

                    {item.subques.length >= 1 && item.subques.map((item1) => {
                      return <Typography gutterBottom variant="h5" style={{
                        color: "#002931",
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        
                        fontWeight: "400",
                        lineHeight: "28px",
                        // Align: "Left",
                        color: "#002931",
                        // VerticalAlign: "Center",
                        // ParagraphSpacing: "24px",
                      }}>

                        <li style={{FontSize: "20px",color:"rgba(0, 41, 49, 1)",fontWeight:"300",
                      fontFamily:"Visuelt Pro",lineHeight:"25px",
                      }}>{item1.test}</li>
                      </Typography>
                    })}
                  </Typography>
                </Grid>

              <Grid style={{ width: "50%", float: "right", paddingLeft: "250px", }}>
                <Typography >
                 <Switches />
                </Typography>
              </Grid>


                {/* <Grid style={{ width: "50%", float: "right", paddingLeft: "250px", }}>
                  <Typography   >
                  <Switch
                          classes={{
                            root: classes.root,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked
                          }}
                          onChange={handleChange}
                          name={name} id={name}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />



                    <Switches />
                  </Typography>
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        })}

            <Grid>
              <a href="/EdCard" style={{textDecoration: "none",color:"#ffffff"}}>
               <p  
              style={{
                marginTop:"50px",
                marginBottom:"70px",
                marginLeft:"600px" , 
                padding:"12px 35px", 
                borderRadius:"8px",
                backgroundColor: "#00839B",
                fontSize: "15px" ,
                color:"#fff",
                cursor:"pointer",}}
              >
              Submit
              </p> 
              </a>
               </Grid>

        
        {/* {ED1questions && ED1questions.ques1 && (
        
      <>
      
               <Card style={{width: "700px",marginBottom:"20px", }}>
                <CardContent>
                  <Grid item   container style={{direction:"column",
                        justifyContent: "space between",
                        alignItems:"flex-start" ,
                      Width:"100%"}}
                        >
                  <Grid item style = {{width:"50%",float:"left"}}  >
                <Typography gutterBottom variant="h5"style={{ 
                 
                  color: "#002931",
                  FontFamily: "Visuelt Pro",
                  FontStyle: "Medium",
                  FontSize: "20px",
                  fontWeight:"bold",
                  LineHeight: "28px",
                  Align: "Left",
                  VerticalAlign: "Center",
                  ParagraphSpacing: "24px",
              }} component="div">
               {console.log(ED1questions.ques1,"ED1questions.ques1")}
             {ED1questions.ques1}{","} 
           </Typography>
           </Grid>
          

           <Grid style = {{width:"50%", float:"right", paddingLeft:"250px",}}>
           <Typography   >
          
            <Switches />
           </Typography>
           </Grid>

           
           

           
           </Grid>
           
             
        
                </CardContent>
                
               </Card>
</>
             
            )}  */}




        {seeDoctor ? (
          <Alert className="d-none">
            <img src={AlertClose} alt="Close" style={{ paddingTop: "52.51px" }} />
            <Typography
              // ref={focusPoint}
              style={{
                paddingTop: "49px",
                paddingBottom: "8px",
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "24px",
                lineHeight: "32px",
                color: "#002931",
              }}
            >
              Not suitable for treatment
            </Typography>
            <Typography
              align="center"
              style={{
                width: matchesSM ? "250px" : "421px",
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "28px",
                color: "#002931",
                paddingBottom: "47px",
              }}
              ref={focusPoint}

            >
              Based on your answer, you are not suitable for Rx. Change your answer
              or kindly click on the button and talk to a doctor.
            </Typography>
            <Button className={classes.talkToADoctorButton} onClick={() => setCallBackModel(!callBackModel)}>
              TALK TO A DOCTOR
            </Button>
          </Alert>
        ) : null}

        <CallBackPopUp
          open={callBackModel}
          close={() => setCallBackModel(!callBackModel)}
        />

      </Grid>

      {/* </Grid> */}

    </>

  );
}

export default QuestionCard1;