import React, { useState } from "react";
import {
  Grid,
  IconButton,
  useMediaQuery,
  Typography,
  Button,
  Input,
  FormControl,
  Dialog,
} from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core";
import closeIcon from "../../../../assets/images/close.svg";
import tplannoBG from "../../../../assets/images/tPlannoBG.svg";
import listmarker from "../../../../assets/images/listmarker.svg";
import samplecell from "../../../../assets/images/samplecell.svg";
import consultationDoc from "../../../../assets/images/consultationDoc.svg";
import planEclipse from "../../../../assets/images/planEclipse.svg";
import joinPopup from "../../../../assets/images/joinPopup.svg";
import { subscribeToNews } from "../../../../services/subscriptions/subscriptionServices";
import { showNotification } from "../../../../components/common-notification/CommonNotification"

import styles from "../styles";
const useStyles = makeStyles((theme) => styles(theme));

const PlanNo = ({ close }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [isDailogOpen, setIsDailogOpen] = useState(false);
  const DailogOpen = () => {
    setIsDailogOpen(true);
  };

  const DailogClose = () => {
    setIsDailogOpen(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { userName, userEmail } = event.target.elements;
      let payLoad = {
        name: userName.value,
        email: userEmail.value,
        type: "doctallweb",
      };
      console.warn(payLoad);
      let response = await subscribeToNews(payLoad);
      if ((response && response.status === 200) || response.status === 201) {
        // showNotification(
        //   "success",
        //   "Success !!",
        //   "You have suceesfully subscribed to newsletter"
        // );
        DailogOpen()
        event.target.reset();
      }
    } catch (error) {
      if (error && error.response) {
        showNotification("error", "Failed !!", "" + error.response.data);
      }
    }
  };
  return (
    <>
      <Dialog
        style={{ borderRadius: "0px" }}
        maxWidth={"sm"}
        fullWidth={true}
        open={isDailogOpen}
        scroll={"body"}
        onClose={DailogClose}
        className={classes.joinPopup}
      >
        <Grid container>
          <Grid item container justifyContent="flex-end">
            <IconButton
              onClick={() => close()}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                background: "#FFFFFF",
                position: "absolute",
                top: "-56px",
              }}
            >
              <img src={closeIcon} alt="close_icon" />
            </IconButton>{" "}
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.joinPopupCon}
          >
            <img src={joinPopup} alt="Join Popup" />
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "500",
                lineHeight: "32px",
                color: "#002931",
                fontFamily: "Visuelt Pro",
                marginTop: "60px",
              }}
            >
              Joined Doctall successfuly
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#667085",
                fontFamily: "Visuelt Pro",
                marginTop: "15px",
              }}
            >
              Joined Doctall successfuly
            </Typography>
            <button
              variant="contained"
              type="submit"
              className={classes.doneButton}
              onClick={() => DailogClose()}
            >
              Done
            </button>
          </Grid>
        </Grid>
      </Dialog>
      <Grid item container>
        <Grid
          item
          container
          lg={4}
          md={12}
          className={classes.tPlanBG_no}
          justifyContent={matchesMD ? "center" : undefined}
        >
          <img
            src={tplannoBG}
            alt="bg"
            style={{ width: matchesSM ? "330px" : undefined, height: "768px" }}
          />
        </Grid>
        <Grid container lg={1}></Grid>
        <Grid
          item
          container
          direction="column"
          //alignItems="center"
          lg={7}
          md={12}
          className={classes.tPlanContent_no}
          style={{ marginTop: matchesMD ? "30px" : undefined }}
        >
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{
              height: matchesXS ? "80px" : "124px",
              paddingTop: matchesXS ? "20px" : "40px",
              paddingRight: "45px",
            }}
          >
            <IconButton
              onClick={() => close()}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                background: "#F2F8F9",
              }}
            >
              <img src={closeIcon} alt="close_icon" />
            </IconButton>
          </Grid>
          <Grid
            item
            container
            direction="column"
            style={{ paddingLeft: matchesSM ? "20px" : "76px" }}
          >
            <img src={planEclipse} alt="Eclipse" style={{ width: "76px" }} />
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: "700",
                lineHeight: "38px",
                color: "#002931",
                fontFamily: "Visuelt Pro",
                marginTop: "30px",
              }}
            >
              Congrats on staying healthy!
            </Typography>
            <Grid
              item
              container
              className={classes.formContainer}
              direction="column"
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#002931",
                  fontFamily: "Visuelt Pro",
                }}
              >
                Now you can continue staying healthier with Doctall's Health
                Tips and article, if you like to receive it, share your details
              </Typography>
              <FormControl variant="standard" fullWidth>
                <form onSubmit={handleSubmit}>
                <label
                  htmlFor="name"
                  className={classes.inputLabel}
                  style={{ paddingTop: "16px" }}
                >
                  Full name*
                </label>
                <Input
                  inputProps={{
                    maxLength: 25,
                  }}
                  type="text"
                  required
                  name="userName"
                  placeholder="Enter your fullname"
                  className={classes.inputField}
                  disableUnderline={true}
                />

                <label
                  htmlFor="name"
                  className={classes.inputLabel}
                  style={{ paddingTop: "20px" }}
                >
                  Email*
                </label>
                <Input
                  name="userEmail"
                  type="email"
                  required
                  placeholder="Enter your email address"
                  className={classes.inputField}
                  disableUnderline={true}
                />

                <button
                  variant="contained"
                  type="submit"
                  className={classes.subscribeButton}
                  // onClick={() => handleSubmit()}
                >
                  Join Doctall
                </button>
                </form>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PlanNo;
