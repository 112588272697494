import actions from '../actions';

const { blogActions } = actions;

const initState = {
    loader: false,
    livingData: [],
    pediaData: [],
    limit: 10,
    skip: 0,
    count: 0,
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {

        case blogActions.GET_LIVING_BLOG:
            return {
                ...state,
                loader: true
            };
        case blogActions.GET_PEDIA_BLOG:
            return {
                ...state,
                loader: true
            };


        case blogActions.GET_LIVING_BLOG_SUCCESS:
            return {
                ...state,
                loader: false,
                livingData: action.livingData,
            };

        case blogActions.GET_PEDIA_BLOG_SUCCESS:
            return {
                ...state,
                loader: false,
                pediaData: action.pediaData,
            };

        default:
            return state;
    }
}
