import React, { useState } from 'react';
import {
  Grid,
  Hidden,
  IconButton,
  useMediaQuery,
  Button,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import user from "../../../assets/images/user.svg";
import doctall_logo from "../../../assets/images/doctall_logo.svg";
import Dropdown from "../../../assets/images/Dropdown.png";
import toggleMenu from "../../../assets/images/toggleMenu.svg";
import Downarrow from "../../../assets/images/Downarrow.png";
import Who from "../../../assets/images/Who.png";
import Avatar from "../../../assets/images/Avatar.png";
import Help from "../../../assets/images/help.png";
import "./dashboardHeader.scss";

const useStyles = makeStyles((theme) => styles(theme));

export default function DashboardHeader() {

  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const custommatchesXL = useMediaQuery("(min-width:1550px)");
  const custommatchesXXL = useMediaQuery("(min-width:1700px)");

  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <>
      <Grid
        container
        direction="row"
        style={{ height: "73px" }}
        className={classes.middleNav}
        alignItems="center"
        justifyContent={
          matchesSM || custommatchesXL ? "space-between" : undefined
        }>
        <Grid item className={classes.drawerToggle}>
          <Hidden mdUp>
            <IconButton onClick={() => setDrawerOpen(true)}>
              <img src={toggleMenu} alt="toggle-menu" />
            </IconButton>
          </Hidden>
          <Grid item
            className={classes.logo}
            style={{
              padding: matchesSM ? "0px" : null,
              marginLeft: custommatchesXL ? "7.5rem" : undefined,
            }}>
            <Button
              className={classes.logoButton}
              disableRipple
              component={Link}
              to="/"
            >
              <img src={doctall_logo} alt="doctall_logo" />
            </Button>
          </Grid>
        </Grid>
        <Grid item md={8} style={{ display: "flex" }}>
          <Grid
            item
            className={classes.tabLinks}
            style={{ paddingLeft: "3.6rem" }}
          >
            <Link to="/">Who are we
             {/* &nbsp;&nbsp;<img src={Help}/> */}
             </Link>
          </Grid>
          <Grid
            item
            className={classes.tabLinks}
            style={{ paddingLeft: "3.6rem" }}
          >
            <Link to="/">Services to Check &nbsp;&nbsp;<img src={Downarrow} /></Link>
          </Grid>
          <Grid
            item
            className={classes.tabLinks}
            style={{ paddingLeft: "3.6rem" }}
          >
            <Link to="/faq">FAQs</Link>
          </Grid>
        </Grid>
        <Grid item md={1}
          container
          alignItems="center"
          justifyContent={matchesMD ? "flex-end" : undefined}
        >
          <Grid item >
            <Grid
              container
              alignItems="center"
              justifyContent={matchesMD ? "flex-end" : undefined}
            >
              <Hidden >
                <Grid item>
                  <IconButton className={classes}
                  // onClick={handleLoginLogout}
                  >
                    <img src={Avatar} alt="searchIcon" />
                    &nbsp; &nbsp;  <img src={Downarrow} alt="searchIcon" />
                  </IconButton>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

