import { ThemeProvider } from "@material-ui/styles";
import { createBrowserHistory } from "history";
import React, { useEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import BaseRoutes from "./BaseRoutes";
import theme from "./components/ui/Theme";
import actions from "./redux/actions";
import hDClose from "./assets/images/hDClose.svg";
import hDBanner from "./assets/images/hDBanner.svg";
import hDTested from "./assets/images/hDTested.svg";
import rightArrow from "./assets/images/rightArrow.svg";
import { useCookies } from "react-cookie";
import { passcodeKey, usernameKey } from "./utils/constants";


import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => styles(theme));



function App({ store, persistor }) {
  var history = createBrowserHistory();
  return (
    <>
      <CookiesProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor} history={history}>
            <ThemeProvider theme={theme}>
              <BaseRoutes history={history} />

              {/* <Popover
                id={id}
                open={open}
                onClose={handleClose}
                autoClose={false}
                anchorReference="anchorPosition"
                anchorPosition={{ right: "50px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                className={classes.popover}
              >
                <Grid container direction="column" alignItems="center">
                  <Grid item container justifyContent="flex-end">
                    <IconButton
                      onClick={handleClose}
                      style={{
                        borderRadius: "50%",
                        marginRight: "16px",
                        width: "18px",
                      }}
                    >
                      <img src={hDClose} alt="close_icon" />
                    </IconButton>{" "}
                  </Grid>
                  <Grid container justifyContent="center">
                    <img
                      src={hDBanner}
                      alt="banner"
                      style={{ width: "302px" }}
                    />
                  </Grid>
                  <Typography
                    style={{
                      textAlign: "center",
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",

                      margin: "23px 0px 28px 0px",
                      width: "302px",
                    }}
                  >
                    Check your heart health with up to 3 lab tests for as low as
                    N13,000 and get a FREE medical report + FREE consultation
                    with a doctor.
                  </Typography>
                  <a href="https://hub.doctall.com/" target="_blank" style={{textDecoration:"none",color:"white"}}>
                  <button
                    variant="contained"
                    className={classes.findMoreButton}
                    style={{cursor:"pointer"}}
                  >
                    Find Out More{" "}
                    <img
                      src={rightArrow}
                      alt="arrow"
                      style={{ marginLeft: "6px" }}
                    />
                  </button>
                  </a>
                  <Grid
                    container
                    justifyContent="center"
                    style={{ marginTop: "26px" }}
                  >
                    <img
                      src={hDTested}
                      alt="banner"
                      style={{ width: "302px" }}
                    />
                  </Grid>
                </Grid>
              </Popover> */}
              {/* <Route element={<NotFound />} /> */}
              {/* <Helmet>
                <title>Doctor Consultations Via VideOnline Calls, Chat | Get well, live better </title>
                <meta
                  name="description"
                  content="See a doctor online; Nigerian, UK, and Indian Doctors. Get packaged treatments for hair loss, erectile dysfunction, premature ejaculation, and other acute and chronic conditions. Find accurate health information about cancer, malaria and much more"
                />
              </Helmet> */}
      {/* <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions>
      </Dialog> */}
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    </>
  );
}

export default App;
