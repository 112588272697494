// import contactLooper from "../../assets/images/contactLooper.svg";

// export const styles = (theme) => ({

//     contactHeader: {
//         background: "#FFFFFF",
//         paddingTop: "9.6rem",
//         paddingLeft: "7.8rem",
//         backgroundImage: `url(${contactLooper})`,
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "100% 28%",
//         height: "40.8rem",

//         [theme.breakpoints.down("sm")]: {
//           paddingLeft: "3.4rem",
//         },
//         [theme.breakpoints.down("xs")]: {
//           backgroundImage: "none",
//           backgroundRepeat: "none",
//           backgroundPosition: "none",
//           paddingLeft: "1.6rem",
//           paddingRight: "1.6rem",
//           height: "42.4rem",
//           paddingTop: "6.4rem",
//         },
//       },
//       contactTitle: {
//         fontFamily: "Visuelt Pro",
//         fontStyle: "normal",
//         fontWeight: "bold",
//         fontSize: "48px",
//         lineHeight: "60px",
//         letterSpacing: "-0.02em",
//         color: "#101828",
//         [theme.breakpoints.down("sm")]: {
//           fontSize: "42px",
//           lineHeight: "50px",
//         },

//         [theme.breakpoints.down("xs")]: {
//           fontSize: "36px",
//           lineHeight: "44px",
//         },
//       },
//       healthyLivingContainer: {
//         paddingLeft: "9.5rem",
//         paddingTop: "6.8rem",
//         paddingBottom: "10.8rem",
//         width: "100%",
//         height: "auto",
//         [theme.breakpoints.down("sm")]: {
//           paddingLeft: "0",
//         },
//       },

// });
// export default styles ;

import Banner from "../../assets/images/Banner.png";
import Doctor from "../../assets/images/doctor.svg";
import Wellness from "../../assets/images/Wellness.png";
import specialistcard from "../../assets/images/specialistCard.svg";
import diagnosisCard from "../../assets/images/diagnosisCard.svg";
import livingPattern from "../../assets/images/livingPattern.svg";
import looper3 from "../../assets/images/looper3.svg";
import looper4 from "../../assets/images/looper4.svg";
import iphone from "../../assets/images/iphone.png";
import WellnessPattern from "../../assets/images/wellnessPattern.svg";
import WellnessBackground from "../../assets/images/WellnessBackground.svg";
import specialistPattern from "../../assets/images/specialistPattern.svg";
import diagnosisPattern from "../../assets/images/diagnosisPattern.svg";
import EDdiseaseCard from "../../assets/images/EDdiseaseCard.svg";
// import DDdiseaseCard from "../../assets/images/Diabe.svg";
import PECard from "../../assets/images/PECard.png";
import HLCard from "../../assets/images/HairD.png";
import EDS from "../../assets/images/EDS.png";
import contactLooper from "../../assets/images/contactLooper.svg";
import banner from "../../assets/images/bannertest.svg";
import { fontSize } from "@mui/system";

const LandingStyle = (theme) => ({
  rowContainer: {
    width: "100%",
    margin: "0px",
  },
  banner: {
    background: `linear-gradient(98.09deg, rgba(1, 9, 11, 0.7) 41.96%, rgba(0, 26, 30, 0.7) 99.06%),url(${Banner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "100%",
    paddingLeft: "9.6rem",
    paddingTop: "15.4rem",
    paddingBottom: "9.6rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "4rem",
      paddingRight: "4rem",
    },
  },
  bannerTestimonial: {
    paddingTop: "20px",
  },

  joinNewsLater: {
    color: "#FF5C3C",
    fontSize: "40px",
    fontWeight: "500",
    marginTop: "40px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
      fontSize: "37px",
    },
  },
  inputField: {
    width: "200px",
  },
  comingSoonButton: {
    color: "#ffffff",
    padding: "10px 0px",
    background: "#FF5C3C",
    fontSize: "16px",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    borderRadius: "8px",
    width: "140px",
    marginLeft: "6%",
    border: "none",
    cursor: "pointer",
    textTransform: "none",
  },

  comingSoonSmallText: {
    padding: "0px",
  },

  bannerTestimonialImage: {
    width: "53px",
    height: "53px",
    borderRadius: "164px",
  },
  bannerPrimary: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "44px",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      lineHeight: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      lineHeight: "36px",
    },
  },
  testiPrimary: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
  },

  testiSecondary: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    /* identical to box height, or 143% */

    color: "#BCFFEB",
  },
  bookADoctor: {
    ...theme.typography.secondaryButton,
    padding: "12px 20px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "10.6rem",
    marginBottom: "7.27rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8.6rem",
    },
  },
  bannerArrow: {
    position: "relative",
    marginTop: "5rem",
  },
  bannerArrowPrev: {
    position: "absolute",
    zIndex: theme.zIndex.modal + 1,
    bottom: "10.6rem",
    left: "9.6rem",
    color: "white",
    cursor: "pointer",
  },
  bannerArrowNext: {
    position: "absolute",
    zIndex: theme.zIndex.modal + 1,
    bottom: "10.6rem",
    left: "14.1rem",
    color: "white",
    cursor: "pointer",
  },
  diseaseContainer: {
    width: "100%",
    height: "auto",
    paddingTop: "10.6rem",
    paddingLeft: "9.6rem",
    paddingBottom: "7rem",
    /* [theme.breakpoints.down("md")]: {
      paddingLeft: "6rem",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingLeft: "0px",
      alignItems: "center",
    },*/
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
    },
  },
  subHeading: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#002931",
  },
  diseaseCardED: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${EDS})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${EDS})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },

  diseaseCardHL: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${HLCard})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${HLCard})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },

  diseaseCardPE: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${PECard})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${PECard})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },
  diseaseCardDD: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${EDdiseaseCard})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },

  diseaseCardContent: {
    justifyContent: "space-around",
    height: "246px",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "3rem",
    paddingTop: "3.3rem",
    paddingRight: "2.7rem",
  },
  readMoreButton: {
    ...theme.typography.primaryButton,
    border: "1px solid #00839B",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    padding: "8px 14px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    marginLeft: "3rem",
  },
  healthyLivingContainer: {
    paddingLeft: "9.5rem",
    paddingTop: "6.8rem",
    paddingBottom: "10.8rem",
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
  },
  wellnessCard: {
    ...theme.typography.imageCard,
    backgroundImage: `url(${Wellness}),url(${WellnessPattern})`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#00839B",
    width: "360px !important",
    //padding: "16px",
    //opacity: "0.8",
    borderRadius: "10px",
    backgroundBlendMode: "normal,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${WellnessPattern})`,
      width: "300px",
    },
  },

  specialistCard: {
    ...theme.typography.imageCard,
    backgroundImage: `url(${specialistcard}),url(${specialistPattern})`,
    background: "#002C19",
    display: "flex",
    justifyContent: "center",
    width: "360px !important",
    alignItems: "center",
    backgroundBlendMode: "normal,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${specialistPattern})`,
      width: "300px",
    },
  },
  diagnosisCard: {
    ...theme.typography.imageCard,
    backgroundImage: `url(${diagnosisCard}),url(${diagnosisPattern})`,
    width: "360px !important",
    background: "#BF0B02",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundBlendMode: "normal,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${diagnosisPattern})`,
      width: "300px",
    },
  },
  livingButton: {
    ...theme.typography.tertiaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#143656",
    position: "absolute",
    bottom: "30px",
    [theme.breakpoints.down("sm")]: {
      bottom: "20px",
    },
  },
  popularProductContainer: {
    paddingLeft: "9.4rem",
    paddingTop: "6.8rem",
    width: "100%",
    height: "auto",
    background: "rgba(242, 248, 249, 0.5)",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  viewProductButton: {
    ...theme.typography.secondaryButton,
    width: "168px",
    background: "#FF5C3C",
    border: "1px solid #FF5C3C",
    boxSizing: "border-box",
    marginRight: "9.8rem",
    /* Shadow/xs */

    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  productCard: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "24px",
    paddingBottom: "32px",
    width: "282px",
    height: "410px",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
  },
  titleContainer: {
    background: "#F2F8F9",
    width: "250px",
    height: "auto",
    gridGap: "14px",
    marginBottom: "20px",
  },
  productTitle: {
    ...theme.typography.title,
    color: "#00839B",
  },
  viewTestButton: {
    ...theme.typography.primaryButton,
    width: "250px",
    height: "48px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  appLinkContainer: {
    maxWidth: "1248px",
    height: "auto",
    background: "#005F71",
    borderRadius: "10px",
    backgroundImage: `url(${iphone}),url(${looper3})`,
    backgroundPosition: "right bottom",
    backgroundRepeat: "no-repeat",

    [theme.breakpoints.down("md")]: {
      width: "80%",
      backgroundImage: `url(${looper3})`,
    },
  },
  linkBox: {
    paddingLeft: "10.1rem",
    paddingTop: "4.7rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  StoreMain: {
    display: "flex",

    background: "#FFFFFF",
    /* Gray/100 */

    border: "2px solid #F2F4F7",
    borderRadius: "5.31px",
  },
  blogContainer: {
    paddingLeft: "9.7rem",
    paddingTop: "7.6rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  viewBlogsButton: {
    ...theme.typography.secondaryButton,
    width: "157px",
    padding: "12px 18px",
    background: "#FF5C3C",
    border: "1px solid #FF5C3C",
    boxSizing: "border-box",
    marginRight: "6.3rem",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  blogCard: {
    width: "384px",
    height: "440px",
    boxShadow: "none",
    borderRadius: "0px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      height: "500px",
    },
  },
  blogCard2: {
    width: "384px",
    height: "520px",
    boxShadow: "none",
    borderRadius: "0px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      height: "500px",
    },
  },
  cardSetter: {
    padding: "0px",
  },
  cardBody: {
    ...theme.typography.title,
    fontWeight: "400",
    color: "#002931",
    marginBottom: "32px",
    height: "48px",
    width: "384px",
    lineHeight: "24px",
    wordBreak: "break-all",

    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    MozBoxOrient: "vertical;",
    MsBoxOrient: "vertical",
    BoxOrient: "vertical",
    WebkitLineClamp: "5",
    MozLineClamp: "5",
    MsLineClamp: "5",
    LineClamp: "5",
    overflow: "hidden",
  },
  blogButton: {
    ...theme.typography.primaryButton,
    padding: "12px 20px",
    width: "119px",
    height: "48px",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    position: "absolute",
    left: "0px",
    bottom: "0px",
  },
  partnerContainer: {
    width: "100%",
    height: "339px",
    background: "#F7FBF8",
    paddingLeft: "64px",
    paddingTop: "81px",
    paddingRight: "96px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "48px",

      paddingRight: "75px",
    },
  },
  partnerTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#002931",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  partnerInnerContainer: {
    paddingTop: "48px",
    paddingLeft: "16px",
  },
  newsLetterContainer: {
    width: "100%",
    background: "#F2F8F9",
    height: "auto",
    paddingLeft: "101px",
    paddingRight: "96px",
    paddingBottom: "98px",
    backgroundImage: `url(${looper4})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 28%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  newsLetterContent: {
    width: "491px",
    height: "auto",
    paddingTop: "94px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      textAlign: "center",
    },
  },

  newLetterSubHeading: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
  },
  newsLetterForm: {
    // width: "647px",
    height: "337px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "10px",
    marginTop: "74px",
    marginLeft: "105px",
    paddingLeft: "42px",
    paddingRight: "42px",
    paddingTop: "54px",
    paddingBottom: "54px",

    [theme.breakpoints.down("md")]: {
      width: "600px",
      paddingLeft: "36px",
      paddingRight: "36px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "530px",
      paddingLeft: "36px",
      paddingRight: "36px",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      paddingLeft: "24px",
      paddingRight: "24px",
      marginLeft: "0px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  inputLabel: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    paddingBottom: "6px",
    fontFamily: "Visuelt Pro",
    marginBottom: "10px",
    /* identical to box height, or 143% */

    /* Gray/700 */

    color: "#344054",
  },
  inputField: {
    background: "#FFFFFF",
    /* Gray/300 */
    width: "100%",
    height: "44px",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "10px 14px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    "&:focus": {
      border: "1px solid #D0D5DD",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  subscribeButton: {
    ...theme.typography.secondaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "10px 0px",
    marginTop: "20px",
    borderRadius: "8px",
    // backgroundColor:"#C53B20",
  },
  contactHeader: {
    background: "#ffffff",
    paddingTop: "9.6rem",
    paddingLeft: "7.8rem",
    backgroundImage: `url(${banner})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 28%",
    height: "84rem",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "3.4rem",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "none",
      backgroundRepeat: "none",
      backgroundPosition: "none",
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
      height: "75.4rem",
      paddingTop: "6.4rem",
    },
  },
  contactTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "60px",
    marginTop: "3%",
    width: "550px",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    color: "#101828",
    [theme.breakpoints.down("sm")]: {
      fontSize: "42px",
      lineHeight: "50px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: "36px",
      lineHeight: "44px",
      width: "350px",
    },
  },
  howItWorkContainer: {
    paddingLeft: "9.4rem",
    paddingTop: "6.8rem",
    width: "100%",
    height: "auto",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
    },
  },
  raditionButton: {
    // ...theme.typography.primaryButton,
    width: "250px",
    height: "48px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    color: "#344054",
    background: "#FFFFFF",
    boxShadow:
      "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    borderRadius: "6px",
    lineHeight: "24px",
  },
});

export default LandingStyle;
