import React, { useState, useEffect } from "react";
import {
    CardMedia,
} from "@material-ui/core";
import { getDoctallLivingImages, getDoctallPediaImages } from "../../../services/doctall-living/staticServices";

function DoctallPediaImages({ data }) {

    const [image, setImage] = useState("");

    useEffect(() => {
        fetchDoctallLivingImages(data.featured_media)
    }, [data]);


    const fetchDoctallLivingImages = async (_imageId) => {
        try {
            let response = await getDoctallPediaImages(_imageId)
            if (response && response.data) {
                setImage(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <CardMedia
                component="img"
                alt="blogImage"
                height="240px"
                image={image.source_url}
                loading='lazy'
            ></CardMedia>
        </>
    );
}

export default DoctallPediaImages;
