import actions from '../actions';

const { categoryActions } = actions;

const initState = {
    loader: false,
    categoryData: [],
    limit: 10,
    skip: 0,
    count: 0,
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {

        case categoryActions.GET_CATEGORY:
            return {
                ...state,
                loader: true
            };


        case categoryActions.GET_CATEGORY_SUCCESS:
            return {
                ...state,
                loader: false,
                categoryData: action.categoryData,
            };

        default:
            return state;
    }
}
