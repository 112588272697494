import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import DashboardComponent from '../layout/components/DashboardComponent'
import { MainListItems } from './MainListItem';
import DashboardHeader from '../layout/dashboardHeader';
import Appointments from '../layout/Appointments';
import { styles } from './styles'

class SidebarLayout extends React.Component {
    state = {
        open: true,
    };

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;

        return (
            <>
                <div style={{ backgroundColor: 'white' }}>
                    <DashboardHeader />
                </div>

                <div className={classes.root}>
                    <CssBaseline />
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                        }}
                        open={this.state.open}
                    >
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={this.handleDrawerClose}>
                                {/* <ChevronLeftIcon /> */}
                            </IconButton>
                        </div>
                        <List>{MainListItems}</List>
                    </Drawer>
                    <main className={classes.content} style={{ overflow: 'hidden',height:"auto" }}>
                        <div className={classes.appBarSpacer} />
                        <div style={{ height: 'auto', width: '90vw', backgroundColor: 'white' }}>
                            <Appointments/>
                        </div>
                        {/* <DashboardComponent
                            title={"Your Appointments"}
                            subTitle={"Create an manage your medical appointments"}
                        /> */}

                    </main>
                </div>
            </>
        );
    }
}

SidebarLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SidebarLayout);




