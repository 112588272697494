import actions from '../actions';

const { locationsActions } = actions;

const initState = {
    loader: false,
    citiesData: [],
    areasData: [],
    limit: 10,
    skip: 0,
    count: 0,
    pageNumber: 0,
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {

        case locationsActions.GET_CITY_LIST:
            return {
                ...state,
                loader: true
            };

        case locationsActions.GET_CITY_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                citiesData: action.citiesData,
            };

        case locationsActions.GET_AREA_LIST:
            return {
                ...state,
                loader: true
            };

        case locationsActions.GET_AREA_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                areasData: action.areasData,
            };


        default:
            return state;
    }
}
