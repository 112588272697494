import React from "react";
import {
    Grid,
    Button,
    Typography,
    useMediaQuery,
    Box,
} from "@material-ui/core";
import starz from "../../../../assets/images/stars.svg"
import StaticHeader from "../Static_Header/StaticHeader"
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import deliver from "../../../../assets/images/deliveryFree.png";
import easytobe from "../../../../assets/images/easy-to-buy.svg";
import doctorsuport from "../../../../assets/images/DoctorSupport.png";
// import carrerImg from "../../../assets/images/careerImg.png";
// import careerImgMob from "../../../assets/images/careerImgMob.png";
import EDstatic from "../../../../assets/images/EDstatic.png";
import { useNavigate } from 'react-router-dom';
import Footer from "../../../../components/Layout/Footer/Footer";

const useStyles = makeStyles((theme) => styles(theme));

export default function StaticED() {
    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const matchesXL = useMediaQuery(theme.breakpoints.down("xl"));

    return (
        <>
           <StaticHeader />
            <Grid container>
                <Grid item container direction="column" className={classes.contactHeader}>
                   
                    <Typography className={classes.careerBannerTitle}>
                      Erectile Dysfunction
                    </Typography>
                    <Typography className={classes.careerPara}>
                    It is not unusual at any age to occasionally not be able to achieve an erection. If it happens often, there are various highly effective, clinically-proven solutions that you can choose from below.
                    </Typography>
                    <span className={classes.star}>
                        <img src={starz} alt="rating" />
                    </span>
                    <Typography className={classes.testimonial}>
                      “Having tried many treatments for my erectile problems, I had little hope before trying doctall treatment plans. To my surprise, this has given me the confidence and ability to no longer worry about performance anxiety.”
                      </Typography>
                    <Typography className={classes.doctall}> DOCTALL PATIENT</Typography>
                    <Grid  item className={classes.img} sm={12} md={12} xs={12}>
                       
                       <img src={EDstatic} alt="edstatic" />
                       
                    </Grid>
                   
           
                </Grid>
                </Grid>
<Grid item >
    <Grid container
    
    direction={matchesXS ? "column" : "row"}

    style={{
        marginTop: matchesXS ? "20px" : "10px",
        borderBottom: "1px solid rgba(174, 215, 223, 0.4)",
        minHeight: matchesXS ? "280px" : "70px",
      }}
      >
            <Grid
              item
              lg={4}
              md={4}
              sm
              container
              justifyContent={matchesXS ? "flex-start" : "center"}
              alignItems="center"
              style={{
                height: "100px",
                background: "#ffffff",
                paddingLeft: matchesXS ? "15%" : undefined,
                backgroundColor: "#ffffff"
              }}
             >
              <img src={deliver} alt="iconDeliver" />
              <Typography className={classes.navLink}>
              &nbsp;  Discreet delivery
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm
              container
              justifyContent={matchesXS ? "flex-start" : "center"}
              alignItems="center"
              style={{
                height: "100px",
                background: "#ffffff",
                paddingLeft: matchesXS ? "15%" : undefined,
                backgroundColor: "#ffffff"
              }}
             >
              <img src={easytobe} alt="easyToBuyicon" style={{ height: "55px" }} />
              <Typography className={classes.navLink}>
              &nbsp; Easy to buy
              </Typography>
            </Grid>
            
            <Grid
              item
              lg={4}
              md={4}
              sm
              container
              justifyContent={matchesXS ? "flex-start" : "center"}
              alignItems="center"
              style={{
                height: "100px",
                background: "#ffffff",
                paddingLeft: matchesXS ? "15%" : undefined,
                backgroundColor: "#ffffff"
              }}
             >
              <img src={doctorsuport} alt="iconClinician" /> 
              <Typography className={classes.navLink}>
              &nbsp;  Doctor support available
              </Typography>
            </Grid>
            </Grid>
            </Grid>

                <Grid item style={{
                    height : matchesXS ? "320px" : "270px"
                }}>
         

                
                <Grid sm={12} md={12} xs={12} className={classes.careerContainer} 
                justifyContent={matchesXS || matchesMD ? "center" : "center"}
                align="center" >
                   
                    <Typography className={classes.careerHeading}  >
                      For more information on all of our Erectile Dysfunction treatment plans, click Buy Treatment! 
                      </Typography>
                    <Button  variant="contained"
                    style={{ 
                      color: "#ffffff", 
                    fontSize: "14px", 
                    fontWeight: "500", 
                    backgroundColor: "#FF5C3C", 
                    border: "1px solid #FF5C3C",
                    borderRadius: "8px", 
                    textColor: "#ffffff",
                    padding: "5px 15px",
                    width: "176px",
                    height: "60px", 
                    // color: "white",
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    boxSizing: "border-box",
                    textTransform: "capitalize",
                    marginTop: matchesXS  ? "70px" : "40px",
                    }} 
                    onClick={() => navigate("/erectile-dysfunction")}
                    >
                        Buy Treatment 
                    </Button>
                    
                    
                    </Grid>
            </Grid>
           
            <Footer />
        </>
    );
};


