import {
    Button, Dialog, Grid,
    makeStyles,
    Typography,
    useTheme
} from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import order from "../../assets/images/order.svg";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

const OrderConfirmed = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  let navigate = useNavigate();

  const matchesXS = theme.breakpoints.down("xs");

  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={{ paper: classes.orderConfirm }}
    >
      <Grid container direction="column" alignItems="center">
        <img src={order} alt="tick" />
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "28px",
            lineHeight: "36px",
            color: "#163E8C",
            paddingTop: "51px",
            paddingBottom: "24px",
          }}
        >
          Order confirmed
        </Typography>
        <Typography
          style={{
            width: matchesXS ? "250px" : "374px",
            height: "97px",
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "24px",
            color: "#717782",
          }}
          align="center"
        >
          We will be in touch with you shortly. Thank you for trusting Doctall.
        </Typography>
        <Button
          className={classes.confirmButton}
          variant="contained"
          style={{
            background: "#0C44AC",
            color: "white",
            marginBottom: "32px",
          }}
          onClick={() => navigate("/")}
        >
          Return to Homepage
        </Button>
        {/* Not Required As of Now */}
        {/* <Button
                    className={classes.confirmButton}
                    variant="outlined"
                    style={{ border: "1px solid #0C44AC", color: "#0C44AC" }}
                    onClick={() => navigate('/')}
                >
                    View Orders
                </Button> */}
      </Grid>
    </Dialog>
  );
};

export default OrderConfirmed;
