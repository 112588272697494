import actions from '../actions';

const { treatmentPlanAction } = actions;

const initState = {
    loader: false,
    treatmentPlans: [],
    limit: 10,
    skip: 0,
    count: 0,
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {

        case treatmentPlanAction.GET_TREATMENT_PLANS:
            return {
                ...state,
                loader: true
            };


        case treatmentPlanAction.GET_TREATMENT_PLANS_SUCCESS:
            return {
                ...state,
                loader: false,
                treatmentPlans: action.treatmentData,
            };

        default:
            return state;
    }
}
