import React, { useState } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import faqExpandIcon from "../../assets/images/faqExpandIcon.svg";
import faqCollapseIcon from "../../assets/images/faqCollapseIcon.svg";

const useStyles = makeStyles((theme) => styles(theme));
const FAQExpandIcon = () => <img src={faqExpandIcon} alt="collapese Icon" />;
const FAQCollapseIcon = () => <img src={faqCollapseIcon} alt="collapseIcon" />;

const FAQ = ({ FAQData }) => {
  const [currentQues, setCurrentQues] = useState();
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid item container className={classes.faqMainArea}>
      
      <Grid
        item
        container
        direction="column"
        className={classes.faqSubHeadingContainer}
      >
        <Typography className={classes.faqTitle} style={{ color: "#FB6514" }}>
          Support
        </Typography>
        <Typography className={classes.faqSubHeading}>FAQs</Typography>
        <Typography className={classes.faqsubtitle2}>
          Everything you need to know about the product and billing. Can’t find
          the answer you’re looking for? Please chat to our friendly team.
        </Typography>
      </Grid>
     
      <Grid item container md direction="column" className={classes.faqList}>
        {FAQData.map((question, index) => (
          <Accordion
            classes={{ root: classes.accordian }}
            key={index}
            expanded={expanded === question.title}
            onChange={handleChange(question.title)}
           >
            <AccordionSummary
              expandIcon={
                expanded === question.title ? (
                  <FAQCollapseIcon />
                ) : (
                  <FAQExpandIcon />
                )
              }
              expanded={expanded === question.title}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{ root: classes.accordianSummary }}
            >
              <Typography className={classes.faqQuestion}>
                {question.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordianDetails }}>
              <Typography className={classes.accordianAnswer}>
                {question.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default FAQ;
