import React, { useEffect, useState } from 'react';
// import "../../Auth/auth.scss";
import "./influencer.scss";
import { Grid, Input, makeStyles, Button } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import logo from "../../../assets/images/doctall_logo.png"
import { useNavigate } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import styles from "../../../components/Landing/styles";
import "../../../components/Landing/Landing.scss";
import { getCountryCodes, validateEmail } from '../../../services/doctall-living/staticServices';
import Box from '@mui/material/Box';

import actions from '../../../redux/actions';

import { Link } from "react-router-dom";
import infImg from "../../../assets/images/reg.png";
import RegistrationHeader from "../RegistrationPages/header";
import moment from 'moment';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import DatePicker from 'sassy-datepicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import { showNotification } from '../../../components/common-notification/CommonNotification';
import StaticHeader from '../../staticPages/utils/Static_Header/StaticHeader';
const useStyles = makeStyles((theme) => styles(theme));


export default function RegistrationPage() {
    const classes = useStyles();
    let dispatch = useDispatch();
    const navigate = useNavigate();
    // const { authActions } = actions;
    const { influencersActions } = actions;
    const [country, setCountry] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [reserror, setReserror] = useState(false);
    const [doctallTerms, isTermsAgreed] = useState(false);
    const [emailTerms, isEmailTermsAgreed] = useState(false);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [dob, setDob] = useState("");
    const [refCode, setRefCode] = useState();
    const [refField, setRefField] = useState();
    const [gender, setGender] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    console.log(refField, "test333")

    const [countryDialCode, setCountryDialCode] = useState();

    const onChangeFname = e => {
        const input = e.currentTarget.value;
        if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
            setFname(input);
        }
    };
    const onChangeLname = e => {
        const input = e.currentTarget.value;
        if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
            setLname(input);
        }
    };
    const onChangeDob = e => {
        const input = e.currentTarget.value;
        setDob(input);
        // if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
        //     setDob(input);
        // }
    };
    const onChangeRefCode = e => {
        const input = e.currentTarget.value;
        setRefCode(input);
        // if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
        //     setDob(input);
        // }
    };
    const handleChangeGender = (e) => {
        setGender(e.target.value)
    }






    const errorstyle = {
        border: reserror ? "1px solid #E04E47" : "1px solid #ADC2C2 ",
        outline: "none !important",
        borderRadius: "5px",

    };

    useEffect(() => {
        getCountryCode()
        const queryParams = new URLSearchParams(window.location.search)
        const ref = queryParams.get("referralcode")
        setRefCode(ref)
        setRefCode(ref)
        if (ref) {
            setRefField(true)
        }
        else {
            setRefField(false)
        }
        // refCode ? setRefField(true):setRefField(false)



    }, []);


    const getCountryCode = async () => {
        try {
            let response = await getCountryCodes()
            if (response) {

                setCountry(response.data.country_code.toLowerCase());
            }
        } catch (error) {
            console.log(error)
        }
    }


    const _onPhoneChange = (value, country, e, formattedValue) => {
        setPhoneNumber(value)
        setCountryDialCode(country.dialCode)
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const { firstName, lastName, email } = event.target.elements;
        let payLoad = {
            // "full_name": `${firstName.value}  ${lastName.value}`,
            "firstName": firstName.value,
            "lastName": lastName.value,
            "email": email.value,
            "mobile": phoneNumber,
            "dob": dob,
            "gender": gender,
            "referal_code": refCode,

            // "password": '',
            // "group": "consumer",
        }
        // dispatch(influencersActions.addInfluencers(payLoad));
        // navigate("/")
        axios.post(`${process.env.REACT_APP_API_BASE_URL_INFLUENCER}/user/auth/influencer/user/register`, payLoad)
            .then((response) => {
                console.log(response, "res123")
                if (response && response.status === 200 || response.status === 201) {
                    return navigate('/RegistrationSucess')
                }
                else {
                    console.log(response.data, "response")
                }
            })
            .catch((error) => {
                console.log(error)
                console.log(error.response.data, "asdkha")
                showNotification("error", "Failed !", "" + error.response.data)
            })
    }







    return (
        <>


            <RegistrationHeader />
          
            <div className='login_page'>

                <Grid container spacing={2} fullWidth>

                    <Grid item sm={12} xs={12} >

                        <div className='influencer-container'>

                            {/* <img src={infImg} alt="login_image" className='Login_img' /> */}
                            <div>.</div>
                            <div className='fom-container'>
                                {/* <h3 className={classes.Registrationform_heading}>
                                Kindly provide your details
                            </h3> */}
                                <h1 className='Login_heading1'> Kindly provide your details</h1>
                                <br />
                                <form onSubmit={handleSubmit}>
                                    <FormControl variant="standard" fullWidth>
                                        <label htmlFor="name" className={classes.inputLabel}>
                                            First Name*
                                        </label>
                                        <Input

                                            onChange={onChangeFname}
                                            value={fname}

                                            placeholder='Enter your firstname'
                                            type="text"
                                            required
                                            name='firstName'
                                            className={classes.inputField}
                                            disableUnderline={true}
                                            inputProps={{
                                                maxLength: 25,
                                                minLength: 3,
                                            }}
                                            fullWidth
                                        />
                                        <label
                                            htmlFor="lastName"
                                            className={classes.inputLabel}
                                            style={{ paddingTop: "20px" }}
                                        >
                                            Last Name*
                                        </label>




                                        <Input
                                            onChange={onChangeLname}
                                            value={lname}

                                            placeholder='Enter your lastname'
                                            name='lastName'
                                            type="text"
                                            required
                                            className={classes.inputField}
                                            disableUnderline={true}
                                            inputProps={{
                                                maxLength: 25,
                                                minLength: 3,
                                            }}
                                        />

                                        <label
                                            htmlFor="lastName"
                                            className={classes.inputLabel}
                                            style={{ paddingTop: "20px" }}
                                        >
                                            Date Of Birth
                                        </label>

                                        {/* <Input
                                        //    {...register("dob", { required: 'Date of birth is required' })}
                                        // value={moment(value).format("DD /MMM /YYYY")}

                                        // onChange={onChangeDob(handleOver)}
                                        type="calender"
                                        className={classes.inputField}
                                        name='dob'
                                        // className={classes.inputFields}
                                        disableUnderline={true}
                                        onChange={onChangeDob}

                                    // onMouseOver={handleOver}
                                    // aria-describedby={id}

                                    /> */}
                                        {/* <LocalizationProvider dateAdapter={AdapterDateFns} >
                                            <DatePicker
                                                // label="Basic example"
                                                className={classes.inputField}
                                                value={dob}
                                                onChange={(newValue) => {
                                                    setDob(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} className={classes.inputField}/>}
                                            />
                                        </LocalizationProvider> */}



                                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Custom input"
        value={dob}
        onChange={(newValue) => {
            setDob(newValue);
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <input ref={inputRef} {...inputProps}   className={classes.inputField}/>
            {InputProps?.endAdornment}
          </Box>
        )}
      />
    </LocalizationProvider> */}

                                        <input type="date" data-date-format="YYYY MMMM DD" placeholder="asdkh" className={classes.inputField} value={dob}
                                            style={{ fontFamily: "Visuelt Pro" }}
                                            onChange={onChangeDob} />



                                        <label
                                            htmlFor="middleName"
                                            className={classes.inputLabel}
                                            style={{ paddingTop: "20px" }}
                                        >
                                            Gender*
                                        </label>
                                        <select
                                            value={gender}
                                            onChange={handleChangeGender}

                                            className={classes.inputField}

                                            disableUnderline={true}
                                        >
                                            <option value=''>Select Sex</option>
                                            <option value='male'>Male</option>
                                            <option value='female'>Female</option>
                                            <option value='other'>Others</option>
                                        </select>

                                        <label
                                            htmlFor="middleName"
                                            className={classes.inputLabel}
                                            style={{ paddingTop: "20px" }}
                                        >
                                            Email address
                                        </label>
                                        <Input
                                            placeholder='Enter your email address'
                                            name='email'
                                            type="email"
                                            required
                                            className={classes.inputField}
                                            disableUnderline={true}
                                        />

                                        <label
                                            htmlFor="phoneNumber"
                                            className={classes.inputLabel}
                                            style={{ paddingTop: "20px" }}
                                        >
                                            Phone Number*
                                        </label>
                                        <PhoneInput
                                            style={errorstyle}
                                            country={country}
                                            value={phoneNumber}
                                            onChange={(value, country, e, formattedValue) => _onPhoneChange(value, country, e, formattedValue)}
                                            name='phoneNumber'
                                        />

                                        <label
                                            htmlFor="lastName"
                                            className={classes.inputLabel}
                                            style={{ paddingTop: "20px" }}
                                        >
                                            Referal Code
                                        </label>

                                        {refField == true ?
                                            <div>

                                                <Input
                                                    onChange={onChangeRefCode}
                                                    value={refCode}
                                                    disabled
                                                    // disabled={refCode ? disabled : null }
                                                    placeholder='Enter your lastname'
                                                    name='refaral_code'
                                                    type="text"
                                                    required
                                                    className={classes.inputField}
                                                    disableUnderline={true}
                                                    inputProps={{
                                                        maxLength: 25,
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div>
                                                <Input
                                                    onChange={onChangeRefCode}
                                                    value={refCode}

                                                    placeholder='Enter your lastname'
                                                    name='refaral_code'
                                                    type="text"
                                                    required
                                                    className={classes.inputField}
                                                    disableUnderline={true}
                                                    inputProps={{
                                                        maxLength: 25,
                                                    }}
                                                />
                                            </div>
                                        }
                                        <Button variant="contained" type='submit' className={classes.subscribeButton} style={{ backgroundColor: "#C53B20" }}
                                        >
                                            Continue
                                        </Button>
                                    </FormControl>
                                </form>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

        </>
    )
}