import {
    Accordion, AccordionDetails, AccordionSummary, Button, Grid, makeStyles,
    Typography,
    useMediaQuery,
    useTheme
  } from "@material-ui/core";
  import { Input } from 'antd';
  import PasswordField from 'material-ui-password-field';
  
  import React, { useEffect, useState } from "react";
  import FacebookLogin from 'react-facebook-login';
  import GoogleLogin from 'react-google-login';
  import PhoneInput from "react-phone-input-2";
  import { useDispatch, useSelector } from 'react-redux';
  import { showNotification } from "../../../components/common-notification/CommonNotification";
  import authActions from "../../../redux/actions/authActions";
  import { checkForUnique, onLoginRequest, onRegistration, onResetPasscode, onVerifyOTP } from "../../../services/auth-services/authServices";
  import { getCountryCodes, validateEmail } from "../../../services/doctall-living/staticServices";
  import styles from "./../styles";
  import Dialog from '@material-ui/core/Dialog';
  import Close from "../../../assets/images/close.png";
  import axios from 'axios'
  // import { Input } from 'antd';
  // import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
  // import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
  
  const useStyles = makeStyles((theme) => styles(theme));
  
  const Account = (
    // { expanded, handleChange, setAccountDetails, handleExpand }
    {stepChange}
    ) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const [signUp, setSignUp] = useState(false);
    const [doctallTerms, isTermsAgreed] = useState(false);
    const [emailTerms, isEmailTermsAgreed] = useState(false);
    const [accountCreated, setAccountCreated] = useState(false);
    // const [passwordType, setPasswordType] = useState("password");
    // const [passwardInput, setPasswardInput] = useState("")
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [countryDialCode, setCountryDialCode] = useState();
  
    const [modalShow, setModalShow] = useState(false);
    const [status, setStatus] = useState('')
    const [uuid1, setUuid1] = React.useState('')
    const [logtype, setLogtype] = React.useState('')
    const [nphone1, setNphone1] = useState();
  
    const [view, setView] = useState(false)
    const viewPass = () => {
      setView(!view)
    }
  
    const [view1, setView1] = useState(false)
    const viewPass1 = () => {
      setView1(!view1)
    }
    //   const handlePasswordChange =(event)=>{
    //     event.preventDefault();
    //     setPasswardInput(event.target.value);
    // }
    // const togglePassword =()=>{
    //   if(passwordType==="password")
    //   {
    //    setPasswordType("text")
    //    return;
    //   }
    //   else{
    //     setPasswordType("password")
    //   }
    // }
  
    // const searchInput = useRef(null);
    // useEffect(()=>{
    //   // current property is refered to input element
    //   searchInput.current.focus();
    // },[])
  
  
    const onChangeFname = e => {
      const input = e.currentTarget.value;
      if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
        setFname(input);
      }
    };
    const onChangeLname = e => {
      const input = e.currentTarget.value;
      if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
        setLname(input);
      }
    };
  
  
  
    const dispatch = useDispatch();
  
    const [country, setCountry] = useState();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userPasscode, setUserPasscode] = useState();
    const [reserror, setReserror] = useState(false);
    const [title, setTitle] = useState("Kindly provide your details");
  
  
    const _userInfo = useSelector((state) => state.authReducer);
  
    const errorstyle = {
      border: reserror ? "1px solid #E04E47" : "1px solid #ADC2C2 ",
      outline: "none !important",
      borderRadius: "5px",
    };
  
    const { email, firstName, lastName, mobile } = _userInfo && _userInfo.userInfo ? _userInfo.userInfo : {};
  
    console.log(_userInfo, "_userInfo")
    let LoginType = _userInfo && _userInfo.userInfo && _userInfo.userInfo.login_type
    useEffect(() => {
      if (_userInfo && _userInfo.userInfo) {
        // handleExpand("Shipping")
        setTitle("Logged In")
        // stepChange()
      }
      getCountryCode()
    }, []);
  
    const getCountryCode = async () => {
      try {
        let response = await getCountryCodes()
        if (response) {
          setCountry(response.data.country_code.toLowerCase());
          setPhoneNumber(mobile)
        }
      } catch (error) {
        console.log(error)
      }
    }
  
    const handleDoctallTerms = (event) => {
      isTermsAgreed(event.target.checked)
    }
  
    const handleEmailTerms = (event) => {
      isEmailTermsAgreed(event.target.checked)
    }
  
    const _onPhoneChange = (value, country, e, formattedValue) => {
      setPhoneNumber(value)
      setCountryDialCode(country.dialCode)
    }
  
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const { firstName, lastName, email, middleName, passcode } = event.target.elements;
      let payLoad = {
        "full_name": `${firstName.value}  ${lastName.value}`,
        "firstName": firstName.value,
        "lastName": lastName.value,
        "email": email.value,
        "mobile": phoneNumber,
        "password": passcode.value,
        "group": "consumer",
      }
      if (countryDialCode === "234" && phoneNumber.length < 13) {
        showNotification('error', "error !", "Please enter valid mobile number")
        return false;
      }
      else if (countryDialCode === "91" && phoneNumber.length < 12) {
        showNotification('error', "error !", "Please enter valid mobile number")
        return false;
      }
  
      else if (phoneNumber.length < 10) {
        showNotification('error', "error !", "please enter valid phone number")
        return false;
      }
      else if (!validateEmail(email.value)) {
        showNotification("error", "Failed !", "Please Enter Valid Email Address")
      }
      else if (doctallTerms === false) {
        showNotification("error", "Failed !", "Please agree to the doctall terms")
      }
      else if (emailTerms === false) {
        showNotification("error", "Failed !", "Please agree to the email terms")
      } else {
        setUserPasscode(passcode.value)
        checkUniqness(payLoad)
      }
  
    }
  
    const checkUniqness = async (payLoad) => {
      try {
        let _payLoad = { email: payLoad.email, mobile: payLoad.mobile }
        let response = await checkForUnique(_payLoad)
        if (response && response.status === 200 || response.status === 201) {
          userRegistration(payLoad)
        }
      } catch (error) {
        if (error && error.response && error.response.data) {
          showNotification("error", "Failed !", "" + error.response.data.message)
        //   setAccountDetails(false)
        }
      }
    }
  
    const userRegistration = async (payLoad) => {
      try {
        let response = await onRegistration(payLoad)
        if (response && response.status === 200 || response.status === 201) {
          sendVerificationCode(payLoad)
        }
      } catch (error) {
        if (error && error.response && error.response.data) {
          showNotification("error", "Failed !", "" + error.response.data)
        //   setAccountDetails(false)
        }
      }
    }
  
    const sendVerificationCode = async (payLoad) => {
      try {
        let resetPasscodePayLoad = { "email": payLoad.email, "mobile": phoneNumber }
        let response = await onResetPasscode(resetPasscodePayLoad)
        if (response && response.status === 200 || response.status === 201) {
          showNotification("success", "Success", "Account created successfully, Please verify the code you just received in your mail")
          setAccountCreated(true)
        }
  
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.message) {
          showNotification("error", "Failed !", "" + error.response.data.message)
        }
      }
    }
  
  
    const handleLogin = async (event) => {
      event.preventDefault();
      let { passcode } = event.target.elements;
      let payLoad = { 'username': phoneNumber, "password": passcode.value }
      let payLoad1 = { 'username': phoneNumber, "password": passcode.value, type: "loginwithcredential" }
      try {
        let response = await onLoginRequest(payLoad1)
        if (response) {
          dispatch(authActions.handleLogin(payLoad1))
        //   setAccountDetails(response.data)
          showNotification('success', "Success !", "Logged In Successfully")
        //   handleExpand("Shipping")
          setTitle("Logged In")
        }
      } catch (error) {
        showNotification("error", "Failed !", "" + error.response.data)
      }
    }
  
    const _onCodeVerification = async (event) => {
      event.preventDefault()
      const { verificationCode } = event.target.elements;
      try {
        let payLoad = { "mobile": phoneNumber, "otp": verificationCode.value }
        let response = await onVerifyOTP(payLoad)
        showNotification("success", "Success !", "Account verified successfully")
        // setAccountDetails(true)
        let loginPayLoad = { 'username': phoneNumber, "password": userPasscode, type: "loginwithcredential" }
        dispatch(authActions.handleLogin(loginPayLoad))
        // handleExpand("Shipping")
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.message) {
          showNotification("error", "Failed !", "" + error.response.data.message)
        }
      }
  
    }
    const logoutFun = () => {
      setModalShow(false)
      dispatch(authActions.userLogout())
    }
    let token ;
    const mobileUpdate = (values) => {
      values.preventDefault();
      console.log(values)
      // let phnNum = values.target.elements.phoneNUmber.value;
      var payload = {
        "uuid": uuid1,
        "login_type": logtype,
        "mobile": nphone1
      }
      axios.post(`${process.env.REACT_APP_API_BASE_URL}user/auth/update/facebook/google/mobile`, payload,{
        headers: { "x-auth-token": status }
    })
        .then((res) => {
          localStorage.setItem("phoneNumber", nphone1);
          setModalShow(false)
        //   handleExpand("Shipping")
          setTitle("Logged In")
          showNotification('success', "Success !!", "Mobile Number Updated successfully")
        })
        .catch((err) => {
          setModalShow(false)
        })
    }
  
    var mobileFT = _userInfo && _userInfo.userInfo && _userInfo.userInfo.is_mobile_update
    var uuidss = _userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid
  
  
    const responseGoogle = (response) => {
      console.log(response, "google")
      const type = "loginwithgoogle"
      var payLoad = { response, type };
      console.log(payLoad, "google")
      let response1 = onLoginRequest(payLoad)
      // if (response1) {
      //   dispatch(authActions.handleLogin(payLoad))
      //   // setAccountDetails(response.data)
      //   handleExpand("Shipping")
      //   setTitle("Logged In")
      // }
      if (response1) {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}user/auth/google/login`, { tokenId: response.tokenId })
          .then((res) => {
            console.log(res, "res")
            if (res.data.is_mobile_update === false) {
              dispatch(authActions.handleLogin(payLoad))
              setStatus(res.data.token)
              setModalShow(true)
              setUuid1(res.data.uuid)
              setLogtype(res.data.login_type)
              token = res.data.token
            }
            else {
              dispatch(authActions.handleLogin(payLoad))
              setModalShow(false)
            //   handleExpand("Shipping")
              setTitle("Logged In")
            }
          })
        // if (mobileFT === false) {
        //   setStatus("fb")
        //   setModalShow(true)
        //   setUuid1(uuidss)
        //   setLogtype(LoginType)
        // }
        // else if(mobileFT === true){
        //   setModalShow(false)
        //   handleExpand("Shipping")  
        //   setTitle("Logged In")
        // }
        // else{
        //   showNotification('failed', "Failed !!", "Try again")
        // }
      }
    }
    const responseFacebook = (response) => {
      console.log(response, "fb")
      const type = "loginwithFb"
      var payLoad = { response, type };
      // let response1 = onLoginRequest(payLoad)
      // if (response1) {
      //   dispatch(authActions.handleLogin(payLoad))
      //   // setAccountDetails(response.data)
      //   handleExpand("Shipping")
      //   setTitle("Logged In")
      // }
      let response1 = onLoginRequest(payLoad)
      console.log(response1, "dfgh")
      if (response1) {
        const payLoads111 = {
          "authToken": response.accessToken,
          "facebookUserId": response.userID
        }
        // dispatch(authActions.handleLogin(payLoad))
        axios.post(`${process.env.REACT_APP_API_BASE_URL}user/auth/facebook/login`, payLoads111)
          .then((res) => {
            console.log(res, "res")
            if (res.data.is_mobile_update === false) {
              dispatch(authActions.handleLogin(payLoad))
              setStatus("fb")
              setModalShow(true)
              setUuid1(res.data.uuid)
              setLogtype(res.data.login_type)
  
            }
            else {
              dispatch(authActions.handleLogin(payLoad))
              setModalShow(false)
            //   handleExpand("Shipping")
              setTitle("Logged In")
            }
          })
        // dispatch(authActions.handleLogin(payLoad))
        // if (mobileFT === false) {
        //   setStatus("fb")
        //   setModalShow(true)
        //   setUuid1(uuidss)
        //   setLogtype(LoginType)
        // }
        // else if(mobileFT === true){
        //   setModalShow(false)
        //   handleExpand("Shipping")  
        //   setTitle("Logged In")
        //   showNotification('success', "Success !!", "Logged in successfully")
        // } 
        // else{
        //   // showNotification('error', "Failed !!", "Try again")
        // }
      }
    };
    return (
      <>
        {signUp ? (
        //   <Accordion
        //     className={_userInfo && _userInfo.userInfo ? classes.inputContainerFaded : classes.inputContainer}
        //     style={{ marginBottom: "32px" }}
        //   >
        //     <AccordionSummary
        //       aria-controls="account"
        //       id="account"
        //       style={{ minHeight: "91px", padding: "0px" }}
        //     >
        //       <Typography className={_userInfo && _userInfo.userInfo ? classes.summaryHeadingFaded : classes.summaryHeading}>
        //         1. {title}
        //       </Typography>
        //     </AccordionSummary>
        //     <AccordionDetails style={{ height: "auto", padding: "0px" }}>
        <>
              {accountCreated === false ? <>
                <form onSubmit={handleSubmit} style={{display:"grid",justifyContent:"center", alignItems:"center", padding:"20px", backgroundColor:"#fff"}}>
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography
                        align="left"
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#344054",
                          paddingBottom: "6px",
                        }}
                      >
                        First name
                      </Typography>
  
                      <Input
                        onChange={onChangeFname}
                        value={fname}
                        type="text"
                        required
                        name='firstName'
                        defaultValue={firstName}
                        placeholder="Enter your firstname"
                        className={classes.checkoutInput}
                        inputProps={{
                          maxLength: 25,
                        }}
                        disableUnderline={true}
                      />
                    </Grid>
                    <Grid item style={{ paddingTop: "20px" }}>
                      <Typography
                        align="left"
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#344054",
                          paddingBottom: "6px",
                        }}
                      >
                        Last name
                      </Typography>
                      <Input
                        onChange={onChangeLname}
                        value={lname}
                        type="text"
                        required
                        name='lastName'
                        placeholder="Enter your lastname"
                        className={classes.checkoutInput}
                        defaultValue={lastName}
                        inputProps={{
                          maxLength: 25,
                        }}
                        disableUnderline={true}
                      />
  
                    </Grid>
                    <Grid item style={{ paddingTop: "20px" }}>
                      <Typography
                        align="left"
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#344054",
                          paddingBottom: "6px",
                        }}
                      >
                        Phone number
                      </Typography>
  
                      <PhoneInput
                        style={errorstyle}
                        // className="inputPhone"
                        country={country}
                        value={phoneNumber}
                        // onChange={setPhoneNumber}
                        onChange={(value, country, e, formattedValue) => _onPhoneChange(value, country, e, formattedValue)}
  
                        name='phoneNumber'
                      />
  
  
                    </Grid>
                    <Grid item style={{ paddingTop: "20px" }}>
                      <Typography
                        align="left"
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#344054",
                          paddingBottom: "6px",
                        }}
                      >
                        Email address
                      </Typography>
  
                      <Input
                        type="email"
                        required
                        name='email'
                        placeholder="Enter your email address"
                        className={classes.checkoutInput}
                        defaultValue={email}
                        disableUnderline={true}
                      />
  
                    </Grid>
                    <Grid item style={{ paddingTop: "20px" }}>
                      <Typography
                        align="left"
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#344054",
                          paddingBottom: "6px",
                        }}
                      >
                        Referral code (Optional)
                      </Typography>
  
                      <Input
                        type="text"
                        name='referralCode'
                        placeholder="Enter your referral code"
                        className={classes.checkoutInput}
                        disableUnderline={true}
                      />
  
                    </Grid>
                    <Grid item style={{ paddingTop: "20px" }}>
                      <Typography
                        align="left"
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#344054",
                          paddingBottom: "6px",
                        }}
                      >
                        Passcode
                      </Typography>
                      <PasswordField
                        required
                        name='passcode'
                        placeholder="Enter Passcode"
                        disableUnderline={true}
                        hintText="only  4 characters"
                        inputProps={{
                          maxLength: 4,
                          minLength: 4
                        }}
                        // visible={true}
                        errorText="Your password is too short"
                        visible={false}
                        className={classes.checkoutInput}
                      />
                      {/* <Input
                        type="text"
                        name='passcode'
                        required
                        placeholder="Please enter 4 digit passcode"
                        className={classes.checkoutInput}
                        inputProps={{
                          maxLength: 4,
                          minLength: 4
                        }}
                        disableUnderline={true}
                      /> */}
  
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        width: matchesSM ? "330px" : "356px",
                        marginTop: "24px",
                      }}
                    >
                      <Grid>
                        <input
                          type="checkbox"
                          className={classes.inputCheck}
                          onChange={(event) => handleDoctallTerms(event)}
                        />
                      </Grid>
                      <Grid style={{ marginLeft: "8px" }}>
                        <Typography
                          // htmlFor="name"
                          align="left"
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#667085",
                            width: matchesSM ? "200px" : "332px",
                          }}
                        >
                          I accept Doctall’s Terms and Conditions
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        width: matchesSM ? "330px" : "380px",
                        marginTop: "14px",
                      }}
                    >
                      <Grid>
                        <input
                          type="checkbox"
                          className={classes.inputCheck}
                          onChange={(event) => handleEmailTerms(event)}
                        />
                      </Grid>
                      <Grid style={{ marginLeft: "8px" }}>
                        <Typography
                          // htmlFor="name"
                          align="left"
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#667085",
                            width: matchesSM ? "200px" : "345px",
                          }}
                        >
                          I agree to receive emails about Doctall promotions, products
                          and services
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button variant="contained" className={classes.createAccount} type='submit'>
                      Create Account{" "}
                    </Button>
  
                    <Grid
                      item
                      container
                      justifyContent="center"
                      style={{ marginTop: "32px", marginBottom: "162px" }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#667085",
                        }}
                      >
                        Already have an account?
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#B4331A",
                          paddingLeft: "4px",
                        }}
                        component={Button}
                        onClick={() => setSignUp(!signUp)}
                        className={classes.linkText}
                        disableRipple
                        disableFocusRipple
                      >
                        Log in
                      </Typography>
                    </Grid>
                  </Grid>
                </form> </> : <><form onSubmit={_onCodeVerification} style={{display:"grid",justifyContent:"center", alignItems:"center", padding:"20px", backgroundColor:"#fff"}}>
                  <Grid item container direction="column">
  
                    <Grid item style={{ paddingTop: "20px" }}>
                      <Typography
                        align="left"
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "20px",
                          color: "#344054",
                          paddingBottom: "6px",
                        }}
                      >
                        Confirm verification code
                      </Typography>
  
                      <Grid item style={{ paddingTop: "20px" }}>
                        <Typography
                          align="left"
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#344054",
                            paddingBottom: "6px",
                          }}
                        >
                          Verification Code
                        </Typography>
  
                        <Input
                          type="text"
                          name='verificationCode'
                          required
                          placeholder="Please enter verification code"
                          className={classes.checkoutInput}
                          inputProps={{
                            maxLength: 6,
                            minLength: 6
                          }}
                          disableUnderline={true}
                        />
  
                      </Grid>
                    </Grid>
                    <Button variant="contained" className={classes.createAccount} type='submit'>
                      Confirm code{" "}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    style={{ marginTop: "32px", marginBottom: "50px" }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#667085",
                      }}
                    >
                      Already have an account?
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#B4331A",
                        paddingLeft: "4px",
                      }}
                      component={Button}
                      onClick={() => setSignUp(!signUp)}
                      className={classes.linkText}
                      disableRipple
                      disableFocusRipple
                    >
                      Log in
                    </Typography>
                  </Grid>
                </form></>}
                </>
        ) : (
        //   <Accordion
        //     className={_userInfo && _userInfo.userInfo ? classes.inputContainerFaded : classes.inputContainer}
        //   >
        //     <AccordionSummary
        //       aria-controls="account"
        //       id="account"
        //       style={{ minHeight: "91px", padding: "0px" }}
        //     >
        //       <Typography className={_userInfo && _userInfo.userInfo ? classes.summaryHeadingFaded : classes.summaryHeading}>
        //         1. {title}
        //       </Typography>
        //     </AccordionSummary>
        //     <AccordionDetails style={{ height: "auto", padding: "0px" }}>
              <Grid item container direction="column" alignItems="center" 
              style={{paddding:"10px", backgroundColor:"#fff"}}
              >
                
                {/* <Typography>
                  <div>
                    <p  align="left" style={{
                      textAlign:"left", 
                      fontSize:"20px",
                      fontWeight:"700",
                      fontFamily:"Visuelt Pro",
                      color:"#002931",
                      display:"flex",
                      justifyContent:"flex-start",
                      alignContent:"flex-start",
                    }}>
                    Returning User
                    </p>
                  </div>

                </Typography> */}
                <form onSubmit={handleLogin}>
                <Typography
                      align="left"
                      style={{
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "30px",
                        color: "#002931",
                      }}
                    >
                      Returning User
                    </Typography>
                  <Grid item style={{ paddingTop: "35px" }}>
                    <Typography
                      align="left"
                      style={{
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#344054",
                        paddingBottom: "6px",
                      }}
                    >
                      Phone number
                    </Typography>
  
                    <PhoneInput
                      style={errorstyle}
                      // className="inputPhone"
                      country={country}
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      name='phoneNumber'
                    />
  
                  </Grid>
  
                  <Grid item style={{ paddingTop: "20px" }}>
                    <Typography
                      align="left"
                      style={{
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#344054",
                        paddingBottom: "6px",
                      }}
                    >
                      Passcode
                    </Typography>
                    {/* <Input
                      type={passwordType}
                      required
                      value={passwardInput}
                      onChange={handlePasswordChange}
                      name='passcode'
                      placeholder="Enter Passcode"
                      disableUnderline={true}
                       ref={searchInput}
                      inputProps={{
                        maxLength: 4,
                        minLength: 4
                      }}
                      className={classes.checkoutInput}
                    />
                     <div className="input-group-btn">
                       <button className="btn btn-outline-primary" onClick={togglePassword}>
                       { passwordType==="password"? <i className="bi bi-eye-slash"></i> :<i className="bi bi-eye"></i> }
                       </button>
                      </div> */}
  
                    {/* <Input
                      name="passcode"
                      maxLength="4"
                      className="input_box"
                      type={view == true ? "text" : "password"} size='large'
                      suffix={
                        view == true ?
                          <EyeOutlined
                            style={{
                              fontSize: 16,
                              color: '#1890ff',
                              cursor: "pointer"
                            }}
                            onClick={viewPass}
                          />
                          :
                          <EyeInvisibleOutlined
                            style={{
                              fontSize: 16,
                              color: '#1890ff',
                              cursor: "pointer"
                            }}
                            onClick={viewPass}
                          ></EyeInvisibleOutlined>
                      } /> */}
                    <PasswordField
                      required
                      name='passcode'
                      placeholder="Enter Passcode"
                      disableUnderline={true}
                      hintText="only  4 characters"
                      inputProps={{
                        maxLength: 4,
                        minLength: 4
                      }}
                      // visible={true}
                      errorText="Your password is too short"
                      visible={false}
                      className={classes.checkoutInput}
                    />
                  </Grid>
  
                  <Button variant="contained" className={classes.createAccount} type='submit'>
                    Sign in{" "}
                  </Button>
                </form>
                <div>&nbsp;</div>
                {/* <Grid container style={{ marginLeft: matchesXS ? "-60px" : undefined }}>
                  <Grid item xs={3}>
                  </Grid>
                  <Grid item xs={3}>
                    <FacebookLogin
                      appId="2887677518113793"
                      // appId="196007421065373"
                      autoLoad={false}
                      fields="name,email,picture"
                      cssClass={classes.fb}
                      textButton="&nbsp;&nbsp;&nbsp;Login"
                      callback={responseFacebook}
                      icon="fa-facebook"
                      isMobile={false}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ marginLeft: matchesXS ? "50px" : undefined }}>
                    <GoogleLogin
                      className="GoogleLogin"
                      style={{ width: "1000px" }}
                      clientId="815723410350-ins2a1s6c2trpfibl0gh9nd9ts5tqtqe.apps.googleusercontent.com"
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={'single_host_origin'}
                    />
                  </Grid>
                  <Grid item xs={3}>
                  </Grid>
                </Grid> */}
                <Dialog fullWidth={true} maxWidth={"sm"} open={modalShow} >
  
                  <div className="closeButtonPopup">
                    <img style={{ cursor: 'pointer' }} src={Close} onClick={() => { logoutFun() }} alt="Close" crossorigin="anonymous" />
                  </div>
  
                  <form onSubmit={mobileUpdate}>
                    <label style={{ marginLeft: "20%" }}>Please Provide your Mobile Number</label>
                    <center>
                      <br></br>
                      <PhoneInput
                        style={{ width: "60%" }}
                        required
                        className="inputPhone"
                        country={country}
                        value={nphone1}
                        onChange={setNphone1}
                        name="phone"
                      />
                      <br></br>
                      <button type="submit" value={"submit"} className="signin_btn" style={{ width: "20%" }}>
                        Submit
                      </button>
                      <br></br><br></br><br></br>
                    </center>
                  </form>
                </Dialog>
                <Grid
                  item
                  container
                  justifyContent="center"
                  style={{ marginTop: "32px", marginBottom: "37px" }}
                >
                  <Typography
                    style={{
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#667085",
                    }}
                  >
                    Don’t have an account?
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#B4331A",
                      paddingLeft: "4px",
                    }}
                    component={Button}
                    className={classes.linkText}
                    onClick={() => setSignUp(!signUp)}
                    disableRipple
                    disableFocusRipple
                  >
                    Sign Up
                  </Typography>
                </Grid>
              </Grid>
        //     </AccordionDetails>
        //   </Accordion>
        )}
      </>
    );
  };
  
  export default Account;
  