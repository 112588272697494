// import premiumServices from "../../assets/images/premiumServices.svg";
import { Title } from "@material-ui/icons";
import doctallRing from "../../assets/images/doctallRing.svg";
import homecareLooper from "../../assets/images/homeCareLooper2.svg";

let premiumServices =
  "https://s3.eu-west-1.amazonaws.com/doctall.storage.com/new-doctall/premiumServices.svg";

const styles = (theme) => ({
  diseaseBanner: {
    paddingTop: "14.4rem",
    paddingLeft: "9.6rem",
    height: "auto",
    paddingBottom: "5.8rem",
    backgroundPosition: "85% 100%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingTop: "40px",
      // width:"200px",
      alignItems:"center",
    },
  },
  diseaseTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "60px",
    lineHeight: "72px",
    letterSspacing: "-0.02em",
    color: "#002931",
    [theme.breakpoints.down("xs")]: {
      fontSize: "48px",
      lineHeight: "60px",
    },
  },
  diseaseDesc: {
    display:"flow root",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "32px",
    color: "#002931",
    width: "494px",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
    },
  },

  diseasebannerTestimonial: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "32px",
    color: "#00839B",
    width: "540px",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
    },
  },
  diseasebannerTestiUser: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#004451",
  },
  shareContainer: {
    position: "absolute",
    right: "94.72px",
    bottom: "56.78px",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  packageContainer: {
    height: "674px",
    minWidth: "286px",
    // minWidth: matchesSM ? "300px" : "350px",
    maxWidth: "500px",
    background: "#FFFFFF",
    border: "1px solid #E4E7EC",
    boxSizing: "border-box",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
    borderRadius: "16px",
    position: "relative",
    paddingTop: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "380px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "286px",
    },
  },
  packageTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#101828",
    paddingBottom: "4px",
  },
  packageSubTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#667085",
    paddingBottom: "16px",
  },
  packagePrice: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "60px",
    color: "#101828",
    latterSpacing: "-0.02em",
    paddingBottom: "32px",
  },
  packageBenefits: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#667085",
  },
  packageHighlight: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#475467",
  },
  Biomarkers: {
    background: "#F9FAFB",
    padding: "16px 32px",
    width: "340px",
    justifyContent: "space-between",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#667085",
    textTransform: "none",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
    },
  },
  StartButton: {
    ...theme.typography.secondaryButton,
    width: "320px",
    position: "absolute",
    bottom: "36px",
    [theme.breakpoints.down("xs")]: {
      width: "260px",
    },
  },
  packagePlanContainer: {
    backgroundColor: "#ffffff",
    width: "100%",
    height: "auto",
    paddingTop: "36px",
    paddingBottom: "32px",
  },
  sectionHeading: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "30px",
    color: " #002931",
  },
  customerCareContent: {
    marginLeft: "32px",
    marginRight: "76px",
    width: "673px",
    [theme.breakpoints.down("xs")]: {
      width: "270px",
      marginLeft: "16px",
      marginRight: "16px",
    },
  },
  customerCareButton: {
    ...theme.typography.secondaryButton,
    height: "48px",
  },
  customerCareTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "38px",
  },
  customerCareSubtitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "32px",
  },
  premiumServicesContainer: {
    marginLeft: "48.5px",
    maxWidth: "1150px",
    height: "386px",
    background: "rgba(242, 248, 249, 0.5)",
    border: "1px solid rgba(174, 215, 223, 0.2)",
    boxSizing: "border-box",
    borderRadius: "4px",
    backgroundImage: `url(${premiumServices}),url(${doctallRing})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      marginLeft: "0px",
    },
  },
  callbackButton: {
    ...theme.typography.secondaryButton,
    width: "181px",
  },
  Closepopup: {
    position: "absolute",
    // transform: "scale(1.5)",
    top: "10px",
    right: "25px",
    cursor: "pointer",
  },
  homeCareContainer: {
    minHeight: "563px",
    backgroundImage: `url(${homecareLooper})`,
    backgroundPosition: "left 0%",
    backgroundRepeat: "no-repeat",
    paddingBottom: "40px",
  },
  homeCareContent: {
    paddingTop: "106px",
    paddingRight: "117px",
    width: "560px",
  },
  reqHomeCareButton: {
    ...theme.typography.secondaryButton,
    width: "max-content",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  bookDoctor1: {
    ...theme.typography.secondaryButton,
    width: "382px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    margin: "auto",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "12px 20px",
    margin: "10px 70px",
    color: "#ffffff",
    // marginBottom: "28px",
    [theme.breakpoints.down("xs")]: {
      width: "260px",
      margin: "0px",
    },
  },
  wellbeing: {
    width: "650px",
    textAlign: "justify",
    [theme.breakpoints.down("xs")]: {
      width: "260px",
    },
  },
  // blogCard:{
  //   position: "relative",
  //   height: "auto",
  //   display: "flex",
  //   justifyContent: "space-around",
  //   flexDirection: "column",

  // },
  // cardBody:{
  //   position: "absolute",
  //   height: "auto",
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "space-around",
  //   alignItems: "flex-end",
  // }
});

export default styles;
