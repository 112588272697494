import React, { useEffect, useState } from "react";
import { EDquestions as questions } from "./Questions";
import "./styles.js";
import QLogo from "../../assets/images/QLogo.svg";
import { Link } from "react-router-dom";
import {
  Grid, IconButton, makeStyles, useMediaQuery, useTheme, Typography,
} from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import LinearProgressBar from "../../components/ui/LinearProgress";
import styles from "./styles";

import { Helmet } from "react-helmet";
// import { useLocation } from "react-router-dom";
import QWelcome from "../../assets/images/QWelcome.png";
import prevQuestionIcon from "../../assets/images/prevQuestion.svg";
import { getFirebaseAnalyticsInstance } from "../../firebase.js";
import {
  AllergicDrugs, AnemiaQuestion, ImapirmentQuestion,
  MedicalConditions
} from "./components/EDQuestionnaire.js";
// import {
//   AllergicDrugs, AnemiaQuestion, ImapirmentQuestion, AllergicDrugs1, AllergicDrugs2,
//   EDCheckout, MainCheckout
// } from "./components/NewEDQuestionnaire.js";
import { AgeQuestion, Mail } from "./components/staticQuestions.js";
import QuestionCard from "./util/QuestionCard";
import { getDiesesIds } from "../../pages/diseases/utils/diseasesIds";

const useStyles = makeStyles((theme) => styles(theme));

const Questionnaire = () => {
  // const { questionsData, userSelectedPlan } = props;
  const { state } = useLocation();

  const { selectedPlan, diseaseId, diseaseName, planDetails } = state
    ? state
    : {};
  console.log(state,planDetails, "shbjasjhbsjkhsa")

  const classes = useStyles();
  const theme = useTheme();
  let navigate = useNavigate();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const [step, setStep] = useState(1);
  const [callBackModel, setCallBackModel] = useState(false);

  const [questionsData, setQuestionsData] = useState([]);

  const [data, setData] = useState({
    age: "",
    email: "",
    drug: [],
    imapirment: "",
    anemia: "",
    medication: [],
    saveData: [],
    anemiaConditionflag: false,
    shippingCarges: 0,
    shippingAddress: {},
  });
  data["medication"] = questionsData;
  console.log(data.saveData, data, "dvddfdf")
  const [uuidDaata, setUuidDaata] = useState();

  console.log(uuidDaata, "dhgchjs")

  const [planAmount, setPlanAmount] = useState('')
  const [planQuantity, setPlanQuantity] = useState('')
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')

  const [pDetailes, setPDetailes] = useState('')
  const [slectedPDetailes, setSelectedPDetailes] = useState()

  const diseaseUUID = getDiesesIds('Erectile Dysfunction');
  
  useEffect(() => {
    if (step > questions.length ) {
      data["medication"] = questionsData;
      navigate("/summary", {
        state: {
          uuidDaata:uuidDaata,
          questionsSummary: data,
          planInfo: planDetails,
          userSelectedPlan: selectedPlan,
          disease: diseaseName,
        },
      });
      
    }
  }, [step, data]);

  
  // useEffect(() => {
  //   if (step > questions.length-2 ) {
  //     data["medication"] = questionsData;
  //     console.log(questions, "questions")

  //     navigate("/EdCard", {
  //       state: {
  //         diseaseUUID: diseaseUUID,
  //         uuid : uuidDaata,
  //         questionanswer: data,
  //         diseaseName : "Erectile Dysfunction"
  //       },
  //     });
      
  //   }
  // }, [step, data]);

  useEffect(() => {
    addFireBaseAnalytics();
  }, [step, data]);

  const addFireBaseAnalytics = async () => {
    try {
      await getFirebaseAnalyticsInstance().logEvent("ED_QUESTIONNAIRE_START", {
        eventType: 'TREATMENT_PLAN_QUESTIONNAIRE',
        platform: 'web'
      });
    } catch (error) {
      console.log(error)
    }
  }



  const prevQuestion = () => {
    setStep(step - 1);
  };

  const [guestState, setGuestState] = useState(false)
  console.log(guestState, "hgdcAGFDHGAJAS")
  // New ED Flow Start
  // const RenderedItem = () => {
  //   switch (step) {
  //     // case 1:
  //     //   return (
  //     //     <AgeQuestion
  //     //       age={questions[0]}
  //     //       selectedAge={data.age}
  //     //       handleChange={(selectedAge) => {
  //     //         setData({ ...data, age: selectedAge });
  //     //       }}
  //     //       stepUpdate={(step) => setStep(step)}
  //     //     />
  //     //   );
  //     //   break;
  //     // case 2:
  //     //   return (
  //     //     <Mail
  //     //       mail={questions[1]}
  //     //       userEmail={data.email}
  //     //       handleChange={(email, shippingCharge, shippingDetails) =>
  //     //         setData({ ...data, email: email, shippingCarges: shippingCharge, shippingAddress: shippingDetails })
  //     //       }
  //     //       stepUpdate={(step) => setStep(step)}
  //     //     />
  //     //   );
  //     case 1:
  //       return (
  //         <AllergicDrugs
  //           drugs={questions[2]}
  //           stepUpdate={(step) => setStep(step)}
  //           handleChangeDrug={(drug) => setData(drug)}
  //           sav={data}
  //           // uuidata={(data) => setUuidDaata(data)}
  //           disease_uuid={diseaseUUID}
  //           disease_name={"Erectile Dysfunction"}
  //           // uuidDaata={uuidDaata}
  //         />
  //       );

  //     case 2:
  //       return (
  //         <AllergicDrugs1
  //           drugs={questions[3]}
  //           stepUpdate={(step) => setStep(step)}
  //           handleChangeDrug={(drug) => setData((prev) => [...prev, drug])}
  //           sav={data}
  //           uuidata={(data) => setUuidDaata(data)}
  //           // disease_uuid={planDetails && planDetails.diseases_uuid}
  //           // disease_name={planDetails && planDetails.diseases_name}
  //            disease_uuid={diseaseUUID}
  //           disease_name={"Erectile Dysfunction"}
  //           uuidDaata={uuidDaata}
  //         // ques={questions[3]}
  //         // stepUpdate={(step) => setStep(step)}
  //         // handleImapirment={(event) =>
  //         //   setData({ ...data, imapirment: event })
  //         // }
  //         // step={step}
  //         // sav = {data}
  //         // uuidata = {(data) => setUuidDaata(data)}
  //         // disease_uuid = {planDetails && planDetails.diseases_uuid}
  //         // disease_name = {planDetails && planDetails.diseases_name}
  //         // uuidDaata={uuidDaata}
  //         />
  //       );
  //     case 3:
  //       return (
  //         <AllergicDrugs2
  //           drugs={questions[2]}
  //           stepUpdate={(step) => setStep(step)}
  //           setPlanAmount={(amt) => setPlanAmount(amt)}
  //           setPlanQuantity={(amt) => setPlanQuantity(amt)}
  //           setTitle={(amt) => setTitle(amt)}
  //           setSubtitle={(amt) => setSubtitle(amt)}
  //           handleChangeDrug={(drug) => setData({ ...data, drug: drug })}
  //           sav={data}
  //           uuidata={(data) => setUuidDaata(data)}
  //           disease_uuid={diseaseUUID}
  //           disease_name={"Erectile Dysfunction"}
  //           uuidDaata={uuidDaata}
  //           setPDetailes={(amt) => setPDetailes(amt)}
  //           setSelectedPDetailes={(amt) => setSelectedPDetailes(amt)}
  //         />
  //       );
  //     case 4:
  //       return (
  //         <EDCheckout
  //           drugs={questions[2]}
  //           stepUpdate={(step) => setStep(step)}
  //           guestStatusUpdate={(guest) => setGuestState(guest)}

  //           handleChangeDrug={(drug) => setData({ ...data, drug: drug })}
  //           sav={data}
  //           uuidata={(data) => setUuidDaata(data)}
  //           disease_uuid={diseaseUUID}
  //           disease_name={planDetails && planDetails.diseases_name}
  //           uuidDaata={uuidDaata}
  //           guestState={guestState}
  //           questionsSummary={data}
  //           planAmount1={planAmount}
  //           planQuantity={planQuantity}
  //           diseaseUUID={diseaseUUID}
  //           Plantitle={title}
  //           planDetails={pDetailes}
  //           Plansubtitle={subtitle}
  //         />
  //       );
  //     case 5:
  //       return (
  //         <MainCheckout
  //           drugs={questions[2]}
  //           stepUpdate={(step) => setStep(step)}
  //           handleChangeDrug={(drug) => setData({ ...data, drug: drug })}
  //           sav={data}
  //           uuidata={(data) => setUuidDaata(data)}
  //           disease_uuid={diseaseUUID}
  //           disease_name={planDetails && planDetails.diseases_name}
  //           uuidDaata={uuidDaata}
  //         />
  //       );

  //     default:
  //       data["medication"] = questionsData;
  //       navigate("/checkouts", {
  //         state: {
  //           uuidDaata: uuidDaata,
  //           questionsSummary: data,
  //           userSelectedPlan: selectedPlan,
  //           disease: diseaseName,
  //           guestState: guestState,
  //           planAmount1: planAmount,
  //           planQuantity: planQuantity
  //         },
  //       });
  //       return null;
  //       break;
  //   }
  // };
  // New ED Flow End
  // OlD ED Flow Start

  const RenderedItem = () => {
    switch (step) {
      case 1:
        return (
          <AgeQuestion
            age={questions[0]}
            selectedAge={data.age}
            handleChange={(selectedAge) => {
              setData({ ...data, age: selectedAge });
            }}
            stepUpdate={(step) => setStep(step)}
          />
        );
        break;
      case 2:
        return (
          <Mail
            mail={questions[1]}
            userEmail={data.email}
            handleChange={(email, shippingCharge, shippingDetails) =>
              setData({ ...data, email: email, shippingCarges: shippingCharge, shippingAddress: shippingDetails })
            }
            stepUpdate={(step) => setStep(step)}
          />
        );
      case 3:
        return (
          <AllergicDrugs
            drugs={questions[2]}
            stepUpdate={(step) => setStep(step)}
            handleChangeDrug={(drug) => setData({ ...data, drug: drug })}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );

      case 4:
        return (
          <ImapirmentQuestion
            ques={questions[3]}
            stepUpdate={(step) => setStep(step)}
            handleImapirment={(event) =>
              setData({ ...data, imapirment: event })
            }
            step={step}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 5:
        return (
          <AnemiaQuestion
            ques={questions[4]}
            stepUpdate={(step) => setStep(step)}
            handleAnemia={(event) => setData({ ...data, anemia: event })}
            isAnimiaConditionAccepted={(flag) =>
              setData({ ...data, anemiaConditionflag: flag })
            }
            step={step}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );

      case 6:
        return (
          <MedicalConditions
            condition={questions[5]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            // saveData={(event) => setData({ ...data, medicalCondition_step1:  event })}
            saveData = {(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 7:
        return (
          <MedicalConditions
            condition={questions[6]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            // saveData={(event) => setData({ ...data, medicalCondition_step2: event })}
            saveData = {(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 8:
        return (
          <MedicalConditions
            condition={questions[7]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            // saveData={(event) => setData({ ...data, medicalCondition_step3: event })}
            saveData = {(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
          );

      default:
        data["medication"] = questionsData;
        navigate("/summary", {
          state: {
            uuidDaata:uuidDaata,
            questionsSummary: data,
            userSelectedPlan: selectedPlan,
            planInfo:planDetails,
            disease: diseaseName,
          },
        });
        return null;
        break;
    }
  };

  // Old ED Flow End



  return (
    <>
      <Helmet>
        <title>
          Erectile Dysfunction Questionnaire | Discreet erectile dysfunction treatment online
        </title>
        <meta
          name="description"
          content="Treat different types of erectile dysfunction with doctor-packaged plans. Choose your treatment plan, answer basic questions about your symptoms, completer your treatment order, and have your medicines delivered to you discreetly"
        />
      </Helmet>
      <Grid item container alignItems="center" direction="column">
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: "20px", //width: "820px"
        }}
      >
        {step === 1 ? null : (
          <Grid item style={{ width: "114px", marginRight: "39px" }}>
            <IconButton>
              <img src={prevQuestionIcon} alt="prev" onClick={prevQuestion} />
            </IconButton>
          </Grid>
        )}

        <Grid item>
          <LinearProgressBar
            value={(step - 1) * 13}
            // value={(step - 1) * 50}
            // diseaseName={"Erectile Dysfunction"}
          />
        </Grid>


      </Grid>
      <Grid item container>
        {<RenderedItem />}
      </Grid>
    </Grid>
      {/* <Grid item container alignItems="center" direction="column">
        {step > 2 && step <= 3 ? <>
          <div className="row">
            <div className="col-12">
              <div style={{
                fontSize: "36px",
                padding: "0px 0px 30px 0px",
                display: "flex",
                justifyContent: "center",
                color: "#002931",
                fontWeight: "600",
                paddingTop: "40px",
              }}>
                <p >Congratulations</p><br />
              </div>
              <div style={{
                padding: "0px 20px",
                fontWeight: "300",
                fontSize: "24px",
                fontFamily: "Visuelt Pro",
                display: "flex",
                justifyContent: "center",
                color: "#002931"
              }}>
                <p>You are suitable for a Doctall Treatment Plan. Please select your preferred Plan below:</p>
              </div>
            </div>
          </div>
        </>
          :
          step <= 2 &&
          <>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: "20px", //width: "820px"
              }}
            >

              {step === 0 ? null : (
                <Grid item style={{ width: "114px", marginRight: "39px" }}>
                  <IconButton>
                    <img src={prevQuestionIcon} alt="prev" onClick={prevQuestion} />
                  </IconButton>
                </Grid>
              )}

              <Grid item>
                <LinearProgressBar
                  // value={(step - 1) * 13}
                  value={(step - 1) * 50}
                  // value={step}
                  diseaseName={"Erectile Dysfunction"}

                />
              </Grid>


            </Grid>
            {step <= 1 &&
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ position: "relative" }}
            >
              <img src={QWelcome} alt="Welcome" />
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "36px",
                  lineHeight: "44px",
                  color: "#101828",
                  letterSpacing: "-0.02em",
                  paddingTop: "32px",
                  paddingBottom: "12px",
                }}
              >
                Welcome,
              </Typography>

            </Grid>
            }
          </>

        }



        <Grid item container>

          {<RenderedItem />}

        </Grid>

      </Grid> */}
    </>
  );
};

export default Questionnaire;
