import actions from "../actions";

const initState = {
    userInfo: [{}],
    registrationPayLoad: {},
    loader : false
};

export default function rootReducer(state = initState, action) {
    // console.log(action,actions.authActions.LOGIN_SUCCESS,"action")
    if(action.type === actions.authActions.LOGIN_SUCCESS){
            return {
                ...state,
                userInfo: action && action.userInfo,
                loader : true
            };
    }
    else if(action.type === actions.authActions.SAVE_REGISTRATION_PAYLOAD){
        return {
            ...state,
            registrationPayLoad: action.payLoad,
        }
    }
    else if(action.type === actions.authActions.LOGOUT){
        return {
            ...state,
            userInfo: null
        }
    }
    else{
      return  state
    }
    // switch (action.type) {
    //     case actions.authActions.LOGIN_SUCCESS:
    //         return {
    //             ...state,
    //             userInfo: action && action.userInfo,
    //             loader : true
    //         };
    //     case actions.authActions.LOGOUT:
    //         return {
    //             userInfo: null
    //         };
    //     case actions.authActions.SAVE_REGISTRATION_PAYLOAD:
    //         return {
    //             ...state,
    //             registrationPayLoad: action.payLoad,
    //         };

    //     default:
    //         return state;
    // }
}
