import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import CheckIcon from "../../assets/images/CheckIcon.svg";
import ChevronRightPack from "../../assets/images/chevronRightPack.svg";
import iconPhoneCircle from "../../assets/images/iconPhoneCircle.svg";
import premiumServiceIcon from "../../assets/images/premiumServiceIcon.svg";
import { Link } from "react-router-dom";
import close from "../../assets/images/close.png";
import { useDispatch, useSelector } from "react-redux";
import CallBackPopUp from "../callbackPopUp/CallBackPopUP";
import { Dialog } from "@material-ui/core";
import { getPackage } from "./utils/diseasesIds";
import BiomarkersPopup from "../../components/popup/BiomarkersPopup";
import InvestigationAreaPopup from "../../components/popup/InvestigationAreaPopup";
import actions from "../../redux/actions";

const useStyles = makeStyles((theme) => styles(theme));

const LabPackage = ({ diseaseName }) => {
  const dispatch = useDispatch();
  let { packageActions } = actions;
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesXL = useMediaQuery("(min-width:1500px)");
  const [callBackModel, setCallBackModel] = useState(false);
  const _Package = useSelector((state) => state.packageReducer);
  const [currentpageurl, setCurrentPageUrl] = useState("");
  // console.log(diseaseName, _Package, "_Packagesss");

  useEffect(() => {
    // setCurrentPageUrl(window.location.href)

    let payLoad = getPackage(diseaseName);
    // console.log(payLoad, "dhggdh");
    dispatch(packageActions.getPackage(payLoad));
  }, [diseaseName]);

  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.packagePlanContainer}>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          style={{ paddingBottom: "28px" }}
        >
          <Typography align="center" className={classes.sectionHeading}>
            Packages
          </Typography>
          <hr
            style={{
              width: "100px",
              height: "2px",
              background: "#FF8D77",
              borderRadius: "60px",
              marginTop: "9px",
              marginBottom: "16px",
              border: "none",
            }}
          />
        </Grid>
        <Grid 
        // xs={12}
          item
          container
          style={{
            // paddingRight: "25px",
            paddingBottom: "40px",
            display:"flex",
            justifyContent:"center",
          }}
        >
          {_Package &&
            _Package?.packageData?.data?.length > 0 &&
            _Package?.packageData?.data?.map((item) => {
              return (
                <Grid
                  item
                  md={4} 
                  style={{ 
                    // paddingRight: "24px", 
                    paddingRight: matchesSM ? "0px" : "24px",
                    paddingBottom: "24px" ,
                   width: matchesXS ? "286px" : "380px" ,
                  }}
                >
                  <BasicPlan item={item} />
                </Grid>
              );
            })}
          {/* <Grid item md style={{ paddingRight: "24px", paddingBottom: "24px" }}>
            <IntermediatePlan />
          </Grid>
          <Grid item md style={{ paddingRight: "24px", paddingBottom: "24px" }}>
            <IntermediatePlan />
          </Grid> */}
        </Grid>
        <hr
          style={{
            background: "rgba(174, 215, 223, 0.4)",
            width: "100%",
            marginBottom: "42px",
          }}
        />
        <Grid item container className={classes.premiumServicesContainer}>
          <Grid
            item
            container
            direction="column"
            style={{
              marginLeft: matchesSM ? "24px" : "79px",
              paddingTop: matchesSM ? "20px" : "69px",
              width: matchesSM ? "300px" : "608px",
            }}
          >
            <Grid item container style={{ paddingBottom: "26px" }}>
              <img src={premiumServiceIcon} alt="service-icon" />
              <Typography
                style={{
                  paddingLeft: "8px",
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "900",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#475467",
                  alignSelf: "center",
                }}
              >
                PREMIUM CONCIERGE SERVICES{" "}
              </Typography>
            </Grid>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "30px",
                lineHeight: "38px",
                color: "#002931",
                paddingBottom: "36px",
                fontSize: matchesSM ? "20px" : "30px",
              }}
            >
              Find out more about our bespoke premium diagnostic services
              tailored for your convenience
            </Typography>
            <Button variant="contained" className={classes.callbackButton}>
              Request a Callback
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={matchesXS ? "column" : "row"}
        justifyContent={custommatchesXL ? "center" : undefined}
        alignItems="center"
        style={{ paddingTop: "64px", paddingLeft: "0px" ,paddingTop: matchesXS ? "5px" : "64px",}}
      >
        <img src={iconPhoneCircle} alt="phone" />
        <Grid
          item
          container
          direction="column"
          className={classes.customerCareContent}
          style={{
            paddingTop: matchesXS ? "24px" : "0px",
            paddingBottom: matchesXS ? "24px" : "0px",
          }}
        >
          <Typography
            className={classes.customerCareTitle}
            align={matchesXS ? "center" : undefined}
          >
            Not sure the health plan to go for?
          </Typography>
          <Typography
            className={classes.customerCareSubtitle}
            align={matchesXS ? "center" : undefined}
          >
            Speak with one of our customer service agents and get the best
            personalised advice
          </Typography>
        </Grid>
        <Button
          variant="contained"
          className={classes.customerCareButton}
          onClick={() => setCallBackModel(!callBackModel)}
        >
          Speak with Customer Service
        </Button>

        <CallBackPopUp
          open={callBackModel}
          close={() => setCallBackModel(!callBackModel)}
        />
      </Grid>
    </>
  );
};

const BasicPlan = (item) => {
  // console.log(item,"item")
  const classes = useStyles();
  const theme = useTheme();

  const [isDailogOpen, setIsDailogOpen] = useState(false);
  const [isDailogOpen1, setIsDailogOpen1] = useState(false);

  const _Package = useSelector((state) => state.packageReducer);
  console.log(_Package.packageData, "dsj");

  const OpenPopup = () => <img src={ChevronRightPack} alt="right arrow" />;

  const DailogOpen = () => {
    setIsDailogOpen(true);
  };
  const DailogClose = () => {
    setIsDailogOpen(false);
  };
  const DailogOpen1 = () => {
    setIsDailogOpen1(true);
  };
  const DailogClose1 = () => {
    setIsDailogOpen1(false);
  };
  const redirecTodmp = (uuid) => {
    window.open(
      process.env.REACT_APP_DMP_SITE_URL + "lab-package-compareprice/" + uuid,
      "_blank"
    );
    
  };

  return (
    <>
      <Dialog
        style={{ borderRadius: "0px" }}
        maxWidth={"sm"}
        fullWidth={true}
        open={isDailogOpen}
        scroll={"body"}
        onClose={DailogClose}
      >
        <BiomarkersPopup
          onClose={() => setIsDailogOpen(false)}
          biomarkers={item.item.biomarks}
          packageType={item.item.package_type}
        />
      </Dialog>

      <Dialog
        style={{ borderRadius: "0px" }}
        maxWidth={"sm"}
        fullWidth={true}
        open={isDailogOpen1}
        scroll={"body"}
        onClose={DailogClose1}
      >
        <InvestigationAreaPopup
          type="InvestigationAreaPopup"
          onClose={() => setIsDailogOpen1(false)}
          InvestigationArea={item.item.investigation_area}
          packageType={item.item.package_type}
        />
      </Dialog>

      {/* <BiomarkersPopup/> */}

      <Grid
        item
        container
        direction="column"
        className={classes.packageContainer}
        alignItems="center"
        xs={12} sm={12} 
       
      >
        <Typography className={classes.packageTitle}>
          {item.item.package_type} plan
        </Typography>
        <Typography className={classes.packageSubTitle}>Starts from</Typography>
        <Typography className={classes.packagePrice}>
          {item.item.min_amount}
        </Typography>
        <Grid
          item
          container
          direction="row"
          style={{ paddingBottom: "16px", paddingLeft: "32px" }}
        >
          <img
            src={CheckIcon}
            alt="check-icon"
            style={{ alignSelf: "flex-start", paddingRight: "12px" }}
          />

          <Typography className={classes.packageBenefits}>
            <span className={classes.packageHighlight}>FREE</span> medical
            report
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          style={{ paddingBottom: "24px", paddingLeft: "32px" }}
        >
          <img
            src={CheckIcon}
            alt="check-icon"
            style={{ alignSelf: "flex-start", paddingRight: "12px" }}
          />

          <Typography className={classes.packageBenefits}>
            Sample Type -{" "}
            <span className={classes.packageHighlight}>
              Blood, Swab & Urine
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.Biomarkers}
            endIcon={<OpenPopup />}
            onClick={DailogOpen}
          >
            Biomarkers ({item.item.no_of_biomarks})
          </Button>
        </Grid>
        <Grid item style={{ marginTop: "8px" }}>
          <Button
            className={classes.Biomarkers}
            endIcon={<OpenPopup />}
            onClick={DailogOpen1}
          >
            Investigation Area ({item.item.no_of_investigation_area})
          </Button>
        </Grid>
        <Button
          variant="contained"
          className={classes.StartButton}
          // component={Link}
          // to=""
          onClick={() => redirecTodmp(item?.item?.uuid)}
        >
          Get started
        </Button>
      </Grid>
    </>
  );
};

const IntermediatePlan = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [isDailogOpen, setIsDailogOpen] = useState(false);
  const [isDailogOpen1, setIsDailogOpen1] = useState(false);

  const OpenPopup = () => <img src={ChevronRightPack} alt="right arrow" />;

  const DailogOpen = () => {
    setIsDailogOpen(true);
  };
  const DailogClose = () => {
    setIsDailogOpen(false);
  };
  const DailogOpen1 = () => {
    setIsDailogOpen1(true);
  };
  const DailogClose1 = () => {
    setIsDailogOpen1(false);
  };
  return (
    <>
      <Dialog
        style={{ borderRadius: "0px" }}
        maxWidth={"sm"}
        fullWidth={true}
        open={isDailogOpen}
        scroll={"body"}
        onClose={DailogClose}
      >
        <BiomarkersPopup onClose={() => setIsDailogOpen(false)} />
      </Dialog>

      <Dialog
        style={{ borderRadius: "0px" }}
        maxWidth={"sm"}
        fullWidth={true}
        open={isDailogOpen1}
        scroll={"body"}
        onClose={DailogClose1}
      >
        <InvestigationAreaPopup onClose={() => setIsDailogOpen1(false)} />
      </Dialog>

      <Grid
        item
        container
        direction="column"
        className={classes.packageContainer}
        alignItems="center"
        width={matchesXS ? "270px" : "380px"}
      >
        <Typography className={classes.packageTitle}>
          Intermediate plan
        </Typography>
        <Typography className={classes.packageSubTitle}>Starts from</Typography>
        <Typography className={classes.packagePrice}>₦23,000</Typography>
        <Grid
          item
          container
          direction="row"
          style={{ paddingBottom: "16px", paddingLeft: "32px" }}
        >
          <img
            src={CheckIcon}
            alt="check-icon"
            style={{ alignSelf: "flex-start", paddingRight: "12px" }}
          />

          <Typography className={classes.packageBenefits}>
            <span className={classes.packageHighlight}>FREE</span> medical
            report
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          style={{ paddingBottom: "24px", paddingLeft: "32px" }}
        >
          <img
            src={CheckIcon}
            alt="check-icon"
            style={{ alignSelf: "flex-start", paddingRight: "12px" }}
          />

          <Typography className={classes.packageBenefits}>
            Sample Type -{" "}
            <span className={classes.packageHighlight}>
              Blood, Swab & Urine
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.Biomarkers}
            endIcon={<OpenPopup />}
            onClick={DailogOpen}
            // style={{width: matchesSM ? "250px" : "350px",}}
          >
            Biomarkers ({item.item.no_of_biomarks})
          </Button>
        </Grid>
        <Grid item style={{ marginTop: "8px" }}>
          <Button
            className={classes.Biomarkers}
            endIcon={<OpenPopup />}
            onClick={DailogOpen1}
          >
            Investigation Area ({item.item.no_of_investigation_area})
          </Button>
        </Grid>
        <Button
          variant="contained"
          className={classes.StartButton}
          component={Link}
          to=""
        >
          Get started
        </Button>
      </Grid>
    </>
  );
};

export default LabPackage;
