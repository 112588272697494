import React from 'react'
import {
    Grid,
    Button,
    FormControl,
    Select,
    MenuItem,
} from "@material-ui/core";
import team1 from "../../assets/images/team1.png";
import team2 from "../../assets/images/team2.png";
import team3 from "../../assets/images/team3.png";
import team4 from "../../assets/images/team4.png";
import team5 from "../../assets/images/team5.png";
import Map from "./Map";
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';


function CoverageAreas(props) {
    const [Partners, setPartners] = React.useState('10');
    const [State, setState] = React.useState('10');
    const [Branch, setBranch] = React.useState('10');

    const PartnersChange = (event) => {
        setPartners(event.target.value);

    };
    const StateChange = (event) => {
        setState(event.target.value);

    };
    const BranchChange = (event) => {
        setBranch(event.target.value);

    };
    
    return (
        <>
            <Header />
            <div className='CoverageArea'>
                <div className='banner'>
                    <div className='banner_txt'>
                        <p style={{ color: "#FB6514", fontWeight: 600, fontSize: "16px" }} className="para">Coverage Areas</p>
                        <h1 className='banner_headding'>Quality healthcare is in your city</h1>
                       

                    </div>

                </div>
                <div className='map'>
                    <Map
                      
                        center={{ lat: 40.7484, lng: -73.9857 }}
                        height="300px"
                        zoom={15}
                    />
                </div>

                <div className='fined_container'>

                    <Grid container spacing={0}>
                        <Grid md={6}>
                            <div className="fined_container_text">
                                <h1 className='newletter_heading'>Find the nearest location of our partners</h1>
                                <p className='newsletter_para' >You can see the spread of all our partner services in Nigeria at a glance
                                </p>


                            </div>
                        </Grid>



                        <Grid sm={12} md={6}>

                            <div className='form_container'>
                                <div className='newsletter_form'>
                                    <div className='newsletter_form_items'>

                                        <form action="" >
                                            <FormControl fullWidth>
                                                <label for="fname" className='label'>Partners</label>
                                                <Select
                                                    className='input_box'
                                                    disableUnderline={true}
                                                    labelId="demo-simple-select-label"
                                                    id="1"
                                                    value={Partners}
                                                    label="Age"
                                                    onChange={PartnersChange}
                                                >
                                                    <MenuItem value={10}>HealthPlus</MenuItem>
                                                    <MenuItem value={20}>HealthPlus1</MenuItem>
                                                    <MenuItem value={30}>HealthPlus2</MenuItem>
                                                </Select>
                                                <label for="lname" className='label'  >State</label>
                                                <Select
                                                    className='input_box'
                                                    disableUnderline={true}
                                                    labelId="demo-simple-select-label"
                                                    id="2"
                                                    value={State}
                                                    label="Age"
                                                    onChange={StateChange}
                                                >
                                                    <MenuItem value={10}>Lagos</MenuItem>
                                                    <MenuItem value={20}>Lagos1</MenuItem>
                                                    <MenuItem value={30}>Lagos2</MenuItem>
                                                </Select>

                                                <label for="fname" className='label'>Branch</label>
                                                <Select
                                                    className='input_box'
                                                    disableUnderline={true}
                                                 
                                                    id="3"
                                                    value={Branch}
                                                    label="Age"
                                                    onChange={BranchChange}
                                                >
                                                    <MenuItem value={10}>Lekki</MenuItem>
                                                    <MenuItem value={20}>Lekki1</MenuItem>
                                                    <MenuItem value={30}>Lekki2</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <button type="submit" value="Submit" className='newsletter_btn' >See Coverage Area</button>

                                        </form>


                                    </div>


                                </div>

                            </div>
                        </Grid>
                    </Grid>


                </div>

                <div className='proactive_container'>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className='team_text'>
                                <h1 className='team_heading'>Be proactive about your health.</h1>
                                <p style={{ color: "#475467", fontWeight: 900, fontSize: "20px", marginTop: "3%", fontFamily: "Visuelt Pro" }}>Get weekly health updates, tips and other helpful information from Doctall</p>

                                <p style={{ fontSize: "20px", marginTop: "3%", width: "100%", fontFamily: "Visuelt Pro" }}>Together, we can achieve a healthier society for all
                                    Contact us at <span style={{ color: "#FF5C3C", fontWeight: 600, fontFamily: "Visuelt Pro" }}><a href='mailto:support@doctall.com'>support@doctall.com</a></span></p>
                                <Button style={{ backgroundColor: "#C53B20", color: "#fff", fontSize: "14px", marginTop: "10px", padding: "10px 20px", marginTop: "5%", fontFamily: "Visuelt Pro" }} variant="contained">Stay informed</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>

                            <Grid container spacing={0}>
                                <Grid xs={6} sm={6} md={6}>
                                    <img src={team1} alt="Girl in a jacket" style={{ float: "right", paddingTop: "85px", paddingRight: "5px", paddingLeft: "5px" }} />
                                </Grid>
                                <Grid xs={6} sm={6} md={6}>
                                    <img src={team2} alt="Girl in a jacket" style={{ padding: "5px" }} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={0}>
                                <Grid md={1}></Grid>
                                <Grid xs={4} sm={4} md={4}>
                                    <img src={team3} alt="Girl in a jacket" style={{ float: "right", padding: "5px" }} />
                                </Grid>
                                <Grid xs={3} sm={3} md={3}>
                                    <img src={team4} alt="Girl in a jacket" className='team4' style={{ padding: "5px" }} />
                                </Grid>
                                <Grid xs={4} sm={4} md={4}>
                                    <img src={team5} alt="Girl in a jacket" className='team5' />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>


                </div>
            </div>
            <Footer />
        </>
    )
}

export default CoverageAreas