import React, { useEffect, useState } from "react";
import {
  Grid,
  useMediaQuery,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  Button,
  Popover,
  Popper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "../styles";
import symptomsBullet from "../../../../assets/images/symptoms-bullet.svg";
import GreenTick from "../../../../assets/images/GreenTick.svg";
import downArrow from "../../../../assets/images/downArrow.svg";
import listmarker from "../../../../assets/images/listmarker.svg";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => styles(theme));
const BioMarker = ({ children, full }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log("TPlan", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        container
        justifyContent="center"
        disableRipple
        disableFocusRipple
        style={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "20px",
          fontFamily: "Visuelt Pro",
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          cursor: "pointer",
          position: "relative",
          display: "visible",
          textTransform: "none",
        }}
        onClick={handleClick}
      >
        {children}

        <img src={downArrow} alt="downArrow" style={{ marginLeft: "12px" }} />
      </Button>
      <Menu
        id="basic-menu"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          marginTop: "50px",
          boxShadow: "0px 2px 5px rgba(11, 59, 60, 0.14)",
          borderRadius: "8px",
        }}
      >
        {console.log("Tplan", anchorEl)}
        <MenuItem onClick={handleClose}>
          <Grid
            container
            direction="column"
            style={{
              width: "286px",
              height: "auto",
            }}
          >
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "Inter",
                marginBottom: "22px",
              }}
            >
              Biomarkers
            </Typography>
            <ul>
              <li style={{ listStyle: "none", marginBottom: "16px" }}>
                <img src={listmarker} alt="marker" />
                <span className={classes.biomarkerList}>HBA1C</span>
              </li>
              <li style={{ listStyle: "none", marginBottom: "16px" }}>
                <img src={listmarker} alt="marker" />
                <span className={classes.biomarkerList}>
                  Fasting Blood Sugar
                </span>
              </li>
              <li style={{ listStyle: "none", marginBottom: "16px" }}>
                <img src={listmarker} alt="marker" />
                <span className={classes.biomarkerList}>
                  Post Prandial Blood Sugar
                </span>
              </li>
              <li style={{ listStyle: "none", marginBottom: "16px" }}>
                <img src={listmarker} alt="marker" />
                <span className={classes.biomarkerList}>
                  Micro Albumin Spot Urine
                </span>
              </li>
              <li style={{ listStyle: "none", marginBottom: "16px" }}>
                <img src={listmarker} alt="marker" />
                <span className={classes.biomarkerList}>Creatinine Serum</span>
              </li>
              {full ? (
                <li style={{ listStyle: "none", marginBottom: "16px" }}>
                  <img src={listmarker} alt="marker" />
                  <span className={classes.biomarkerList}>Urea Serum</span>
                </li>
              ) : null}
              {full ? (
                <li style={{ listStyle: "none", marginBottom: "16px" }}>
                  <img src={listmarker} alt="marker" />
                  <span className={classes.biomarkerList}>Lipid Profile</span>
                </li>
              ) : null}
              <li style={{ listStyle: "none", marginBottom: "16px" }}>
                <img src={listmarker} alt="marker" />
                <span className={classes.biomarkerList}>Full Blood Count</span>
              </li>
              {full ? (
                <li style={{ listStyle: "none", marginBottom: "16px" }}>
                  <img src={listmarker} alt="marker" />
                  <span className={classes.biomarkerList}>
                    Complete Urine Exam
                  </span>
                </li>
              ) : null}
            </ul>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
};
const PlanYes = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const[plan, setPlan] = useState([])
  const navigate = useNavigate()

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log("TPlan", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    width: "275.5px",
    height: "64px",
    padding: "0px",
    [`&.${tableCellClasses.head}`]: {
      color: "#101828",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const THeading = styled(Typography)(({ theme }) => ({
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    fontFamily: "Visuelt Pro",
  }));
  const TCategory = styled(Typography)(({ theme }) => ({
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "Visuelt Pro",
  }));
  const TData = styled(Typography)(({ theme }) => ({
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "Visuelt Pro",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    color: "#002931",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F9FAFB",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  function createData(category, Basic, Advance, Ultimate) {
    return { category, Basic, Advance, Ultimate };
  }

  const rows = [
    createData(
      "Screening (+ Home sample collection)",

      <>
        <BioMarker full>
          <TData>Once - 9 Biomarkers</TData>
        </BioMarker>
      </>,

      <>
        <BioMarker full>
          <TData>Once - 9 Biomarkers</TData>
        </BioMarker>
      </>,
      <>
        <BioMarker full>
          <TData>Once - 9 Biomarkers</TData>
        </BioMarker>
      </>
    ),
    createData(
      "Medical Report",
      <>
        Yes <img src={GreenTick} alt="tick" style={{ marginLeft: "10px" }} />
      </>,
      <>
        Yes <img src={GreenTick} alt="tick" style={{ marginLeft: "10px" }} />
      </>,
      <>
        Yes <img src={GreenTick} alt="tick" style={{ marginLeft: "10px" }} />
      </>
    ),
    createData(
      "Blood Test (+ Home sample collection)",
      <>
        <BioMarker>
          <TData>Once - 6 Biomarkers</TData>
        </BioMarker>
      </>,
      <>
        <BioMarker>
          <TData>Twice - 6 Biomarkers</TData>
        </BioMarker>
      </>,
      <>
        <BioMarker>
          <TData>Thrice - 6 Biomarkers</TData>
        </BioMarker>
      </>
    ),
    createData(
      "Nurse Monitoring",
      "Once  a week - 11 times",
      "Twice  a week - 22 times",
      "4 times a week - 44 times"
    ),
    createData(
      "General Practioner Consulting",
      "2 times",
      "4 times",
      "6 times"
    ),
    createData("Endocrinologist Consultation", "2 times", "3 times", "4 times"),
    createData("Fitness session", "Once", "6 Times", "13 Times"),
    createData("Nutrionist consultation", "Once", "3 Times", "6 Times"),
    createData("Psychologist", "-", "-", "Once"),
  ];
  const BuyNow = (item) => {
  console.log(item, "itemsdsd")
  navigate("/checkout", {
    state: {
      planDetails: item,
      disease: item.diseases_name,
      // uuidDaata : state.uuidDaata
    },
  });
}
const DiabetesApi = () => {
  axios.get(`${process.env.REACT_APP_API_BASE_URL}doctallnew/diabetestreatmentplan/plan/list`)
  .then((res) => {
    console.log(res,"err")
    setPlan(res.data.data)
  })
  .catch((err) => {
    console.log(err,"err")
  })
}
useEffect(() => {
  DiabetesApi()
}, [])
  return (
    <Grid container>
      <Grid item container className={classes.beneCont}>
        <Grid item container lg={6} direction="column">
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "30px",
              lineHeight: "38px",
              fontFamily: "Visuelt Pro",
            }}
          >
            100 Days Treatment Plan
          </Typography>
          <hr
            style={{
              width: "71px",
              border: "2px solid #FF8D77",
              borderRadius: "60px",
            }}
          />
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: matchesSM ? "16px" : "18px",
              lineHeight: "32px",
              fontFamily: "Visuelt Pro",
              marginTop: "34px",
              width: matchesSM ? "330px" : "494px",
            }}
          >
            The Diabetes 100 Day Plan provides a unique holistic approach to
            managing the symptoms and triggers of type 2 diabetes, and has been
            designed to help each patient individually better control and manage
            their personal circumstances and decision-making to improve how they
            live with their diabetes
          </Typography>
        </Grid>
        <Grid item container lg={6} direction="column">
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "30px",
              lineHeight: "38px",
              fontFamily: "Visuelt Pro",
            }}
          >
            Benefits You Get
          </Typography>
          <hr
            style={{
              width: "71px",
              border: "2px solid #FF8D77",
              borderRadius: "60px",
            }}
          />
          <Grid
            item
            container
            direction="column"
            alignItems={matchesSM ? "left" : undefined}
            style={{
              maxWidth: matchesXS ? "230px" : "460px",
              height: matchesSM ? (matchesXS ? "400px" : "360px") : "200px",
              columnGap: "20px",
              marginTop: "34px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "28px",
                color: " #002931",
                paddingBottom: "16px",
                position: "relative",
              }}
            >
              <img
                src={symptomsBullet}
                alt="list"
                style={{ marginRight: "9px" }}
              />
              Personalized plan
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "28px",
                color: " #002931",
                paddingBottom: "16px",

                position: "relative",
              }}
            >
              <img
                src={symptomsBullet}
                alt="list"
                style={{ marginRight: "9px" }}
              />
              Continuous care
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "28px",
                color: " #002931",
                paddingBottom: "16px",

                position: "relative",
              }}
            >
              <img
                src={symptomsBullet}
                alt="list"
                style={{ marginRight: "9px" }}
              />
              Frequent monitoring
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "28px",
                color: " #002931",
                paddingBottom: "16px",

                position: "relative",
              }}
            >
              <img
                src={symptomsBullet}
                alt="list"
                style={{ marginRight: "9px" }}
              />
              Progress checks
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "28px",
                color: " #002931",
                paddingBottom: "16px",

                position: "relative",
              }}
            >
              <img
                src={symptomsBullet}
                alt="list"
                style={{ marginRight: "9px" }}
              />
              Consultation from best Doctors
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "28px",
                color: " #002931",
                paddingBottom: "16px",

                position: "relative",
              }}
            >
              <img
                src={symptomsBullet}
                alt="list"
                style={{ marginRight: "9px" }}
              />
              Easy fitness regimen
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "28px",
                color: " #002931",
                paddingBottom: "16px",

                position: "relative",
              }}
            >
              <img
                src={symptomsBullet}
                alt="list"
                style={{ marginRight: "9px" }}
              />
              Simple diet and much more
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        className={classes.tPlanTableCon}
      >
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "30px",
            lineHeight: "38px",
            color: "#101828",
          }}
        >
          Compare our plans and find the one that works for you
        </Typography>
        <TableContainer
          component={Paper}
          style={{ boxShadow: "none", marginTop: "32px" }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <hr
                    style={{
                      width: "275.5px",
                      border: "1px solid #E4E7EC",
                      marginTop: "16px",
                    }}
                  />
                  <Grid style={{ height: "232px", marginTop: "-30px" }}></Grid>
                </StyledTableCell>
                {plan.map((item, index) => {
                  return index < 3 && <StyledTableCell align="center">
                   <Grid container direction="column">
                     <THeading>{item.plan_name}{item.plan_name === "Advance" &&                          
                      <Typography
                            style={{
                              background: "#FFF0ED",
                              borderRadius: "16px",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#FF5C3C",
                              width: "72px",
                              height: "24px",
                              padding: "2px 10px",
                              alignSelf: "right",
                              marginLeft: '201px',
                              marginTop: '-28px'
                            }}
                          >
                            Popular
                          </Typography>}
                          </THeading>

                     <hr
                       style={{
                         width: "275.5px",
                         border: "1px solid #E4E7EC",
                         marginTop: "16px",
                       }}
                     />
                     <Grid
                       container
                       direction="column"
                       style={{
                         padding: "32px 24px 0px",
                         width: "275.5px",
                         height: "232px",
                       }}
                     >
                       <Grid item>
                         <Typography
                           style={{
                             fontFamily: "Visuelt Pro",
                             fontStyle: "normal",
                             fontWeight: "900",
                             fontSize: "32px",
                             lineHeight: "44px",
                             color: "#101828",
                             letterSpacing: "-0.02em",
                           }}
                         >
                           ₦ {item.price}
                           <span
                             style={{
                               fontFamily: "Visuelt Pro",
                               fontStyle: "normal",
                               fontWeight: "500",
                               fontSize: "16px",
                               lineHeight: "24px",
                               color: "#002931",
                             }}
                           >
                             /  100 Days
                             {/* {item.short_info} */}
                           </span>
                         </Typography>
                       </Grid>
                       <button className={classes.buyTButton} onClick={() => BuyNow(item)}>
                         Buy Treatment Plan
                       </button>
                     </Grid>
                   </Grid>
                 </StyledTableCell>
                })}
                {/* <StyledTableCell align="center">
                  <Grid container direction="column">
                    <THeading>Basic</THeading>
                    <hr
                      style={{
                        width: "275.5px",
                        border: "1px solid #E4E7EC",
                        marginTop: "16px",
                      }}
                    />
                    <Grid
                      container
                      direction="column"
                      style={{
                        padding: "32px 24px 0px",
                        width: "275.5px",
                        height: "232px",
                      }}
                    >
                      <Grid item>
                        <Typography
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "900",
                            fontSize: "32px",
                            lineHeight: "44px",
                            color: "#101828",
                            letterSpacing: "-0.02em",
                          }}
                        >
                          ₦ 100,000.00
                          <span
                            style={{
                              fontFamily: "Visuelt Pro",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#002931",
                            }}
                          >
                            / 100 Days
                          </span>
                        </Typography>
                      </Grid>
                      <button className={classes.buyTButton} onClick={BuyNow}>
                        Buy Treatment Plan
                      </button>
                    </Grid>
                  </Grid>
                </StyledTableCell> */}
                {/* <StyledTableCell align="center">
                  <Grid container direction="column">
                    <THeading>Advance</THeading>
                    <hr
                      style={{
                        width: "275.5px",
                        border: "1px solid #E4E7EC",
                        marginTop: "16px",
                      }}
                    />
                    <Grid
                      container
                      direction="column"
                      style={{
                        padding: "32px 24px 0px",
                        width: "275.5px",
                        height: "232px",
                      }}
                    >
                      <Grid item>
                        <Typography
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "900",
                            fontSize: "32px",
                            lineHeight: "44px",
                            color: "#101828",
                            letterSpacing: "-0.02em",
                          }}
                        >
                          ₦ 180,000.00
                          <span
                            style={{
                              fontFamily: "Visuelt Pro",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#002931",
                            }}
                          >
                            / 100 Days
                          </span>
                        </Typography>
                      </Grid>
                      <button className={classes.buyTButton}>
                        Buy Treatment Plan
                      </button>
                    </Grid>
                  </Grid>
                </StyledTableCell> */}
                {/* <StyledTableCell align="center">
                  <Grid container direction="column">
                    <THeading>Ultimate</THeading>
                    <hr
                      style={{
                        width: "275.5px",
                        border: "1px solid #E4E7EC",
                        marginTop: "16px",
                      }}
                    />
                    <Grid
                      container
                      direction="column"
                      style={{
                        padding: "32px 24px 0px",
                        width: "275.5px",
                        height: "232px",
                      }}
                    >
                      <Grid item container>
                        <Typography
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "900",
                            fontSize: "32px",
                            lineHeight: "44px",
                            color: "#101828",
                            letterSpacing: "-0.02em",
                          }}
                        >
                          ₦ 320,000.00
                          <span
                            style={{
                              fontFamily: "Visuelt Pro",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#002931",
                            }}
                          >
                            / 100 Days
                          </span>
                        </Typography>
                      </Grid>
                      <button className={classes.buyTButton}>
                        Buy Treatment Plan
                      </button>
                    </Grid>
                  </Grid>
                </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody style={{ paddingTop: "68px" }}>
              {rows.map((row) => (
                <StyledTableRow key={row.category}>
                  <StyledTableCell component="th" scope="row">
                    <TCategory>{row.category}</TCategory>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TData>{row.Basic}</TData>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TData>{row.Advance}</TData>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TData>{row.Ultimate}</TData>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PlanYes;
