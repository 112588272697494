import {
  Button, FormControl, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Typography,
  useMediaQuery, useTheme
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertClose from "../../../assets/images/AlertClose.svg";
import CallBackPopUp from "../../callbackPopUp/CallBackPopUP";
import styles from "../styles";
import "../styles.js";
import { Alert, NeutralComp, NoComp, YesComp } from "../util/UiComp";
import { saveQuestionnaire } from "../../../services/category-services/categoryServices";

const useStyles = makeStyles((theme) => styles(theme));
let uuid ;
export const AllergicDrugs = ({ drugs, stepUpdate, handleChangeDrug, sav, disease_uuid, disease_name, uuidata  }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const [drugState, setDrugState] = useState([]);
  const [seeDoctor, setSeeDoctor] = useState(false);
  const [callBackModel, setCallBackModel] = useState(false);
  const [value, setValue] = React.useState("");

  const focusPoint = useRef(null);


  const saveQuestions = async () => {
    try {
      var payLoad;

          payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            // uuid: uuidDaata,
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };

          let response = await saveQuestionnaire(payLoad);
          if ((response && response.status === 200) || response.status === 201) {
            console.log(response.data.uuid,"Saved");

            uuid = response.data.uuid;
            () => uuidata(uuid)
            // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
          }
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        saveQuestions()
        uuidata(uuid)
      },[uuidata])

  useEffect(() => {
    if (seeDoctor) {
      window.scrollTo(0, focusPoint.current.offsetTop);
    }
  }, [seeDoctor]);


  useEffect(() => {
    const drugList = [];
    drugs.drugs.map((drugIn) => {
      drugList.push({ drugname: drugIn, value: "" });
    });
    setDrugState(drugList);
  }, [drugs]);


  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleValueChange = (drugname, val) => {
    setSeeDoctor(false);
    const indexToUpdate = drugState.findIndex((ds) => ds.drugname === drugname);
    const updatedState = [...drugState];
    let complete = true;
    updatedState[indexToUpdate].value = val;
    setDrugState(updatedState);
    drugState.map((drug) => {
      if (drug.value === "Yes") {
        setSeeDoctor(true);
      }
      if (drug.value === "Yes" || drug.value === "") {
        complete = false;
      }
    });

    if (complete === true) {
      handleChangeDrug(drugState);
      stepUpdate(4);
    }
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "40px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{ paddingBottom: "46px", fontSize: "30px", lineHeight: "38px" }}
      >
        {drugs.title}
      </Typography>
      {drugState.map((drug) => (
        <Grid item>
          <Grid
            item
            container
            direction={matchesSM ? "column" : "row"}
            style={{
              paddingBottom: "20px",
              width: matchesSM ? "350px" : "776px",
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "24px",
                  lineHeight: "32px",
                  color: "#002931",
                }}
              >
                {drug.drugname}
              </Typography>
            </Grid>
            <Grid item>
              <FormControl>
                <RadioGroup
                  aria-labelledby={drug}
                  name={drug}
                  value={value}
                  onChange={handleChange}
                  style={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    value={`${drug.drugname} Yes`}
                    control={
                      <Radio
                        checked={drug.value === "Yes"}
                        onChange={(e) => {
                          handleValueChange(drug.drugname, "Yes");
                        }}
                        name={drug}
                        inputProps={{ "aria-label": "No" }}
                        icon={<NeutralComp value="Yes" />}
                        checkedIcon={<YesComp />}
                        disableFocusRipple
                        disableTouchRipple
                        disableRipple
                        aria-label={drug}
                      />
                    }
                  />
                  <FormControlLabel
                    value={`${drug.drugname} No`}
                    control={
                      <Radio
                        onChange={(e) => {
                          handleValueChange(drug.drugname, "No");
                        }}
                        checked={drug.value === "No"}
                        name={drug}
                        inputProps={{ "aria-label": "No" }}
                        icon={<NeutralComp value="No" />}
                        checkedIcon={<NoComp />}
                        disableFocusRipple
                        disableTouchRipple
                        disableRipple
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {seeDoctor ? (
        <Alert>
          <img src={AlertClose} alt="Close" style={{ paddingTop: "16px" }} />
          <Typography
            style={{
              paddingTop: "24px",
              paddingBottom: "8px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#002931",
            }}
          >
            Not suitable for Rx
          </Typography>
          <Typography
            align="center"
            style={{
              width: matchesSM ? "250px" : "421px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#002931",
              paddingBottom: "24px",
            }}
            ref={focusPoint}
          >
            Based on your answer, you are not suitable for Rx. Change your answer
            or kindly click on the button and talk to a doctor.
          </Typography>
          <Button className={classes.talkToADoctorButton} onClick={() => setCallBackModel(!callBackModel)}>
            TALK TO A DOCTOR
          </Button>
        </Alert>
      ) : null}

      <CallBackPopUp
        open={callBackModel}
        close={() => setCallBackModel(!callBackModel)}
      />
    </Grid>
  );
};

export const ImapirmentQuestion = ({ ques, stepUpdate, step, handleImapirment, sav, disease_uuid, disease_name, uuidata }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [value, setValue] = React.useState("");

  const [question, setQuestion] = useState({ question: "", value: "" });
  const [seeDoctor, setSeeDoctor] = useState(false);
  const [callBackModel, setCallBackModel] = useState(false);

  const focusPoint = useRef(null);

  const saveQuestions = async () => {
    try {
          let payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            uuid : uuid,
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };
          let response = await saveQuestionnaire(payLoad);
          if ((response && response.status === 200) || response.status === 201) {
            console.log(response.data.uuid,"Saved");
            uuidata(response.data.uuid)
            uuid = response.data.uuid;
            // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
          }
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        saveQuestions()
        uuidata(uuid)
      },[uuid])

  useEffect(() => {
    if (seeDoctor) {
      window.scrollTo(0, focusPoint.current.offsetTop);
    }
  }, [seeDoctor]);



  useEffect(() => {
    setQuestion({ ...question, question: ques.title });
  }, [ques]);

  const handleChange = (event) => {
    setValue(event.target.value);

  };
  const handleValueChange = (e) => {
 
    setQuestion({ ...question, value: e.target.value });
    if (e.target.value === "Yes") {
      setSeeDoctor(true);
    } else {
      setSeeDoctor(false);
      handleImapirment({ ...question, value: e.target.value })
      stepUpdate(step + 1);
    }
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "104px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{ paddingBottom: "46px", fontSize: "30px", lineHeight: "38px" }}
      >
        {ques.title}
      </Typography>
      <Grid item>
        <FormControl>
          <RadioGroup
            aria-labelledby="Impairment"
            name="Impairment"
            value={value}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="Yes"
              control={
                <Radio
                  onChange={handleValueChange}
                  icon={<NeutralComp value="Yes" />}
                  checkedIcon={<YesComp />}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
            />
            <FormControlLabel
              value="No"
              control={
                <Radio
                  onChange={handleValueChange}
                  icon={<NeutralComp value="No" />}
                  checkedIcon={<NoComp />}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {seeDoctor ? (
        <Alert>
          <img src={AlertClose} alt="Close" style={{ paddingTop: "16px" }} />
          <Typography
            style={{
              paddingTop: "24px",
              paddingBottom: "8px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#002931",
            }}
          >
            Not suitable for Rx
          </Typography>
          <Typography
            ref={focusPoint}
            align="center"
            style={{
              width: matchesSM ? "250px" : "421px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#002931",
              paddingBottom: "24px",
            }}
          >
            Based on your answer, you are not suitable for Rx. Change your answer
            or kindly click on the button and talk to a General Practioner.
          </Typography>
          <Button className={classes.talkToADoctorButton} onClick={() => setCallBackModel(!callBackModel)}>
            TALK TO A GENERAL PRACTIONER
          </Button>
        </Alert>
      ) : null}
      <CallBackPopUp
        open={callBackModel}
        close={() => setCallBackModel(!callBackModel)}
      />
    </Grid>
  );
};

export const AnemiaQuestion = ({ ques, stepUpdate, step, handleAnemia, isAnimiaConditionAccepted,  sav, disease_uuid, disease_name, uuidata, uuidDaata }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const focusPoint = useRef(null);

  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [value, setValue] = React.useState("");

  const [question, setQuestion] = useState({ question: "", value: "" });
  const [seeDoctor, setSeeDoctor] = useState(false);
  const [alertInput, setAlertInput] = useState("");
  const [callBackModel, setCallBackModel] = useState(false);

  const saveQuestions = async () => {
    try {
          let payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            uuid : uuid,
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };
          let response = await saveQuestionnaire(payLoad);
          if ((response && response.status === 200) || response.status === 201) {
            console.log(response.data.uuid,"Saved");
            // uuidata(response.data.uuid)
            // uuid = response.data.uuid;
            // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
          }
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        saveQuestions()
      },[])

  useEffect(() => {
    if (seeDoctor) {
      window.scrollTo(0, focusPoint.current.offsetTop);
    }
  }, [seeDoctor]);



  useEffect(() => {
    setQuestion({ ...question, question: ques.title });
  }, [ques]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleValueChange = (e) => {
    console.log(e.target.value, "sdfdf");
    setQuestion({ ...question, value: e.target.value });
    if (e.target.value === "Yes") {
      setSeeDoctor(true);
    } else {
      setSeeDoctor(false);
      handleAnemia({ ...question, value: e.target.value })
      stepUpdate(step + 1);
    }
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "104px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{ paddingBottom: "46px", fontSize: "30px", lineHeight: "38px" }}
      >
        {ques.title}
      </Typography>
      <Grid item>
        <FormControl>
          <RadioGroup
            aria-labelledby="Impairment"
            name="Impairment"
            value={value}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="Yes"
              control={
                <Radio
                  onChange={handleValueChange}
                  icon={<NeutralComp value="Yes" />}
                  checkedIcon={<YesComp />}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
            />
            <FormControlLabel
              value="No"
              control={
                <Radio
                  onChange={handleValueChange}
                  icon={<NeutralComp value="No" />}
                  checkedIcon={<NoComp />}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {seeDoctor ? (
        <Alert>
          <Typography
            style={{
              paddingTop: "52px",
              paddingBottom: "8px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#002931",
              width: matchesSM ? "250px" : "526px",
            }}
            align="center"
          >
            Sickle cell anemia, myeloma or leukemia may predispose to PRIAPISM
            (Prolonged <br /> errection)
          </Typography>
          <Grid
            item
            container
            style={{
              width: matchesSM ? "330px" : "588px",
              background: "#ffffff",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              borderRadius: "8px",
              padding: matchesSM ? "16px 16px" : "16px 40px",
            }}
          >
            <Grid>
              <input
                type="checkbox"
                className={classes.inputCheck}
                onChange={() => {
                  setAlertInput("Yes");
                  isAnimiaConditionAccepted(true);
                  stepUpdate(step + 1);
                }}
                checked={alertInput === "Yes"}
              />
            </Grid>
            <Grid style={{ marginLeft: "16px" }}>
              <Typography
                ref={focusPoint}
                // htmlFor="name"
                align="left"
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#002931",
                  width: matchesSM ? "200px" : "475px",
                }}
              >
                I accept the risk that Sickle cell anemia, myeloma or leukemia
                may predispose to PRIAPISM (Prolonged errection)
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            style={{
              width: matchesSM ? "330px" : "588px",
              background: "#ffffff",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              borderRadius: "8px",
              padding: matchesSM ? "16px 16px" : "16px 40px",
              marginTop: "24px",
            }}
          >
            <Grid>
              <input
                type="checkbox"
                className={classes.inputCheck}
                onChange={() => {
                  setAlertInput("No");
                  navigate('/')
                }}
                checked={alertInput === "No"}
              />
            </Grid>
            <Grid style={{ marginLeft: "16px" }}>
              <Typography
                // htmlFor="name"
                align="left"
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#002931",
                  width: matchesSM ? "200px" : "475px",
                }}
              >
                I do not accept the risk and do not wish to proceed
              </Typography>
            </Grid>
          </Grid>
        </Alert>
      ) : null}
    </Grid>
  );
};

export const MedicalConditions = ({ condition, stepUpdate, step, saveData, sav, disease_name, disease_uuid, uuidDaata }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState("");
  const [conValue, setConValue] = React.useState([]);
  const [seeDoctor, setSeeDoctor] = useState(false);
  const [callBackModel, setCallBackModel] = useState(false);

  const focusPoint = useRef(null);

  const saveQuestions = async () => {
    try {
          let payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            uuid : uuidDaata,
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };
          let response = await saveQuestionnaire(payLoad);
          if ((response && response.status === 200) || response.status === 201) {
            console.log(response.data.uuid,"Saved");
            // uuidata(response.data.uuid)
            // uuid = response.data.uuid;
            // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
          }
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        saveQuestions()
      },[])


  useEffect(() => {
    if (seeDoctor) {
      window.scrollTo(0, focusPoint.current.offsetTop);
    }
  }, [seeDoctor]);


  useEffect(() => {
    const conList = [];
    condition.questions.map((ques) => {
      conList.push({ question: ques, value: "" });
    });
    setConValue(conList);
  }, [condition]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleValueChange = (question, val) => {
    setSeeDoctor(false);
    const indexToUpdate = conValue.findIndex((ds) => ds.question === question);
    const updatedState = [...conValue];
    let complete = true;
    updatedState[indexToUpdate].value = val;
    setConValue(updatedState);

    conValue.map((drug) => {
      if (drug.value === "Yes") {
        setSeeDoctor(true);
      }
      if (drug.value === "Yes" || drug.value === "") {
        complete = false;
      }
    });

    if (complete === true) {
      saveData(conValue)
      stepUpdate(step + 1);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "40px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{ paddingBottom: "46px", fontSize: "30px", lineHeight: "38px" }}
      >
        {condition.title}
      </Typography>
      {conValue.map((ques) => (
        <Grid item>
          <Grid
            item
            container
            direction={matchesSM ? "column" : "row"}
            style={{
              paddingBottom: "20px",
              width: matchesSM ? "350px" : "776px",
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: matchesSM ? "16px" : "24px",
                  lineHeight: "32px",
                  color: "#002931",
                  width: matchesSM ? "350px" : "409px",
                }}
              >
                {ques.question}
              </Typography>
            </Grid>
            <Grid item>
              <FormControl>
                <RadioGroup
                  aria-labelledby="Impairment"
                  name="Impairment"
                  value={value}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value={`${ques.question} Yes`}
                    control={
                      <Radio
                        checked={ques.value === "Yes"}
                        onChange={(e) => {
                          handleValueChange(ques.question, "Yes");
                        }}
                        icon={<NeutralComp value="Yes" />}
                        checkedIcon={<YesComp />}
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      />
                    }
                  />
                  <FormControlLabel
                    value={`${ques.question} No`}
                    control={
                      <Radio
                        checked={ques.value === "No"}
                        onChange={(e) => {
                          handleValueChange(ques.question, "No");
                        }}
                        icon={<NeutralComp value="No" />}
                        checkedIcon={<NoComp />}
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {seeDoctor ? (
        <Alert>
          <img src={AlertClose} alt="Close" style={{ paddingTop: "16px" }} />
          <Typography
            style={{
              paddingTop: "24px",
              paddingBottom: "8px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#002931",
            }}
          >
            Not suitable for treatment
          </Typography>
          <Typography
            align="center"
            style={{
              width: matchesSM ? "250px" : "421px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#002931",
              paddingBottom: "24px",
            }}
            ref={focusPoint}
          >
            Based on your answer, you are not suitable for Rx. Change your answer
            or kindly click on the button and talk to a docgotor.
          </Typography>
          <Button className={classes.talkToADoctorButton} onClick={() => setCallBackModel(!callBackModel)}>
            TALK TO A DOCTOR
          </Button>
        </Alert>
      ) : null}
    <CallBackPopUp
        open={callBackModel}
        close={() => setCallBackModel(!callBackModel)}
      />
    </Grid>
  );
};
