import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { fetchArea, fetchCitys } from '../../services/locationsServices/locationServices';
import actions from '../actions';

const { locationsActions } = actions;

export function* fetchCities() {
    yield takeEvery(locationsActions.GET_CITY_LIST, function* () {
        try {
            const response = yield fetchCitys();
            if (response.status === 200 || response.status === 201) {
                yield put({
                    type: locationsActions.GET_CITY_LIST_SUCCESS,
                    citiesData: response.data
                });
            } else {
                yield put({
                    areasData: []
                });
                throw response;
            }
        } catch (error) {
           
            yield put({
                areasData: []
            });
        }
    });
}

export function* fetchAreas() {
    yield takeEvery(locationsActions.GET_AREA_LIST, function* ({ city_id }) {
        try {
            const response = yield fetchArea(city_id);
            if (response.status === 200) {
                yield put({
                    type: locationsActions.GET_AREA_LIST_SUCCESS,
                    areasData: response.data
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}


export default function* rootSaga() {
    yield all([fork(fetchCities), fork(fetchAreas)]);
}