import CryptoJS from "crypto-js";

export const encrypt = (data) => {
  try {
    return CryptoJS.AES.encrypt(
      data,
      process.env.REACT_APP_SECRET_KEY
    ).toString();
  } catch (error) {
    console.error(error);
  }
}

export const decrypt = (encrypteddata) => {
  try {
    return CryptoJS.AES.decrypt(
      encrypteddata,
      process.env.REACT_APP_SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error(error);
  }
}