const questions = {
  title: "",
  rows: [
    {
      title: "How do I log in and Sign Up ?",
      content: [
        `There are 2 ways to login and sign up into the Diagnostic Market Place To Login`,
        <br></br>,
        `1. Click on the Login button at the top right corner of the website, you will be required to input your registered phone number and 4 digit unique passcode.`,
        <br></br>,
        `2.Click on the Create an Account to sign up`,
        <br></br>,
        `3. Fill in your name, phone number and Email address and click continue.`,
        <br></br>,
        `4. An OTP will be sent to both your phone number and email. Click Create Account to complete the process. `,
        <br></br>,
        `5. You will then be required to login with your phone number and 4 digit unique passcode. `,
        <br></br>,
        <br></br>,
        `From Checkout Page
                     Upon completing your test selection, price comparison across the different partners and adding your selection to your cart, you would be required to login to your account or create an account as a new user, before proceeding to Checkout. 
                     Clicking the Proceed to Checkout launches the Login interface. Fill in your login credentials or complete the create an account process to complete your purchase
                     `,
      ],
    },
    {
      title: "How can I order a test ?",
      content: [
        `To order a test:`,
        <br></br>,
        `1. Click on the Shop Test Button on the landing page. This takes you to test page`,
        <br></br>,
        `2. Select what category of test you wish to buy; Radiology or Pathology `,
        <br></br>,
        `3. Set your Location (City and Area),`,
        <br></br>,
        `4. You can use the search box to search for your test using key words`,
        <br></br>,
        `5. You can also filter the test displayed by alphabet groups`,
        <br></br>,
        `6. Click on the Select test on each test card for your preferred test. Your selected test will be added to a Selection Screen`,
        <br></br>,
        `7. Click on the Compare Test Price when you have completed your selection `,
        <br></br>,
        `8. In the Compare test page, You can filter from the list of available laboratory partners `,
        <br></br>,
        `9. Each selected test is displayed under each partner, you can review each partner's price and make your selection.`,
        <br></br>,
        `10. You can click on the Home Sample Collection to opt for our home visit form your preferred partner or choose uncheck this option to visit the laboratory to have your sample collected `,
        <br></br>,
        `11. Click on Add to Cart to move your selection to the cart and on the cart, click on Proceed to Checkout `,
        <br></br>,
        `12. You will be required to Login or sign up to complete your purchase. `,
        <br></br>,
        `13. Select who you would be buying the test for, you can add multiple relatives to your selection. `,
        <br></br>,
        `14. Click on the proceed to checkout to make payment and complete your order. `,
        <br></br>,
        `15. An email confirmation and receipt will be sent to you once payment is made`,
        <br></br>,
        `16. Your selected Laboratory partner will contact an hour after payment is made to schedule your sample collection.`,
        <br></br>,
      ],
    },
    {
      title: "Can I buy a test for someone ?",
      content: [
        `Yes, you can. Simply  register the person's details  as a relative when making your order. There are 2 ways of adding a relative:`,
        <br></br>,

        `From Relative Page `,
        <br></br>,
        ` 1. Click on the Profile icon to open the available options and Click on Relatives `,
        <br></br>,
        ` 2. In Relatives Page Click on the Add Relative button and fill out all the information on the Add Relative Profile `,
        <br></br>,
        ` 3. Click on the Update Profile Button when all profile sections are completely filled.  All added relatives will be available when completing your checkout for any test. `,
        <br></br>,
        ` 4. On the Relative landing page Click on the Edit button to make changes or update relative information.`,
        <br></br>,
        ` 5.Click on the Delete button to remove a saved relative.`,
        <br></br>,

        `From Checkout Page `,
        <br></br>,
        ` 1. In the Add Test Recipient page, click on the Add New Relative Button, this launches the Add New Relative details interface.`,
        <br></br>,
        ` 2. Fill out all the input sections`,
        <br></br>,
        ` 3. Click on the Add Relative to save details. You can repeat this process multiple times for multiple relatives.`,
        <br></br>,
        ` 4. You can view and update all added relatives on the Relatives Page.`,
        <br></br>,
      ],
    },
    {
      title: "Who can I contact if my order fails ? ",
      content: [
        `Our support team is available Monday - Friday: 9am - 5pm and Saturday: 9am - 2pm to oukrespond to any issue you may have. You can contact us via the following channels`,
        <br></br>,
        ` 1. Call: +234 9010996000`,
        <br></br>,
        ` 2. Email: info@doctall.com `,
        <br></br>,
        ` 3. Click up -  Customer Complaint Form`,
        <br></br>,
        ` 4. WhatsApp:+2349010996341`,
        <br></br>,
        ` 5.Facebook: Doctall`,
        <br></br>,
        ` 6.Instagram: mydoctall`,
        <br></br>,
        `Please click here to also fill out complaint form`,
        <br></br>,
      ],
    },
    {
      title: "How do I compare test prices? ? ",
      content: [
        `To compare test process across the available partners`,
        <br></br>,
        ` 1. Search and select a test from the list of available tests,`,
        <br></br>,
        ` 2. Set your location`,
        <br></br>,
        ` 3. In the selection section click on Compare Test Prices. `,
        <br></br>,
        ` 4. In the Compare Test Prices, Select the partners you wish to see prices for and click on apply filter`,
        <br></br>,
        ` 5. This updates the page with  the selected partners and you can see the prices for each test across all the selected partners.`,
        <br></br>,
        ` 6. Select your preferred test for your preferred partner, and you can opt for the Home Sample Collection.`,
        <br></br>,
        ` 7. Click on the Add to Cart button to save selection on your Cart `,
        <br></br>,
        ` 8. Click on Proceed to Checkout to make payment. You will be required to login in to your account for existing users or create an account for new users`,
        <br></br>,
      ],
    },
    {
      title: "How do I get my test result online? ",
      content: [
        `Electronic copy of your test result is available on your Diagnostic Marketplace My Orders and the mobile app. To view your test Results`,
        <br></br>,
        `On Diagnostic Marketplace`,
        <br></br>,
        `Click on the Login button at the top right corner of the website, you will be required to input your registered phone number and 4 digit unique passcode.`,
        <br></br>,
        ` 1. Once login is successful, click on the name profile to display the drop down options and select My Account. This takes you into the profile dashboard`,
        <br></br>,
        ` 2. On the side menu, click on Orders and Results - this page displays all your orders both completed and In-Progress.`,
        <br></br>,
        ` 3. Click on the check result icon to go into the order `,
        <br></br>,
        ` 4. Click on the View Result for the test under that order. The View result button will only be active once the Order Tracking status is set to Result Released.`,
        <br></br>,
        `On The Mobile App`,
        <br></br>,
        ` 1. Click on Health Records on the bottom mean and navigate to Lab Results Tab in Health Record`,
        <br></br>,
        ` 2. Your test results will be available in the order of the most recent result`,
        <br></br>,
        ` 3. You can also filter by date and by Service partner`,
        <br></br>,
      ],
    },
    {
      title: "How do I update my profile? ",
      content: [
        `To update your profile:To update your profile:`,
        <br></br>,
        `1. Click on the Login button at the top right corner of the website, you will be required to input your registered phone number and 4 digit unique passcode. `,
        <br></br>,
        `2. Once login is successful, click on the name profile to display the drop down options and select My Account. This takes you into the profile dashboard`,
        <br></br>,
        `3. Click on the Profile icon to o display the drop down options and select  Profile`,
        <br></br>,
        `4. Your Health  profile is divided into 3 sub groups for you to update the necessary field: Personal, Medical and Lifestyle. `,
        <br></br>,
      ],
    },
    {
      title: "How to cancel or reschedule Sample Collection?",
      content: [
        `To cancel or reschedule sample collection please contact our customer support via call to have your request processed immediately. 
              Our support team is available Monday - Friday: 9am - 5pm and Saturday: 9am - 2pm to respond to any issue you may have. You can contact us via the following channels 
              Call: +234 9010996000
              Please note that you are required to cancel your scheduled Home sample Collection or In-Person sample collection 24 hours before the scheduled visit to our partner Laboratories or home visit. 
              `,
      ],
    },
    {
      title: "What happens when my location is not available?",
      content: [
        `If your city and area are not available in the location section, it means our service is not yet available in your area. However, you can select a location closet to you to take advantage of our service pending our availability in your area. `,
      ],
    },
    {
      title: "How do I buy for multiple people? ",
      content: [
        `To buy test for multiple people, you would need to add them as a relative on your profile.`,
        <br></br>,
        `On the check out process, in the Add Test Recipient page, click on the Add New Relative Button, this launches the Add New Relative details interface.`,
        <br></br>,
        `Fill out all the input sections `,
        <br></br>,
        `Click on the Add Relative to save details. You can repeat this process multiple times for multiple relatives.`,
        <br></br>,
        `You can view all added relatives on the Relatives Page.`,
        <br></br>,
        `Once added, under the My Relative section, select the relatives you wish to buy test for and click on continue to proceed to the shopping cart. `,
        <br></br>,
        `On the shopping cart page under the Who is the test For? You can assign each of the previously added relative  to a specific test.`,
        <br></br>,
        `If you are buying a home sample collection, click on the home service collection to add the address for each of your added relatives.`,
        <br></br>,
        `Please note that the address you are adding out be within the location you set for your test.`,
        <br></br>,
      ],
    },
    {
      title: "How will I know available diagnostics partners in my location? ",
      content: [
        `You will be required to set your location before proceeding to make your test selection. Once this is done, you can proceed to compare test price will be displayed  based on availability of partners within your selected location                 `,
      ],
    },
    {
      title:
        "How will the partner know I paid for the test already on the platform ? ",
      content: [
        `We have an automated process in place and your details are sent to the partners once payment for an order is successfully made. For home sample collection, a partner representative  will contact you within one hour of your order to schedule your home sample collection. For in-person sample collection at designated laboratory , please go with your receipt and a valid ID for identification purposes.
              `,
      ],
    },
    {
      title: "What valid means of Identification is required? ",
      content: [
        `You can present the following means of identification when visiting the laboratory or during the home sample collection visit: International Passport, driver’s license, permanent voters’ card and national ID card.      `,
      ],
    },
    {
      title:
        "Do I get a refund if the diagnostics partner fails to provide service? ? ",
      content: [
        `Yes, a refund is processed within 3-10 working days  once the requirements for a refund are met. You can also reorder the test and select another diagnostics partner.`,
      ],
    },
    {
      title:
        "What happens if I want to change my selected diagnostics partner? ",
      content: [
        `For a test that is yet to be done, you may request a refund and if granted will be processed. You will be required to reorder the test and select the new preferred partner from the list.`,
      ],
    },
    {
      title: "What happens if I no longer want to have a test done? ? ",
      content: [
        `A refund will be processed within 3-10 working days once the requirements for a refund are met. However, client will bear all bank/transaction charges

              A refund will be processed within 3-10 working days once the terms of the refund policy is met. Customer will have to bear all bank/transaction charges
              `,
      ],
    },
    {
      title: "Am I required to fast for this investigation?",
      content: [
        `Some tests require fasting, while others do not. If you are not sure of your test requirement, please reach out to our customer support representative 
              `,
      ],
    },
    {
      title: "Do I need a doctor’s order before I can purchase tests ? ",
      content: [
        `No. After payment, you will be able to access  your order receipt that will suffice. However, you will also be required to fill out a partner's requisition form as per policy before sample collection. 
              `,
      ],
    },
    {
      title: "Is home sample collection available ? ",
      content: [
        `Yes, home sample collection is available depending on the test and location. Once you have selected a test and proceed to compare prices, the option for home sample collection will be made available to select on each partner section. You will also be notified for selected tests that do not have home sample collection. 
              `,
      ],
    },
    {
      title: `Will all my results be available on my profile
      for every test at the same time? `,
      content: [
        `Results will be available on the profile depending on test turnaround time and the partner
              `,
      ],
    },
    {
      title: "Do you accept HMOs ? ",
      content: [
        `Currently, we do not accept HMOs. 
              `,
      ],
    },
    {
      title: `What if I visit multiple partners? Will I still get my results
      on the same profile?`,
      content: [
        `Irrespective of the partner you chose to visit, all your test results/medical record will be available on your mobile app and Diagnostic Marketplace Profile.`,
      ],
    },
    {
      title:
      "Are my card details used for online payment on your platform secured?",
      content: [
        `Yes. Your credit card transactions performed on our website are fully encrypted and secured by Paystack for your protection`,
      ],
    },
  ],
};

export default questions;
