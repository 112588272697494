const locationsActions = {

    GET_CITY_LIST: "GET_CITY_LIST",
    GET_CITY_LIST_SUCCESS: "GET_CITY_LIST_SUCCESS",

    GET_AREA_LIST: "GET_AREA_LIST",
    GET_AREA_LIST_SUCCESS: "GET_AREA_LIST_SUCCESS",


    getCitys: () => {
        return {
            type: locationsActions.GET_CITY_LIST,
        };
    },

    getAreas: (city_id) => {
        return {
            type: locationsActions.GET_AREA_LIST,
            city_id
        };
    },
};

export default locationsActions;