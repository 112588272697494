import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { fetchDoctors } from '../../services/doctors-services/doctorServices';
import actions from '../actions';

const { doctorsActions } = actions;

export function* fetchSpecialist() {
    yield takeEvery(doctorsActions.GET_SPECIALIST_DOCTOR, function* ({ payLoad, pageNumber }) {
        try {
            if (payLoad) {
                const response = yield fetchDoctors(payLoad, pageNumber);
                if (response.status === 200) {
                    yield put({
                        type: doctorsActions.GET_SPECIALIST_DOCTOR_SUCCESS,
                        doctorsData: response.data
                    });
                } else {
                    throw response;
                }
            } else {
                console.log('-')
            }

        } catch (error) {
            console.log(error)
        }
    });
}

export default function* rootSaga() {
    yield all([fork(fetchSpecialist)]);
}