import React, { useEffect, useState, } from "react"
import QWelcome from "../../../assets/images/QWelcome.png";
import prevQuestionIcon from "../../../assets/images/prevQuestion.svg";
import QLogo from "../../../assets/images/QLogo.svg";
import LinearProgressBar from "../../../components/ui/LinearProgress";
import { EDquestions as questions } from "../Questions";
import styles from "../styles";
import Button from '@mui/material/Button';
import { Helmet } from "react-helmet";
// import Q_Logo from "../../../assets/images/QLogo.svg";
// import "./styles.js";
import EDheader from "./EdCardheader"

import {
  Grid, IconButton, makeStyles, useMediaQuery, useTheme, Typography,
} from "@material-ui/core";
import QuestionCard from "./QuestionCard";
import QuestionCard1 from "./QuestionCard1";

const Edduplicate = () => {
  const [step, setStep] = useState(1);
  const prevQuestion = () => {
    setStep(step - 1);
  };

  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  // const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  // function myFunction() {
  //   var x = document.getElementById("myDIV");
  //   if (x.style.display === "hide") {
  //     x.style.display = "show";
  //   } else {
  //     x.style.display = "hide";
  //   }
  // }



  const RenderItems = () => {
    switch (step) {
      case 1:
        return (
          <QuestionCard />
        );
      case 2:
        return (
          <QuestionCard1 />
        )
    }
  };

  return (
    <>
     {/* <Grid item className={classes.quesHeader}>
        <IconButton component={Link} to="/" style={{ padding: "0px" }}>
          <img src={Q_Logo} alt="Logo" />
        </IconButton>
      </Grid> */}
      <Helmet>
        <title>
          Erectile Dysfunction Questionnaire | Discreet erectile dysfunction treatment online
        </title>
        <meta
          name="description"
          content="Treat different types of erectile dysfunction with doctor-packaged plans. Choose your treatment plan, answer basic questions about your symptoms, completer your treatment order, and have your medicines delivered to you discreetly"
        />
      </Helmet>
      <EDheader />

      {/* <Grid item container justifyContent="center" alignItems="center" direction="row"
      // style={{body : "#E5E5E5",}}
      > */}
      <section style={{backgroundColor:"#F7FBF8",}}>
        <div className="container" >
          <div className="row progress1" style={{
            paddingTop: "40px", 
            display:"flex",
           justifyContent: "center",
           alignItems: "center",
           
          }}>
            
        {/* <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          style={{
            paddingTop: "40px", 
            // background: "#E5E5E5",//width: "820px"
          }}
        > */}

          {step === 0 ? null : (
            <div className="col-12">
            {/* <Grid item style={{ width: "114px", marginRight: "39px" }}> */}
              <IconButton>
                <img src={prevQuestionIcon} alt="prev" onClick={prevQuestion} />&nbsp;&nbsp;&nbsp;
              
            {/* // </Grid> */}
        

          {/* <Grid item> */}
            <LinearProgressBar
              value={(step - -49) * 1}/>
              </IconButton>
          {/* </Grid> */}
          </div>
            )}
          </div>

        {/* </Grid> */}
<div className="column">
        {/* <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ ,
          // background: "#E5E5E5",
        }}
        > */}
        <div className="col-12"style={{justifyContent:"center",display:"flex", position: "relative",
          alignItems:"center"}}>
          <img src={QWelcome} alt="Welcome" />
          </div>
          <div className="col-12">
          <Typography
            style={{
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "36px",
              textAlign: "center",
              lineHeight: "44px",
              color: "#101828",
              letterSpacing: "-0.02em",
              paddingTop: "32px",
              paddingBottom: "12px",
            }}
          >
            Welcome,

          </Typography>
          </div>
          </div>
        {/* </Grid> */}
        {/* <Grid item container> */}
          <div className="row">
<div className="col-12">
          <RenderItems /> <br />
          </div>
          </div>
          

        {/* </Grid> */}
        
      {/* </Grid> */}

      </div>
      </section>
    </>
  )
};

export default Edduplicate;