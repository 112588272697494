import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  FormControl,
  Input,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header/Header";
import PhoneInput from "react-phone-input-2";
import { showNotification } from "../../components/common-notification/CommonNotification";
import { getInTouch } from "../../services/subscriptions/subscriptionServices";
import { getCountryCodes } from '../../services/doctall-living/staticServices';
import { Helmet } from 'react-helmet';


const useStyles = makeStyles((theme) => styles(theme));

const ContactUs = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const [policyTerms, isPolicyTerms] = useState(false);

  const [fullname, setFullname] = useState("");
  // const [phone, setPhone] = useState("");

  const [country, setCountry] = useState('ng');
  const [phoneNumber, setPhoneNumber] = useState();
  const [reserror, setReserror] = useState(false);

  const errorstyle = {
    border: reserror ? "1px solid #D0D5DD" : "1px solid #ADC2C2 ",
    outline: "none !important",
    borderRadius: "5px",
    marginBottom: "20px",
  };


  useEffect(() => {
    getCountryCode()
  }, []);





  const getCountryCode = async () => {
    try {
      let response = await getCountryCodes()
      if (response) {
        setCountry(response.data.country_code.toLowerCase());
      }
    } catch (error) {
      console.log(error)
    }
  }



  const onChangeFullname = e => {
    const input = e.currentTarget.value;
    if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
      setFullname(input);
    }
  };
  // const onChangePhone = e => {
  //   const input = e.currentTarget.value;
  //   if (/^[^!-\/:-@\[-`{-~a-zA-Z]+$/.test(input) || input === "") {
  //     setPhone(input);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { userName, userEmail, userMessage } = event.target.elements
      let payLoad = {
        'full_name': userName.value,
        'email': userEmail.value,
        'mobile': phoneNumber,
        'message': userMessage.value,

      }


      if (policyTerms === true) {
        let response = await getInTouch(payLoad)
        if (response && response.status === 200 || response.status === 201) {
          showNotification('success', "Success !!", "You have suceesfully submited your request")
          setFullname("")
          setPhoneNumber('')
          event.target.reset();
        }
      } else {
        showNotification('error', "Failed !!", "Please accept privacy policy")
      }

    } catch (error) {
      if (error && error.response) {
        showNotification('error', "Failed !!", "" + error.response.data)
      }
    }

  }


  const handlePolicyTerms = (event) => {
    isPolicyTerms(event.target.checked)
  }

  return (
    <>
    
      <Header />
      <Helmet>
      <title>Contact Doctall</title>
      <meta 
            name='description' 
            content="Contact us to answer questions about our services and provide any other information you need."
            />
    </Helmet>

      <Grid container>
        <Grid
          item
          container
          direction="column"
          className={classes.contactHeader}
        >
          <Typography className={classes.contactHeading}>Contact us</Typography>
          <Typography className={classes.contactTitle}>
            We are happy to answer questions
            {matchesSM ? null : <br />} about our services and provide any
            {matchesSM ? null : <br />} other information you need.
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction={matchesMD ? "column" : "rows"}
          className={classes.contactFormContainer}
          alignItems={matchesMD ? "center" : undefined}
        >
          <Grid
            item
            md
            container
            direction="column"
            className={classes.contactFormContent}
          >
            <Typography className={classes.contentHeading}>
              Email Support
            </Typography>
            <Typography
              className={classes.contentBody}
              style={{ paddingBottom: "73px" }}
            >
              For specific enquiries, please send us an email at &nbsp;
              <span style={{ color: theme.palette.common.orange, fontWeight: "500" }}>
               <a href="mailto:support@doctall.com"> hello@doctall.com </a>
              </span>
            </Typography>
            <Typography className={classes.contentHeading}>
              Call Support
            </Typography>
            <Typography className={classes.contentBody}>
              For prompt response, contact Customer Support on &nbsp;{" "}
              <span style={{ color: theme.palette.common.orange, fontWeight: "500" }}>
                0901 099 6000
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            container
            md
            className={classes.contactFormOuter}
            justifyContent={matchesMD ? "center" : undefined}
          >
            <Grid container direction="column" className={classes.contactForm}>
              <Typography
                align="center"
                className={classes.contactHeading}
                style={{
                  color: "#B4331A",
                  paddingBottom: "12px",
                  paddingTop: "51px",
                  fontWeight: "700",
                }}
              >
                Contact us
              </Typography>
              <Typography
                style={{
                  color: "#002931",
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "36px",
                  lineHeight: "44px",
                  letterSpacing: "-0.02em",
                  paddingBottom: "20px",
                }}
                align="center"
              >
                Get in touch
              </Typography>
              <Typography
                align="center"
                style={{
                  color: "#667085",
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "20px",
                  lineHeight: "30px",
                  paddingBottom: "32px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                We’d love to hear from you. Please fill out this form.
              </Typography>

              <Grid container justifyContent="center">
                <form onSubmit={handleSubmit}>
                  <FormControl className={classes.formControl} >
                    <label
                      htmlFor="name"
                      className={classes.inputLabel}
                      style={{ alignSelf: "flex-start" }}
                    >
                      Full name*
                    </label>
                    <Input
                      onChange={onChangeFullname}
                      value={fullname}
                      type="text"
                      required
                      name='userName'
                      placeholder="Enter your name"
                      className={classes.inputField}
                      disableUnderline={true}
                      inputProps={{
                        maxLength: 25,
                      }}
                    />

                    <label
                      htmlFor="name"
                      className={classes.inputLabel}
                      style={{ paddingTop: "20px", paddingBottom: "6px" }}
                    >
                      Email*
                    </label>
                    <Input

                      type="email"
                      required
                      name='userEmail'
                      placeholder="Enter your email"
                      className={classes.inputField}
                      disableUnderline={true}
                    />
                    <label
                      htmlFor="name"
                      className={classes.inputLabel}
                      style={{ paddingTop: "20px", paddingBottom: "6px" }}
                    >
                      Phone Number*
                    </label>
                    <PhoneInput
                      style={errorstyle}
                      className="inputPhone"
                      country={country}
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      name='phone'
                    />

                    {/* <Input
                      onChange={onChangePhone}
                      value={phone}
                      type="type"
                      required
                      name="userNumber"
                      placeholder="Enter your phone number"
                      className={classes.inputField}
                      disableUnderline={true}
                      inputProps={{
                        minLength: 10,
                        maxLength: 10,
                      }}
                    /> */}
                    <label
                      htmlFor="name"
                      className={classes.inputLabel}
                      style={{ paddingTop: "20px", paddingBottom: "6px" }}
                    >
                      How Can We Help
                    </label>
                    <textarea
                      name="userMessage"
                      required
                      type="text"

                      rows="7"
                      className={classes.inputFieldMultiline}
                    />
                    <Grid style={{ paddingTop: "20px" }}>
                      {" "}
                      <input type="checkbox" className={classes.inputCheck} onChange={(event) => handlePolicyTerms(event)} />
                      <label
                        htmlFor="name"
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "6px",
                          display: "inline",
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#667085",
                          paddingLeft: "12px",
                        }}
                      >
                        You agree to our friendly{" "}
                        <a
                          style={{
                            color: "#667085",
                            textDecoration: "underline",
                          }}
                          href="/privacy-policy"
                        >
                          privacy policy
                        </a>
                        .
                      </label>
                    </Grid>
                    <Button variant="contained" className={classes.contactButton} type='submit'>
                      Contact us
                    </Button>
                  </FormControl>
                </form>
              </Grid>
            </Grid>{" "}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default ContactUs;
