import * as React from 'react';
import { Grid, Typography, } from "@material-ui/core";
import styles from "./../../pages/diseases/styles"
import { Dialog, makeStyles } from "@material-ui/core";
import close from "../../assets/images/close.png";

const useStyles = makeStyles((theme) => styles(theme));


export default function InvestigationAreaPopup(props) {
    // console.log(props.InvestigationArea,"props")
    const classes = useStyles();

    return (
        <>
            <div className="ContainerWrapper">

                <Grid container
                    style={{

                        background: "#F7F8FB",
                    }} >
                    <Grid sm={11} md={11} xs={11} align="left" >
                        <h1
                            style={{
                                fontSize: "24px",
                                padding: "20px",
                                background: "#F7F8FB",
                            }}
                        >
                            Investigation Area - {props.packageType} Plan
                        </h1>
                    </Grid>
                    <Grid sm={1} md={1} xs={1} align="center" >
                        <img
                            src={close}
                            alt="Close"
                            className={classes.Closepopup}
                            onClick={() => props.onClose()}
                            crossorigin="anonymous"
                        />
                    </Grid>
                </Grid>

                <Grid container >
                    <Grid sm={12} md={12} xs={12} align="left" >
                        <Typography style={{ fontSize: "16px", color: "#667085", padding: "20px" }}>
                            View list of Investigation Area for the basic plan
                        </Typography>
                        <Grid
                            style={{
                                border: " 1px solid #E4E7EC",
                                height: "auto",
                                // padding: " 20px",
                                margin: "10px 30px 50px 30px",
                            }}
                        >
                            <Typography style={{ fontSize: "18px", fontWeight: "700", padding: "20px" }}>
                                Diabetes
                            </Typography>
                            <Grid style={{ borderBottom: " 1px solid #E4E7EC", }}></Grid>
                            <Typography style={{ fontSize: "16px", background: "#F9FAFB", padding: "10px 20px" }}>Name</Typography>
                            {props.InvestigationArea.map((item)=>{
                               return <Typography style={{ fontSize: "16px", padding: "20px", color: " #002931", fontWeight: "500" }}>
                                   {item}
                           </Typography> 
                            })}
                            {/* <Typography style={{ fontSize: "16px", padding: "20px", color: " #002931", fontWeight: "500" }}>
                                Kidney/Renal test lectrolytes Urea and Creatine (E/U/CR)
                            </Typography>
                            <Grid style={{ borderBottom: " 1px solid #E4E7EC", }}></Grid>
                            <Typography style={{ fontSize: "16px", padding: "20px", color: " #002931", fontWeight: "500" }}>Total Protein</Typography>
                            <Grid style={{ borderBottom: " 1px solid #E4E7EC", }}></Grid>
                            <Typography style={{ fontSize: "16px", padding: "20px", color: " #002931", fontWeight: "500" }}>Urine MCS</Typography> */}
                        </Grid>

                        <Grid
                            style={{
                                border: " 1px solid #E4E7EC",
                                height: "auto",
                                margin: "10px 30px 50px 30px",
                            }}
                        >
                            <Typography style={{ fontSize: "18px", fontWeight: "700", padding: "20px" }}>
                                Kidney & Liver
                            </Typography>
                            <Grid style={{ borderBottom: " 1px solid #E4E7EC", }}></Grid>
                            <Typography style={{ fontSize: "16px", background: "#F9FAFB", padding: "10px 20px" }}>Name</Typography>
                            <Typography style={{ fontSize: "16px", padding: "20px", color: " #002931", fontWeight: "500" }}>
                                (Liver Function Test)
                            </Typography>
                            <Grid style={{ borderBottom: " 1px solid #E4E7EC", }}></Grid>
                            <Typography style={{ fontSize: "16px", padding: "20px", color: " #002931", fontWeight: "500" }}>Beta 2 Microglobin</Typography>
                            <Grid style={{ borderBottom: " 1px solid #E4E7EC", }}></Grid>
                            <Typography style={{ fontSize: "16px", padding: "20px", color: " #002931", fontWeight: "500" }}>Albumin: Creatirine</Typography>
                        </Grid>

                    </Grid>
                </Grid>
            </div>
        </>

    );
}
