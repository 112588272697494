import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  Card,
  CardActions,
  Button,
  CardContent,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "../styles";

import hormoneIcon from "../../../assets/images/hormoneIcon.svg";

const useStyles = makeStyles((theme) => styles(theme));

const Popularproduct = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const products = [
    {
      name: " Men’s Hormone Test",
      desc: "A simple finger-prick blood test that measures the male hormone testosterone - essential for libido, muscle mass, fertility and mood.",
      count: "9 BIOMARKERS",
      price: "₦120,000",
    },
    {
      name: " Men’s Hormone Test",
      desc: "A simple finger-prick blood test that measures the male hormone testosterone - essential for libido, muscle mass, fertility and mood.",
      count: "9 BIOMARKERS",
      price: "₦120,000",
    },
    {
      name: " Men’s Hormone Test",
      desc: "A simple finger-prick blood test that measures the male hormone testosterone - essential for libido, muscle mass, fertility and mood.",
      count: "9 BIOMARKERS",
      price: "₦120,000",
    },
    {
      name: " Men’s Hormone Test",
      desc: "A simple finger-prick blood test that measures the male hormone testosterone - essential for libido, muscle mass, fertility and mood.",
      count: "9 BIOMARKERS",
      price: "₦120,000",
    },
  ];

  return (
    <Grid
      item
      container
      direction={matchesXS ? "column" : undefined}
      alignItems={matchesSM ? "center" : undefined}
      justifyContent={matchesSM ? "center" : undefined}
    >
      {products.map((product, index) => (
        <Grid
          item
          key={`${product}${index}`}
          style={{
            marginRight: matchesXS ? "0px" : "40px",
            marginBottom: "24px",
          }}
        >
          <Card classes={{ root: classes.productCard }}>
            <CardContent classes={{ root: classes.cardSetter }}>
              <Grid container direction="column" alignItems="center">
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.titleContainer}
                  style={{ padding: "16px" }}
                >
                  <img src={hormoneIcon} alt="hormoneIcon" />
                  <Typography className={classes.productTitle}>
                    {product.name}
                  </Typography>
                </Grid>
                <Grid item align="center" style={{ marginBottom: "20px" }}>
                  <Typography variant="body1">
                    A simple finger-prick blood test that measures the male
                    hormone testosterone - essential for libido, muscle mass,
                    fertility and mood.{" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.titleContainer}
                  style={{ padding: "8px", marginBottom: "20px" }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: "#00839B",
                      fontWeight: "500",
                      justifyContent: "center",
                    }}
                  >
                    {product.count}
                  </Typography>
                </Grid>
                <Grid item style={{ marginBottom: "20px" }}>
                  <Typography align="center" variant="body1">
                    Start from
                  </Typography>
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ fontWeight: "900", color: "#002931" }}
                  >
                    ₦120,000
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.viewTestButton}
                  >
                    View Test
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Popularproduct;
