import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import firebase from "firebase/app";
import styles from "./../styles";
import paystack from "../../../assets/images/paystack.png";
import paymentOptions from "../../../assets/images/paymentOptions.png";
import paymentCheck from "../../../assets/images/paymentCheck.png";
import usePaystackHook from "../../../components/Payment";
import OrderConfirmed from "./../OrderConfirmed";
import {
  blockCoupon,
  changeStatus,
  placeOrder,
  postTransaction,
  saveOrderDetails,
} from "../../../services/order-services/orderServices";
import { showNotification } from "../../../components/common-notification/CommonNotification";
import { useSelector } from "react-redux";
import { saveQuestionnaire } from "../../../services/category-services/categoryServices";
import { getFirebaseAnalyticsInstance } from "../../../firebase";
import axios from 'axios'

const useStyles = makeStyles((theme) => styles(theme));

const Payment = ({
  expanded,
  handleChange,
  planInfo,
  questionsSummary,
  payableAmount,
  basePlan,
  shippingDetails,
  couponId,
  finalQuantity,
  uuidDaata,
  disease,
  guestState,
  wallAmount1,
  usedamount
}) => {

  console.log(basePlan, planInfo, "basePlan")
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [orderConfirmedModel, setOrderConfirmedModel] = useState(false);
  const [preOrderId, setPreorderId] = useState();
  const _userInfo = useSelector((state) => state.authReducer);
  console.log(planInfo, "planInfo")
  const initializePayment = usePaystackHook({
    email: guestState === false ? _userInfo && _userInfo.userInfo && _userInfo.userInfo.email : shippingDetails && shippingDetails.emailId,
    amt: payableAmount,
  });


  useEffect(() => {
    saveOrderData()
  }, []);

  const saveOrderData = async () => {
    try {
      let payLoad;
      if (guestState === true) {
        payLoad = {
          // user_uuid: 
          // email: _userInfo && _userInfo.userInfo && _userInfo.userInfo.email,
          email: guestState === false ? _userInfo && _userInfo.userInfo && _userInfo.userInfo.email : shippingDetails && shippingDetails.emailId,
          category_uuid: "CAT-CCC878E370EC",
          disease_uuid: basePlan.diseases_uuid,
          type: "treatment",
          amount: basePlan.price.toString(),
          details: {
            _id: basePlan._id,
            diseases_uuid: basePlan.diseases_uuid,
            diseases_name: basePlan.diseases_name,
            plan_name: basePlan.plan_name,
            treatment: [{
              _id: basePlan._id,
              category_uuid: "CAT-CCC878E370EC",
              diseases_uuid: basePlan.diseases_uuid,
              diseases_name: basePlan.diseases_name,
              plan_name: basePlan.plan_name,
              price: payableAmount.toString(),
              createdAt: new Date(),
              updatedAt: new Date(),
              uuid: basePlan.uuidDaata,
              v: 0
            }],
            createdAt: new Date(),
            updatedAt: new Date(),
            uuid: basePlan.uuid,
            __v: 0,
          },
          // delivery_address: {},
        };
      }
      else {
        payLoad = {
          // user_uuid: 
          // email: questionsSummary.email,
          // email: _userInfo && _userInfo.userInfo && _userInfo.userInfo.email,
          email: guestState === false ? _userInfo && _userInfo.userInfo && _userInfo.userInfo.email : shippingDetails && shippingDetails.emailId,
          category_uuid: "CAT-CCC878E370EC",
          disease_uuid: basePlan.diseases_uuid,
          type: "treatment",
          amount: planInfo.finalPrice.toString(),
          details: {
            _id: planInfo._id,
            diseases_uuid: basePlan.diseases_uuid,
            diseases_name: basePlan.diseases_name,
            plan_name: basePlan.plan_name,
            treatment: basePlan.plan_name === "Full Package" ? basePlan.treatment : [planInfo],
            createdAt: new Date(),
            updatedAt: new Date(),
            uuid: planInfo.tpl_uuid,
            __v: 0,
          },
          // delivery_address: {},
        };
      }


      let response = await saveOrderDetails(payLoad);
      if (response) {
        const params = {
          currency: 'NGN',
          value: planInfo.finalPrice, // Total Revenue
          items: [{ item_id: planInfo.tpl_uuid, item_name: basePlan.plan_name, currency: "NGN", price: planInfo.finalPrice, quantity: 1 }]
        };
        addFireBaseAnalytics(firebase.analytics.EventName.ADD_TO_CART, params)
        setPreorderId(response.data.uuid);
      }
    } catch (error) {
      if (error && error.response) {
        console.log(error.response.data)
      }
    }
  };





  const handleValidation = () => {

    if ((_userInfo.userInfo == undefined || _userInfo.userInfo == null) && guestState === false) {
      showNotification('error', "Failed !!", "Please add and verify account  or Login with valid credentials !")
    } else if (shippingDetails == null || shippingDetails == false) {
      showNotification("error", "Failed !!", "Please Add Shipping Address !");
    } else {
      if (payableAmount == 0) {
        makeOrder();
      } else {
        initializePayment((event) => {
          if (event.status == "success") {
            makeOrder();
          } else {
            showNotification("error", "Failed !", "Payment Failed");
          }
        });
      }
    }
  };

  const makeOrder = async () => {
    try {
      console.log(_userInfo, "gfg")
      let payLoad;
      if (guestState === true) {
        payLoad = {
          pre_plan_uuid: preOrderId,
          // "user_uuid": _userInfo &&  _userInfo.userInfo && _userInfo.userInfo.uuid,
          category_uuid: "CAT-CCC878E370EC",
          disease_uuid: basePlan.diseases_uuid,
          type: "treatment",
          amount: payableAmount.toString(),
          qty: finalQuantity.toString(),
          details: {
            _id: basePlan._id,
            diseases_uuid: basePlan.diseases_uuid,
            diseases_name: basePlan.diseases_name,
            plan_name: basePlan.plan_name,
            treatment: [{
              _id: basePlan._id,
              category_uuid: "CAT-CCC878E370EC",
              diseases_uuid: basePlan.diseases_uuid,
              diseases_name: basePlan.diseases_name,
              plan_name: basePlan.plan_name,
              price: payableAmount.toString(),
              createdAt: new Date(),
              updatedAt: new Date(),
              uuid: basePlan.uuidDaata,
              v: 0
            }],
            createdAt: new Date(),
            updatedAt: new Date(),
            uuid: basePlan.uuid,
            __v: 0,
          },
          delivery_address: shippingDetails,
        };
      }
      else {
        payLoad = {
          pre_plan_uuid: preOrderId,
          "user_uuid": _userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid,
          category_uuid: "CAT-CCC878E370EC",
          disease_uuid: basePlan.diseases_uuid,
          type: "treatment",
          amount: payableAmount.toString(),
          qty: finalQuantity.toString(),
          details: {
            _id: planInfo._id,
            diseases_uuid: basePlan.diseases_uuid,
            diseases_name: basePlan.diseases_name,
            plan_name: basePlan.plan_name,
            treatment: basePlan.plan_name === "Full Package" ? basePlan.treatment : [planInfo],
            createdAt: new Date(),
            updatedAt: new Date(),
            uuid: planInfo.tpl_uuid,
            __v: 0,
          },
          delivery_address: shippingDetails,
        };
      }


      let response = await placeOrder(payLoad);
      if (response) {
        console.log(response.data)
        addTransaction(response.data.uuid);
        // getFirebaseAnalyticsInstance().setUserId(_userInfo.userInfo.uuid); //logEvent(firebase.analytics.EventName.SELECT_ITEM, params2);

        const params = {
          transaction_id: response.data.uuid,
          currency: 'NGN',
          value: payableAmount, // Total Revenue
          items: [{ item_id: planInfo.tpl_uuid, item_name: basePlan.plan_name, currency: "NGN", price: planInfo.finalPrice, quantity: finalQuantity, shipping: shippingCharges }]
        };
        // getFirebaseAnalyticsInstance().logEvent(firebase.analytics.EventName.PURCHASE, params);
        addFireBaseAnalytics(firebase.analytics.EventName.PURCHASE, params);
      }
    } catch (error) {
      console.log(error)
      if (error && error.response) {
        showNotification("error", "Failed !!", "" + error.response.data);
      }
    }
  };






  const addTransaction = async (transactionId) => {
    try {
      if (guestState == false) {
        let authToken = _userInfo && _userInfo.userInfo && _userInfo.userInfo.token;

        let response = await postTransaction(transactionId, authToken);
        if (response) {
          updateOrderStatus(transactionId);
          blockAppliedCoupon(response.data.reference);
          // addFireBaseAnalytics("TREATMENT_PLAN_ORDER_SUCCESS", response.data.reference)
          addFireBaseAnalytics("order_placed", response.data.reference)
        }
      }
      else {
        let response = await postTransaction(transactionId, '');
        if (response) {
          updateOrderStatus(transactionId);
          blockAppliedCoupon(response.data.reference);
          // addFireBaseAnalytics("TREATMENT_PLAN_ORDER_SUCCESS", response.data.reference)
          addFireBaseAnalytics("order_placed", response.data.reference)
        }
      }

    } catch (error) {
      if (error && error.response && error.response.data) {
        showNotification("error", "Failed !!", "" + error.response.data);
      }
    }
  };


  const payFromWalletUpdate = (OrderId) => {
    console.log(OrderId,wallAmount1,usedamount,_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid,"dsdsdda")
    let payLoad = {
      totalamount : wallAmount1,
      amount : Number(usedamount),
      order_id : OrderId,
      user_uuid : _userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid
    }
    let authToken = _userInfo && _userInfo.userInfo && _userInfo.userInfo.token;
    console.log(payLoad,"dsdsdda")
    axios.put(`${process.env.REACT_APP_API_BASE_URL}user/wallethistory/pay/from/wallet`,payLoad,{ headers: { "x-auth-token": authToken } })
    .then((res) => {
      console.log(res, "Suc")
    })
    .catch((err) => {
      console.log(err)
    })
  }


  const updateOrderStatus = async (transactionId) => {
    try {
      if (guestState == false){
        let authToken = _userInfo && _userInfo.userInfo && _userInfo.userInfo.token;
        let response = await changeStatus(transactionId, authToken);
        if (response) {
          setOrderConfirmedModel(true);
          saveQuestions();
          payFromWalletUpdate(response && response.data && response.data.uuid);
        }
      }
      else {
        let response = await changeStatus(transactionId, '');
        if (response) {
          setOrderConfirmedModel(true);
          saveQuestions();
        }
      }

    } catch (error) {
      if (error && error.response && error.response.data) {
        showNotification("error", "Failed !!", "" + error.response.data);
      }
    }
  };

  const blockAppliedCoupon = async (transactionId) => {
    try {
      let response = await blockCoupon(
        couponId,
        transactionId,
        _userInfo.userInfo.token
      );
      if (response) {
        console.log("Success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveQuestions = async () => {
    try {
      let payLoad;
      if (guestState === true) {
        payLoad = {
          uuid: uuidDaata,
          disease_uuid: basePlan.diseases_uuid,
          disease_name: basePlan.diseases_name,
          questionanswer: [questionsSummary],
        };
      }
      else {
        payLoad = {
          uuid: uuidDaata,
          user_uuid: _userInfo.userInfo.uuid,
          disease_uuid: basePlan.diseases_uuid,
          disease_name: basePlan.diseases_name,
          questionanswer: [questionsSummary],
        };
      }

      let response = await saveQuestionnaire(payLoad);
      if ((response && response.status === 200) || response.status === 201) {
        console.log("Saved");
        addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const addFireBaseAnalytics = async (eventType, eventRef) => {
  const addFireBaseAnalytics = (eventType, eventRef) => {
    try {
      // await getFirebaseAnalyticsInstance().logEvent(eventType, {
      //   type: 'TREATMENT_PLAN' + eventRef,
      getFirebaseAnalyticsInstance().logEvent(eventType, {
        ...eventRef,
        platform: 'web'
      });
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Accordion
        expanded={expanded === "Payment"}
        onChange={handleChange("Payment")}
        className={classes.inputContainer}
        style={{ marginBottom: "14px" }}
      >
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{ minHeight: "91px", padding: "0px" }}
        >
          <Typography className={classes.summaryHeading}>
            2. Payments
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ height: "auto", padding: "0px" }}>
          <Grid item container direction="column">
            <Grid item className={classes.paymentHeader}>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "30px",
                  color: "#002931",
                }}
              >
                Pay online/Card Transfer
              </Typography>

              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#002931",
                }}
              >
                Powered By{" "}
                <img
                  src={paystack}
                  alt="paystack"
                  style={{ paddingLeft: "10px" }}
                />
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{
                border: "1px solid #D9EDF1",
                boxSizing: "border-box",
                height: "54px",
                padding: "0px 10px",
                width: matchesXS ? "320px" : "491px",
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "30px",
                  color: "#002931",
                }}
              >
                Pay online/Card Transfer
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#002931",
                }}
              >
                Powered By{" "}
                <img
                  src={paystack}
                  alt="paystack"
                  style={{ paddingLeft: "10px" }}
                />
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ marginTop: "24px" }}
              justifyContent="space-between"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "32px",
                  color: "#002931",
                }}
              >
                ₦ {payableAmount}
              </Typography>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ width: "153px", height: "15px", alignSelf: "center" }}
              >
                <img
                  src={paymentOptions}
                  alt="paystackmini"
                  style={{ marginRight: "23px" }}
                />
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: "25px", marginBottom: "35px" }}>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#002931",
                }}
              >
                Channel Supported
              </Typography>
              <Grid item container>
                <Grid item style={{ paddingRight: "30px" }}>
                  <Typography
                    style={{
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#667085",
                    }}
                  >
                    <img
                      src={paymentCheck}
                      alt="check icon"
                      style={{ marginRight: "8px" }}
                    />
                    Card
                  </Typography>
                </Grid>
                <Grid item style={{ paddingRight: "30px" }}>
                  <Typography
                    style={{
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#667085",
                    }}
                  >
                    <img
                      src={paymentCheck}
                      alt="check icon"
                      style={{ marginRight: "8px" }}
                    />
                    USSD
                  </Typography>
                </Grid>
                <Grid item style={{ paddingRight: "30px" }}>
                  <Typography
                    style={{
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#667085",
                    }}
                  >
                    <img
                      src={paymentCheck}
                      alt="check icon"
                      style={{ marginRight: "8px" }}
                    />
                    Bank Transfer
                  </Typography>
                </Grid>
                <Grid item style={{ paddingRight: "30px" }}>
                  <Typography
                    style={{
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#667085",
                    }}
                  >
                    <img
                      src={paymentCheck}
                      alt="check icon"
                      style={{ marginRight: "8px" }}
                    />
                    Visa QR
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Typography paragraph>
              Your personal data will be used to process your order,
              send marketing messages, support your experience throughout this website,
              and for other purposes described in our privacy policy.{" "}
            </Typography>
            <Button
              variant="contained"
              className={classes.payNow}
              onClick={handleValidation}
            >
              Pay Now : ₦ {payableAmount}
            </Button>
            <Typography
              align="center"
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "28px",
                color: "#039855",
                paddingBottom: "17px",
              }}
            >
              100% Safe and Secure
            </Typography>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <OrderConfirmed
        open={orderConfirmedModel}
        onClose={() => setOrderConfirmedModel(false)}
      />
    </>
  );
};

export default Payment;
