import React from 'react'
import "../AboutaUs/AboutUs.scss";
import {
  Grid,
} from "@material-ui/core";


function NewsLetter() {
  return (

    <div className='newsletter'>

      <Grid container spacing={0}>
        <Grid md={6}>
          <div className="newsletter_text">
            <h1 className='newletter_heading'>Subscribe to our newsletter today!</h1>
            <p className='newsletter_para'>Doctall aims at bringing the best of health information that are really importan to you easily.
            </p>
            <p className='newsletter_para'>
              Also, helps to keep you abrest  emerging health processes that will keep your body and mind in a better shape </p>

          </div>
        </Grid>



        <Grid md={6}>

          <div className='form_container'>
            <div className='newsletter_form'>
              <div className='newsletter_form_items'>

                <form action="" >
                  <label for="fname" className='label'>Full name*</label>

                  <input type="text" id="fname" name="fname" placeholder='Enter your name' className='input_box'></input>

                  <label for="lname" className='label'  >Email*</label>

                  <input type="email" id="lname" name="lname" placeholder='Enter your email' className='input_box'></input>
                  <Typography
          variant="h6"
          style={{ textAlign: matchesSM ? "justify" : undefined, 
          fontFamily: 'Visuelt Pro',
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "13px",
          lineHeight: "20px",
          color: "#667085",
          alignSelf: "stretch",
          paddingBottom: "7px",}}>
          *By subscribing, you are agreeing to receive emails from Doctall, in line with our Privacy Policy.Your details are safe with us. You can unsubscribe at any time.
          </Typography>
                  <button type="submit" value="Submit" className='newsletter_btn' >Subscribe to Newsletter</button>

                </form>
              </div>           
            </div>

          </div>
       
        </Grid>
      </Grid>

    </div>
  )
}

export default NewsLetter