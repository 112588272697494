import {
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import AppointmentIcon from "../../assets/images/Appointment";
import Specialist2 from "../../assets/images/see_specialist_dark.svg";
import Specialistt from "../../assets/images/see_specialist_white.svg";
import SymptomsIcon from "../../assets/images/SymptomsIcon";
import actions from "../../redux/actions";
import { doEncryption, doEncryptionAndRedirect } from "../common/utils";
import Applayout from "../Layout/layout/Applayout";
import { _redirect } from "../Redirect/Redirect";
import Treatment from "./../../components/popup/TreatmentPopup";
import AppLink from "./components/AppLink";
import BannerCarousel from "./components/BannerCarousel";
import Blogs from "./components/Blogs";
import BrowseByDisease from "./components/BrowseByDisease";
import NewsletterForm from "./components/NewsletterForm";
import PartnerSlider from "./components/PartnerSlider";
import "./Landing.scss";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

const Landing = () => {
  const [cookies, setCookie] = useCookies(["username", "token", "passcode"]);
  const { categoryActions, authActions } = actions;
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const custommatchesXL = useMediaQuery("(min-width:1500px)");
  const custommatchesXXS = useMediaQuery("(max-width:330px)");
  const dispatch = useDispatch();
  const diseaseCategorys = useSelector((state) => state.categoryReducer);
  const _userInfo = useSelector((state) => state.authReducer);
  const [open, setOpen] = React.useState(false);
  const [over, setOver] = useState(false);
  console.log(useSelector(state => state), "userDetails")
  const fetchUserDetails = () => {
    if (cookies.passcode && cookies.username && _userInfo.userInfo.login_type != "facebook" && _userInfo.userInfo.login_type != "google") {
      let username = CryptoJS.AES.decrypt(
        cookies.username,
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
      let password = CryptoJS.AES.decrypt(
        cookies.passcode,
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
      dispatch(authActions.handleLogin({ username, password }));
    } else {
      // dispatch(authActions.userLogout());
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [cookies.username]);

  useEffect(() => {
    // dispatch(categoryActions.getCategory())
    let _redirectPayload = localStorage.getItem("BOOK-DOCTOR-PAYLOAD");
    let _payLoad = JSON.parse(_redirectPayload);
    if (_payLoad && _userInfo && _userInfo.userInfo) {
      _payLoad["userName"] = _userInfo.userInfo.username;
      _payLoad["password"] = _userInfo.userInfo.savedPassword;
      _bookDoctor(_payLoad);
    }
  }, []);

  const _bookDoctor = async (payLoad) => {
    try {
      let response = await doEncryptionAndRedirect([payLoad]);
    } catch (error) {
      console.log(error);
    }
  };

  const TreatmentPopupOpen = () => {
    setOpen(true);
  };

  const TreatmentPopupClose = () => {
    setOpen(false);
  };

  const loginAndRedirectToFADN = async () => {
    try {
      if (_userInfo && _userInfo.userInfo) {
        let response = await doEncryption([
          {
            userName: _userInfo.userInfo.username,
            password: _userInfo.userInfo.savedPassword,
          },
        ]);
        let targetUrl =
          process.env.REACT_APP_CUNSUMER_SITE_URL + "/find-doctor/" + response;
        window.open(targetUrl, "_blank");
      } else {
        _redirect("CONSUMER");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dialog
        style={{ borderRadius: "0px" }}
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        scroll={"body"}
        onClose={TreatmentPopupClose}
      >
        <Treatment onClose={() => setOpen(false)} />
      </Dialog>

      <Applayout>
        <Grid container>
          {/*Banner*/}
          <Grid
            item
            container
            className={classes.rowContainer}
            justifyContent="flex-start"
          >
            <BannerCarousel />
          </Grid>
          <Grid item container>
            <Grid item className={classes.rowContainer}>
              <Grid container direction={matchesSM ? "column" : "row"}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm
                  style={{ borderRight: "1px solid #002931" }}
                >
                  <Link to="" className="bannerNav bannerNav--1">
                    <AppointmentIcon />
                    <span
                      className="bannerNav__links"
                      onClick={() => _redirect("CONSUMER")}
                    >
                      Book an appointment
                    </span>
                  </Link>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm
                  style={{ borderRight: "1px solid #002931" }}
                  onMouseOver={() => setOver(true)}
                  onMouseOut={() => setOver(false)}
                >
                  <Link to="" className="bannerNav bannerNav--2">
                    {over ? (
                      <img
                        src={Specialistt}
                        style={{ width: "41px", height: "41px" }}
                      />
                    ) : (
                      <img
                        src={Specialist2}
                        style={{ width: "41px", height: "41px" }}
                      />
                    )}
                    <span
                      className="bannerNav__links"
                      // onClick={() => _redirect("CONSUMER-FADN")}
                      onClick={loginAndRedirectToFADN}
                    >
                      See a specialist
                    </span>
                  </Link>
                </Grid>
                {/* <Grid item lg={4} md={4} sm>
                  <Link to="" className="bannerNav bannerNav--3">
                    <SymptomsIcon />
                    <span className="bannerNav__links">Look up symptoms</span>
                  </Link>
                </Grid> */}
              </Grid>
            </Grid>

            {/* <Grid md={12} className={classes.browse_by_disease} style={{
              paddingLeft: matchesMD ? "50px" : undefined,
              
            }}>
              <Typography
                variant="h3"
                style={{ textAlign: matchesSM ? "center" : undefined, }}
              >
                BROWSE BY DISEASE
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  paddingTop: "24px",
                  textAlign: matchesSM ? "center" : undefined,
                  maxWidth: "849px",

                }}
              >
                Some of the common health concerns that people face and suggestions from specialists
              </Typography>
            </Grid> */}
            <Grid
              item
              container
              className={classes.diseaseContainer}
              style={{
                alignItem: matchesXL ? "center" : undefined,
                background: "#F7FBF8",
              }}
              direction="column"
              md={12}
            >
              <Grid>
                <Typography
                  variant="h3"
                  style={{
                    color: "#FF5C3C",
                    fontSize: "16px",
                    fontWeight: "600",
                    paddingBottom: "2.5rem",
                    textAlign: matchesSM ? "center" : undefined,
                  }}
                >
                  BROWSE BY DISEASE
                </Typography>
                <Typography
                  // style={{textAlign: "start" ,alignContent: "flex-start" ,marginRight: "40px"}}

                  variant="subtitle1"
                  style={{
                    paddingBottom: "3.3rem",
                    textAlign: matchesSM ? "center" : undefined,
                    // width: "849px",
                    // [theme.breakpoints.down("xs")]:{
                    //   width: "100px"
                    // }
                  }}
                >
                  Some of the common health concerns that people{" "}
                  {matchesSM ? null : <br />}
                  face and suggestions from specialists
                </Typography>
              </Grid>

              <Grid
                item
                justifyContent={matchesXL ? "center" : undefined}
                direction={matchesXS ? "column" : undefined}
                alignItems={matchesSM ? "center" : undefined}
              >
                <BrowseByDisease />
              </Grid>
            </Grid>
            {/*  Healthy Living*/}
            <Grid
              md={12}
              style={{ alignItem: matchesXL ? "center" : undefined }}
            >
              <Grid
                item
                container
                className={classes.healthyLivingContainer}
                direction="column"
              >
                <Typography
                  variant="h3"
                  style={{
                    color: "#FF5C3C",
                    fontSize: "16px",
                    fontWeight: "600",
                    paddingBottom: "2.5rem",
                    textAlign: matchesSM ? "center" : undefined,
                  }}
                >
                  HEALTHY LIVING
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    paddingBottom: "3.6rem",
                    textAlign: matchesSM ? "center" : undefined,
                  }}
                >
                  How we can help you manage your health
                </Typography>

                <Grid
                  item
                  container
                  direction={matchesSM ? "column" : "row"}
                  alignItems={matchesSM ? "center" : undefined}
                  justifyContent={custommatchesXL ? "center" : undefined}
                >
                  <Grid
                    item
                    style={{
                      marginRight: matchesSM ? "0px" : "24px",
                      marginBottom: "24px",
                    }}
                  >
                    <Card classes={{ root: classes.wellnessCard }}>
                      <CardContent
                        style={{ paddingTop: "0px", paddingLeft: "38px" }}
                      >
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-around"
                          alignItems="flex-start"
                        >
                          <Typography
                            variant="h2"
                            style={{ paddingBottom: "8px", fontSize: "27px" }}
                          >
                            Worried about something? Need a diagnosis?
                          </Typography>
                          <Typography
                            variant="h5"
                            style={{ paddingBottom: "35px" }}
                          >
                            Speak to one of our doctors <br /> now
                          </Typography>
                          <Button
                            variant="contained"
                            className={classes.livingButton}
                            onClick={() => _redirect("CONSUMER")}
                          >
                            SEE A DOCTOR
                          </Button>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid
                    item
                    style={{
                      marginRight: matchesSM ? "0px" : "24px",
                      marginBottom: "24px",
                    }}
                  >
                    <Card classes={{ root: classes.specialistCard }}>
                      <CardContent
                        style={{ paddingTop: "0px", paddingLeft: "38px" }}
                      >
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-around"
                          alignItems="flex-start"
                        >
                          <Typography
                            variant="h2"
                            style={{
                              paddingBottom: matchesSM ? "24px" : "8px",
                            }}
                          >
                            Seen a doctor and know what treatment you need?
                          </Typography>
                          <Typography
                            variant="h5"
                            style={{ paddingBottom: "35px" }}
                          >
                            Get your medications <br /> and enjoy 20% off
                          </Typography>
                          <Button
                            variant="contained"
                            className={classes.livingButton}
                            onClick={TreatmentPopupOpen}
                          >
                            BUY TREATMENTS
                          </Button>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    style={{
                      marginBottom: "24px",
                    }}
                  >
                    <Card classes={{ root: classes.diagnosisCard }}>
                      <CardContent
                        style={{ paddingTop: "0px", paddingLeft: "38px" }}
                      >
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-around"
                          alignItems="flex-start"
                        >
                          <Typography
                            variant="h2"
                            style={{ paddingBottom: "8px" }}
                          >
                            Doctor told you need a test?
                          </Typography>
                          <Typography
                            variant="h5"
                            style={{ paddingBottom: "35px" }}
                          >
                            Book your follow-up tests <br /> or screenings
                          </Typography>
                          <Button
                            variant="contained"
                            className={classes.livingButton}
                            onClick={() => _redirect("DMP")}
                          >
                            GET TESTED
                          </Button>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/*  Most Popular products*/}
            <Grid
              item
              container
              direction="column"
              className={classes.popularProductContainer}
              alignItems={matchesSM ? "center" : undefined}
            >
              {/*applink */}
              <AppLink />
            </Grid>
            {/* Blogs*/}
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              className={classes.blogContainer}
              align="center"
            >
              <Grid item>
                <Blogs />
              </Grid>
            </Grid>

            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              className={classes.partnerContainer}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.partnerInnerContainer}
              >
                <Typography
                  className={classes.partnerTitle}
                  textAlign="center"
                  style={{ paddingBottom: "42px" }}
                >
                  OUR PARTNERS
                </Typography>
                <Grid item style={{ width: "100%" }}>
                  <PartnerSlider />
                </Grid>
              </Grid>
            </Grid>
            {/* NewsLetter*/}
            <Grid
              item
              container
              direction={matchesMD ? "column" : "row"}
              className={classes.newsLetterContainer}
              alignItems={matchesMD ? "center" : undefined}
            >
              <Grid item className={classes.newsLetterContent}
              style={{width:custommatchesXXS?"300px":undefined}}
              >
                <Typography variant="h1" style={{ paddingBottom:matchesSM?"10px": "24px",fontSize:matchesSM?"35px":undefined }}  
                 align={matchesSM?"left":undefined}
                >
                  Subscribe to our newsletter today!
                </Typography>
                <Typography
                  paragraph
                  style={{ paddingBottom:matchesSM? "10px" :"20"}}
                  className={classes.newLetterSubHeading}
                  align={matchesSM?"left":undefined}
                >
                  Doctall aims at bringing the best of health information that
                  are really importan to you easily.
                </Typography>
                <Typography paragraph className={classes.newLetterSubHeading} 
 align={matchesSM?"left":undefined}                 >
                  Also, helps to keep you abrest emerging health processes that
                  will keep your body and mind in a better shape
                </Typography>
              </Grid>
              <Grid
                item
                container
                className={classes.newsLetterForm}
                md
                justifyContent={custommatchesXL ? "center" : undefined}
              >
                <NewsletterForm />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Applayout>
    </>
  );
};

export default Landing;
