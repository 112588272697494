import React from "react";
import { Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        width: "90px",
        height: "42px",
        padding: "0px",
        
      },
      switchBase: {
        color: "#818181",
        padding: "1px",
        "&$checked": {
          "& + $track": {
            backgroundColor: "#00839B",
            
          }
        }
      },
      thumb: {
        color: "white",
        width: "35px",
        height: "35px",
        margin: "2px",
        background: "linear-gradient(180deg, #FFFFFF 0%, #E8EAEA 100%)",
        
      },
      track: {
        borderRadius: "20px",
        backgroundColor:"#EFEFEF",
        boxShadow: "inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1)",
        Offset: "0px, 6px,rgba(0, 0, 0, 0.1)",
        opacity: "1 !important",
        "&:after, &:before": {
          color: "white",
          fontSize: "15px",
          position: "absolute",
          top: "11px"
        },
        "&:after": {
          content: "'Yes'",
          left: "12px",
          color:"#FFFFFF",
          fontWeight:"500",
          fontFamily:"Visuelt Pro",
        },
        "&:before": {
          content: "'No'",
          right: "12px",
          color:"#002931",
          fontWeight:"500",
          fontFamily:"Visuelt Pro",
        }
      },
      checked: {
        color: "#23bf58 !important",
        transform: "translateX(48px) !important"
      },  
});

export default function Switches() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: true
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        // checked={state.checkedA}
        onChange={handleChange}
        // name={name} id={name}
        // name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </div>
  );
}
