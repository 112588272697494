const blogActions = {

    GET_LIVING_BLOG: "GET_LIVING_BLOG",
    GET_LIVING_BLOG_SUCCESS: "GET_LIVING_BLOG_SUCCESS",

    GET_PEDIA_BLOG: "GET_PEDIA_BLOG",
    GET_PEDIA_BLOG_SUCCESS: "GET_PEDIA_BLOG_SUCCESS",


    getLivingBlog: (categoryId, pageNumber) => {
        return {
            type: blogActions.GET_LIVING_BLOG,
            categoryId,
            pageNumber,
        };
    },

    getPediaBlog: (categoryId, pageNumber) => {
        return {
            type: blogActions.GET_PEDIA_BLOG,
            categoryId,
            pageNumber,
        };
    },

};

export default blogActions;