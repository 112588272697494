import React, { useEffect, useReducer, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Button, Typography, useMediaQuery } from "@material-ui/core";

import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import Banner from "../Banner";
import AppLink from "../../../components/Landing/components/AppLink";
import FAQ from "../../../components/ui/FAQ";
import OfferElipse from "../../../assets/images/OfferElipse.svg";
import SpecialistDoctor from "../SpecialistDoctor";
import LabPackage from "../LabPackage";
import Blogs from "../Blogs";
import Homecare from "../Homecare";
import TreatmentPlan from "./TreatmentPlan";
import CallBackPopUp from "../../callbackPopUp/CallBackPopUP";
import iconPhoneCircle from "../../../assets/images/iconPhoneCircle.svg";
import symptomsBullet from "../../../assets/images/symptoms-bullet.svg";
import { _redirect } from "../../../components/Redirect/Redirect";
import { Helmet } from "react-helmet";
import MetaDecorator from "../utils/metaDecorator";

const useStyles = makeStyles((theme) => styles(theme));

const DiseaseDirectCheckout = ({
  disease,
  offerSelected,
  setOfferSelected,
  title,
  description,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const custommatchesXL = useMediaQuery("(min-width:1500px)");
  const [alignment, setAlignment] = React.useState("");
  const [callBackModel, setCallBackModel] = useState(false);
  // const [offerSelected, setOfferSelected] = useState(
  //   "Speciality Doctor"
  // );
  const [header, setHeader] = useState(
    disease && disease.offerSection && disease.offerSection.offers[0].header
  );

  console.log(header, "headsdfsdfer");
  // const previousHeaderRef = useRef()
  // const previousTitleRef = useRef()
  // useEffect(() => {
  //   setOfferSelected(offerselect);
  // },[]);
  // const SomeComponent = () => {
  const location = useLocation();
  useEffect(() => {
    setOfferSelected(
      disease.offerSection && disease.offerSection.offers[0].title
    );
    console.log("Soundharya", offerSelected);
  }, [disease]);

  /* useEffect(() => {
    console.log(location, "Location changed");
    {
      offerSelected === "Articles & Tips"
        ? setOfferSelected("Speciality Doctor")
        : setOfferSelected("Articles & Tips");
    }
  }, [location]); */

  // }
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const [currentpage, setCurrentPage] = useState({
    header: "",
    offerSelected: "",
  });

  // useEffect(() => {
  //   //Checks if location.pathname is not "/".
  //   setCurrentPage(window.location.href)
  //   if (location.pathname !== "/") setOfferSelected(true);
  //   else setOfferSelected(false);
  // }, []);

  console.log(offerSelected); //Keeps saying true

  // return null;
  //  useEffect(() =>{
  //    setCurrentPage(window.location.href)
  //  },[currentpage])

  // useEffect(() => {
  //   setCurrentPage( window.location.reload(null))
  // },)
  // const refresh = () =>{
  //   if( window.localStorage )
  // {
  //   if( !localStorage.getItem('firstLoad') )
  //   {
  //     localStorage['firstLoad'] = true;
  //     setCurrentPage(window.location.reload())
  //   }
  //   else
  //     localStorage.removeItem('firstLoad');
  // }

  // }

  return (
    <>
      {/* <Helmet>
      <title>{title}</title>
      <meta name="description" content={content} />
    </Helmet> */}
      <MetaDecorator title={title} description={description} />
      <Grid container className={classes.ddcContainer}>
        <Banner
          name={disease && disease.name}
          desc={disease && disease.desc}
          title={disease && disease.title}
          description={disease && disease.description}
          // {console.log(desc,"desc")}
          banner={disease && disease.banner}
          testimonial={disease && disease.testimonial}
        />
        {console.log(disease.name, "disease.name")}
        {disease.name === "Wellness" || disease.name === "Premarital Check" ? (
          <>
            {disease && disease.Whatdoesitcover && (
              <Grid item container className={classes.symptomsContainer}>
                <Grid
                  item
                  container
                  direction={matchesXS ? "row" : "column"}
                  justifyContent={matchesXS ? "flex-start" : undefined}
                  alignItems={matchesXS ? "flex-start" : undefined}
                >
                  <Typography
                    className={classes.sectionHeading}
                    style={{ textAlign: matchesXS ? "left" : undefined }}
                  >
                    What does it cover?
                  </Typography>
                  <hr
                    style={{
                      height: "2px",
                      background: "#FF8D77",
                      borderRadius: "60px",
                      width: "100px",
                      border: "none",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent={matchesSM ? undefined : "space-between"}
                  alignItems={matchesSM ? "center" : undefined}
                >
                  <Grid
                    item
                    direction={matchesXS ? "column" : "row"}
                    style={{ marginBottom: "30px" }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "32px",
                        color: " #002931",
                        marginTop: "20px",
                        width: matchesSM ? "350px" : "400px",
                        width: matchesXS ? "280px" : "350px",
                        // height: matchesSM ?  "auto" : undefined,
                      }}
                    >
                      {disease.Whatdoesitcover.desc}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    //justifyContent={matchesSM ? "center" : undefined}
                    alignItems={matchesSM ? "left" : undefined}
                    style={{
                      maxWidth: matchesXS ? "230px" : "460px",
                      height: matchesXS ? "300px" : "250px",
                      columnGap: "20px",
                    }}
                    md={12}
                    xs={12}
                  >
                    {disease.Whatdoesitcover.symptoms.map((symptom) => (
                      // {console.log(symptoms,"symptoms")}
                      <Typography
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "18px",
                          lineHeight: "28px",
                          color: " #002931",
                          paddingBottom: "16px",
                          //right: "70px",
                          position: "relative",
                          // marginTop: matchesXS ? "5px" : undefined
                          // textOrientation: matchesXS ? "vertical" : "horizontal",
                          // width: "140px",

                          // [theme.breakpoints.down("sm")]:{
                          //   width: "30px",
                          // }
                        }}
                      >
                        <img
                          src={symptomsBullet}
                          alt="list"
                          style={{ marginRight: "9px" }}
                        />
                        {symptom}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <>
            {disease && disease.symptoms && (
              <Grid item container className={classes.symptomsContainer}>
                <Grid item container direction="column">
                  <Typography className={classes.sectionHeading}>
                    Symptoms
                  </Typography>
                  <hr
                    style={{
                      height: "2px",
                      background: "#FF8D77",
                      borderRadius: "60px",
                      width: "100px",
                      border: "none",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent={matchesXS ? undefined : "space-between"}
                  alignItems={matchesXS ? "center" : undefined}
                >
                  <Grid item direction={matchesXS ? "column" : "row"}>
                    <Typography
                      style={{
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "32px",
                        color: " #002931",
                        marginTop: "20px",
                        width: matchesSM ? "300px" : "400px",
                        width: matchesXS ? "280px" : "350px",
                      }}
                    >
                      {disease.symptoms.desc}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    // alignItems={matchesXS ?  "Flex-start" : undefined}
                    // justifyContent={matchesXS ? "center" : undefined}
                    style={{
                      maxWidth: matchesXS ? "300px" : "460px",
                      // height: matchesXS ? "275px" : "250px",
                      columnGap: "20px",
                      marginTop: "30px",
                      paddingBottom:"30px",
                      paddingLeft: matchesXS ? undefined : "30px",
                    }}
                    md={12}
                    xs={12}
                  >
                    {disease.symptoms.symptoms.map((symptom) => (
                      // {console.log(symptoms,"symptoms")}
                      <Typography
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "18px",
                          lineHeight: "28px",
                          color: " #002931",
                          paddingBottom: "16px",
                          //right: "70px",
                          //position: "relative",
                          // height:matchesXS ? "50px" : undefined,
                          // marginTop: matchesXS ? "5px" : undefined
                          // textOrientation: matchesXS ? "vertical" : "horizontal",
                          // width: matchesXS ? "230px" : undefined,

                          // [theme.breakpoints.down("sm")]:{
                          //   width: "30px",
                          // }
                        }}
                      >
                        <img
                          src={symptomsBullet}
                          alt="list"
                          style={{ marginRight: "9px" }}
                        />
                        {symptom}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {/* {disease && disease.symptoms && (
        <Grid item container className={classes.symptomsContainer}>
          <Grid item container direction="column">
            <Typography className={classes.sectionHeading}>Symptoms</Typography>
            <hr
              style={{
                height: "2px",
                background: "#FF8D77",
                borderRadius: "60px",
                width: "100px",
                border: "none",
                marginTop: "8px",
              }}
            />
          </Grid>
          <Grid item container direction="row" justifyContent="space-between">
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "32px",
                color: " #002931",
                marginTop: "20px",
                width: matchesSM ? "550px" : "400px",
              }}
            >
              {disease.symptoms.desc}
            </Typography>
            <Grid
              item
              container
              direction="column"
              style={{
                maxWidth: matchesXS ? "230px" : "460px",
                height: matchesXS ? "480px" : "250px",
                columnGap: "20px",
              }}
              md={12}
              xs={12}
             >
              {disease.symptoms.symptoms.map((symptom) => (
                // {console.log(symptoms,"symptoms")}
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: " #002931",
                    paddingBottom: "16px",
                    right: "70px",
                    position: "relative",
                    // marginTop: matchesXS ? "5px" : undefined
                    // textOrientation: matchesXS ? "vertical" : "horizontal",
                    // width: "140px",

                    // [theme.breakpoints.down("sm")]:{
                    //   width: "30px",
                    // }
                  }}
                >
                  <img
                    src={symptomsBullet}
                    alt="list"
                    style={{ marginRight: "9px" }}
                  />
                  {symptom}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )} */}
        {disease && disease.offerSection && (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.offersContainer}
          >
            <Grid item container direction="column" alignItems="center">
              <Typography className={classes.sectionHeading} align="center">
                {disease && disease.offerSection && disease.offerSection.title}
              </Typography>
              <hr
                style={{
                  width: "100px",
                  height: "2px",
                  background: "#FF8D77",
                  borderRadius: "60px",
                  marginTop: "9px",
                  marginBottom: "16px",
                  border: "none",
                }}
              />
              <Typography className={classes.sectionSubtitle}>
                {disease.offerSection.desc}
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleChange}
                classes={{
                  groupedHorizontal: classes.buttonGroup,
                  groupedVertical: classes.buttonGroupVertical,
                }}
                orientation={matchesSM ? "vertical" : "horizontal"}
              >
                {disease.offerSection.offers.map((offer) => (
                  <Grid item container direction="column" alignItems="center">
                    <ToggleButton
                      value={offer.title}
                      selected={offer.title === offerSelected}
                      className={classes.offerList}
                      style={{
                        backgroundImage: `url(${offer.icon})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        "&.MuiToggleButton-root.Mui-selected": {
                          backgroundImage: `url(${offer.icon})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        },
                      }}
                      disableRipple
                      onClick={() => {
                        // preventDefault(e)
                        setOfferSelected(offer.title);
                        setHeader(offer.header);
                        // refresh(null)
                        // setCurrentPage({[e.target.offerSelected] : e.target.value})
                        console.log(offer,"dasdsdadas")
                      }}
                    ></ToggleButton>
                    {offerSelected === offer.title ? (
                      <>
                        <hr
                          style={{
                            height: "59px",
                            border: "1px dashed #C53B20",
                          }}
                        />
                        <img src={OfferElipse} alt="elipse" />
                      </>
                    ) : null}
                    <Typography style={{ marginTop: "18px" }}>
                      {offer.title}
                    </Typography>
                  </Grid>
                ))}
              </ToggleButtonGroup>
            </Grid>

            <Grid item justifyContent="center" alignItems="center" container>
              <Grid
                item
                container
                justifyContent="space-around"
                className={classes.planIntroContainer}
              >
                {disease.offerSection.planIntro.map((intro) => (
                  <Grid
                    item
                    container
                    direction="column"
                    className={classes.plainIntroInd}
                    alignItems={matchesXS ? "center" : undefined}
                    // style={{
                    //   marginBottom: custommatchesMDX ? "24px" : undefined,
                    // }}
                  >
                  {console.log(intro,"sdfsdfsf")}
                    {intro.header === header ? 
                    (
                      <>
                      {/* <Grid
                    // item
                    // container
                    // direction="column"
                    // className={classes.plainIntroInd}
                    // alignItems={matchesSM ? "center" : undefined}
                    // align={matchesSM ? "center" : undefined}
                    // style={{
                    //   marginBottom: custommatchesMDX ? "24px" : undefined,
                    // }}
                  > */}
                        <img
                          src={intro.icon}
                          alt="thunder-icon"
                          style={{ paddingBottom: "20px" }}
                        />
                        {/* </Grid> */}
                        <Typography className={classes.introTitle}>
                          {intro.title}
                        </Typography>

                        <Grid style={{ paddingLeft: "0px" }}>
                          {intro.content.map((content) => (
                            <Typography className={classes.introContent}>
                              {content}
                            </Typography>
                          ))}
                        </Grid>
                      </>
                    ) :
                    null}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {/* 
          <Grid item container justifyContent="center">
            <Grid
              item
              container
              justifyContent="space-between"
              className={classes.planIntroContainer}
             >
              {disease.offerSection.planIntro.map((intro) => (
                <>  
                {intro.header[2] === header ?(
                              
                <Grid
                item
                container
                direction="column"
                justifyContent="center"
                className={classes.plainIntroInd}
                alignItems="flex-start"
                style={{
                  marginBottom: custommatchesMDX ? "24px" : undefined,
                }}>
                
                    <img
                      src={intro.icon}
                      alt="thunder-icon"
                      style={{ paddingBottom: "20px" }}
                    />
                  
                  <Typography className={classes.introTitle}> 
                  {intro.title}
                  </Typography>
                 {intro.content.map((content) => (
                        <Typography className={classes.introContent}>
                          {content}
                        </Typography>))}
                </Grid>
                
               ) :(
                <Grid
                  item
                  container
                  direction="column"
                  className={classes.plainIntroInd}
                  alignItems="flex-start"
                  style={{
                    marginBottom: custommatchesMDX ? "24px" : undefined,
                  }}
                 >
                  {intro.header === header ? (
                    <img
                      src={intro.icon}
                      alt="thunder-icon"
                      style={{ paddingBottom: "20px" }}
                    />
                  ) : (
                    ""
                  )}
                  {console.log(header, intro.header, "twyurtwer")}

                  <Typography className={classes.introTitle}>
                    {intro.header === header ? intro.title : ""}
                  </Typography>
               
                  {intro.header === header
                    ? intro.content.map((content) => (
                        <Typography className={classes.introContent}>
                          {content}
                        </Typography>
                      ))
                    : ""}
                
                </Grid>
              )
            }
                </>

              ))}
            </Grid>
            

          </Grid> */}
            <Grid
              item
              container
              justifyContent="center"
              style={{
                width: matchesSM?"100%":"89%",
                
                height: "auto",
                backgroundColor:
                  offerSelected === "Articles & Tips" ? undefined : "#ffffff",
              }}
            >
              {offerSelected === "Lab Test Package" ? (
                <LabPackage diseaseName={disease.name} />
              ) : offerSelected === "Speciality Doctor" ? (
                <SpecialistDoctor
                  doctors={disease.specialistDoctors}
                  per_page_count={6}
                  diseaseName={disease.name}
                />
              ) : offerSelected === "Articles & Tips" ? (
                // {onClick={() => window.location.reload(false)}}
                <Blogs blogs={disease.blogs} diseaseName={disease.name} />
              ) : offerSelected === "Home Care" ? (
                <Homecare />
              ) : offerSelected === "Treatment Plans" ? (
                <TreatmentPlan />
              ) : null}
            </Grid>
            {offerSelected === "Treatment Plans" ? (
              <Grid
                item
                container
                direction={matchesXS ? "column" : "row"}
                justifyContent="center"
                alignItems="center"
                style={{ paddingTop: "64px", paddingLeft: "0px" }}
              >
                <img src={iconPhoneCircle} alt="phone" />
                <Grid
                  item
                  container
                  direction="column"
                  className={classes.customerCareContent}
                  style={{
                    paddingTop: matchesXS ? "24px" : "0px",
                    paddingBottom: matchesXS ? "24px" : "0px",
                  }}
                >
                  <Typography
                    className={classes.customerCareTitle}
                    align={matchesXS ? "center" : undefined}
                  >F
                    Not sure the health plan to go for?
                  </Typography>
                  <Typography
                    className={classes.customerCareSubtitle}
                    align={matchesXS ? "center" : undefined}
                  >
                    Speak with one of our customer service agents and get the
                    best personalised advice
                  </Typography>
                </Grid>
                <Button
                  variant="contained"
                  className={classes.customerCareButton}
                  onClick={() => setCallBackModel(!callBackModel)}
                >
                  Speak with Customer Service
                </Button>

                <CallBackPopUp
                  open={callBackModel}
                  close={() => setCallBackModel(!callBackModel)}
                />
              </Grid>
            ) : null}
          </Grid>
        )}

        <Grid
          item
          container
          justifyContent={
            custommatchesXL ? "center" : matchesMD ? "center" : undefined
          }
          alignItems={matchesMD ? "center" : undefined}
          direction={matchesMD ? "column" : "row"}
          style={{ marginTop: "66px" }}
          className={classes.appointContainer}
        >
          <Grid
            item
            className={classes.dWQhomeCare}
            style={{
              marginRight: matchesMD ? "0px" : "48px",
              marginBottom: custommatchesMDX ? "24px" : "24px",
            }}
          >
            <Typography
              className={classes.homeCareTitle}
              style={{ width: matchesSM ? "250px" : "385px" }}
            >
              {disease && disease.appointment}
            </Typography>
            <Button
              variant="contained"
              className={classes.homeCareButton}
              onClick={() => _redirect("CONSUMER-DASHBOARD")}
            >
              Book an appointment
            </Button>
          </Grid>
          <Grid
            item
            className={classes.dWQpurchase}
            style={{
              marginBottom: custommatchesMDX ? "24px" : "24px",
            }}
          >
            <Typography className={classes.homeCareTitle}>
              {disease && disease.purchase}
            </Typography>
            <Button variant="contained" className={classes.homeCareButton}>
              Purchase a Diagnostic Lab Test
            </Button>
          </Grid>
        </Grid>

        {/* <Grid 
        item
        container
        direction="row"
        className={classes.proceedContainer}
      >
        <Grid
          item
          container
          style={{ paddingBottom: "7.1rem" }}
          direction={custommatchesMDX ? "row" : "column"}
          alignItems={custommatchesMDX ? "center" : undefined}
          justifyContent={custommatchesXL ? "center" : undefined}
        >
          <Grid
            item
            className={classes.dWQhomeCare}
            style={{
              marginRight: custommatchesMDX ? "0px" : "38px",
              marginBottom: custommatchesMDX ? "24px" : "24px",
            }}
          >
            <Typography
              className={classes.homeCareTitle}
              style={{ width: matchesSM ? "250px" : "385px" }}
            >
              {disease.appointment}
            </Typography>
            <Button
              variant="contained"
              className={classes.homeCareButton}
              onClick={() => _redirect("CONSUMER")}
            >
              Book an appointment
            </Button>
          </Grid>
          <Grid item className={classes.dWQpurchase}>
            <Typography className={classes.homeCareTitle}>
              {disease.purchase}
            </Typography>
            <Button variant="contained" className={classes.homeCareButton}>
              Purchase a Diagnostic Lab Test
            </Button>
          </Grid>
        </Grid> */}
        <Grid
          item
          container
          justifyContent={
            custommatchesXL ? "center" : matchesXS ? "center" : undefined
          }
          style={{ marginLeft: matchesXS ? "0px" : "92px" }}
        >
          <AppLink />
        </Grid>
        {/* </Grid> */}
        {/* <hr
          style={{
            width: "100%",
            // border: "1px solid #E4E7EC",
            border: "none",
            height: "50px",
            backgroundColor: "rgba(242, 248, 249, 0.5)",
            marginBottom: "30px",
          }}
        /> */}
        {disease && disease.faq && (
          <Grid item container style={{ background: "#FCFCFD" }}>
            <FAQ FAQData={disease.faq} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DiseaseDirectCheckout;
