import {
    Button,
    FormControl, Grid, MenuItem, Select
} from "@material-ui/core";
import React from 'react';
import { Helmet } from 'react-helmet';
import echolab from '../../assets/images/echolab.png';
import healt from '../../assets/images/health_plus.png';
import Medplus from '../../assets/images/Medplus-Logo 1.png';
import RightArrow from '../../assets/images/right_Arrow.png';
import synlab from '../../assets/images/synlab.png';
import team1 from "../../assets/images/team1.png";
import team2 from "../../assets/images/team2.png";
import team3 from "../../assets/images/team3.png";
import team4 from "../../assets/images/team4.png";
import team5 from "../../assets/images/team5.png";
import union from '../../assets/images/union-diagnostics.png';
import Footer from '../../components/Layout/Footer/Footer';
import Header from '../../components/Layout/Header/Header';
import { _redirect } from '../../components/Redirect/Redirect';
import '../AboutaUs/AboutUs.scss';


function OurPartner() {
    const [Partners, setPartners] = React.useState('10');
    const [State, setState] = React.useState('10');
    const [Branch, setBranch] = React.useState('10');
    const[textdecoration1, setTextDecoration] = React.useState(false)
    const[textdecoration2, setTextDecoration2] = React.useState(false)
    const[textdecoration3, setTextDecoration3] = React.useState(false)
    const[textdecoration4, setTextDecoration4] = React.useState(false)
    const[textdecoration5, setTextDecoration5] = React.useState(false)
    // useEffect(()=>{
    //     setTextDecoration(true)
    // })

    const PartnersChange = (event) => {
        setPartners(event.target.value);

    };
    const StateChange = (event) => {
        setState(event.target.value);

    };
    const BranchChange = (event) => {
        setBranch(event.target.value);

    };

    return (
        <>
         <Helmet>
            <title>Our Partners - Doctall
</title>
            <meta 
            name='description' 
            content='Amazing health partner organizations over the years. We work with partners to deliver best quality medical services at cheaper than market rates' 
            />
        </Helmet>
       
            <Header />
           
            <div className='our_partners'>
           
                <div className='banner'>
                    <div className='banner_txt'>
                        <p style={{ color: "#FB6514", fontWeight: 600, fontSize: "16px" }} className="para">Our Partners</p>
                        <h1 className='banner_headding'>Partnership for Impact </h1>
                        <p className='partner_banner_para' >We leverage strategic partnerships with top players in the health industry to efficiently deliver quality medical care to all. </p>
                        <div className='patner_banner_button'>
                            <button className='partner_button' onClick={() => _redirect("CONSUMER-DASHBOARD")} >Book an appointment</button>

                        </div>
                    </div>


                </div>
                <div className='meat_our_partners'>

                    {/* <Button style={{ backgroundColor: "#F9F5FF", color: "#B4331A", fontSize: "14px", borderRadius: "20px", padding: "5px 10px" }}
                        variant="contained" >See Coverage Area</Button> */}
                    <h1 className='Our_parner_heading'>Amazing partner organizations over the years. </h1>
                    <p className='Our_parner_sub_heading'>We work with partners to deliver best quality medical services at cheaper than market rates</p>


                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className='team_card'>
                                <img src={healt} alt="healt_plus" />

                                <p className='partner_para'>HealthPlus Limited is Nigeria’s first Integrative Pharmacy. The fastest growing pharmacy chain in West Africa, HealthPlus is committed to helping people achieve optimum health and vitality.</p>
                                <Button style={{ backgroundColor: "#FFF", color: " #B4331A", fontSize: "14px", borderRadius: "20px", padding: "-10px" }}
                                 
                                ><a href='/coverage-areas'
                                onMouseOver={()=>setTextDecoration(true)}
                                 onMouseOut={()=>setTextDecoration(false)}
                                 style={{color: "black"}}
                                 className={textdecoration1 == true ? "decorate" : "nondecorate"}> View coverage areas &nbsp; <img src={RightArrow} alt="right_arrow" /></a></Button>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className='team_card'>
                                <img src={synlab} alt="synlab" />
                                <p className='partner_para'>SYNLAB Nigeria offers quality diagnostic care to families and supports medical professionals with specialized expertise.
                                    SYNLAB is trusted by millions to provide reliable medical testing and deliver fast and accurate results.</p>
                                <Button style={{ backgroundColor: "#FFF", color: " #B4331A", fontSize: "14px", borderRadius: "20px", padding: "-10px" }}
                                // onMouseOver={(event)=>{
                                //     event.target.value.textDecoration=true,
                                //     handleHover(event)
                                // }}
                                // onMouseOut={(event)=>{
                                //     event.target.value.textDecoration=false,
                                //     handleHoverAway(event)
                                // }}
                                // onMouseOver={()=>setTextDecoration(true)}
                                // className={textdecoration1 == true ? "decorate" : "nondecorate"}
                                ><a href='/coverage-areas'  
                                // style={{
                                //     textDecoration:{textdecoration1 == true ? " " : " "}
                                // }}
                                onMouseOver={()=>setTextDecoration2(true)}
                                onMouseOut={()=>setTextDecoration2(false)}
                                style={{color: "black"}}
                                className={textdecoration2 == true ? "decorate" : "nondecorate"}
                                
                                > View coverage areas &nbsp; <img src={RightArrow} alt="right_arrow" style={{color: "black"}}/></a></Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className='team_card'>
                                <img src={echolab} alt="echolab" />
                                <p className='partner_para'>EchoLab ensures global best practice delivery of diagnostic services in Nigeria by providing a wide range of diagnostic services, including pathology and molecular diagnostics genetics testing and radiology to patients.</p>
                                <Button style={{ backgroundColor: "#FFF", color: " #B4331A", fontSize: "14px", borderRadius: "20px", padding: "-10px" }}
                                ><a href='/coverage-areas'
                                onMouseOver={()=>setTextDecoration3(true)}
                                onMouseOut={()=>setTextDecoration3(false)}
                                style={{color: "black"}}
                                className={textdecoration3 == true ? "decorate" : "nondecorate"}> View coverage areas &nbsp; <img src={RightArrow} alt="right_arrow" /></a></Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}></Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className='team_card'>
                                <img src={Medplus} alt="Girl in a jacket" />
                                <p className='partner_para'>Medplus is Nigeria’s leading health and beauty retailer. With over 61 stores nationwide, Medplus is committed to caring for the mind, body and spirit, and providing the best pharmaceutical products and services.</p>
                                <Button style={{ backgroundColor: "#FFF", color: " #B4331A", fontSize: "14px", borderRadius: "20px", padding: "-10px" }}
                                > <a href='/coverage-areas'
                                onMouseOver={()=>setTextDecoration4(true)}
                                onMouseOut={()=>setTextDecoration4(false)}
                                style={{color: "black"}}
                                className={textdecoration4 == true ? "decorate" : "nondecorate"}> View coverage areas &nbsp; <img src={RightArrow} alt="right_arrow" /></a></Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className='team_card'>
                                <img src={union} alt="Girl in a jacket" />
                                <p className='partner_para'>Union Diagnostic and Clinical Services is a leading indigenous medical diagnostics company offering full and comprehensive diagnostic services with the capacity for conducting services ranging from Sonology to Laboratory Services.</p>
                                <Button style={{ backgroundColor: "#FFF", color: " #B4331A", fontSize: "14px", borderRadius: "20px", padding: "-10px" }}
                                ><a href='/coverage-areas'
                                onMouseOver={()=>setTextDecoration5(true)}
                                onMouseOut={()=>setTextDecoration5(false)}
                                style={{color: "black"}}
                                className={textdecoration5 == true ? "decorate" : "nondecorate"}> View coverage areas &nbsp; <img src={RightArrow} alt="right_arrow" /></a></Button>
                            </div>
                        </Grid>

                    </Grid>

                </div>

            </div>
            <div className='fined_container'>

                <Grid container spacing={0}>
                    <Grid md={6}>
                        <div className="fined_container_text">
                            <h1 className='newletter_heading'>Find the nearest location of our partners</h1>
                            <p className='newsletter_para' >You can see the spread of all our partner services in Nigeria at a glance
                            </p>

                        </div>
                    </Grid>
                    <Grid sm={12} md={6}>

                        <div className='form_container'>
                            <div className='newsletter_form'>
                                <div className='newsletter_form_items'>

                                    <form action="" >
                                        <FormControl fullWidth>
                                            <label for="fname" className='label'>Partners</label>
                                            <Select
                                                className='input_box'
                                                disableUnderline={true}
                                                labelId="demo-simple-select-label"
                                                id="1"
                                                value={Partners}
                                                label="Age"
                                                onChange={PartnersChange}
                                            >
                                                <MenuItem value={10}>HealthPlus</MenuItem>
                                                <MenuItem value={20}>HealthPlus1</MenuItem>
                                                <MenuItem value={30}>HealthPlus2</MenuItem>
                                            </Select>
                                            <label for="lname" className='label'  >State</label>
                                            <Select
                                                className='input_box'
                                                disableUnderline={true}
                                                labelId="demo-simple-select-label"
                                                id="2"
                                                value={State}
                                                label="Age"
                                                onChange={StateChange}
                                            >
                                                <MenuItem value={10}>Lagos</MenuItem>
                                                <MenuItem value={20}>Lagos1</MenuItem>
                                                <MenuItem value={30}>Lagos2</MenuItem>
                                            </Select>

                                            <label for="fname" className='label'>Branch</label>
                                            <Select
                                                className='input_box'
                                                disableUnderline={true}
                                                // labelId="demo-simple-select-label"
                                                id="3"
                                                value={Branch}
                                                label="Age"
                                                onChange={BranchChange}
                                            >
                                                <MenuItem value={10}>Lekki</MenuItem>
                                                <MenuItem value={20}>Lekki1</MenuItem>
                                                <MenuItem value={30}>Lekki2</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <button type="submit" value="Submit" className='newsletter_btn' >See Coverage Area</button>

                                    </form>


                                </div>


                            </div>

                        </div>
                    </Grid>
                </Grid>







                <div className='proactive_container'>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className='team_text'>
                                {/* <p className='join_team'>Join our team</p> */}
                                <h1 className='team_heading'>Be proactive about your health.</h1>
                                <p style={{ color: "#475467", fontWeight: 900 }} className="para">Get weekly health updates, tips and other helpful information from Doctall</p>

                                <p style={{ paddingRight: "50px" }} className="para">Together, we can achieve a healthier society for all<br />
                                    Contact us at <span style={{ color: "#FF5C3C", fontWeight: 600 }}><a href='mailto:support@doctall.com'>support@doctall.com</a></span></p>
                                <Button style={{ backgroundColor: "#C53B20", color: "#fff", fontSize: "14px", marginTop: "10px", padding: "12px 20px", borderRadius: "8px" }} variant="contained">Stay informed</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>

                            <Grid container spacing={0}>
                                <Grid xs={6} sm={6} md={6}>
                                    <img src={team1} alt="Girl in a jacket" style={{ float: "right", paddingTop: "85px", paddingRight: "5px", paddingLeft: "5px" }} />
                                </Grid>
                                <Grid xs={6} sm={6} md={6}>
                                    <img src={team2} alt="Girl in a jacket" style={{ padding: "5px" }} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={0}>
                                <Grid md={1}></Grid>
                                <Grid xs={4} sm={4} md={4}>
                                    <img src={team3} alt="Girl in a jacket" style={{ float: "right", padding: "5px" }} />
                                </Grid>
                                <Grid xs={3} sm={3} md={3}>
                                    <img src={team4} alt="Girl in a jacket" className='team4' style={{ padding: "5px" }} />
                                </Grid>
                                <Grid xs={4} sm={4} md={4}>
                                    <img src={team5} alt="Girl in a jacket" className='team5' />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>


                </div>
            </div>
            <Footer />
        </>
    )
}

export default OurPartner