import React, { useEffect, useState } from "react";
import "../styles.js";
import {
    Grid,
    Typography,
    Checkbox,
} from "@material-ui/core";
import styles from "../styles";
import { makeStyles, useTheme } from "@material-ui/core";
import {
    InputDetails,
} from "../util/UiComp";

const useStyles = makeStyles((theme) => styles(theme));

export default function SingleQuestionInput({ option, question, saveDetails }) {
    const classes = useStyles();
    const [getResponse, setgetResponse] = useState(false);
    const [userRemark, setUserRemark] = useState();
    const [selectedQuestion, setSelectedQuestion] = useState();


    useEffect(() => {
        saveDetails({ question: question.title, value: selectedQuestion, remark: userRemark })
    }, [userRemark]);


    const handleChange = (event) => {
        if (event.target.value === "None") {
            saveDetails({ question: question.title, value: selectedQuestion, remark: "NONE" })
            setSelectedQuestion(event.target.value)
        } else {
            setgetResponse(!getResponse);
            setSelectedQuestion(event.target.value)
        }

    };

    const Unchecked = ({ label }) => (
        <Grid className={classes.quesOption}>
            <Typography className={classes.quesOptionLabel}>{label}</Typography>
        </Grid>
    );
    const Checked = ({ label }) => (
        <Grid className={classes.quesChecked}>
            <Typography className={classes.quesOptionLabel}>{label}</Typography>
        </Grid>
    );


    return (
        <>

            <Checkbox
                onChange={handleChange}
                value={option.option}
                name="checkbox"
                inputProps={{ "aria-label": option.option }}
                icon={<Unchecked label={option.option} />}
                checkedIcon={<Checked label={option.option} />}
                disableFocusRipple
                disableTouchRipple
                disableRipple
            />
            {getResponse && <InputDetails
                userResponse={(data) => setUserRemark(data)}
            />}
        </>
    )
}