import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./styles";
import { showNotification } from "../../components/common-notification/CommonNotification";
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  useTheme,
  Input,
} from "@material-ui/core";
import { applyCoupon } from "../../services/order-services/orderServices";

const useStyles = makeStyles((theme) => styles(theme));
export default function DiscountCode(props) {
  const _userInfo = useSelector((state) => state.authReducer);
  const classes = useStyles();
  const theme = useTheme();

  const { totalAmount, finalAmount, blockCouponid, couponAppliedFlags, diseaseUuid } = props;
  console.log(diseaseUuid,"diseaseUuid")
  const [couponCode, setCouponCode] = useState()
  const [amount, setAmount] = useState('')
  const [successVisibility, setSuccessVisibility] = useState(false)
  const [finalMessage, setFinalMessage] = useState('')

  const handleCoupanCode = (event) => {
    couponAppliedFlags(false)
    setCouponCode(event.target.value)
  }


  useEffect(() => {
    setAmount(totalAmount)
  }, [])


  const handleSubmit = async () => {
    try {

      if (_userInfo.userInfo == undefined || _userInfo.userInfo == null) {
        showNotification('error', "Failed !!", "Please Add Account Details In Order To Apply Discount !")
        throw new Error("Failed");
      }

      if (couponCode == null || couponCode == undefined || couponCode == '') {
        showNotification('error', "Failed", "Please Enter Discount Code")
        throw new Error("Failed");
      }

      let payLoad = {
        "user_uuid": _userInfo.userInfo.uuid,
        "coupon_code": couponCode,
        "total_amount": amount,
        "type": "pharmacy",
        "category": 'pharmacy',
        disease_uuid:diseaseUuid
      }

      let response = await applyCoupon(payLoad, _userInfo.userInfo.token)

      if (response.status == 200 || response.status == 201) {
        setSuccessVisibility(true)
        if (response.data.finalmessage !== null || response.data.finalmessage !== '' && response.data.finalmessage !== undefined) {
          setFinalMessage(response.data.finalmessage)
        } else {
          setFinalMessage('discount')
        }
        setAmount(response.data.finalAmount)
        finalAmount(response.data.finalAmount)
        blockCouponid(response.data.coupon_applied_id)
        couponAppliedFlags(true)
      } else {
        setSuccessVisibility(false)
      }

    } catch (error) {
      if (error && error.response) {
        showNotification('error', "Failed", "" + error.response.data)
      }
      setSuccessVisibility(false)
    }
  }


  return (
    <div>
      {!successVisibility && (
        <div style={{ border: "1px solid #d0d5dd", borderRadius: "2px" }}>
          <Grid item style={{ padding: "16px" }}>
            <Typography
              align="left"
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#344054",
                paddingBottom: "6px",
              }}
            >
              Doctall Discount Code
            </Typography>

            <Input
              type="text"
              placeholder="Enter discount code"
              className={classes.checkoutInput}
              onChange={handleCoupanCode}
              value={couponCode}
              disableUnderline={true}
              style={{ width: "100%" }}
            />

            <Button
              variant="contained"
              className={classes.createAccount}
              onClick={handleSubmit}
            >
              Claim Discount Code{" "}
            </Button>
          </Grid>
        </div>
      )}
      {/* If Promo Code applied sucessfully */}
      {successVisibility && (
        <div className={"input"} style={{ padding: "16px" }}>
          <div className="info-container">
            <div className={classes.infoText}>
              {`Your Discount Code (${couponCode && couponCode
                }) was successfully applied and ${finalMessage} has been applied`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
