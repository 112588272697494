const diagnosticTestAction = {
    GET_DIAGNOSTIC_TEST: "GET_DIAGNOSTIC_TEST",
    GET_DIAGNOSTIC_TEST_SUCCESS: "GET_DIAGNOSTIC_TEST_SUCCESS",

    getDiagnosticTests: (testType) => {
        return {
            type: diagnosticTestAction.GET_DIAGNOSTIC_TEST,
            testType,
        };
    },
};

export default diagnosticTestAction;