import { all } from "redux-saga/effects";
import authSagas from "./authSaga";
import categorySaga from './categorySaga'
import treatmentPlanSaga from './treatmentPlanSaga'
import blogSaga from './blogSaga'
import questionnaireSaga from "./questionnaireSaga";
import doctorsSaga from './doctorsSaga';
import locationsSaga from './locationsSaga'
import diagnosticsTestSaga from './diagnosticsTestSaga';
import packageSaga from "./packageSaga";
import InfluencerSaga from "./registrationSaga";

export default function* rootSaga() {
    yield all([
        authSagas(),
        blogSaga(),
        categorySaga(),
        treatmentPlanSaga(),
        questionnaireSaga(),
        doctorsSaga(),
        locationsSaga(),
        diagnosticsTestSaga(),
        packageSaga(),
        InfluencerSaga(),
    ]);
}