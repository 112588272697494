import React from 'react';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

export default function Applayout(props) {
    const { children } = props;
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    )
}