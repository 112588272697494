import { all, takeEvery, put, fork } from "redux-saga/effects";
import { fetchPackage } from "../../services/package-services/packageServices";
import actions from "../actions";

const { packageActions } = actions;

export function* fetchPackages() {
  yield takeEvery(packageActions.GET_PACKAGE, function* ({ payLoad }) {
    try {
      if (payLoad) {
        const response = yield fetchPackage(payLoad);
        if (response.status === 200) {
          yield put({
            type: packageActions.GET_PACKAGE_SUCCESS,
            packageData: response.data,
          });
        } else {
          throw response;
        }
      } else {
        console.log("-");
      }
    } catch (error) {
      console.log(error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchPackages)]);
}
