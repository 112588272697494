import React from "react";
import "./EdCard.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  // Input,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
} from "@material-ui/core";

class InputBase1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      focused: false,
      errors: []
    };
  }

  handleBlur = evt => {
    const { onBlur } = this.props;

    this.setState({
      touched: true,
      focused: false
    });

    if (onBlur) {
      onBlur(evt);
    }
  };

  handleFocus = evt => {
    const { onFocus } = this.props;

    this.setState({
      focused: true
    });

    if (onFocus) {
      onFocus(evt);
    }
  };

  handleChange = evt => {
    const { onChange } = this.props;

    this.setState({ value: evt.target.value });

    if (onChange) {
      onChange(evt);
    }
  };

  validate() {
    const { required } = this.props;

    let errors = {};

    if (required) {
      if (!this.state.value) {
        errors["required"] = "required";
      }
    }

    return Object.keys(errors).length ? errors : null;
  }

  get attributes() {
    const { className, required } = this.props;

    const errors = this.validate(this.state.value);
    const showErrors = errors && this.state.touched;

    return {
      "iso-latin-only": "",

      ...this.props,

      className:
        (className ? className + " " : "") +
        (showErrors && !this.state.focused ? "form-input-error" : ""),

      "aria-required": !!required,
      "aria-invalid": showErrors && !this.state.focused,

      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      onChange: this.handleChange
    };
  }

  render() {
    if (true) throw new Error("InputBase.render function not implemented");
    return;
  }
}

class Input extends InputBase1 {
  isButton() {
    const { type } = this.props;

    return type === "submit" || type === "reset";
  }

  render() {
    let attributes = this.attributes;
    const { className } = attributes;

    attributes = {
      type: "text",

      ...attributes,

      className:
        "text-5 " +
        (!this.isButton() ? "form-input " : "form-button form-input-shadow ") +
        className
    };

    return (
      <div class='inputWithButton'>
         <Card elevation={0} style={{ width: "620px",  backgroundColor: "#fff", }}>
            <CardContent>
              <Grid item container style={{
                direction: "column",
                justifyContent: "space between",
                alignItems: "flex-start",
                Width: "100%"
              }}
              >
                <Grid item >
                  <Typography gutterBottom variant="h5" 
                  style={{
                    width: "650px",
                  }} >

                       <Typography gutterBottom variant="h5" style={{
                        color: "#002931",
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        FontSize: "20px",
                        fontWeight: "400",
                        // lineHeight: "28px",
                        // Align: "Left",
                        // color: "#002931",
                        // VerticalAlign: "Center",
                        // ParagraphSpacing: "24px",
                      }}>
                <div 
                style={{ width: "90%", height: "10vh" ,textAlign:"center"}} >
                  <input {...attributes} placeholder={"Amount to pay from wallet"} style={{padding:"0px 15px",marginRight:"60px",}}/>
                  <button
                    type="button"
                    value="Alert the text input"
                    onClick={() => console.log("ee")}
                  >Pay from Wallet</button>
                </div>
                      </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
    );
  }
}

export { InputBase1 };
export default Input;
