import react from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./style";
import "./Productslider.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import DMP_Product from "../../assets/images/DMP_Product.png";
import Home_Product from "../../assets/images/Home_Product.png";
import Lap_Product from "../../assets/images/Lap_Product.png";
import Con_Product from "../../assets/images/Con_Product.png";
import Pedia_Product from "../../assets/images/Pedia_Product.png";
import Livi_Product from "../../assets/images/Livi_Product.png";
import Pro_Product from "../../assets/images/Pro_Product.png";
import Mail from "../../assets/images/Mail.png";
import Power from "../../assets/images/Power.png";
import Beat from "../../assets/images/Beat.png";
const useStyles = makeStyles((theme) => styles(theme));

const Productslider = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div className="sliderContainer">
      <Carousel
        infiniteLoop
        showIndicators={true}
        showArrows={false}
        showStatus={false}
        swipeable={matchesXS ? false : true}
        showThumbs={false}
      
      >
        <div className={classes.banner}>
          <Grid container direction="column" alignItems="center">
            <Grid
              item
              style={{
                background: "#F9F5FF",
                borderRadius: "16px",
                padding: "4px 12px",
                marginBottom: "16px",
              }}
            >
              <Typography className={classes.bannerPrimary}>Product</Typography>
            </Grid>
            <Typography variant="h4" className={classes.sliderHeading}>Diagnostics market place</Typography>
            <Typography variant="h8" align="center" className={classes.sliderSubHeading}>
              Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.
              </Typography>
            <Grid
              item >
              <img src={DMP_Product} alt="DMP" />
            </Grid>

            <Grid container item  >
              <>
              <Grid container className={classes.sliderBelowTextContainer}>
              <Grid xs={12} md={4} sm={12} align="center">
                 <img
                  src={Mail}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center"
                 style={{color:"#101828", fontSize:"20px", fontWeight:"500", padding:"10px"}}
               
                >Lorem ipsum dolor sit</Typography>
                
                <Typography className={classes.bannerSupPara}  >
              Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Power}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
              
                >Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara}>
              An all-in-one customer service platform that helps you balance everything your customers need to be happy.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Beat}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
               
                >
                Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara}>
                Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.
              </Typography>
                </Grid>
                </Grid>
              </>
            </Grid>

            <Grid item className={classes.sliderControl}>
              <Grid item className={classes.sliderControlContent}>
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "30px",
                    lineHeight: "38px",
                  }}
                  align={matchesXS ? "center" : undefined}
                >
                  See more of our exciting products
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.banner}>
          <Grid container direction="column" alignItems="center">
            <Grid
              item
              style={{
                background: "#F9F5FF",
                borderRadius: "16px",
                padding: "4px 12px",
                marginBottom: "16px",
              }}
            >
              <Typography className={classes.bannerPrimary}>Tele Consultation</Typography>
            </Grid>
            <Typography variant="h4" className={classes.sliderHeading}>
              Expert/Quality care in your home
            </Typography>
            <Typography variant="h8" className={classes.sliderSubHeading}>
              Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.
              </Typography>
            <Grid
              item
              style={{
                width: matchesSM ? "90%" : "1032px",
                height: "auto",
                marginTop: "64px",
              }}
            >
              <img src={Home_Product} alt="DMP" />
            </Grid>
            <Grid container
            item
            style={{
              // width: matchesSM ? "100%" : "100%",
              marginTop:"50px",
            }}
            >
              
              <>
              <Grid container className={classes.sliderBelowTextContainer}>
              <Grid xs={12} md={4} sm={12} align="center">
                 <img
                  src={Mail}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center"
                 style={{color:"#101828", fontSize:"20px", fontWeight:"500", padding:"10px"}}
               
                >Lorem ipsum dolor sit</Typography>
                
                <Typography className={classes.bannerSupPara} >
              Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Power}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
              
                >Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara}
                >
              An all-in-one customer service platform that helps you balance everything your customers need to be happy.
              </Typography>
                </Grid>

              <Grid xs={4} align="center"><img
                  src={Beat}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
               
                >
                Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara}
                >
                Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.
              </Typography>
                </Grid>
                </Grid>
              </>
            
            </Grid>

            <Grid item className={classes.sliderControl}>
              <Grid item className={classes.sliderControlContent}>
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "30px",
                    lineHeight: "38px",
                  }}
                  align={matchesXS ? "center" : undefined}
                >
                  See more of our exciting products
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.banner}>
          <Grid container direction="column" alignItems="center">
            <Grid
              item
              style={{
                background: "#F9F5FF",
                borderRadius: "16px",
                padding: "4px 12px",
                marginBottom: "16px",
              }}
            >
              <Typography className={classes.bannerPrimary}>Product</Typography>
            </Grid>
            <Typography variant="h4" className={classes.sliderHeading}> Lab Test Packages</Typography>
            <Typography variant="h8" className={classes.sliderSubHeading}>
              Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.
              </Typography>
            <Grid
              item
              style={{
                width: matchesSM ? "90%" : "1032px",
                height: "auto",
                marginTop: "64px",
              }}
            >
              <img src={Lap_Product} alt="DMP" />
            </Grid>
            <Grid container
            item
            style={{
              // width: matchesSM ? "100%" : "100%",
              marginTop:"50px",
            }}
            >
              <>
              <Grid container className={classes.sliderBelowTextContainer}>
              <Grid xs={12} md={4} sm={12} align="center">
                 <img
                  src={Mail}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center"
                 style={{color:"#101828", fontSize:"20px", fontWeight:"500", padding:"10px"}}
               
                >Lorem ipsum dolor sit</Typography>
                
                <Typography className={classes.bannerSupPara}>
              Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Power}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
              
                >Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara} >
              An all-in-one customer service platform that helps you balance everything your customers need to be happy.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Beat}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
               
                >
                Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara} >
                Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.
              </Typography>
                </Grid>
                </Grid>
              </>
            </Grid>
            <Grid item className={classes.sliderControl}>
              <Grid item className={classes.sliderControlContent}>
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "30px",
                    lineHeight: "38px",
                  }}
                  align={matchesXS ? "center" : undefined}
                >
                  See more of our exciting products
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.banner}>
          <Grid container direction="column" alignItems="center">
            <Grid
              item
              style={{
                background: "#F9F5FF",
                borderRadius: "16px",
                padding: "4px 12px",
                marginBottom: "16px",
              }}
            >
              <Typography className={classes.bannerPrimary}>Tele Consultation</Typography>
            </Grid>
            <Typography variant="h4" className={classes.sliderHeading}>
              Quality healthcare on your terms
            </Typography>
            <Grid
              item
              style={{
                width: matchesSM ? "90%" : "1032px",
                height: "auto",
                marginTop: "64px",
              }}
            >
              <img src={Con_Product} alt="DMP" />
            </Grid>
            <Grid container
            item
            style={{
              // width: matchesSM ? "100%" : "100%",
              marginTop:"50px",
            }}
            >
              <>
              <Grid container className={classes.sliderBelowTextContainer}>
              <Grid xs={12} md={4} sm={12} align="center">
                 <img
                  src={Mail}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center"
                 style={{color:"#101828", fontSize:"20px", fontWeight:"500", padding:"10px"}}
               
                >Lorem ipsum dolor sit</Typography>
                
                <Typography className={classes.bannerSupPara} >
              Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Power}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
              
                >Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara}  >
              An all-in-one customer service platform that helps you balance everything your customers need to be happy.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Beat}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
               
                >
                Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara}  >
                Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.
              </Typography>
                </Grid>
                </Grid>
              </>
            </Grid>
            <Grid item className={classes.sliderControl}>
              <Grid item className={classes.sliderControlContent}>
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "30px",
                    lineHeight: "38px",
                  }}
                  align={matchesXS ? "center" : undefined}
                >
                  See more of our exciting products
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.banner}>
          <Grid container direction="column" alignItems="center">
            <Grid
              item
              style={{
                background: "#F9F5FF",
                borderRadius: "16px",
                padding: "4px 12px",
                marginBottom: "16px",
              }}
            >
              <Typography className={classes.bannerPrimary}>Doctallpedia</Typography>
            </Grid>
            <Typography variant="h4" className={classes.sliderHeading}>
              Get the best health fact from Doctallpedia
            </Typography>
            <Typography variant="h8" className={classes.sliderSubHeading}>
              Find out all you need to know about symptoms, prevention, and treatment of over 500 health conditions
              </Typography>
            <Grid
              item
              style={{
                width: matchesSM ? "90%" : "1032px",
                height: "auto",
                marginTop: "64px",
              }}
            >
              <img src={Pedia_Product} alt="DMP" />
            </Grid>
            <Grid container
            item
            style={{
              // width: matchesSM ? "100%" : "100%",
              marginTop:"50px",
            }}
            >
             <>
              <Grid container className={classes.sliderBelowTextContainer}>
              <Grid xs={12} md={4} sm={12} align="center">
                 <img
                  src={Mail}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center"
                 style={{color:"#101828", fontSize:"20px", fontWeight:"500", padding:"10px"}}
               
                >Lorem ipsum dolor sit</Typography>
                
                <Typography className={classes.bannerSupPara} >
              Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
              </Typography>
                </Grid>

              <Grid xs={4} align="center"><img
                  src={Power}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
              
                >Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara} >
              An all-in-one customer service platform that helps you balance everything your customers need to be happy.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Beat}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
               
                >
                Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara} >
                Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.
              </Typography>
                </Grid>
                </Grid>
              </>
            </Grid>
            <Grid item className={classes.sliderControl}>
              <Grid item className={classes.sliderControlContent}>
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "30px",
                    lineHeight: "38px",
                  }}
                  align={matchesXS ? "center" : undefined}
                >
                  See more of our exciting products
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.banner}>
          <Grid container direction="column" alignItems="center">
            <Grid
              item
              style={{
                background: "#F9F5FF",
                borderRadius: "16px",
                padding: "4px 12px",
                marginBottom: "16px",
              }}
            >
              <Typography className={classes.bannerPrimary}>Doctall Living</Typography>
            </Grid>
            <Typography variant="h4" className={classes.sliderHeading}>Diagnostics market place</Typography>
            <Typography variant="h8" className={classes.sliderSubHeading}>
              Find out all you need to know about symptoms, prevention, and treatment of over 500 health conditions
              </Typography>
            <Grid
              item
              style={{
                width: matchesSM ? "90%" : "1032px",
                height: "auto",
                marginTop: "64px",
              }}
            >
              <img src={Livi_Product} alt="DMP" />
            </Grid>
            <Grid container
            item
            style={{
              // width: matchesSM ? "100%" : "100%",
              marginTop:"50px",
            }}
            >
             <>
              <Grid container className={classes.sliderBelowTextContainer}>
              <Grid xs={12} md={4} sm={12} align="center">
                 <img
                  src={Mail}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center"
                 style={{color:"#101828", fontSize:"20px", fontWeight:"500", padding:"10px"}}
               
                >Lorem ipsum dolor sit</Typography>
                
                <Typography className={classes.bannerSupPara} >
              Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Power}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
              
                >Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara} >
              An all-in-one customer service platform that helps you balance everything your customers need to be happy.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Beat}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
               
                >
                Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara}
                >
                Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.
              </Typography>
                </Grid>
                </Grid>
              </>
            </Grid>
            <Grid item className={classes.sliderControl}>
              <Grid item className={classes.sliderControlContent}>
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "30px",
                    lineHeight: "38px",
                  }}
                  align={matchesXS ? "center" : undefined}
                >
                  See more of our exciting products
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.banner}>
          <Grid container direction="column" alignItems="center">
            <Grid
              item
              style={{
                background: "#F9F5FF",
                borderRadius: "16px",
                padding: "4px 12px",
                marginBottom: "16px",
              }}
            >
              <Typography className={classes.bannerPrimary}>Corporate Health</Typography>
            </Grid>
            <Typography variant="h4" className={classes.sliderHeading}>Leading the way to better Corporate Health</Typography>
            <Typography variant="h8" className={classes.sliderSubHeading} >
              Discover new ways to achieve optimum employee health from hire to retire
              </Typography>
            <Grid
              item
              style={{
                width: matchesSM ? "90%" : "1032px",
                height: "auto",
                marginTop: "64px",
              }}
            >
              <img src={Pro_Product} alt="DMP" />
            </Grid>
            <Grid container
            item
            style={{
              // width: matchesSM ? "100%" : "100%",
              marginTop:"50px",
            }}
            >
               <>
              <Grid container className={classes.sliderBelowTextContainer}>
              <Grid xs={12} md={4} sm={12} align="center">
                 <img
                  src={Mail}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center"
                 style={{color:"#101828", fontSize:"20px", fontWeight:"500", padding:"10px"}}
               
                >Lorem ipsum dolor sit</Typography>
                
                <Typography className={classes.bannerSupPara} >
              Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Power}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
              
                >Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara} >
              An all-in-one customer service platform that helps you balance everything your customers need to be happy.
              </Typography>
                </Grid>

              <Grid xs={12} md={4} sm={12} align="center"><img
                  src={Beat}
                  alt="Mail-icon"
                  style={{ textAlign: "center",width:"50px" }}
                />
                <Typography align="center" style={{color:"#101828", fontSize:"20px", fontWeight:"500",padding:"10px"}}
               
                >
                Lorem ipsum dolor sit</Typography>
                <Typography className={classes.bannerSupPara} >
                Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.
              </Typography>
                </Grid>
                </Grid>
              </>
            </Grid>
            <Grid item className={classes.sliderControl}>
              <Grid item className={classes.sliderControlContent}>
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "30px",
                    lineHeight: "38px",
                  }}
                  align={matchesXS ? "center" : undefined}
                >
                  See more of our exciting products
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Carousel>
    </div>
  );
};

export default Productslider;
