import React, { useState } from "react";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import styles from "../styles";

const useStyles = makeStyles((theme) => styles(theme));

export const NeutralComp = ({ value }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.neutralComp}>
      <Typography
        style={{
          fontFamily: "Visuelt Pro",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "28px",
          color: "#002931",
        }}
      >
        {value}
      </Typography>
    </Grid>
  );
};
export const YesComp = () => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.neutralComp}
      style={{ border: "2px solid #982B16" }}
    >
      <Typography
        style={{
          fontFamily: "Visuelt Pro",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "28px",
          color: "#002931",
        }}
      >
        Yes
      </Typography>
    </Grid>
  );
};
export const NoComp = () => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.neutralComp}
      style={{ border: "2px solid #005F71" }}
    >
      <Typography
        style={{
          fontFamily: "Visuelt Pro",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "28px",
          color: "#002931",
        }}
      >
        No
      </Typography>
    </Grid>
  );
};

export const Alert = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      className={classes.VisitDoctorAlert}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Grid>
  );
};

export const InputDetails = ({ userResponse }) => {
  const classes = useStyles();
  const [textArea, setTextArea] = useState("")

  const onChangeText =(e) => {
    const input = e.currentTarget.value;
    if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
      setTextArea(input);
  }
  }
  const _handleKeyDown = (e) => {
    userResponse(e.target.value);
  };



  return (
    <Grid
      item
      container
      className={classes.inputmultilineContainer}
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        style={{
          fontFamily: "Visuelt Pro",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "30px",
          color: "#002931",
          marginBottom: "200px",
          textAlign: "center",
          margin: "30px",
          alignContent: "center",

        }}
        align="center"
      >
        Please specify <br />
      </Typography>
      <textarea
      onInput={onChangeText}
      value={textArea}
        type="text"
        name="mRemark"
        rows="3"
        required
        onChange={(event) => _handleKeyDown(event)}
        className={classes.inputFieldMultiline}
        placeholder="State nature of reaction"
      />
    </Grid>
  );
};
