import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "../styles";
import appStore from "../../../assets/images/appStore.svg";
import playStore from "../../../assets/images/playStore.png";

const useStyles = makeStyles((theme) => styles(theme));

const AppLink = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const custommatchesXL = useMediaQuery("(min-width:1500px)");
  console.log(window ,"Windows.location.pathname ")
  return (
   
    <Grid
      item
      container
      style={{ marginBottom:matchesSM?"4.1rem":"8.1rem",
      // marginLeft: matchesXS ? "-27px" : undefined 
    }}
      className={classes.appLinkContainer}
    >
      <Grid
        item
        container
        direction="column"
        alignItems={matchesSM ? "center" : undefined}
        className={classes.linkBox}
      >
        <Grid
          item
          style={{
            height: "auto",
            paddingBottom: matchesXS ? "20px" : "24px",
            paddingLeft: matchesSM ? "20px" : null,
            paddingRight: matchesSM ? "20px" : null,
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "700",
              color: "#ffffff",
              fontSize: matchesSM ? (matchesXS ? "22px" : "28px") : null,
              lineHeight: matchesSM ? (matchesXS ? "36px" : "40px") : null,
              marginLeft: matchesSM ? (matchesXS ? "20px" : "35px") : null,
            }}
          >
            Sign Up and get 10% off on your
            {matchesSM ? null : <br />} first purchase
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction={matchesXS ? "column" : "row"}
          alignItems={matchesSM ? "center" : undefined}
          justifyContent={matchesSM ? "center" : undefined}
          style={{
            marginBottom: "4.7rem",
          }}
        >
          <Grid
            item
            style={{
              marginRight: matchesXS ? "0px" : "22px",
              marginBottom: matchesXS ? "24px" : "0px",
            }}
          >
            <a
              target="_blank"
              href="https://apps.apple.com/ng/app/doctall/id1544455716"
            >
              <img src={appStore} alt="AppStore" />
            </a>
          </Grid>
          <Grid item
          >
         <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.doctall"
            >
              <img src={playStore} alt="GooglePlay" />{" "}
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  
  );
};

export default AppLink;
