import { _SAVE_ORDER_DETAILS, _BLOCK_COUPON, _APPLY_COUPON, _PLACE_ORDER, _REQUEST_CALLBACK, _POST_TRANSACTION, _CHANGE_ORDER_STATUS, _GET_ADDRESS } from '../endpoints';
import axios from 'axios';

export const placeOrder = async (payLoad) => {
    let response = await axios.post(_PLACE_ORDER, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed");
}

export const saveOrderDetails = async (payLoad) => {
    let response = await axios.post(_SAVE_ORDER_DETAILS, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed");
}


export const requestCallBack = async (payLoad) => {
    let response = await axios.post(_REQUEST_CALLBACK, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed");
}


export const postTransaction = async (transactionId, authToken) => {
    if(authToken === ''){
        let response = await axios.get(`${_POST_TRANSACTION}/${transactionId}`)
        if (response && response.status === 200 || response.status === 201) {
            return response;
        }
        throw new Error("Failed");
    }
    else {
        let response = await axios.get(`${_POST_TRANSACTION}/${transactionId}`, {
            headers: { "x-auth-token": authToken }
        })
        if (response && response.status === 200 || response.status === 201) {
            return response;
        }
        throw new Error("Failed");
    }

}


export const changeStatus = async (transactionId, authToken) => {
    if(authToken === ''){
        let response = await axios.put(`${_CHANGE_ORDER_STATUS}/${transactionId}/paid`)
        if (response && response.status === 200 || response.status === 201) {
            return response;
        }
        throw new Error("Failed");
    }
    else {
        let response = await axios.put(`${_CHANGE_ORDER_STATUS}/${transactionId}/paid`, {
            headers: { "x-auth-token": authToken }
        })
        if (response && response.status === 200 || response.status === 201) {
            return response;
        }
        throw new Error("Failed");
    }

}



export const applyCoupon = async (payLoad, authToken) => {
    if(authToken === ''){
        let response = await axios.post(_APPLY_COUPON, payLoad)
        if (response && response.status === 200 || response.status === 201) {
            return response;
        }
        throw new Error("Failed");
    }
    else {
        let response = await axios.post(_APPLY_COUPON, payLoad, {
            headers: { "x-auth-token": authToken }
        })
        if (response && response.status === 200 || response.status === 201) {
            return response;
        }
        throw new Error("Failed");
    }

}

export const blockCoupon = async (couponId, transactionId, authToken) => {
    let response = await axios.put(`${_BLOCK_COUPON}/${couponId}/${transactionId}`, {
        headers: { "x-auth-token": authToken }
    })
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed");
}

export const getAddressDetails = async (id, authToken) => {
    let response = await axios.get(`${_GET_ADDRESS}/${id}`, {
        headers: { "x-auth-token": authToken }
    })
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed");
}
