import axios from "axios";
import { GET_DISEASES_CATEGORY, GET_DISEASES_QUESTIONS, _SAVE_DISEASES_QUESTIONS } from "../endpoints";


export const fetchCategory = () => {
    return axios.get(GET_DISEASES_CATEGORY)
}

export const fetchQuestions = (id) => {
    return axios.get(GET_DISEASES_QUESTIONS + '/' + id)
}


export const saveQuestionnaire = async (payLoad) => {
    let response = await axios.post(_SAVE_DISEASES_QUESTIONS, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to save questions");
}





