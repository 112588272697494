import React, { lazy, Suspense, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import EdDUPLICATE from "../src/pages/Questionnaire/util/EDquestionsduplicate";
import EdDUPLICATE1 from '../src/pages/Questionnaire/util/QuestionCard1';
import EdCard from './pages/Questionnaire/util/EdCard';
import EdCheckout from './pages/Questionnaire/util/EdCheckout';
import EdShipping from './pages/Questionnaire/util/EdShipping';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import LandingPage from "./components/Landing";
import Appointments from "./components/Layout/layout/Appointments";
import Dashboard from "./components/Layout/layout/Dashboard";
import SidebarLayout from "./components/Layout/SidebarLayout/SidebarLayout";
import LoaderComponent from "./components/Loading/LoaderComponent";
import Testing from "./components/testing/Testing";
import AboutUs from "./pages/AboutaUs/AboutUs";
import CurrentPasscode from "./pages/Auth/ChangePasscode/CurrentPasscode";
import NewPasscode from "./pages/Auth/ChangePasscode/NewPasscode";
import PasscodeChanged from "./pages/Auth/ChangePasscode/PasscodeChanged";
import CreatNewPasscode from "./pages/Auth/ForgotPasscode/CreatNewPasscode";
import ForgotPasscode from "./pages/Auth/ForgotPasscode/ForgotPasscode";
import PasscodeResetSuccess from "./pages/Auth/ForgotPasscode/PasscodeResetSuccess";
import ConfirmVerification from "./pages/Auth/RegistrationPages/ConfirmVerification";
import RegistationForm from "./pages/Auth/RegistrationPages/registrationform";
import RegistrationPage from "./pages/Auth/RegistrationPages/RegistrationPage";
import SetupPasscode from "./pages/Auth/RegistrationPages/SetupPasscode";
import VerificationCode from "./pages/Auth/RegistrationPages/VerificationCode";
import LoginVerificationCode from "./pages/Auth/RegistrationPages/VerificationCodethroughLogin";
import CancelAndRefund from "./pages/CancellationAndRefund/CancelAndRefund";
import Careers from "./pages/Careers/Careers";
import Checkout from "./pages/Checkout/Checkout";
import Checkouts from "./pages/Checkout/newEDcheckout/Checkout";
import CoverageAreas from "./pages/CoverageAreas/CoverageAreas";
import Disease from "./pages/diseases/Disease";
import PharmacyPage from "./pages/doctall-pharmacy/PharmacyPage";
import Doctor from "./pages/DoctorRecuriter/index";
import Legal from "./pages/LegalPage/Legal";
import PrivacyAndPolicy from "./pages/LegalPage/PrivacyAndPolicy";
import ServiceDeliveryPolicy from "./pages/LegalPage/ServiceDeliveryPolicy";
import MensHelth from "./pages/MensHelth/MensHelth";
import OurPartner from "./pages/PartnersPage/OurPartner";
import Questionnaire from "./pages/Questionnaire/QuestionnaireLanding";
import QuestionnaireSummary from "./pages/Questionnaire/QuestionnaireSummary";
import ContactUs from "./pages/staticPages/ContactUs";
import Faq from "./pages/staticPages/Faq";
import Whoareyou from "./pages/Whoareyou/index";
import authActions from "./redux/actions/authActions";
import AppContainer from "./utils/AppContainer";
import { usernameKey, passcodeKey } from "./utils/constants";
import { decrypt, encrypt } from "./utils";
import InfluencerRegistration from "./pages/Auth/RegistrationPages/InfuencerRegistration";
import RegistrationSucess from "./pages/Auth/RegistrationPages/registrationSucess";
import StaticED from "./pages/staticPages/utils/staticEd/staticED";
import StaticPE from "./pages/staticPages/utils/staticPe/staticPE";
import StaticHL from "./pages/staticPages/utils/staticHl/staticHL";
import NotFound from "./pages/notFound";
import HeartDay from "./components/popup/HeartDay";
import FreeDoctor from "./components/popup/FreeDoctor";
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';



const LoginPage = lazy(() => import("./pages/Auth/LoginPage/LoginPage"));

export default function BaseRoutes({ history }) {
  // const [cookies] = useCookies([usernameKey]);
  const _userInfo = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const [cookies, setCookie] = useCookies([usernameKey, passcodeKey]);


  const addFireBaseAnalytics = async (payLoad) => {
    try {
      await getFirebaseAnalyticsInstance().logEvent("LOGIN_SUCCESS", {
        eventType: "LOGIN" + "USER : " + payLoad.username,
        platform: "web",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addFireBaseAnalyticsLogout = async () => {
    try {
      await getFirebaseAnalyticsInstance().logEvent("USER_LOGOUT", {
        eventType: "LOGOUT",
        platform: "web",
      });
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (!_userInfo.userInfo && cookies[usernameKey] && cookies[passcodeKey]) {
  //     const username = decrypt(cookies[usernameKey]);
  //     const password = decrypt(cookies[passcodeKey]);
  //     // const val = { username, password, type: "loginwithcredential" };
  //     let payLoad = {
  //       username: username,
  //       password: password,
  //       type: "loginwithcredential",
  //     };
  //     dispatch(authActions.handleLogin(payLoad, (res) => {
  //       console.log(res, "verified")
  //     }));
  //   addFireBaseAnalytics(payLoad);
  //   }
  //   // alert(cookies["redirect"])
  // }, [cookies]);

  const cookieLogin = () => {
    console.log(cookies[usernameKey], cookies[passcodeKey], "Coookie11")
    if (!_userInfo.userInfo && cookies[usernameKey] && cookies[passcodeKey] && count <= 1) {
      const username = decrypt(cookies[usernameKey]);
      const password = decrypt(cookies[passcodeKey]);
      // const val = { username, password, type: "loginwithcredential" };
      let payLoad = {
        username: username,
        password: password,
        type: "loginwithcredential",
      };
      dispatch(authActions.handleLogin(payLoad, (res) => {
        console.log(res, "verified")
      }));
      addFireBaseAnalytics(payLoad);
    }
    else if (_userInfo.userInfo && (cookies[usernameKey] === undefined || cookies[passcodeKey] === undefined)) {
      dispatch(authActions.userLogout());
      // navigate("/login");
      setCount(0)
      addFireBaseAnalyticsLogout();
      // history.push("/login");
    }
  }

  const [count, setCount] = useState(0)

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      // console.log(cookies[usernameKey] , cookies[passcodeKey], "Coookie")
      setCookie('ddd')
      setCount(count + 1)
      cookieLogin();
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
    // alert(cookies["redirect"])
  }, [cookies, count]);

  console.log(_userInfo, "_userInfo_userInfo");
  var LoginType = _userInfo.userInfo;
  useEffect(() => {
    if (_userInfo.userInfo && !cookies[usernameKey]) {
      if (
        LoginType.login_type !== "" &&
        LoginType.login_type !== "facebook" &&
        LoginType.login_type !== "google"
      ) {
        dispatch(authActions.userLogout());
      }
    }
  }, [cookies]);

  useEffect(() => {
    // setOpen(true)
  })
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Suspense
      fallback={
        <div>
          <LoaderComponent />
        </div>
      }
    >

      <BrowserRouter>
        <AppContainer>
          <Routes history={history}>
            <Route exact path="/not-found" element={<NotFound />} />
            <Route exact path="*" element={<NotFound />} />
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/registration" element={<RegistrationPage />} />
            <Route path="/user-register" element={<InfluencerRegistration />} />
            <Route
              path="/RegistrationSucess"
              element={<RegistrationSucess />}
            />
            <Route
              exact
              path="/ed"
              element={<EdDUPLICATE />}
            />
            <Route
              exact
              path="/EdCard"
              element={<EdCard />}
            />
            <Route
              exact
              path="/ed1"
              element={<EdDUPLICATE1 />}
            />
            <Route
              exact
              path="/EdCheckout"
              element={<EdCheckout />}
            />
            <Route
              exact
              path="/EdShipping"
              element={<EdShipping />}
            />
            <Route
              exact
              path="/verification-code"
              element={<VerificationCode />}
            />
            <Route
              exact
              path="/login_verification_code"
              element={<LoginVerificationCode />}
            />
            <Route
              exact
              path="/verification-success"
              element={<ConfirmVerification />}
            />
            <Route
              exact
              path="/new-passcode-setup"
              element={<CreatNewPasscode />}
            />

            <Route exact path="/set-passcode" element={<SetupPasscode />} />
            <Route
              exact
              path="/current-passcode"
              element={<CurrentPasscode />}
            />
            <Route exact path="/new-passcode" element={<NewPasscode />} />
            <Route
              exact
              path="/change-passcode"
              element={<PasscodeChanged />}
            />
            <Route exact path="/forgot-passcode" element={<ForgotPasscode />} />
            <Route exact path="/erectile/dysfunction" element={<StaticED />} />
            <Route exact path="/premature/ejaculation" element={<StaticPE />} />
            <Route exact path="/hairloss" element={<StaticHL />} />
            <Route
              exact
              path="/code-verification"
              element={<VerificationCode />}
            />
            <Route
              exact
              path="/creat-new-passcode"
              element={<CreatNewPasscode />}
            />
            <Route
              exact
              path="/reset-password-success"
              element={<PasscodeResetSuccess />}
            />

            <Route exact path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/partner" element={<OurPartner />} />
            <Route path="/coverage-areas" element={<CoverageAreas />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/terms-condition" element={<Legal />} />
            <Route exact path="/who-we-are" element={<Whoareyou />} />
            <Route path="/mens-helth" element={<MensHelth />} />
            <Route path="/registrationform" element={<RegistationForm />} />
            <Route path="/cancel-and-refund" element={<CancelAndRefund />} />
            <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
            <Route
              path="/delivery-policy"
              element={<ServiceDeliveryPolicy />}
            />
            <Route
              path="/:selectedDisease/questionnaire"
              element={<Questionnaire />}
            />
            <Route path="/summary" element={<QuestionnaireSummary />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/Doctor" element={<Doctor />} />

            <Route path="/checkouts" element={<Checkouts />} />


            <Route path="/:selectedDisease" element={<Disease />} />
            <Route path="/doctall-pharmacy" element={<PharmacyPage />} />
            <Route path="/doctall-prime" element={<PharmacyPage />} />
            <Route exact path="/doctall-tests" element={<Testing />} />

            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/dashboard-layout" element={<SidebarLayout />} />
            <Route path="/appointments" element={<Appointments />} />
          </Routes>
          <FreeDoctor />
          {/* <HeartDay /> */}
        </AppContainer>
      </BrowserRouter>
    </Suspense>
  );
}
