export const footerLinks = [
  {
    id: 1,
    title: "PRODUCTS",
    subroute:{
      id: 1.1,
      title: "COMMUNITY",
      routes: [{
        id: 1.12,
        path: "https://doctall.com/foundation/",
        label: "Doctall Foundation",
        target: "_blank",
      },
      ],
    },
    routes:[
    {
      id: 1.2,
      path: "https://diagnostics.doctall.com/",
      label: "Diagnostics",
      target: '_blank'
    },
    {
      id: 1.3,
      path: "https://consumer.doctall.com/",
      label: "Book a Doctor",
      target: '_blank'
    },
    {
      id: 1.4,
      path: "https://doctall.com/homecare",
      label: "Home Care",
      target: '_blank'
     },
    
  ],
    },
  
  {
    id: 2,
    title: "RESOURCES",
    subroute: {
      id: 2,
      title: "CORPORATE",
      routes: [
        {
          id: 2.1,
          path: "https://doctall.com/doctallpro/",
          label: "Doctall Pro",
          target: '_blank'
        },
      ],
    },
    routes: [
      {
        id: 2.1,
        path: "https://doctall.com/pedia/",
        label: "Doctallpedia",
        target: '_blank'
      },
      {
        id: 2.2,
        path: "https://doctall.com/living/",
        label: "Doctall Living",
        target: '_blank'
      },
      // {
      //   id: 2.3,
      //   path: "/partner",
      //   label: "Find Partner Branch",
      //   target: '_blank'
      // },
    ],
  },
  {
    id: 3,
    title: "COMPANY",
    routes: [
      {
        id: 3.1,
        path: "/about-us",
        label: "About Us",
        // target: '_blank'
      },
      {
        id: 3.2,
        path: "/contact-us",
        label: "Contact Us",
        // target: '_blank'
      },
      // {
      //   id: 3.3,
      //   path: "https://doctall.com/",
      //   label: "Visit Doctall",
      //   target: '_blank'
      // },
       {
        id: 3.3,
        path: "/careers",
        label: "Careers",
        // target: '_blank'
      },
      // {
      //   id: 3.4,
      //   path: "/coverage-areas",
      //   label: "Coverage-Areas",
      //   target: '_blank'
      // },
    ],
  },
  {
    id: 4,
    title: "POLICIES",
    routes: [
      {
        id: 4.1,
        path: "/cancel-and-refund",
        label: "Refund/Cancellation Policy",
        target: '',
      },
      {
        id: 4.2,
        path: "/delivery-policy",
        label: "Delivery Policy",
        target: ''
      },
      // {
      //   id: 4.3,
      //   path: "/cancel-and-refund",
      //   label: "Cancellation Policy",
      //   target: ''
      // },
      {
        id: 4.4,
        path: "/faq",
        label: "FAQs",
        target: ''
      },
      {
        id: 4.5,
        path: "/privacy-policy",
        label: "Privacy-Policy",
        target: '',
      },
      {
        id: 4.6,
        path: "/terms-condition",
        label: "Terms & Conditions",
        target: ''
      },
      // {
      //   id: 4.7,
      //   path: "/registrationform",
      //   label: "registration form",
      //   target: ''
      // },
    ],
  },
  // {
  //   id: 5,
  //   title: "COMMUNITY",
  //   routes: [{
  //     id: 5.1,
  //     path: "https://doctall.com/foundation/",
  //     label: "Doctall Foundation",
  //     target: "",
  //   }
  //   ],
  // }
];

export const mobfooterLinks = [
  {
    id: 1,
    title: "PRODUCTS",
    subroute:  {
      id: 5,
      title: "COMMUNITY",
      routes: [{
        id: 5.1,
        path: "https://doctall.com/foundation/",
        label: "Doctall Foundation",
        // target: "_blank",
      }
      ],
    },
    routes: [
      // {
      //   id: 1.1,
      //   path: "/",
      //   label: "Bio Intels",
      // },
      {
        id: 1.2,
        path: "https://diagnostics.doctall.com/",
        label: "Diagnostics",
      },
      {
        id: 1.3,
        path: "https://consumer.doctall.com/",
        label: "Book a Doctor",
      },
      {
        id: 1.4,
        path: "https://doctall.com/homecare",
        label: "Home Care",
      },
    ],
  },
  {
    id: 3,
    title: "CORPORATE",
    routes: [
      {
        id: 2.1,
        path: "https://doctall.com/doctallpro/",
        label: "Doctall Pro",
      },
    ],
  },
  {
    id: 2,
    title: "POLICIES",
    subroute: {
      id: 5,
      title: "RESOURCES",
      routes: [
        {
          id: 2.1,
          path: "https://doctall.com/pedia/",
          label: "Doctallpedia",
        },
        {
          id: 2.2,
          path: "https://doctall.com/living/",
          label: "Doctall Living",
        },
        // {
        //   id: 2.3,
        //   path: "/partner",
        //   label: "Find Partner Branch",
        // },
      ],
    },
    routes: [
      {
        id: 4.1,
        path: "/cancel-and-refund",
        label: "Refund/Cancellation Policy",
      },
      {
        id: 4.2,
        path: "/delivery-policy",
        label: "Delivery Policy",
      },
      // {
      //   id: 4.3,
      //   path: "/cancel-and-refund",
      //   label: "Cancellation Policy",
      // },
      {
        id: 4.3,
        path: "/privacy-policy",
        label: "Privacy-Policy",
        target: ''
      },
      {
        id: 4.4,
        path: "/faq",
        label: "FAQs",
      },
      {
        id: 4.5,
        path: "/terms-condition",
        label: "Terms & Conditions",
      },
    ],
  },
  {
    id: 4,
    title: "COMPANY",
    routes: [
      {
        id: 3.1,
        path: "/about-us",
        label: "About Us",
      },
      {
        id: 3.2,
        path: "/contact-us",
        label: "Contact Us",
      },
      // {
      //   id: 3.3,
      //   path: "/",
      //   label: "Visit Doctall",
      //},
       {
        id: 3.4,
        path: "/careers",
        label: "Careers",
      },
      // {
      //   id: 3.5,
      //   path: "/coverage-areas",
      //   label: "Covearage-Areas",
      //   target: ''
      // },
    ],
  },
];
