import {
    Button, Card, CardContent, Grid, makeStyles, Typography,
    useMediaQuery, useTheme
} from "@material-ui/core";
import React from "react";
import afri from '../../assets/images/afri.png';
import blogImg from "../../assets/images/blog_img.png";
import doctor from "../../assets/images/Dr-Stephen3.png";
import echolab from '../../assets/images/echolab.png';
import harmons from "../../assets/images/harmons.png";
import mcure from '../../assets/images/mecure.png';
import mensHelthImg1 from "../../assets/images/mens-helth-img-1.png";
import mensHelthImg2 from "../../assets/images/mens-helth-img-2.png";
import mensHelthImg3 from "../../assets/images/mens-helth-img-3.png";
import mensHelthImg4 from "../../assets/images/mens-helth-img-4.png";
import mensHelthImg5 from "../../assets/images/mens-helth-img-5.png";
import mensHelthImg6 from "../../assets/images/mens-helth-img-6.png";
import mensHelthImg7 from "../../assets/images/mens-helth-img-7.png";
import mensHelthImg8 from "../../assets/images/mens-helth-img-8.png";
import mensHeltTestTube from "../../assets/images/mens-helth-test-tubes.png";
import mhDoctor from "../../assets/images/mh-doctor.png";
import synlab from '../../assets/images/synlab.png';
import union from '../../assets/images/union-diagnostics.png';
import videocallIcon from "../../assets/images/videoCallIcon.svg";
import viewIcon from "../../assets/images/viewIcon.svg";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header/Header";
import { _redirect } from '../../components/Redirect/Redirect';
import NewsLetter from "../AboutaUs/NewsLetter";
import SpecialistDoctor from "../diseases/SpecialistDoctor";
import styles from "./styles";



const useStyles = makeStyles((theme) => styles(theme));

const MensHelth = ({ disease }) => {

    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));


    return (
        <>
            <Header />

            <div className='banner'>
                <div className='banner_txt'>
                    <p style={{ color: "#FB6514", fontWeight: 600, fontSize: "16px" }} className="para">Lab Packages</p>
                    <h1 className='banner_headding'>Men’s Health</h1>
                    <p className='partner_banner_para' >Loremipsumdolorsitamet, consecteturadipiscingelit,<br />seddoeiusmodtempor
                        incididuntutlaboreet....</p>
                </div>

            </div>

            <Grid container className={classes.MensHelthContainer}>
                <Grid sm={12} md={3} xs={12} align="center" >
                    <img src={mensHelthImg1} alt="img" style={{ width: "100%" }} />
                </Grid>

                <Grid sm={12} md={3} xs={12} align="center" style={{ padding: "25px" }} >
                    <img src={mensHeltTestTube} alt="img" />
                    <Typography className={classes.CardHeading} >Hormone & Fertilite</Typography>
                    <Typography className={classes.CardSubHeading}>Lorem ipsum dolor sit amet, consectetur. Gravida cursus elementum dignissim vulputate lorem mattis.</Typography>
                </Grid>

                <Grid sm={12} md={3} xs={12} align="center" >
                    <img src={mensHelthImg2} alt="img" style={{ width: "100%" }} />
                </Grid>

                <Grid sm={12} md={3} xs={12} align="center" style={{ padding: "25px" }} >
                    <img src={mensHeltTestTube} alt="img" />
                    <Typography className={classes.CardHeading} >Hairl Loss</Typography>
                    <Typography className={classes.CardSubHeading}>Lorem ipsum dolor sit amet, consectetur. Gravida cursus elementum dignissim vulputate lorem mattis.</Typography>
                </Grid>

                <Grid sm={12} md={3} xs={12} align="center" style={{ padding: "25px" }} >
                    <img src={mensHeltTestTube} alt="img" />
                    <Typography className={classes.CardHeading} >Wellness</Typography>
                    <Typography className={classes.CardSubHeading}>Lorem ipsum dolor sit amet, consectetur. Gravida cursus elementum dignissim vulputate lorem mattis.</Typography>
                </Grid>
                <Grid sm={12} md={3} xs={12} align="center" >
                    <img src={mensHelthImg3} alt="img" style={{ width: "100%" }} />
                </Grid>
                <Grid sm={12} md={3} xs={12} align="center" style={{ padding: "25px" }} >
                    <img src={mensHeltTestTube} alt="img" />
                    <Typography className={classes.CardHeading} >Prostate</Typography>
                    <Typography className={classes.CardSubHeading}>Lorem ipsum dolor sit amet, consectetur. Gravida cursus elementum dignissim vulputate lorem mattis.</Typography>
                </Grid>
                <Grid sm={12} md={3} xs={12} align="center" >
                    <img src={mensHelthImg4} alt="img" style={{ width: "100%" }} />
                </Grid>

            </Grid>



            <Grid container className={classes.MensHelthMainContainer}>
                <Grid container className={classes.MensHelthMidContainer}>
                    <Grid sm={12} md={6} xs={12} className={classes.mhSubContainer}>
                        <Typography className={classes.Heading} >Want to discuss with our doctor now</Typography>
                        <Button variant="contained" className={classes.mensHelthBtn}>Request a Callback</Button>
                    </Grid>
                    <Grid sm={12} md={6} xs={12} align="center" >
                        <img src={mhDoctor} alt="img" className={classes.drimage} />
                    </Grid>
                </Grid>
            </Grid>


            <Grid container className={classes.MensHelthContainer}>
                <Grid sm={12} md={3} xs={12} align="center" >
                    <img src={mensHelthImg5} alt="img" style={{ width: "100%" }} />
                </Grid>

                <Grid sm={12} md={3} xs={12} align="center" style={{ padding: "25px" }} >
                    <img src={mensHeltTestTube} alt="img" />
                    <Typography className={classes.CardHeading} >Erectile dysfunction</Typography>
                    <Typography className={classes.CardSubHeading}>Lorem ipsum dolor sit amet, consectetur. Gravida cursus elementum dignissim vulputate lorem mattis.</Typography>
                </Grid>

                <Grid sm={12} md={3} xs={12} align="center" >
                    <img src={mensHelthImg6} alt="img" style={{ width: "100%" }} />
                </Grid>

                <Grid sm={12} md={3} xs={12} align="center" style={{ padding: "25px" }} >
                    <img src={mensHeltTestTube} alt="img" />
                    <Typography className={classes.CardHeading} >Stress Management</Typography>
                    <Typography className={classes.CardSubHeading}>Lorem ipsum dolor sit amet, consectetur. Gravida cursus elementum dignissim vulputate lorem mattis.</Typography>
                </Grid>

                <Grid sm={12} md={3} xs={12} align="center" style={{ padding: "25px" }} >
                    <img src={mensHeltTestTube} alt="img" />
                    <Typography className={classes.CardHeading} >Pre-Marital check</Typography>
                    <Typography className={classes.CardSubHeading}>Lorem ipsum dolor sit amet, consectetur. Gravida cursus elementum dignissim vulputate lorem mattis.</Typography>
                </Grid>
                <Grid sm={12} md={3} xs={12} align="center" >
                    <img src={mensHelthImg7} alt="img" style={{ width: "100%" }} />
                </Grid>
                <Grid sm={12} md={3} xs={12} align="center" style={{ padding: "25px" }} >
                    <img src={mensHeltTestTube} alt="img" />
                    <Typography className={classes.CardHeading} >Heart & Hypertension</Typography>
                    <Typography className={classes.CardSubHeading}>Lorem ipsum dolor sit amet, consectetur. Gravida cursus elementum dignissim vulputate lorem mattis.</Typography>
                </Grid>
                <Grid sm={12} md={3} xs={12} align="center" >
                    <img src={mensHelthImg8} alt="img" style={{ width: "100%" }} />
                </Grid>

            </Grid>


            <Grid container spacing={4} className={classes.MHPackegeContainer}>
                <Grid item sm={12} md={12} xs={12} align="left" >
                    <Typography className={classes.MHCradMainHeading}>Popular health checkup packages</Typography>
                    <Typography style={{ fontSize: "18px", color: "#002931", marginTop: "10PX" }}>DIscover our best sellers of the products that are constantly purchased</Typography>
                </Grid>


                <Grid item sm={12} md={3} xs={12} align="center" >
                    <Card className={classes.MhPackegeCard}>
                        <Typography className={classes.MHCradHeading}><img src={harmons} /> &nbsp; Men’s Hormone Test - Premium Plan</Typography>
                        <Typography className={classes.MHCradSubHeading}>A simple finger-prick blood test that measures the male hormone testosterone - essential for libido, muscle mass, fertility and mood.</Typography>
                        <Typography className={classes.MHCardPoints} align="left">. Free Medical Report <br />
                            . Free International Senior Medical Officer<br />
                            . Follow-Up Consultation (If Applicable)</Typography>
                        <Typography className={classes.MHCardInvest} align="center"> Investigation areas: 3</Typography>
                        <Typography className={classes.MHCardBiomarkers} align="center"> Biomarkers: 3</Typography>
                        <Button variant="contained" fullWidth className={classes.MHCardTestBtn}>View Test</Button>
                    </Card>
                </Grid>
                <Grid item sm={12} md={3} xs={12} align="center" >
                    <Card className={classes.MhPackegeCard}>
                        <Typography className={classes.MHCradHeading}><img src={harmons} /> &nbsp; Men’s Hormone Test - Premium Plan</Typography>
                        <Typography className={classes.MHCradSubHeading}>A simple finger-prick blood test that measures the male hormone testosterone - essential for libido, muscle mass, fertility and mood.</Typography>
                        <Typography className={classes.MHCardPoints} align="left">. Free Medical Report <br />
                            . Free International Senior Medical Officer<br />
                            . Follow-Up Consultation (If Applicable)</Typography>
                        <Typography className={classes.MHCardInvest} align="center"> Investigation areas: 3</Typography>
                        <Typography className={classes.MHCardBiomarkers} align="center"> Biomarkers: 3</Typography>
                        <Button variant="contained" fullWidth className={classes.MHCardTestBtn}>View Test</Button>
                    </Card>
                </Grid>
                <Grid item sm={12} md={3} xs={12} align="center" >
                    <Card className={classes.MhPackegeCard}>
                        <Typography className={classes.MHCradHeading}><img src={harmons} /> &nbsp; Men’s Hormone Test - Premium Plan</Typography>
                        <Typography className={classes.MHCradSubHeading}>A simple finger-prick blood test that measures the male hormone testosterone - essential for libido, muscle mass, fertility and mood.</Typography>
                        <Typography className={classes.MHCardPoints} align="left">. Free Medical Report <br />
                            . Free International Senior Medical Officer<br />
                            . Follow-Up Consultation (If Applicable)</Typography>
                        <Typography className={classes.MHCardInvest} align="center"> Investigation areas: 3</Typography>
                        <Typography className={classes.MHCardBiomarkers} align="center"> Biomarkers: 3</Typography>
                        <Button variant="contained" fullWidth className={classes.MHCardTestBtn}>View Test</Button>
                    </Card>
                </Grid>
                <Grid item sm={12} md={3} xs={12} align="center" >
                    <Card className={classes.MhPackegeCard}>
                        <Typography className={classes.MHCradHeading}><img src={harmons} /> &nbsp; Men’s Hormone Test - Premium Plan</Typography>
                        <Typography className={classes.MHCradSubHeading}>A simple finger-prick blood test that measures the male hormone testosterone - essential for libido, muscle mass, fertility and mood.</Typography>
                        <Typography className={classes.MHCardPoints} align="left">. Free Medical Report <br />
                            . Free International Senior Medical Officer<br />
                            . Follow-Up Consultation (If Applicable)</Typography>
                        <Typography className={classes.MHCardInvest} align="center"> Investigation areas: 3</Typography>
                        <Typography className={classes.MHCardBiomarkers} align="center"> Biomarkers: 3</Typography>
                        <Button variant="contained" fullWidth className={classes.MHCardTestBtn}>View Test</Button>
                    </Card>
                </Grid>
            </Grid>


            <Grid container spacing={4} className={classes.MHPackegeContainer} style={{ background: "#fff" }}>
                <Grid item sm={12} md={12} xs={12} align="left" >
                    <Typography className={classes.MHCradMainHeading}>Popular health checkup packages</Typography>
                    <Typography style={{ fontSize: "18px", color: "#002931", marginTop: "10PX" }}>DIscover our best sellers of the products that are constantly purchased</Typography>
                </Grid>
                <Grid item sm={12} md={4} xs={12} align="left" >
                    <Card style={{ background: "#F2F8F9", padding: "20px" }}>
                        <Grid container spacing={4}>
                            <Grid item sm={6} md={6} xs={6} align="left" >
                                <img src={doctor} className={classes.drimage} />
                            </Grid>
                            <Grid item sm={6} md={6} xs={6} align="left" >
                                <Typography style={{ fontSize: "16px", fontWeight: "500", color: "#013C44" }}>Dr John Stephen</Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44" }}>MBBS, MD</Typography>
                                <Typography style={{ fontSize: "14px", color: "#66B5C3" }}>Psychologist</Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44", marginTop: "10px", lineHeight: "14px" }}>Location: &nbsp;<span style={{ fontWeight: "600" }}> Nigeria</span> </Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44", marginTop: "10px", lineHeight: "14px" }}>Experience: &nbsp; <span style={{ fontWeight: "600" }}>  7 Years</span> </Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44", marginTop: "10px", lineHeight: "14px" }}>Languages Spoken: <br /><span style={{ fontWeight: "600" }}> English, Hausa, Yoruba </span> </Typography>
                                

                            </Grid>
                            <Grid item sm={12} md={12} xs={12} align="left" style={{ border: "1px solid #D9EDF1" }}>
                                <Typography style={{ color: "#013C44", fontWeight: "600", fontSize: "14px" }}>Earliest Available Time</Typography>
                                <input type="number" id="fname" name="fname" placeholder='Enter Date And Time' className='input_box'></input>
                                <Button varient="contained"
                                    style={{
                                        color: "#fff", background: "#00839B", padding: "15px 30px", fontSize: "16px"
                                    }}
                                >Book Doctor</Button> &nbsp; <img src={viewIcon} /> &nbsp; <img src={videocallIcon} />
                            </Grid>
                            <Grid md={12}>






                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item sm={12} md={4} xs={12} align="left" >
                    <Card style={{ background: "#F2F8F9", padding: "20px" }}>
                        <Grid container spacing={4}>
                            <Grid item sm={6} md={6} xs={6} align="left" >
                                <img src={doctor} className={classes.drimage} />
                            </Grid>
                            <Grid item sm={6} md={6} xs={6} align="left" >
                                <Typography style={{ fontSize: "16px", fontWeight: "500", color: "#013C44" }}>Dr John Stephen</Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44" }}>MBBS, MD</Typography>
                                <Typography style={{ fontSize: "14px", color: "#66B5C3" }}>Psychologist</Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44", marginTop: "10px", lineHeight: "14px" }}>Location: &nbsp;<span style={{ fontWeight: "600" }}> Nigeria</span> </Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44", marginTop: "10px", lineHeight: "14px" }}>Experience: &nbsp; <span style={{ fontWeight: "600" }}>  7 Years</span> </Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44", marginTop: "10px", lineHeight: "14px" }}>Languages Spoken: <br /><span style={{ fontWeight: "600" }}> English, Hausa, Yoruba </span> </Typography>
                               

                            </Grid>
                            <Grid item sm={12} md={12} xs={12} align="left" style={{ border: "1px solid #D9EDF1" }}>
                                <Typography style={{ color: "#013C44", fontWeight: "600", fontSize: "14px" }}>Earliest Available Time</Typography>
                                <input type="number" id="fname" name="fname" placeholder='Enter Date And Time' className='input_box'></input>
                                <Button varient="contained" className={classes.bookDrBtn}

                                >Book Doctor</Button> &nbsp; <img src={viewIcon} /> &nbsp; <img src={videocallIcon} />
                            </Grid>
                            <Grid md={12}>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item sm={12} md={4} xs={12} align="left" >
                    <Card style={{ background: "#F2F8F9", padding: "20px" }}>
                        <Grid container spacing={4}>
                            <Grid item sm={6} md={6} xs={6} align="left" >
                                <img src={doctor} className={classes.drimage} />
                            </Grid>
                            <Grid item sm={6} md={6} xs={6} align="left" >
                                <Typography style={{ fontSize: "16px", fontWeight: "500", color: "#013C44" }}>Dr John Stephen</Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44" }}>MBBS, MD</Typography>
                                <Typography style={{ fontSize: "14px", color: "#66B5C3" }}>Psychologist</Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44", marginTop: "10px", lineHeight: "14px" }}>Location: &nbsp;<span style={{ fontWeight: "600" }}> Nigeria</span> </Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44", marginTop: "10px", lineHeight: "14px" }}>Experience: &nbsp; <span style={{ fontWeight: "600" }}>  7 Years</span> </Typography>
                                <Typography style={{ fontSize: "12px", color: "#013C44", marginTop: "10px", lineHeight: "14px" }}>Languages Spoken: <br /><span style={{ fontWeight: "600" }}> English, Hausa, Yoruba </span> </Typography>
                                

                            </Grid>
                            <Grid item sm={12} md={12} xs={12} align="left" style={{ border: "1px solid #D9EDF1" }}>
                                <Typography style={{ color: "#013C44", fontWeight: "600", fontSize: "14px" }}>Earliest Available Time</Typography>
                                <input type="number" id="fname" name="fname" placeholder='Enter Date And Time' className='input_box'></input>
                                <Button varient="contained" className={classes.bookDrBtn}
                                >Book Doctor</Button> &nbsp; <img src={viewIcon} /> &nbsp; <img src={videocallIcon} />
                            </Grid>
                            <Grid md={12}>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>


            </Grid>

            <SpecialistDoctor
                doctors={disease && disease.SpecialistDoctor}
                diseaseName={disease.name}
            />



            <Grid container spacing={5} className={classes.MHPackegeContainer} style={{ background: "#fff" }}>
                <Grid item sm={12} md={12} xs={12} align="left" >
                    <Typography style={{ fontSize: "12px", fontWeight: "600", color: "#FF5C3C" }}>OUR BLOGS</Typography>
                    <Typography className={classes.MHCradMainHeading}>Doctallpedia </Typography>
                    <Typography style={{ fontSize: "18px", color: "#002931", marginTop: "10PX" }}>Stay informed by reading through our library of health articles. Find procedures and medications of over 1000+ health conditions</Typography>
                </Grid>
                <Grid item sm={12} md={4} xs={12} align="left" >
                    <Card style={{ border: "none !important" }}>

                        <img src={blogImg} alt="img" style={{ width: "100%" }} />
                        <Grid style={{ padding: "15px" }}>
                            <Typography style={{ fontSize: "14px", color: "#C53B20", fontWeight: "600", marginTop: "30px" }}>Doctallpedia</Typography>
                            <Typography style={{ fontSize: "24px", color: "#002931", fontWeight: "600", marginTop: "20px" }}>How stress affects your body</Typography>
                            <Typography style={{ fontSize: "126x", color: "#002931", fontWeight: "400", lineHeight: "24px", marginTop: "20px" }}>How do you create compelling presentations that wow your colleagues and impress your managers?</Typography>
                            <Button varient="contained"
                                style={{
                                    color: "#fff", background: "#00839B", padding: "12px 20px", fontSize: "16px",
                                    marginTop: "20px"
                                }}
                            >Read more</Button>

                        </Grid>
                    </Card>
                </Grid>
                <Grid item sm={12} md={4} xs={12} align="left" >
                    <Card style={{ border: "none !important" }}>

                        <img src={blogImg} alt="img" style={{ width: "100%" }} />
                        <Grid style={{ padding: "15px" }}>
                            <Typography style={{ fontSize: "14px", color: "#C53B20", fontWeight: "600", marginTop: "30px" }}>Doctallpedia</Typography>
                            <Typography style={{ fontSize: "24px", color: "#002931", fontWeight: "600", marginTop: "20px" }}>How stress affects your body</Typography>
                            <Typography style={{ fontSize: "126x", color: "#002931", fontWeight: "400", lineHeight: "24px", marginTop: "20px" }}>How do you create compelling presentations that wow your colleagues and impress your managers?</Typography>
                            <Button varient="contained"
                                style={{
                                    color: "#fff", background: "#00839B", padding: "12px 20px", fontSize: "16px",
                                    marginTop: "20px"
                                }}
                            >Read more</Button>

                        </Grid>
                    </Card>
                </Grid>
                <Grid item sm={12} md={4} xs={12} align="left" >
                    <Card style={{ border: "none !important" }}>

                        <img src={blogImg} alt="img" style={{ width: "100%" }} />
                        <Grid style={{ padding: "15px" }}>
                            <Typography style={{ fontSize: "14px", color: "#C53B20", fontWeight: "600", marginTop: "30px" }}>Doctallpedia</Typography>
                            <Typography style={{ fontSize: "24px", color: "#002931", fontWeight: "600", marginTop: "20px" }}>How stress affects your body</Typography>
                            <Typography style={{ fontSize: "126x", color: "#002931", fontWeight: "400", lineHeight: "24px", marginTop: "20px" }}>How do you create compelling presentations that wow your colleagues and impress your managers?</Typography>
                            <Button varient="contained"
                                style={{
                                    color: "#fff", background: "#00839B", padding: "12px 20px", fontSize: "16px",
                                    marginTop: "20px"
                                }}
                            >Read more</Button>

                        </Grid>
                    </Card>
                </Grid>



            </Grid>

            <Grid container spacing={5} className={classes.MHPackegeContainer} style={{ background: "#fff" }}>
                <Grid item sm={12} md={12} xs={12} align="left" >
                  
                    <Typography className={classes.MHCradMainHeading}>Doctall Living</Typography>
                    <Typography style={{ fontSize: "18px", color: "#002931", marginTop: "10PX" }}>Stay informed by reading through our library of health articles. Find procedures and medications of over 1000+ health conditions</Typography>
                </Grid>
                <Grid item sm={12} md={4} xs={12} align="left" >
                    <Card style={{ border: "none !important" }}>

                        <img src={blogImg} alt="img" style={{ width: "100%" }} />
                        <Grid style={{ padding: "15px" }}>
                            <Typography style={{ fontSize: "14px", color: "#C53B20", fontWeight: "600", marginTop: "30px" }}>Doctallpedia</Typography>
                            <Typography style={{ fontSize: "24px", color: "#002931", fontWeight: "600", marginTop: "20px" }}>How stress affects your body</Typography>
                            <Typography style={{ fontSize: "126x", color: "#002931", fontWeight: "400", lineHeight: "24px", marginTop: "20px" }}>How do you create compelling presentations that wow your colleagues and impress your managers?</Typography>
                            <Button varient="contained"
                                style={{
                                    color: "#fff", background: "#00839B", padding: "12px 20px", fontSize: "16px",
                                    marginTop: "20px"
                                }}
                            >Read more</Button>

                        </Grid>
                    </Card>
                </Grid>
                <Grid item sm={12} md={4} xs={12} align="left" >
                    <Card style={{ border: "none !important" }}>

                        <img src={blogImg} alt="img" style={{ width: "100%" }} />
                        <Grid style={{ padding: "15px" }}>
                            <Typography style={{ fontSize: "14px", color: "#C53B20", fontWeight: "600", marginTop: "30px" }}>Doctallpedia</Typography>
                            <Typography style={{ fontSize: "24px", color: "#002931", fontWeight: "600", marginTop: "20px" }}>How stress affects your body</Typography>
                            <Typography style={{ fontSize: "126x", color: "#002931", fontWeight: "400", lineHeight: "24px", marginTop: "20px" }}>How do you create compelling presentations that wow your colleagues and impress your managers?</Typography>
                            <Button varient="contained"
                                style={{
                                    color: "#fff", background: "#00839B", padding: "12px 20px", fontSize: "16px",
                                    marginTop: "20px"
                                }}
                            >Read more</Button>

                        </Grid>
                    </Card>
                </Grid>
                <Grid item sm={12} md={4} xs={12} align="left" >
                    <Card style={{ border: "none !important" }}>

                        <img src={blogImg} alt="img" style={{ width: "100%" }} />
                        <Grid style={{ padding: "15px" }}>
                            <Typography style={{ fontSize: "14px", color: "#C53B20", fontWeight: "600", marginTop: "30px" }}>Doctallpedia</Typography>
                            <Typography style={{ fontSize: "24px", color: "#002931", fontWeight: "600", marginTop: "20px" }}>How stress affects your body</Typography>
                            <Typography style={{ fontSize: "126x", color: "#002931", fontWeight: "400", lineHeight: "24px", marginTop: "20px" }}>How do you create compelling presentations that wow your colleagues and impress your managers?</Typography>
                            <Button varient="contained"
                                style={{
                                    color: "#fff", background: "#00839B", padding: "12px 20px", fontSize: "16px",
                                    marginTop: "20px"
                                }}
                            >Read more</Button>

                        </Grid>
                    </Card>
                </Grid>



            </Grid>





            {/* HEALTHY LIVING */}
            <Grid
                item
                container
                className={classes.healthyLivingContainer}
                direction="column"
                style={{ background: "#F2F8F9" }}
            >
                <Typography
                    variant="h3"
                    style={{
                        paddingBottom:
                            "2.5rem",
                        textAlign: matchesSM ? "center" : undefined,
                    }}
                >
                    HEALTHY LIVING
                </Typography>
                <Typography
                    variant="subtitle1"
                    style={{
                        paddingBottom: "3.6rem",
                        textAlign: matchesSM ? "center" : undefined,
                    }}
                >
                    Looking after your health just got easier
                </Typography>
                <Grid
                    container
                    direction={matchesSM ? "column" : "row"}
                    alignItems={matchesSM ? "center" : undefined}
                    item
                >
                    <Grid
                        item
                        style={{
                            marginRight: matchesSM ? "0px" : "24px",
                            marginBottom: "24px",
                        }}
                    >
                        <Card classes={{ root: classes.wellnessCard }}>
                            <CardContent>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-around"
                                    alignItems="flex-start"
                                >
                                    <Typography variant="h2" style={{ paddingBottom: "8px" }}>
                                        You’re not feeling well
                                        <br /> or have concerns ?
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        style={{ paddingBottom: "35px" }}
                                    >
                                        Need a diagnosis to <br /> one of our doctors now
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        className={classes.livingButton}
                                        onClick={() => _redirect("CONSUMER-FADN")}
                                    >
                                        SEE A DOCTOR
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        style={{
                            marginRight: matchesSM ? "0px" : "24px",
                            marginBottom: "24px",
                        }}
                    >
                        <Card classes={{ root: classes.specialistCard }}>
                            <CardContent>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-around"
                                    alignItems="flex-start"
                                >
                                    <Typography
                                        variant="h2"
                                        style={{ paddingBottom: matchesSM ? "24px" : "8px" }}
                                    >
                                        You’ve seen a specialist and have a diagnosis?
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        style={{ paddingBottom: "35px" }}
                                    >
                                        Get your medications and <br /> enjoy 65% off
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        className={classes.livingButton}
                                    >
                                        BUY TREATMENTS
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        style={{
                            marginBottom: "24px",
                        }}
                    >
                        <Card classes={{ root: classes.diagnosisCard }}>
                            <CardContent>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-around"
                                    alignItems="flex-start"
                                >
                                    <Typography variant="h2" style={{ paddingBottom: "8px" }}>
                                        You’ve seen a diagnosis?
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        style={{ paddingBottom: "35px" }}
                                    >
                                        Book a screening or a lab test
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        className={classes.livingButton}
                                        onClick={() => _redirect("DMP-LOGIN")}
                                    >
                                        GET TESTED
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>










            {/* partners */}
            <div className='partners' >
                <h2 className='our_partner'>OUR PARTNERS</h2>
                <Grid container spacing={0} >
                    <Grid item md={1} sm={0} xs={12} >

                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                        <img src={echolab} style={{ padding: "20px 0px" }} />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12} >
                        <img src={union} alt="union" style={{ padding: "20px 0px" }} />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                        <img src={mcure} alt="mcure" style={{ padding: "20px 0px" }} />

                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                        <img src={synlab} alt="synlab" style={{ padding: "20px 0px" }} />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                        <img src={afri} alt="afry" style={{ padding: "20px 0px" }} />
                    </Grid>
                    <Grid item md={1} sm={0} xs={12}></Grid>

                </Grid>
               
            </div>
            {/* newsletter */}
            <NewsLetter />



            <Footer />
        </>
    );
};

export default MensHelth;
