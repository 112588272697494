import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import styles from "../styles";
import { Divider, makeStyles, useTheme, Switch, useMediaQuery } from "@material-ui/core";
import CallBackPopUp from "../../callbackPopUp/CallBackPopUP";
import { showNotification } from "../../../components/common-notification/CommonNotification.js";
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Grid, Alert, } from '@mui/material';
import EDheader from "./EdCardheader"



const label = { inputProps: { 'aria-label': 'Switch demo' } };

const useStyles = makeStyles((theme) => styles(theme));
const QuestionCard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const [selectedValue, setSelectedValue] = useState("");
  const [seeDoctor, setSeeDoctor] = useState(false);
  const [callBackModel, setCallBackModel] = useState(false);

  useEffect(() => {
    if (seeDoctor) {
      window.scrollTo(0, focusPoint.current.offsetTop);
    }
  }, [seeDoctor]);


  const handleChange = (event) => {

  };

  const handleChangeAge = (event) => {
    let answer = event.target.value.toString().trim();
    if (answer === "I am under 18 years old" || answer === "I am between 70 and above years old") {
      setSeeDoctor(true)
    } else {
      handleChange(event.target.value);
      setSeeDoctor(false)
      stepUpdate(2);
    }
  };



  const Unchecked = ({ label }) => (
    <Grid className={classes.quesOption}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );
  const Checked = ({ label }) => (
    <Grid className={classes.quesChecked}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );
  const EDquestions = {
    id: 1,
    active: true,
    title: "The following questions ensure your medical suitability for ED Treatment Plans, and must be answered accurately and by those of 18 years of age and over.",
    options: [
      "Do you have, or have ever had, a heart condition, stroke, kidney or liver problem?",
      "Are you taking any medication for angina, blood pressure and/or any heart condition?",
      "Do you suffer from Sickle Cell Anaemia Myeloma or Leukaemia?",
      "Do you suffer from either Non-Arteritic Ischemic Optic Neuropathy (NAION) and / or Hereditary Degenerative Retinal Disorder?",
    ],
  }
  return (
    <>
      {/* <EDheader /> */}
      <section style={{ backgroundColor: "#F7FBF8" }}>
        <div className='container'>
          <div className='row'>

        
          {EDquestions && (
            <>
              <div style={{
                display:"grid",
                // alighItem:"center",
                placeContent:"center",
              }}>
              <Typography
                className={classes.questionTitle}
                style={{
                  display:"flex",
                  alighItem:"center",
                  justifyContent:"center",
                  paddingBottom: "56px",
                  fontFamily: "Visuelt Pro",
                  // fontStyle: "normal",
                  fontWeight: "100",
                  fontSize: "24px",
                  width: matchesXS ? "450px" : "910px",
                  // background: "#E5E5E5",
                  backgroundColor: "#F7FBF8",
                  color: "#002931",
                  // ParagraphSpacing: "24px",
                  // textTransform: "capitalize",
                  [theme.breakpoints.down("xs")]: {
                    textAlign: "center",
                  },
                }}
              >
                {EDquestions.title}
              </Typography>

              {EDquestions.options.map((option, index) => {
                let name = `seeDoctor-${index}`;

                { console.log(option) }
                return <Card elevation={0} style={{ width: matchesXS ? "500px" : "910px", marginBottom: "20px",  backgroundColor: "#F7FBF8", }}>
                  <CardContent>
                    <div style={{
                      direction: "column",
                      justifyContent: "space between",
                      alignItems: "flex-start",
                      maxWidth: "100%",
                     
                     
                    }}>
                      {/* <Grid item container style={{
                    direction: "column",
                    justifyContent: "space between",
                    alignItems: "flex-start",
                    maxWidth: "100%",
                    // background: "#E5E5E5",
                  }}
                  > */}
                      <Grid item style={{ width: "50%", float: "left" }}  >
                        <Typography gutterBottom variant="h5" style={{

                          color: "#002931",
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontSize: "20px",
                          fontWeight: "500",
                          lineHeight: "28px",
                          align: "center",
                          verticalAlign: "Center",
                         
                          // paragraphSpacing: "24px",
                        }} component="div">
                          {option}{","}
                        </Typography>
                      </Grid>


                      <Grid style={{
                        width: "50%", 
                        float: "right",
                        //  paddingLeft: "350px",
                        
                      }}>
                        <Typography item  >

                          <Switch 
                            classes={{
                              root: classes.root,
                              switchBase: classes.switchBase,
                              thumb: classes.thumb,
                              track: classes.track,
                              checked: classes.checked
                            }}
                            onChange={handleChange}
                            name={name} id={name}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Typography>

                      </Grid>
                      {/* </Grid> */}
                    </div>
                  </CardContent>
                </Card>
              })}

              </div>
              <Grid style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
                <a href="/ed1" style={{ textDecoration: "none", color: "#ffffff", }}>
                <p
                  style={{
                    marginTop:"50px",
                    marginBottom:"70px",
                    marginLeft:"600px" , 
                    padding:"12px 35px", 
                    borderRadius:"8px",
                    backgroundColor: "#00839B",
                    fontSize: "15px" ,
                    color:"#fff",
                    cursor:"pointer",}}
                   >
                    Submit
                  </p>
                </a>
              </Grid>


              {seeDoctor ? (
                <Alert id="seeDoctor">
                  <img src={AlertClose} alt="Close" style={{ paddingTop: "52.51px" }} />
                  <Typography
                    // ref={focusPoint}
                    style={{
                      paddingTop: "49px",
                      paddingBottom: "8px",
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "24px",
                      lineHeight: "32px",
                      color: "#002931",
                    }}
                  >
                    Not suitable for treatment
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      width: matchesSM ? "250px" : "421px",
                      fontFamily: "Visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "18px",
                      lineHeight: "28px",
                      color: "#002931",
                      paddingBottom: "47px",
                    }}
                    ref={focusPoint}

                  >
                    Based on your answer, you are not suitable for Rx. Change your answer
                    or kindly click on the button and talk to a doctor.
                  </Typography>
                  <Button className={classes.talkToADoctorButton} onClick={() => setCallBackModel(!callBackModel)}>
                    TALK TO A DOCTOR
                  </Button>
                </Alert>
              ) : null}

              <CallBackPopUp
                open={callBackModel}
                close={() => setCallBackModel(!callBackModel)}
              />
            </>
          )}
          {/* </Grid> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default QuestionCard;