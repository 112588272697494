import React, { useEffect, useState } from "react";
import {
    Dialog,
    Grid,
    Button,
    Typography,
    IconButton,
    useMediaQuery,
    CardContent,
    Card,
    FormControl,
    Checkbox,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,

} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "../../pages/diseases/DIseaseWithQues/styles";
import mfIcons from "../../assets/images/most-iffective-icon.png";
import bfftIcons from "../../assets/images/best-for-first-time-icon.png";
import close from "../../assets/images/close.png";
import testTubeIcon from "../../assets/images/testTube1.svg";
import medicine from "../../assets/images/medicine_no_bg.png";


const useStyles = makeStyles((theme) => styles(theme));

export default function MultiDrugDialog(props) {
    const { drugInfo, closeDialog, openDialog } = props;


    const classes = useStyles();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const custommatchesMDX = useMediaQuery("(max-width:1380px)");
    const [selectIndex, setSelectedIndex] = useState(0);


    return (
        <>
            <Dialog
                style={{ borderRadius: "0px" }}
                maxWidth={"md"}
                fullWidth={true}
                open={openDialog}
                scroll={"body"}
                onClose={() => { setSelectedIndex(0); closeDialog() }}
                disableBackdropClick
            >



                <Grid container item>
                    <Grid sm={12} md={4} xs={12} align="center"
                        style={{ backgroundColor: "#FFF0ED", verticalAlign: "center" }}
                        className={classes.DrugPopupFisrSection}
                        verticalAlign="center"
                    >

                        <img src={drugInfo[selectIndex].product_image} alt="callback pop up image" style={{ width: "100%" }} />
                    </Grid>
                    <Grid sm={12} md={8} xs={12} align="left" >
                        <Grid sm={12} md={12} xs={12} align="right" >
                            <img
                                style={{ cursor: 'pointer' }}
                                src={close}
                                alt="Close"
                                onClick={() => { setSelectedIndex(0); closeDialog() }}
                            />
                        </Grid>
                        <Grid item container className={classes.dWQplaInfoDetails}>
                            <FormControl
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "18px",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                }}
                            >
                                {drugInfo && drugInfo.map((drug, index) => (
                                    <Grid item xs key={`${drug}${index}`}>
                                        <FormControlLabel
                                            style={
                                                selectIndex === index
                                                    ? {
                                                        borderColor: "#004451",
                                                        backgroundColor: "#D9EDF1",
                                                        height: "42px ",
                                                    }
                                                    : {
                                                        height: "42px ",
                                                    }
                                            }
                                            classes={{
                                                root: classes.optionSelect,
                                                label: classes.optionSelectLabel,
                                            }}
                                            value={drug}
                                            checked={selectIndex === index ? true : false}
                                            onChange={(event) => {
                                                setSelectedIndex(index);
                                            }}
                                            control={<Checkbox style={{ display: "none" }} />}
                                            label={drug.drug_name}
                                        />
                                    </Grid>
                                ))}
                            </FormControl>


                        </Grid>

                        <div style={{ padding: "25px" }}>

                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <Typography className={classes.DrugPopHeading}>{drugInfo[selectIndex].drug_name}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>

                                        <img src={drugInfo[selectIndex].country_flag} alt="ellipse" style={{ width: "20px", height: "15px" }} />
                                        <Typography className={classes.DrugPopSubHeading}>
                                            {drugInfo[selectIndex].manufacturing_country}
                                        </Typography>
                                    </div>

                                </Grid>
                            </Grid>


                            <Typography className={classes.DrugPopSubHeading}>
                                The brand name for this drug is {drugInfo.brand_name}
                            </Typography>
                            <Typography className={classes.DrugPopPara}>
                                {drugInfo[selectIndex].overview}
                            </Typography>

                            <Grid item container className={classes.dWQplaInfoDetails}
                                style={{ height: "155px", margin: "20px 0px" }}>

                                <Grid
                                    item
                                    container
                                    direction={matchesXS ? "column" : "row"}
                                    justifyContent="space-between"
                                >
                                    <Typography className={classes.planInfoFormTitle}>
                                        Manufacturer Name
                                    </Typography>
                                    <Typography
                                        className={classes.planInfoFormTitle}
                                        style={{ color: "#005F71" }}
                                    >
                                        {drugInfo[selectIndex].manufacturing}
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    justifyContent="space-between"
                                    direction={matchesXS ? "column" : "row"}
                                >
                                    <Typography className={classes.planInfoFormTitle}>
                                        Time to take effect
                                    </Typography>
                                    <Typography
                                        className={classes.planInfoFormTitle}
                                        style={{ color: "#005F71" }}
                                    >
                                        {drugInfo[selectIndex].time_to_take_effect}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    justifyContent="space-between"
                                    direction={matchesXS ? "column" : "row"}
                                >
                                    <Typography className={classes.planInfoFormTitle}>
                                        Lasts for
                                    </Typography>
                                    <Typography
                                        className={classes.planInfoFormTitle}
                                        style={{ color: "#005F71" }}
                                    >
                                        {drugInfo[selectIndex].last_for}
                                    </Typography>
                                </Grid>
                                {
                                    drugInfo && drugInfo[selectIndex].features && drugInfo[selectIndex].features.length > 0 && drugInfo[selectIndex].features.map((feature) => (
                                        <>
                                            <Button
                                                key={feature.title}
                                                variant="contained"
                                                disableRipple
                                                disableFocusRipple
                                                style={{
                                                    backgroundColor: "#D9EDF1",
                                                    color: "#002931",
                                                    padding: "0px 8px",
                                                    border: "1px solid #66B5C3",
                                                    fontSize: "14px",
                                                    textTransform: "lowercase",
                                                    cursor: "auto",
                                                }}
                                            >
                                                <img src={feature.image} /> &nbsp; {feature.title}
                                            </Button>
                                            &nbsp; &nbsp;
                                        </>
                                    ))
                                }

                            </Grid>


                            {drugInfo[selectIndex].how_to_use && drugInfo[selectIndex].how_to_use.length > 0 && <Typography className={classes.DrugPopSubHeading1}>How to use {""} {drugInfo.drug_name}</Typography>}

                            <Grid container align="left">
                                {
                                    drugInfo[selectIndex].how_to_use && drugInfo[selectIndex].how_to_use.length > 0 && drugInfo[selectIndex].how_to_use.map((usage) => (
                                        <>
                                            <Grid sm={12} md={4} xs={12} style={{ padding: "5px" }}>
                                                <img src={usage.image} />
                                                <Typography className={classes.howToUseDrugSubHeading}>{usage.title}</Typography>
                                                <Typography className={classes.howToUseDrugPara}>{usage.desc}</Typography>
                                            </Grid>
                                        </>
                                    ))
                                }


                            </Grid>


                        </div>

                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
}

