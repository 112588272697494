import { useEffect } from "react";
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { decrypt } from ".";
import authActions from "../redux/actions/authActions";
import { passcodeKey, tokenKey, usernameKey } from "./constants";

function AppContainer(props) {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies([usernameKey, passcodeKey, tokenKey]);
    
    const _userInfo = useSelector((state) => state.authReducer);

    const location = useLocation();
    const dispatch = useDispatch();
    
    const fetchUserDetails = () => {
        let username = decrypt(cookies[usernameKey]);
        let password = decrypt(cookies[passcodeKey]);
        dispatch(authActions.handleLogin({ username, password, type: 'loginwithcredential' }));
    };
// console.log(!_userInfo.userInfo && cookies[passcodeKey],"!_userInfo.userInfo && cookies[passcodeKey]")
console.log(_userInfo.userInfo , cookies[passcodeKey], cookies[usernameKey],"!_userInfo.userInfo && cookies[passcodeKey]")

    useEffect(() => {
        if ((!_userInfo.userInfo && cookies[passcodeKey] != 'null' && cookies[usernameKey] != 'null') && (!_userInfo.userInfo && cookies[passcodeKey] != undefined && cookies[usernameKey] != undefined 
            // && _userInfo.userInfo.login_type !="facebook" && _userInfo.userInfo.login_type !="googlr"
            )) {
            fetchUserDetails();
        }
        // {
        //     (_userInfo.userInfo && cookies[passcodeKey] === null && cookies[usernameKey] === null) && fetchUserDetails();

        // }
    }, [cookies]);

    useEffect(() => {
        console.log('doctall aoo location ', location.pathname);
    }, [location])
    
    return (
        <div>{props.children}</div>
    )
}
export default AppContainer;