import { all, takeEvery, put, fork , select} from 'redux-saga/effects';
import actions from '../actions';
import { getregistrationInfluencer } from "../../services/auth-services/authServices";

const { influencersActions } = actions;

export function* influencerSaga() {

    yield takeEvery(influencersActions.INFLUENCER_REQUEST,  function* ({ payload }) {
        console.log(payload,"payload")
        try {
            // const token = yield select(state => state.auth.accesstoken);
            const response = yield getregistrationInfluencer( payload );
            const { data } = response;
            
        yield put({
          type: influencersActions.INFLUENCER_REQUEST_SUCCESS,
          influencer: data  
        });

        } catch (error) {
        //yield showNotification('error', "Failed !!", "" + error.response.data)
             console.log(error)
        }
    });
}

export default function* rootSaga() {
    yield all([fork(influencerSaga)]);
}