import React from "react";
import {
    Grid,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header/Header";
import { getCurrentDate } from "../../components/common/utils";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => styles(theme));

const ServiceDeliveryPolicy = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
       
            <Header />
            <Helmet>
            <title>Delivery Policy - Doctall</title>
            <meta 
            name="description" 
            content=" Service Delivery Policy. Read more to find out what Doctall's delivery is for purchases and home sample collection."
            />
        </Helmet>
            <Grid container>
                <Grid item container direction="column" className={classes.LegalHeader} align="center" >
                    <Typography className={classes.legalBannerHeading}>{`Current as of ${getCurrentDate()}`}</Typography>
                    <Typography className={classes.legalHeading}>Service Delivery Policy</Typography>
                </Grid>

                <Grid item container direction="column" className={classes.LegalTextContainer} >

                    <Typography className={classes.legalParaHeading}>1. General Information</Typography>
                    <Typography className={classes.legalPara}>
                        All orders are subject to service availability. If a service is unavailable at the time you place your order, we will notify you and refund you the total amount of your order, using the original method of payment.

                    </Typography>
                    <Typography className={classes.legalParaHeading}>2. Delivery Time</Typography>
                    <Typography className={classes.legalPara}>
                        For walk-ins, kindly visit a selected partner's location within their opening hour, preferably 30 minutes before close of business.<br /><br />
                        For home sample collection, an estimated service delivery time will be provided to you within an hour of ordering for home service. Partners will contact you and confirm the exact time they will be visiting you for home sample collection.<br /><br />
                        Unless there are exceptional circumstances, partners make every effort to fulfill your home service request within 24 business hours of the date of your order. Business day means Monday to Friday, except holidays.

                    </Typography>



                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

export default ServiceDeliveryPolicy;
