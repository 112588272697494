import {
    Button, Card, CardContent, Grid, makeStyles, Typography, useMediaQuery, useTheme
} from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import close from "../../assets/images/close.png";
import EDCard1 from "../../assets/images/EDCard1.png";
import HLT from "../../assets/images/HLT1.png";
import PECard1 from "../../assets/images/PEcard1.png";
import styles from "../../pages/diseases/DIseaseWithQues/styles";
import { ED_ROUTE_PATH, HL_ROUTE_PATH, PE_ROUTE_PATH } from "../common/utils";

const useStyles = makeStyles((theme) => styles(theme));

export default function BuyTreatmentModel(props) {
  let navigate = useNavigate();
  const { planInfo } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");

  const onNavigate = (dieases) => {
    console.log(dieases);
    switch (dieases) {
      case "HL":
        matchesXS ? window.scroll(0, 1600) : window.scroll(0, 1000);
        navigate(HL_ROUTE_PATH);
        break;
      case "PE":
        matchesXS ? window.scroll(0, 1600) : window.scroll(0, 1000);
        navigate(PE_ROUTE_PATH);
        break;
      case "ED":
        matchesXS ? window.scroll(0, 1600) : window.scroll(0, 1000);
        navigate(ED_ROUTE_PATH);
        break;
      default:
        console.log("SEND TO HOME PAGE");
    }
  };

  return (
    <>
      <Grid container>
        <Grid sm={12} md={12} xs={12} align="left">
          <Grid container>
            <Grid sm={12} md={12} xs={12} align="right">
              <img src={close} alt="Close" onClick={() => props.onClose()} />
            </Grid>
          </Grid>

          <div style={{ padding: "25px" }}>
            <Typography className={classes.TreatHeading}>
              Select the treatment you want to go for
            </Typography>
            <Grid item className={classes.rowContainer}>
              <Grid
                item
                lg={12}
                md={12}
                container
                direction={matchesSM ? "column" : "row"}
              >
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Card classes={{ root: classes.TreatCard }}>
                    <CardContent classes={{ root: classes.cardTreat }}>
                      <Grid item container classes={{ root: classes.cardHead }}>
                        <Grid md={8} sm={8} xs={8} lg={8}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: "700",
                              color: "#002931",
                              marginTop: "32px",
                              fontSize: "20px",
                              padding: "10px",
                            }}
                          >
                            Erectile Dysfunction
                          </Typography>
                        </Grid>
                        <Grid md={4} sm={4} xs={4} lg={4}>
                          <img src={EDCard1} />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        style={{ margin: "12px 0px" }}
                      >
                        <Typography
                          style={{
                            width: matchesXS ? "250px" : "344px",
                            fontSize: "14px",
                            color: "#002931",
                            padding: "0px 10px",
                          }}
                        >
                          Erectile dysfunction (ED) is quite common, typically
                          seen in men over 40 .<br />
                          <br />
                          It is not unusual at any age to occasionally not be
                          able to achieve an erection. Various reasons can cause
                          this, from stress, anxiety, tiredness, or excessive
                          alcohol intake.
                          <br />
                          <br />
                          If it happens often, it is worth speaking to a doctor
                          to investigate the cause and discuss potential
                          treatments to resolve the problem. <br />
                          <br />
                        </Typography>
                      </Grid>
                      <Button
                        variant="contained"
                        disableRipple
                        className={classes.TreatButton}
                        // onClick={() => onNavigate("ED")}
                        component={HashLink}
                        to={`${ED_ROUTE_PATH}#Treatment_plan`}
                      >
                        Buy Treatment
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Card classes={{ root: classes.TreatCard }}>
                    <CardContent classes={{ root: classes.cardTreat1 }}>
                      <Grid item container classes={{ root: classes.cardHead }}>
                        <Grid md={6} sm={6} xs={6} lg={6}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: "700",
                              color: "#002931",
                              marginTop: "32px",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            Hair Loss
                          </Typography>
                        </Grid>
                        <Grid md={6} sm={6} xs={6} lg={6}>
                          <img src={HLT} />
                        </Grid>
                      </Grid>
                      {/* <Typography
                        variant="body1"
                        style={{
                          fontWeight: "700",
                          color: "#002931",
                          marginTop: "32px",
                          fontSize: "20px",
                        }}
                      >
                        Hair Loss
                      </Typography> */}

                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        style={{ margin: "12px 0px" }}
                      >
                        <Typography
                          //   variant="h4"
                          style={{
                            width: matchesXS ? "250px" : "344px",
                            fontSize: "14px",
                            color: "#002931",
                            padding: "0px 10px",
                          }}
                        >
                          Did you know that we lose between 50-100 hairs a day,
                          usually without worrying the slightest about it?{" "}
                          <br />
                          <br />
                          Although that is normal and nothing to be concerned
                          about, some hair loss can be more severe and
                          permanent. <br />
                          <br />
                          And no, hair loss does not just affect men, and it can
                          affect generation after generation of the same family.
                          <br />
                          <br />
                          <br />
                          <br />
                        </Typography>
                      </Grid>
                      <Button
                        variant="contained"
                        disableRipple
                        className={classes.TreatButton}
                        // onClick={() => onNavigate("HL")}
                        component={HashLink}
                        to={`${HL_ROUTE_PATH}#Treatment_plan`}
                        style={{ marginTop: "13px" }}
                      >
                        Buy Treatment
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Card classes={{ root: classes.TreatCard }}>
                    <CardContent classes={{ root: classes.cardTreat2 }}>
                      <Grid item container classes={{ root: classes.cardHead }}>
                        <Grid mmd={8} sm={8} xs={8} lg={8}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: "700",
                              color: "#002931",
                              marginTop: "32px",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            Premature Ejaculation
                          </Typography>
                        </Grid>
                        <Grid md={4} sm={4} xs={4} lg={4}>
                          <img src={PECard1} />
                        </Grid>
                      </Grid>
                      {/* <Typography
                        variant="body1"
                        style={{
                          fontWeight: "700",
                          color: "#002931",
                          marginTop: "32px",
                          fontSize: "20px",
                        }}
                      >
                        Premature Ejaculation
                      </Typography> */}

                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        style={{ margin: "12px 0px" }}
                      >
                        <Typography
                          //   variant="h4"
                          style={{
                            width: matchesXS ? "250px" : "344px",
                            fontSize: "14px",
                            color: "#002931",
                            padding: "0px 10px",
                          }}
                        >
                          Like erectile dysfunction, premature ejaculation is
                          experienced by most men occasionally. Research shows
                          that about one in five male adults between 18-59
                          experience premature ejaculation.
                          <br />
                          <br />
                          Studies also show that the average time for
                          ejaculation during intercourse is around five and a
                          half minutes. However, there's no exact time scale
                          anyone should be checking against because it depends
                          on the couple and what they are after.
                        </Typography>
                      </Grid>
                      <Button
                        variant="contained"
                        disableRipple
                        className={classes.TreatButton}
                        //onClick={() => onNavigate("PE")}
                        component={HashLink}
                        to={`${PE_ROUTE_PATH}#Treatment_plan`}
                        style={{ marginTop: "50px" }}
                      >
                        Buy Treatment
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
