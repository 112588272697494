const categoryActions = {
    GET_CATEGORY: "GET_CATEGORY",
    GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",

    getCategory: () => {
        return {
            type: categoryActions.GET_CATEGORY
        };
    },


};

export default categoryActions;