import {
  Button, Grid, IconButton, makeStyles, Typography, useMediaQuery, useTheme
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import "../Landing.scss";
import styles from "../styles";

import ArrowLeft from "../../../assets/images/ArrowLeft.svg";
import ArrowRight from "../../../assets/images/ArrowRight.svg";
import { _redirect } from "../../Redirect/Redirect";

const useStyles = makeStyles((theme) => styles(theme));

const BannerCarousel = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const openConsumerAPP = () => {
    _redirect("CONSUMER-FADN");
  };

  return (
    <Carousel
      infiniteLoop
      showIndicators={matchesSM ? false : true}
      showArrows={false}
      showStatus={false}
      swipeable={matchesSM ? false : true}
      showThumbs={false}
      autoPlay={true}
      renderArrowPrev={(clickHandler, hasPrev, labelPrev) => (
        <IconButton onClick={clickHandler} className={classes.bannerArrowPrev}>
          <img src={ArrowLeft} alt="left Arrow" />
        </IconButton>
      )}
      renderArrowNext={(clickHandler, hasNext, labelNext) => (
        <IconButton onClick={clickHandler} className={classes.bannerArrowNext}>
          <img src={ArrowRight} alt="Right arrow" />
        </IconButton>
      )}
    >
      <div className={classes.banner}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              className={classes.bannerPrimary}
              style={{ color: "#0B4A6F" }}
            >
              “ Doctall has helped me to live
              {matchesSM ? undefined : <br />} healthy and get my medications
              {matchesSM ? undefined : <br />}conveniently”
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              className={classes.bannerTestimonial}
              alignItems="center"
            >
              <div style={{ height: "95px" }}></div>
              {/* <Grid item>
                <img
                  src={Testimonial1}
                  alt="doctall patient"
                  className={classes.bannerTestimonialImage}
                />
              </Grid> */}
              {/* <Grid item style={{ paddingLeft: "16px" }}>
                <Grid container direction="column">
                  <Typography className={classes.testiPrimary}>
                    Chisom Kaima
                  </Typography>
                  <Typography className={classes.testiSecondary}>
                    Doctall Patient
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item>
            <Button className={classes.bookADoctor} onClick={openConsumerAPP}>
              Book a Doctor Now
            </Button>
          </Grid>
          <Grid item className={classes.bannerArrow}></Grid>
        </Grid>
      </div>
      <div className={classes.banner1}>
        <Grid container direction="column">
          <Grid item>
            <Typography className={classes.bannerPrimary}>
              “ Speak to doctors and specialists
              {matchesSM ? undefined : <br />} from wherever you are.
              {matchesSM ? undefined : <br />} Book an appointment now.”
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              className={classes.bannerTestimonial}
              alignItems="center"
            >
              <div style={{ height: "95px" }}></div>
              {/* <Grid item>
                <img
                  src={Testimonial1}
                  alt="doctall patient"
                  className={classes.bannerTestimonialImage}
                />
              </Grid> */}
              {/* <Grid item style={{ paddingLeft: "16px" }}>
                <Grid container direction="column">
                  <Typography className={classes.testiPrimary}>
                    Chisom Kaima
                  </Typography>
                  <Typography className={classes.testiSecondary}>
                    Doctall Patient
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item>
            <Button
              className={classes.bookADoctor}
              onClick={openConsumerAPP}
              style={{ backgroundColor: "#FFFFFF", color: "#0B4A6F" }}
            >
              Book a Doctor Now
            </Button>
          </Grid>
          <Grid item className={classes.bannerArrow}></Grid>
        </Grid>
      </div>
      <div className={classes.banner2}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              className={classes.bannerPrimary}
              style={{ color: "#065986" }}
            >
              “ Get <span style={{ color: "#FF5C3C" }}>10% off</span> your first
              appointment
              {matchesSM ? undefined : <br />} when you sign up to Doctall.”
              <br />
              <br />
            </Typography>
          </Grid>
          {/* <Grid style={{marginTop:"60px"}}></Grid> */}
          <Grid item>
            <Grid
              container
              direction="row"
              className={classes.bannerTestimonial}
              alignItems="center"
            >
              <div style={{ height: "95px" }}></div>
              {/* <Grid item>
                <img
                  src={Testimonial1}
                  alt="doctall patient"
                  className={classes.bannerTestimonialImage}
                />
              </Grid>  */}
              {/* <Grid item style={{ paddingLeft: "16px" }}>
                <Grid container direction="column">
                  <Typography className={classes.testiPrimary}>
                    Chisom Kaima
                  </Typography>
                  <Typography className={classes.testiSecondary}>
                    Doctall Patient
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item>
            <Link to={"/registration"} style={{ textDecoration: "none" }}>
              <Button className={classes.bookADoctor}>Sign Up here</Button>
            </Link>
          </Grid>
          <Grid item className={classes.bannerArrow}></Grid>
        </Grid>
      </div>
    </Carousel>
  );
};

export default BannerCarousel;
