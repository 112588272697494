import { Button, Grid, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import "../../Auth/auth.scss";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"
import { FormControl } from "@material-ui/core";
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "../../../components/Landing/Landing.scss";
import actions from '../../../redux/actions';
import { getCountryCodes, validateEmail } from '../../../services/doctall-living/staticServices';
import RegistrationHeader from "../RegistrationPages/header";
import styles from "../RegistrationPages/Styles";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'




const useStyles = makeStyles((theme) => styles(theme));

export default function RegistationForm() {
    const classes = useStyles();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const custommatchesMDX = useMediaQuery("(max-width:1380px)");
    const custommatchesXL = useMediaQuery("(min-width:1500px)");
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const { influencersActions } = actions;
    const [country, setCountry] = useState();
    const [mobile, setMobile] = useState("");
    const [reserror , setReserror] = useState(false)
    const [dob , setDob] = useState("")
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [gender, setGender] = useState(" ")
    const [value, onChange] = useState(new Date());
    const [date, setDate] = useState(new Date());
    const [show , setShow] = useState(null)
   
    const [errorStatus, setErrorstatus] = useState(false)
   
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [typesubmit , settypesubmit] = useState('')
   

    const { control ,register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = (payload, e) =>   {
        // e.preventDefault.();
         console.log(payload, "payload")
        dispatch(influencersActions.addInfluencers(payload));
       
        
     
      }
      
    
    
    const [countryDialCode, setCountryDialCode] = useState();

   const handleChange  =(e) => {
         setGender(e.target.value)
   }

   const handleDob = (e) => {
    setDob(true)
   }
   
    const onChangeFname = (e) => {
        e.preventDefault()
        const input = e.currentTarget.value;
        if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
            setFname(input);
            setErrorstatus(false);
        }
    }

    const onChangeLname = (e) => {
        e.preventDefault()
        const input = e.currentTarget.value;
        if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
            setLname(input);
           
        }
    }

      

    const errorstyle = {
        border: reserror ? "1px solid #E04E47" : "1px solid #ADC2C2 ",
        outline: "none !important",
        borderRadius: "5px",
    }

    useEffect(() => {
        getCountryCode()
    }, [])

    const getCountryCode = async () => {
        try {
            let response = await getCountryCodes()
            if (response) {
                setCountry(response.data.country_code.toLowerCase());
            }
        } catch (error) {
            console.log(error)
        }
    }

    const _onPhoneChange = (value, country, e, formattedValue) => {
        setMobile(value)
        setCountryDialCode(country.dialCode)
    }

    const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const handleOver = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setShow(true)
  };
    

   
   
    return (
        <div className={classes.main}>
            <RegistrationHeader />
            <div className={classes.registration_container_main}>


                <div className={classes.registrationform_page}
             
                >
                    <Grid container 
                        direction={matchesXS ? "column" : undefined}
                    >


                        <Grid item xs={6} sm={12} >

                            <div className={classes.registration_container}>
                        
                             
                                <h3 className={classes.Registrationform_heading}>
                                    Kindly provide your details
                                    </h3>
                            
                                <div className={classes.registrationform_section}>
                                <form onSubmit={handleSubmit(onSubmit )}>
                                        <FormControl varient="standard">
                                            <Typography htmlFor="name" className={classes.inputLabels}

                                            >
                                                First Name*
                                            </Typography>
                                            <input
                                                 {...register("firstName", {  maxLength : {
                                                    value: 20,
                                                    message: 'Name is too long' 
                                                  },
                                                  required: 'This field cannot be empty' })}
                                                onChange={onChangeFname}
                                                value={fname}
                                                style={errorstyle}
                                                placeholder='Enter your First Name'
                                                type="text"
                                            
                                                name='firstName'
                                                className={classes.inputFields}
                                              
                                                disableUnderline={true}
                                                inputProps={{
                                                    maxLength: 25,
                                                }}
                                            />
                                            <div> {errors.firstName && <span  className={classes.eror} >{errors.firstName.message}</span>}
                                            </div>
                                            
                                             <Typography htmlFor="name" className={classes.inputLabels}

                                            >
                                                Last Name*
                                            </Typography>
                                            <input
                                            {...register("lastName", { maxLength:{
                                                value: 10,
                                            message: 'last name should not be too long' },
                                             required: 'This field cannot be empty',   })}
                                                onChange={onChangeLname}
                                                value={lname}
                                                placeholder='Enter your Last name'
                                               
                                                type="text"
                                               
                                                name='lastName'
                                                className={classes.inputFields}
                                                disableUnderline={true}
                                               
                                            />
                                            <div>
                                                {errors.lastName && <span  className={classes.eror} >{errors.lastName.message}</span>}
                                            </div>
                                              
                                             <Typography
                                                className={classes.inputLabels}
                                            >
                                                Date of birth
                                            </Typography>
                                            <input
                                               {...register("dob", { required: 'Date of birth is required' })}
                                                value={moment(value).format("DD /MMM /YYYY")}
                                                
                                                onChange={handleDob}
                                                type="text"
                                                 
                                                name='dob'
                                                className={classes.inputFields}
                                                disableUnderline={true}
                                              
                                                onMouseOver={handleOver}
                                                aria-describedby={id}
                                                 
                                            />
                                           
                                                 {show == true &&
                                                 <Popper id={id} open={open} anchorEl={anchorEl}
                                                 placement="bottom-start"
                                                 >
                                                    <Box sx={{  bgcolor: 'none' }}>
                                                 <div
                                                 style={{padding: "7px"}}
                                              
                                                 onMouseLeave ={() => setShow(false)}
                                                
                                                 >
                                                    
                                            <Calendar 
                                           
                                                onChange={(event) => { onChange(event); setShow(false)}}
                                                value={value}
                                            
                                                
                                                />
                                                </div>
                                                </Box>
                                                </Popper>
                                            }
                                               <div>  
                                                {errors.dob && <span  className={classes.eror} >{errors.dob.message}</span>}
                                            </div>
                                            



                                            <Typography
                                                className={classes.inputLabels}
                                            >
                                                Gender
                                            </Typography>
                                            <select
                                            {...register("gender", { required: 'Gender is required' })}
                                               
                                                value={gender}
                                                onChange={handleChange}
                                               
                                                className={classes.inputFields }
                                               
                                                disableUnderline={true}
                                            >   
                                                 <option value = ''>Select Sex</option>
                                                 <option value='male'>Male</option  >
                                                <option value='female'>Female</option  >
                                                <option value='others'>Others</option>
                                            </select>
                                            <div>
                                            {errors.gender && <span  className={classes.eror} >{errors.gender.message}</span>}
                                            </div>
                                             
                                            <Typography
                                                htmlFor="Email adress"
                                                className={classes.inputLabels}
                                           
                                            >
                                                Email address
                                            </Typography>
                                            <input
                                             {...register("email", 
                                             { pattern:{value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i , 
                                             message: 'Enter valid email' },
                                              required: 'Email is required'})}

                                                placeholder='Enter your email address'
                                                name='email'
                                                type="text"
                                                onChange={(e) => validateEmail(e) }
                                                
                                               
                                                autoFocus={true}
                                                className={classes.inputFields}
                                                disableUnderline={true}
                                            />
                                            <div>
                                                {errors.email && <span  className={classes.eror} >{errors.email.message}</span>}
                                            </div>
                                            
                                            <Typography
                                                className={classes.inputLabels}
                                                // htmlFor="mobile"
                                            >
                                                Phone number
                                            </Typography>
                                           
                                            {/* <PhoneInputWithCountry
                                              name="mobile"
                                              control={control}
                                              defaultCountry="IN"
                                              className={classes.inputFields}
                                              rules={{ required: 'This field cannot be empty' }} /> */}
                                            {/* <div>
                                                {errors.mobile && <span  className={classes.eror} >{errors.mobile.message}</span>}
                                            </div> */}
                                            <PhoneInput/>
    
                                            <Typography
                                                className={classes.inputLabels}
                                           
                                            >Referral code
                                            </Typography>
                                            <input
                                              {...register("referal_code", { required: 'Referal_Code is required ', })}
                                                type="text"
                                                name='referal_code'
                                                placeholder="Input your referral code here "
                                                className={classes.inputFields}
                                                disableUnderline={true}

                                            />
                                             <div> 
                                                {errors.referal_code && <span  className={classes.eror} >{errors.referal_code.message}</span>}
                                            </div> 
                                           
                                            <Button 
                                            className={classes.registrationClick}
                                            variant="text"
                                           
                                            type='submit'
                                            value = 'submit'
                                            >
                                                Register
                                            </Button>

                                        </FormControl>

                                    </form>
                                </div>  
                                

                             </div>
                        </Grid>

                    </Grid>

                </div>
                </div>

            
        </div>
    )


}