import {
    Grid
} from "@material-ui/core";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/doctall_logo.png";
import mark from "../../../assets/images/mark.png";
import success from "../../../assets/images/success.png";
import "../../Auth/auth.scss";



function ConfirmVerification() {
    const navigate = useNavigate();
    
    const handleSubmit = () => {
        // let verify = localStorage.getItem("verify");
        // let verify1 = localStorage.getItem("verify1");
        // console.log(verify, "loginVerify")
        // if(verify == "loginVerify"){
        //     navigate('/')
        // }
        // else if(verify1 == "RegisterVerify"){
        //     navigate('/login')
        // } 
        navigate('/login')
    }

    return (

        <div className='login_page'>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12} >
                    <div className='Login_img_box'>
                        <img src={mark} alt="login_image" className='Login_img' />
                    </div>

                </Grid>
                <Grid item sm={6} xs={12} >
                    <div className='login_container'>
                        <div className='doctall_logo'>
                            <img src={logo} alt="login_image" />
                        </div>

                        <div className='form_section'>
                            <div className="passcode_container">
                                <img src={success} alt="success_icon" />
                                <h1 className='Login_heading' style={{ marginTop: "30px" }}>Verification code</h1>
                                <p className='login_para'>Code verification successful</p>
                            </div>

                            <button type="submit" value="Submit" className='signin_btn' onClick={handleSubmit}>Continue</button>
                        </div>

                    </div>

                </Grid>

            </Grid>
        </div>
    )
}

export default ConfirmVerification



