import React from 'react'
import './AboutUs.scss'
import {
  Grid,
}from "@material-ui/core";
import NewsletterForm from '../../components/Landing/components/NewsletterForm';


function NewsLetter() {
  return (

    <div className='newsletter'>

      <Grid container spacing={0}>
        <Grid md={6}>
          <div className="newsletter_text">
            <h1 className='newletter_heading'>Subscribe to our newsletter today!</h1>
            <p className='newsletter_para'>The Doctall newsletter brings the best health information that is really important to you - direct to your inbox.
            </p>
            <p className='newsletter_para'>
            We can also send you the latest and emerging health trends that will help keep your mind and body in good shape. So sign up today.</p>
          </div>
        </Grid>



        <Grid md={6} style={{width:"100%"}}>
        
        <div className='form_container'>
        <div className='newsletter_form'>
        <div className='newsletter_form_items'>
        <NewsletterForm/>
        </div>
        </div>
        </div>      
        </Grid>
      </Grid>

    </div>
  )
}

export default NewsLetter