const influencersActions = {
    
    INFLUENCER_REQUEST: 'INFLUENCER_REQUEST',
    INFLUENCER_REQUEST_SUCCESS: 'INFLUENCER_REQUEST_SUCCESS',

    addInfluencers: (payload) => {
        // console.log(payload,"payLoad")
        return {
        type: influencersActions.INFLUENCER_REQUEST,
        payload,
        }
    }
}

export default influencersActions;