const LegalStyles = (theme) => ({

    LegalHeader:{
      background:"#F9FAFB",
      padding:"100px 355px",
      [theme.breakpoints.down("sm")]: {
        padding:"50px",
      },
      [theme.breakpoints.down("xs")]: {
        padding:"10px",
      },
      
    },
    legalBannerHeading:{
      fontSize:"16px",
      fontWeight:"600",
      color: "#C53B20",
  
    },
  
    legalHeading:{
  fontSize:"48px",
  fontWeight:"bold",
  color:"#101828",
  lineHeight:"44px",
  marginTop:"3%",
  [theme.breakpoints.down("sm")]: {
  },
  [theme.breakpoints.down("xs")]: {
    fontSize:"36px",
  },
    },
    legalSubHeading:{
  fontSize:"20px",
  color:"#667085",
  lineHeight:"30px",
    },
  
    LegalTextContainer:{
      padding:"100px 255px",
      [theme.breakpoints.down("sm")]: {
        padding:"50px",
      },
      [theme.breakpoints.down("xs")]: {
        padding:"10px",
      },
  
    },
  
    legalPara:{
  fontSize:"18px",
  color:"#667085",
  lineHeight:"28px",
  marginTop:"3%",
  marginBottom:"3%",
  },
  legalParaHeading:{
  fontSize:"30px",
  color:"#101828",
  lineHeight:"38px",
  fontWeight:"bold",
  },
  
  
  });
  
  export default LegalStyles;
  