import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import { useParams } from "react-router-dom";
import TreatmentPlanCard from "../../../components/treatment-plan/TreatmentPlanCard";
import actions from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import { PE_ROUTE_PATH } from "../../../components/common/utils";

const useStyles = makeStyles((theme) => styles(theme));

const TreatmentPlanForm = (props) => {
  const { treatments, diseaseName } = props;

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { treatmentPlanAction } = actions;
  const { selectedDisease } = useParams();




  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");

  const _treatments = useSelector((state) => state.treatmentPlanReducer);


  return (
    <Grid
      item
      container
      className={classes.dWQTreatmentCardContainer}
      style={{
        paddingLeft:
          selectedDisease === PE_ROUTE_PATH
            ? matchesXS
              ? "0px"
              : "96px"
            : null,
      }}
      direction={matchesMD ? "column" : undefined}
      alignItems={matchesMD ? "center" : undefined}
      justifyContent={matchesMD ? "center" : undefined}
    >
      {_treatments &&
        _treatments.treatmentPlans &&
        _treatments.treatmentPlans.length > 0 &&
        _treatments.treatmentPlans.map((treatment, index) => (
         
          <Grid
            item
            key={`${treatment._id}${index}`}
            style={{
              marginRight: matchesXS ? "0px" : "24px",
              marginBottom: "32px",
            }}
          >
             {console.log(treatment,"treatment")}
            <TreatmentPlanCard planInfo={treatment} />
          </Grid>
        ))}
    </Grid>
  );
};

export default TreatmentPlanForm;
