import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import { fetchTreatmentPlan } from '../../services/treatment-plan/treatmentServices';
import actions from '../actions';

const { treatmentPlanAction } = actions;

export function* fetchTreatmentPlans() {

    yield takeEvery(treatmentPlanAction.GET_TREATMENT_PLANS, function* ({ id }) {
        try {
            const response = yield fetchTreatmentPlan(id);
            if (response.status === 200) {
                yield put({
                    type: treatmentPlanAction.GET_TREATMENT_PLANS_SUCCESS,
                    treatmentData: response.data
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}


export default function* rootSaga() {
    yield all([fork(fetchTreatmentPlans)]);
}