import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import styles from "./styles";
import QLogo from "../../assets/images/QLogo.svg";
import chevronRight from "../../assets/images/chevronRight.svg";
import chevronDownQ from "../../assets/images/chevronDownQ.svg";
import chevronUp from "../../assets/images/chevronUp.svg";
import { useLocation } from "react-router-dom";
import Account from "./Account";
import Shipping from "./Shipping";
import Payment from "./Payment";
import OrderConfirmed from "./OrderConfirmed";
import DiscountCode from "./DiscountCode";
import { showNotification } from "../../components/common-notification/CommonNotification";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";


const useStyles = makeStyles((theme) => styles(theme));

const Checkout = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [expanded, setExpanded] = React.useState("Account");
  const [orderConfirm, setOrderConfirm] = useState(false);
  const [shippingCharge, setShippingCharge] = useState(0);
  const { selectedDisease } = useParams();
  const _userInfo = useSelector((state) => state.authReducer);
  const { state } = useLocation();
  const { userSelectedPlan, questionsSummary, planDetails, disease, uuidDaata } = state
    ? state
    : {};
  console.log(state, "disease")
  const [accountDetails, setAccountDetails] = useState(null);
  const [shippingDetails, setShippingDetails] = useState(null);
  const [totalAmt, setTotalAmt] = useState();

  const [promoCodeVisibility, setPromoCodeVisibility] = useState(false);
  const [appliedCoupanId, setAppliedCoupanId] = useState("");
  const [couponAppliedFlag, setCouponsAppliedFlag] = useState(false);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [couponAmount, setCouponAmount] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState();
  const [treatmentPrice, setTreatmentPrice] = useState();
  const [planAmount, setPlanAmount] = useState();



  const handleClose = (value) => {
    setOrderConfirm(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (panel === "Account" && _userInfo.userInfo) {

    } else {
      setExpanded(isExpanded ? panel : false);
    }
  };


  useEffect(() => {
    if (questionsSummary && questionsSummary.shippingCarges) {
      setShippingCharge(questionsSummary.shippingCarges);
    }
  }, [questionsSummary]);

  console.log(disease, "disease")
  useEffect(() => {
    if (disease !== "Diabetes") {
      if (userSelectedPlan && userSelectedPlan.userSelectedQuantity) {
        setSelectedQuantity(userSelectedPlan.userSelectedQuantity)
        setTreatmentPrice(userSelectedPlan.price)
      }
    }
    else {
      if (planDetails) {
        setSelectedQuantity(1)
        setTreatmentPrice(planDetails.price)
      }
    }

  }, [userSelectedPlan]);

  useEffect(() => {
    let totalAmount = 0;
    let _amt = Number(selectedQuantity) * Number(treatmentPrice)
    setPlanAmount(_amt)
    if (disease !== "Diabetes") {
      totalAmount = Number(_amt) + Number(shippingCharge);
    }
    else {
      totalAmount = Number(_amt);
    }
    setTotalAmt(totalAmount);
  }, [selectedQuantity, shippingCharge])


  const _onIncreaseQuantity = () => {

    let allowedQuantity = 0;
    let message = ''
    if (disease === "Hairloss") {
      allowedQuantity = 3
      message = `Duration (Months) can not be more than 3`
    } else {
      allowedQuantity = 10
      message = `Quantity can not be more than 10`
    }
    if (selectedQuantity >= allowedQuantity) {
      showNotification("error", "Failed !!", " " + message)
    } else {
      let _quantity = Number(selectedQuantity + 1);
      setSelectedQuantity(_quantity);
    }
  }


  const _onDecreaseQuantity = () => {
    if (selectedQuantity > 1) {
      let _quantity = Number(selectedQuantity - 1);
      setSelectedQuantity(_quantity);
    } else {
      console.log("no less than 1")
    }
  };

  console.log(expanded, "expanded")
  return (
    <>
      <Helmet>
        <title>Doctall Purchase Checkout</title>
        <meta name="description" content="Pay for treatments, medications and tests on Doctall. Your payments are secure." />
      </Helmet>
      <Grid container className={classes.checkoutContainer}>
        <Grid item className={classes.checkoutHeader}>
          <IconButton component={Link} to="/" style={{ padding: "0px" }}>
            <img src={QLogo} alt="Logo" />
          </IconButton>
        </Grid>
        <Grid container className={classes.checkoutContent} direction="column">
          {disease !== "Diabetes" ?
                      <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        width: matchesXS ? "100%" : "475px",
                        height: "35px",
                        marginTop: "40px",
                        paddingLeft: "0px",
                      }}
                    >
                      <Typography className={classes.notexpanded}>Questions</Typography>
                      <img src={chevronRight} alt="right arrow" />
                      <Typography
                        className={
                          expanded === "Account" ? classes.expanded : classes.notexpanded
                        }
                      >
                        Account
                      </Typography>
                      <img src={chevronRight} alt="right arrow" />
                      <Typography
                        className={
                          expanded === "Shipping" ? classes.expanded : classes.notexpanded
                        }
                      >
                        Shipping Address
                      </Typography>
                      <img src={chevronRight} alt="right arrow" />
                      <Typography
                        className={
                          expanded === "Payment" ? classes.expanded : classes.notexpanded
                        }
                      >
                        Payments
                      </Typography>
                      <img src={chevronRight} alt="right arrow" />
                    </Grid>
            :
            <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{
              width: matchesXS ? "100%" : "475px",
              height: "35px",
              marginTop: "40px",
              paddingLeft: "0px",
            }}
          >
            <Typography
              className={
                expanded === "Account" ? classes.expanded : classes.notexpanded
              }
            >
              Account
            </Typography>
            <img src={chevronRight} alt="right arrow" />
            <Typography
              className={
                expanded === "Shipping" ? classes.expanded : classes.notexpanded
              }
            >
              Home Sample Collection Address
            </Typography>
            <img src={chevronRight} alt="right arrow" />
            <Typography
              className={
                expanded === "Payment" ? classes.expanded : classes.notexpanded
              }
            >
              Payments
            </Typography>
            <img src={chevronRight} alt="right arrow" />
          </Grid>
          }

          <Grid
            item
            container
            style={{ marginTop: "25px", width: "100%" }}
            direction={matchesXS ? "column" : "row"}
            alignItems={matchesXS ? "center" : undefined}
          >
            <Grid
              item
              container
              className={classes.detailsContainer}
              style={{
                width: matchesXS ? "350px" : "563px",
                alignSelf: "center",
                marginRight: matchesXS ? "0px" : "40px",
              }}
            >
              <Account
                expanded={expanded}
                handleChange={handleChange}
                setAccountDetails={(_details) => setAccountDetails(_details)}
                handleExpand={(event) => setExpanded(event)}
              />

              <Shipping
                expanded={expanded}
                handleChange={handleChange}
                setShippingDetails={(_details) => setShippingDetails(_details)}
                handleExpand={(event) => setExpanded(event)}
                setShippingCharge={(shippingPrice) =>
                  setShippingCharge(shippingPrice)
                }
                questionsSummary={questionsSummary}
                disease={disease}
              />

              <Payment
                expanded={expanded}
                handleChange={handleChange}
                planInfo={userSelectedPlan}
                questionsSummary={questionsSummary}
                payableAmount={
                  couponAppliedFlag === true ? discountedAmount : totalAmt
                }
                basePlan={planDetails}
                shippingDetails={shippingDetails}
                couponId={appliedCoupanId}
                finalQuantity={selectedQuantity}
                uuidDaata={uuidDaata}
                disease={disease}
              />
            </Grid>
            <Grid
              item
              container
              className={classes.summaryContainer}
              direction="column"
              alignSelf="flex-start"
            >
              <Grid
                item
                container
                style={{
                  paddingLeft: matchesXS ? "16px" : "33px",
                  paddingRight: matchesXS ? "16px" : undefined,
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "20px",
                    lineHeight: "30px",
                    color: "#002931",
                    paddingBottom: "20px",
                  }}
                >
                  Order Summary
                </Typography>
                <Grid
                  item
                  container
                  direction={matchesXS ? "column" : undefined}
                  style={{
                    width: matchesXS ? "320px" : "523px",
                    height: matchesXS ? "auto" : "106px",
                    marginBottom: "50px",
                  }}
                >
                  <Grid
                    item
                    style={{
                      height: "106px",
                      width: "120px",
                      background: "#F2F8F9",
                    }}
                  >
                    {userSelectedPlan && userSelectedPlan.product_image && <img style={{
                      height: "106px",
                      width: "120px",
                      background: "#F2F8F9",
                    }} src={userSelectedPlan.product_image}></img>}
                  </Grid>
                  <Grid
                    item
                    container
                    style={{
                      width: matchesXS ? "320px" : "402px",
                      paddingTop: "22px",
                      paddingLeft: matchesXS ? "0px" : "32px",
                    }}
                  >
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      style={{ paddingBottom: "9px" }}
                    >
                      <Typography className={classes.summaryHeading}>
                        {disease !== "Diabetes" ? userSelectedPlan && userSelectedPlan.brand_name : planDetails && planDetails.plan_name }
                        <br></br>
                        {disease !== "Diabetes" ? `` : planDetails && planDetails.short_info }
                      </Typography>
                      <Typography className={classes.summaryHeading}>
                        {/* ₦ {disease === "Hairloss" ? userSelectedPlan && userSelectedPlan.finalPrice : planAmount} */}
                        ₦ {planAmount}
                      </Typography>
                    </Grid>
                    {disease !== "Diabetes" &&
                      <Grid item container>

                        <Grid
                          item
                          container
                          direction="column"
                          style={{ width: "89px" }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Visuelt Pro",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#344054",
                              paddingBottom: "5px",
                            }}
                          >
                            {disease === "Hairloss" ? "Duration (Months)" : "Size"}
                          </Typography>

                          <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="space-evenly"
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #C6E2E7",
                              boxSizing: "border-box",
                              height: "45px",
                              width: "89px",
                            }}
                          >
                            <Typography className={classes.planInfoFormTitle}>
                              {selectedQuantity}
                            </Typography>
                            <Grid
                              item
                              container
                              direction="column"
                              style={{ width: "9px" }}
                            >
                              <IconButton
                                disableTouchRipple
                                className={classes.iconButton}
                                onClick={_onIncreaseQuantity}
                                style={{ marginBottom: "12px" }}
                              >
                                <img src={chevronUp} alt="down" />
                              </IconButton>
                              <IconButton
                                disableTouchRipple
                                className={classes.iconButton}
                                onClick={_onDecreaseQuantity}
                              >
                                <img src={chevronDownQ} alt="down" />
                              </IconButton>
                            </Grid>
                          </Grid>

                        </Grid>

                        {/* )} */}
                      </Grid>
                    }
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="space-between"
                  className={classes.summaryDetails}
                  style={{ marginBottom: "20px" }}
                >
                  <Typography
                    className={classes.summaryHeading}
                    style={{ fontSize: "16px", lineHeight: "24px" }}
                  >
                    Subtotal
                  </Typography>
                  <Typography
                    className={classes.summaryHeading}
                    style={{ fontSize: "16px", lineHeight: "24px", marginRight: matchesXS ? undefined : "-15px" }}
                  >
                    ₦{planAmount}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  className={classes.summaryDetails}
                  style={{
                    marginBottom: "20px",
                    height: promoCodeVisibility ? "max-content" : null,
                  }}
                >
                  <Typography
                    className={classes.summaryHeading}
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#FF5C3C",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPromoCodeVisibility(!promoCodeVisibility);
                      setCouponsAppliedFlag(false);
                    }}
                  >
                    Add discount code
                  </Typography>

                  {promoCodeVisibility && (
                    <Grid
                      style={{
                        marginTop: "3%",
                        marginBottom: "3%",
                        marginLeft: "1%",
                        width: "100%",
                        marginRight: "-30px",
                      }}
                    >
                      <DiscountCode
                        totalAmount={totalAmt}
                        close={() => {
                          setPromoCodeVisibility(!promoCodeVisibility);
                        }}
                        finalAmount={(amount) => {
                          setDiscountedAmount(amount);
                          setCouponAmount(Number(totalAmt - amount));
                        }}
                        couponAppliedFlags={(event) => {
                          setCouponsAppliedFlag(event);
                        }}
                        blockCouponid={(couponId) => {
                          setAppliedCoupanId(couponId);
                        }}
                        diseaseUuid={planDetails && planDetails.diseases_uuid}
                      />
                    </Grid>
                  )}
                </Grid>
                {disease !== "Diabetes" &&
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    className={classes.summaryDetails}
                    style={{ marginBottom: "63px" }}
                  >
                    <Typography
                      className={classes.summaryHeading}
                      style={{ fontSize: "16px", lineHeight: "24px", }}
                    >
                      Shipping
                    </Typography>
                    <Typography
                      className={classes.summaryHeading}
                      style={{ fontSize: "16px", lineHeight: "24px", marginRight: matchesXS ? undefined : "-25px" }}
                    >
                      ₦ {shippingCharge}
                    </Typography>
                  </Grid>
                }
              </Grid>
              <Grid
                item
                container
                style={{
                  width: "100%",
                  background: "#F2F8F9",
                  border: "1px solid #D9EDF1",
                  boxSizing: "border-box",
                  borderRadius: "4px",
                  height: "auto",
                  paddingLeft: matchesXS ? "16px" : "33px",
                  paddingRight: matchesXS ? "16px" : undefined,
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
                alignItems="center"
              >
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  className={classes.summaryDetails}
                  style={{ marginBottom: "16px" }}
                >
                  <Typography className={classes.summaryHeading}>
                    Total
                  </Typography>
                  <Typography className={classes.summaryHeading} style={{ marginRight: matchesXS ? undefined : "-40px" }}>
                    ₦ {totalAmt}
                  </Typography>
                </Grid>

                {couponAppliedFlag === true && discountedAmount >= 0 && (
                  <>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      className={classes.summaryDetails}
                      style={{ marginBottom: "16px" }}
                    >
                      <Typography className={classes.summaryHeading}>
                        Discounted Amount
                      </Typography>
                      <Typography className={classes.summaryHeading}>
                        ₦{couponAmount}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      className={classes.summaryDetails}
                    >
                      <Typography className={classes.summaryHeading}>
                        Amount to pay
                      </Typography>
                      <Typography className={classes.summaryHeading}>
                        ₦{discountedAmount}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <OrderConfirmed open={orderConfirm} onClose={handleClose} />
      </Grid>
    </>
  );
};

export default Checkout;
