const headerStyle = (theme) => ({
  middleNav: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "10.4rem",
    background: "#ffffff",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    [theme.breakpoints.down("sm")]: {
      height: "58px",
    },
  },
  tabLinks: {
    "&>a": {
      textDecoration: "none",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "1.4rem",
      lineHeight: "1rem",
      color: "#101828",
    },
  },

  logo: {
    paddingLeft: "6.4rem",
    paddingRight: "4.4rem",
    marginRight: "10rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem",
      paddingLeft: "1rem",
    },
  },

  logoButton: {
    "&:hover": {
      background: "transparent",
      //  marginLeft: "-3rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1rem",
    },
  },

  loginSignUp: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    padding: "10px 18px",
    background: "#ff5c3c",
    border: "1px solid #ff5c3c",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    color: "#ffffff",
    marginLeft: "-70px",
    textDecoration: "none",
    "&:hover": {
      background: "#ff5c3c",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
      fontSize: "1.4rem",
    }, 
  },
  shoping1:{ 
    boxSizing: "border-box",
    position: "absolute",
    width: "44px",
    height: "44px",
    marginLeft: "40PX",
    marginBottom: "50px",
    marginTop: "-25px",
    background: "#D9EDF1",
    border: "1px solid #BBEAF2"
  },

  cartContainer: {
    width: "44px",
    height: "44px",
    background: "#d9edf1",
    border: "1px solid #bbeaf2",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      background: "#d9edf1",
    },
  },
  drawerToggle: {
    display: "flex",
    alignItems: "center",
  },
  drawerList: {
    color: "#191716",
    textDecoration: "none",
    textTransform: "none",
    fontSize: "1.5rem",
    padding: "1rem 3rem",
    // backgroundColor: "#FF5C3C",
  },
  menuContainer: {
    background: "#00839B",
    overFlow: "auto",
    // position: "absolute",
    width: "100%",
    paddingLeft: "9.4rem",
    // paddingRight: "6rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "5rem",
      paddingRight: "4rem",
    },
  },
  menuDropdown: {
    height: "84px",
    width: "100%",
    background: "#E5E5E5",
  },

  tabContainer: {
    height: "65px",
    top: "-2px",
    position: "relative",
  },

  tabIconWrapper: {
    flexDirection: "row-reverse",
    fontFamily: "Visuelt Pro",
    flexGrow: "0px",
    flex: "0px",
    gridGap: "4px",
    color: "#ffffff",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "1px",
    minWidth: 10,
    // marginLeft: "-1px",
    marginRight: "1.6rem",
    // width: "125px",

    [theme.breakpoints.down("md")]: {
      fontSize: "14px", 
      marginLeft: "0px",
      marginRight: "1.2rem",
      gridGap: "13px",
    },

    [theme.breakpoints.up("xl")]: {
      marginRight: "5rem",
    },
  },

  popoverPaper: {
    width: "100%",
    height: "80%",
    maxHeight: "unset",
    top: "0px",
    left: "0px",
  },
  menu: {
    left: "0px !important",
    padding: "0px",
    borderRadius: "0px",
    height: "auto",
  
    

    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
  },

  menuItemContainer: {
    paddingTop: "3.2rem",
    paddingLeft: "11.2rem",
    paddingBottom: "96px",
    
  },
  menuHeading: {
    paddingBottom: "12px",
    color: "#FF5C3C",
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    
  },
  menuItem: {
    padding: "0px",
    marginBottom: "1.6rem",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    "&:hover": {
      color: "inherit",
      borderLeft: "2px solid #FF5C3C",
      fontFamily: "Inter",
      fontWeight: "600",
      paddingLeft: "5px",
    },
  },

  currentSubMenu: {
    background: "transparent",
    color: "inherit",
    borderLeft: "2px solid #FF5C3C",
    fontFamily: "Inter",
    fontWeight: "600",
    paddingLeft: "5px",
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: "1.6rem",
    "&:hover": {
      background: "transparent",
    },
  },

  SelectedMenuItem: {
    padding: "0px",
    marginBottom: "1.6rem",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    background: "transparent",
    color: "inherit",
    borderLeft: "2px solid #FF5C3C",
    fontFamily: "Inter",
    fontWeight: "600",
    paddingLeft: "5px",
  },
  menuBlogContainer: {
    paddingTop: "32px",
    paddingLeft: "32px",
    paddingRight: "52px",
    background: "#F9FAFB",
    height: "100%",
  },
  menuBlog: {
    width: "240px",
    border: "none",
    borderRadius: "none",
    boxShadow: "none",
    background: "#F9FAFB",
    padding: "0px",

    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
  },
  cardSetter: {
    padding: "0px",
  },
  readMoreButton: {
    ...theme.typography.primaryButton,
    border: "1px solid #00839B",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    padding: "8px 14px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  menuBlogTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#002931",
    paddingTop: "24px",
  },
  menuBlogSubtitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#002931",
    paddingTop: "4px",
    paddingBottom: "12px",
  },
  sectionDropdown: {
    width: "320px",
    height: "44px",

    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "8px",
    color: "#667085",
    "&:hover": {
      background: "#FFFFFF",
    },
    [theme.breakpoints.only("xs")]: {
      width: "320px",
    },
  },
  sectionMenuDropdown: {
    width: "320px",
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },

  menuDropdownmob: {
    width: "100%",
    minHeight: "44px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    fontStyle: "normal",
    fontSize: "16px",
    color: "#667085",
    textTransform: "none",
  },
  submenu: {
    width: "100%",
    paddingLeft: "24px",
  },
  factsContainer: {
    height: "488px",
    backgroundColor: "#F9FAFB",

    paddingLeft: "33px",
  },
  drawerMenu: {
    fontStyle: "normal",
    fontSize: "16px",
    color: "#667085",
  },
  menuText: {
    "&.MuiAccordion-root.Mui-expanded:first-child": {
      margin: "0px",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "&.MuiAccordion-rounded:last-child": {
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
    },
  },
  drawerSubMenu: {
    fontStyle: "normal",
    fontSize: "14px",
    color: "#667085",

    textDecoration: "none",
  },
  drawer: {
    width: "75%",
  },

  // hairlossstyele:{

  //   padding: "0px",
  //   marginBottom: "1.6rem",
  //   fontStyle: "normal",
  //   fontWeight: "normal",
  //   fontSize: "16px",
  //   lineHeight: "24px",
  //   background: "transparent",
  //   color: "inherit",
  //   borderLeft: "2px solid #FF5C3C",
  //   fontFamily: "Inter",
  //   fontWeight: "600",
  //   paddingLeft: "5px",
  //   // "&:hover": {
  //   //   background: "transparent",
  //   //   color: "inherit",
  //   //   borderLeft: "2px solid #FF5C3C",
  //   //   fontFamily: "Inter",
  //   //   fontWeight: "600",
  //   //   paddingLeft: "5px",
  //   // },
  // },
});

export default headerStyle;
