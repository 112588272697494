import React from "react";
import {
    Grid,
    Button,
    Typography,
    useMediaQuery,
    Box,
} from "@material-ui/core";
import starz from "../../../../assets/images/stars.svg";
import StaticHeader from "../Static_Header/StaticHeader";
import { useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import deliver from "../../../../assets/images/deliveryFree.png";
import easytobe from "../../../../assets/images/easy-to-buy.svg";
import doctorsuport from "../../../../assets/images/DoctorSupport.png";
// import carrerImg from "../../../assets/images/careerImg.png";
// import careerImgMob from "../../../assets/images/careerImgMob.png";
import staticpe from "../../../../assets/images/staticpe.png";
import Footer from "../../../../components/Layout/Footer/Footer";
// import Footer from "../../../components/Layout/Footer/Footer";


const useStyles = makeStyles((theme) => styles(theme));

const StaticPE = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const matchesXL = useMediaQuery(theme.breakpoints.down("xl"));

    return(
<>
<StaticHeader />
<Grid container>
<Grid item container direction="column" className={classes.contactHeader} >
<Typography className={classes.careerBannerTitle}>Premature Ejaculation
                    </Typography>
                    <Typography className={classes.careerPara}>
                    Research shows that about one in five male adults between 18-59 experience premature ejaculation. If it happens often, there are various highly effective, clinically-proven solutions that you can choose from below.
                    </Typography>
                    <span className={classes.star}>
                        <img src={starz} alt="rating" />
                    </span>
                    <Typography className={classes.testimonial}>
                      “The problem I had consulted you about is now a thing of the past. You should be very proud of your medical team and their treatments are the world's best”
                      </Typography>
                    <Typography className={classes.doctall}> DOCTALL PATIENT</Typography>
                    <Grid  item className={classes.img} sm={12} md={12}>
                    <div>
                        <img src={staticpe} alt="premature" />
                    </div>
                    </Grid>
</Grid>
</Grid>
<Grid item >
    <Grid container
    
    direction={matchesXS ? "column" : "row"}

    style={{
        marginTop: matchesSM ? "30px" : "20px",
        borderBottom: "1px solid rgba(174, 215, 223, 0.4)",
        minHeight: matchesXS ? "300px" : "70px",
      }}
      >
                <Grid
              item
              lg={4}
              md={4}
              sm
              container
              justifyContent={matchesXS ? "flex-start" : "center"}
              alignItems="center"
              style={{
                height: matchesXS ? "100px" : "20px",
                background: "#ffffff",
                paddingLeft: matchesXS ? "15%" : undefined,
              }}
            >
              <img src={deliver} alt="iconDeliver" />
              <Typography className={classes.navLink}>
              &nbsp;  Discreet delivery
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm
              container
              justifyContent={matchesXS ? "flex-start" : "center"}
              alignItems="center"
              style={{
                height: matchesXS ? "100px" : "20px",
                background: "#ffffff",
                paddingLeft: matchesXS ? "15%" : undefined,
              }}
            >
              <img src={easytobe} alt="easyToBuyicon" style={{ height: "55px" }} />
              <Typography className={classes.navLink}>
              &nbsp; Easy to buy
              </Typography>
            </Grid>
            
            <Grid
              item
              lg={4}
              md={4}
              sm
              container
              justifyContent={matchesXS ? "flex-start" : "center"}
              alignItems="center"
              style={{
                height: matchesXS ? "100px" : "20px",
                background: "#ffffff",
                paddingLeft: matchesXS ? "15%" : undefined,
              }}
            >
              <img src={doctorsuport} alt="iconClinician" /> 
              <Typography className={classes.navLink}>
              &nbsp;  Doctor support available
              </Typography>
            </Grid>
            </Grid>
            </Grid>

            <Grid item style={{
                    height : matchesXS ? "300px" : "270px"
                }}>
         

                
                <Grid sm={12} md={12} xs={12} className={classes.careerContainer} align="center" >
                   
                    <Typography className={classes.careerHeading}  >
                      For more information on all of our Premature Ejaculation treatment plans, click Buy Treatment!
                      </Typography>
                    <Button  variant="contained"
                    style={{ color: "#ffffff", 
                    fontSize: "14px", 
                    fontWeight: "500", 
                    backgroundColor: "#FF5C3C", 
                    border: "1px solid #FF5C3C",
                    borderRadius: "8px", 
                    textColor: "#ffffff",
                    padding: "5px 15px",
                    width: "176px",
                    height: "60px", 
                    // color: "white",
                    boxSizing: "border-box",
                    textTransform: "capitalize",
                    marginTop: matchesSM ? "130px" : "50px",
                    }} 
                    onClick={() => navigate("/premature-ejaculation")}
                    >
                        Buy Treatment 
                    </Button>
                    
                    
                    </Grid>
            </Grid>

<Footer />
</>
    )
}

export default StaticPE;