// export const EDquestions = [
//   {
//     id: 1,
//     active: true,
//     title: "The following questions ensure your medical suitability for ED Treatment Plans, and must be answered accurately and by those of 18 years of age and over. ",
//     options: [
//       "Do you have, or have ever had, a heart condition, stroke, kidney or liver problem?",
//       "Are you taking any medication for angina, blood pressure and/or any heart condition?",
//       "Do you suffer from Sickle Cell Anaemia Myeloma or Leukaemia?",
//       "Do you suffer from either Non-Arteritic Ischemic Optic Neuropathy (NAION) and / or Hereditary Degenerative Retinal Disorder? ",
//     ],
//     PEOptions: [
//       "I am under 18 years old",
//       "I am between 18 - 65 years old",
//       "I am 65 and above years old ",
//     ],
//     value: 0,
//   },
//   {
//     id: 2,
//     active: false,
//     title: "KINDLY PROVIDE YOUR EMAIL ADDRESS",
//     value: 10,
//     address: {
//       title: "Kindly select your shipping address",
//       subtitle: "Kindly provide your city and area",
//     },
//   },
  
//   {
//     id: 3,
//     active: false,
//     title: "The following questions ensure your medical suitability for ED Treatment Plans, and must be answered accurately and by those of 18 years of age and over.",
//     drugs: [
//       {
//         ques: "Do you have, or have ever had, a heart condition, stroke, kidney or liver problem?",
//         subques: [
  
//         ]
//       },
//       {
//         ques: "Are you taking any medication for angina, blood pressure and/or any heart condition?",
//         subques: [
  
//         ]
//       },
//       {
//         ques: "Do you suffer from Sickle Cell Anaemia Myeloma or Leukaemia?",
//         subques: [
  
//         ]
//       },
//       {
//         ques: "Do you suffer from either Non-Arteritic Ischemic Optic Neuropathy (NAION) and / or Hereditary Degenerative Retinal Disorder?",
//         subques: [
  
//         ]
//       },
//       // "Do you have, or have ever had, a heart condition, stroke, kidney or liver problem?",
//       // "Are you taking any medication for angina, blood pressure and/or any heart condition?",
//       // "Do you suffer from Sickle Cell Anaemia Myeloma or Leukaemia?",
//       // "Do you suffer from either Non-Arteritic Ischemic Optic Neuropathy (NAION) and / or Hereditary Degenerative Retinal Disorder?",
//     ],
//     value: 20,
//   },
//   {
//     id: 4,
//     active: false,
//     title: "Do you have any allergic reaction to the following drugs?",
//     drugs:  [
//       {
//         ques: "Have you been advised by your Doctor to refrain from strenuous activity, such as exercise or sex?",
//         subques: [
  
//         ]
//       },
//       {
//         ques: "Do you get chest pain or shortness of breath walking short distances or up a flight of stairs?",
//         subques: [
  
//         ]
//       },
//       {
//         ques: "Do you have any of the following?",
//         subques: [
//           {
//             test: "Inflammation or infection of your penis"
  
//           },
//           {
//             test: "Severe curvature of the penis"
  
//           },
//           {
//             test: "The opening of the penis (the urethra) is on the underside of the penis"
  
//           },
//           {
//             test: "A urethra that is abnormally tight"
  
//           }
//         ]
//       },
//       {
//         ques: "Have you ever suffered an allergic reaction (rash, swelling etc.) after taking medication for erectile dysfunction? ",
//         subques: [
  
//         ]
//       }
//     ],
//     value: 40,
//   },
//   // {
//   //   id: 3,
//   //   active: false,
//   //   title: "Do you have any allergic reaction to the following drugs?",
//   //   drugs: ["Sidenafil", "Tadafil", "Verdanifil", "Avanafil"],
//   //   value: 20,
//   // },
//   // { id: 4, title: " Do you have a kidney or liver impairment?", value: 30 },
//   // {
//   //   id: 5,
//   //   title: "Do you suffer from Sickle cell anemia myeloma or Leukemia?",
//   //   value: 40,
//   // },
//   // {
//   //   id: 6,
//   //   title: "Do any of the listed medical conditions apply to you?",
//   //   questions: [
//   //     " Do you take Diltiazem, Verapamil or Amiodarone",
//   //     "Have you been advised from your doctor to refrain from strenuous activity such as sex?",
//   //     "Have you had a heart attack/myocardial infarction stroke/transient ischemic attack in the last 6 months?",
//   //     "Do you suffer from a hereditary degenerative retinal disorder?",
//   //   ],
//   //   value: 60,
//   // },
//   // {
//   //   id: 7,
//   //   title: "Do any of the listed medical conditions apply to you?",
//   //   questions: [
//   //     "Do you get chest pain or short of breath walking short distances or up a flight stair?",
//   //     "Do you have a history of non arteritic anterior ischemic optic neuropathy?",
//   //     "Do you take cimetidine, indoramin, erythromycin/clarithromycin, fluconazole/itraconazole, ketoconazole, miconazole, voriconazole?",

//   //   ],
//   //   value: 70,
//   // },
//   // {
//   //   id: 8,
//   //   title: "Do any of the listed medical conditions apply to you?",
//   //   questions: [
//   //     "Do you take Nicorandil, Glyceryt Trinitrate, Isosorbide Mmononitrate, Amyl Nitrate?",
//   //     "Do you suffer penile deformity?",
//   //     " Do you have major illness, ongoing medical condition, allergy to medications, or take prescribed or over the counter medications?",
//   //   ],
//   //   value: 80,
//   // },
// ];

export const EDquestions = [
  {
    id: 1,
    active: true,
    title: "DO YOU AGREE AND CONSENT TO THE FOLLOWING?",
    options: [
      "I am under 18 years old",
      "I am between 18 - 25 years old",
      "I am between 26 - 69 years old",
      "I am between 70 and above years old ",
    ],
    PEOptions: [
      "I am under 18 years old",
      "I am between 18 - 65 years old",
      "I am 65 and above years old ",
    ],
    value: 0,
  },
  {
    id: 2,
    active: false,
    title: "KINDLY PROVIDE YOUR EMAIL ADDRESS",
    value: 10,
    address: {
      title: "Kindly select your shipping address",
      subtitle: "Kindly provide your city and area",
    },
  },
  {
    id: 3,
    active: false,
    title: "Do you have any allergic reaction to the following drugs?",
    drugs: ["Sidenafil", "Tadafil", "Verdanifil", "Avanafil"],
    value: 20,
  },
  { id: 4, title: " Do you have a kidney or liver impairment?", value: 30 },
  {
    id: 5,
    title: "Do you suffer from Sickle cell anemia myeloma or Leukemia?",
    value: 40,
  },
  {
    id: 6,
    title: "Do any of the listed medical conditions apply to you?",
    questions: [
      " Do you take Diltiazem, Verapamil or Amiodarone",
      "Have you been advised from your doctor to refrain from strenuous activity such as sex?",
      "Have you had a heart attack/myocardial infarction stroke/transient ischemic attack in the last 6 months?",
      "Do you suffer from a hereditary degenerative retinal disorder?",
    ],
    value: 60,
  },
  {
    id: 7,
    title: "Do any of the listed medical conditions apply to you?",
    questions: [
      "Do you get chest pain or short of breath walking short distances or up a flight stair?",
      "Do you have a history of non arteritic anterior ischemic optic neuropathy?",
      "Do you take cimetidine, indoramin, erythromycin/clarithromycin, fluconazole/itraconazole, ketoconazole, miconazole, voriconazole?",

    ],
    value: 70,
  },
  {
    id: 8,
    title: "Do any of the listed medical conditions apply to you?",
    questions: [
      "Do you take Nicorandil, Glyceryt Trinitrate, Isosorbide Mmononitrate, Amyl Nitrate?",
      "Do you suffer penile deformity?",
      " Do you have major illness, ongoing medical condition, allergy to medications, or take prescribed or over the counter medications?",
    ],
    value: 80,
  },
];

export const PEquestions = [
  {
    id: "age",
  },
  { id: "email" },
  {
    id: 3,
    title: "How long have you been experiencing premature ejaculation?",
    options: [
      "Ever since my first sexual encounter",
      "For more than 6 months",
      "For less than 6 months",
    ],
    value: 30,
  },
  {
    id: 4,
    title: "How often do you feel affected by premature ejaculation?",
    options: [
      "Every time I have sex",
      "More than half time I have sex",
      "Less than half time I have sex",
    ],
    value: 40,
  },
  {
    id: 5,
    title: "How soon after entering your partner do you ejaculate",
    options: [
      "Ejaculate before entering my partner",
      "Less than 2 mins after entering my partner",
      "Between 2 -10 mins after entering my partner",
      "More than 10 mins after entering my partner",
    ],
    value: 50,
  },
  {
    id: 6,
    title: "Do you have a control when you ejaculate?",
    options: [
      "Yes, I can control when I ejaculate",
      "No, I cannot control when I ejaculate",
      "I can sometimes control when I ejaculate",
    ],
  },
  {
    id: 7,
    title:
      "Does Premature ejaculation cause distress or difficulties in your sex life",
    options: ["Yes absolutely", "Yes, to a certain extent", "No"],
  },
  {
    id: 8,
    title: "Have you tried any medication for premature ejaculation before?",
    options: [
      "Yes, it worked for me",
      "Yes, it did not work for me",
      "Yes, I experienced side effects",
      "No, never",
    ],
  },
  {
    id: 9,
    title: "Can you always get and maintain an erection, when you want one",
  },
  {
    id: 10,
    title:
      "Are you currently experiencing or have you ever experienced depression, anxiety, mania, or panic disorder?",
  },
  {
    id: 11,
    title: "Do any of the listed medical conditions apply to you?",
    questions: [
      "Do you have any heart conditions as heart failure, ischemic heart diseases, heart valve replacement, heart rhythm or peripheral vascular diseases?",
      "Do you have any neurological conditions such as epilepsy or fainting episodes due to low pressure?",
      "Do you have any blood clotting disorders or take any medication that effects your blood clotting?",
      "Do you have any liver or Kidney conditions?",
    ],
  },
  {
    id: 12,
    title:
      "Do you have any prostate conditions such as prostatitis or benign prostatic hypertrophy?",
  },
  {
    id: 13,
    title:
      "Do you have any hormone conditions such as thyroid problems or hypogonadism (low testosterone levels)",
  },
  { id: 14, title: "Do you consume alcohol" },
  {
    id: 15,
    title: "Have you consumed any illegal drugs in the last 6 months?",
  },
  { id: 16, title: "Would you like to talk to a GP?" },
];

export const HLquestions = [
  {
    id: "age",
  },
  { id: "email" },
  {
    id: 3,
    title: "Type of Hair loss",
    options: [
      "Hairline / Front of the Head",
      "Crown/ Middle of the Head",
      "Thinning all over",
      "In Patches",
      "Others",
    ],
  },
  {
    id: 4,
    title: "When did you start notice your hair loss",
    options: [
      "just started",
      "over the past few days or weeks",
      "gradually started over the last few months to years",
    ],
  },
  {
    id: 5,
    title:
      "Have you noticed Hair loss on any other body parts other than your head?",
  },
  {
    id: 6,
    title:
      "Have you taken any of the medications in the last 12 months (minoxidil oral/topical, Finasteride)",
  },
  {
    id: 7,
    title: "Are you allergic to Finasteride, Minoxidil or Lactose?",
  },
  {
    id: 8,
    title: "Do you have any scalp issues?",
    options: [
      "Pain itching bumps on the scalp",
      "Dandruff",
      "Scalp Psoriasis",
      "Scalp Eczema",
      "Rashes on the Scalp",
      "None",
    ],
  },
  { id: 9, title: "Are you undergoing any treatment for Prostate?" },
  { id: 10, title: "Do you have uncontrolled High/Low Blood Pressure?" },
  {
    id: 11,
    title:
      "Any history or currently Experiencing depression Anxiety or panic disorders",
  },
  { id: 12, title: "Do you Smoke" },
  {
    id: 13,
    title: "Any History of",
    questions: [
      "Major Illness",
      "Ongoing Medical Condition",
      "Drug Allergies",
      "Surgical History",
      "Ongoing Medications",
    ],
  },
  {
    id: 14,
    title: "Would you like to have a consultation with GP?",
  },
  {
    id: 15,
    title: "Do you have any scalp issues?",
    questions: [
      "Pain itching bumps on the scalp",
      "Dandruff",
      "Scalp Psoriasis",
      "Scalp Eczema",
      "Rashes on the Scalp",
      // "None",
    ],
  },
];
