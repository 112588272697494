import {
    Grid, IconButton, makeStyles, useMediaQuery, useTheme
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import prevQuestionIcon from "../../assets/images/prevQuestion.svg";
import LinearProgressBar from "../../components/ui/LinearProgress";
import { AgeQuestion, Mail } from "./components/staticQuestions.js";
import { EDquestions as questions, HLquestions } from "./Questions";
import styles from "./styles";
import "./styles.js";

import { Helmet } from "react-helmet";
import { getFirebaseAnalyticsInstance } from "../../firebase.js";
import CallBackPopUp from "../callbackPopUp/CallBackPopUP.js";
import {
    MedicalConditions, MultiChoice, MultiChoiceValidation, RadioChoiceResponse
} from "./components/HLQuestionnaire";
import { RadioChoice } from "./components/PEQuestionnaire";
import { saveQuestionnaire } from "../../services/category-services/categoryServices";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => styles(theme));

const Questionnaire = () => {
  const classes = useStyles();
  const theme = useTheme();
  let navigate = useNavigate();
  const { state } = useLocation();
  const { selectedPlan, diseaseId, diseaseName, planDetails } = state
    ? state
    : {};

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    age: "",
    email: "",
    userResponse: [],
    shippingCarges: 0,
    shippingAddress: {},
  });
  const [questionsData, setQuestionsData] = useState([]);
  const [callBackModel, setCallBackModel] = useState(false);
  const _userInfo = useSelector((state) => state.authReducer);

  const [ uuidDaata,  setUuidDaata ] = useState('');
  console.log(uuidDaata, "uuidDaata")
  const [questionsData1, setQuestionsData1] = useState([]);
  useEffect(() => {
    if (step > HLquestions.length) {
      data["userResponse"] = questionsData;
      navigate("/summary", {
        state: {
          questionsSummary: data,
          userSelectedPlan: selectedPlan,
          planInfo: planDetails,
          disease: diseaseName,
          uuidDaata: uuidDaata
        },
      });
    }
  }, [step, data]);

  useEffect(() => {
    addFireBaseAnalytics();
  }, [step, data]);

  const addFireBaseAnalytics = async () => {
    try {
      await getFirebaseAnalyticsInstance().logEvent("HL_QUESTIONNAIRE_START", {
        eventType: 'TREATMENT_PLAN_QUESTIONNAIRE',
        platform: 'web'
      });
    } catch (error) {
      console.log(error)
    }
  }

  data["userResponse"] = questionsData;

  const prevQuestion = () => {
    setStep(step - 1);
  };
  const RenderedItem = () => {
    switch (step) {
      case 1:
        return (
          <AgeQuestion
            age={questions[0]}
            selectedAge={data.age}
            handleChange={(selectedAge) =>
              setData({ ...data, age: selectedAge })
            }
            stepUpdate={(step) => setStep(step)}
            HL={true}
          />
        );
        break;
      case 2:
        return (
          
          <Mail
            mail={questions[1]}
            userEmail={data.email}
            handleChange={(email, shippingCharge, shippingDetails) =>
              setData({ ...data, email: email, shippingCarges: shippingCharge, shippingAddress: shippingDetails })
            }
            stepUpdate={(step) => setStep(step)}
          />
        );
      case 3:
        return (
          <MultiChoice
            question={HLquestions[2]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
            //sev = {(option) => setQuestionsData1((prev) => [...prev, option])}
          />
        );
      case 4:
        return (
          <MultiChoiceValidation
            question={HLquestions[3]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
          // saveQuestions1()
        );
      case 5:
        return (
          <RadioChoiceResponse
            ques={HLquestions[4]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            uuidata = {(data) => setUuidDaata(data)}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
          // saveQuestions1()
        );
      case 6:
        return (
          <RadioChoiceResponse
            ques={HLquestions[5]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            uuidata = {(data) => setUuidDaata(data)}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
          // saveQuestions1()
        );
      case 7:
        return (
          <RadioChoiceResponse
            ques={HLquestions[6]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
          // saveQuestions1()
        );
      case 8:
        return (
          // <MultiChoiceResponse
          //   question={HLquestions[7]}
          //   stepUpdate={(step) => setStep(step)}
          //   step={step}
          //   saveData={(option) => setQuestionsData((prev) => [...prev, option])}
          // />
          <MedicalConditions
            condition={HLquestions[14]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
          
          // saveQuestions1()
        );
      case 9:
        return (
          <RadioChoiceResponse
            ques={HLquestions[8]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
          // saveQuestions1()
        );
      case 10:
        return (
          console.log(uuidDaata, "uuidDaata"),
          <RadioChoice
            question={HLquestions[9]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            callBack={(event) => setCallBackModel(event)}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            uuidDaata={uuidDaata}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
          />
          // saveQuestions1()
        );
      case 11:
        return (
          <RadioChoice
            question={HLquestions[10]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            callBack={(event) => setCallBackModel(event)}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            uuidDaata={uuidDaata}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
          />
          // saveQuestions1()
        );
      case 12:
        return (
          <RadioChoice
            question={HLquestions[11]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            callBack={(event) => setCallBackModel(event)}
            sav = {data}
            uuidDaata={uuidDaata}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
          />
          // saveQuestions1()
        );
      case 13:
        return (
          <MedicalConditions
            condition={HLquestions[12]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            uuidDaata={uuidDaata}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
          />
          // saveQuestions1()
        );

      case 14:
        return (
          <RadioChoice
            question={HLquestions[13]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            callBack={(event) => setCallBackModel(event)}
            sav = {data}
            uuidDaata={uuidDaata}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
          />
          // saveQuestions1()
        );

      default:
        data["userResponse"] = questionsData;
        navigate("/summary", {
          state: {
            questionsSummary: data,
            userSelectedPlan: selectedPlan,
            planInfo: planDetails,
            disease: diseaseName,
            uuidDaata : uuidDaata,
          },
        });
        return null;
        break;
    }
  };

  return (
    <>
    <Helmet>
      <title>Hair Loss Questionnaire | Treat hair loss in women and men</title>
      <meta 
      name="description" 
      content="Buy hair loss treatment for men and women. Choose your treatment plan, answer basic questions about your symptoms, completer your treatment order, and have your medicines delivered to you discreetly" 
      />
    </Helmet>
    <Grid item container alignItems="center" direction="column">
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: "58px", //width: "820px"
        }}
      >
        {step === 1 ? null : (
          <Grid item style={{ width: "114px", marginRight: "39px" }}>
            <IconButton disableFocusRipple disableRipple>
              <img src={prevQuestionIcon} alt="prev" onClick={prevQuestion} />
            </IconButton>
          </Grid>
        )}

        <Grid item>
          <LinearProgressBar
            value={(step - 1) * 7}
          />
        </Grid>
      </Grid>
      <Grid item container>
        {<RenderedItem />}{" "}
      </Grid>
      <CallBackPopUp
        open={callBackModel}
        close={() => setCallBackModel(!callBackModel)}
      />
    </Grid>
    </>
  );
};

export default Questionnaire;
