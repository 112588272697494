import React from "react";
// import React from "react";
import {
    colors,
    Grid,
    Typography,
    useMediaQuery,
    
   
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header/Header";
import { getCurrentDate } from "../../components/common/utils";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => styles(theme));

const PrivacyAndPolicy = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
       
            <Header />
            <Helmet>
            <title>Privacy Policy - Doctall</title>
            <meta 
            name='description' 
            content='Your privacy is important to us at Doctall. We respect your privacy regarding any information we may collect from you across our website. Read our privacy policy for more on this '
            />
        </Helmet>
            <Grid container>
           
                <Grid item container direction="column" className={classes.LegalHeader} align="center" >
                    <Typography className={classes.legalBannerHeading}>{`Current as of ${getCurrentDate()}`}</Typography>
                    <Typography className={classes.legalHeading}>Privacy and Cookie Policy</Typography>
                    <Typography className={classes.legalSubHeading}>Your privacy is important to us at Doctall. We respect your privacy regarding any information we may collect from you across our website.
                    </Typography>

                </Grid>

                <Grid item container direction="column" className={classes.LegalTextContainer} >

                    <Typography className={classes.legalParaHeading}>1. Introduction</Typography>
                    <Typography className={classes.legalPara}>
                      DigiCare Hospital & Allied Services Ltd. (hereinafter "Doctall" or "We") will process personal
                      data about you as part of your use of our services and in connection with visits to our website
                      and mobile applications ("the Service"). We believe that personal privacy is important and adhere
                      to applicable data protection laws to protect your personal privacy. This Privacy Policy ("Privacy Policy") 
                      is intended to inform you about our processing of your personal data and about your rights. Your personal 
                      data is handled in accordance with the EU General Data Protection Regulation ("GDPR"). <br/><br/>

                      1.1 Doctall takes your privacy and confidentiality very seriously.<br/><br/>
                      1.2 This policy applies where we are acting as a data controller with respect to the personal data of our website visitors and service users; in other words, where we determine the purposes and means of the processing of that personal data.<br/><br/>
                      1.3 We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.<br/><br/>
                      1.4 In this policy, “we”, “us” and “our” refer to Doctall Limited. For more information about us, see Section 12.<br/><br/>


                    </Typography>
                    <Typography className={classes.legalParaHeading}>2. How we use your personal data.</Typography>
                    <Typography className={classes.legalPara}>
                     {/* The entity responsible for your personal data is DigiCare Hospital & Allied Services Ltd. We can be reached by <br/>
                     e-mail:  <a href="mailto:support@doctall.com"><span className={classes.legalPara}>support@doctall.com. </span></a> */}
                     2.1 In this Section, we have set out:<br/><br/>
                    (a) the general categories of personal data that we may process;<br/><br/>
                    (b) the purposes for which we may process personal data; and<br/><br/>
                    (c) the legal basis of the processing.<br/><br/>
                    2.2 We may process data about your use of our website and services (“usage data“). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is primarily Google Analytics along with server monitoring through our platform. This usage data may be processed for the purposes of analysing the use of the website and services. The legal basis for this processing is for monitoring and improving our website and services.<br/><br/>
                    2.3 We may process your account data (“account data“). The account data may include your name, email address, interests and social media information. Account data provided by you. The account data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is so you can use Doctall, and to ensure we present relevant information to you.<br/><br/>
                    2.4 We may process your information included in your personal profile on our website (“profile data“). The profile data may include your name, address, telephone number, email address, gender, plus other elements you may choose to share with us. The profile data may be processed for the purposes of enabling and monitoring your use of our website and services. The legal basis for this processing is to ensure that the information we provide to you is tailored to your preferences and to ensure that your personal information is up to date for your interactions with Doctall Caregivers. We will not share your personal information without permission.<br/><br/>
                    2.5 By using our Service, which includes your meeting with affiliated Caregivers, you may share personal information regarding your past and present physical and mental health. This information may include, but is not limited to, information that you are suffering from any illness, your medical history or your physiological or biomedical condition ("Health Data"). Health data that is particularly attributable to you may also be provided by the Caregiver you come into contact with when you use our service. The fact that the Caregiver adds information is part of the Caregiver's obligation to you as a patient.
                    We do not share any Health Data with any third party outside of our service, except in cases where such disclosure results from law. In order to offer you our services, we also use a few external service providers who process personal data (including Health Data) on behalf of us.
                    If you have been referred to Doctall by your insurer, We may, for the regulation of a specific insurance case, disclose information about your health condition, including a copy of your medical records, to your insurer.<br/><br/>
                    2.6 We may process your personal data that are provided during the use of our services or to answer enquiries (“service data“, “enquiry data”, “health data”). The service data may include your contact information such as your name, telephone number, email address, plus other elements you may choose to provide to us to share on your behalf.

                    The service data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. We will inform you ahead of sharing any personally identifiable information with any third party. The legal basis for this processing is to provide the service for you and to log requests for backup and site performance purposes.<br/><br/>
                    2.7 We may process information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters (“notification data“). The notification data may be processed for the purposes of sending you the relevant notifications and/or newsletters. The legal basis for this processing is to deliver your requests for information.<br/><br/>
                    2.8 We may process information contained in or relating to any communication that you send to us (“correspondence data“). The correspondence data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms. The correspondence data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business and communications with users.<br/><br/>
                    2.9 We may process any of your personal data identified in this policy where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.<br/><br/>
                    2.10 In addition to the specific purposes for which we may process your personal data set out in this Section 3, we may also process any of your personal data where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.<br/><br/>
                    2.11 Please do not supply any other person’s personal data to us, unless we prompt you to do so.<br/><br/>

                    </Typography>
                    <Typography className={classes.legalParaHeading}>3. Providing your personal data to others</Typography>
                    <Typography className={classes.legalPara}>
                    3.1 We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy.<br/><br/>
                    3.2 We may disclose your personal data to our suppliers or subcontractors insofar as reasonably necessary for fulfilling a request or servicing your account.<br/><br/>
                    3.3 Financial transactions relating to advertising on site are handled by our payment services providers(Paystack Payment Limited). We will share transaction data with our payment services providers only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds. You can find information about the payment services providers’ privacy policies and practices at https://paystack.com/terms<br/><br/>
                    3.4 In addition to the specific disclosures of personal data set out in this Section 3, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.[ We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.]<br/><br/>

                    </Typography>

                    <Typography className={classes.legalParaHeading}>4. International transfers of your personal data</Typography>
                    <Typography className={classes.legalPara}>
                    4.1 In this Section 4, we provide information about the circumstances in which your personal data may be transferred to countries outside the European Economic Area (EEA).<br/><br/>
                    4.2 The hosting facilities for our website are situated in EU Ireland, personal data is only shared outside the EEA for the purposes of operating the website, and carrying out transactions through our banking partners (if necessary).<br/><br/>
                    4.3 You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.<br/><br/>

                    </Typography>
                    <Typography className={classes.legalParaHeading}>5. Retaining and deleting personal data</Typography>
                    <Typography className={classes.legalPara}>
                    5.1 This Section 5 sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.<br/><br/>
                    5.2 Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.<br/><br/>
                    5.3 We will retain your personal data as follows:<br/><br/>
                    (a) Personal Information may be retained for up to 10 years from the last time you log in to the website<br/><br/>
                    (b) If you continue to use our website, we will continue to hold onto your personal data indefinitely to facilitate our services to you. We may review some of this data periodically and remove anything superfluous to the smooth operation of the website or services you have opted in to receive<br/><br/>
                    5.4 Notwithstanding the other provisions of this Section 5, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.<br/><br/>
                    5.5 You can delete your account at any time and lose access to your consultations, lab test orders, treatment plan orders, health screening orders, stored health records and wallet amounts. Simply navigate to the delete account option on the telemedicine app and delete your account. Once deleted we will flag your account as deleted but we can maintain it, so you will not be able to log in again and re-register if you choose to return.<br/><br/>

                    </Typography>
                    <Typography className={classes.legalParaHeading}>6. Amendments</Typography>
                    <Typography className={classes.legalPara}>
                    6.1 We may update this policy from time to time by publishing a new version on our website.<br/><br/>
                    6.2 You should check this page occasionally to ensure you are happy with any changes to this policy.<br/><br/>
                    6.3 We will notify you of significant changes to this policy by email.<br/><br/>

                    </Typography>
                    <Typography className={classes.legalParaHeading}>7. Your rights</Typography>
                    <Typography className={classes.legalPara}>
                    7.1 In this Section 7, we have summarised the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights.<br/><br/>
                    7.2 Your principal rights under data protection law are:<br/><br/>
                    (a) the right to access;<br/>
                    (b) the right to rectification;<br/>
                    (c) the right to erasure;<br/>
                    (d) the right to restrict processing;<br/>
                    (e) the right to object to processing;<br/>
                    (f) the right to data portability;<br/>
                    (g) the right to complain to a supervisory authority; and<br/>
                    (h) the right to withdraw consent.<br/><br/>
                    7.3 You have the right to confirmation as to whether or not we process your personal data and, where we do, access to the personal data, together with certain additional information. That additional information includes details of the purposes of the processing, the categories of personal data concerned and the recipients of the personal data. Providing the rights and freedoms of others are not affected, we will supply to you a copy of your personal data. The first copy will be provided free of charge, but additional copies may be subject to a fee. You can request access to your personal information by contacting us using our contact form<br/><br/>
                    7.4 You have the right to have any inaccurate personal data about you rectified and, taking into account the purposes of the processing, to have any incomplete personal data about you completed. All information we store about your account will have been a result of the information provided by yourself, so you can update this yourself by visiting your My Account section.<br/><br/>
                    7.5 In some circumstances you have the right to the erasure of your personal data without undue delay. Those circumstances include: the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions include where processing is necessary: for exercising the right of freedom of expression and information; for compliance with a legal obligation; or for the establishment, exercise or defence of legal claims.<br/><br/>
                    7.6 In some circumstances you have the right to restrict the processing of your personal data. Those circumstances are: you contest the accuracy of the personal data; processing is unlawful but you oppose erasure; we no longer need the personal data for the purposes of our processing, but you require personal data for the establishment, exercise or defence of legal claims; and you have objected to processing, pending the verification of that objection. Where processing has been restricted on this basis, we may continue to store your personal data. However, we will only otherwise process it: with your consent; for the establishment, exercise or defence of legal claims; for the protection of the rights of another natural or legal person; or for reasons of important public interest.<br/><br/>
                    7.7 You have the right to object to our processing of your personal data on grounds relating to your particular situation, but only to the extent that the legal basis for the processing is that the processing is necessary for: the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or the purposes of the legitimate interests pursued by us or by a third party. If you make such an objection, we will cease to process the personal information unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defence of legal claims.<br/><br/>
                    7.8 You have the right to object to our processing of your personal data for direct marketing purposes (including profiling for direct marketing purposes). If you make such an objection, we will cease to process your personal data for this purpose.<br/><br/>
                    7.9 You have the right to object to our processing of your personal data for scientific or historical research purposes or statistical purposes on grounds relating to your particular situation unless the processing is necessary for the performance of a task carried out for reasons of public interest.<br/><br/>
                    7.10 To the extent that the legal basis for our processing of your personal data is:<br/><br/>
                    (a) consent; or<br/>
                    (b) that the processing is necessary for the performance of a contract to which you are party or in order to take steps at your request prior to entering into a contract,
                    and such processing is carried out by automated means, you have the right to receive your personal data from us in a structured, commonly used and machine-readable format. However, this right does not apply where it would adversely affect the rights and freedoms of others.<br/><br/>
                    7.11 If you consider that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection. <br/><br/>
                    7.12 To the extent that the legal basis for our processing of your personal information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.<br/><br/>
                    7.13 You may exercise any of your rights in relation to your personal data by written notice to us, in addition to the other methods specified in this Section 7.<br/><br/>

                    </Typography>
                    <Typography className={classes.legalParaHeading}>8. About cookies</Typography>
                    <Typography className={classes.legalPara}>
                    {/* You have the right to request, once a calendar year, free information about what personal information about you we possess and how we process it. The application must be in writing, signed by you and sent to Doctall.<br/><br/>
                    You have the right, at any time, to object to the processing of personal data relating to processing based on our legitimate interest. These can be marketing activities, e.g. our newsletters with information about Doctall's services. The objection is to be sent to Doctall in writing, or you can deactivate this marketing to you via your profile when you log into the Service.<br/><br/>
                    You also have the right to request corrections in respect of incorrect personal data. <br/><br/>
                    If you have any complaints regarding Doctall's processing of your personal data, you have the right to file a complaint with us at  <a href="mailto:support@doctall.com"><span className={classes.legalPara}>support@doctall.com. </span></a> */}
                    8.1 A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.<br/><br/>
                    8.2 Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.<br/><br/>
                    8.3 Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.<br/><br/>

                  </Typography>
                  <Typography className={classes.legalParaHeading}>9. Cookies that we use</Typography>
                    <Typography className={classes.legalPara}>
                    9.1 We use cookies for the following purposes:<br/><br/>
                    (a) authentication – we use cookies to identify you when you visit our website and as you navigate our website;<br/>
                    (b) status – we use cookies [to help us to determine if you are logged into our website;<br/>
                    (c) personalisation – we use cookies to store information about your preferences and to personalise the website for you<br/>
                    (d) security – we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally<br/>
                    (e) advertising – we use cookies [to help us to display advertisements that will be relevant to you<br/>
                    (f) analysis – we use cookies [to help us to analyse the use and performance of our website and services; and<br/>
                    (g) cookie consent – we use cookies to store your preferences in relation to the use of cookies more generally<br/><br/>


                    </Typography>
                    <Typography className={classes.legalParaHeading}>10. Cookies used by our service providers</Typography>
                    <Typography className={classes.legalPara}>
                    10.1 Our service providers use cookies and those cookies may be stored on your computer when you visit our website.<br/><br/>
                    10.2 We use Google Analytics to analyse the use of our website. Google Analytics gathers information about website use by means of cookies. The information gathered relating to our website is used to create reports about the use of our website. Google’s privacy policy is available at: https://www.google.com/policies/privacy/.<br/><br/>
                    10.3 We may publish Google AdSense interest-based advertisements on our website. These are tailored by Google to reflect your interests. To determine your interests, Google will track your behaviour on our website and on other websites across the web using cookies. You can view, delete or add interest categories associated with your browser by visiting: https://adssettings.google.com. You can also opt out of the AdSense partner network cookie using those settings or using the Network Advertising Initiative’s multi-cookie opt-out mechanism at: http://optout.networkadvertising.org. However, these opt-out mechanisms themselves use cookies, and if you clear the cookies from your browser your opt-out will not be maintained. To ensure that an opt-out is maintained in respect of a particular browser, you may wish to consider using the Google browser plug-ins available at: https://support.google.com/ads/answer/7395996.<br/><br/>

                    </Typography>
                    <Typography className={classes.legalParaHeading}>11. Managing cookies</Typography>
                    <Typography className={classes.legalPara}>
                    11.1 Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:<br/><br/>
                    (a)<a href="/" ><span className={classes.legalPara} style={{color:"blue"}}>https://support.google.com/chrome/answer/95647?hl=en (Chrome);</span></a> <br/><br/>
                    (b) <a href="/" ><span className={classes.legalPara} style={{color:"blue"}}>https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox);</span></a><br/><br/>
                    (c) <a href="/" ><span className={classes.legalPara} style={{color:"blue"}}>http://www.opera.com/help/tutorials/security/cookies/ (Opera);</span></a><br/><br/>
                    (d) <a href="/" ><span className={classes.legalPara} style={{color:"blue"}}>https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies (Internet Explorer);</span></a> <br/><br/>
                    (e) <a href="/" ><span className={classes.legalPara} style={{color:"blue"}}>https://support.apple.com/kb/PH21411 (Safari); and</span></a><br/><br/>
                    (f) <a href="/" ><span className={classes.legalPara} style={{color:"blue"}}>https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge).</span></a><br/><br/>
                    11.2 Blocking all cookies will have a negative impact upon the usability of many websites.<br/><br/>
                    11.3 If you block cookies, you will not be able to use all the features on our website.<br/><br/>
                    </Typography>
                    <Typography className={classes.legalParaHeading}>12. Our details</Typography>
                    <Typography className={classes.legalPara}>
                    12.1 This website is owned and operated by DigiCare Hospital & Allied Services Ltd<br/><br/>
                    12.2 We are registered in Nigeria under registration number RC 1653433, and our registered office is in Abuja.<br/><br/>
                    12.3 Our principal place of business is at MELITA PLAZA, SUITE F1, AREA 11 GARKI, ABUJA FCT<br/><br/>
                    12.4 You can contact us:<br/><br/>
                    (a) by post, to the postal address given above; or<br/>
                    (b) using our website contact form;<br/><br/>

                    </Typography>
                    <Typography className={classes.legalParaHeading}>13. Data protection officer</Typography>
                    <Typography className={classes.legalPara}>
                    13.1 Our data protection officer’s contact details are:<br/><br/>
                    Name: Sharmila Yesukumar<br/><br/>
                    Email address: <a href="/"><span className={classes.legalPara} style={{color:"blue"}}>smy@doctall.com</span></a><br/><br/>
                    Contact number:  +2349010996355<br/><br/>
                  </Typography>

                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

export default PrivacyAndPolicy;