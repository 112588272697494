const footerStyle = (theme) => ({
  footerContainer: {
    width: "100%",
    background: "#005F71",
    height: "auto",
    position: "relative",
    bottom: "0",
    left: "0",
    paddingLeft: "58px",
    paddingRight: "58px",
    paddingBottom: "58px",
    paddingTop: "117px",
    color: "white",
    "@global": {
      a: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        textDecoration: "initial",
        color: "#FFFFFF",
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
  },
  InputMail: {
    width: "90%",
    background: "#F2F8F9",
    borderRadius: "5px",
    order: "0",
    padding: "16px 16px",
    height: "50px",
    fontFamily: "Visuelt-Regular",
    [theme.breakpoints.up("sm")]: {
      width: "100% !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "100% !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100% !important",
    },
  },
  MailBtn: {
    maxWidth: "178px",
    padding: "14px 27px",
    minHeight: "51px",
    background: "#FFAD05",
    borderRadius: "5px",
    border: "none",
    color: "#fff",
    fontWeight: "600",
    fontFamily: "Visuelt-Medium",
    [theme.breakpoints.up("sm")]: {
      width: "100% !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "100% !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100% !important",
    },
  },
  title: {
    fontFamily: "visuelt-regular",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "28px",
    textTransform: "uppercase",
    color: "#92D5E6",
    paddingBottom: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  group: {
    display: "flex",
    justifyContent: "center",
    gap: "5px",
    width: "100px",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      width: "45px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "45px !important",
    },
  },
  hideDiv: {
    position: "absolute",
    background: "white",
    top: "92%",
    padding: "2px 30px",
    borderRadius: "5px",
    cursor: "pointer",
    boxShadow: "0px 0px 1px 0px black",
    right: "8px",
    zIndex: 999,
    [theme.breakpoints.up("md")]: {
      right: "0 !important",
      top: "135% !important",
    },
  },
  hideDivElements: {
    marginTop: "3px",
    marginBottom: "1px",
    padding: "5px",
    color: "#15408D",
  },

  socialIcon: {
    padding: "0px",
  },
  downloadLink: {},
});

export default footerStyle;
