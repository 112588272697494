import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  Card,
} from "@material-ui/core";
import usePagination from "../../components/Landing/components/usePagination";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./DIseaseWithQues/styles";
import previousButton from "../../assets/images/previousButton.svg";
import nextButton from "../../assets/images/nextButton.svg";
import viewProfile from "../../assets/images/viewProfile.svg";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/actions";
import {
  _redirect,
  _redirectToDoctorProfile,
} from "../../components/Redirect/Redirect";
import DoctorPopup from "../../components/popup/DoctorPopup";
import { Dialog } from "@material-ui/core";
import { getSpeciality } from "./utils/diseasesIds";
import TimeSloteSelector from "../../components/ui/TimeSloteSelector";
import { doEncryption } from "../../components/common/utils";
import { showNotification } from "../../components/common-notification/CommonNotification";
import { useNavigate } from "react-router-dom";
import CallBackPopUp from "../callbackPopUp/CallBackPopUP";
import defaultProfile from "../../assets/images1/download.jfif";
import '../diseases/Banner.css';

const useStyles = makeStyles((theme) => styles(theme));
const PreviousButton = () => <img src={previousButton} alt="previous" />;
const NextButton = () => <img src={nextButton} alt="next" />;

const SpecialistDoctor = ({ doctors, per_page_count, diseaseName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { doctorsActions } = actions;
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");


  const _specialistDoctors = useSelector((state) => state.doctorsReducer);
  const _userInfo = useSelector((state) => state.authReducer);

  let [page, setPage] = useState(1);
  const [doctorInfo, setDoctorInfo] = useState();
  const [timeSloteInfo, setTimeSloteInfo] = useState();
  const [experience, setExperience] = useState();
  const [textdecoration1, setTextDecoration] = React.useState(false);
  const [callBackModel, setCallBackModel] = useState(false);

  const PER_PAGE = (per_page_count && per_page_count) || 5;

  useEffect(() => {
    //pagination not supported by backend as of now..
    let payLoad = getSpeciality(diseaseName);
    dispatch(doctorsActions.getSpecialistDoctors(payLoad, ""));
  }, []);

  const _DATA = usePagination(
    _specialistDoctors &&
      _specialistDoctors.doctorsData &&
      _specialistDoctors.doctorsData &&
      _specialistDoctors.doctorsData.length > 0 &&
      _specialistDoctors.doctorsData,
    PER_PAGE
  );

  let total =
    _specialistDoctors &&
    _specialistDoctors.doctorsData &&
    _specialistDoctors.doctorsData.total &&
    _specialistDoctors.doctorsData.total.length > 0 &&
    _specialistDoctors.doctorsData.total[0].Total;

  const totalCount = Math.ceil(total / PER_PAGE);

  // const DoctorExperience = () =>{
  //   let PresentYear = new Date()
  //   let practice_started_year = int()
  //   let Experience = math.floor( (PresentYear - practice_started_year)/(24*3600*1000))
  //   // return Experience
  // }
  const d = new Date();
  let year = d.getFullYear();
  // let Experience =  year - {doctor.profile.practice_started_year}

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handlePrevious = (p) => {
    let previousPage = p - 1;
    setPage(previousPage);
    _DATA.jump(previousPage);
  };

  const handleNext = (p) => {
    let nextPage = p + 1;
    setPage(nextPage);
    _DATA.jump(nextPage);
  };
  const [isDailogOpen, setIsDailogOpen] = useState(false);
  const DailogOpen = (data) => {
    setDoctorInfo(data);
    setIsDailogOpen(true);
  };

  const DailogClose = () => {
    setIsDailogOpen(false);
  };

  const _onBookDoctor = async (doctorInfo) => {
    try {
      if (_userInfo && _userInfo.userInfo) {
        let response = await doEncryption([
          {
            doctorId: doctorInfo.uuid,
            timeSloteInfo: timeSloteInfo,
            userName: _userInfo.userInfo.username,
            password: _userInfo.userInfo.savedPassword,
          },
        ]);
        let targetUrl =
          process.env.REACT_APP_CUNSUMER_SITE_URL + "/book-doctor/" + response;
        // let targetUrl = "http://localhost:3001" + "/book-doctor/" + response
        window.open(targetUrl, "_blank");
      } else {
        showNotification(
          "error",
          "Failed !!",
          "Please login to proceed with book doctor"
        );
        let _autoLoginPayLoad = {
          doctorId: doctorInfo.uuid,
          timeSloteInfo: timeSloteInfo,
          userName: "NONE",
          password: "NONE",
        };
        localStorage.setItem(
          "BOOK-DOCTOR-PAYLOAD",
          JSON.stringify(_autoLoginPayLoad)
        );
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CallBackPopUp
        open={callBackModel}
        close={() => setCallBackModel(!callBackModel)}
      />
      <Dialog
        style={{ borderRadius: "0px" }}
        maxWidth={"sm"}
        fullWidth={true}
        open={isDailogOpen}
        scroll={"body"}
        onClose={DailogClose}
      >
        <DoctorPopup
          doctorInfo={doctorInfo}
          onClose={() => setIsDailogOpen(false)}
        />
      </Dialog>
      <div >
       <Grid  container direction="column" lg={12} md={6} xl={4}>
        <Grid item container direction="row">
          {_DATA &&
            _DATA.currentData() &&
            _DATA.currentData().map((doctor) => (
              <Grid
                Grid
                item
                
                style={{
                  marginBottom: "28px",
                  marginLeft: matchesSM ? "0px" : "12px",
                  marginRight: matchesSM ? "0px" : "12px",
                  width: matchesXS ? "288px" : "380px",
                  
                }}
              >
                <Card
                  elevation={false}
                  classes={{ root: classes.specialistDoctor }}
                  justifyContent="center"
                >
                  <Grid item container direction="column" alignItems="center">
                    <img
                      style={{
                        height: "158px",
                        width: "158px",
                        borderRadius: "50%",
                        marginBottom: "16px",
                      }}
                      src={
                        doctor.profile_pic === " "
                          ? defaultProfile
                          : doctor.profile_pic
                      }
                      alt="doctor"
                    />
                    <Typography
                      style={{
                        fontFamily: "visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "30px",
                        color: "#101828",
                      }}
                    >
                      {doctor.full_name}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#667085",
                      }}
                    >
                      {doctor.profile && doctor.profile.education
                        ? doctor.profile.education
                        : "NA"}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "30px",
                        color: "#FB6514",
                        paddingTop: "8px",
                        paddingBottom: "10px",
                      }}
                      align="center"
                    >
                      {doctor.profile &&
                        doctor.profile.speciality &&
                        doctor.profile.speciality.length > 0 &&
                        doctor.profile.speciality.toString()}
                    </Typography>
                  </Grid>
                  <hr
                    style={{
                      width: "352px",
                      border: "1px solid #E4E7EC",
                      marginBottom: "30px",
                    }}
                  />
                  <Grid item container direction="column">
                    <Grid item container direction="row">
                      <Typography
                        style={{
                          fontFamily: "visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#667085",
                          width: "40%",
                        }}
                      >
                        Location:&nbsp;
                      </Typography>
                      <hr
                        style={{
                          height: "25px",
                          width: "0px",
                          border: "1px solid #D0D5DD",
                          margin: "0px 20px",
                          marginBottom: "10px",
                        }}
                      />{" "}
                      <Typography
                        style={{
                          fontFamily: "visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#667085",
                          width: "40%",
                        }}
                        noWrap={false}
                      >
                        {" "}
                        {doctor.profile && doctor.profile.address
                          ? doctor.profile.address.city
                          : "NA"}
                        {", "}{" "}
                        {doctor.profile && doctor.profile.address
                          ? doctor.profile.address.country
                          : "NA"}
                      </Typography>
                    </Grid>
                    <Grid item container direction="row">
                      <Typography
                        style={{
                          fontFamily: "visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#667085",
                          width: "40%",
                        }}
                      >
                        {console.log(
                          year - doctor.profile.practice_started_year,
                          year,
                          doctor.profile.practice_started_year,
                          "doctor.profile.practice_started_year"
                        )}
                        Experience:
                      </Typography>
                      <hr
                        style={{
                          height: "25px",
                          width: "0px",
                          border: "1px solid #D0D5DD",
                          margin: "0px 20px",
                          marginBottom: "10px",
                        }}
                      />{" "}
                      <Typography
                        style={{
                          fontFamily: "visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#667085",
                          width: "40%",
                        }}
                      >
                        {year - doctor.profile.practice_started_year} Years
                      </Typography>
                    </Grid>
                    <Grid item container direction="row">
                      <Typography
                        style={{
                          fontFamily: "visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#667085",
                          width: "40%",
                        }}
                      >
                        Language Spoken:
                      </Typography>
                      <hr
                        style={{
                          height: "25px",
                          width: "0px",
                          border: "1px solid #D0D5DD",
                          margin: "0px 20px",
                          marginBottom: "10px",
                        }}
                      />
                      {[]}
                      <Typography
                        style={{
                          fontFamily: "visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#667085",
                          width: "150px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                        noWrap={true}
                      >
                        {doctor.profile.languages &&
                          doctor.profile.languages.length > 0 &&
                          doctor.profile.languages.map((item) => (
                            <p>{[item]} </p>
                          ))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    style={{ marginTop: "30px", marginBottom: "30px", justifyContent:matchesSM?"center":undefined }}
                    
                  >
                    <Typography
                      style={{
                        fontFamily: "visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#002931",
                        paddingBottom: "8px",
                      }}
                    >
                      Earliest Available Time
                    </Typography>
                    <Grid
                      item
                      container
                      style={{
                        width: matchesXS ? "300px" : "352px",
                        height: "57px",
                        background: " #F9FAFB",
                        padding: "16px",
                        justifyContent:matchesSM?"center":undefined
                      }}
                      alignItems="center"
                    >
                      <TimeSloteSelector
                        timeSlotes={doctor.slots}
                        selectedSlotes={(event) => setTimeSloteInfo(event)}
                      />
                    </Grid>
                  </Grid>
                  <Grid align="center">           
                  <Button
                    variant="outlined"
                    style={{
                      border: "1px solid #D0D5DD",
                      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                      borderRadius: "8px",
                      width: matchesXS ? "255px" : "352px",
                      height: "48px",
                      marginBottom: "15px",
                      justifyContent:matchesSM?"center":undefined
                    }}
                    onClick={() => DailogOpen(doctor)}
                  >
                    <img
                      src={viewProfile}
                      alt=""
                      style={{ paddingRight: "9px" }}
                    />
                    
                    <Typography
                      style={{
                        fontFamily: "visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#344054",
                      }}
                    >
                      View Doctor’s profile
                    </Typography>
                  </Button>
                  {}
                  <Button
                    disabled={
                      !(doctor && doctor.slots && doctor.slots.length > 0)
                    }
                    variant="contained"
                    className={classes.bookDoctor}
                    // () => _onBookDoctor(doctor)
                    // () => _redirect("CONSUMER")
                    onClick={() => _onBookDoctor(doctor)}
                  >
                    Book Doctor
                  </Button>
                  </Grid>
                  <Grid align="center">   
                  <Typography
                    style={{
                      fontFamily: "visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    onClick={() => setCallBackModel(!callBackModel)}
                    onMouseOver={() => setTextDecoration(true)}
                    onMouseOut={() => setTextDecoration(false)}
                    // style={{color: "black"}}
                    className={
                      textdecoration1 === true ? "decorate" : "nondecorate"
                    }
                  >
                    {" "}
                    Can't find a convenient slot?
                    <br /> Please contact us to book this doctor click here.{" "}
                  </Typography>
                  </Grid>
                </Card>
              </Grid>
            ))}
        </Grid>
        <hr
          style={{
            height: "1px",
            border: "none",
            background: "#E4E7EC",
            maxWidth: "1247px",
            marginBottom: "20px",
          }}
        />
      </Grid>
      </div>
    </>
  );
};

export default SpecialistDoctor;
