import DateFnsUtils from '@date-io/date-fns';
import { Dialog, Grid, Input, makeStyles, Typography } from "@material-ui/core";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css';
import { useNavigate } from 'react-router-dom';
import CallAppointment from "../../assets/images/Call.png";
import { showNotification } from '../../components/common-notification/CommonNotification';
import styles from "../../pages/Questionnaire/styles";
import { getCountryCodes } from '../../services/doctall-living/staticServices';
import { requestCallBack } from '../../services/order-services/orderServices';
import "../Auth/auth.scss";

import { useDispatch, useSelector } from "react-redux";
import callbackSuccess from "../../assets/images/callbackSuccess.svg";

const useStyles = makeStyles((theme) => styles(theme));


export default function CallBackPopUp(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();

    const _userInfo = useSelector((state) => state.authReducer);

    const { open, close } = props;
    const [time, changeTime] = useState();
    const [date, changeDate] = useState(new Date());
    const [country, setCountry] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [reserror, setReserror] = useState(false);
    const [fname, setFname] = useState("");
    const [countryDialCode, setCountryDialCode] = useState();
    const [successFlag, setSuccessFlag] = useState(false);
   
    

    const onChangeFname = e => {
        const input = e.currentTarget.value;
        if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
            setFname(input);
        }
    };
    const [timeError, setTimeError] = useState(false);

    const errorstyle = {
        border: reserror ? "1px solid #E04E47" : "1px solid #ADC2C2 ",
        outline: "none !important",
        borderRadius: "5px",
        marginBottom: "20px",
        marginTop: "10px",

    };

    useEffect(() => {
        getCountryCode()
        changeDate(new Date())
        setTimeError(false)
    }, []);



    useEffect(() => {
        if (_userInfo && _userInfo.userInfo) {
            setPhoneNumber(_userInfo.userInfo.mobile);
            setFname(_userInfo.userInfo.full_name)
        }

    }, [_userInfo, open]);

    const getCountryCode = async () => {
        try {
            let response = await getCountryCodes()
            if (response) {
                setCountry(response.data.country_code.toLowerCase());
            }
        } catch (error) {
            console.log(error)
        }
    }


    const _onPhoneChange = (value, country, e, formattedValue) => {
        setPhoneNumber(value)
        setCountryDialCode(country.dialCode)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let { fullName, email, message } = event.target.elements;
        if (timeError === false) {
            try {
                let payLoad = {
                    "full_name": fullName.value,
                    "mobile": phoneNumber,
                    "email": email.value,
                    "message": message.value,
                    "time": moment(date).format('LT'),
                    "date": moment(date).format('l')
                }
                if (countryDialCode === "234" && phoneNumber.length < 13) {
                    showNotification('error', "error !", "Please enter valid mobile number")
                    return false;
                }
                if (countryDialCode === "91" && phoneNumber.length < 12) {
                    showNotification('error', "error !", "Please enter valid mobile number")
                    return false;
                }

                if (phoneNumber.length < 10) {
                    showNotification('error', "error !", "please enter valid phone number")
                    return false;
                }

                let response = await requestCallBack(payLoad)
                if (response) {
                   setSuccessFlag(true)
                }

            } catch (error) {
                if (error && error.response) {
                 showNotification('error', "Failed !!", "" + error.response.data)
                }
            }
        }
    }


    const _onSelectTime = (time) => {

        let currentDateTime = moment().format('LLL');
        let formatedTime = moment(time).format('LLL');
        changeDate(time)
        var isafter = moment(formatedTime).isAfter(currentDateTime);
        if (isafter === false) {
            setTimeError(true)
        } else {
            setTimeError(false)
        }

    }


    const onNavigate = ()=>{
        if(window.location.pathname.endsWith("questionnaire")){
            close()
            navigate(-1)
        }else{
            close()
        }
        
    }



    return (
        <>
            <div>
                <Dialog
                    open={open}
                    onClose={() => { setTimeError(false); changeDate(new Date()); setPhoneNumber(''); close(); }}
                    fullWidth
                    fullHeight
                    onExited={() => { setTimeError(false); changeDate(new Date()); setPhoneNumber(''); }}
                    maxWidth={successFlag === false ? 'md' : 'sm'}
                    style={{ zIndex: 6, height:"100%" }}
                    
                >

                {successFlag === false ? <Grid container className={classes.callbackpopupDialog}>
                    <Grid sm={12} md={6} xs={12} align="center" className='callbackPopImg'>
                        <img src={CallAppointment} alt="callback pop up image" style={{ width: "100%", height: '100%', overflow: "auto"}} />
                    </Grid>
                    <Grid sm={12} md={6} xs={12} align="left" >


                        <Grid container>
                            <Grid sm={10} md={11} xs={11} align="left">
                                <Typography style={{ color: "#002931", fontSize: "24px", padding: "15px" }}>Request a Callback</Typography>
                            </Grid>
                            <Grid sm={1} md={1} xs={1} align="right" >

                                <div
                                    style={{ cursor: "pointer" }}
                                    className="labmodal_close"
                                    onClick={() => close()}
                                >
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 15L15 1M1 1L15 15L1 1Z"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>

                            </Grid>

                        </Grid>
                        <div style={{ padding: "0px 20px 20px 20px" }}>
                            <form onSubmit={handleSubmit} style={{ paddingTop: "20px" }} >

                                <label
                                    htmlFor="fname"
                                    className='label'>
                                    Full name
                                </label>

                                <Input
                                    onChange={onChangeFname}
                                    value={fname}
                                    type="text"
                                    id="fname"
                                    name="fullName"
                                    required
                                    placeholder='Enter your name'
                                    className='input_box'
                                    disableUnderline={true}
                                    inputProps={{
                                        maxLength: 25,
                                    }}
                                />


                                <label
                                    htmlFor="fname"
                                    className='label'
                                    style={{ marginBottom: "10px" }}
                                >
                                    Phone Number

                                </label>

                                <PhoneInput
                                    style={errorstyle}
                                    className="inputPhone"
                                    country={country}
                                    value={phoneNumber}
                                    onChange={(value, country, e, formattedValue) => _onPhoneChange(value, country, e, formattedValue)}
                                    name='phone'

                                />

                                <label

                                    htmlFor="lname"
                                    className='label'>
                                    Email Address
                                </label>

                                <Input
                                    type="email"
                                    id="lname"
                                    name="email"
                                    placeholder='Enter your email address'
                                    className='input_box'
                                    required
                                    disableUnderline={true}
                                    defaultValue={_userInfo && _userInfo.userInfo ? _userInfo.userInfo.email : " "}
                                />

                                <label
                                    htmlFor="lname"
                                    className='label'>
                                    Message (optional)
                                </label>

                                <textarea
                                    style={{ width: '100%', marginTop: '2%', height: '50%' ,overflow: "auto" }}
                                    className={classes.inputFieldMultiline}
                                    type="text"
                                    id="lname"
                                    name="message"
                                    placeholder='Additional Message'
                                    row='2'
                                />

                                <label
                                    htmlFor="lname"
                                    className='label'>
                                    Preferred Date and time for call
                                </label>

                                <Grid container spacing={0} >
                                    <Grid sm={12} md={12} xs={12} align="left" style={{ marginTop: "15px" }}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker

                                                fullWidth

                                                style={{ marginBottom: "10px", border: "1px solid #D0D5DD", borderRadius: "8px", height: "44px", padding: "10px 14px",textDecoration: "none" }}
                                                disablePast
                                                value={date}
                                                format="yyyy/MM/dd   hh:mm a"
                                                onChange={(time) => _onSelectTime(time)}
                                                disableUnderline={true}
                                            />
                                            {timeError === true && (
                                                <span className="error" style={{ color: 'red', paddingTop: "10px" }}>{"Past Date And Time Not Allowed"}</span>
                                            )}
                                        </MuiPickersUtilsProvider>

                                    </Grid>
                                    <Grid sm={6} md={6} xs={6} align="left" >

                                    </Grid>
                                </Grid>
                                <Grid style={{ marginTop: "0px", }}>
                                    <button type="submit" className='newsletter_btn' style={{ cursor: 'pointer', marginTop: "5px", }}>Request for Callback</button>
                                </Grid>
                            </form>
                        </div>
                    </Grid>
                </Grid> : <Grid container style={{overflow:"auto"}} >
                          <Grid md={12} sm={12} xs={12} lg={12} align="center" style={{padding:"100px 70px"}}>
                            <img  src={callbackSuccess} alt="callback pop success icon"  />
                           
                            <Typography style={{fontSize:"24px", color:"#002931", fontWeight:"500", margin:"50px 20px 0px 20px"}}>Callback Request Sent</Typography>
                            <Typography style={{fontSize:"16px", color:"#667085", padding:"20px", lineHeight:"24px"}}>You’ve successfully requested for a callback with us. We will get in touch within 24 hours</Typography>
                            <button onClick={onNavigate} className='newsletter_btn' style={{ cursor: 'pointer', marginTop:"20px", }}>Done</button>
                            </Grid>
                        
                    </Grid>}
                </Dialog>
            </div>




            {/* success popup */}

            {/* <div>
                <Dialog
                    open={open}
                    onClose={() => { setTimeError(false); changeDate(new Date()); setPhoneNumber(''); close(); }}
                    fullWidth
                    onExited={() => { setTimeError(false); changeDate(new Date()); setPhoneNumber(''); }}
                    maxWidth="sm"
                    style={{ zIndex: 5 }}
                >

                    <Grid container style={{overflow:"hidden"}} >
                          <Grid md={12} sm={12} xs={12} lg={12} align="center" style={{padding:"100px 70px"}}>
                            <img  src={callbackSuccess} alt="callback pop success icon"  />
                           
                            <Typography style={{fontSize:"24px", color:"#002931", fontWeight:"500", margin:"50px 20px 0px 20px"}}>Callback Request Sent</Typography>
                            <Typography style={{fontSize:"16px", color:"#667085", padding:"20px", lineHeight:"24px"}}>You’ve successfully requested for a callback with us. We will get in touch within 24 hours</Typography>
                            <button onClick={() => close()} className='newsletter_btn' style={{ cursor: 'pointer', marginTop:"20px", }}>Done</button>
                            </Grid>
                        
                    </Grid>
                </Dialog>
            </div> */}






        </>

    );
}
