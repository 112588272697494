import axios from 'axios';
import { _FbLogin,_CHANGE_PASSCODE, _CHECK_UNIQUE_MAIL_PHONE, _GoogleLogin, _INFLUENCER_REGISTRATION, _LOGIN, _REGISTRATION, _RESET_PASSCODE, _VERIFY_OTP } from '../endpoints';


export const onLoginRequest = (payLoad) => {
    console.log(payLoad.response,"payLoad")
    if(payLoad.type == "loginwithcredential"){
        var payLoads = { "username" : payLoad.username, "password" : payLoad.password}
        return axios.post(_LOGIN, payLoads)
    }
    else if(payLoad.type == "loginwithgoogle"){
        var payLoads = { "tokenId" : payLoad.response.tokenId,}
        return axios.post(_GoogleLogin, payLoads)
    }
    else if(payLoad.type == "loginwithFb"){
        const payLoads = {
            "authToken" : payLoad.response.accessToken,
            "facebookUserId" : payLoad.response.userID
        }
        return axios.post(_FbLogin, payLoads)
    }

}


export const checkForUnique = async (payLoad) => {
    let response = await axios.post(_CHECK_UNIQUE_MAIL_PHONE, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to get code");
}


export const onRegistration = async (payLoad) => {
    let response = await axios.post(_REGISTRATION, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to get code");
}

export const onResetPasscode = async (payLoad) => {
    let response = await axios.post(_RESET_PASSCODE, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to get code");
}

export const onChangePasscode = async (payLoad) => {
    let response = await axios.post(_CHANGE_PASSCODE, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed");
}


export const onVerifyOTP = async (payLoad) => {
    let response = await axios.post(_VERIFY_OTP, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed");
}

export const getregistrationInfluencer = async (payload) => {
    console.log(payload , "payload")
    let response = await axios.post(_INFLUENCER_REGISTRATION, payload)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to get code");
}


