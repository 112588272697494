import {
    Checkbox, FormControl, FormControlLabel, Grid, makeStyles, Typography, useTheme
} from "@material-ui/core";
import React from 'react';
import { useNavigate } from "react-router-dom";
import styles from "../../pages/diseases/DIseaseWithQues/styles";


const useStyles = makeStyles((theme) => styles(theme));


export default function TabletsView({ title, _data, type, isFullPackage }) {


    let navigate = useNavigate();
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            <Typography className={classes.planInfoFormTitle}>
                {title}
            </Typography>

            <FormControl
                style={{
                    display: "flex",
                    width: "100%",
                    marginBottom: "25px",
                    marginTop: "8px",
                    flexDirection: "row",
                }}
            >
                <Grid item xs >
                    <FormControlLabel
                        style={{
                            borderColor: "#004451",
                            backgroundColor: "#D9EDF1",
                        }
                        }
                        classes={{
                            root: classes.optionSelect,
                            label: classes.optionSelectLabel,
                        }}
                        value={"dosage"}
                        control={<Checkbox style={{ display: "none" }} />}
                        label={
                            isFullPackage === false ? <span>
                                {type === "DOSAGE" ? _data.doses : title}
                                <br />
                                <span
                                    style={{
                                        fontFamily: "Visuelt Pro",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                    }}
                                >
                                    {type === "DOSAGE" ? _data.mg + "mg" : _data.ml}
                                </span>
                            </span> : <span>
                                {_data.qty === "1" ? "Quantity" : _data.doses}
                                <br />
                                <span
                                    style={{
                                        fontFamily: "Visuelt Pro",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                    }}
                                >
                                    {_data.qty === "1" ? _data.ml : _data.mg + "  mg"}
                                </span>
                            </span>
                        }

                    />
                </Grid>

            </FormControl>


            {/* Tablets in package section */}

            {_data && _data.qty !== "1" && <>
       
                <Typography className={classes.planInfoFormTitle}>
                    Tablets in this package
                </Typography>

                <FormControl
                    style={{
                        display: "flex",
                        width: "100%",
                        marginBottom: "25px",
                        marginTop: "8px",
                        flexDirection: "row",
                    }}
                >
                    <Grid item xs >
                        <FormControlLabel
                            style={{
                                borderColor: "#004451",
                                backgroundColor: "#D9EDF1",
                            }
                            }
                            classes={{
                                root: classes.optionSelect,
                                label: classes.optionSelectLabel,
                            }}
                            value={"dosage"}
                            control={<Checkbox style={{ display: "none" }} />}
                            label={
                                <span>
                                    {_data.tabs}
                                    <br />
                                </span>
                            }
                        />
                    </Grid>

                </FormControl> </>}


        </>
    )
}






