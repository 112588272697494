import contactLooper from "../../../../assets/images/Looperr.png";
import PEMOB from "../../../../assets/images/PEMOB.png";
// import restaticpe from "../../../assets/images/restaticpe.png";


const CareersStyles = (theme) => ({
    contactHeader: {
        background: "#f2f8f9",
        paddingTop: "6.6rem",
        paddingLeft: "7.8rem",
        backgroundImage: `url(${contactLooper})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "110% 40%",
        height: "50.8rem",
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "3.4rem",
          height: "75rem",
        },
        [theme.breakpoints.down("xs")]: {
          backgroundImage: `url(${PEMOB})`,
          backgroundRepeat: "none",
          backgroundPosition: "30% 100%",
          paddingLeft: "1.6rem",
          paddingRight: "1.6rem",
          height: "70.4rem",
          paddingTop: "1.4rem",
        },
        [theme.breakpoints.down("md")]: {
        
        //   backgroundImage: `url(${restaticpe})`,
          backgroundRepeat: "none",
          backgroundPosition: "90% 100%",
          // paddingLeft: "1.6rem",
          // paddingRight: "1.6rem",
          // height: "70.4rem",
          // paddingTop: "1.4rem",
        },
      },
      careerBannerHeading: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#7F56D9",
        paddingBottom: "12px",
      },
    careerBannerTitle: {
        fontFamily: "Visuelt Pro",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "60px",
        lineHeight: "72px",
        letterSpacing: "-0.02em",
        color: "#002931",
        // textAlign: "center",
        [theme.breakpoints.down("sm")]: {
          fontSize: "42px",
          lineHeight: "50px",
        },
    
        [theme.breakpoints.down("xs")]: {
          fontSize: "36px",
          lineHeight: "44px",
        },
      },
      careerPara:{
        fontFamily:"Visuelt Pro",
        fontStyle:"normal",
        fontSize: "20px",
        lineHeight:"30px",
        width:"630px",
        height: "90px",
        fontWeight: "400",
        color: "#002931",
        marginTop:"1%",
        marginBottom:"50px",
        // marginBottom:"4%",
        [theme.breakpoints.down("sm")]: {
          lineHeight: "30px",
          width:"98%",
         top: "50px",
        },
    
        [theme.breakpoints.down("xs")]: {
          width:"100%",
          // marginTop: "-10px",
        },
        [theme.breakpoints.down("md")]: {
          width:"360px",
          
        },
      
    },
    testimonial:{
        width: "649px",
        height: "96px",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "32px",
        color: "#00839B",
        fontFamily: "Visuelt Pro",
        fontStyle: "normal",
        [theme.breakpoints.down("xs")]: {
          width:"300px",
          marginTop: "10px",
         
        },
        [theme.breakpoints.down("md")]: {
          width:"360px",
          marginTop: "10px",
          
        },
        [theme.breakpoints.down("sm")]: {
          // lineHeight: "30px",
         
        marginTop: "10px",
        },
  
    },
    star:{
        width: "17px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "30px",
          // display: "none",
        },
        [theme.breakpoints.down("md")]: {
          // display: "none",
          marginTop: "40px",
        },
        [theme.breakpoints.down("sm")]: {
          // display: "none",
          // marginBottom: "30px",
        },
    
      },
      doctall:{
        width: "107px",
        height: "18px",
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "18px",
        color: "#004451",
        fontFamily: "Visuelt Pro",
        fontStyle: "normal",
        [theme.breakpoints.down("xs")]: {
          marginTop: "10px",
        },
        [theme.breakpoints.down("md")]: {
         
          marginTop: "30px",
        },
      },
      careerContainer:{
        padding:"50px 100px",
        [theme.breakpoints.down("sm")]: {
            padding:"10px"
          },
          [theme.breakpoints.down("xs")]: {
            padding: "10px",
          },
        
        },
          careerHeading:{
            fontSize:"30px",
            color:"#002931",
            width: "791px",
            height: "76px",
            fontWeight:"500",
            lineHeight:" 38px",
            padding: "10px 10px 0px 10px",
            textAlign: "center",
            marginTop:"-20px",
            [theme.breakpoints.down("xs")]: {
              fontSize:"30px",
              width: "350px",
              textAlign: "center",
            },
            [theme.breakpoints.down("md")]: {
              // fontSize:"30px",
              width: "350px",
              textAlign: "center",
              marginTop: "-10px",
            },
        },
      img: {
        marginTop: "-25px",
       //  position: "absolute",
        backgroundPosition: "100% 40%",
        [theme.breakpoints.down("xs")]: {
         display: "none",
         backgroundImage: `url(${PEMOB})`
       },
       [theme.breakpoints.down("md")]: {
        display: "none",
        // backgroundImage: `url(${PEMOB})`
      },
       },
       careerImage:{
        width:"100%",
          [theme.breakpoints.down("sm")]: {
          display:"block",
            },
            [theme.breakpoints.down("xs")]: {
              display:"none",
            },
      },
      careerImageMob:{
        display:"none",
        [theme.breakpoints.down("sm")]: {
          display:"none",
            },
            [theme.breakpoints.down("xs")]: {
              display:"block",
            },
      
      },
      careerTextContainer:{
        padding:"20px 330px",
        [theme.breakpoints.down("sm")]: {
            padding:"10px"
          },
          [theme.breakpoints.down("xs")]: {
            padding: "10px",
          },
      },
      careerCard:{
      border:"1px solid #E4E7EC",
      shadows:"none",
      borderRadius:"16px",
      margin:"20px 0px"
      
      },
      cardHead:{
        fontSize:"20px",
        color:"#101828",
        fontWeight:"500",
      
      },
      cardHeading:{
        fontSize:"18px",
        color:"#101828",
        fontWeight:"500",
        padding:"20px 20px 10px 20px",
      },
      cardPara:{
        fontSize:"16px",
        color:" #667085",
        padding:"20px",
      },
      

})

export default CareersStyles;
