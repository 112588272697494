import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import EdCardlogo from '../../../assets/images/EdCardlogo.svg';
import cardLogo from '../../../assets/images/cardLogo.png';
import iconCard1 from '../../../assets/images/IconCard1.png';
import iconCard2 from '../../../assets/images/IconCard2.png';
import iconCard3 from '../../../assets/images/Card_icon3.png';
import Card_icon from '../../../assets/images/Card_icon.png';
import Call_icon from '../../../assets/images/Call_icon.png';
import chevronD from "../../../assets/images/chevronDownQ.svg";
import chevronU from "../../../assets/images/chevronUp.svg";
import ReactCountryFlag from "react-country-flag";
import EdCardheader from '../../../pages/Questionnaire/util/EdCardheader';
import EdCarddropdown from '../../../pages/Questionnaire/util/EdCarddropdown';
import prevQuestionIcon from "../../../assets/images/prevQuestion.svg";
import LinearProgressBar from "../../../components/ui/LinearProgress";
import ArrowUp from '../../../assets/images/ArrowUp.png';
import ArrowDown from '../../../assets/images/ArrowDown.png';
import './EdCard1.css';
import styles from './../../diseases/DIseaseWithQues/styles';
import '../styles';
import { makeStyles, useTheme } from "@material-ui/core";
import phoneIcon from "../../../assets/images/phone-icon.svg";
// import backgroundCardlogo from '../../../assets/images/cardLogo.png';
import dropdownCard from '../../../pages/Questionnaire/util/dropdownCard';
import { NavLink } from 'react-router-dom';
import DrugPopup from '../../../components/popup/DrugPopup';
import BrandInfo from "../../../components/treatment-plan/BrandInfo";
import CallBackPopUp from "../../../pages/callbackPopUp/CallBackPopUP";
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import { saveQuestionnaire } from "../../../services/category-services/categoryServices";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  CardContent,
  FormControl, InputLabel, MenuItem, Select, Card, IconButton,
} from "@material-ui/core";
import { showNotification } from "../../../components/common-notification/CommonNotification";

const EdCard = ({ handleStepChange, handleAmount, handleQuantity, handleDetailes }) => {
  const useStyles = makeStyles((theme) => styles(theme));
  const ExpandMoreIcon = () => <img src={chevronDownPlan} alt="expand" />;
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const custommatchesXL = useMediaQuery("(min-width:1500px)");
  const [callBackModel, setCallBackModel] = useState(false);

  const [value, setValue] = React.useState("");

  const [step, setStep] = useState(1);
  const prevQuestion = () => {
    setStep(step - 1);
  };

  const [drugModel, setDrugModel] = useState(false);
  const [drugModelData, setDrugModelData] = useState();
  const [drugModeldataChange, setDrugModeldataChange] = useState(false);



  // const history = useHistory();
  const navigate = useNavigate();
  const location = useLocation();


  // drugs={questions[2]}
  // stepUpdate={(step) => setStep(step)}
  // setPlanAmount={(amt) => setPlanAmount(amt)}
  // setPlanQuantity={(amt) => setPlanQuantity(amt)}
  // setTitle={(amt) => setTitle(amt)}
  // setSubtitle={(amt) => setSubtitle(amt)}
  // handleChangeDrug={(drug) => setData({ ...data, drug: drug })}
  // sav={data}
  // uuidata={(data) => setUuidDaata(data)}
  // disease_uuid={diseaseUUID}
  // disease_name={planDetails && planDetails.diseases_name}
  // uuidDaata={uuidDaata}
  // setPDetailes={(amt) => setPDetailes(amt)}
  // setSelectedPDetailes={(amt) => setSelectedPDetailes(amt)}

  const [planAmount, setPlanAmount] = useState()
  const [planQuantity, setPlanQuantity] = useState()
  const [title, setTitle] = useState()
  const [subTitle, setSubtitle] = useState()
  const [pDetailes, setPDetailes] = useState()
  const [selectedPDetailes, setSelectedPDetailes] = useState()


  const disease_uuid = location && location.state && location.state.diseaseUUID
  console.log(location && location.state && location.state.questionanswer,"disease_uuidduygsd")

  const EDCheckout = (data, index) => {
    console.log(index,"index")
    setPDetailes(data)
    if (selectedValue && selectedValue[index] && selectedValue[index].amount >= 1 && selectedValue && selectedValue[index] && selectedValue[index].quantity >= 1
         && selectedValue && selectedValue[index] && selectedValue[index].amount !== '00' && selectedValue && selectedValue[index] && selectedValue[index].quantity !== 'QTY') {
      navigate('/EdCheckout', {
        state: {
          planAmount: planAmount,
          planQuantity: planQuantity,
          title: title,
          subTitle: subTitle,
          pDetailes: data,
          selectedPDetailes: selectedPDetailes,
          data: location && location.state && location.state.questionanswer,
          diseaseUUID: disease_uuid,
          uuid: location && location.state && location.state.uuid,
          disease_name: location && location.state && location.state.diseaseName,
        }
      })
    }
    else {
      showNotification('error', "Failed", "Please Select Dosage")

    }

  }
const [hrs, setHrs] = useState(0)

  // const saveQuestions = async () => {
  //   try {
  //     var payLoad;

  //     payLoad = {
  //       uuid: location && location.state && location.state.uuid,
  //       disease_uuid: disease_uuid,
  //       disease_name: location && location.state && location.state.diseaseName,
  //       questionanswer: location && location.state && location.state.questionanswer,
  //     };

  //     let response = await saveQuestionnaire(payLoad);
  //     if ((response && response.status === 200) || response.status === 201) {

  //       addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   saveQuestions()
  // },[])
  const [planList, setPlanList] = useState([])
  const [drop, setDrop] = useState([])
  const [selectedValue, setSelectedValue] = useState([])
  // const [quantityss, setQuantity] = useState('Qty');
  // var planListdfds = [];

  var selectDrop = []
  var selectDrop1 = []
  const PlansList = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}doctallnew/treatmentplan/plan/list/${disease_uuid}`)
      .then((res) => {
        console.log(res.data, "kndhbks")
        setPlanList(res.data)

        {
          res.data.map((item) => {
            // item.treatment.map((itemwd) => {
            //   // console.log(itemwd,"whydfwytd")
            //   planListdfds.push(itemwd)
            //   setPlanList(planListdfds)
            //   console.log(planListdfds,"whydfwytd")
            // })
            selectDrop.push(false)
            setDrop(selectDrop)
            selectDrop1.push({ title: "Choose your dosages", subTitle: "", amount: "00", initAmount:'00', quantity: "Qty" })
            setSelectedValue(selectDrop1)
          })
        }
      })
      .catch((err) => {
        console.log(err, "kndhbks")
      })
  }

  const IncreaseDecrease = (type, dataIndex) => {
    if (type === "inc") {
      if (selectedValue[dataIndex].quantity === 'Qty') {
        // handleChangeQn(1)
        const indexToUpdate = selectedValue.findIndex((ds, index) => index === dataIndex);

        const updatedState = [...selectedValue];
        updatedState[indexToUpdate].quantity = 1
        updatedState[indexToUpdate].amount = 1 * Number(selectedValue && selectedValue[dataIndex] && selectedValue[dataIndex].initAmount).toFixed()
        console.log(updatedState, "dsbbsksad")
        setSelectedValue(updatedState)
        setPlanQuantity(1)
      }
      else {
        let quan = Number(selectedValue[dataIndex].quantity) + 1

        setPlanQuantity(quan)
        // handleChangeQn(Number(selectedValue[dataIndex].quantity) + 1)
        const indexToUpdate = selectedValue.findIndex((ds, index) => index === dataIndex);

        const updatedState = [...selectedValue];
        updatedState[indexToUpdate].quantity = quan
        updatedState[indexToUpdate].amount = quan * Number(selectedValue && selectedValue[dataIndex] && selectedValue[dataIndex].initAmount).toFixed()

        console.log(updatedState, "dsbbsksad")
        setSelectedValue(updatedState)
        // handleChangeQn(Number(selectedValue[dataIndex].quantity) + 1)
      }
    }
    else if (type === "dec") {
      if (selectedValue[dataIndex].quantity === 'Qty') {
        // handleChangeQn('Qty')
        const indexToUpdate = selectedValue.findIndex((ds, index) => index === dataIndex);

        const updatedState = [...selectedValue];
        updatedState[indexToUpdate].quantity = 'Qty'
        updatedState[indexToUpdate].amount = "00"
        console.log(updatedState, "dsbbsksad")
        setSelectedValue(updatedState)
      }
      else {
        let quan = Number(selectedValue[dataIndex].quantity) - 1

        if (selectedValue[dataIndex].quantity > 1) {
          // handleChangeQn(quan)
          setPlanQuantity(quan)
          const indexToUpdate = selectedValue.findIndex((ds, index) => index === dataIndex);

          const updatedState = [...selectedValue];
          updatedState[indexToUpdate].quantity = quan
          updatedState[indexToUpdate].amount = quan * Number(selectedValue && selectedValue[dataIndex] && selectedValue[dataIndex].initAmount).toFixed()

          console.log(updatedState, "dsbbsksad")
          setSelectedValue(updatedState)
        }
        else {
          // handleChangeQn(1)
          const indexToUpdate = selectedValue.findIndex((ds, index) => index === dataIndex);

          const updatedState = [...selectedValue];
          updatedState[indexToUpdate].quantity = 1
          updatedState[indexToUpdate].amount = 1 * Number(selectedValue && selectedValue[dataIndex] && selectedValue[dataIndex].initAmount).toFixed()

          console.log(updatedState, "dsbbsksad")
          setSelectedValue(updatedState)
          setPlanQuantity(1)
        }

      }
    }
  }

  console.log(selectedValue, "whydfwytd")
  useEffect(() => {
    PlansList()
  }, [])

  const [isActive1, setIsActive1] = useState(false);
  const [selected1, setIsSelected1] = useState("Choose your dosages");
  const [selected12, setIsSelected12] = useState("");


  console.log(selected1, "selected1")
  const [isActive2, setIsActive2] = useState(false);
  const [selected2, setIsSelected2] = useState("Choose your dosages");
  const [selected22, setIsSelected22] = useState("");
  const [isActive3, setIsActive3] = useState(false);
  const [selected3, setIsSelected3] = useState("Choose your dosages");
  const [selected33, setIsSelected33] = useState("");
  const [isActive4, setIsActive4] = useState(false);
  const [selected4, setIsSelected4] = useState("Choose your dosages");
  const [selected44, setIsSelected44] = useState("");
  const [isActive5, setIsActive5] = useState(false);
  const [selected5, setIsSelected5] = useState("Choose your dosages");
  const [isActive6, setIsActive6] = useState(false);
  const [selected6, setIsSelected6] = useState("Choose your dosages");

  const handleActive = (dataIndex) => {

    let val;
    if (drop[dataIndex] === true) {
      val = false
    }
    else {
      val = true
    }
    const indexToUpdate = drop.findIndex((ds, index) => index === dataIndex);

    console.log(indexToUpdate, "dsbbsksad")
    const updatedState = [...drop];
    // let complete = true;
    // setcomplete(true)
    updatedState[indexToUpdate] = val
    console.log(updatedState, "dsbbsksad")
    setDrop(updatedState)
  }

  const [medicineName, setMedicineName] = useState("")
  const [medicineQ, setMedicineQ] = useState("")
  console.log(medicineName, medicineQ, "medicineName")


  const medicine1 = [{
    name1: "Choose your dosages",

  },
  ]



  const medicine = [{
    name: "Choose your dosages",
    quantity: ""
  },
  {
    name: "Standard 50 mg",
    quantity: "4 tablets in this package"
  },
  {
    name: "High 100 mg",
    quantity: "1 tablet in this package"
  },
    // {
    //   name:"Standard 100 mg",
    //   quantity:"4 tablets in this package"
    // },

  ]


  const editEventHandler = (event, dataIndex) => {
    console.log(event, "event")
    setIsActive1(true)
    const doses = event.doses + " " + event.mg + " Mg"
    const tabs = event.tabs + " " + "tablet(s) in this package"
    const Price = event.price
    // handleDetailes(event)
    setDrugModeldataChange(true)
    setSelectedPDetailes(event)
    setDrugModelData(event)
    setPlanAmount(Price)
    setPlanQuantity(1)
    setTitle(doses)
    setSubtitle(tabs)
    setHrs(event.last_for.replaceAll('hrs',''))
    // setIsSelected1(doses)
    // setIsSelected12(tabs)
    const indexToUpdate = selectedValue.findIndex((ds, index) => index === dataIndex);

    const updatedState = [...selectedValue];
    // updatedState[indexToUpdate] = { title: doses, subTitle: tabs }
    updatedState[indexToUpdate].title = doses
    updatedState[indexToUpdate].subTitle = tabs
    updatedState[indexToUpdate].initAmount = Price
    updatedState[indexToUpdate].amount = Price
    updatedState[indexToUpdate].quantity = 1

    // selectedValue && selectedValue[index] && selectedValue[index].initAmount
    // handleChangeAm(Price);
    //     handleAmount(Price)
    // handleQuantity(1)

    console.log(updatedState, "dsbbsksad")
    setSelectedValue(updatedState)

  };
  const editEventHandler2 = event => {
    console.log(event, "event")
    setIsSelected2(event.name)
    setIsSelected22(event.quantity)

  };
  const editEventHandler3 = event => {
    console.log(event, "event")
    setIsSelected3(event.name)
    setIsSelected33(event.quantity)

  };
  const editEventHandler4 = event => {
    console.log(event, "event")
    setIsSelected4(event.name)
    setIsSelected44(event.quantity)

  };



  const DetailesOFCard = (data) => {
    console.log(data, "datadata")
    setDrugModel(true)
    if (drugModeldataChange === false) {
      setDrugModelData(data && data.treatment && data.treatment[0])
    }
  }
const [displayCount, setDisplayCount] = useState(3)
const viewMoreFun = () => {
  setDisplayCount(displayCount+3)
}
  console.log(medicine, "medicine")
  return (
    <>
      <EdCardheader />
      <div style={{ backgroundColor: "#F7FBF8" }}>
        <div className="container"
          style={{ display: "inline" }}>
          <div className="row">



            {/* <div className="row" > */}

            <div className="col-12">
              <div style={{
                fontSize: "36px",
                padding: "0px 0px 30px 0px",
                display: "flex",
                justifyContent: "center",
                color: "#002931",
                fontWeight: "600",
                paddingTop: "40px",
              }}>
                <p >Congratulations</p><br />
              </div>
              <div style={{
                padding: "0px 20px",
                fontWeight: "300",
                fontSize: "24px",
                fontFamily: "Visuelt Pro",
                display: "flex",
                justifyContent: "center",
                color: "#002931"
              }}>
                <p>You are suitable for a Doctall Treatment Plan. Please select your preferred Plan below:</p>
              </div>
            </div>

            <div>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  // marginBottom:"20px", 
                  marginTop: "50px",
                  // display:"grid",
                  //   placeContent:"center"
                }}
              >

                {/* {step === 0 ? null : (
            <Grid item style={{ width: "114px", marginRight: "39px" }}>
              <IconButton>
                <img src={prevQuestionIcon} alt="prev" onClick={prevQuestion} />
              </IconButton>
            </Grid>
          )} */}
                <Grid  item>
                  <LinearProgressBar 
                    value={hrs}
                    diseaseName={'card'}
                  />
                </Grid>



              </Grid>
            </div>
            <div id="wrapper-main" style={{ backgroundColor: "#F7FBF8" }}>
              <div className="wrapper-grid">

                {planList && planList.map((datas, index) => {
                  return <div 
                  style={{
                    display:"grid",
                    placeContent:"center"
                   }}
                  className="container"
                  >

                    {index < displayCount &&
                    <div
                      className="col-md-4 col-xl-4 col-lg-4 col-xs-12 card1"
                      style={{
                        border: "2px solid #00839B", display: "inline-block", borderRadius: "16px",
                        margin: "13px",
                        backgroundColor: "#fff",
                      }}>
                      <div
                        className=" col-md-4 col-12"
                        // md={4} xs={12}
                        style={{

                          margin: "40px 12px",
                          display: "flex",
                          justifyContent: "center",
                        }} >
                        <div className="cardlogo1" />
                        <div  >
                          {/* <NavLink to={DrugPopup}> */}
                          <img src={Card_icon} alt="Card_icon" className="Card_icon"
                            style={{
                              width: "65px",
                              padding: "8px 8px",
                              cursor: "pointer",
                            }}
                            onClick={(dd) => DetailesOFCard(datas)}
                          />
                          {/* </NavLink> */}
                        </div>
                      </div>

                      <div
                        className="col-md-4  col-12"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px 40px 60px 10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "0px 10px 0px 10px",
                            padding: "5px 10px",
                            border: "1px solid #66B5C3",
                            background: "#D9EDF1",
                            borderRadius: "8px",

                          }}
                        >
                          <div style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "14px",
                            padding: "8px",

                          }}>
                            <div >
                              <img src={iconCard1} alt="iconCard1" />
                            </div>
                            <div>
                              <p style={{ paddingLeft: "14px", color: " #002931", fontSize: "14px", fontFamily: "Visuelt Pro", fontWeight: "400", lineHeight: "20px", }}>Most effective</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-md-4 col-12"
                          style={{
                            padding: "5px 10px",
                            border: "1px solid #66B5C3",
                            background: "#D9EDF1",
                            borderRadius: "8px",
                          }}
                        >
                          <div style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "14px",
                            padding: "8px",

                          }}>
                            <div >
                              <img src={iconCard2} alt="iconCard2" />
                            </div>
                            <div >
                              <p style={{ paddingLeft: "10px", color: " #002931", fontSize: "14px", fontFamily: "Visuelt Pro", fontWeight: "400", lineHeight: "20px", }}>Best for first timers</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div style={{ padding: "0px 30px 30px 30px" }}>
                          <div style={{ float: "left", fontSize: "17px", lineHeight: "20px", fontFamily: "Visuelt Pro", fontWeight: "500", color: "#002931" }}>
                            <span>Manufacturer Name</span>
                          </div>
                          <div style={{ float: "right", fontSize: "17px", lineHeight: "20px", fontFamily: "Visuelt Pro", fontWeight: "400", color: "#005F71" }}>
                            <span>{datas && datas.treatment && datas.treatment[0] && datas.treatment[0].manufacturing}</span>
                          </div>
                        </div>
                        <div style={{ padding: "0px 30px 30px 30px" }}>
                          <div style={{ float: "left", fontSize: "17px", lineHeight: "20px", fontFamily: "Visuelt Pro", fontWeight: "500", color: "#002931" }}>
                            <span>Manufacturer Country</span>
                          </div>
                          <div style={{ float: "right", fontSize: "17px", lineHeight: "20px", fontFamily: "Visuelt Pro", fontWeight: "400", color: "#005F71" }}>
                            <ReactCountryFlag countryCode="NG" svg
                              style={{
                                borderRadius: "50%",
                                width: "20px",
                              }}
                            />
                            <span> {datas && datas.treatment && datas.treatment[0] && datas.treatment[0].manufacturing_country}</span>
                          </div>
                        </div>
                        <div style={{ padding: "0px 30px 30px 30px" }}>
                          <div style={{ float: "left", fontSize: "17px", lineHeight: "20px", fontFamily: "Visuelt Pro", fontWeight: "500", color: "#002931" }}>
                            <span>Time to take effect</span>
                          </div>
                          <div style={{ float: "right", fontSize: "17px", lineHeight: "20px", fontFamily: "Visuelt Pro", fontWeight: "400", color: "#005F71" }}>
                            <span>{datas && datas.treatment && datas.treatment[0] && datas.treatment[0].time_to_take_effect}</span>
                          </div>
                        </div>
                        <div style={{ padding: "0px 30px 30px 30px" }}>
                          <div style={{ float: "left", fontSize: "17px", lineHeight: "20px", fontFamily: "Visuelt Pro", fontWeight: "500", color: "#002931" }}>
                            <span>Lasts for</span>
                          </div>
                          <div style={{ float: "right", fontSize: "17px", lineHeight: "20px", fontFamily: "Visuelt Pro", fontWeight: "400", color: "#005F71" }}>
                            <span>{datas && datas.treatment && datas.treatment[0] && datas.treatment[0].last_for}</span>
                          </div>
                        </div>
                      </div>

                      {/* dropdown start */}
                      <div style={{
                        marginTop: "40px",
                        padding: "0px 30px",

                      }}

                      >
                        <div className="dropdown"
                          onClick={(e) => {
                            // setIsActive1(!isActive1);
                            handleActive(index)
                            // setIsSelected1(e.target.textContent);
                          }}
                        >
                          <div style={{ height: "60px" }}

                            className="dropdown-btn"
                          >
                            <Grid item container>
                              <Grid xs={11} sm={11} md={11} lg={11} xl={11}>
                                <div>
                                  {/* {selectedValue && selectedValue.map((item) => {
                                  return item.title === "Choose your dosages" ?  () => setIsActive1(false) : () => setIsActive1(true)
                                  })}
                                  {isActive1 === false &&(selectedValue && selectedValue[index] && selectedValue[index].title === undefined || selectedValue && selectedValue[index] && selectedValue[index].title === "Choose your dosages")  ? selected1 : selectedValue && selectedValue[index] && selectedValue[index].title } */}
                                 {console.log(selectedValue && selectedValue[index] ,"dsbydgysd")}
                                 {/* {selectedValue && selectedValue[index] && selectedValue[index].title === "Choose your dosages" || selectedValue && selectedValue[index] && selectedValue[index].title === undefined ? "Choose your dosages" : selectedValue && selectedValue[index] && selectedValue[index].title} */}
                                 {isActive1 === false ? "Choose your dosages" : selectedValue && selectedValue[index] && selectedValue[index].title}
                                </div>

                                <div style={{ color: "#065986", fontSize: "10px", lineHeight: "18px", fontWeight: "400", }}>
                                  {/* {selected12} */}
                                  {selectedValue && selectedValue[index] && selectedValue[index].subTitle}
                                </div>
                              </Grid>
                              <Grid xs={1} sm={1} md={1} lg={1} xl={1}>
                                <span>
                                  {drop[index] === true ?
                                    <img src={ArrowUp} alt="ArrowUp Icon" />
                                    :
                                    <img src={ArrowDown} alt="ArrowDown Icon" />
                                  }

                                </span>
                              </Grid>

                            </Grid>


                          </div>
                          <div
                            className="dropdown-content"
                            style={{ display: drop[index] === true ? "block" : "none" }}
                          >
                            {datas && datas.treatment.map((item) => {
                              return (<>
                                <div
                                  onClick={() => {
                                    editEventHandler(item, index);
                                    // setIsActive1(!isActive1)
                                  }}
                                  className="item"
                                  style={{
                                    fontFamily: "Visuelt Pro",
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#065986",
                                  }}
                                >
                                  {item.doses} {item.mg} Mg
                                  &nbsp;
                                  <br />
                                  <span>
                                    {item.tabs} {"tablet(s) in this package"}
                                  </span>
                                </div>
                              </>)
                            })}

                          </div>
                        </div>
                      </div>




                      {/* dropdown End */}

                      <div style={{
                        display: "flex",
                        alignItem: "center",
                        justifyContent: "center",
                      }}>
                        <div style={{
                          alignItem: "center",
                          justifyContent: "space between",
                          margin: "22px 10px 22px 10px",
                          borderRadius: "8px",
                          border: "2px solid #66B5C3",
                          padding: "5px 25px",
                          paddingTop: "15px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}>
                          <div style={{
                            color: "#065986",
                            fontSize: "17px",
                            fontWeight: "500",
                            fontSize: "18px",
                            float: "left",
                            paddingRight: "40px",
                            fontFamily: "Visuelt Pro",
                          }}>
                            <p >{isActive1 === false ? 'Qty' : selectedValue && selectedValue[index] && selectedValue[index].quantity}</p>
                          </div>


                          <div style={{
                            cursor: "pointer",
                            float: "right",
                            paadingLeft: "50px",
                          }}>
                            <img style={{ paddingLeft: "3px", }} src={chevronU} alt="up" onClick={() => { IncreaseDecrease('inc', index) }} /> <br />
                            <img src={chevronD} alt="down" onClick={() => { IncreaseDecrease('dec', index) }} />
                          </div>
                        </div>

                        <form>
                          <p style={{
                            color: "#065986",
                            fontSize: "16px",
                            padding: "16px 60px",
                            margin: "22px 10px 22px 10px",
                            border: "2px solid #66B5C3",
                            borderRadius: "8px",
                            fontWeight: "500",
                            fontFamily: "Visuelt Pro",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }} >
                            ₦{isActive1 === false ? '00' : selectedValue && selectedValue[index] && selectedValue[index].amount}{".00"}
                          </p>
                        </form>
                      </div>

                      {/* Buy Treatment */}
                      <div style={{
                        position: "relative",
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        
                        
                        lineHeight: "24px",
                        margin: "0px 35px 30px 35px",
                        cursor: "pointer",
                        zIndex: "none",
                        flexDirection: "row",
                        // overflow: "hidden",
                      }}>
                        {/* <a href="/EdCheckout" style={{ textDecoration: "none", color: "#065986", }}> */}
                        {/* selectedValue && selectedValue[index] && selectedValue[index].title === Choose your dosages || isActive1 === false */}
                        <button style={{
                          width:"100%",
                          height:"8vh",
                          fontSize: "20px",
                          fontWeight: "500",
                          color:"#065986",
                          // position:"absolute",
                          border: "2px solid #00839B",
                          background: "#FFFFFF",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "12px 20px",
                          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                          borderRadius: "8px",
                          // color: "#065986",
                          // opacity: "0.7",
                          cursor:'pointer'
                          // zIndex:" auto",
                          // overflow: "auto",
                        }}
                        disabled={(selectedValue && selectedValue[index] && selectedValue[index].title === "Choose your dosages") || isActive1 === false}
                          onClick={(item) => EDCheckout(datas, index)}
                        // onClick={(item) => handleStepChange({data: selectedValue && selectedValue[index] && selectedValue[index].initAmount, data1: selectedValue && selectedValue[index] && selectedValue[index].quantity, title:selectedValue && selectedValue[index] && selectedValue[index].title , subTitle: selectedValue && selectedValue[index] && selectedValue[index].subTitle, planDetailess:datas})}
                        >
                          Buy Treatment
                        </button>
                        {/* </a> */}
                      </div>

                    </div>
                }
                  </div>
                })}
                {/* First Card End  */}
              </div>
              {/* 2nd row End having 3 Cards */}



              {/* View Button */}

              <div className="col-md-4 col-12" style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                fontSize: "17px",
                color: "#065986",
                margin: "0px 0px 40px 0px ",
              }}
              onClick={viewMoreFun}
              >
                <div>
                  <p style={{
                    fontFamily: "Visuelt Pro",
                    padding: "10px 25px",
                    border: "2px solid #00839B",
                    borderRadius: "8px",
                    fontWeight: "bold",
                    cursor: "pointer",

                  }}
                  >View More</p>
                </div>
              </div>

              {/* </div> */}
            </div>
          </div>
          {/* </div> */}

          {/* Footer */}


          <Grid
            container
            className={classes.TreatmentPlanIfNotSure}
            direction={matchesXS ? "column" : undefined}
            alignItems={matchesXS ? "center" : undefined}
            style={{ backgroundColor: "#fff", }}
          >
            <Grid
              md={1}
              style={{ marginBottom: matchesSM ? "20px" : undefined }}
            >
              <img src={phoneIcon} />
            </Grid>
            <Grid md={8} XS={12}>
              <Typography
                className={classes.NotSureTPHeading}
                align={matchesXS ? "center" : undefined}
                paddingLeft={matchesXS ? "5px" : undefined}
                style={{ padding: matchesXS ? "3px" : undefined }}
              >
                Not sure which treatment plan is best for you?
              </Typography>
              <Typography
                className={classes.SpeakwithSubHeading}
                align={matchesSM ? "center" : undefined}
                style={{ padding: matchesXS ? "4px" : undefined }}
              >
                Speak with our medical team to get support and guidance
              </Typography>
            </Grid>
            <Grid
              md={3}
              style={{
                marginBottom: matchesSM ? "20px" : undefined,
                marginTop: matchesSM ? "30px" : undefined,
              }}
            >
              <button
                varient="contained"
                className={classes.SpeakwithCbtn}
                onClick={() => setCallBackModel(!callBackModel)}
              >
                Speak with Customer Service
              </button>
            </Grid>
          </Grid>

          <CallBackPopUp
            open={callBackModel}
            close={() => setCallBackModel(!callBackModel)}
          />

        </div>
      </div>



      {/* <div className=" footer">
                <div className="row">
                  <div  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    marginTop: "80px",
                    background: "#FFFFFF",
                    boxShadow: "0px -13px 19px 6px rgba(0, 0, 0, 0.07)",
                    padding: "40px 0px 0px 0px",
                  }}>
                   

                    <div className="col-md-4 col-12" style={{
                      padding: "0px 130px 0px 100px",
                      float: "left",
                    }}>
                      <img src={Call_icon} alt="Call_icon" />
                    </div>
                    <div className="col-md-4 col-12">
                      <p style={{
                        width: "628px",
                        height: "38px",
                        fontFamily: 'Visuelt Pro',
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "30px",
                        lineHeight: "38px",
                        color: "#002931",

                      }}>Not sure which treatment plan is best for you?</p>
                      <p
                        style={{
                          // width: "463px",
                          height: "32px",
                          fontFamily: 'Visuelt Pro',
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "18px",
                          lineHeight: "32px",

                        }}
                      >Speak with our medical team to get support and guidance</p>
                    </div>
                    <div className="col-md-4 col-12"
                      style={{
                        // width: "257px",
                        height: "48px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "12px 20px",
                        gap: "8px",
                        background: "#FF5C3C",
                        border: "1px solid #FF5C3C",
                        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                        borderRadius: "8px",
                        float: "right",
                        margin: "0px 0px 0px 200px",
                        cursor: "pointer",
                      }}
                    >
                      <p
                        style={{
                          // width: "217px",
                          height: "24px",
                          fontFamily: 'Visuelt Pro',
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "15px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                        }}
                      >Speak with Costumer Service</p>
                    </div>
                    
                    </div>
                  </div>
                </div>  */}


      {/* footer End */}


      <DrugPopup
        closeDialog={() => setDrugModel(!drugModel)}
        drugInfo={drugModelData && drugModelData}
        openDialog={drugModel}
      />
      {/* {drugpopup?
  // <DrugPopup/>
  <BrandInfo/>
  :null
} */}

    </>
  )
};



export default EdCard;
