import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { fetchCategory, fetchQuestions } from '../../services/category-services/categoryServices';
import actions from '../actions';

const { questionnaireActions } = actions;

export function* fetchQuestion() {

    yield takeEvery(questionnaireActions.GET_QUESTIONS, function* ({ disease_id }) {
        try {
            const response = yield fetchQuestions(disease_id);
            if (response.status === 200) {
                yield put({
                    type: questionnaireActions.GET_QUESTIONS_SUCCESS,
                    questionsData: response.data
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}


export default function* rootSaga() {
    yield all([fork(fetchQuestion)]);
}