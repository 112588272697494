import React, { useState, useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import "./SliderPartner.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
function ParentSlider(props) {
  const theme = useTheme();
  const [mobileView, setMobileView] = useState(false);
  const [partner, setPartner] = useState([]);
  const [noLogo, setnoLogo] = useState('')
  const custommatchesXS = useMediaQuery("(max-width:400px)");

  partner.splice(noLogo, 1)
  useEffect(() => {
    axios.get(`/metadata/partner/getpartner`)
      .then((res) => {
        setPartner(res.data);
      }).catch((err) => console.log(err.resp));



    const setResponsiveness = () => {
      return window.innerWidth < 767
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1440 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1200 },
      items: 5,
    },
    desktopMedium: {
      breakpoint: { max: 1200, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 3,
    },

    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      justify: "center",
    },
  };
  var Logo = [];

  {
    partner &&
      partner.map((partner1, index) => (
        <div key={`${partner1}${index}`}>

          {partner1 && partner1.logo ?

            <>
             
              {Logo.push(partner1.logo)}

            </>
            :
            setnoLogo(index)
          }

        </div>
      ))
  }
  return (
    <div className="partner_slider">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        //ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={1000}
        // containerClass="carousel-container"
        removeArrowOnDeviceType={[
          "tablet",
          "mobile",
          "desktop",
          "desktopMedium",
          "superLargeDesktop",
        ]}
        //deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {partner &&
          partner.map((partner, index) => (
            <>
              {partner.logo ? <div key={`${partner}${index}`}>
                <img
                  className="slider_img"
                  src={partner.logo}
                  alt="icon"
                  style={{
                    marginBottom: "45px",
                    width:"130px",
                    height:"auto"

                  }}
                />
              </div> : null}

            </>

          ))}
      </Carousel>
    </div>
  );
}

export default ParentSlider;
