import actions from "../actions";

const { packageActions } = actions;

const initState = {
  loader: false,
  packageData: [],
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case packageActions.GET_PACKAGE:
      return {
        ...state,
        loader: true,
      };

    case packageActions.GET_PACKAGE_SUCCESS:
      return {
        ...state,
        loader: false,
        packageData: action.packageData,
      };
    default:
      return state;
  }
}
