import React from 'react';
import Footer from '../Footer/Footer';
import DashboardHeader from './dashboardHeader';
import { makeStyles, useTheme } from "@material-ui/core";
import {
    Grid,
    Button,
    Typography,
    IconButton,
    useMediaQuery,
    CardContent,
    Card,
    CardActions,
    Hidden,
    FormControl,
    Input,
    Checkbox,
} from "@material-ui/core";
import styles from "./styles";
import Profile from "../../../assets/images/Profile.png";
import Arrow from "../../../assets/images/Arrow.png";
import Appointment from "../../../assets/images/Appointment.png";
import Person from "../../../assets/images/Person.png";
import Wallet from "../../../assets/images/Wallet.png";
import Res from "../../../assets/images/Res.png";
import Family from "../../../assets/images/Family.png";
import Record from "../../../assets/images/Record.png";
import DashboardComponent from './components/DashboardComponent';

const useStyles = makeStyles((theme) => styles(theme));

export default function Dashboard() {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const custommatchesXL = useMediaQuery("(min-width:1500px)");
    const custommatchesMDX = useMediaQuery("(max-width:1380px)");
    return (
        <>
            {/* <Header/> */}
            <DashboardHeader />
            <div>
                <Grid container direction="column"
                    className={classes.DashContainer}
                    alignItems={matchesXS ? "center" : undefined}
                >
                    <Grid
                        className={classes.TreatmentPlanIfNotSure}
                        direction={matchesXS ? "column" : undefined}
                        alignItems={matchesXS ? "center" : undefined}
                    >
                        <Grid
                            md={1}
                            style={{ marginBottom: matchesSM ? "20px" : undefined }}
                        >
                            <img src={Profile} />
                        </Grid>
                        <Grid md={8}>
                            <Typography
                                className={classes.NotSureTPHeading}
                                align={matchesXS ? "center" : undefined}
                            >
                                Good Morning, Promise
                            </Typography>
                            <Typography
                                className={classes.SpeakwithSubHeading}
                                align={matchesSM ? "center" : undefined}
                            >
                                Welcome to your dashboard, take a look at your services
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        className={classes.proceedContainer}
                    >
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className={classes.DashboardContainer}>
                            <Grid item xs={6}>
                                <DashboardComponent
                                    title={"Your Appointments"}
                                    subTitle={"Create an manage your medical appointments"}
                                    infoIcon={Appointment}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DashboardComponent
                                    title={"Orders & Results"}
                                    subTitle={"Keep a good record of your orders, and track deliveries"}
                                    infoIcon={Res}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DashboardComponent
                                    title={"Health Records"}
                                    subTitle={"An overview of your health records and medical report"}
                                    infoIcon={Record}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DashboardComponent
                                    title={"Your Family & Friends"}
                                    subTitle={"View and manage your relatives profiles"}
                                    infoIcon={Family}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DashboardComponent
                                    title={"Wallet"}
                                    subTitle={"View your wallet balance and refer a friend"}
                                    infoIcon={Wallet}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DashboardComponent
                                    title={"Profile"}
                                    subTitle={"Edit your personal information & shipping adddress"}
                                    infoIcon={Person}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <button
                            varient="contained"
                            className={classes.LogoutButton}
                        >
                            Log Out
                        </button>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    )
}
