const packageActions = {
  GET_PACKAGE: "GET_PACKAGE",
  GET_PACKAGE_SUCCESS: "GET_PACKAGE_SUCCESS",

  getPackage: (payLoad) => {
    return {
      type: packageActions.GET_PACKAGE,
      payLoad,
    };
  },
};

export default packageActions;
