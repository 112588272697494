import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import QLogo from "../../assets/images/QLogo.svg";
import QWelcome from "../../assets/images/QWelcome.png";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

const Summary = () => {
  const classes = useStyles();
  const { state } = useLocation();
console.log(state,"state")
  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(function () {
      navigate("/checkout", {
        state: {
          questionsSummary: state.questionsSummary,
          userSelectedPlan: state.userSelectedPlan,
          planDetails: state.planInfo,
          disease: state.disease,
          uuidDaata : state.uuidDaata
        },
      });
    }, 2000);
  }, []);

  return (
    <Grid container className={classes.quesContainer}>
      <Grid item className={classes.quesHeader}>
        <IconButton component={Link} to="/" style={{ padding: "0px" }}>
          <img src={QLogo} alt="Logo" />
        </IconButton>
      </Grid>
      <Grid container className={classes.quesContent}>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ position: "relative" }}
        >
          <img src={QWelcome} alt="Welcome" />
          <Typography
            style={{
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "36px",
              lineHeight: "44px",
              color: "#101828",
              letterSpacing: "-0.02em",
              paddingTop: "32px",
              paddingBottom: "12px",
            }}
          >
            Wait a minute
          </Typography>
          <Typography
            style={{
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#101828",
            }}
          >
            We are currently preparing the best treatment for you...
          </Typography>
          <hr
            style={{
              height: "8px",
              background: "#004451",
              width: "100%",
              position: "absolute",
              bottom: "0px",
              border: "none",
              left: "0px",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Summary;
