import React from 'react';
import "../AboutaUs/AboutUs.scss";
import image from '../../assets/images/Image.png';
// import image2 from '../../assets/images/image2.png';
import icon1 from '../../assets/images/Featured icon1.png';
import icon2 from '../../assets/images/Featured icon2.png';
import icon3 from '../../assets/images/Featured icon3.png';
import icon4 from '../../assets/images/Featured icon4.png';
import icon5 from '../../assets/images/Featured icon5.png';
import Olivia from '../../assets/images/Olivia Rhye.png';
import echolab from '../../assets/images/echolab.png';
import union from '../../assets/images/union-diagnostics.png';
import mcure from '../../assets/images/mecure.png';
import synlab from '../../assets/images/synlab.png';
import afri from '../../assets/images/afri.png';
import rightArrowWhite from '../../assets/images/right_arrow_white.png'



import {
    Grid,
    Button,
    Typography,
    IconButton,
    useMediaQuery,
} from "@material-ui/core";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/Linkedin.png";
import web from "../../assets/images/web.png";
import Team from "./Team";
// import NewsLetter from "../../components/AboutPage/NewsLetter"
import NewsLetter from "./NewsLetter"
import foundatioLogo from "../../assets/images/Doctall Foundation-logo.png"
// import TwitterIcon from '@mui/icons-material/Twitter';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';


function AboutUs() {
    return (
        <>
            <Header/>
        
        <div className='about_us'>
            {/* <BannerCarousel /> */}
            {/* banner_start */}
            <div className='banner'>
                <div className='banner_txt'>
                    <p style={{ color: "#FB6514", fontWeight: 600, fontSize: "16px" }} className="para">Work with Doctall</p>
                    <h1 className='banner_headding'>Join the train of doctors changing Africa’s healthcare narrative</h1>
                    {/* <button className='partner_button'>Book an appointment</button> */}
                </div>

            </div>
            {/* banner_end */}

            {/* about_first_section_start */}
            <div className='about-first-section'>
                <h1 className='mainHeading'>Leading you to the healthier life you deserve</h1>
                <p style={{ paddingTop: "20px" }} className="para">Doctall is a full circle digital healthcare provider committed to delivering quality end-to-end medical care that is accessible to all.</p>
            </div>
           {/* about_first_section_end */}

            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={6}>
                    <div className='vision_container'>
                        <h2 className='subheading'>Our Vision</h2>
                        <p style={{ fontSize: "18px" }} className="para">At Doctall, we believe that quality healthcare must be accessible to everyone, with no exceptions – this is what we strive for, what drives our every action and is our vision of a healthier future for all</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <img src={image} alt="Our Vision" className='vision_img' />
                </Grid>
                </Grid>

                <Grid container spacing={0}>
                {/* <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2 }}>
                    <img src={image2} alt="Our Mission" className='vision_img' />
                </Grid> */}
                <Grid item xs={12} sm={12} md={6}>
                    <div className='vision_container'>
                        <h2 className='subheading'>Our Mission</h2>
                        <p style={{ fontSize: "18px" }} className="para">Everyone deserves to have a greater expectation for good health, this should not be aspirational. Through technology, knowledge sharing, and sheer tenacity we have made it our mission to transform people’s ability to better manage their well-being and improve their health security</p>
                    </div>
                </Grid>
            </Grid>
       

            <div className='about_card_container'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className='about_card'>
                            <h1 className='subheading' style={{ fontSize: "38px" }}>WHY?</h1>
                            <p className="para2">Because, unfortunately, quality healthcare is currently a privilege and not a right.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className='about_card'>
                            <h1 className='subheading' style={{ fontSize: "38px" }}>HOW?</h1>
                            <p className="para2">Through our unwavering dedication, our doctors’ invaluable experience and leveraging innovative technologies to break down all barriers.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className='about_card'>
                            <h1 className='subheading' style={{ fontSize: "38px" }}>WHAT?</h1>
                            <p className="para2"> Providing comprehensive primary and secondary healthcare accessible anywhere, to everyone.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className='about_card'>
                            <h1 className='subheading' style={{ fontSize: "38px" }}>WHO?</h1>
                            <p className="para2">Everyone, one and all.</p>
                        </div>
                    </Grid>

                </Grid>

            </div>
            <div className='value_section'>
                <div className='value_container'>
                    <p style={{ color: " #E9D7FE", fontSize: "16px", fontWeight: 600 }} className="para">Our Values</p>
                    <h1 className='value_subheading'>What do we value? All sorts of things! But we particularly pride ourselves on:</h1>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={4} md={4}>
                            <div className='value'>
                                <img src={icon1} alt="Girl in a jacket" />
                                <h3 style={{ color: "#fff", fontSize: "20px", paddingTop: "10px" }}>Inclusivity</h3>
                                <p style={{ color: "#E9D7FE", fontSize: "16px", paddingTop: "10px" }} className="para">To lead communities, one and all, to better health </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <div className='value'>
                                <img src={icon2} alt="Girl in a jacket" />
                                <h3 style={{ color: "#fff", fontSize: "20px", paddingTop: "10px" }}>Vision </h3>
                                <p style={{ color: "#E9D7FE", fontSize: "16px", paddingTop: "10px" }} className="para">Be stubborn on vision, but flexible on strategy </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <div className='value'>
                                <img src={icon3} alt="Girl in a jacket" />
                                <h3 style={{ color: "#fff", fontSize: "20px", paddingTop: "10px" }}>Empowerment </h3>
                                <p style={{ color: "#E9D7FE", fontSize: "16px", paddingTop: "10px" }} className="para">Knowledge is power and access is an opportunity – we level the playing field</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}></Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <div className='value'>
                                <img src={icon4} alt="Girl in a jacket" />
                                <h3 style={{ color: "#fff", fontSize: "20px", paddingTop: "10px" }}>Empathy</h3>
                                <p style={{ color: "#E9D7FE", fontSize: "16px", paddingTop: "10px" }} className="para">To show empathy and compassion, inspiring hope to those most in need  </p>
                            </div>

                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>

                            <div className='value' >
                                <img src={icon5} alt="Girl in a jacket" />
                                <h3 style={{ color: "#fff", fontSize: "20px", paddingTop: "10px" }}>Commitment</h3>
                                <p style={{ color: "#E9D7FE", fontSize: "16px", paddingTop: "10px" }} className="para">Steadfast in our tireless relentlessness</p>
                            </div>
                        </Grid>

                    </Grid>

                </div>

            </div>


            <div className='team'>
                <div className='team_container'>
                    <h1 className='meet_subheading'>Meet our team</h1>
                    <p className='team_para'>Driven by passion, grit and invaluable experience, our team are true believers in convenient, quality healthcare that is accessible to all Nigerians.</p>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='team_card'>
                                <img src={Olivia} alt="Girl in a jacket" />
                                <h3 className='team_name'>Olivia Rhye</h3>
                                <h4 className='team_designation'>Founder & CEO</h4>
                                <p className="teamCardPara">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                <img src={twitter} alt="twitter" style={{ padding: " 5px" }} />
                                <img src={linkedin} alt="linkedin" style={{ padding: "5px " }} />
                                <img src={web} alt="web" style={{ padding: "5px " }} />

                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='team_card'>
                                <img src={Olivia} alt="Girl in a jacket" />
                                <h3 className='team_name'>Olivia Rhye</h3>
                                <h4 className='team_designation'>Founder & CEO</h4>
                                <p className="teamCardPara">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                <img src={twitter} alt="twitter" style={{ padding: " 5px" }} />
                                <img src={linkedin} alt="linkedin" style={{ padding: "5px " }} />
                                <img src={web} alt="web" style={{ padding: "5px " }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='team_card'>
                                <img src={Olivia} alt="Girl in a jacket" />
                                <h3 className='team_name'>Olivia Rhye</h3>
                                <h4 className='team_designation'>Founder & CEO</h4>
                                <p className="teamCardPara">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                <img src={twitter} alt="twitter" style={{ padding: " 5px" }} />
                                <img src={linkedin} alt="linkedin" style={{ padding: "5px " }} />
                                <img src={web} alt="web" style={{ padding: "5px " }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='team_card'>
                                <img src={Olivia} alt="Girl in a jacket" />
                                <h3 className='team_name'>Olivia Rhye</h3>
                                <h4 className='team_designation'>Founder & CEO</h4>
                                <p className="teamCardPara">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                <img src={twitter} alt="twitter" style={{ padding: " 5px" }} />
                                <img src={linkedin} alt="linkedin" style={{ padding: "5px " }} />
                                <img src={web} alt="web" style={{ padding: "5px " }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='team_card'>
                                <img src={Olivia} alt="Girl in a jacket" />
                                <h3 className='team_name'>Olivia Rhye</h3>
                                <h4 className='team_designation'>Founder & CEO</h4>
                                <p className="teamCardPara">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                <img src={twitter} alt="twitter" style={{ padding: " 5px" }} />
                                <img src={linkedin} alt="linkedin" style={{ padding: "5px " }} />
                                <img src={web} alt="web" style={{ padding: "5px " }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='team_card'>
                                <img src={Olivia} alt="Girl in a jacket" />
                                <h3 className='team_name'>Olivia Rhye</h3>
                                <h4 className='team_designation'>Founder & CEO</h4>
                                <p className="teamCardPara">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                <img src={twitter} alt="twitter" style={{ padding: " 5px" }} />
                                <img src={linkedin} alt="linkedin" style={{ padding: "5px " }} />
                                <img src={web} alt="web" style={{ padding: "5px " }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='team_card'>
                                <img src={Olivia} alt="Girl in a jacket" />
                                <h3 className='team_name'>Olivia Rhye</h3>
                                <h4 className='team_designation'>Founder & CEO</h4>
                                <p className="teamCardPara">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                <img src={twitter} alt="twitter" style={{ padding: " 5px" }} />
                                <img src={linkedin} alt="linkedin" style={{ padding: "5px " }} />
                                <img src={web} alt="web" style={{ padding: "5px " }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className='team_card'>
                                <img src={Olivia} alt="Girl in a jacket" />
                                <h3 className='team_name'>Olivia Rhye</h3>
                                <h4 className='team_designation'>Founder & CEO</h4>
                                <p className="teamCardPara">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                <img src={twitter} alt="twitter" style={{ padding: " 5px" }} />
                                <img src={linkedin} alt="linkedin" style={{ padding: "5px " }} />
                                <img src={web} alt="web" style={{ padding: "5px " }} />
                            </div>
                        </Grid>

                    </Grid>
                </div>



            </div>
            <Team />
            <div className='doctall_foundation'>




                <img src={foundatioLogo} alt="Girl in a jacket" style={{paddingBottom:"20px"}}/>
                <h1 className='foundation_heading'> Leading communities, one and all, to better health</h1>
                <p className='foundation_para'>The Doctall Foundation exists to bridge the gap between digitally excluded communities and the quality healthcare they need.
                </p>
                <p className='foundation_para'>
                    By leveraging the technologies of Doctall, the foundation strives to travel the last mile to reach those most in need of medical attention.</p>

                <Button style={{ backgroundColor: "#FF5C3C", color: "#fff", fontSize: "14px",padding:"10px 18px" }} variant="contained"> Lern More &nbsp; <img src={rightArrowWhite} alt="arrow"/> </Button>
            </div>

            <div className='partners' >
                <h2 className='our_partner'>OUR PARTNERS</h2>
                <Grid container spacing={0} >
                    <Grid item md={1} sm={0} xs={12} >

                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                        <img src={echolab} style={{padding:"20px 0px"}}  />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12} >
                        <img src={union} alt="union" style={{padding:"20px 0px"}} />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                        <img src={mcure} alt="mcure" style={{padding:"20px 0px"}} />

                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                        <img src={synlab} alt="synlab" style={{padding:"20px 0px"}}/>
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                        <img src={afri} alt="afry" style={{padding:"20px 0px"}}/>
                    </Grid>
                    <Grid item md={1} sm={0} xs={12}></Grid>

                </Grid>
                <Button style={{ backgroundColor: "#FF5C3C", color: "#fff", fontSize: "14px", marginTop: "50px" ,padding:"10px 18px" }} variant="contained"> View Partners &nbsp; <img src={rightArrowWhite} alt="arrow"/>   </Button>

            </div>
            <NewsLetter />


        </div>
        <Footer/>
        </>
    )
}

export default AboutUs