import { Input } from "@material-ui/core";
import { padding } from "@mui/system";
import register from "../../../assets/images/reg.jpg"

const Styles = (theme) =>({
    main: {
        position: "relative",
        
        // background: `linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(../../../assets/images/reg.jpg)`,
        // [theme.breakpoints.down("xs")]:{
        //     width: "0",
        //     height: "500px",
        // }
    },
    header: {
           width: "1349px",
    },

    registration_container_main: {
        position: "absolute",
        width: "100%",
        height: "1160px",
        //background: `linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5),url(${register})`,
        // left: "432px",
        top: "83px",
        backgroundImage: `url(${register})`,
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down("xs")]:{
            width: "0%",
            // height: "40%",
        },
        // [theme.breakpoints.down("md")]:{
        //     width: "100%",
            
        // }


    },
    nav: {
       width: "1365px",
       [theme.breakpoints.down("md")]:{
            width: "800px",

    },
},
    registrationform_page: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        position: "absolute",
        top: "100px",
        left: '382px',
        width: "666px",
        height: "910px",
       background: "#ffffff",
       flex: "none",
       order: "0",
       flexGrow: "0",
        
        [theme.breakpoints.down("xs")]: {
            width: "350px",
            
            left: '-30px',
            top: "13px",
            // padding: "50px 10px 10px 20px" ,
          },
          [theme.breakpoints.down("md")]:{
            // left: "0px",
            
        }  


    },
    registration_container:{
        display: "flex",
        position: "absolute",
        left: "45px",
        top: "90px",
        flexDirection: "column",
        flexDirection: "row",
        alignItems: "flexStart",
        padding: "0px",
        gap: "38px",
        width: "566px",
        height: "772px",
      
        flex: "none",
        order: "0",
        flexGrow: "0",
        [theme.breakpoints.down("xs")]: {
            height: "700px",
            width: "370px",
            // top: "20px"
        },
        [theme.breakpoints.down("sm")]: {
            height: "700",
            width: "0px",
            // top: "20px"
        }

    },
    Registrationform_heading: {
        width: "300px",
        position: "absolute",
        top: "-30px",
        height: "30px",
        lineHeight: "30px",
        fontWeight: "500",
        fontStyle: "normal",
        fontFamily: "Visuelt Pro",
        fontSize: "20px",
        lineHeight: "30px",
        display: "flex",
        alignItems: "center",
    
        textAlign: "center",
        color: "#002931",
        flex: "none",
        order: "0",
        flexGrow: "0",

    },
    headercontainer: {
backgroundColor: "#F7FBF8",

    },
     
    registrationform_section: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0",
        left: "-20px",
        gap: "38px",
        width: "666px",
        height: "704px",
        flex: "none",
        order: "1",
        flexGrow: "0",
        
        [theme.breakpoints.down("xs")]: {
            height: "700px",
            width: "300px",
            // top: "20px"
        },
        [theme.breakpoints.down("sm")]: {
            
            width: "390px",
            // top: "20px"
        },
    },

    inputLabels:{
        width: "100px",
height: "20px",

        
        display: "flex",
        
        color: "#344054",
        


fontFamily: 'Visuelt Pro',
fontStyle: "normal",
fontWeight: "500",
fontSize: "14px",
lineHeight: "20px",
paddingTop: "30px",



color: "#344054",




flex: "none",
order: "0",
flexGrow: "0",
    },
inputFields:{
display: "flex",
        
flexDirection: "row",
alignItems: "center",
padding: "10px 14px",
gap: "10px",

width: "566px",
height: "44px",
boxSizing: "border-box",


background: "#FFFFFF",


border: "1px solid #D0D5DD",


boxShadow:" 0px 1px 2px rgba(16, 24, 40, 0.05)",
borderRadius: "8px",
marginTop: "20px",
[theme.breakpoints.down("xs")]: {
    width: "350px",
    
   
  },
  [theme.breakpoints.down("sm")]: {
    // height: "250px",
    width: "380px",
    // top: "20px"
},




    },
   
    registrationClick:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "10px 14px",
        top: "30px",
        gap: "8px",
        fontSize: "16px",
        width: "566px",
        height: "44px",
        color: "#FFFFFF",
       fontWeight: "500",
        fontFamily: "Visuelt Pro",
        background: "#FF5C3C",
        lineHeight: "24px",
        
        border: "1px solid #C53B20",
        
        
        // boxShadow:" 0px 1px 2px rgba(16, 24, 40, 0.05)",
        borderRadius: "8px",
        [theme.breakpoints.down("xs")]: {
            width: "360px",
            
           
          },
        
      
        
        // flex: "none",
        // order: "0",
       
        // flexGrow: "1",
    },
    eror:{
      color: "#F04438",
      fontWeight: "400",
      fontSize: "14px",
      fontFamily: 'Visuelt Pro',
      fontStyle: "normal",
        },

    paper: {
        padding: '30px 20px',
         width: "666px", 
         height: "872px",
         alignContent: "center",
        //  margin: "50px auto",
        //  alignItems: "center",

    },
    header :{
        margin: "0px",
        fontSize: "20px",
        fontFamily: 'Visuelt Pro',
        fontWeight: "500",
        lineHeight: "30px",
    },
    inputs :{
        border: "1px solid #D0D5DD",
        // boxSizing: "border-box",
        height: "44px",
        padding: "20px 14px",
        borderRadius: "8px",
        columnGap: "8px",
        // top: "20px",
        display: "flex",
        flexDirection: "row",


    },

    Sucesspaper:{
        padding: "30px 20px",
        margin: "150px auto",
        width: "664px",
        height: "416px",
   },
   headersucess:{
       width: "348px",
       height: "76px",
       padding: "20px",
       fontWeight: "700",
       fontSize: "30px",
       lineHeight: "38px",
       color: "#101828",
       fontFamily: "Visuelt Pro",
       fontStyle: "normal",

   },
   subsucess:{
       width: "300px",
       // height: "48px",
       fontWeight: "400",
       padding: "20px",
       fontSize: "16px",
       lineHeight: "24px",
       // position: "absolute",
       margiTop: "20px",
       color: "#667085",
       fontFamily: "Visuelt Pro",
       fontStyle: "normal",
   },
})
export default Styles;