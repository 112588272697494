import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core";
import {
    Grid,
    Button,
    Typography,
    IconButton,
    useMediaQuery,
    CardContent,
    Card,
    CardActions,
    Hidden,
    FormControl,
    Input,
    Checkbox,
} from "@material-ui/core";
import Arrow from "../../../../assets/images/Arrow.png";

import styles from "../styles";

const useStyles = makeStyles((theme) => styles(theme));

export default function DashboardComponent({ title, subTitle, infoIcon }) {

    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const custommatchesXL = useMediaQuery("(min-width:1500px)");
    const custommatchesMDX = useMediaQuery("(max-width:1380px)");


    const onNavigate = () => {
        console.log(title)
    }

    return (
        <>
            <Grid
                item
                container
                direction={custommatchesMDX ? "column" : "row"}
                alignItems={custommatchesMDX ? "center" : undefined}
            >
                <Grid
                    item
                    className={classes.dWQhomeCare}
                    style={{
                        marginRight: custommatchesMDX ? "0px" : "38px",
                        marginBottom: custommatchesMDX ? "24px" : "24px",
                    }}
                    onClick={onNavigate}
                >
                    <Grid style={{ marginTop: "-50px" }}>
                        <img src={infoIcon} />
                    </Grid>
                    <Typography
                        className={classes.homeCareTitle}
                        style={{ width: matchesSM ? "250px" : "385px" }}
                    >
                        {title}

                    </Typography>
                    <Grid item container>
                        <Grid md={11}>
                            <Typography
                                className={classes.SpeakwithSubHeading1}
                            >
                                {subTitle}
                            </Typography>
                        </Grid>
                        <Grid md={1} align="right" style={{ marginTop: "-5%" }}>
                            <img src={Arrow} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

