import { Button, FormControl, Grid, Input, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/doctall_logo.png";
import mark from "../../../assets/images/mark.png";
import setPassCodeIcone from "../../../assets/images/setPassCodeIcone.svg";
import { showNotification } from "../../../components/common-notification/CommonNotification";
import "../../../components/Landing/Landing.scss";
import styles from "../../../components/Landing/styles";
import {
    onRegistration,
    onResetPasscode
} from "../../../services/auth-services/authServices";
import "../../Auth/auth.scss";

const useStyles = makeStyles((theme) => styles(theme));

export default function SetupPasscode() {
  const classes = useStyles();
  const navigate = useNavigate();
  const _userInfo = useSelector((state) => state.authReducer);

  const [passcode, passcodeName] = useState("");
  const [confirmPasscode, confirmPasscodeName] = useState("");

  const onChangeFname = (e) => {
    const input = e.currentTarget.value;
    if (/^[^!-\/:-@\[-`{-~a-zA-Z]+$/.test(input) || input === "") {
      passcodeName(input);
    }
  };
  const onChangeLname = (e) => {
    const input = e.currentTarget.value;
    if (/^[^!-\/:-@\[-`{-~a-zA-Z]+$/.test(input) || input === "") {
      confirmPasscodeName(input);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { passcode, confirmPasscode } = event.target.elements;
    if (passcode.value !== confirmPasscode.value) {
      showNotification(
        "error",
        "Failed !",
        "Passcode and Confirmed Passcode must be same"
      );
    } else {
      let _payLoad = _userInfo.registrationPayLoad;
      _payLoad["password"] = passcode.value;
      userRegistration(_payLoad);
    }
  };

  const userRegistration = async (payLoad) => {
    try {
      let response = await onRegistration(payLoad);
      if ((response && response.status === 200) || response.status === 201) {
        sendVerificationCode(payLoad);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        showNotification("error", "Failed !", "" + error.response.data);
      }
    }
  };

  const sendVerificationCode = async (payLoad) => {
    try {
      let resetPasscodePayLoad = {
        email: payLoad.email,
        mobile: payLoad.mobile,
      };
      let response = await onResetPasscode(resetPasscodePayLoad);
      if ((response && response.status === 200) || response.status === 201) {
        showNotification(
          "success",
          "Success",
          "Account created successfully, Please verify the code you just received in your mail"
        );
        navigate("/code-verification");
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        showNotification("error", "Failed !", "" + error.response.data.message);
      }
    }
  };

  return (
    <div className="login_page">
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <div className="Login_img_box">
            <img src={mark} alt="login_image" className="Login_img" />
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className="login_container">
            <div className="doctall_logo">
              <Link to="/">
                <img src={logo} alt="login_image" />
              </Link>
            </div>

            <div className="form_section">
              <div className="passcode_container">
                <img src={setPassCodeIcone} alt="success_icon" />
                <h1 className="Login_heading" style={{ marginTop: "30px" }}>
                  Setup passcode
                </h1>
                <p className="login_para" style={{ padding: "0px 30px" }}>
                  Choose a unique passcode you can easily remember
                </p>
              </div>

              <form onSubmit={handleSubmit}>
                <FormControl variant="standard" fullWidth>
                  <label htmlFor="name" className={classes.inputLabel}>
                    Passcode*
                  </label>
                  <Input
                    onChange={onChangeFname}
                    value={passcode}
                    type="text"
                    required
                    name="passcode"
                    className={classes.inputField}
                    disableUnderline={true}
                    inputProps={{
                      maxLength: 4,
                      minLength: 4,
                    }}
                  />

                  <label
                    htmlFor="confirmPasscode"
                    className={classes.inputLabel}
                    style={{ paddingTop: "20px" }}
                  >
                    Confirm Passcode*
                  </label>

                  <Input
                    onChange={onChangeLname}
                    value={confirmPasscode}
                    required
                    name="confirmPasscode"
                    className={classes.inputField}
                    disableUnderline={true}
                    inputProps={{
                      maxLength: 4,
                      minLength: 4,
                    }}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.subscribeButton}
                    style={{ backgroundColor: "#C53B20" }}
                  >
                    Create account
                  </Button>
                </FormControl>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
