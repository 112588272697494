import {
    Grid, Input
} from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import backArrow from "../../../assets/images/back_arrow.png";
import logo from "../../../assets/images/doctall_logo.png";
import mark from "../../../assets/images/mark.png";
import passcode from "../../../assets/images/passcode.png";
import { showNotification } from '../../../components/common-notification/CommonNotification';
import actions from '../../../redux/actions';
import { onResetPasscode } from '../../../services/auth-services/authServices';
import { getCountryCodes } from '../../../services/doctall-living/staticServices';
import "../auth.scss";




function ForgotPasscode() {
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState();
    const [reserror, setReserror] = useState(false);
    const [country, setCountry] = useState();
    const dispatch = useDispatch();

    const { authActions } = actions;

    const errorstyle = {
        border: reserror ? "1px solid #E04E47" : "1px solid #ADC2C2 ",
        outline: "none !important",
        borderRadius: "5px",
        marginBottom:"20px",

    };


    useEffect(() => {
        getCountryCode()
    }, []);

    const getCountryCode = async () => {
        try {
            let response = await getCountryCodes()
            if (response) {
                setCountry(response.data.country_code.toLowerCase());
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let { email } = event.target.elements;
        let payLoad = { 'mobile': phoneNumber, "email": email.value }
        try {
            let response = await onResetPasscode(payLoad)
            if (response && response.status === 200 || response.status === 201) {
                showNotification("success", "Success", "Please verify the code you just received in your mail")
                let _payLoad = {
                    "full_name": "FORGOT_PASSCODE",
                    "firstName": "FORGOT_PASSCODE",
                    "lastName": "FORGOT_PASSCODE",
                    "email": email.value,
                    "mobile": phoneNumber,
                    "password": 'FORGOT_PASSCODE',
                    "group": "consumer",
                }
                dispatch(authActions.saveRegPayLoad(_payLoad))
                navigate("/code-verification")

            }
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                showNotification("error", "Failed !", "" + error.response.data.message)
            }
        }

    }

    return (

        <div className='login_page'>
            <Helmet>
                <title>Doctall.com | Forgot Password</title>
                <meta 
                name='description' 
                content='Book a doctor appoinment online. Get packaged treatments for hair loss, erectile dysfunction, premature ejaculation, and other acute and chronic conditions. Find accurate health information about cancer, malaria and much more' 
                />
            </Helmet>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12} >
                    <div className='Login_img_box'>
                        <img src={mark} alt="login_image" className='Login_img' />
                    </div>

                </Grid>
                <Grid item sm={6} xs={12} >
                    <div className='login_container'>
                        <div className='doctall_logo'>
                        <Link to="/">
                                <img src={logo} alt="login_image" />
                            </Link>
                        </div>


                        <div className='form_section'>
                            <div className="passcode_container">
                                <img src={passcode} alt="success_icon" />
                                <h1 className='Login_heading' style={{ marginTop: "30px" }}>Forgot passcode?</h1>
                                <p className='login_para'>Change and create new passcode</p>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <label for="fname" className='label'>Phone number</label>

                                <PhoneInput
                                    style={errorstyle}
                                    // style={{width:"100%"}}
                                    className="inputPhone"
                                    country={country}
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                    name='phone'
                                    required
                                />
                                <label for="fname" className='label'>Email address</label>
                                <Input
                                    type="email"
                                    name="email"
                                    required
                                    placeholder='Enter your email'
                                    className='input_box'
                                    disableUnderline={true} />
                                <div style={{ paddingTop: "20px" }}>


                                </div>


                                <button type="submit" value="Submit" className='signin_btn' style={{ marginTop: "20px" }}>Send reset link</button>

                            </form>
                            <div style={{ paddingTop: "20px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}>
                                    

                                    </Grid>
                                    <Grid item xs={11}>
                                        <p className='Forgot_passcode' style={{ color: "#667085" }}>You’ll receive the code on your registered phone number and email address
                                        </p>

                                    </Grid>

                                </Grid>

                            </div>
                            <Link to="/login">
                                <p style={{ color: "#667085", fontSize: "14px", textAlign: "center", marginTop: "20px" }}>  <img src={backArrow} /> &nbsp; Back to log in </p>
                            </Link>

                        </div>

                    </div>


                </Grid>

            </Grid>
        </div>
    )
}

export default ForgotPasscode