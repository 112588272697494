// import factsBG from "../../../assets/images/factsBG.svg";
import {
  ED_ROUTE_PATH,
  HL_ROUTE_PATH,
  PE_ROUTE_PATH,
  STRESS_MANAGEMENT,
   WELLNESS_ROUTE_PATHE,
   HORMONE_AND_FERTILITY,
   PROSTATE_ROUTE_PATH,
   PREMARITAL_CHECK,
   HEART_AND_HYPERTENSION,
   MENOPAUSE_ROUTE_PATH,
   PCOS_ROUTE_PATH,
   THYROID_ROUTE_PATH,
   DIABETES_ROUTE_PATH,
   KIDNEY_FAILURES,
   LIVER_ROUTE_PATH,
   STIs_ROUTE_PATH,
   HIV_ROUTE_PATH,
   MOTHER_AND_CHILD,
   WELLNES_ROUTE_PATHE,
   HORMONE_AND_FERTILITYW,
   HEART_AND_HYPERTENSIONW,
   STRESS_MANAGEMENTW,
   TYPHOID_ROUTE_PATH,
   COVID_ROUTE_PATH,
   MALARIA_ROUTE_PATH,
   COLD_COUGH_OR_FEVER,
   NUTRITION_ROUTE_PATH,
   FITNESS_AND_NDURANCE,
   SPORTS_ROUTE_PATH,
   WEIGHT_MANAGEMENT,
   ENERGY_AND_VIGOR,
   } from "../../common/utils";


let factsBG = "https://s3.eu-west-1.amazonaws.com/doctall.storage.com/new-doctall/factsBG.svg"

// let factsBG =
//   "https://s3.eu-west-1.amazonaws.com/doctall.storage.com/new-doctall/factsBG.svg";

export const headerMenu = [
  {
    id: 0,
    name: "Men’s Health",
    //ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
    //ariaPopup: anchorEl ? "true" : undefined,

    //mouseOver: (event) => handleClick(event),
    options: [
      /* "Wellness",
        "Hormone and Fertility",
        "Prostate",
        
        "Pre-Marital Check",
        
        "Heart & Hypertension",
        "Stress Management", */
      {
        name: "Wellness",
        routePath: WELLNESS_ROUTE_PATHE,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },
      {
        name: "Hormone & Fertility",
        routePath: HORMONE_AND_FERTILITY,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        // bg: factsBG,
      },
      {
        name: "Prostate",
        routePath: PROSTATE_ROUTE_PATH,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },
      {
        name: "Premarital Check",
        routePath: PREMARITAL_CHECK,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },
      {
        name: "Heart & Hypertension",
        routePath: HEART_AND_HYPERTENSION,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },
      {
        name: "Stress Management",
        routePath: STRESS_MANAGEMENT,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },

      {
        name: "Hair Loss",
        routePath: HL_ROUTE_PATH,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },
      {
        name: "Erectile Dysfunction",
        routePath: ED_ROUTE_PATH,
        facts: [
          `Erectile dysfunction is a common problem.`,
          `It can be dangerous to treat erectile dysfunction yourself. You run several risks when you take supplements for ED `,
          `Although ED itself isn't dangerous, it can be an early warning sign of a serious health condition, such as diabetes or heart problems.`,
          `Lifestyle habits can contribute to erectile dysfunction.`,
          ` Although ED is more common among older men, that doesn't mean it's something you have to live with.`,
        ],
        bg: factsBG,
      },
      {
        name: "Premature Ejaculation",
        routePath: PE_ROUTE_PATH,
        facts: [
          `Premature ejaculation is when you ejaculate too soon, typically before sex or right after starting sex.`,
          `Premature ejaculation is usually caused by anxiety, other emotional issues, or sexual inexperience.`,
          `Premature ejaculation often causes stress for couples.`,
          `Therapy can help most men delay ejaculation.`,
        ],
        bg: factsBG,
      },
    ],
  },
   {
    id: 1,
    name: "Women’s Health",
    // ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
    // ariaPopup: anchorEl ? "true" : undefined,

    // mouseOver: (event) => handleClick(event),
    options: [
      // "Wellness",
      // "Hormone and Fertility",
      // "PCOS",
      // "Hair Loss",
      // "Pre-Marital Check",
      // "Menopause",
      // "Heart & Hypertension",
      // "Stress Management",
      {
        name: "Wellness",
        routePath: WELLNES_ROUTE_PATHE,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },
      {
        name: "Hormone & Fertility",
        routePath: HORMONE_AND_FERTILITYW,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },

      {
        name: "Premarital Check",
        routePath: PREMARITAL_CHECK,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },
      {
        name: "Heart & Hypertension",
        routePath: HEART_AND_HYPERTENSIONW,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },
      {
        name: "Stress Management",
        routePath: STRESS_MANAGEMENTW,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },

      {
        name: "Hair Loss",
        routePath: HL_ROUTE_PATH,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },

      {
        name: "Menopause",
        routePath: MENOPAUSE_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "PCOS",
        routePath: PCOS_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Chronic Conditions",
  //   ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
  //   ariaPopup: anchorEl ? "true" : undefined,

  //   mouseOver: (event) => handleClick(event),
    options: [
      // "Thyroid",
      // "Diabetes",
      // "PCOS",
      // "Kidney",
      // "Liver",
      // "HIV",
      // "Hair Loss",
      // "Prostate",
      // "Heart & Hypertension",
      // "Stress Management",
      {
        name: "Thyroid",
        routePath: THYROID_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "Diabetes",
        routePath: DIABETES_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "PCOS",
        routePath: PCOS_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "Kidney Failures",
        routePath: KIDNEY_FAILURES,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "Liver",
        routePath: LIVER_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },

      {
        name: "Hair Loss",
        routePath: HL_ROUTE_PATH,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },
      {
        name: "Prostate",
        routePath: PROSTATE_ROUTE_PATH,
        facts: [
          `50% of your hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
          `Hair loss can be easily treated.`,
        ],
        bg: factsBG,
      },
      {
        name: "Heart & Hypertension",
        routePath: HEART_AND_HYPERTENSIONW,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "Stress Management",
        routePath: STRESS_MANAGEMENTW,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Common Condition",
    // ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
    // ariaPopup: anchorEl ? "true" : undefined,

    // mouseOver: (event) => handleClick(event),
    options: [
      {
        name: "Malaria",
        routePath: MALARIA_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "Typhoid",
        routePath: TYPHOID_ROUTE_PATH,
        // facts: [
        //   `Typhoid is a life-threatening bacterial infection spread through contaminated food and water. It is highly contagious and can pass from person to person very quickly.`,
        //   `Typhoid is treatable with antibiotics and medical care.`,
        // ],
        // bg: TYP1,
      },
      {
        name: "Cold, Cough or Fever",
        routePath: COLD_COUGH_OR_FEVER,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "STIs",
        routePath: STIs_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      // {
      //   name: "HIV",
      //   facts: [
      //     `Hair may be lost before it’s noticeable.`,
      //     `It’s normal to lose 50-100 strands of hair a day.`,
      //     `Surgical hair restoration is socially acceptable.`,
      //     `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
      //     `Hair loss can be easily treated.`,
      //   ],
      // },
      {
        name: "Erectile Dysfunction",
        routePath: ED_ROUTE_PATH,
        facts: [
          `Erectile dysfunction is a common problem.`,
          `It can be dangerous to treat erectile dysfunction yourself.You run several risks when you take supplements for ED `,
          `Although ED itself isn't dangerous, it can be an early warning sign of a serious health condition, such as diabetes or heart problems.`,
          `Lifestyle habits can contribute to erectile dysfunction.`,
          ` Although ED is more common among older men, that doesn't mean it's something you have to live with.`,
        ],
      },
      // {
      //   name: "Thyroid",
      //   routePath: THYROID_ROUTE_PATH,
      //   facts: [
      //     `Hair may be lost before it’s noticeable.`,
      //     `It’s normal to lose 50-100 strands of hair a day.`,
      //     `Surgical hair restoration is socially acceptable.`,
      //     `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
      //     `Hair loss can be easily treated.`,
      //   ],
      // },
      {
        name: "Covid-19",
        routePath: COVID_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
    ],
  },
  {
    id: 4,
    name: "Sexual Health",
    //ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
    //ariaPopup: anchorEl ? "true" : undefined,

    //mouseOver: (event) => handleClick(event),
    options: [
      {
        name: "Erectile Dysfunction",
        routePath: ED_ROUTE_PATH,
        facts: [
          `Erectile dysfunction is a common problem.`,
          `It can be dangerous to treat erectile dysfunction yourself.You run several risks when you take supplements for ED.`,
          `Although ED itself isn't dangerous, it can be an early warning sign of a serious health condition, such as diabetes or heart problems.`,
          `Lifestyle habits can contribute to erectile dysfunction.`,
          ` Although ED is more common among older men, that doesn't mean it's something you have to live with.`,
        ],
      } /*  "STIs", "HIV" */,

      {
        name: "Premature Ejaculation",
        routePath: PE_ROUTE_PATH,
        facts: [
          `Premature ejaculation is when you ejaculate too soon, typically before sex or right after starting sex.`,
          `Premature ejaculation is usually caused by anxiety, other emotional issues, or sexual inexperience.`,
          `Premature ejaculation often causes stress for couples.`,
          `Therapy can help most men delay ejaculation.`,
        ],
        bg: factsBG,
      },
      {
        name: "STIs",
        routePath: STIs_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "HIV",
        routePath: HIV_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
    ],
  },
   {
    id: 5,
    name: "Lifestyle & Health",
  //   ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
  //   ariaPopup: anchorEl ? "true" : undefined,

  //   mouseOver: (event) => handleClick(event),
    options: [
      // "Wellness",
      // "Nutrition",
      // "Fitness & Endurance",
      // "Sport",
      // "Weight Management",
      // "Energy & Vigor",
      // "Stress Management",
      {
              name: "Wellness",
              routePath: WELLNESS_ROUTE_PATHE,
              facts: [
                `50% of your hair may be lost before it’s noticeable.`,
                `It’s normal to lose 50-100 strands of hair a day.`,
                `Surgical hair restoration is socially acceptable.`,
                `Thyroid imbalance and iron deficiency are reversible causes for hair loss.`,
                `Hair loss can be easily treated.`,
              ],
              bg: factsBG,
            },
            {
              name: "Nutrition",
              routePath: NUTRITION_ROUTE_PATH,
              facts: [
                `Hair may be lost before it’s noticeable.`,
                `It’s normal to lose 50-100 strands of hair a day.`,
                `Surgical hair restoration is socially acceptable.`,
                `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
                `Hair loss can be easily treated.`,
              ],
            },
            {
              name: "Fitness & Endurance",
              routePath: FITNESS_AND_NDURANCE,
              facts: [
                `Hair may be lost before it’s noticeable.`,
                `It’s normal to lose 50-100 strands of hair a day.`,
                `Surgical hair restoration is socially acceptable.`,
                `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
                `Hair loss can be easily treated.`,
              ],
            },
      {
        name: "Sport",
        routePath: SPORTS_ROUTE_PATH,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "Weight Management",
        routePath: WEIGHT_MANAGEMENT,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "Energy & Vigor",
        routePath: ENERGY_AND_VIGOR,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },
      {
        name: "Stress Management",
        routePath: STRESS_MANAGEMENTW,
        facts: [
          `Hair may be lost before it’s noticeable.`,
          `It’s normal to lose 50-100 strands of hair a day.`,
          `Surgical hair restoration is socially acceptable.`,
          `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
          `Hair loss can be easily treated.`,
        ],
      },

    ],
  },
  //   mouseOver: (event) => handleClick(event),
  //   options: [
  // //     // "Wellness",
  // //     // "Nutrition",
  // //     // "Fitness & Endurance",
  // //     // "Sport",
  // //     // "Weight Management",
  // //     // "Energy & Vigor",
  // //     // "Stress Management",
  //     {
  //       name: "Stress Management",
  //       facts: [
  //         `Hair may be lost before it’s noticeable.`,
  //         `It’s normal to lose 50-100 strands of hair a day.`,
  //         `Surgical hair restoration is socially acceptable.`,
  //         `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
  //         `Hair loss can be easily treated.`,
  //       ],
  //     },
  //     {
  //       name: "Menopause",
  //       routePath: MENOPAUSE_ROUTE_PATH,
  //       facts: [
  //         `Hair may be lost before it’s noticeable.`,
  //         `It’s normal to lose 50-100 strands of hair a day.`,
  //         `Surgical hair restoration is socially acceptable.`,
  //         `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
  //         `Hair loss can be easily treated.`,
  //       ],
  //     },
  //     {
  //     name: "Thyroid",
  //     facts: [
  //       `Hair may be lost before it’s noticeable.`,
  //       `It’s normal to lose 50-100 strands of hair a day.`,
  //       `Surgical hair restoration is socially acceptable.`,
  //       `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
  //       `Hair loss can be easily treated.`,
  //     ],
  //   },
  //   {
  //     name: "Cold, Cough and Fever",
  //     facts: [
  //       `Hair may be lost before it’s noticeable.`,
  //       `It’s normal to lose 50-100 strands of hair a day.`,
  //       `Surgical hair restoration is socially acceptable.`,
  //       `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
  //       `Hair loss can be easily treated.`,
  //     ],
  //   },
  //   {
  //     name: "Malaria",
  //     facts: [
  //       `Hair may be lost before it’s noticeable.`,
  //       `It’s normal to lose 50-100 strands of hair a day.`,
  //       `Surgical hair restoration is socially acceptable.`,
  //       `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
  //       `Hair loss can be easily treated.`,
  //     ],
  //   },

  //   ],
  // },
 
  {
    id: 6,
    name: "Diabetes",
    routePath: DIABETES_ROUTE_PATH,
    // ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
    // ariaPopup: anchorEl ? "true" : undefined,

    // mouseOver: (event) => handleClick(event),
    // Facts Need To Update
    options: [
      {
        name: "Diabetes",
        routePath: DIABETES_ROUTE_PATH,
        facts: [
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        ],
      },
      // {
      //   name: "Heart & Hypertension",
      //   facts: [
      //     `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      //     `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      //     `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      //     `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      //     `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      //   ],
      // },
      // {
      //   name: "Kidney Failures",
      //   facts: [
      //     `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      //     `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      //     `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      //     `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      //     `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
      //   ],
      // },
    ],
  },
  
    {
       id: 7,
       name: "Mother & Child",
       routePath: MOTHER_AND_CHILD,
      //  routePath: MOTHER_AND_CHILD,
       // ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
       // ariaPopup: anchorEl ? "true" : undefined,

      // mouseOver: (event) => handleClick(event),
      options: [
        {
          name: "Mother and Child",
          routePath: MOTHER_AND_CHILD,
        }
      ],
     }, 
];
