export const getDiesesIds = (diseaseName) => {
    console.log(diseaseName, "assas")
    switch (diseaseName) {
        case 'Erectile Dysfunction':
            return 'DIS-E7F72BF54FC1';
        case 'Hair Loss':
            return 'DIS-52F617A1BA8D';
        case 'Premature Ejaculation':
            return 'DIS-AD10E9D4F719';
        case 'Wellness':
            return 'DIS-24B1E056E99F';
        default:
            return 'DIS-E7F72BF54FC1'
    }
}

export const getPackage = (diseaseName) => {
    console.log(diseaseName, "assas")
    switch (diseaseName) {
        case 'Wellness':
            return 'DIS-24B1E056E99F';
        case 'Erectile Dysfunction':
            return 'DIS-E7F72BF54FC1';
        case 'Hair Loss':
            return 'DIS-52F617A1BA8D';
        case 'Premature Ejaculation':
            return 'DIS-8F265DF76C4E';
        case 'Prostate':
            return "DIS-36E3AA104FD1";
        case "Hormone & Fertility":
            return "DIS-24B1E056E99F" 
        case "Premarital Check":
            return "DIS-24B1E056E99F";
        case "Heart & Hypertension":
            return "DIS-24B1E056E99F";
        case "Wellness ":
            return "DIS-24B1E056E99F";
        case "Hormone & Fertility":
            return "DIS-63755E6E3729" ;
        case "PCOS ":
            return "DIS-A289863DED88" ;
        case "Premarital Check":
            return "DIS-A7ADBC6D8AF4";
        case "Heart & Hypertension":
            return "DIS-E00A3CC49F0E" ;
        case "Menopause ":
            return "DIS-F813AAA04CC8";
        case "Thyroid ":
            return "DIS-DFB315E90A37";
        case "Diabetes ":
            return "DIS-97CA3642DE35";
        case "pcos":
            return "DIS-A289863DED88";
        case "Kidney Failures":
            return "DIS-6D18FF2CEA7C";
        case "Liver ":
            return "DIS-04BAD2DB9B60";
        case "HIV ":
            return "DIS-92A2EAB9E6A3";
        case "Prostate ":
            return "DIS-942B9F57EEF9";
        case "Heart & Hypertension":
            return "DIS-3F308D069656";
        case "Malaria ":
            return "DIS-9E418FE799B4";
        case "Typhoid ":
            return "DIS-E4F1FA3D83E8";
        case "Cold, cough or fever":
            return "DIS-BB533B6553AF";
        case "STIs ":
            return "DIS-0F26DEAB19FE ";
        case "Covid-19":
            return "DIS-94A6CC94302C";
        case "STIs ":
            return "DIS-0F26DEAB19FE";
        case "HIV ":
            return "DIS-92A2EAB9E6A3";
        case "Wellness ":
            return "DIS-6D4158611410";
        case "Nutrition ":
            return "DIS-70CBD49FF54C";
        case "Fitness & Endurance":
            return "DIS-0590EC18BC8F";
        case "Sport ":
            return "DIS-7AEF2FAE945C";
        case "Weight Management":
            return "DIS-1FACFCAC330E";
        case "Energy & Vigor":
            return "DIS-1C2DC2524094";
        case "Diabetes ":  
            return "DIS-6BA3C437D357";
                                                                                                                                   
        default:
            return 'DIS-24B1E056E99F';
    }
}


export const getDiesesIdForPediaBlog = (diseaseName) => {
    //if you want setup ids for women and men separately then you need to 
    // change selectedD[0].name to selectedD[0].routePath in disease.js
    switch (diseaseName) {
        case 'Erectile Dysfunction':
            return '3824';

        case 'Hair Loss':
            return '38';


        case 'Premature Ejaculation':
            return '3823';


        case 'Prostate':

            return "3859";


        case 'Hormone & Fertility':

            return " 3880";

        // case 'Women’s Hormone & Fertility':

        //     return " 3877";


        case 'Wellness':

            return "3878";

        case 'Women’s Wellness':
            return " 3882";


        case 'Heart & Hypertension':

            return '3860';


        case 'Stress Management':

            return "3848";


        // case 'Hormone & Fertility':

        //     return '3839';


        case 'PCOS':

            return '3841';


        case 'Menopause':

            return "3846";


        case 'Thyroid':

            return "3850";


        case 'Diabetes':

            return "113";


        case 'Kidney Failures':

            return "3853";


        case 'Liver':

            return "3855";


        case 'HIV':

            return "3858"


        case 'Malaria':

            return "3862";


        case 'STIs':

            return "3869";


        // case 'Covid-19':

        //     return "5532";

        // case 'HIV':

        //     return "3858";


        // case 'Nutrition':

        //     return "1200";

        case 'Typhoid':

            return "3864";

        // case 'Fitness & Endurance':

        //     return "0444";

        // case 'Sport':

        //     return "0099";

        // case 'Weight Management':

        //     return "0090";

        // case 'Energy & Vigor':

        //     return "7654";

        // case 'Mother and Child':

        //     return "0809";

        case 'Premarital Check':

            return "3844";

        default:
            return '0000'
    }
}



export const getDiesesIdForBlog = (diseaseName) => {
    switch (diseaseName) {
        case 'Erectile Dysfunction':

            return "1257";

        case 'Premature Ejaculation':

            return "1258";

        case 'Hair Loss':

            return "1259";


        case 'Prostate':

            return "1315";


        case 'Hormone & Fertility':

            return "1322";


        case 'Wellness':

            return "105";


        case 'Heart & Hypertension':

            return "1319";


        case 'Stress Management':

            return "109";


        // case 'Hormone & Fertility':

        //     return "4432";

        case 'PCOS':

            return "1324";


        case 'Menopause':

            return "1326";


        // case 'Thyroid':

        //     return "9987";

        case 'Diabetes':

            return "113";


        case 'Kidney Failures':

            return "1330";


        // case 'Liver':

        //     return "6654";

        case 'HIV':

            return "1337";


        case 'Malaria':

            return "1331";


        case 'STIs':

            return "1335";


        // case 'Covid-19':

        //     return "5532";

        // case 'HIV':

        //     return "5555";

        case 'Nutrition':

            return "29";

        case 'Thypoid':

            return "1332";

        case 'Fitness & Endurance':

            return "30";


        case 'Sport':

            return "1343";


        case 'Weight Management':

            return "1345";


        // case 'Energy & Vigor':

        //     return "7654";

        case 'Mother and Child':

            return "123";

        case 'Premarital Check':

            return "1312";


        default:

            return "0000";

    }
}


export const getSpeciality = (diseaseName) => {
    let payLoad = {}
    switch (diseaseName) {
        case 'Erectile Dysfunction':
            payLoad = {
                "speciality": ["Urology"]
            }
            return payLoad;
        case 'Premature Ejaculation':
            payLoad = {
                "speciality": ["Urology"]
            }
            return payLoad;
        case 'Hair Loss':
            payLoad = {
                "speciality": ["Dermatology"]
            }
            return payLoad;

        case 'Prostate':
            payLoad = {
                "speciality": ["Urology"]
            }
            return payLoad;

        case 'Hormone & Fertility':
            payLoad = {
                "speciality": ["Endocrinology", "Urology"]
            }
            return payLoad;

        case 'Wellness':
            payLoad = {
                "speciality": ["Internal Medicine"]
            }
            return payLoad;

        case 'Heart & Hypertension':
            payLoad = {
                "speciality": ["Cardiology"]
            }
            return payLoad;

        case 'Stress Management':
            payLoad = {
                "speciality": ["Psychology"]
            }
            return payLoad;

        case 'Hormone & Fertility':
            payLoad = {
                "speciality": ["Endocrinology", "Obstetrics and Gynecology"]
            }
            return payLoad;

        case 'PCOS':
            payLoad = {
                "speciality": ["Endocrinology", "Obstetrics and Gynecology"]
            }
            return payLoad;

        case 'Menopause':
            payLoad = {
                "speciality": ["Gynecology", "Obstetrics and Gynecology"]
            }
            return payLoad;

        case 'Thyroid':
            payLoad = {
                "speciality": ["Endocrinology"]
            }
            return payLoad;

        case 'Diabetes':
            payLoad = {
                "speciality": ["Endocrinology"]
            }
            return payLoad;

        case 'Kidney Failures':
            payLoad = {
                "speciality": ["Nephrology"]
            }
            return payLoad;

        case 'Liver':
            payLoad = {
                "speciality": ["Gastroenterology"]
            }
            return payLoad;

        case 'HIV':
            payLoad = {
                "speciality": ["Internal Medicine"]
            }
            return payLoad;

        case 'Malaria':
            payLoad = {
                "speciality": ["Internal Medicine"]
            }
            return payLoad;

        case 'STIs':
            payLoad = {
                "speciality": ["Urology"]
            }
            return payLoad;

        case 'Covid-19':
            payLoad = {
                "speciality": ["Internal Medicine", "Pulmonology"]
            }
            return payLoad;

        case 'HIV':
            payLoad = {
                "speciality": ["Internal Medicine"]
            }
            return payLoad;

        case 'Nutrition':
            payLoad = {
                "speciality": ["Internal Medicine", "Dietitian"]
            }
            return payLoad;

        // case 'Nutrition':
        //     payLoad = {
        //         "speciality": ["Internal Medicine", "Dietician"]
        //     }
        //     return payLoad;

        case 'Fitness & Endurance':
            payLoad = {
                "speciality": ["Internal Medicine"]
            }
            return payLoad;

        case 'Sport':
            payLoad = {
                "speciality": ["Internal Medicine"]
            }
            return payLoad;

        case 'Weight Management':
            payLoad = {
                "speciality": ["Internal Medicine"]
            }
            return payLoad;

        case 'Energy & Vigor':
            payLoad = {
                "speciality": ["Internal Medicine"]
            }
            return payLoad;

        case 'Mother and Child':
            payLoad = {
                "speciality": ["Obstetrics and Gynecology", "Paediatrics"]
            }
            return payLoad;

        case 'Premarital Check':
            payLoad = {
                "speciality": ["Internal Medicine"]
            }
            return payLoad;

        default:
            payLoad = {
                "speciality": ["Internal Medicine"]
            }
            return payLoad;

    }
}