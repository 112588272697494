import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { showNotification } from '../../components/common-notification/CommonNotification';
import { onLoginRequest } from '../../services/auth-services/authServices';
import actions from '../actions';
const { authActions } = actions;


export function* loginRequest() {
    yield takeEvery(authActions.LOGIN_REQUEST, function* ({ payLoad }) {
        try {
            const response = yield onLoginRequest(payLoad);
            if (response && response.status === 200) {
                response.data['savedPassword'] = payLoad.password;
                console.log(response.data, "data")
                yield put({
                    type: authActions.LOGIN_SUCCESS,
                    userInfo: response.data,
                })
                yield showNotification('success', "Success !!", "Logged in successfully")
            }
            // else if((response && response.status === 200 ) && response.data.verified == false){
            //     console.log(response,"false","responseresponse")
            //     navigate("/not-found")
            //     response.data['savedPassword'] = payLoad.password;
            //     yield put({
            //         type: authActions.LOGIN_SUCCESS,
            //         userInfo: response.data,
            //     })
            // }
        } catch(error) {
            if (error && error.response) {
                yield showNotification('error', "Failed !!", "" + error.response.data)
                yield put({ type: actions.authActions.AUTH_ERROR });
            }

        }
    });
}

export default function* rootSaga() {
    yield all([fork(loginRequest)]);
}
