import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  useMediaQuery,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core";
import closeIcon from "../../../../assets/images/close.svg";
import tplanBG_maybe from "../../../../assets/images/tplanBG_maybe.svg";
import listmarker from "../../../../assets/images/listmarker.svg";
import samplecell from "../../../../assets/images/samplecell.svg";
import consultationDoc from "../../../../assets/images/consultationDoc.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios"

import styles from "../styles";
const useStyles = makeStyles((theme) => styles(theme));

const PlanMaybe = ({ close }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const redirecTodmp = (uuid) => {
    window.open(
      process.env.REACT_APP_DMP_SITE_URL + "lab-package-compareprice/" + "LPK-659E9BEB5A76",
      "_blank"
    );
  };

  const [plan, setPlan] = useState([])
  const navigate = useNavigate()

  const BuyNow = (item) => {
    console.log(item, "itemsdsd")
    navigate("/checkout", {
      state: {
        planDetails: item,
        disease: item.diseases_name,
        // uuidDaata : state.uuidDaata
      },
    });
  }
  const DiabetesApi = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}doctallnew/diabetestreatmentplan/plan/list`)
      .then((res) => {
        console.log(res, "err")
        setPlan(res.data.data)
      })
      .catch((err) => {
        console.log(err, "err")
      })
  }
  useEffect(() => {
    DiabetesApi()
  }, [])
  return (
    <Grid container>
      <Grid
        item
        container
        justifyContent="flex-end"
        style={{ height: "124px", paddingTop: "40px", paddingRight: "45px" }}
      >
        <IconButton
          onClick={() => close()}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "#F2F8F9",
          }}
        >
          <img src={closeIcon} alt="close_icon" />
        </IconButton>
      </Grid>
      <Grid item container>
        <Grid
          item
          container
          lg={4}
          md={12}
          className={classes.tPlanBG_maybe}
          justifyContent={matchesMD ? "center" : undefined}
        >
          <img
            src={tplanBG_maybe}
            alt="bg"
            style={{ width: matchesSM ? "330px" : undefined }}
          />
        </Grid>
        <Grid container lg={1}></Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-around"
          //alignItems="center"
          lg={7}
          md={12}
          className={classes.tPlanContent_maybe}
          style={{ marginTop: matchesMD ? "30px" : undefined }}
        >
          <Typography
            style={{
              fontSize: matchesSM ? "18px" : "20px",
              fontWeight: "500",
              lineHeight: matchesSM ? "20px" : "30px",
              color: "#002931",
              fontFamily: "Visuelt Pro",
              marginLeft: matchesMD ? "20px" : undefined,
            }}
          >
            To check whether you are diabetic, purchase our diabetic screening
          </Typography>
          {plan.map((item, index) => {
            return index === 3 && <Grid item container className={classes.screening}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ paddingLeft: "32px", paddingRight: "32px" }}
              >
                <Typography
                  style={{
                    fontSize: matchesSM ? "18px" : "24px",
                    fontWeight: "500",
                    lineHeight: matchesSM ? "20px" : "32px",
                    color: "#002931",
                    fontFamily: "Visuelt Pro",
                  }}
                >
                  Diabetic Screening
                </Typography>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "109px" }}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      color: "#667085",
                      fontFamily: "Visuelt Pro",
                    }}
                  >
                    Amount
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: "500",
                      lineHeight: "32px",
                      color: "#101828",
                      fontFamily: "Visuelt Pro",
                    }}
                  >
                    ₦ {item.price}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.screeningListCont}>
                <ul>
                  <li style={{ listStyle: "none", marginBottom: "16px" }}>
                    <img src={listmarker} alt="marker" />
                    <span className={classes.screeningList}>HBA1C</span>
                  </li>
                  <li style={{ listStyle: "none", marginBottom: "16px" }}>
                    <img src={listmarker} alt="marker" />
                    <span className={classes.screeningList}>
                      Fasting Blood Sugar
                    </span>
                  </li>
                  <li style={{ listStyle: "none", marginBottom: "16px" }}>
                    <img src={listmarker} alt="marker" />
                    <span className={classes.screeningList}>
                      Post Prandial Blood Sugar
                    </span>
                  </li>
                  <li style={{ listStyle: "none", marginBottom: "16px" }}>
                    <img src={listmarker} alt="marker" />
                    <span className={classes.screeningList}>
                      Micro Albumin Spot Urine
                    </span>
                  </li>
                  <li style={{ listStyle: "none", marginBottom: "16px" }}>
                    <img src={listmarker} alt="marker" />
                    <span className={classes.screeningList}>
                      Creatinine Serum
                    </span>
                  </li>
                  <li style={{ listStyle: "none", marginBottom: "16px" }}>
                    <img src={listmarker} alt="marker" />
                    <span className={classes.screeningList}>Urea Serum</span>
                  </li>
                  <li style={{ listStyle: "none", marginBottom: "16px" }}>
                    <img src={listmarker} alt="marker" />
                    <span className={classes.screeningList}>Lipid Profile</span>
                  </li>
                  <li style={{ listStyle: "none", marginBottom: "16px" }}>
                    <img src={listmarker} alt="marker" />
                    <span className={classes.screeningList}>
                      Full Blood Count
                    </span>
                  </li>
                  <li style={{ listStyle: "none", marginBottom: "16px" }}>
                    <img src={listmarker} alt="marker" />
                    <span className={classes.screeningList}>
                      Complete Urine Exam
                    </span>
                  </li>
                </ul>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                style={{
                  paddingLeft: matchesSM ? "20px" : undefined,
                  paddingRight: matchesSM ? "20px" : undefined,
                }}
              >
                <button
                  disableRipple
                  variant="contained"
                  className={classes.buyButton}
                  onClick={() => {BuyNow(item)}}
                >
                  Buy Screening
                </button>
              </Grid>
            </Grid>
          })}

          <Grid item container justifyContent="center">
            <Grid item container alignItems="center" direction="column">
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  color: "#002931",
                  fontFamily: "Visuelt Pro",
                }}
              >
                Benefits
              </Typography>
              <hr
                style={{
                  width: "72px",
                  height: "2px",
                  border: "2px solid #FF8D77",
                  borderRadius: "60px",
                }}
              />
            </Grid>
            <Grid
              item
              container
              justifyContent="space-around"
              style={{ marginTop: "44px" }}
            >
              <Grid
                item
                container
                direction="column"
                style={{ width: "135px" }}
              >
                <img src={samplecell} alt="sample cell" />
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "28px",
                    color: "#002931",
                    fontFamily: "Visuelt Pro",
                    textAlign: "center",
                  }}
                >
                  Free Home Sample Collection{" "}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ width: "135px" }}
              >
                <img src={consultationDoc} alt="consultationDoc" />
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "28px",
                    color: "#002931",
                    fontFamily: "Visuelt Pro",
                    textAlign: "center",
                  }}
                >
                  Free Doctor Consultation
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ width: "135px" }}
              >
                <img src={consultationDoc} alt="consultationDoc" />
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "28px",
                    color: "#002931",
                    fontFamily: "Visuelt Pro",
                    textAlign: "center",
                  }}
                >
                  Free Medical Report
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlanMaybe;
