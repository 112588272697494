import React from "react";
import EDBanner from "../../../assets/images/EDBanner.png";
import EDBanner1 from "../../../assets/images/EDBanner1.png";
import PE from "../../../assets/images/PE.png";
import HL from "../../../assets/images/HairBan.png";
import ED from "../../../assets/images/ED.png";
// import HL from "../../../assets/images/Hairss.png";
import Choose from "../../../assets/images/Choose.svg";
import Complete from "../../../assets/images/Complete.svg";
import Delivered from "../../../assets/images/Delivered.svg";
import EDStress from "../../../assets/images/Stress.png";
import EDCold from "../../../assets/images/Cold.png";
import EDHIV from "../../../assets/images/HIV.png";
import EDCovid from "../../../assets/images/COVID-19.png";
import EDSti from "../../../assets/images/STI.png";
import EDMalaria from "../../../assets/images/Malaria.png";
import Hairloss from "../../../assets/images/Hairloss.png";
import Thyroid from "../../../assets/images/thyroid.png";
import testTube1 from "../../../assets/images/testTube1.svg";
import deliveryService1 from "../../../assets/images/deliveryService1.svg";
import bloodTest from "../../../assets/images/bloodTest.svg";
import specialityDoctor from "../../../assets/images/dWQSpecialityDoctor.svg";
import Article from "../../../assets/images/dWQArticle.svg";
import featuredIcon1 from "../../../assets/images/featuredIcon1.svg";
import featuredIcon2 from "../../../assets/images/featuredIcon2.svg";
import specialistDoctor from "../../../assets/images/specialistDoctorImage.png";
import blog from "../../../assets/images/blog.svg";
import diabetesBG from "../../../assets/images/diabetesBG.svg";
import Daibete from "../../../assets/images/Daibete.png";
import HeartHypertensionBG from "../../../assets/images/HeartHypertension.png";
import { LabPackageOffer } from "../../../assets/images/bloodTestOffer.js";
import specialistOffer from "../../../assets/images/specialistOffer.svg";
import treatmentHands from "../../../assets/images/treatmentHands.svg";
import treatmentCapsule from "../../../assets/images/treatmentCapsule.svg";
import KidneyFailuresBG from "../../../assets/images/KidneyFailures.png";
import WellnessBG from "../../../assets/images/WellnessBG.png";
import WellnessWG from "../../../assets/images/WellnessWG.png";
import WGStress from "../../../assets/images/StressWG.png";
import HormoneWG from "../../../assets/images/HormoneWG.png";
import HeartWG from "../../../assets/images/HeartWG.png";
import Cold from "../../../assets/images/Cold.png";
import HormoneFertilityBG from "../../../assets/images/HormoneFertility.png";
import ProstateBG from "../../../assets/images/ProstateBG.png";
import newspaperTips from "../../../assets/images/newspaperTips.svg";
import homeCareHands from "../../../assets/images/homeCareHands.svg";
import PremaritalCheckBG from "../../../assets/images/PremaritalCheck.png";
import PCOSBG from "../../../assets/images/PCOSBG.png";
import LiverBG from "../../../assets/images/LiverBG.png";
import NutritionBG from "../../../assets/images/NutritionBG.png";
import fitnessBG from "../../../assets/images/fitnessBG.png";
import sportBG from "../../../assets/images/sportBG.png";
import weightlossBG from "../../../assets/images/weightlossBG.png";
import vigorBG from "../../../assets/images/energy-and-vigor.png";
import menopauseBG from "../../../assets/images/menopause.png";
import MB from "../../../assets/images/MB.png";
import TYP from "../../../assets/images/TYP.png";
import HFM from "../../../assets/images/HFM.png";
import homeheart1 from "../../../assets/images1/Heart & Hypertension 0 1.svg";
import homeheart2 from "../../../assets/images1/Heart & Hypertension 1 1.svg";
import homehormone from "../../../assets/images1/Hormone & Fertility 4.svg";
import homemother from "../../../assets/images1/mother and child.svg";
import hometyphoid from "../../../assets/images1/typhoid.svg";
import homediabeti from "../../../assets/images1/woman injecting insuline_Diabetes 1.svg";
import {
  ED_ROUTE_PATH,
  HL_ROUTE_PATH,
  PE_ROUTE_PATH,
  WELLNESS_ROUTE_PATHE,
  WELLNES_ROUTE_PATHE,
  STRESS_MANAGEMENT,
  STRESS_MANAGEMENTW,
  HORMONE_AND_FERTILITYW,
  HEART_AND_HYPERTENSIONW,
  HORMONE_AND_FERTILITY,
  TYPHOID_ROUTE_PATH,
  PROSTATE_ROUTE_PATH,
  PREMARITAL_CHECK,
  HEART_AND_HYPERTENSION,
  MENOPAUSE_ROUTE_PATH,
  PCOS_ROUTE_PATH,
  THYROID_ROUTE_PATH,
  DIABETES_ROUTE_PATH,
  KIDNEY_FAILURES,
  LIVER_ROUTE_PATH,
  STIs_ROUTE_PATH,
  HIV_ROUTE_PATH,
  NUTRITION_ROUTE_PATH,
  FITNESS_AND_NDURANCE,
  SPORTS_ROUTE_PATH,
  WEIGHT_MANAGEMENT,
  ENERGY_AND_VIGOR,
  MALARIA_ROUTE_PATH,
  COVID_ROUTE_PATH,
  COLD_COUGH_OR_FEVER,
  MOTHER_AND_CHILD,
} from "../../../components/common/utils";

const diseases = [
  {
    name: "Erectile Dysfunction",
    routePath: ED_ROUTE_PATH,
    desc: "Don't let erectile dysfunction hold you back. We offer effective solutions to help you regain your sexual health and confidence. Get help today.",
    mainButton: "View Erectile Dysfunction Treatments",
    title:
      "Erectile Dysfunction | Discreet erectile dysfunction treatment online",
    description:
      "Treat different types of erectile dysfunction with doctor-packaged plans. Choose your treatment plan, answer basic questions about your symptoms, completer your treatment order, and have your medicines delivered to you discreetly",
    banner: ED,
    testimonial:
      " “Quick and discreet, reliable as well. Everybody should use doctall” ",
    // symptoms: {
    //   desc: "Symptoms of Erectile dysfunction include There are only a few symptoms to watch out for, listed below/on the right. As we've said, this condition is pretty common, so please do not panic if it happens occasionally. If you experience these symptoms regularly for more than three months, please book an appointment with one of our doctors.",
    //   symptoms: [
    //     "Trouble achieving an erection",
    //     "Difficulty maintaining an erection once achieved",
    //     "Overall reduced interest in sex",
    //   ],
    // },
    treatmentPlan: {
      title: "Erectile Dysfunction Treatment plans",
      subTitle:
        "To find out more about a specific medication, please click on the info icon.",
    },
    process: {
      title: "The process",
      processes: [
        {
          title: "Choose your medication",
          desc: "Choose from the medical options below that is best suited to your needs.",
          icon: Choose,
        },
        {
          title: "Complete assessment",
          desc: "Answer some key medical questions that will help our doctors ensure that the medication is right for you.",
          icon: Complete,
        },
        {
          title: "Delivery",
          desc: "Once your order is complete and approved by a doctor, your medication will be delivered to you in discreet packaging.",
          icon: Delivered,
        },
      ],
    },

    overview: {
      title: "Erectile Dysfunction : What is this about?",
      subtitle1: "Overview",

      desc1: [
        ` Erectile dysfunction (ED) is quite common, typically seen in men over 40.`,
        <br />,
        <br />,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          It is not unusual at any age to occasionally not be able to achieve an
          erection. Various reasons can cause this, from stress, anxiety,
          tiredness, or excessive alcohol intake.
          <br />
          <br /> If it happens often, it is worth speaking to a doctor to
          investigate the cause and discuss potential treatments to resolve the
          problem
        </div>,
      ],
      subtitle2: "Symptoms of Erectile dysfunction include",
      desc2: [
        ` There are only a few symptoms to watch out for, This condition is pretty common, so please do not panic if it happens occasionally. If you experience these symptoms regularly for more than three months the medications above can help. Please book an appointment with one of our doctors if you want to discuss your symptoms further.`,
        <br />,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          ● &nbsp;&nbsp;Trouble achieving an erection
        </div>,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          ● &nbsp;&nbsp;Difficulty maintaining an erection once achieved
        </div>,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          ● &nbsp;&nbsp;Overall reduced interest in sex
        </div>,
        // `●	Trouble achieving an erection`,

        // `●	Difficulty maintaining an erection once achieved`,

        // `●	Overall reduced interest in sex `,
        <br />,
      ],
    },
    wellbeing: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",

      offers: [
        /*  {
          title: "Health Checkup Package",
          icon: bloodTest,
        }, */
        {
          header: "v1",
          title: "Speciality  Doctor",
          icon: specialityDoctor,
        },
        {
          header: "v2",
          title: "Articles & Tips",
          icon: Article,
        },
      ],
      planIntro: [
        {
          header: "v1",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v1",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v2",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you`,
          ],
        },
      ],
    },
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          `You choose the medication you want, answer a health questionnaire and provide your details for us to process your order.`,
        ],
      },
      {
        title: "What happens next?",
        content: [
          `Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.`,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          ` Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.`,
        ],
      },
      {
        title: "What happens then?",
        content: [
          `Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.`,
        ],
      },
      {
        title: "How is the medication delivered?",
        content: [
          `The medication will be delivered to you in discreet packaging using our delivery partner .`,
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          `Delivery time will depend on where you live but on average, will take 3-5 days. As part of the order process, you’ll need to input your delivery address and will be provided a delivery estimate.`,
        ],
      },
    ],
    TreatmentPlanSection: {
      AvailablePlans: [
        {
          title: "Basic Plan",
          price: "₦8,600",
          popular: false,
          benefits: [
            <>
              {" "}
              <span
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#475467",
                }}
              >
                FREE{" "}
              </span>
              <span
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#475467",
                }}
              >
                medical report
              </span>
            </>,

            <>
              <span
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#475467",
                }}
              >
                Sample Type{" "}
              </span>
              <span
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#475467",
                }}
              >
                - Blood, Swab & Urine
              </span>
            </>,
          ],
          Biomarkers: [],
          Investigation_Area: [],
        },
        {
          title: "Advance Plan",
          price: "₦23,000",
          popular: true,
          benefits: [
            <>
              {" "}
              <span
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FB6514",
                }}
              >
                FREE{" "}
              </span>
              <span
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#475467",
                }}
              >
                medical report
              </span>
            </>,

            <>
              <span
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FB6514",
                }}
              >
                FREE MEDICAL OFFICER{" "}
              </span>
              <span
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#475467",
                }}
              >
                follow-up consultation (worth ₦8,600)
              </span>
            </>,

            <>
              <span
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#475467",
                }}
              >
                Sample Type{" "}
              </span>
              <span
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#475467",
                }}
              >
                - Blood, Swab & Urine
              </span>
            </>,
          ],
          Biomarkers: [],
          Investigation_Area: [],
        },
      ],
      PremiumService: {
        title: "PREMIUM CONCIERGE SERVICES ",
        desc: "Find out more about our bespoke premium diagnostic services tailored for your convenience",
      },
    },
    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen1",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Premature Ejaculation",
    routePath: PE_ROUTE_PATH,
    desc: "About 20% of men aged 18-59 experience premature ejaculation. If it occurs frequently, there are many highly effective, clinically-proven solutions available here.",
    mainButton: "View Erectile Dysfunction Treatments",
    banner: PE,
    title:
      "Premature Ejaculation Questionnaire| Discreet premature ejaculation treatment online",
    description:
      "Treat premature ejaculation with doctor-packaged plans. Choose your treatment plan, answer basic questions about your symptoms, completer your treatment order, and have your medicines delivered to you discreetly",
    testimonial:
      "“The problem I had consulted you about is now a thing of the past.  You should be very proud of your medical team and their treatments are the world's best”",
    // symptoms: {
    //   desc: "The key symptom is when a man orgasms and ejaculates semen sooner than he or their partner would like. If you find that more than half of your sexual encounters result in ejaculating sooner than desired, please book an appointment with one of our doctors. They will investigate and offer various treatment options.",
    //   symptoms: [
    //   ],
    // },
    treatmentPlan: {
      title: "Premature Ejaculation Treatment plans",
      subTitle:
        " To find out more about a specific medication, please click on the info icon.",
    },
    process: {
      title: "The process",
      processes: [
        {
          title: "Choose your medication",
          desc: "Choose from the medical options below that is best suited to your needs.",
          icon: Choose,
        },
        {
          title: "Complete assessment",
          desc: "Answer some key medical questions that will help our doctors ensure that the medication is right for you.",
          icon: Complete,
        },
        {
          title: "Delivery",
          desc: "Once your order is complete and approved by a doctor, your medication will be delivered to you in discreet packaging.",
          icon: Delivered,
        },
      ],
    },
    overview: {
      title: "Premature Ejaculation : What is it?",

      subtitle1: "Overview",
      desc1: [
        "Premature ejaculation is experienced by most men occasionally. Research shows that about one in five male adults between 18-59 experience premature ejaculation.",
        <br />,
        <br />,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          Studies also show that the average time for ejaculation during
          intercourse is around five and a half minutes. However, there's no
          exact time scale anyone should be checking against because it depends
          on the couple and what they are after.
        </div>,
      ],
      subtitle2: "Symptoms of Premature Ejaculation",
      desc2: [
        `The key symptom is when a man orgasms and ejaculates semen sooner than he or their partner would like.`,
        <br />,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          If you find that more than half of your sexual encounters result in
          ejaculating sooner than desired. The medications above will help
          address this. Please book an appointment with one of our doctors if
          you want to discuss your symptoms further.
        </div>,
      ],
    },
    wellbeing: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        {
          header: "v1",
          title: "Speciality  Doctor",
          icon: specialityDoctor,
        },
        {
          header: "v2",
          title: "Articles & Tips",
          icon: Article,
        },
      ],
      planIntro: [
        {
          header: "v1",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v1",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v2",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you`,
          ],
        },
      ],
    },
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          `You choose the medication you want, answer a health questionnaire and provide your details for us to process your order.`,
        ],
      },
      {
        title: "What happens next?",
        content: [
          `Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.`,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          ` Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.`,
        ],
      },
      {
        title: "What happens then?",
        content: [
          `Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.`,
        ],
      },
      {
        title: "How is the medication delivered?",
        content: [
          `The medication will be delivered to you in discreet packaging using our delivery partner .`,
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          `Delivery time will depend on where you live but on average, will take 3-5 days. As part of the order process, you’ll need to input your delivery address and will be provided a delivery estimate.`,
        ],
      },
    ],
    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },

  {
    name: "Hair Loss",
    routePath: HL_ROUTE_PATH,
    desc: "Stop hair loss in its tracks. Our clinically-proven solutions can help you regain your confidence. Get a personalized treatment plan today.",
    title: "Hair Loss | Treat hair loss in women and men",
    content:
      "Buy hair loss treatment for men and women. Choose your treatment plan, answer basic questions about your symptoms, completer your treatment order, and have your medicines delivered to you discreetly",
    banner: HL,
    testimonial:
      "“Before starting treatment with Doctall, my hair had gone very thin and my scalp was very visible throughout the top of my head. After few sittings, it was obvious my hair was improving and I have had considerable regrowth. I do not worry about my hair anymore and I am more confident.”",
    // symptoms: {
    //   desc: "If you experience any of these symptoms, please talk to our doctors on advice on how to manage it and potential treatments to restore growth:",
    //   symptoms: [
    //     "Bald spots - either circular or patchy",
    //     "Losing clumps of hairs",
    //     "Sudden loss of hair",
    //     "Full-body hair loss",
    //     "The head also burns and itches",
    //   ],
    // },
    treatmentPlan: {
      title: "Hair Loss Treatment plans",
      subTitle:
        "To find out more about a specific medication, please click on the info icon.",
    },
    process: {
      title: "The process",
      processes: [
        {
          title: "Choose your medication",
          desc: "Choose from the medical options below that is best suited to your needs.",
          icon: Choose,
        },
        {
          title: "Complete assessment",
          desc: "Answer some key medical questions that will help our doctors ensure that the medication is right for you.",
          icon: Complete,
        },
        {
          title: "Delivery",
          desc: "Once your order is complete and approved by a doctor, your medication will be delivered to you in discreet packaging.",
          icon: Delivered,
        },
      ],
    },
    overview: {
      title: "Hair Loss : What is it?",

      subtitle1: "Overview",
      desc1: [
        "Did you know that we lose between 50-100 hairs a day, usually without worrying the slightest about it? ",
        <br />,
        <br />,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          Although that is normal and nothing to be concerned about, some hair
          loss can be more severe and permanent.
          <br />
          <br /> And no, hair loss does not just affect men, and it can affect
          generation after generation of the same family
        </div>,
      ],
      subtitle2: "Symptoms of Hair Loss",
      desc2: [
        `If you experience any of these symptoms, please talk to our doctors on advice on how to manage it and potential treatments to restore growth:`,
        <br />,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          ● &nbsp;&nbsp;Bald spots - either circular or patchy
        </div>,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          ● &nbsp;&nbsp;Losing clumps of hairs
        </div>,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          ● &nbsp;&nbsp;Sudden loss of hair
        </div>,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          ● &nbsp;&nbsp;Full-body hair loss
        </div>,
        <div
          style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}
        >
          ● &nbsp;&nbsp;The head also burns and itches
        </div>,
        // `●	Bald spots - either circular or patchy`,
        // `●	Losing clumps of hairs`,
        // `●	Sudden loss of hair`,
        // `●	Full-body hair loss`,
        // `●	The head also burns and itches`,
        <br />,
      ],
    },
    wellbeing: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        /*  {
          title: "Health Checkup Package",
          icon: bloodTest,
        }, */
        {
          header: "v1",
          title: "Speciality  Doctor",
          icon: specialityDoctor,
        },
        {
          header: "v2",
          title: "Articles & Tips",
          icon: Article,
        },
      ],
      planIntro: [
        {
          header: "v1",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v1",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v2",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you`,
          ],
        },
      ],
    },
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          `You choose the medication you want, answer a health questionnaire and provide your details for us to process your order.`,
        ],
      },
      {
        title: "What happens next?",
        content: [
          `Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.`,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          ` Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.`,
        ],
      },
      {
        title: "What happens then?",
        content: [
          `Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.`,
        ],
      },
      {
        title: "How is the medication delivered?",
        content: [
          `The medication will be delivered to you in discreet packaging using our delivery partner .`,
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          `Delivery time will depend on where you live but on average, will take 3-5 days. As part of the order process, you’ll need to input your delivery address and will be provided a delivery estimate.`,
        ],
      },
    ],
    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },

  {
    name: "Diabetes",
    routePath: DIABETES_ROUTE_PATH,
    desc: "While there are different types, diabetes is characterised by high sugar levels in the blood due to insulin malfunction.It is a chronic disease, but with lifestyle modifications and medications, diabetes can be properly managed.",
    title: "Diabetes | Type 1 and Type 2 Diabetes Management Plan - Doctall",
    content:
      "Diabetes is characterised by high sugar levels in the blood due to insulin malfunction. It is a chronic disease, but with lifestyle modifications and medications, diabetes can be properly managed. Find out what the best treatment for diabetes is and how lifestyle management in diabetes works.",
    banner: Daibete,
    testimonial:
      "“It's so convenient enough knowing fully well how health care system could be cumbersome to access especially here in Nigeria. Moreover the medical personnel I spoke with was very much concerned with my health condition and gave expert advice.”",
    symptoms: {
      desc: "In Nigeria, it is estimated that 1.9 million people have diabetes, with 53% of people affected not diagnosed. So, it is essential to recognise the symptoms.The key symptoms to be aware of are:",
      symptoms: [
        "Increased thirst",
        "Blurred vision",
        "Frequest urination",
        "Fatigue/feeling worn out",
        "Unexplained weight loss",
      ],
    },

    HomeCare: {
      name: "Diabetes",
      desc: "",
      homebanner1: Daibete,
      homebanner2: homediabeti,
      HomeCareList: [
        "Diabetic foot management",
        //  "Diabetic foot management",
      ],
    },

    offerSection: {
      // title: "Overall Wellbeing",
      // desc: [
      //   `Our new offering is a holistic plan to improve your health with recommendations`,
      //   <br />,

      //   `from medical experts, as well as tracking and ongoing clinician support.`,
      // ],
      title: "Know what we can offer you",
      desc: "Choose from highly effective solutions to make your diabetes woprries a thing of the past. Also don’t wait for the signs, take control of your health toay!",

      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { header: "v3", title: "Treatment Plans", icon: treatmentCapsule },
        { header: "v4", title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        //////
        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },

        {
          header: "v3",
          title: "What are treatment plans?",
          icon: featuredIcon1,
          content: [
            `These offer those who have received a diagnosis can access a range of regular tests and check-ins with the relevant doctors to their condition with these treatment plans.`,
            `Each plan will provide you with everything you need to manage your illness and ensure that doctors can help you with any changes that may happen.`,
          ],
        },
        {
          header: "v3",
          title: "Why do I need a treatment plan?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, these plans provide you with peace of mind in helping you manage your illness. With regular interaction with our expert doctors, you can take the steps to manage any symptoms and under-lying conditions.`,
          ],
        },

        {
          header: "v4",
          title: "What is Home Care?",
          icon: featuredIcon1,
          content: [
            `Home Care is a wide range of services - from visits by our doctors and doing tests in the comfort of your home to providing more around-the-clock care for those who are infirm or have mobility issues. Whatever the reason or need, we can come to you.`,
            `and We also offer homecare services. For anyone who needs home visits or more regular health support, we've got caregivers, nurses, and readily available doctors, who can come to you to provide the care you need in the comfort of your home.`,
          ],
        },
        {
          header: "v4",
          title: "Why would I request Home Care?",
          icon: featuredIcon2,
          content: [
            `If you or someone in your family needs medical help but isn’t able to travel or requires more regular home care support, including end-of-life care; we have a team of caregivers, nurses and doctors who can come to you to ensure you are looked after.`,
          ],
        },

        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    treatmentPlan: {
      desc: "To provide you with best treatment plan, we would like to know about your Diabetes, kindly answer the following question;",
      subtitle: "Are you suffering from Diabetes?",
      yes: {},
    },
    faq: [
      {
        title: "Is there a free trial available?",
        content: [
          `Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.`,
        ],
      },
      {
        title: "Can I change my plan later?",
        content: [
          `Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.`,
        ],
      },
      {
        title: "What is your cancellation policy?",
        content: [
          `Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.`,
        ],
      },
      {
        title: "Can other info be added to an invoice?",
        content: [
          `Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.`,
        ],
      },
      {
        title: "How does billing work?",
        content: [
          `Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.`,
        ],
      },
      {
        title: "How do I change my account email?",
        content: [
          `Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.`,
        ],
      },
    ],
  },
  {
    name: "Stress Management",
    routePath: STRESS_MANAGEMENT,
    desc: "There's no exact medical definition of stress - that's because what causes it and how it affects each person can be different. It can generally occur when events or different situations put us under pressure. It also relates to our reaction to said pressure.",
    title: " Stress Management | Lab tests to keep stress levels at a minimum",
    content:
      "Keeping stress levels at a minimum can help you live a more balanced life. Buy lab tests, visit psychologists and purchase medication to manage stress on Doctall",
    banner: EDStress,
    testimonial:
      "“I really appreciate how the conversation goes on.Indeed you are showing much concerned on a patient unlike some did. Thank you very much for your time.”",
    symptoms: {
      desc: "It can also vary how it affects an individual, but here are some key symptoms:",
      symptoms: [
        "Chest pain or fast heart rate",
        "Stomach problems",
        "Headaches or dizziness",
        "Muscle pain or tension",
        "Feeling overwhelmed",
        "Finding it difficult to concentrate",
        "Can't stop worrying",
        "Not able to make decisions",
        "Eating too much or not enough",
        "Not sleeping enough or too much",
        // "Being snappy and irritable with others",
        // "Smoking or drinking more to cope",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { header: "v4", title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },

        {
          header: "v4",
          title: "What is Home Care?",
          icon: featuredIcon1,
          content: [
            `Home Care is a wide range of services - from visits by our doctors and doing tests in the comfort of your home to providing more around-the-clock care for those who are infirm or have mobility issues. Whatever the reason or need, we can come to you.`,
            `and We also offer homecare services. For anyone who needs home visits or more regular health support, we've got caregivers, nurses, and readily available doctors, who can come to you to provide the care you need in the comfort of your home.`,
          ],
        },
        {
          header: "v4",
          title: "Why would I request Home Care?",
          icon: featuredIcon2,
          content: [
            `If you or someone in your family needs medical help but isn’t able to travel or requires more regular home care support, including end-of-life care; we have a team of caregivers, nurses and doctors who can come to you to ensure you are looked after.`,
          ],
        },

        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Heart & Hypertension",
    routePath: HEART_AND_HYPERTENSION,
    desc: 'High blood pressure is also known as hypertension.Blood pressure is the measure of the force created by pushing blood against the walls of your arteries, which are the major vessels moving blood throughout your body.The measure is made up of two numbers. The first is the measure of the pressure when your heart beats or contracts (called "systolic"), the second number (called diastolic) is the pressure when the heart rests between the beats. For example, if your blood pressure is taken - you may be told it is "120 over 80" or 120/80mmHg.If your reading is 140/90mmHg or more, youre diagnosed with high blood pressure. Readings between 120/80mmHg - 140/90mmHg mean that you could develop high blood pressure unless you take action to control it.',
    title:
      "Heart & Hypertension | Check Your Heart Health, Prevent Hypertension",
    content:
      "Hypertension rarely has warning signs or symptoms but it can lead to heart attacks and strokes when left untreated. Know your blood pressure numbers and spot heart hypertension symptoms. Learn more about hypertensive cardiovascular disease and find trusted specialists.",
    banner: HeartHypertensionBG,
    testimonial:
      "“I will recommend this for friends because of it great opportunity to get medical attention. I still commend your efforts. Thanks”",
    symptoms: {
      desc: "Unfortunately, there are very few warning signs or symptoms of high blood pressure. Persistent high blood pressure increases the chances of other severe health conditions such as strokes, heart failure, kidney disease, vascular dementia, and heart attacks:",
      symptoms: [
        "Nosebleeds",
        "Changes to your vision",
        "Early morning headaches",
        "Buzzing in the ears",
        "Fatigue",
        "Vomiting",
        "Chest pain",
        "Muscle tremors",
        "Nausea",
      ],
    },

    HomeCare: {
      name: "Heart & Hypertension",
      desc: "",
      homebanner1: homeheart1,
      homebanner2: homeheart2,
      HomeCareList: [
        "ECG",
        // "kkk",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { header: "v3", title: "Treatment Plans", icon: treatmentHands },
        { header: "v4", title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v3",
          title: "What are treatment plans?",
          icon: featuredIcon1,
          content: [
            `These offer those who have received a diagnosis can access a range of regular tests and check-ins with the relevant doctors to their condition with these treatment plans.`,
            `Each plan will provide you with everything you need to manage your illness and ensure that doctors can help you with any changes that may happen.`,
          ],
        },
        {
          header: "v3",
          title: "Why do I need a treatment plan?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, these plans provide you with peace of mind in helping you manage your illness. With regular interaction with our expert doctors, you can take the steps to manage any symptoms and under-lying conditions.`,
          ],
        },
        {
          header: "v4",
          title: "What is Home Care?",
          icon: featuredIcon1,
          content: [
            `Home Care is a wide range of services - from visits by our doctors and doing tests in the comfort of your home to providing more around-the-clock care for those who are infirm or have mobility issues. Whatever the reason or need, we can come to you.`,
            `and We also offer homecare services. For anyone who needs home visits or more regular health support, we've got caregivers, nurses, and readily available doctors, who can come to you to provide the care you need in the comfort of your home.`,
          ],
        },
        {
          header: "v4",
          title: "Why would I request Home Care?",
          icon: featuredIcon2,
          content: [
            `If you or someone in your family needs medical help but isn’t able to travel or requires more regular home care support, including end-of-life care; we have a team of caregivers, nurses and doctors who can come to you to ensure you are looked after.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },

  {
    name: "Kidney Failures",
    routePath: KIDNEY_FAILURES,
    desc: "Your kidneys are crucial to keeping you healthy as they remove waste and extra fluid from your body - creating urine that you then pass. They also make hormones that help keep your bones strong and manage your blood pressure. ",
    title:
      "Kidney Failures | Tests for Kidney Function & Treatment for Kidney Disease - Doctall",
    content:
      "Your kidneys are crucial to keeping you healthy as they remove waste and extra fluid from your body - creating urine that you then pass out.Kidney failure is a condition in which one or both kidneys can no longer work on their own. Know the stages of kidney failure and its treatment. ",
    banner: KidneyFailuresBG,
    testimonial:
      "“The doctor was able to interact well with me and the doctor just didn't jump into conclusion without some proof of test to know what exactly is wrong”",
    symptoms: {
      desc: "When things aren't quite right, there may not be any initial symptoms and may only come to light when having a blood or urine test for something else. When symptoms do appear, they can include the following:",
      symptoms: [
        "Fatigue",
        "Trouble sleeping",
        "Blood in urine or even foamy urine",
        "Swollen hands, ankles, and feet",
        "Muscle cramps",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { header: "v3", title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these checkup packages for?",
          icon: featuredIcon1,
          content: [
            `Those who want to find out if they have diabetes.`,

            `Those with risk factors - people who are overweight and carry out little or no activity`,
          ],
        },
        {
          header: "v1",
          title: "Why take a health checkup pakage?",
          icon: featuredIcon2,
          content: [
            `Take any checkup health package if you are diabetis and want to monitor your progress`,

            `If you want an insight of your risk factors.`,

            `If you want to change your lifestyle to improve risk factors.`,
          ],
        },
        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v3",
          title: "What are treatment plans?",
          icon: featuredIcon1,
          content: [
            `These offer those who have received a diagnosis can access a range of regular tests and check-ins with the relevant doctors to their condition with these treatment plans.`,
            `Each plan will provide you with everything you need to manage your illness and ensure that doctors can help you with any changes that may happen.`,
          ],
        },
        {
          header: "v3",
          title: "Why do I need a treatment plan?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, these plans provide you with peace of mind in helping you manage your illness. With regular interaction with our expert doctors, you can take the steps to manage any symptoms and under-lying conditions.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },

  {
    name: "Menopause",
    routePath: MENOPAUSE_ROUTE_PATH,
    desc: "Menopause is a normal part of a woman's life that usually happens between 45 and 55. It is where a woman's periods stop - this typically starts with them becoming less frequent over some months or even years before stopping completely. There are also occasions where they stop suddenly.",
    title: "Menopause | Symptoms and when they often begin - Doctall",
    content:
      "Menopause is a normal part of a woman's life that usually happens between 45 and 55. It is where a woman's periods stop - this typically starts with them becoming less frequent over some months or even years before stopping completely.",
    banner: menopauseBG,
    testimonial: "“It's just awesome, a truly good experience”",
    symptoms: {
      desc: "Every woman's symptoms will vary as well as the regularity and severity of them. Here are some of the typical symptoms:",
      symptoms: [
        "Hot flushes",
        "Problems sleeping",
        "Night sweats",
        "Mood swings or becoming irritable",
        "Vaginal dryness or discomfort during sex",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },

  {
    name: "PCOS",
    routePath: PCOS_ROUTE_PATH,
    desc: "This condition is usually characterised by irregular periods, hair growth in strange places, weight gain, thinning hair, or hair loss on the head.Over half of women affected do not have any symptoms and are diagnosed when they have problems getting pregnant.",
    title:
      "Polycystic Ovary Syndrome (PCOS) | Speak to a gynaecologist about PCOS symptoms and get treatment",
    content:
      "A diagnosis of infertility means you have not been able to get pregnant after a year of trying. Although there are many causes of infertility that affect both men and women, in a quarter of cases, the cause is not identified. Learn more about types of infertility in men and women.",
    banner: PCOSBG,
    testimonial:
      "“I love the way I was attended to with love and kindness. May God bless them ”",
    symptoms: {
      desc: "These symptoms will typically be apparent in a person's late teens or early 20s - though over half of women affected do not have any symptoms and are diagnosed when they have problems getting pregnant.The main symptoms are:",
      symptoms: [
        "Irregular periods",
        "Acne or oily skin",
        "Weight gain",
        "More than normal hair growth - typically on the face, back, belly, and buttocks",
        // "Thinning hair or hair loss on the head",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Thyroid",
    desc: "The thyroid is a small gland in your neck that produces hormones that influence most of your metabolic process and your body temperature and heart rate. So it's an essential part of your body. There are a variety of illnesses that can happen when it isn't functioning as planned.",
    title: "Thyroid Problems & how to treat them - Doctall",
    content:
      "The thyroid is a small gland in your neck that produces hormones that influence most of your metabolic process, your body temperature and heart rate. Take Thyroid function tests on Doctall and be aware of your thyroid health ",
    banner: Thyroid,
    routePath: THYROID_ROUTE_PATH,
    testimonial:
      "“Because the kind of response I got... I am not sure if I could get it from the general hospital i do attend”",
    symptoms: {
      desc: "Your symptoms will vary depending on the exact nature of the problem, but these are the fundamental changes to be aware of:",
      symptoms: [
        "Feeling tired ",
        "Gaining or losing significant weight",
        "Having dry and coarse hair",
        // "Intolerance to cold temperatures or feeling sensitive to heat",
        "Muscle weakness and tremors",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        //////
        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },

        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Wellness",
    routePath: WELLNESS_ROUTE_PATHE,
    desc: "How are you feeling? We all have times when we wonder if everything is ok with our health - and as men, we're famous for not checking things out until it's too late. So we offer a series of packages that can check out a wide range of issues for any man that wants to take control of their health.",
    banner: WellnessBG,
    testimonial: "“It's convenient and reliable.... Thank you so much.”",

    Whatdoesitcover: {
      desc: "We'll do tests to make sure that all your main body organs are working properly and tests to check out your hormone levels. Other checks below can also be included:",
      symptoms: [
        "Male fertility tests",
        "Prostate check",
        "Diabetes test",
        "Blood health tests",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Hormone & Fertility",
    routePath: HORMONE_AND_FERTILITY,
    desc: "Are you concerned that your hormone levels may be causing some other health condition or illness? Do you want to start a family, but you and your partner are experiencing problems conceiving? These aren't just womens issues. Let us help you get some clarity and reassurance with these health packages.",
    banner: HFM,
    title: "Hormones and Fertility | Understand male hormones and fertility",
    content:
      "Men who want to start a family but are experiencing problems with their partners can purchase this test. Take tests to understand hormonal imbalance and how it affects male fertility. Treat symptoms like gynecomastia, loss of libido, erectile dysfunction, mood changes and much more",
    testimonial:
      "“The platform is very easy to operate and navigate through. I love this.”",
    symptoms: {
      desc: "We'll run a panel of tests to get details of your hormone levels. Regardless of which package you choose, you'll get answers to your concerns:",
      symptoms: [
        "Gynecomastia",
        "Loss of libido",
        "Erectile dysfunction",
        "Loss of muscle",
        "Mood changes",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Premarital Check",
    routePath: PREMARITAL_CHECK,
    desc: "If you are getting married, you may want to make sure that you and your partner are aware of any potential health problems that both of you may not already know of.This package of tests provides reassurance and ensures that you are both aware of any potential illnesses that could also impact plans for children. This helps you plan accordingly and have options for your future together.",
    banner: PremaritalCheckBG,
    title: "Premarital Screenings | Get Premarital Tests Near You",
    content:
      "The importance of premarital screenings has become more obvious as they help identify a wide range of conditions such as genetic disorders, blood transmitted, and infectious diseases. Get affordable premarital tests in Nigeria, genotype screening, HIV and other sexual transmitted diseases tests and much more.",
    testimonial:
      "“Very cheap and affordable,friendly and easy to express yourself”",
    Whatdoesitcover: {
      desc: "The screening covers common genetic blood disorders and infectious diseases:",
      symptoms: [
        "Blood type and health",
        "Inflammation",
        "Diabetes",
        "Sexually transmitted diseases",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },

  {
    name: "Malaria",
    routePath: MALARIA_ROUTE_PATH,
    desc: "Malaria is a potentially life-threatening disease spread to humans by mosquito bites. A single mosquito bite is enough to become infected.The good news is that it is preventable and curable, particularly if treated quickly once symptoms appear.",
    title: "Malaria | Malaria Symptoms and treatment with doctors on Doctall",
    content:
      "A single mosquito bite is enough to become infected with malaria.The good news is that it is preventable and curable, particularly if treated quickly once symptoms appear. Treat malaria symptoms quickly on Doctall. ",
    banner: EDMalaria,
    testimonial:
      "“This channel is lovely especially to the poor masses. God bless the CEO and the entire team.”",
    symptoms: {
      desc: "Symptoms typically occur between 7 and 18 days after infection and can sometimes appear relatively mild. The key ones are:",
      symptoms: [
        "A temperature of 38°C or more",
        "Vomiting",
        "Feeling hot yet shivery",
        "Headaches",
        "Diarrhoea ",
        "Muscle pains",
      ],
    },

    HomeCare: {
      name: "Malaria",
      desc: "",
      homebanner1: EDMalaria,
      homebanner2: homehormone,
      HomeCareList: ["iv fluids", "iv antibiotics"],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },

        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Prostate",
    routePath: PROSTATE_ROUTE_PATH,
    desc: "The prostate is a small walnut-shaped gland found only in men. It creates a thick fluid that mixes with sperm to create semen Prostate cancer is one of the most common cancers in men and rarely has any symptoms until it has grown large enough to pressure the urethra that carries urine from the bladder. Some types of prostate cancer grow slowly while others progress aggressively and may require radiation.",
    title: "Prostate | Understand Prostate Function, Diseases and Tests",
    content:
      "Prostate cancer is one of the most common cancers in men and rarely has any symptoms until it has grown large enough to pressure the urethra that carries urine from the bladder. Read more to understand prostate cancer symptoms and treatment plans.",
    banner: ProstateBG,
    testimonial:
      "“I had a great time, it was professional enough. A great service overall.”",
    symptoms: {
      desc: "Unfortunately, symptoms do not usually appear in the early stages until the enlarged prostate starts to push on the tube carrying urine from the bladder. Once at this stage, symptoms can include the following:",
      symptoms: [
        "Requiring to pee more regularly, especially at night",
        "Hesitancy in starting to pee",
        "Taking a long time to start urinating",
        "Sometimes, blood in the urine or semen",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "STIs",
    routePath: STIs_ROUTE_PATH,
    desc: "More than 30 different viruses, parasites, and bacteria spread through sexual activity. These sexually transmitted infections (STIs) have specific symptoms and potential treatment routes.Though specific symptoms for each STI vary, the majority of them affect the genitals of both genders.",
    title: "STIs | Get STI Tests and Treatment From Trusted Doctors Online",
    content:
      "More than 30 different viruses, parasites, and bacteria spread through sexual activity. These sexually transmitted infections (STIs) have specific symptoms and potential treatment routes. Though specific symptoms for each STI vary, the majority of them affect the genitals of both genders. Speak to a doctor on Doctall if you feel symptoms.",
    banner: EDSti,
    testimonial: "“Very effective means of medical consultation”",
    symptoms: {
      desc: "Though specific symptoms for each STI vary - the majority of them affect the genitals of both genders. So look out for these:",
      symptoms: [
        "A rash, blister, or sores around the genitals or anus",
        "An unusual discharge from the vagina, penis, or anus",
        "Unusual vaginal bleeding",
        "Lumps or skin growths around the genitals or bottom (anus)",
        // "Warts around your genitals or anus",
        // "Warts in your mouth or throat, but this is very rare",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },

        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "HIV",
    routePath: HIV_ROUTE_PATH,
    desc: "HIV means Human Immunodeficiency Virus. This means that the virus weakens the immune system and compromises its ability to fight other illnesses.With effective treatment through daily medication, most people live a normal life and can not pass it on to other people.",
    title: "HIV | Discreet treatment and disease management for HIV - Doctall",
    content:
      "HIV means Human Immunodeficiency Virus. This means that the virus weakens the immune system and compromises its ability to fight other illnesses.With effective treatment through daily medication, most people live a normal life and can not pass it on to other people.",
    banner: EDHIV,
    testimonial:
      "“Quick and discreet, reliable as well. Everybody should use doctall”",
    symptoms: {
      desc: "Usually, some initial symptoms appear within six weeks of infection but not always. These symptoms are like a short flu-like illness, and the most common symptoms are:",
      symptoms: ["Fever", "Sore throat", "A red rash over the body"],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },

        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Covid-19",
    routePath: COVID_ROUTE_PATH,
    desc: "Coronavirus is an acute infectious illness caused by the Covid-19 (SARS-CoV-2) virus.Initially identified in China in late December 2019, the disease is capable of causing mild and severe symptoms and, in some cases, death, especially in aged people or those with underlying health conditions.",
    title: "COVID-19 | Purchase COVID-19 testing and treatment - Doctall",
    content:
      "Coronavirus is an acute infectious illness caused by the Covid-19 (SARS-CoV-2) virus. The disease is capable of causing mild and severe symptoms and, in some cases, death, especially in aged people or those with underlying health conditions. Speak to a doctor on Doctall if you feel symptoms.",
    banner: EDCovid,
    testimonial:
      "“The professional way attention is given. I highly recommend family and friends to use this”",
    symptoms: {
      desc: "Some  mild symptoms of include of Covid-19 include:",
      symptoms: [
        "Fever",
        "Cough",
        "Body aches",
        "Fatigue",
        "Loss of taste and smell",
        "Respiratory Failure",
        "Pneumonia",
        "Acute Respiratory Distress Syndrome",
        "Acute Cardiac Injury",
        "Acute Liver Injury",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v2", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Liver",
    routePath: LIVER_ROUTE_PATH,
    desc: "Your liver is a large body organ that sits on the right side of your belly. Its main job is to help you digest food and cleanse your body of toxic substances. Some liver problems can be genetic, while others are caused by issues such as alcohol usage, being overweight, or some viruses.",
    title:
      "Liver | Liver Function Tests, Pareameters & Interpretation - Doctall",
    content:
      "Your liver is a large body organ that sits on the right side of your belly. Its main job is to help you digest food and cleanse your body of toxic substances. Some liver problems can be genetic, while others are caused by alcohol usage, being overweight, or viruses. See A gastroenterologyist and get treatment.",
    banner: LiverBG,
    testimonial: "“I love this because they value people's lives and health”",
    symptoms: {
      desc: "The symptoms may vary depending on the type of liver problem and the cause of them, but here are some of the most common:",
      symptoms: [
        "Jaundice (skin and eyes that appear yellowish)",
        "Swelling in the legs and ankles",
        "Abdominal pain and swelling, itchy skin",
        "Dark urine colour ",
        "Chronic fatigue",
        // "Tendency to bruise easily",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Nutrition",
    routePath: NUTRITION_ROUTE_PATH,
    desc: "It is essential to nourish your body with food and drinks to stay healthy and function properly.Nutrition is crucial to your survival because it supplies your body with the macro and micronutrients that fuel your body and keeps you fit and strong.",
    title: "Nutrition | Nutrients & Nutrition Tests, Facts & Diet Plans",
    content:
      "Nutrition is crucial to your survival because it supplies your body with the macro and micronutrients that fuel your body and keeps you fit and strong. Get the right nutrition help from trained doctors and nutritionist on Doctall.",
    banner: NutritionBG,
    testimonial: "“Very helpful and nice Informative also”",
    symptoms: {
      desc: "Some of the nourishing foods you should add to your diet include:",
      symptoms: [
        "Reduced appetite",
        "Poor concentration",
        "Wounds taking a long time to heal",
        "Feeling tired all the time",
        // "Eggyolk",
        // "Garlic",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Fitness & Endurance",
    routePath: FITNESS_AND_NDURANCE,
    desc: "Your endurance level is the rate at which your lungs, heart, and muscles work together when you are exercising.It is essential to know your endurance level and how your cardiorespiratory system functions because it indicates how fit you are.",
    title: "Fitness & Endurance | Buy Fitness Tests Online & Speak to a Doctor",
    content:
      "Your endurance level is the rate at which your lungs, heart, and muscles work together when you are exercising. It is essential to know your endurance level and how your cardiorespiratory system functions because it indicates how fit you are. Order lab tests to your endurance levels from trusted labs online",
    banner: fitnessBG,
    testimonial: "“The professionalism is top notch”",
    symptoms: {
      desc: "The tests you’d get include:",
      symptoms: [
        "Lack of energy ",
        "Constantly tired or exhausted",
        "Fainting or loss of consciousness",
        "Rapid heartbeat dizziness",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },

        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Sport",
    routePath: SPORTS_ROUTE_PATH,
    desc: "Staying active with sporting activities has very many health benefits for your body.Engaging in sports makes you physically fit and healthy. It helps to mentally and physically challenge you positively.",
    title: "Sport | Stay Active and Healthy - Doctall",
    content:
      "Staying active with sporting activities has very many health benefits for your body. Engaging in sports makes you physically fit and healthy. It helps to mentally and physically challenge you positively. Get the right help to stay active",
    banner: sportBG,
    testimonial: "“Your customer service is top notch”",
    symptoms: {
      desc: "Some fun sports you can easily engage in include:",
      symptoms: [
        "Lack of energy",
        "Irregular heart rate",
        "General aches and pains",
        "Mild muscle or joint soreness",
        "Feling washed-out",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },

  {
    name: "Weight Management",
    routePath: WEIGHT_MANAGEMENT,
    desc: "These are the deliberate techniques and psychological processes taken to attain and maintain a certain weight. One of the critical factors of weight management is adjustment and change in lifestyle.Eating healthy and exercising are very important to achieving and maintaining the body goal you desire.",
    title: "Weight Management | LIfestyle and weight management Programs",
    content:
      "One of the critical factors of weight management is adjustment and change in lifestyle. Eating healthy and exercising are very important to achieving and maintaining the body goal you desire. Speak to a doctor on Doctall about your weight management",
    banner: weightlossBG,
    testimonial: "“Easy to help people with medical drugs”",
    symptoms: {
      desc: "Some techniques of weight management include:",
      symptoms: [
        "Exercise regularly",
        "Drink enough water",
        "Eat whole foods",
        "Plan meals ahead",
        "Think long term",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },

        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },

  {
    name: "Energy & Vigor",
    routePath: ENERGY_AND_VIGOR,
    desc: "Doctall provides tests tailored to check your energy levels to help you alleviate stress and manage the risk of health challenges such as diabetes, cancer, and heart diseases.The package also provides you with energy-boosting vitamins that you need to stay strong, full of energy, and ready to go.",
    title: "Energy & Vigor | Tailored Tests to Check Your Energy Levels",
    content:
      "Doctall provides tests tailored to check your energy levels to help you alleviate stress and manage the risk of health challenges such as diabetes, cancer, and heart diseases. The package also provides you with energy-boosting vitamins that you need to stay strong, full of energy, and ready to go.",
    banner: vigorBG,
    testimonial: "“Professional, punctual. 😊”",
    symptoms: {
      desc: "Some the tests in the package include:",
      symptoms: [
        "Full blood count test",
        "Kidney function test",
        "Liver function test",
        "Estrogen and Testorone test",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },

  {
    name: "Wellness",
    routePath: WELLNES_ROUTE_PATHE,
    desc: "How are you feeling today? We all have times when we wonder if everything is ok with our health, so we offer a series of packages that can check out a wide range of issues for any woman who wants to take control of their health.",
    banner: WellnessWG,
    testimonial: "“They are so detailed and a show of professionalism”",
    Whatdoesitcover: {
      desc: "We'll do tests to make sure that all your main body organs are working properly and tests to check out your hormone levels.These other checks below can also be included:",
      symptoms: [
        "Polycystic Ovary Syndrome (PCOS) test",
        "Diabetes test",
        "Hair Loss tests",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Stress Management",
    routePath: STRESS_MANAGEMENTW,
    desc: "Keeping stress levels at a minimum can help you live a more balanced life. Stress is an automatic physical, mental and emotional response to a challenging event. While stress is a normal part of everyone's life, managing stress effectively is key to living a healthy life.",
    title: "Stress Management | Lab tests to keep stress levels at a minimum",
    content:
      "Keeping stress levels at a minimum can help you live a more balanced life. Buy lab tests, visit psychologists and purchase medication to manage stress on Doctall",
    banner: WGStress,
    testimonial:
      "“I really appreciate how the conversation goes on. Indeed you are showing much concerned on a patient unlike some did. Thank you very much for your time.”",
    symptoms: {
      desc: "It can also vary how it affects an individual, but here are some key symptoms:",
      symptoms: [
        "Chest pain or fast heart rate",
        "Stomach problems",
        "Headaches or dizziness",
        "Muscle pain or tension",
        "Feeling overwhelmed",
        "Finding it difficult to concentrate",
        "Can't stop worrying",
        "Not able to make decisions",
        "Eating too much or not enough",
        "Not sleeping enough or too much",
        // "Being snappy and irritable with others",
        // "Smoking or drinking more to cope",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { header: "v4", title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },

        {
          header: "v4",
          title: "What is Home Care?",
          icon: featuredIcon1,
          content: [
            `Home Care is a wide range of services - from visits by our doctors and doing tests in the comfort of your home to providing more around-the-clock care for those who are infirm or have mobility issues. Whatever the reason or need, we can come to you.`,
            `and We also offer homecare services. For anyone who needs home visits or more regular health support, we've got caregivers, nurses, and readily available doctors, who can come to you to provide the care you need in the comfort of your home.`,
          ],
        },
        {
          header: "v4",
          title: "Why would I request Home Care?",
          icon: featuredIcon2,
          content: [
            `If you or someone in your family needs medical help but isn’t able to travel or requires more regular home care support, including end-of-life care; we have a team of caregivers, nurses and doctors who can come to you to ensure you are looked after.`,
          ],
        },

        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Hormone & Fertility",
    routePath: HORMONE_AND_FERTILITYW,
    desc: "Are you concerned that your hormone levels may be causing some other health condition or illness? Do you want to start a family, but you and your partner are experiencing problems conceiving? Let us help you get some clarity and reassurance with these health packages.",
    banner: HormoneWG,
    testimonial: "“Very good experience”",
    title: "Hormones and Fertility | Understand female hormones and fertility",
    content:
      "Women who want to start a family but are experiencing problems with their partners can purchase this test. Take tests to understand hormonal imbalance and how it affects male fertility. Treat symptoms like gynecomastia, loss of libido, erectile dysfunction, mood changes and much more",
    symptoms: {
      desc: "We'll run a panel of tests to get details of your hormone levels. Regardless of which package you choose, you'll get answers to your concerns:",
      symptoms: [
        "Heavy or irregular periods",
        "Missed periods, a stopped period, or a frequent period",
        // "Excessive hair on the face, chin, or other parts of the body",
        "Acne on the face, chest, or upper back",
        "Vaginal dryness",
        "Night sweats",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Heart & Hypertension",
    routePath: HEART_AND_HYPERTENSIONW,
    desc: "High blood pressure or hypertension rarely has warning signs or symptoms, but it can lead to heart attacks and strokes when left untreated. Your doctor will recommend treatments to bring your blood pressure down depending on your health history.",
    title:
      "Heart & Hypertension | Check Your Heart Health, Prevent Hypertension",
    content:
      "Hypertension rarely has warning signs or symptoms but it can lead to heart attacks and strokes when left untreated. Know your blood pressure numbers and spot heart hypertension symptoms. Learn more about hypertensive cardiovascular disease and find trusted specialists.",
    banner: HeartWG,
    testimonial:
      "“I will recommend this for friends because of it great opportunity to get medical attention. I still commend your efforts. Thanks”",
    symptoms: {
      desc: "80% of sexually transmitted diseases are asymptomatic (do not display any symptoms), however, if symptoms do occur, they are likely to present themselves within two weeks of disease. Symptoms of sexually transmitted diseases may include:",
      symptoms: [
        "Increased thirst",
        "Blurred vision",
        "Frequest urination",
        "Vaginal infections",
        "Extreme hunger",
        "Irratability",
        "Extreme hunger",
        "Unexplained weight loss",
        "Fatigue",
        "Slow-healing sores",
      ],
    },

    HomeCare: {
      name: "Heart & Hypertension",
      desc: "",
      homebanner1: HeartWG,
      homebanner2: homeheart2,
      HomeCareList: [
        "ECG",
        // "kkk",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { header: "v3", title: "Treatment Plans", icon: treatmentHands },
        { header: "v4", title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v3",
          title: "What are treatment plans?",
          icon: featuredIcon1,
          content: [
            `These offer those who have received a diagnosis can access a range of regular tests and check-ins with the relevant doctors to their condition with these treatment plans.`,
            `Each plan will provide you with everything you need to manage your illness and ensure that doctors can help you with any changes that may happen.`,
          ],
        },
        {
          header: "v3",
          title: "Why do I need a treatment plan?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, these plans provide you with peace of mind in helping you manage your illness. With regular interaction with our expert doctors, you can take the steps to manage any symptoms and under-lying conditions.`,
          ],
        },
        {
          header: "v4",
          title: "What is Home Care?",
          icon: featuredIcon1,
          content: [
            `Home Care is a wide range of services - from visits by our doctors and doing tests in the comfort of your home to providing more around-the-clock care for those who are infirm or have mobility issues. Whatever the reason or need, we can come to you.`,
            `and We also offer homecare services. For anyone who needs home visits or more regular health support, we've got caregivers, nurses, and readily available doctors, who can come to you to provide the care you need in the comfort of your home.`,
          ],
        },
        {
          header: "v4",
          title: "Why would I request Home Care?",
          icon: featuredIcon2,
          content: [
            `If you or someone in your family needs medical help but isn’t able to travel or requires more regular home care support, including end-of-life care; we have a team of caregivers, nurses and doctors who can come to you to ensure you are looked after.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },

  {
    name: "Cold, cough or fever",
    routePath: COLD_COUGH_OR_FEVER,
    desc: "The common cold is caused by a virus that enters your body through the nose or mouth.It affects your nose and throat, and while it's not life-threatening, the common cold can make you feel dreadful for up to 10 days.",
    title:
      "Cold, cough or fever | Get Treatment for Cough and Cold From Trusted Doctors Online",
    content:
      "The common cold is caused by a virus that enters your body through the nose or mouth. It affects your nose and throat, and while it's not life-threatening, the common cold can make you feel dreadful for up to 10 days.Speak to a doctor on Doctall if you feel symptoms.",
    banner: Cold,
    testimonial:
      "“Very cheap and affordable,friendly and easy to express yourself”",
    symptoms: {
      desc: "Some of its symptoms include:",
      symptoms: [
        "Sneezing",
        "Sore throat",
        "Runny or stuffy nose",
        "Cough",
        "Slight fever",
        "Faint body aches or a mild headache",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        // { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Typhoid",
    routePath: TYPHOID_ROUTE_PATH,
    desc: "Typhoid is a life-threatening bacterial infection spread through contaminated food and water. It is highly contagious and can pass from person to person very quickly.Typhoid is treatable with antibiotics and medical care.",
    title: "Typhoid | Get Typhoid Treatment From Trusted Doctors Online",
    content:
      "Typhoid is a life-threatening bacterial infection spread through contaminated food and water. It is highly contagious and can pass from person to person very quickly. Typhoid is treatable with antibiotics and medical care. Speak to a doctor if you feel typhoid symptoms.",
    banner: TYP,
    testimonial: "“Very good experience”",
    symptoms: {
      desc: "These symptoms can develop gradually over a number of days:",
      symptoms: [
        "Cough and headache",
        "Constipation or diarrhoea",
        "Stomach ache",
        "Body aches and pains",
        "Some people can get a rash",
      ],
    },

    HomeCare: {
      name: "Typhoid",
      desc: "",
      homebanner1: ED,
      homebanner2: hometyphoid,
      HomeCareList: ["iv fluids", "iv antibiotics"],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   header: "v1",
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        { title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v1",
          title: "Who are these check-up packages for?",
          icon: featuredIcon1,
          content: [
            `If you are worried that you may have XXX, these packages provide answers.`,

            `If you have any of the known risk factors.`,
          ],
        },
        {
          header: "v1",
          title: "Why choose these health check-up packages?",
          icon: featuredIcon2,
          content: [
            `Peace of mind if you are concerned that you might suffer with/from XXX.`,

            `A way to monitor your illness if you have already been diagnosed with XXX.`,

            `A start to changing your lifestyle if you have any of the risk factors.`,
          ],
        },

        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },
        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
      // HomeCare: [
      //   {
      //     HomeCareTitle: "For this particular disease, we can provide these home service for you;",
      //     HomeCareList: [
      //       `iv fluids`,
      //       `iv antibiotics`,
      //     ]
      //   }
      // ],
      // HomeCare: [
      //   "iv fluids",
      //   "iv antibiotics",
      // ],
    },
    // HomeCare : {
    //   title: "For this particular disease, we can provide these home service for you;",
    //   HomeCare: [
    //     "iv fluids",
    //     "iv antibiotics",
    //   ],
    // },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
  {
    name: "Mother and Child",
    routePath: MOTHER_AND_CHILD,
    desc: "Doctall offer tests to  keep you aware of what is happening with your child's health as well as your own body as a new mother. ",
    title: "What You Need to Know About Mother & Child Health - Doctall",
    content:
      "Mother & Child Health are important. Doctall offer tests to keep you aware of what is happening with your child's health as well as your own body as a new mother. Speak to paediatricians and gynaecologists on Doctall.",
    banner: MB,
    testimonial:
      "“They know their work and have mastered how to take care of a patient”",
    symptoms: {
      desc: "Some signs you can look out for :",
      symptoms: [
        // "Vaginal Pain" ,
        "Breast Pain",
        "Infection",
        //"Urinary Incontinence" ,
        // "Pelvic Organ Prolapse" ,
        //"Exhaustion" ,
        "Jaundice",
        //"Colic" ,
        //"Abdominal Distension" ,
        "Vomiting",
        //"Coughing" ,
        // "Anaemia" ,
        "Fever",
        "Skin Problems",
        "Ear Infections",
        //"Oral Thrush" ,
        "Cold and Flu",
        "Diarrhoea",
      ],
    },

    HomeCare: {
      name: "Mother and Child",
      desc: "",
      homebanner1: WGStress,
      homebanner2: homemother,
      HomeCareList: [
        "vaccinations Post-operative C-section care",
        "Infant care",
        "Assessment of both the mother and the baby",
        "Breastfeeding support",
      ],
    },

    offerSection: {
      title: "Overall Wellbeing",
      desc: "Our new offering is a holistic plan to improve your health with recommendations from medical experts, as well as tracking and ongoing clinician support.",
      offers: [
        // {
        //   title: "Lab Test Package",
        //   icon: bloodTest,
        // },
        { header: "v2", title: "Speciality Doctor", icon: specialistOffer },
        // { title: "Treatment Plans", icon: treatmentHands },
        { header: "v4", title: "Home Care", icon: homeCareHands },
        { header: "v5", title: "Articles & Tips", icon: newspaperTips },
      ],
      planIntro: [
        {
          header: "v2",
          title: "What is a Speciality Doctor?",
          icon: featuredIcon1,
          content: [
            `A speciality doctor is an experienced doctor who has trained in a specific area of the body and medicine. They will have several years experience in that area so that you get the best support.`,
          ],
        },
        {
          header: "v2",
          title: "Why do I need a Speciality Doctor?",
          icon: featuredIcon2,
          content: [
            `If you have received a diagnosis, it is normal for you to have a follow-up appointment with a doctor who is an expert in that disease or area of the body. This ensures that you get the best guidance and plan of action to address your health issues.`,
          ],
        },

        {
          header: "v4",
          title: "What is Home Care?",
          icon: featuredIcon1,
          content: [
            `Home Care is a wide range of services - from visits by our doctors and doing tests in the comfort of your home to providing more around-the-clock care for those who are infirm or have mobility issues. Whatever the reason or need, we can come to you.`,
            `and We also offer homecare services. For anyone who needs home visits or more regular health support, we've got caregivers, nurses, and readily available doctors, who can come to you to provide the care you need in the comfort of your home.`,
          ],
        },
        {
          header: "v4",
          title: "Why would I request Home Care?",
          icon: featuredIcon2,
          content: [
            `If you or someone in your family needs medical help but isn’t able to travel or requires more regular home care support, including end-of-life care; we have a team of caregivers, nurses and doctors who can come to you to ensure you are looked after.`,
          ],
        },

        {
          header: "v5",
          title: "What are Articles & Tips?",
          icon: featuredIcon1,
          content: [
            `Our team of medical experts have created articles and advice on symptoms, diagnosis and managing this disease. Please see below a selection of helpful information for you.`,
          ],
        },
      ],
    },
    labpackages: ["Basic Plan", "Intermediate Plan", "Ultimate Plan"],
    appointment: "Feel unsure about what to do? Put a call across to us",
    purchase: "Looking out for diagnostic test to purchase?",
    faq: [
      {
        title: "How does it work?",
        content: [
          `We aim to simplify the process of getting the medications you are after by outlining your options and then processing the order online for ease and convenience. `,
          "You choose the medication you want, answer a health questionnaire and provide your details for us to process your order. ",
        ],
      },
      {
        title: "What happens next?",
        content: [
          `
          Once we’ve received your order and your health questionnaire, all your information is reviewed by a doctor to make sure that the medication is right for you and they then approve the order.
          `,
        ],
      },
      {
        title: "What should I do if it says I need to speak with a doctor?",
        content: [
          "Depending on your answers in the health questionnaire, you may get told you need to speak with a doctor to complete the order. To do this, please book an appointment here.",
        ],
      },
      {
        title: "What happens then?",
        content: [
          "Once the medication has been approved and prescribed by the doctor, we will then arrange for delivery of the medication to the address you’ve provided.",
        ],
      },
      {
        title: "How is the medication delivered?",

        content: [
          "The medication will be delivered to you in discreet packaging using our delivery partner .",
        ],
      },
      {
        title: "How long will it take for me to receive the medication?",
        content: [
          "As part of the order process, you’ll need to input your delivery address. Delivery time will depend on where you live but on average, will take 3-5 days.",
        ],
      },
    ],

    specialistDoctors: [
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
      {
        Image: specialistDoctor,
        name: "Dr John Stephen",
        desig: "MBBS, MD",
        category: "Urologist",
        location: "Nigeria",
        experience: "7 Years",
        language: "English, Hausa, Yoruba",
        availableDate: " 21, Nov, 2020",
        availableTime: "10:00am - 10: 20pm",
      },
    ],

    blogs: [
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "DoctallLiving",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
      {
        title: "Doctallpedia",
        heading: "How stress affects your body",
        desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
        image: blog,
        link: "",
      },
    ],
  },
];

export default diseases;
