import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import axios from "axios";
import { Helmet, helmet } from 'react-helmet';
import { store, persistor, history } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import setupAxios from './setupAxios'
import { getFirebaseInstance } from './firebase.js';
import { hydrate, render } from "react-dom";


// const helmet = Helmet.renderStatic(); 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App store={store} persistor={persistor} history={history} />, rootElement);
} else {
  render(<App store={store} persistor={persistor} history={history} />, rootElement);
}

axios.interceptors.request.use(
    request => {
        return request;
    },
    error => {
        return Promise.reject(error);
    },
);

setupAxios(axios)

getFirebaseInstance();

ReactDOM.render(<App store={store} persistor={persistor} history={history} />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
