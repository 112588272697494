import React from 'react'
import {Grid, useMediaQuery, useTheme} from "@material-ui/core";
import team1 from "../../assets/images/team1.png";
import team2 from "../../assets/images/team2.png"
import team3 from "../../assets/images/team3.png"
import team4 from "../../assets/images/team4.png"
import team5 from "../../assets/images/team5.png";
import './AboutUs.scss'

function Team() {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        <>
            <div className='team_container'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={6} s>
                    <div className='team_text_container'>
                        <p className='join_team'>Join our team</p>
                        <h1 className='team_heading'>Join the Doctall team</h1>
                        <p className="para">Doctall is home to committed healthcare professionals, creatives and technical support staff who are driven daily by the vision of a healthier society.</p>
                        <p className="para">
                            Every day, our team works with dedication and strong passion to create a future where quality healthcare is accessible and available to all.</p>
                            <p className="para">
                            Email your CV to us at <span style={{color:"#FF5C3C", fontWeight:600}}><a href='mailto:careers@doctall.com'>careers@doctall.com</a></span></p>
                         </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                
                        <Grid container spacing={0}>
                            <Grid xs={6} sm={6} md={6}>
                            <img src={team1} alt="Girl in a jacket" style={{float:"right", paddingTop:"85px",paddingRight:"5px", paddingLeft:"5px"}} />
                            </Grid>
                            <Grid xs={6} sm={6} md={6}>
                            <img src={team2} alt="Girl in a jacket" style={{padding:"5px"}} />
                            </Grid>
                        </Grid>
  
                        <Grid container spacing={0}>
                        <Grid  md={1}></Grid>
                        <Grid  xs={4} sm={4} md={4}>
                            <img src={team3} alt="Girl in a jacket" style={{float:"right", padding:"5px"}} />
                         </Grid>
                            <Grid  xs={3} sm={3} md={3}>
                            <img src={team4} alt="Girl in a jacket" className='team4' style={{padding:"5px"}} />
                        </Grid>
                            {/* <Grid xs={3} sm={3} md={3}>
                            <img src={team5} alt="Girl in a jacket"  className='team5' style={{padding: "5px",position: "absolute",right: matchesXS ?  "-120px" : "65px"}}/>
                          </Grid> */}
                        </Grid>

                    </Grid>
                </Grid>


            </div>
        </>
    )
}

export default Team