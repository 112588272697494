import {
  Button,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import DiabHomecare1 from "../../assets/images/DiabHomecare1.png";
import DiabHomecare2 from "../../assets/images/DiabHomecare2.png";
import homediabeti from "../../assets/images1/woman injecting insuline_Diabetes 1.svg";
import factsList from "../../assets/images/factsList.svg";
import styles from "./styles";
import diseases from "./utils/disease";

const useStyles = makeStyles((theme) => styles(theme));

const Homecare = () => {
  console.log(diseases, "disease");
  let navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const custommatchesXL = useMediaQuery("(min-width:1500px)");

  return (
    <Grid
      container
      className={classes.homeCareContainer}
      direction={custommatchesMDX ? "row" : "column"}
      justifyContent={custommatchesMDX ? "center" : undefined}
    >
      {diseases.map((disease) => (
        <>
          {window.location.pathname === `/${disease.routePath}` && (
            <Grid
              item
              container
              direction="column"
              style={{ width: "650px" }}
              xs={12}
              md={6}
              xl={6}
            >
              {disease && disease.HomeCare && (
                <>
                  <Grid item>
                    <img
                      src={`${disease.HomeCare.homebanner1}`}
                      alt="Diabetes1"
                      style={{
                        height: "246px",
                        marginTop: "29px",
                        marginLeft: matchesXS ? "35px" : "83px",
                      }}
                    />
                    {console.log(disease.HomeCare.homebanner1, "iiiii")}
                  </Grid>
                  <Grid item>
                    <img
                      src={`${disease.HomeCare.homebanner2}`}
                      alt="Diabetes2"
                      style={{
                        height: "246px",
                        marginTop: matchesXS ? "10px" : "-30px",
                        marginLeft: matchesXS ? "50px" : "282px",
                        marginBottom: "20px",
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}

          {/* {console.log(window.location.pathname, disease && disease.HomeCare && disease.HomeCare.name, disease.name, "dsfgdsf")} */}
          {window.location.pathname === `/${disease.routePath}` && (
            <Grid
              item
              container
              direction="row"
              xs={12}
              md={6}
              xl={6}
              className={classes.homeCareContent}
              justifyContent={custommatchesMDX ? "center" : undefined}
              style={{
                marginLeft: matchesXS ? "40px" : undefined,
                paddingRight: matchesXS ? "10px" : undefined,
              }}
            >
              <Grid item>
                <Typography
                  paragraph
                  style={{
                    fontFamily: "visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "30px",
                    lineHeight: "38px",
                    color: "#002931",
                    paddingBottom: "43px",
                    // width: "300px",
                      width: matchesXS ? "270px" : "300px",
                    textAlign: matchesXS ? "left" : undefined,
                  }}
                >
                  For this particular disease, we can provide these home service
                  for you;
                </Typography>

                {disease.HomeCare.HomeCareList.map((List) => (
                  <Typography
                    style={{
                      fontFamily: "visuelt Pro",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "28px",
                      color: "#002931",
                      paddingBottom: "16px",
                      width: "300px",
                     
                    }}
                   
                  >
                    <img
                      src={factsList}
                      alt=""
                      style={{ marginRight: "9px" }}
                    />

                    {/* Diabetic foot management */}
                    {console.log(List, "List")}

                    {List}
                  </Typography>
                ))}
                {/* <Typography
          style={{
            fontFamily: "visuelt Pro",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "28px",
            color: "#002931",
            paddingBottom: "70px",
            width: "300px",
           
          }}
        >
          <img src={factsList} alt="" style={{ marginRight: "9px" }} /> 
          Diabetic foot management
        </Typography> */}
                <a
                  href="https://doctall.com/homecare/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Button
                    variant="contained"
                    className={classes.reqHomeCareButton}
                  >
                    Request for home care
                  </Button>
                </a>
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};

export default Homecare;
