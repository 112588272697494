import axios from "axios";
import { _GET_CITYS, _GET_AREAS, _GET_DELIVERY_AMOUNT } from "../endpoints";


export const fetchCitys = () => {
    return axios.get(_GET_CITYS)
}

export const fetchArea = (id) => {
    return axios.get(_GET_AREAS + '/' + id)
}


export const getShippingCharges = async (city_uuid, area_uuid) => {
    let response = await axios.get(`${_GET_DELIVERY_AMOUNT}?region_uuid=${city_uuid}&city_uuid=${area_uuid}`)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed");
}








