import React from 'react';
import {
    Grid,
    Button,
    Typography,
    IconButton,
    useMediaQuery,
    CardContent,
    Card,
    FormControl,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "../../pages/diseases/DIseaseWithQues/styles";
import mfIcons from "../../assets/images/most-iffective-icon.png";
import bfftIcons from "../../assets/images/best-for-first-time-icon.png";


const useStyles = makeStyles((theme) => styles(theme));


export default function BrandInfo({ planInfo }) {

    const classes = useStyles();
    const theme = useTheme();

    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <>
            <Grid
                item
                container
                className={classes.dWQplaInfoDetails}
                style={{ background: "#F2F8F9" }}
            >
                {/* <Grid
                    item
                    container
                    direction={matchesXS ? "column" : "row"}
                    justifyContent="space-between"
                    style={{ marginBottom: "8px" }}
                >
                    <Typography className={classes.planInfoFormTitle}>
                        Brand Name
                    </Typography>
                    <Typography
                        className={classes.planInfoFormTitle}
                        style={{ color: "#005F71" }}
                    >
                        {planInfo.treatment && planInfo.treatment.length > 0
                            ? planInfo.treatment[0].brand_name
                            : "NA"}
                    </Typography>
                </Grid> */}
                <Grid
                    item
                    container
                    direction={matchesXS ? "column" : "row"}
                    justifyContent="space-between"
                    style={{ marginBottom: "8px" }}
                >
                    <Typography className={classes.planInfoFormTitle}>
                        Manufacturer Name
                    </Typography>
                    <Typography
                        className={classes.planInfoFormTitle}
                        style={{ color: "#005F71" }}
                    >
                        {planInfo.treatment && planInfo.treatment.length > 0
                            ? planInfo.treatment[0].manufacturing
                            : "NA"}
                    </Typography>

                </Grid>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    direction={matchesXS ? "column" : "row"}
                    style={{ marginBottom: "8px" }}
                >
                    <Typography className={classes.planInfoFormTitle}>
                        Manufacturer Country
                    </Typography>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={planInfo.treatment[0].country_flag} alt="ellipse" style={{ width: "20px", height: "15px" }} />
                        <Typography
                            className={classes.planInfoFormTitle}
                            style={{ color: "#005F71" }}
                        >
                            {planInfo.treatment && planInfo.treatment.length > 0
                                ? planInfo.treatment[0].manufacturing_country
                                : "NA"}
                        </Typography>
                    </div>
                </Grid>

                {planInfo.diseases_name !== "Hairloss" && <> <Grid
                    item
                    container
                    justifyContent="space-between"
                    direction={matchesXS ? "column" : "row"}
                    style={{ marginBottom: "8px" }}
                >
                    <Typography className={classes.planInfoFormTitle}>
                        Time to take effect
                    </Typography>
                    <Typography
                        className={classes.planInfoFormTitle}
                        style={{ color: "#005F71" }}
                    >
                        {planInfo.treatment[0].time_to_take_effect}
                    </Typography>
                </Grid>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        direction={matchesXS ? "column" : "row"}
                        style={{ marginBottom: "8px" }}
                    >
                        <Typography className={classes.planInfoFormTitle}>
                            Lasts for
                        </Typography>
                        <Typography
                            className={classes.planInfoFormTitle}
                            style={{ color: "#005F71" }}
                        >
                            {planInfo.treatment[0].last_for}
                        </Typography>
                    </Grid></>}

                {
                    planInfo.treatment[0].features && planInfo.treatment[0].features.length > 0 && planInfo.treatment[0].features.map((feature) => (
                        <>
                            <Button
                                key={feature.title}
                                variant="contained"
                                disableRipple
                                disableFocusRipple
                                style={{
                                    backgroundColor: "#D9EDF1",
                                    color: "#002931",
                                    padding: "0px 8px",
                                    border: "1px solid #66B5C3",
                                    fontSize: "14px",
                                    textTransform: "lowercase",
                                    cursor: "auto",
                                }}
                            >
                                <img src={feature.image} /> &nbsp; {feature.title}
                            </Button>
                            &nbsp; &nbsp;
                        </>
                    ))
                }

            </Grid>
        </>
    )
}
