import actions from '../actions';

const { influencersActions } = actions;

const initState = {
    influencer: [],
    loader: false,
}

export default function rootReducer(state = initState, action){
    switch(action.type){

        case influencersActions.INFLUENCER_REQUEST:
            return {
                ...state,
                loader: true,
            };
        case influencersActions.INFLUENCER_REQUEST_SUCCESS:
            return {
                ...state,
                influencer: action.data,
                loader: false,
            };
        default:
            return state;        
    };
};