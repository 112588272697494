const treatmentPlanAction = {
    GET_TREATMENT_PLANS: "GET_TREATMENT_PLANS",
    GET_TREATMENT_PLANS_SUCCESS: "GET_TREATMENT_PLANS_SUCCESS",

    getTreatmentPlans: (id) => {
        console.log(id,'id')
        return {
            type: treatmentPlanAction.GET_TREATMENT_PLANS,
            id,
        };
    },
};

export default treatmentPlanAction;