import { width } from "@mui/system";

const styles = (theme) => ({
  fb: {
    color: "#344054",
    border: "1px solid #D0D5DD",
    background: "white",
    borderRadius: "5px",
    height: "45px",
    width: "100%",
    cursor: "pointer",
  },
  checkoutContainer: {
    width: "auto",
    height: "auto",
  },
  checkoutHeader: {
    height: "83px",
    width: "100%",
    paddingLeft: "97px",
    paddingTop: "28px",
  },
  checkoutContent: {
    background: "rgb(247, 251, 248)",
    width: "100%",
    minHeight: "calc(100vh - 83px)",
    paddingLeft: "97px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
  },
  summaryContainer: {
    width: "648px",
    height: "max-content",
    background: "#FFFFFF",

    paddingTop: "34px",
    [theme.breakpoints.down("xs")]: {
      width: "350px",
      height: "max-content",
      marginBottom: "40px",
    },
  },
  summaryHeading: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#002931",
  },
  summaryHeadingFaded: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#002931",
  },
  summaryDetails: {
    width: "494px",
    height: "24px",
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },
  checkoutInput: {
    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    width: "471px",
    height: "44px",
    padding: "10px 14px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  inputContainer: {
    paddingLeft: "44px",
    paddingRight: "48px",
    width: "563px",
    [theme.breakpoints.down("xs")]: {
      width: "350px",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  inputContainerFaded: {
    paddingLeft: "44px",
    paddingRight: "48px",
    backgroundColor: "#D3D3D3",
    width: "563px",
    [theme.breakpoints.down("xs")]: {
      width: "350px",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  inputCheck: {
    width: "16px",
    height: "16px",
    border: "1px solid #d0d5dd",
    borderRadius: "4px",
    WebkitAppearance: "none",
    MozAppearance: "none",
    cursor: "pointer",
    "&:checked": {
      backgroundColor: "#ff5c3c !important",
      border: "none",
      background:
        '#ff5c3c url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 3px 3px no-repeat',
    },
  },
  createAccount: {
    ...theme.typography.secondaryButton,
    width: "100%",
    height: "44px",
    border: "1px solid #C53B20",
    boxSizing: "border-box",
    /* Shadow/xs */

    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    marginTop: "24px",
  },
  createAccount1: {
    ...theme.typography.secondaryButton,
    width: "100%",
    height: "44px",
    border: "1px solid #00839B",
    boxSizing: "border-box",
    fontWeight:"400",
    /* Shadow/xs */

    // boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    marginTop: "24px",
  },
  directSignIn: {
    border: "1px solid #D0D5DD",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    width: "100%",
    height: "44px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#344054",
    textTransform: "none",
    "&:hover": {
      background: "#ffffff",
    },
  },
  linkText: {
    padding: "0px",
    "&:hover": {
      background: "transparent",
    },
  },
  paymentHeader: {
    width: "491px",
    height: "82px",
    padding: "14px 44px",
    background: "#F2F4F7",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },
  payNow: {
    ...theme.typography.secondaryButton,
    width: "100%",
    height: "44px",
    paddingBottom: "13px",
  },
  email: {
    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    width: "471px",
    height: "44px",
    padding: "10px 14px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "6px",
    color: "#667085",
    "&:focus-visible": {
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "320px",
    },
  },
  notexpanded: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(0, 41, 49, 0.5)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  expanded: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#002931",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  orderConfirm: {
    width: "616px",
    height: "667px",
    padding: "97px 64px",
    [theme.breakpoints.down("xs")]: {
      width: "360px",
      height: "667px",
      padding: "97px 16px",
    },
  },
  confirmButton: {
    width: "487px",
    height: "51px",
    borderRadius: "5px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },
  InfoTooltip: {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    width: "199px",
    height: "124px",
  },
  infoText: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
  },
  iconButton: {
    width: "9px",
    height: "4.5px",
    background: "transparent",
    padding: "0px",

    "&:hover": {
      background: "transparent",
    },
  },
});

export default styles;
