import React, { useEffect, useState } from "react";
import "../styles.js";
import {
    Grid,
    Typography,
    useMediaQuery,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
   
} from "@material-ui/core";
import styles from "../styles";
import { makeStyles, useTheme } from "@material-ui/core";
import {
    NeutralComp,
    YesComp,
    NoComp,
    InputDetails,
} from "../util/UiComp";

const useStyles = makeStyles((theme) => styles(theme));

export default function MultipleQuestionsInput({ ques, saveDetails, condition, showProceed }) {
    const classes = useStyles();

    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const [value, setValue] = React.useState("");
    const [userRemark, setUserRemark] = useState();
    const [selectedQuestion, setSelectedQuestion] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const [getResponce, setgetResponse] = useState(false);



    useEffect(() => {
        saveDetails({ "title": condition.title, "question": ques.question, "checked": selectedOption, "remark": userRemark })
    }, [userRemark]);


    const handleChange = (event) => {
        setSelectedQuestion(event.target.value);
    };

    const handleValueChange = (question, val) => {
        setSelectedOption(val)
        if (val === "Yes") {
            setgetResponse(true)
            saveDetails({ "title": condition.title, "question": ques.question, "checked": "Yes", "remark": userRemark })
        } else {
            setgetResponse(false)
            saveDetails({ "title": condition.title, "question": ques.question, "checked": "No", "remark": "NO" })
        }
    };

    return (
        <>

            <Grid item>
                <Grid
                    item
                    container
                    direction={matchesSM ? "column" : "row"}
                    style={{
                        paddingBottom: "20px",
                        width: matchesSM ? "350px" : "776px",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography
                            style={{
                                fontFamily: "Visuelt Pro",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: matchesSM ? "16px" : "24px",
                                lineHeight: "32px",
                                color: "#002931",
                                width: matchesSM ? "350px" : "409px",
                            }}
                        >
                            {ques.question}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Impairment"
                                name="Impairment"
                                value={value}
                                onChange={handleChange}
                                row
                            >
                                <FormControlLabel
                                    value={`${ques.question} Yes`}
                                    control={
                                        <Radio
                                            checked={selectedOption === "Yes"}
                                            onChange={(e) => {
                                                handleValueChange(ques.question, "Yes");
                                            }}
                                            icon={<NeutralComp value="Yes" />}
                                            checkedIcon={<YesComp />}
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                        />
                                    }
                                />
                                <FormControlLabel
                                    value={`${ques.question} No`}
                                    control={
                                        <Radio
                                            checked={selectedOption === "No" || selectedOption === "NO"}
                                            onChange={(e) => {
                                                handleValueChange(ques.question, "No");
                                            }}
                                            icon={<NeutralComp value="No" />}
                                            checkedIcon={<NoComp />}
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                        />
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                {getResponce ? (
                    <Grid item>
                        <InputDetails
                            userResponse={(data) => setUserRemark(data)}
                        />
                    </Grid>
                ) : null}
            </Grid>
        </>
    )
}