import React, { useEffect, Component, useState } from "react";
import EdCardheader from '../../../pages/Questionnaire/util/EdCardheader';
import chevronRight from "../../../assets/images/chevronRight.svg";
import chevronUp from "../../../assets/images/chevronUp.svg";
import chevronDownQ from "../../../assets/images/chevronDownQ.svg";
import DiscountCode from "../../../pages/Checkout/DiscountCode";
import ArrowUp from '../../../assets/images/ArrowUp.png';
import ArrowDown from '../../../assets/images/ArrowDown.png';
import InputBase from './inputbutton';
import InputBase1 from './inputbutton1';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './EdShipping';
import {
  Input,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
} from "@material-ui/core";
import styles from "../styles";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/actions/authActions";
import { showNotification } from "../../../components/common-notification/CommonNotification";
import { getShippingCharges } from "../../../services/locationsServices/locationServices";
import { getAddressDetails } from "../../../services/order-services/orderServices";
import editaddr from "../../../assets/images/editaddr.svg";
import infoAlert from "../../../assets/images/infoAlert.svg";
import { useLocation } from "react-router-dom";
import './EdCard.css';

const useStyles = makeStyles((theme) => styles(theme));

const EdShipping = () => {
  // expanded,
  // handleChange,
  // setShippingDetails,
  // handleExpand,
  // setShippingCharge,
  // questionsSummary,
  // disease
  {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { locationsActions } = actions;
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const [areaId, setAreaId] = useState();
    const [cityId, setCityId] = useState();
    const [shipmentCharge, setShipmentCharge] = useState();

    const [address1, setAddress1] = useState();
    const [address2, setAddress2] = useState();

    const [totalAmt, setTotalAmt] = useState();

    // const { userSelectedPlan, questionsSummary, planDetails, disease, uuidDaata } = state
    // ? state
    // : {};

    // const _userInfo = useSelector((state) => state.authReducer);
    const { state } = useLocation();

    // const _userInfo = useSelector((state) => state.authReducer);
    // console.log(expanded, "expandedexpanded")
    //  let id = _userInfo.userInfo.uuid;




    const handleClose = () => {
      setOpen(false);
    };

    const handleOpen = () => {
      setOpen(true);
    };
    const tooltip =
      "Please note that, shipping charges varies based on the city and area selection";

    const _locations = useSelector((state) => state.locationReducer);

    const handleCity = (event) => {
      dispatch(locationsActions.getAreas(event));
      setCityId(event)
    };

    useEffect(() => {
      fetchShippingCharges()
      fetchAddress()
    }, [areaId])

    useEffect(() => {
      console.log("_userInfo")
      fetchAddress()
    },)

    const onSelectArea = (event) => {
      setAreaId(event)
    }

    const fetchShippingCharges = async () => {
      try {
        let response = await getShippingCharges(cityId, areaId)
        if (response && (response.status === 200 || response.status === 201)) {
          if (response.data && response.data.length > 0) {
            let mResponse = response.data[0];
            setShippingCharge(mResponse.doctall_selleing_price)
            setShipmentCharge(mResponse.doctall_selleing_price)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    const fetchAddress = async () => {
      try {
        let response = await getAddressDetails(_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid, _userInfo && _userInfo.userInfo && _userInfo.userInfo.token)
        if (response && (response.status === 200 || response.status === 201)) {

          if (response.data) {
            let mResponse = response.data.delivery_address;
            // console.log(response.data.delivery_address)
            // addressLine1
            setAddress1(mResponse.addressLine1)
            setAddress2(mResponse.addressLine2)
            // setShipmentCharge(mResponse.doctall_selleing_price)
          }
        }

      } catch (error) {
        console.log(error)
      }
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      let { address2, address1, city, area } = event.target.elements;
      let payLoad = {
        addressLine1: address1.value,
        addressLine2: address2.value,
        city: city.value,
        area: area.value,
        shippingAmount: shipmentCharge
      };
      handleExpand("Payment");
      showNotification(
        "success",
        "Success !",
        "Address Details Saved Successfully.."
      );
      setShippingDetails(payLoad);

      const params = {
        currency: 'NGN',
        value: payableAmount, // Total Revenue
        items: [{ item_id: planInfo.tpl_uuid, item_name: basePlan.plan_name, currency: "NGN", price: planAmount, quantity: finalQuantity, shipping_tier: "Ground", shipping: shippingCharges }]
      };
      getFirebaseAnalyticsInstance().logEvent(firebase.analytics.EventName.ADD_SHIPPING_INFO, params)

    };


    const _onDecreaseQuantity = () => {
      if (selectedQuantity > 1) {
        let _quantity = Number(selectedQuantity - 1);
        setSelectedQuantity(_quantity);
      } else {
        console.log("no less than 1")
      }
    };


    return (
      <div>

        <div>
          <Grid md={6} xs={12} sm={12} lg={12}>
            <EdCardheader />
          </Grid>
        </div>

        <div className="navbar">
          <Grid
            item
            container
            justifyContent="start"
            alignItems="left"
            style={{
              padding: "50px 80px",
              height: "35px",
              backgroundColor: "greenyellow",
              backgroundColor: "#F7FBF8",
            }}


          >
            <Typography style={{
              color: "rgba(0, 41, 49, 0.5)",
              fontFamily: "Visuelt Pro",
              fontSize: "16px",
              fontWeight: "400",
            }}>Questions</Typography>
            <img src={chevronRight} alt="right arrow" />
            <Typography
              style={{
                color: "rgba(0, 41, 49, 0.5)",
                fontFamily: "Visuelt Pro",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Account
            </Typography>
            <img src={chevronRight} alt="right arrow" />
            <Typography

              style={{
                color: "rgba(0, 41, 49, 1)",
                fontFamily: "Visuelt Pro",
                fontSize: "16px",
                fontWeight: "700",
                color: "#002931",
              }}
            >
              Shipping Address
            </Typography>
            <img src={chevronRight} alt="right arrow" />
            <Typography

              style={{
                color: "rgba(0, 41, 49, 0.5)",
                fontFamily: "Visuelt Pro",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Payments
            </Typography>
            <img src={chevronRight} alt="right arrow" />
          </Grid>
        </div>

        <div id="wrapper-main" style={{ backgroundColor: "#F7FBF8" }}>
          <div id="wrapper-grid">
            <div class="container">
              <Grid item
                // className="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12"
                style={{
                  float: "left",
                  marginRight: "45px",
                  marginBottom: "40px",
                }}
              >
                <div className="shipping">
                  <form onSubmit={handleSubmit}>
                    <Grid direction="column" style={{
                      backgroundColor: "#fff", padding: "20px 30px",
                      width: "100%"
                    }}>
                      <Grid>
                        <Typography
                          align="left"
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "20px",
                            lineHeight: "20px",
                            color: "#002931",
                            paddingBottom: "40px",
                            paddingTop: "20px",
                          }}
                        >
                          1. Shipping
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography md={4} sm={4} xs={12}
                          align="left"
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#344054",
                            paddingBottom: "6px",
                          }}
                        >
                          Name
                        </Typography>
                        <input
                          type="text"
                          placeholder="Enter Your Name"
                          style={{
                            width: "97%",
                            padding: "0px 10px",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                            height: "6vh",
                            border: "1px solid #D0D5DD",
                            fontSize: "15px",
                          }}
                        />

                      </Grid><br />
                      <Grid item>
                        <Typography
                          align="left"
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#344054",
                            paddingBottom: "6px",
                          }}
                        >
                          Address line 1
                        </Typography>

                        <Input
                          type="text"
                          required
                          name="address1"
                          placeholder="Enter Address line 1"
                          className={classes.checkoutInput}
                          onChange={(event) => { setAddress1(event.target.value) }}
                          disableUnderline={true}
                          value={address1}
                          style={{
                            width: "97%",
                            padding: "0px 10px",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                            height: "6vh",
                            border: "1px solid #D0D5DD",
                            fontSize: "15px",
                          }}
                        />
                      </Grid>
                      <Grid item style={{ paddingTop: "20px" }}>
                        <Typography
                          align="left"
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#344054",
                            paddingBottom: "6px",
                          }}
                        >
                          Address line 2
                        </Typography>

                        <Input
                          type="text"
                          required
                          name="address2"
                          placeholder="Enter Address line 2"
                          className={classes.checkoutInput}
                          onChange={(event) => { setAddress2(event.target.value) }}
                          disableUnderline={true}
                          value={address2}
                          style={{
                            width: "97%",
                            padding: "0px 10px",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                            height: "6vh",
                            border: "1px solid #D0D5DD",
                            fontSize: "15px",
                          }}
                        />
                      </Grid>
                      <Grid item style={{ paddingTop: "20px" }}>
                        <Grid container justifyContent="space-between">
                          <Typography
                            align="left"
                            style={{
                              fontFamily: "Visuelt Pro",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#344054",

                            }}
                          >
                            City
                          </Typography>
                          <Tooltip
                            title={tooltip}
                            placement="left"
                            arrow
                            classes={{ popper: classes.InfoTooltip }}
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}

                          >
                            <IconButton disableFocusRipple disableRipple>
                              <img src={infoAlert} alt="alert" />
                            </IconButton>
                          </Tooltip>
                          {/* } */}
                        </Grid>

                        <select
                          name="city"
                          required
                          className={classes.email}
                          onChange={(e) => handleCity(e.target.value)}
                        >
                          <option value="">Select City</option>
                          {_locations &&
                            _locations.citiesData.length > 0 &&
                            _locations.citiesData.map((city) => (
                              <option value={city.uuid}>{city.name}</option>
                            ))}

                        </select>
                      </Grid>
                      <Grid item style={{ paddingTop: "20px" }}>
                        <Typography
                          align="left"
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#344054",
                          }}
                        >
                          Area
                        </Typography>

                        <select name="area"
                          required onChange={(e) => onSelectArea(e.target.value)}
                          className={classes.email}>
                          <option value="">Select Area</option>
                          {_locations &&
                            _locations.areasData.length > 0 &&
                            _locations.areasData.map((area) => (
                              <option value={area.uuid}>{area.name}</option>
                            ))}
                        </select>
                      </Grid><br /><br /><br />

                      <hr style={{
                        border: "0.5px solid rgba(0, 0, 0, 0.5)",
                      }} />
                      <br /><br /><br />

                      <Grid style={{
                        display: "flex",
                        alignItem: "center",

                      }}>
                        <Grid style={{ float: "left" }}>
                          <Typography
                            align="left"
                            style={{
                              fontFamily: "Visuelt Pro",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "15px",
                              lineHeight: "20px",
                              color: "#344054",
                              paddingBottom: "6px",
                              gap: "18px",
                            }}
                          >
                            Email
                          </Typography>
                          <input
                            type="email"
                            placeholder="Enter Your Email"
                            style={{
                              width: "100%",
                              padding: "0px 10px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                              height: "8vh",
                              border: "1px solid #D0D5DD",
                              fontSize: "18px",
                            }}
                          />
                        </Grid>

                        <Grid style={{ float: "right" }}>
                          <Typography
                            style={{
                              fontFamily: "Visuelt Pro",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "15px",
                              lineHeight: "20px",
                              color: "#344054",
                              paddingBottom: "6px",
                              marginLeft: "20px",

                            }}
                          >
                            Phone Number
                          </Typography>
                          <input
                            type="number"
                            placeholder="Enter Your Number"
                            style={{
                              width: "90%",
                              padding: "0px 10px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                              height: "8vh",
                              border: "1px solid #D0D5DD",
                              fontSize: "18px",
                              marginLeft: "20px",
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Typography>
                        <div>
                          <p style={{
                            color: "#667085", fontSize: "13px", fontFamily: "Visuelt Pro",
                            fontWeight: "400", marginTop: "20px",
                          }}>Don’t panic! We need this information delivery purposes only.</p>
                        </div>
                      </Typography>
                      <p
                        type="submit"
                        style={{
                          marginTop: "30px",
                          marginBottom: "30px",
                          padding: "10px 50px",
                          borderRadius: "8px",
                          color: "#fff",
                          fontFamily: "Visuelt Pro",
                          fontWeight: "500",
                          backgroundColor: "#FF5C3C",
                          border: "1px solid #C53B20",
                          fontSize: "17px",
                          textAlign: "center",
                          cursor: "pointer",
                          width: "97%",
                        }}
                      >
                        Save and proceed
                      </p>
                    </Grid>
                  </form>
                </div>
              </Grid>

            </div>
            <div class="container">
              <div item
                // className="col-md-6 col-xl-6 col-lg-6 col-xs-12" 
                style={{ backgroundColor: "#fff", float: "right", padding: "20px 10px 20px 20px" }}>

                <div>
                  <h3 style={{ fontSize: "20px", fontFamily: "Visuelt Pro", fontWeight: "bold", alignItem: "left", paddingBottom: "50px", color: "#002931", paddingTop: "10px" }}>Order  Summary</h3>
                </div>

                <Card elevation={0} xs={12} sm={12} md={12} style={{ width: "620px", marginBottom: "20px", backgroundColor: "#fff", }}>
                  <CardContent>
                    <Grid item container style={{
                      direction: "column",
                      justifyContent: "space between",
                      alignItems: "flex-start",
                      Width: "100%"
                    }}
                    >
                      <Grid item >
                        <Typography gutterBottom variant="h5"
                          style={{
                            width: "650px",
                          }} >

                          <Typography gutterBottom variant="h5" style={{
                            color: "#002931",
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            FontSize: "20px",
                            fontWeight: "400",
                            color: "#002931",
                          }}>
                            <div
                              style={{ border: "2px solid #D9EDF1", width: "90%", height: "18vh" }} >
                              <div>
                                <div>
                                  <span style={{ width: "20%", height: "18vh", backgroundColor: "#F2F8F9", float: "left" }} />
                                </div>

                                <div style={{ float: "left", textAlign: "left", paddingLeft: "15px", }}>
                                  <p
                                    style={{ fontSize: "20px", fontWeight: "700", fontFamily: "Visuelt Pro", paddingTop: "10px", color: "#002931", }}
                                  >Priligy</p>
                                  <p
                                    style={{ color: " #065986", fontSize: "14px", fontWeight: "700", fontFamily: "Visuelt Pro", }}
                                  >Standard 30 mg, 3 tablets in this package
                                  </p>

                                  <div style={{
                                    display: "flex",
                                    alignItem: "center",
                                    justifyContent: "center",
                                    padding: "0px 15px",
                                    border: "2px solid #00839B",
                                    float: "left",
                                    borderRadius: "10px",
                                    marginTop: "5px",
                                  }}>
                                    <div
                                      style={{
                                        color: "#065986",
                                        fontWeight: "500",
                                        fontSize: "20px",
                                        fontFamily: "Visuelt Pro",
                                        paddingTop: "6px",

                                      }}>
                                      <p>1</p>
                                    </div>

                                    <div style={{
                                      cursor: "pointer",
                                      paddingTop: "0px",
                                      paddingLeft: "30px",
                                      display: "inline-grid",
                                      justifyContent: "center"
                                    }}>
                                      <img width={"70%"} src={ArrowUp} alt="up" />
                                      <img width={"70%"} src={ArrowDown} alt="down" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div style={{ float: "right", textAlign: "right", padding: "10px" }}>
                                <p style={{ color: "#065986", fontSize: "20px", fontFamily: "Visuelt Pro", fontWeight: "500" }}>₦10,000.00</p>
                                <del style={{ color: "#FF5C3C", fontSize: "15px", fontFamily: "Visuelt Pro", fontWeight: "500" }}>₦6,100.00</del>
                              </div>

                            </div>
                          </Typography>

                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <Card elevation={0} style={{ width: "620px", backgroundColor: "#fff", }}>
                  <CardContent>
                    <Grid item container style={{
                      direction: "column",
                      justifyContent: "space between",
                      alignItems: "flex-start",
                      Width: "100%"
                    }}
                    >
                      <Grid item >
                        <Typography gutterBottom variant="h5"
                          style={{
                            width: "650px",
                          }} >
                          <Typography gutterBottom variant="h5" style={{
                            color: "#002931",
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            FontSize: "20px",
                            fontWeight: "400",
                            color: "#002931",
                          }}>
                            <div
                              style={{ width: "90%", textAlign: "center" }} >
                              <div>
                                <div>
                                  <p
                                    style={{
                                      float: "left", textAlign: "center", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro", paddingLeft: "20px",
                                      fontWeight: "700"
                                    }}>
                                    Shipping
                                  </p>
                                </div>
                                <div>
                                  <p style={{
                                    float: "right", textAlign: "center", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro", paddingRight: "20px",
                                    fontWeight: "700"
                                  }}>
                                    ₦ 1400.00
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <Card elevation={0} style={{ width: "620px", backgroundColor: "#fff", }}>
                  <CardContent>
                    <Grid item container style={{
                      direction: "column",
                      justifyContent: "space between",
                      alignItems: "flex-start",
                      Width: "100%"
                    }}
                    >
                      <Grid item >
                        <Typography gutterBottom variant="h5"
                          style={{
                            width: "650px",
                          }} >
                          <Typography>
                            <div>
                              <p style={{
                                fontSize: "20px", color: "#FF5C3C", fontFamily: "Visuelt Pro", paddingBottom: "10px",
                                fontWeight: "600", paddingLeft: "15px",
                              }}>Coupon code</p>
                            </div>
                            <div>
                              <p style={{ fontSize: "18px", color: "#717782", fontFamily: "Visuelt Pro", fontWeight: "400", paddingLeft: "15px", }}>Apply Doctall discount promo code below</p>
                            </div>
                          </Typography>

                          <InputBase />

                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>



                <Card elevation={0} style={{ width: "620px", backgroundColor: "#fff", }}>
                  <CardContent>
                    <Grid item container style={{
                      direction: "column",
                      justifyContent: "space between",
                      alignItems: "flex-start",
                      Width: "100%"
                    }}
                    >
                      <Grid item >
                        <Typography gutterBottom variant="h5"
                          style={{
                            width: "650px",
                          }} >
                          <Typography>
                            <Typography>
                              <div>
                                <p style={{
                                  fontSize: "20px", color: "#FF5C3C", fontFamily: "Visuelt Pro", paddingBottom: "10px",
                                  fontWeight: "600", paddingLeft: "15px",
                                }}>Cash bonus from wallet</p>
                              </div>
                              <div>
                                <p style={{ fontSize: "18px", color: "#717782", fontFamily: "Visuelt Pro", fontWeight: "400", paddingLeft: "15px", }}>You currently have <span style={{ color: "#191716", fontWeight: "400" }}>₦34,0989</span> in your wallet balance </p>
                              </div>
                            </Typography>

                            <InputBase1 />
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>



                <Typography style={{
                  width: "100%", padding: "35px 0px 55px 0px", backgroundColor: "#F2F8F9",
                  border: "1px solid #D9EDF1",
                }}>
                  <div>
                    <p style={{
                      float: "left", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",
                      fontWeight: "700", paddingLeft: "20px", alignItems: "center",
                    }}>Total</p>
                  </div>
                  <div>
                    <p style={{
                      float: "right", color: "#002931", fontSize: "20px", fontFamily: "Visuelt Pro",
                      fontWeight: "700", paddingRight: "20px", alignItems: "center",
                    }}>
                      ₦ 3,500.00
                    </p>
                  </div>
                </Typography>
              </div>
            </div>
          </div>
        </div>







      </div>
    )

  }
};

export default EdShipping;