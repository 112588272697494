import actions from '../actions';

const { questionnaireActions } = actions;

const initState = {
    loader: false,
    questions: [],
    limit: 10,
    skip: 0,
    count: 0,
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {

        case questionnaireActions.GET_QUESTIONS:
            return {
                ...state,
                loader: true
            };


        case questionnaireActions.GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                loader: false,
                questions: action.questionsData,
            };

        default:
            return state;
    }
}
