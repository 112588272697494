import { Grid, IconButton, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import prevQuestionIcon from "../../assets/images/prevQuestion.svg";
import LinearProgressBar from "../../components/ui/LinearProgress";
import { AgeQuestion, Mail } from "./components/staticQuestions.js";
import { EDquestions as questions, PEquestions } from "./Questions";
import styles from "./styles";
import "./styles.js";

import { useNavigate, useLocation } from "react-router-dom";
import { getFirebaseAnalyticsInstance } from "../../firebase.js";
import CallBackPopUp from "../callbackPopUp/CallBackPopUP.js";
import { MedicalConditions } from "./components/EDQuestionnaire";
import {
    MultiChoice,
    RadioChoice,
    RadioChoiceValidation
} from "./components/PEQuestionnaire";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => styles(theme));

const Questionnaire = () => {
  const classes = useStyles();
  const theme = useTheme();
  let navigate = useNavigate();
  const { state } = useLocation();
  const { selectedPlan, diseaseId, diseaseName, planDetails } = state
    ? state
    : {};

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const [callBackModel, setCallBackModel] = useState(false);

  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    age: "",
    email: "",
    userResponse: [],
    shippingCarges: 0,
    shippingAddress: {},
  });
  const [questionsData, setQuestionsData] = useState([]);

  const [ uuidDaata,  setUuidDaata ] = useState();

  useEffect(() => {

    if (step > PEquestions.length) {
      data["userResponse"] = questionsData;
      navigate("/summary", {
        state: {
          questionsSummary: data,
          userSelectedPlan: selectedPlan,
          planInfo: planDetails,
          disease: diseaseName,
          uuidDaata: uuidDaata
        },
      });
    }
  }, [step, data]);

  useEffect(() => {
    addFireBaseAnalytics();
  }, [step, data]);

  const addFireBaseAnalytics = async () => {
    try {
      await getFirebaseAnalyticsInstance().logEvent("PE_QUESTIONNAIRE_START", {
        eventType: 'TREATMENT_PLAN_QUESTIONNAIRE',
        platform: 'web'
      });
    } catch (error) {
      console.log(error)
    }
  }


  const prevQuestion = () => {
    setStep(step - 1);
  };

  data["userResponse"] = questionsData;

  const RenderedItem = () => {
    switch (step) {
      case 1:
        return (
          <AgeQuestion
            age={questions[0]}
            selectedAge={data.age}
            handleChange={(selectedAge) =>
              setData({ ...data, age: selectedAge })
            }
            stepUpdate={(step) => setStep(step)}
          />
        );
        break;
      case 2:
        return (
          <Mail
            mail={questions[1]}
            userEmail={data.email}
            handleChange={(email, shippingCharge, shippingDetails) =>
              setData({ ...data, email: email, shippingCarges: shippingCharge, shippingAddress: shippingDetails })
            }
            stepUpdate={(step) => setStep(step)}
          />
        );

      case 3:
        return (
          <MultiChoice
            question={PEquestions[2]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 4:
        return (
          <MultiChoice
            question={PEquestions[3]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 5:
        return (
          <MultiChoice
            question={PEquestions[4]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 6:
        return (
          <MultiChoice
            question={PEquestions[5]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 7:
        return (
          <MultiChoice
            question={PEquestions[6]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 8:
        return (
          <MultiChoice
            question={PEquestions[7]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );

      case 9:
        return (
          <RadioChoice
            question={PEquestions[8]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            callBack={(event) => setCallBackModel(event)}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 10:
        return (
          <RadioChoice
            question={PEquestions[9]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            callBack={(event) => setCallBackModel(event)}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 11:
        return (
          <MedicalConditions
            condition={PEquestions[10]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 12:
        return (
          <RadioChoiceValidation
            ques={PEquestions[11]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 13:
        return (
          <RadioChoiceValidation
            ques={PEquestions[12]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 14:
        return (
          <RadioChoiceValidation
            ques={PEquestions[13]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 15:
        return (
          <RadioChoiceValidation
            ques={PEquestions[14]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      case 16:
        return (
          <RadioChoice
            question={PEquestions[15]}
            stepUpdate={(step) => setStep(step)}
            step={step}
            saveData={(option) => setQuestionsData((prev) => [...prev, option])}
            callBack={(event) => setCallBackModel(event)}
            sav = {data}
            uuidata = {(data) => setUuidDaata(data)}
            disease_uuid = {planDetails && planDetails.diseases_uuid}
            disease_name = {planDetails && planDetails.diseases_name}
            uuidDaata={uuidDaata}
          />
        );
      default:
        data["userResponse"] = questionsData;
        navigate("/summary", {
          state: {
            questionsSummary: data,
            userSelectedPlan: selectedPlan,
            planInfo: planDetails,
            disease: diseaseName,
            uuidDaata: uuidDaata
          },
        });
        return null;
        break;
    }
  };

  return (
    <>
    <Helmet>
    <title>Premature Ejaculation Questionnaire| Discreet premature ejaculation treatment online</title>
    <meta 
    name="description" 
    content="Treat premature ejaculation with doctor-packaged plans. Choose your treatment plan, answer basic questions about your symptoms, completer your treatment order, and have your medicines delivered to you discreetly" 
    />
  </Helmet>
    <Grid item container alignItems="center" direction="column">
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: "58px", //width: "820px"
        }}
      >
        {step === 1 ? null : (
          <Grid item style={{ width: "114px", marginRight: "39px" }}>
            <IconButton disableFocusRipple disableRipple>
              <img src={prevQuestionIcon} alt="prev" onClick={prevQuestion} />
            </IconButton>
          </Grid>
        )}

        <Grid item>
          <LinearProgressBar
            value={(step - 1) * 6}
          />
        </Grid>
      </Grid>
      <Grid item container>
        {<RenderedItem />}{" "}
      </Grid>

      <CallBackPopUp
        open={callBackModel}
        close={() => setCallBackModel(!callBackModel)}
      />

    </Grid>
    </>
  );
};
export default Questionnaire;
