import actions from '../actions';

const { doctorsActions } = actions;

const initState = {
    loader: false,
    doctorsData: [],
    limit: 10,
    skip: 0,
    count: 0,
    pageNumber: 0,
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {

        case doctorsActions.GET_SPECIALIST_DOCTOR:
            return {
                ...state,
                loader: true
            };

        case doctorsActions.GET_SPECIALIST_DOCTOR_SUCCESS:
            return {
                ...state,
                loader: false,
                doctorsData: action.doctorsData,
            };
        default:
            return state;
    }
}
