const DashboardStyle = (theme) => ({
  DashContainer: {
    width: "100%",
    background: "#E8EBEC",
  },
  TreatmentPlanIfNotSure: {
    display: "flex",
    padding: "50px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px",
    },
  },
  NotSureTPHeading: {
    color: " #002931",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "38px",
  },
  SpeakwithSubHeading: {
    fontSize: "18px",
    lineHeight: "32px",
    color: "#002931",
    fontWeight: "400",
  },
  proceedContainer: {
    width: "100%",
    paddingTop: "1.1rem",
    paddingLeft: "9.6rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
    },
  },
  dWQhomeCare: {
    width: "60.5rem",
    height: "190px",
    background: "#FFFFFF",
    borderRadius: "10px",
    // backgroundImage: `url(${CallAppointment})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    paddingLeft: "5rem",
    paddingTop: "7.45rem",
    paddingRight: "10.6rem",
    position: "relative",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "32.8rem",
      // backgroundImage: `url(${homeCareLooper})`,
      paddingRight: "24px",
      paddingLeft: "24px",
      height: "180px",
    },
  },
  dWQpurchase: {
    width: "60.5rem",
    height: "190px",
    background: "#FFFFFF",
    borderRadius: "10px",
    // backgroundImage: `url(${homeCareLooper}),url(${Purchase})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    paddingLeft: "5rem",
    paddingTop: "7.45rem",
    paddingRight: "10.6rem",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "32.8rem",
      // backgroundImage: `url(${homeCareLooper})`,
      paddingRight: "24px",
      paddingLeft: "24px",
      height: "180px",
    },
  },
  homeCareTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#002931",
    paddingBottom: "0.3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  SpeakwithSubHeading1: {
    fontSize: "14px",
    lineHeight: "32px",
    color: "#002931",
    fontWeight: "400",
    marginTop: "-4%",
  },
  LogoutButton: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 6px rgba(0, 76, 78, 0.02)",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 20px",
    width: "200px",
    margin: "100px",
    border: "none",
    fontSize: "16px",
    color: "#002931",
  },
  logo: {
    paddingLeft: "7.4rem",
    paddingRight: "4.4rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
    },
  },

  logoButton: {
    "&:hover": {
      background: "transparent",
    },
  },
  tabLinks: {
    "&>a": {
      textDecoration: "none",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "2rem",
      color: "#002931",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drawerToggle: {
    display: "flex",
    alignItems: "center",
  },

  // Appointment page styles
  AppointmetContainer: {
    padding: "30px",
    fontFamily: "Visuelt Pro",
    width: "90%",
    height: "auto",
  },
  AptMainHeading: {
    fontSize: "24px",
    fontFamily: "Visuelt Pro",
    fontWeight: "500",
    color: "#002931",
    lineHeight: "32px",
  },
  addAptButton: {
    color: "#fff",
    fontFamily: "Inter",
    fontSize: "16px",
    padding: "10px 18px",
    background: "#FF5C3C",
    "&:hover": {
      background: "#FF5C3C",
    },
  },
  upcomingAptContainer: {
    background: "#F3F8FA",
    padding: "20px",
    marginTop: "20px",
    border: " 1px solid #E4EEF0",
    borderRadius: "4px",
  },
  AptSubHeading: {
    fontSize: "18px",
    fontFamily: "Visuelt Pro",
    fontWeight: "500",
    color: "#002931",
    lineHeight: "28px",
  },
  AptCards: {
    margin: "20px",
    // padding:"20px",
  },
  AptBookStatus: {
    fontSize: "12px",
    color: "#027A48",
    background: "#ECFDF3",
    borderRadius: "16px",
  },
  FindDrSpecialist: {
    fontSize: "18px",
    color: "#002931",
    fontWeight: "500",
    lineHeight: "28PX",
  },
  dateAndTime: {
    fontFamily: "Visuelt Pro",
    fontSize: "14px",
    fontWeight: "400",
    color: "#002931",
  },
  DrName: {
    fontFamily: "Visuelt Pro",
    fontSize: "14px",
    fontWeight: "500",
    color: " #002931",
  },
  DrSpecialist: {
    fontFamily: "Visuelt Pro",
    fontSize: "14px",
    fontWeight: "400",
    color: "#5B7B83",
  },
  Search: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 14px",
    gap: "8px",
    width: "402px",
    height: "44px",
    background: "#FFFFFF",
    border: "1px solid #E4EEF0",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
  },
  DashboardContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
});
export default DashboardStyle;
