export const _redirect = (target) => {
  switch (target) {
    case "CONSUMER":
      window.open(process.env.REACT_APP_CUNSUMER_SITE_URL, "_blank");
      break;
    case "CONSUMER-FADN":
      window.open(
        process.env.REACT_APP_CUNSUMER_SITE_URL + "/specialist",
        "_blank"
      );
      break;
    case "CONSUMER-DASHBOARD":
      window.open(
        process.env.REACT_APP_CUNSUMER_SITE_URL + "/dashboard",
        "_blank"
      );
      break;
    case "DMP":
      window.open(process.env.REACT_APP_DMP_SITE_URL, "_blank");
      break;
    case "DMP-LOGIN":
      window.open(process.env.REACT_APP_DMP_SITE_URL + "/login", "_blank");
      break;
    case "DMP":
      window.open(process.env.REACT_APP_DMP_SITE_URL, "_blank");
      break;
    case "DMP-POPULAR":
      window.open(
        process.env.REACT_APP_DMP_SITE_URL + "/populartest",
        "_blank"
      );
      break;

    case "FOUNDATION":
      window.open("https://doctall.com/foundation/", "_blank");
      break;
    case "LIVING":
      window.open("https://doctall.com/living/", "_blank");
      break;
    case "PEDIA":
      window.open("https://doctall.com/pedia/", "_blank");
      break;
    case "HUB":
      window.open("https://hub.doctall.com/", "_blank");
      break;
    case "HOMECARE":
      window.open("https://doctall.com/homecare/", "_blank");
      break;
    case "FB":
      window.open("https://www.facebook.com/myDoctall/", "_blank");
      break;
    case "INSTAGRAM":
      window.open("https://www.instagram.com/mydoctall/", "_blank");
      break;
    case "TWITTER":
      window.open("https://twitter.com/myDoctall", "_blank");
      break;
    case "LINKEDIN":
      window.open("https://www.linkedin.com/company/doctall/", "_blank");
      break;
    default:
      console.log("SEND TO HOME PAGE");
  }
};

export const _redirectToDoctorProfile = (queryParams) => {
  window.open(
    process.env.REACT_APP_CUNSUMER_SITE_URL + "/doctorDetails/" + queryParams,
    "_blank"
  );
};

export const _redirectToURL = (target) => {
  window.open(target, "_blank");
};
