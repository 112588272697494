import React from "react";
import {
    Grid,
    Button,
    Typography,
    useMediaQuery,
    Box,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import carrerImg from "../../assets/images/careerImg.png";
import careerImgMob from "../../assets/images/careerImgMob.png"
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header/Header";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => styles(theme));

const Careers = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
        
            <Header />
            <Helmet>
            <title>Careers at Doctall</title>
            <meta 
            name="description" 
            content="Join Doctall. We are a home to committed healthcare professionals, creatives and technical support staff who are driven daily by the vision of a healthier society."
            />
        </Helmet>

            <Grid container>
                <Grid item container direction="column" className={classes.contactHeader}>
                    <Typography className={classes.careerBannerHeading}>Careers</Typography>
                    <Typography className={classes.careerBannerTitle}>Join the Doctall team
                    </Typography>
                    <Typography className={classes.careerPara}>
                        Doctall is home to committed healthcare professionals, creatives and technical support staff who are driven daily by the vision of a healthier society.<br /><br />
                        Every day, our team works with dedication and strong passion to create a future where quality healthcare is accessible and available to all.<br /><br />
                        Email your CV to us at <span style={{ color: "#FF5C3C", fontWeight:"600" }}><a href="mailto:careers@doctall.com">careers@doctall.com</a></span>
                    </Typography>
                </Grid>
                <Grid sm={12} md={12} xs={12} className={classes.careerContainer} align="center" >
                    <Button style={{ color: "#B4331A", fontSize: "14px", fontWeight: "500", backgroundColor: "#F9F5FF", borderRadius: "16px", padding: "5px 15px", textTransform: "capitalize" }}>Open positions</Button>
                    <Typography className={classes.careerHeading}  >We’re looking for talented people</Typography>
                    <Typography className={classes.careerPara}>
                        We’re a 100% remote team spread all across the world. Join us!
                    </Typography>
                    <Grid className={classes.careerImage}>
                        <img src={carrerImg} alt="career banner image" style={{ width: "100%" }} />
                    </Grid>
                    <Grid variant="outlined" className={classes.careerImageMob}>
                        <img src={careerImgMob} alt=" career mobile banner image" style={{ width: "100%" }} />
                    </Grid>
                </Grid>
                <Grid sm={12} md={12} xs={12} className={classes.careerTextContainer} >
                    <Typography className={classes.cardHead}>Design</Typography>
                    <Box className={classes.careerCard} >
                        <Typography className={classes.cardHeading}>Product Designer</Typography>
                        <Typography className={classes.cardPara}>We’re looking for a mid-level product designer to join our team.</Typography>
                    </Box>
                    <Box className={classes.careerCard} >
                        <Typography className={classes.cardHeading}>UX Designer</Typography>
                        <Typography className={classes.cardPara}>We’re looking for a mid-level UX designer to join our team.</Typography>
                    </Box>
                </Grid>
                <Grid sm={12} md={12} xs={12} className={classes.careerTextContainer} >
                    <Typography className={classes.cardHead}>Software Development</Typography>
                    <Box className={classes.careerCard} >
                        <Typography className={classes.cardHeading}>Engineering Manager</Typography>
                        <Typography className={classes.cardPara}>We’re looking for an experienced engineering manager to join our team.</Typography>
                    </Box>
                    <Box className={classes.careerCard} >
                        <Typography className={classes.cardHeading}>Frontend Developer</Typography>
                        <Typography className={classes.cardPara}>We’re looking for an experienced frontend developer to join our team.</Typography>
                    </Box>
                    <Box className={classes.careerCard} >
                        <Typography className={classes.cardHeading}>Backend Developer</Typography>
                        <Typography className={classes.cardPara}>We’re looking for an experienced backend developer to join our team.</Typography>
                    </Box>
                </Grid>
                <Grid sm={12} md={12} xs={12} className={classes.careerTextContainer} >
                    <Typography className={classes.cardHead}>Customer Success</Typography>
                    <Box className={classes.careerCard} >
                        <Typography className={classes.cardHeading}>Customer Success Manager</Typography>
                        <Typography className={classes.cardPara}>We’re looking for a mid-level product designer to join our team.</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

export default Careers;
