import { Button, FormControl, Grid, Input, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/doctall_logo.png";
import mark from "../../../assets/images/mark.png";
import { showNotification } from '../../../components/common-notification/CommonNotification';
import "../../../components/Landing/Landing.scss";
import styles from "../../../components/Landing/styles";
import actions from '../../../redux/actions';
import { checkForUnique } from '../../../services/auth-services/authServices';
import { getCountryCodes, validateEmail } from '../../../services/doctall-living/staticServices';
import "../../Auth/auth.scss";

const useStyles = makeStyles((theme) => styles(theme));


export default function RegistrationPage() {
    const classes = useStyles();
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const { authActions } = actions;
    const [country, setCountry] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [reserror, setReserror] = useState(false);
    const [doctallTerms, isTermsAgreed] = useState(false);
    const [emailTerms, isEmailTermsAgreed] = useState(false);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    
    const [countryDialCode, setCountryDialCode] = useState();
    useEffect(() => {
        document.head.innerHTML+=`
        <meta name='description' content='Sign up to speak to a doctor online, book an appointment from anywhere and get medical treatment'/>
        <meta name='keywords' content='My keywords!!!'/>
        `
        
    },[])

    const onChangeFname = e => {
        const input = e.currentTarget.value;
        if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
            setFname(input);
        }
    };
    const onChangeLname = e => {
        const input = e.currentTarget.value;
        if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
            setLname(input);
            setReserror(true)
        }
    };




    const errorstyle = {
        border: reserror ? "1px solid #E04E47" : "1px solid #ADC2C2 ",
        outline: "none !important",
        borderRadius: "5px",

    };

    useEffect(() => {
        getCountryCode()
    }, []);


    const getCountryCode = async () => {
        try {
            let response = await getCountryCodes()
            if (response) {
              
                setCountry(response.data.country_code.toLowerCase());
            }
        } catch (error) {
            console.log(error)
        }
    }

    const _onPhoneChange = (value, country, e, formattedValue) => {
        setPhoneNumber(value)
        setCountryDialCode(country.dialCode)
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const { firstName, lastName, email } = event.target.elements;
        let payLoad = {
            "full_name": `${firstName.value}  ${lastName.value}`,
            "firstName": firstName.value,
            "lastName": lastName.value,
            "email": email.value,
            "mobile": phoneNumber,
            "password": '',
            "group": "consumer",
        }

        if (countryDialCode === "234" && phoneNumber.length < 13) {
            showNotification('error', "error !", "Please enter valid mobile number")
            return false;
        }
        else if (countryDialCode === "91" && phoneNumber.length < 12) {
            showNotification('error', "error !", "Please enter valid mobile number")
            return false;
        }

        else if (phoneNumber.length < 10) {
            showNotification('error', "error !", "please enter valid phone number")
            return false;
        }
        else if (!validateEmail(email.value)) {
            showNotification("error", "Failed !", "Please Enter Valid Email Address")
        }
        else if (doctallTerms === false) {
            showNotification("error", "Failed !", "Please agree to the doctall terms")
        }
        else if (emailTerms === false) {
            showNotification("error", "Failed !", "Please agree to the email terms")
        }



        else {
            checkUniqness(payLoad)
        }
    }

    const checkUniqness = async (payLoad) => {
        try {
            let _payLoad = { email: payLoad.email, mobile: payLoad.mobile }
            let response = await checkForUnique(_payLoad)
            if (response && response.status === 200 || response.status === 201) {
                dispatch(authActions.saveRegPayLoad(payLoad))
                navigate("/set-passcode")

            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                showNotification("error", "Failed !", "" + error.response.data.message)
            }
        }
    }

    const handleDoctallTerms = (event) => {
        isTermsAgreed(event.target.checked)
    }

    const handleEmailTerms = (event) => {
        isEmailTermsAgreed(event.target.checked)
    }

    return (

        <div className='login_page'>
            <Helmet>
                <title>Doctall.com | Signup</title>
                <meta 
                name='description' 
                content='Sign up to speak to a doctor online, book an appointment from anywhere and get medical treatment' 
                />
            </Helmet>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12} >
                    <div className='Login_img_box'>
                        <img src={mark} alt="login_image" className='Login_img' />
                    </div>

                </Grid>
                <Grid item sm={6} xs={12} >
                    <div className='login_container'>
                        <div className='doctall_logo'>
                            <Link to="/">
                                <img src={logo} alt="login_image" />
                            </Link>
                        </div>
                        <h1 className='Login_heading'>Create an account</h1>
                        <p className='login_para'>Enjoy personalized healthcare and begin living better.</p>
                        <div className='form_section'>

                            <form onSubmit={handleSubmit}>
                                <FormControl variant="standard">
                                    <label htmlFor="name" className={classes.inputLabel}>
                                        First Name*
                                    </label>
                                    <Input

                                        onChange={onChangeFname}
                                        value={fname}
                                        
                                        placeholder='Enter your firstname'
                                        type="text"
                                        required
                                        name='firstName'
                                        className={classes.inputField}
                                        disableUnderline={true}
                                        inputProps={{
                                            maxLength: 25,
                                        }}
                                      
                                    />
                                   
                                    <label
                                        htmlFor="lastName"
                                        className={classes.inputLabel}
                                        style={{ paddingTop: "20px" }}
                                    >
                                        Last Name*
                                    </label>
                                    <Input
                                        onChange={onChangeLname}
                                        value={lname}

                                        placeholder='Enter your lastname'
                                        name='lastName'
                                        type="text"
                                        required
                                        className={classes.inputField}
                                        disableUnderline={true}
                                        inputProps={{
                                            maxLength: 25,
                                        }}
                                    />



                                    <label
                                        htmlFor="phoneNumber"
                                        className={classes.inputLabel}
                                        style={{ paddingTop: "20px" }}
                                    >
                                        Phone Number*
                                    </label>

                                    <PhoneInput
                                        style={errorstyle}
                                        country={country}
                                        value={phoneNumber}
                                        onChange={(value, country, e, formattedValue) => _onPhoneChange(value, country, e, formattedValue)}
                                        name='phoneNumber'
                                    />

                                    <label
                                        htmlFor="middleName"
                                        className={classes.inputLabel}
                                        style={{ paddingTop: "20px" }}
                                    >
                                        Email address
                                    </label>
                                    <Input
                                        placeholder='Enter your email address'
                                        name='email'
                                        type="email"
                                        required
                                        className={classes.inputField}
                                        disableUnderline={true}
                                    />
                                    <div style={{ paddingTop: "20px", paddingBottom: "0px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                                <input type="checkbox" onChange={(event) => handleDoctallTerms(event)} />

                                            </Grid>
                                            <Grid item xs={11}>
                                                <label for="vehicle1" className='check_label'>I accept Doctall’s Terms and Conditions</label>

                                            </Grid>

                                            <Grid item xs={1}><input type="checkbox" onChange={(event) => handleEmailTerms(event)} /></Grid>
                                            <Grid item xs={11}>
                                                <p className='check_label'>I agree to receive emails about Doctall promotions, products and services</p>
                                            </Grid>
                                        </Grid>

                                    </div>
                                    <Button variant="contained" type='submit' className={classes.subscribeButton} style={{ backgroundColor: "#C53B20" }}>
                                        Continue
                                    </Button>
                                </FormControl>
                            </form>
                            <p style={{ color: "#667085", fontSize: "14px", textAlign: "center", marginTop: "20px" }}>Already have an account? <Link to="/login"><span style={{ color: '#B4331A', cursor: 'pointer', fontWeight: '500' }}>Log in</span></Link></p>

                        </div>

                    </div>


                </Grid>

            </Grid>
        </div>
    )
}