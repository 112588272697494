import contactLooper from "../../assets/images/contactLooper.svg";

const CareersStyles = (theme) => ({
  contactHeader: {
    background: "#FFFFFF",
    paddingTop: "6.6rem",
    paddingLeft: "7.8rem",
    backgroundImage: `url(${contactLooper})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0%",
    height: "48.8rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "3.4rem",
      height: "56.4rem",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "none",
      backgroundRepeat: "none",
      backgroundPosition: "none",
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
      height: "66.4rem",
      paddingTop: "6.4rem",
    },
  },
  careerBannerHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#7F56D9",
    paddingBottom: "12px",
  },
  careerBannerTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    color: "#101828",
    [theme.breakpoints.down("sm")]: {
      fontSize: "42px",
      lineHeight: "50px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: "36px",
      lineHeight: "44px",
    },
  },
  careerPara:{
      fontFamily:"Visuelt Pro",
      fontStyle:"normal",
      lineHeight:"30px",
      width:"50%",
      fontSize:"20px",
      color:"#667085",
      marginTop:"1%",
      marginBottom:"50px",
      // marginBottom:"4%",
      [theme.breakpoints.down("sm")]: {
        lineHeight: "30px",
        width:"100%",
      },
  
      [theme.breakpoints.down("xs")]: {
        width:"100%",
      },
    
  },

  careerContainer:{
padding:"50px 100px",
[theme.breakpoints.down("sm")]: {
    padding:"10px"
  },
  [theme.breakpoints.down("xs")]: {
    padding: "10px",
  },

},
  careerHeading:{
    fontSize:"36px",
    color:"#101828",
    fontWeight:"bold",
    lineHeight:" 44px",
    padding: "10px 10px 0px 10px",
    marginTop:"1%",
    [theme.breakpoints.down("xs")]: {
      fontSize:"30px",
    },
},

careerImage:{
  width:"100%",
    [theme.breakpoints.down("sm")]: {
    display:"block",
      },
      [theme.breakpoints.down("xs")]: {
        display:"none",
      },
},
careerImageMob:{
  display:"none",
  [theme.breakpoints.down("sm")]: {
    display:"none",
      },
      [theme.breakpoints.down("xs")]: {
        display:"block",
      },

},
careerTextContainer:{
  padding:"20px 330px",
  [theme.breakpoints.down("sm")]: {
      padding:"10px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
},
careerCard:{
border:"1px solid #E4E7EC",
shadows:"none",
borderRadius:"16px",
margin:"20px 0px"

},
cardHead:{
  fontSize:"20px",
  color:"#101828",
  fontWeight:"500",

},
cardHeading:{
  fontSize:"18px",
  color:"#101828",
  fontWeight:"500",
  padding:"20px 20px 10px 20px",
},
cardPara:{
  fontSize:"16px",
  color:" #667085",
  padding:"20px",
},

});

export default CareersStyles;
