import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./../styles";
import { showNotification } from "../../../components/common-notification/CommonNotification";
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  useTheme,
  Input,
} from "@material-ui/core";
import { applyCoupon } from "../../../services/order-services/orderServices";

const useStyles = makeStyles((theme) => styles(theme));
export default function DiscountCode(props) {
  const _userInfo = useSelector((state) => state.authReducer);
  const classes = useStyles();
  const theme = useTheme();

  const { totalAmount, finalAmount, blockCouponid, couponAppliedFlags, diseaseUuid, guestState, adStatus } = props;
  console.log(diseaseUuid, "diseaseUuid")
  const [couponCode, setCouponCode] = useState()
  const [amount, setAmount] = useState('')
  const [successVisibility, setSuccessVisibility] = useState(false)
  const [finalMessage, setFinalMessage] = useState('')

  const handleCoupanCode = (event) => {
    couponAppliedFlags(false)
    setCouponCode(event.target.value)
  }


  useEffect(() => {
    setAmount(totalAmount)
  }, [])


  const handleSubmit = async () => {
    try {
      let payLoad;

        if (adStatus === false) {
          showNotification('error', "Failed !!", "Please Add Shipping Details In Order To Apply Discount !")
          throw new Error("Failed");
        }
      else if (couponCode == null || couponCode == undefined || couponCode == '') {
        showNotification('error', "Failed", "Please Enter Discount Code")
        throw new Error("Failed");
      }
      else if (guestState === false) {
        payLoad = {
          "user_uuid": _userInfo.userInfo.uuid,
          "coupon_code": couponCode,
          "total_amount": totalAmount,
          "type": "pharmacy",
          "category": 'pharmacy',
          disease_uuid: diseaseUuid
        }
      }
      else if (guestState === true){
        payLoad = {
          "coupon_code": couponCode,
          "total_amount": totalAmount,
          "type": "pharmacy",
          "category": 'pharmacy',
          disease_uuid: diseaseUuid
        }
      }

      const token = guestState === false ? _userInfo.userInfo.token : ''
     console.log(payLoad,"payLoad")

      let response = await applyCoupon(payLoad, token)

      if (response.status == 200 || response.status == 201) {
        setSuccessVisibility(true)
        if (response.data.finalmessage !== null || response.data.finalmessage !== '' && response.data.finalmessage !== undefined) {
          setFinalMessage(response.data.finalmessage)
        } else {
          setFinalMessage('discount')
        }
        setAmount(response.data.finalAmount)
        finalAmount(response.data.finalAmount)
        blockCouponid(response.data.coupon_applied_id)
        couponAppliedFlags(true)
      } else {
        setSuccessVisibility(false)
      }

    } catch (error) {
      if (error && error.response) {
        showNotification('error', "Failed", "" + error.response.data)
      }
      setSuccessVisibility(false)
    }
  }


  return (
    <div>
      {!successVisibility && (
        <div >
          <Grid item style={{ padding: "16px" }}>
            {/* <Typography
              align="left"
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#344054",
                paddingBottom: "6px",
              }}
            >
              Doctall Discount Code
            </Typography> */}

            <Input
              type="text"
              placeholder="Add Code"
              className={classes.checkoutInput}
              onChange={handleCoupanCode}
              value={couponCode}
              disableUnderline={true}
              style={{ width: "90%", height: '100%' }}
            />

            <Button
              variant="contained"
              className={classes.createAccount1}
              style={{ width: '24%', marginLeft: '65%', marginTop: '-13%' ,backgroundColor:"#00839B",color:"#fff",borderRadius:"1px solid #00839B"}}
              onClick={handleSubmit}
            >
              Claim Coupon{" "}
            </Button>
          </Grid>
        </div>
      )}
      {/* If Promo Code applied sucessfully */}
      {successVisibility && (
        <div  style={{ padding: "16px" }}>
          <div className="info-container">
            <div className={classes.infoText} style={{color:"#717782"}}>
              {`Your Discount Code (${couponCode && couponCode
                }) was successfully applied and ${finalMessage} has been applied`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
