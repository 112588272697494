const doctorsActions = {

    GET_SPECIALIST_DOCTOR: "GET_SPECIALIST_DOCTOR",
    GET_SPECIALIST_DOCTOR_SUCCESS: "GET_SPECIALIST_DOCTOR_SUCCESS",



    getSpecialistDoctors: (payLoad, pageNumber) => {
        return {
            type: doctorsActions.GET_SPECIALIST_DOCTOR,
            payLoad,
            pageNumber,
        };
    },
};

export default doctorsActions;