import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { fetchCategory } from '../../services/category-services/categoryServices';
import actions from '../actions';

const { categoryActions } = actions;

export function* fetchCategorys() {
    yield takeEvery(categoryActions.GET_CATEGORY, function* () {
        try {
            const response = yield fetchCategory();
            if (response.status === 200) {
                yield put({
                    type: categoryActions.GET_CATEGORY_SUCCESS,
                    categoryData: response.data
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}


export default function* rootSaga() {
    yield all([fork(fetchCategorys)]);
}