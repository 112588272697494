export const _LOGIN = '/user/auth/login';
export const _GoogleLogin = '/user/auth/google/login';
export const _FbLogin = '/user/auth/facebook/login';
export const _CHECK_UNIQUE_MAIL_PHONE = '/user/auth/availability';
export const _PLACE_ORDER = '/doctallnew/order';
export const _SAVE_ORDER_DETAILS = '/doctallnew/preorder';
export const _REQUEST_CALLBACK = '/doctallnew/requestCallBack';
export const _POST_TRANSACTION = '/payment/treatmentplan';
export const _CHANGE_ORDER_STATUS = '/doctallnew/order';
export const _APPLY_COUPON = '/coupon/apply';
export const _BLOCK_COUPON = '/coupon/applied/update';
export const _REGISTRATION = '/user/auth/register';
export const _RESET_PASSCODE = '/user/auth/reset-password';
export const _CHANGE_PASSCODE = '/user/auth/change-password';
export const _VERIFY_OTP = '/sms/verify-otp';
export const GET_DISEASES_CATEGORY = '/doctallnew/listcategorydisease';
export const GET_TREATMENT_PLANS = '/doctallnew/treatmentplan/plan/list';
export const GET_DISEASES_QUESTIONS = '/subscribequestions/fetch';
export const _SAVE_DISEASES_QUESTIONS = '/subscribequestions/save';
export const _GET_CITYS = '/uploadDoc/region/list';
export const _GET_AREAS = '/uploadDoc/region/city/list';
export const _GET_DELIVERY_AMOUNT = '/uploadDoc/delivery/amount';
export const _GET_ADDRESS = '/doctallnew/order/address'
export const _GET_SPECIALIST_DOCTORS = '/user/profile/speciality/doctor/list';
export const _GET_DIAGNOSTIC_TESTS = '/labtest/diagnostic/filterLists';
export const _GET_PACKAGE = '/doctallnew/biocheck/by';
export const _INFLUENCER_REGISTRATION = '/user/auth/influencer/user/register';