import {
  Button, Grid, makeStyles, Typography,
  useMediaQuery, useTheme
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import AboutSectionImg from "../../assets/images/AboutSectionImg.png";
import diseaseLooper1 from "../../assets/images/diseaseLooper1.svg";
import Doctor from "../../assets/images/Doctors.png";
import NewsletterForm from "../../components/Landing/components/NewsletterForm";
import PartnerSlider from "../../components/Landing/components/PartnerSlider";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header/Header";
import { _redirect } from '../../components/Redirect/Redirect';
import Appcontent from "./Appcontent";
import Productslider from "./Productslider";
import styles from "./style";

const useStyles = makeStyles((theme) => styles(theme));

export default function Whoareyou({ Banner }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const custommatchesXL = useMediaQuery("(min-width:1500px)");


  const openConsumerAPP = () => {
    _redirect("CONSUMER-FADN"); //CONSUMER-FADN
  };
  return (
    <>
      <Header />
      <Grid container>
        <div
          style={{
            backgroundImage: matchesMD ? "none" : `url(${diseaseLooper1})`,
            backgroundPosition: "106% 2%",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
            backgroundColor: "#F2F8F9",
          }}
        >
          <Grid
            item
            container
            direction="column"
            alignItems={matchesXS ? "center" : undefined}
            className={classes.dWQBanner}
            style={{
              backgroundImage: matchesMD ? "none" : `url(${Doctor})`,
              height: "650px",
              // backgroundRepeat:"no-repeat",
              backgroundPosition: "right",
            }}
          >
          <Typography className={classes.whoweare}>Who we are</Typography>
            <Typography
              className={classes.dWQTitle}
              style={{ paddingBottom: "16px" }}
              align={matchesXS ? "center" : undefined}
            >
              {/* {disease.name} */}
              Get well, live better
            </Typography>
            <Typography
              className={classes.dWQDesc}
              style={{ paddingBottom: "92px" }}
              align={matchesXS ? "center" : undefined}
            >
              {/* {disease.desc} */}
              Get personalised care from certified local and international
              doctors online
            </Typography>
            <Button variant="contained" className={classes.viewProductButton} onClick={openConsumerAPP}>
              Book a Doctor Now
            </Button>
          </Grid>
        </div>




        <Grid container className={classes.AbouUsContainer}>
                
                    <Grid sm={12} md={6} xs={12} className={classes.mhSubContainer}>
                    <Typography className={classes.AboutSectionHeading}>ABOUT US</Typography>
                    <Typography className={classes.AboutSectionPara}>
              
                    Doctall is a full-circle digital healthcare provider offering comprehensive primary and secondary medical services accessible anywhere, to everyone.
            </Typography>
            <Link to="/about-us" style={{ textDecoration: 'none' }}>
                        <Button variant="contained" className={classes.aboutSectionBtn} >Learn More</Button>
                        </Link>
                    </Grid>
                    <Grid sm={12} md={6} xs={12} align="center" >
                        <img src={AboutSectionImg} alt="img" className={classes.aboutSectionimage}/>
                    </Grid>
           
            </Grid>





        <Grid item container>
          <Productslider />
        </Grid>
        <Grid item container direction="column">
          <Appcontent />
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          className={classes.partnerContainer}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.partnerInnerContainer}
          >
            <Typography
              className={classes.partnerTitle}
              textAlign="center"
              style={{ paddingBottom: "42px" }}
            >
              OUR PARTNERS
            </Typography>
            <Grid item style={{ width: "100%" }}>
              <PartnerSlider />
            </Grid>
          </Grid>
        </Grid>
        {/* NewsLetter*/}
        <Grid
          item
          container
          direction={matchesMD ? "column" : "row"}
          className={classes.newsLetterContainer}
          alignItems={matchesMD ? "center" : undefined}
        >
          <Grid item className={classes.newsLetterContent} md>
            <Typography variant="h1" style={{ paddingBottom: "24px" }}>
              Subscribe to our newsletter today!
            </Typography>
            <Typography
              paragraph
              style={{ paddingBottom: "20px" }}
              className={classes.newLetterSubHeading}
            >
             The Doctall newsletter  brings the best health information that is really important to you - direct to your inbox.
            </Typography>
            <Typography paragraph className={classes.newLetterSubHeading}>
            We can also send you the latest and emerging health trends that will help keep your mind and body in good shape. So sign up today.
            </Typography>
          </Grid>
          <Grid
            item
            container
            className={classes.newsLetterForm}
            md
            justifyContent={custommatchesXL ? "center" : undefined}
          >
            <NewsletterForm />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
