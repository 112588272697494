import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import {
  Grid,
  Popover,
  Button,
  Popper,
  IconButton,
  Typography,
} from "@mui/material";
import styles from "../../components/ui/styles";
import theme from "../../components/ui/Theme";
import hDClose from "../../assets/images/hDClose.svg";
import FreeDoctorBanner from "../../assets/images/FreeDoctor.svg";
import hDTested from "../../assets/images/hDTested.svg";
import rightArrow from "../../assets/images/rightArrow.svg";
import FDElipse from "../../assets/images/FDElipse.svg";

import { makeStyles, useTheme } from "@material-ui/core";
// import NotFound from "./pages/notFound";

const useStyles = makeStyles((theme) => styles(theme));

const FreeDoctor = () => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    console.log(location.pathname);
    setOpen(true);
  }, [location.pathname]);

  const handleClose = () => {
    setOpen(false);
  };

  const id = open ? "simple-popover" : undefined;
  return (
    <Popover
      id={id}
      open={open}
      onClose={handleClose}
      autoClose={false}
      anchorReference="anchorPosition"
      anchorPosition={{ right: "10px" }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      className={classes.popover2}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item container justifyContent="flex-end">
          <IconButton
            onClick={handleClose}
            style={{
              borderRadius: "50%",
              marginRight: "16px",
              width: "18px",
            }}
          >
            <img src={hDClose} alt="close_icon" />
          </IconButton>{" "}
        </Grid>
        <Grid container justifyContent="center">
          <img src={FreeDoctorBanner} alt="banner" style={{ width: "302px" }} />
        </Grid>
        <Typography
          style={{
            textAlign: "center",
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",

            margin: "23px 0px 30px 0px",
            width: "302px",
          }}
        >
          The best things in life are free. Take advantage of this opportunity
          and speak to a doctor for FREE using this coupon code. Address those
          niggling symptoms AT NO COST.
        </Typography>
        <button
          variant="contained"
          className={classes.couponButton}
          style={{ marginBottom: "26px", position: "relative" }}
        >
          <img
            src={FDElipse}
            alt="FDElipse"
            style={{
              position: "absolute",
              left: "-5px",
            }}
          />
          BCA2022
          <img
            src={FDElipse}
            alt="FDElipse"
            style={{
              position: "absolute",
              right: "-5px",
            }}
          />
        </button>
        <a href={`${process.env.REACT_APP_CUNSUMER_SITE_URL}`} target="_blank" style={{textDecoration:"none",color:"white"}}>
        <button variant="contained" className={classes.findMoreButton}>
          Book Now{" "}
          <img src={rightArrow} alt="arrow" style={{ marginLeft: "6px" }} />
        </button>
        </a>

      </Grid>
    </Popover>
  );
};

export default FreeDoctor;
