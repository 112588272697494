import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { getDoctallLivingArticles, getDoctallPediaArticles } from '../../services/doctall-living/staticServices';
import actions from '../actions';

const { blogActions } = actions;

export function* fetchLivingBlog() {
    yield takeEvery(blogActions.GET_LIVING_BLOG, function* ({ categoryId, pageNumber }) {
        try {
            const response = yield getDoctallLivingArticles(categoryId, pageNumber);
            if (response.status === 200) {
                yield put({
                    type: blogActions.GET_LIVING_BLOG_SUCCESS,
                    livingData: response.data
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}

export function* fetchPediaBlog() {
    yield takeEvery(blogActions.GET_PEDIA_BLOG, function* ({ categoryId, pageNumber }) {
        try {
            const response = yield getDoctallPediaArticles(categoryId, pageNumber);
            if (response.status === 200) {
                yield put({
                    type: blogActions.GET_PEDIA_BLOG_SUCCESS,
                    pediaData: response.data
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}


export default function* rootSaga() {
    yield all([fork(fetchLivingBlog), fork(fetchPediaBlog)]);
}