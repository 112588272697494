import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  CardContent,
  Card,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import usePagination from "../../components/Landing/components/usePagination";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./DIseaseWithQues/styles";
import previousButton from "../../assets/images/previousButton.svg";
import nextButton from "../../assets/images/nextButton.svg";
import { _redirect, _redirectToURL } from "../../components/Redirect/Redirect";
import blogActions from "../../redux/actions/blogActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getDiesesIdForBlog,
  getDiesesIdForPediaBlog,
} from "./utils/diseasesIds";

import arrowUpRight from "../../assets/images/arrowUpRight.svg";
import DoctallLivingImages from "../../components/Landing/components/DoctallLivingImages";
import DoctallPediaImages from "../../components/Landing/components/DoctallPediaImages";
const useStyles = makeStyles((theme) => styles(theme));

const PreviousButton = () => <img src={previousButton} alt="previous" />;
const NextButton = () => <img src={nextButton} alt="next" />;

const Blogs = ({ blogs, diseaseName }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const _blogContent = useSelector((state) => state.blogReducer);

  console.log(_blogContent.pediaData, "afghjk");

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  useEffect(() => {
    dispatch(
      blogActions.getLivingBlog(getDiesesIdForBlog(diseaseName), PER_PAGE)
    );

    dispatch(
      blogActions.getPediaBlog(getDiesesIdForPediaBlog(diseaseName), PER_PAGE)
    );
  }, []);

  const _DATA = usePagination(
    _blogContent &&
      _blogContent.livingData &&
      _blogContent.livingData.length !== 0 &&
      _blogContent.livingData,
    PER_PAGE
  );

  const totalCount = Math.ceil(
    _DATA && _DATA.currentData() && _DATA.currentData().length / PER_PAGE
  );

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handlePrevious = (p) => {
    let previousPage = p - 1;
    setPage(previousPage);
    _DATA.jump(previousPage);
  };
  const handleNext = (p) => {
    let nextPage = p + 1;
    setPage(nextPage);
    _DATA.jump(nextPage);
  };

  return (
    <>
      <Grid
        container
        md={4}
        lg={12}
        style={{ background: "#ffffff", paddingTop: "30px" }}
      >
        {/* Living Blog */}
        <Grid md={4} lg={12}>
          <Grid item container>
            {_DATA &&
              _DATA.currentData() &&
              _DATA.currentData().map((blog, index) => (
                <Grid
                  item
                  key={`${blog}${index}`}
                  style={{
                    marginLeft: matchesMD ? "0px" : "32px",
                    marginRight: matchesMD
                      ? matchesXS
                        ? "0px"
                        : "32px"
                      : "0px",
                    marginBottom: "8.8rem",
                    position: "relative",
                    minHeight: "550px",
                  }}
                >
                  <Card classes={{ root: classes.blogCard }}>
                    <DoctallLivingImages data={blog} />
                    <CardContent classes={{ root: classes.cardSetter }}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: "700",
                          color: "#C53B20",
                          marginTop: "32px",
                          height: "auto",
                          position: "relative",
                        }}
                      >
                        {"Doctall Living"}
                      </Typography>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        style={{
                          margin: "12px 0px ",
                          position: "relative",
                          // height: "auto",
                          textAlign: "left",
                          // display: "flex",
                          // justifyContent: "space-around",
                          // flexDirection: "row",
                        }}
                      >
                        <Typography
                          variant="h4"
                          style={{ width: matchesXS ? "250px" : "300px" }}
                        >
                          {/*  {blog.title.rendered.replace(";", "")} */}
                          {blog.title.rendered
                            .toString()
                            .replace("<p>", "")
                            .replace("</p>", "")
                            .replace("&#8217;", "'")
                            .replace("&#038;", "&")
                            .replace("&#8220;", "'")
                            .replace("&#8221;", "'")
                            .replace("&#038;", "&")
                            .replace("&nbsp;", "")}
                        </Typography>
                        <img
                          src={arrowUpRight}
                          alt="Arrow Up Right"
                          onClick={() => _redirectToURL(blog.link)}
                          style={{ cursor: "pointer" }}
                        />
                      </Grid>
                      <Typography
                        className={classes.cardBody}
                        style={{
                          height: "50px",
                          textAlign: "left",
                          //   position: "absolute",

                          // display: "flex",
                          // justifyContent: "space-around",
                        }}
                      >
                        {blog.excerpt.rendered
                          .toString()
                          .replace("<p>", "")
                          .replace("</p>", "")
                          .replace("&#8211;", "-")
                          .replace("&#8211;", "-")
                          .replace("&#8217;", "'")
                          .replace("&nbsp;", "")}
                      </Typography>
                      <Grid container justifyContent="left">
                        <Button
                          variant="contained"
                          disableRipple
                          className={classes.blogButton}
                          onClick={() => _redirectToURL(blog.link)}
                          style={{
                            // position: "absolute",
                            display: "flex",
                            //bottom: "7px",
                          }}
                        >
                          Read more
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid md={12}>
          <Grid item container>
            {_blogContent &&
              _blogContent.pediaData &&
              _blogContent.pediaData.length > 0 &&
              _blogContent.pediaData.map((blog, index) => (
                <Grid
                  item
                  key={`${blog}${index}`}
                  style={{
                    marginLeft: matchesMD ? "0px" : "32px",
                    marginRight: matchesMD
                      ? matchesXS
                        ? "0px"
                        : "32px"
                      : "0px",
                    marginBottom: "8.8rem",
                    position: "relative",
                    height: "550px",
                  }}
                >
                  <Card
                    classes={{ root: classes.blogCard }}
                    style={{ height: "500px" }}
                  >
                    <DoctallPediaImages data={blog} />
                    <CardContent classes={{ root: classes.cardSetter }}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: "700",
                          color: "#C53B20",
                          marginTop: "32px",
                          postion: "relative",
                        }}
                      >
                        {"Doctall Pedia"}
                      </Typography>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        style={{ margin: "12px 0px" }}
                      >
                        <Typography
                          variant="h4"
                          style={{ width: matchesXS ? "250px" : "300px" }}
                        >
                          {blog.title.rendered
                            .toString()
                            .replace("<p>", "")
                            .replace("</p>", "")
                            .replace("&#8217;", "'")
                            .replace("&#038;", "&")
                            .replace("&#8220;", "'")
                            .replace("&#8221;", "'")
                            .replace("&#038;", "&")
                            .replace("&nbsp;", "")}
                        </Typography>
                        <img
                          src={arrowUpRight}
                          alt="Arrow Up Right"
                          onClick={() => _redirectToURL(blog.link)}
                          style={{ cursor: "pointer" }}
                        />
                      </Grid>
                      <Typography
                        className={classes.cardBody}
                        style={{
                          height: "50px",
                          //  position: "absolute",

                          //  display: "flex",
                        }}
                      >
                        {blog.excerpt.rendered
                          .toString()
                          .replace("<p>", "")
                          .replace("</p>", "")
                          .replace("&#8217;", "'")
                          .replace("&#8211;", "-")
                          .replace("&#8211;", "-")
                          .replace("&nbsp;", "")
                          .replace("[&hellip;]", "")}
                      </Typography>
                      <Grid container justifyContent="left">
                        <Button
                          variant="contained"
                          disableRipple
                          className={classes.blogButton}
                          onClick={() => _redirectToURL(blog.link)}
                          style={{
                            //position: "absolute",
                            display: "flex",
                            //bottom: "5px",
                          }}
                          // Need to correct this button style
                        >
                          Read more
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Grid>

        {/* Living Blog End */}

        {/* Pedia Blog Start */}

        {/*Pedia Blog End  */}

        {/* <hr
        style={{
          height: "1px",
          border: "none",
          background: "#E4E7EC",
          maxWidth: "1247px",
          marginBottom: "20px",
        }}
      />
      <Grid
        item
        container
        direction={matchesSM ? "column" : "row"}
        justifyContent={matchesSM ? undefined : "space-between"}
        alignItems={matchesSM ? "center" : undefined}
        style={{ maxWidth: "1247px" }}
      >
        <Button
          disableRipple
          disabled={_DATA && _DATA.currentPage === 1}
          onClick={() => {
            _DATA && _DATA.prev();
            handlePrevious(_DATA && _DATA.currentPage);
          }}
          style={{ width: "84px" }}
        >
          <PreviousButton />
        </Button>

        <Pagination
          count={totalCount}
          size="large"
          page={page}
          shape="circular"
          color="secondary"
          variant="outlined"
          onChange={handleChange}
          style={{ alignSelf: "center" }}
          siblingCount={0}
          boundaryCount={2}
          hideNextButton
          hidePrevButton
        />
        <Button
          disableRipple
          disabled={_DATA && _DATA.currentPage === _DATA.maxPage}
          onClick={() => {
            _DATA && _DATA.next();
            handleNext(_DATA && _DATA.currentPage);
          }}
          style={{ width: "58px" }}
        >
          <NextButton />
        </Button>
      </Grid> */}
      </Grid>
    </>
  );
};

export default Blogs;
