import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

const Blue = "#00839B";
const Orange = "#FF5C3C";

export default createTheme({
  palette: {
    common: {
      blue: `${Blue}`,
      orange: `${Orange}`,
    },
    primary: {
      main: `${Blue}`,
    },
    secondary: {
      main: `${Orange}`,
    },
  },

  typography: {
    fontFamily: "Visuelt Pro",
    tab: {
      "&:hover": {
        color: "red",
      },
    },
    secondaryButton: {
      background: Orange,
      color: "#ffffff",
      textTransform: "none",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      padding: "12px 20px",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      "&:hover": {
        background: Orange,
        boxShadow: "inherit",
        color: "#ffffff",
      },
    },
    primaryButton: {
      background: Blue,
      color: "#ffffff",
      textTransform: "none",
      "&:hover": {
        background: Blue,
      },
    },

    tertiaryButton: {
      background: "#FFFFFF",
      border: "1px solid #FFFFFF",
      boxSizing: "border-box",
      boxShadow: "0px 0.973026px 1.94605px rgba(16, 24, 40, 0.05)",
      borderRadius: "8px",
      padding: "10px 18px",
      "&:hover": {
        background: "#FFFFFF",
      },
    },
    title: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
    },
    h1: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "48px",
      lineHeight: "60px",
      letterSpacing: "-0.02em",
      color: "#002931",
    },

    h2: {
      color: "#FAFAFF",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "32px",
    },
    h3: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "18px",
      color: "#FF5C3C",
    },
    h4: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "32px",
      display: "flex",
      alignItems: "center",
      color: "#002931",
      [breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },

    h5: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "28px",
      color: "#FAFAFF",
      [breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },

    h6: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#002931",
    },
    subtitle1: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "36px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
      color: "#002931",
      [breakpoints.down("sm")]: {
        fontSize: "24px",
        lineHeight: "36px",
      },
    },

    body1: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
    },
    body2: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "30px",
      color: "#002931",
      [breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "24px",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      },
    },
    imageCard: {
      width: "400px",
      height: "295px",
      borderRadius: "10px",
      backgroundPosition: "right bottom",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
  },

  overrides: {
    MuiPaper: {
      root: {
        left: "0px",
      },
      elevation0: {
        left: "0px",
      },
      rounded: {
        left: "0px",
      },
    },
    MuiPopover: {
      paper: {
        maxWidth: "100%",
      },
    },
    MuiTabs: {
      root: {
        paddingTop: "16px",
        paddingBottom: "16px",
        minHeight: "56px",
      },
    },
    MuiTab: {
      root: {
        padding: "0px",
        minWidth: "auto",
        [breakpoints.up("sm")]: {
          minWidth: "auto",
        },
      },
      labelIcon: {
        paddingTop: "0px",
        minHeight: "0px",
      },
      textColorInherit: {
        opacity: "1",
      },
    },
    MuiCardActions: {
      root: {
        padding: "0px",
      },
    },
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: "0px",
        },
      },
    },
    MuiAccordion: {
      root: {
        "&::before": {
          backgroundColor: "transparent",
        },
      },
    },

    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      colorSecondary: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiRadio: {
      "colorSecondary.MuiChecked": {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiButton: {
      root: {
        padding: "0px",
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "white",
        width: "199px",
        height: "124px",
        color: "#667085",
        padding: "12px",
        fontFamily: "Visuelt Pro",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        borderRadius: "8px",
      },
      arrow: {
        backgroundColor: "white",
        color: "#ffffff",
      },
      tooltipPlacementLeft: {
        right: "20px",
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: "0px",
      },
    },
  },
});
