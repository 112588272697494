import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ComingSoon from '../../components/coming-soon/ComingSoon'
import ComingSoonDoctallTest from "../../components/coming-soon/ComingSoonDoctallTest"


export default function PharmacyPage() {
    // useEffect(() => {
    //     document.title =" Doctall Pharmacy: Buy Medicines Online at Affordable Prices"
    //     document.head.innerHTML+=`
        
       
    //     <meta name='description' content='Buy medicines online at discounted prices from our nationwide network of trusted pharmacies. Compare prices, order drugs to your doorstep, and store your prescriptions on our secure cloud storage'/>
    //     ` 
    // },[])

    let comingSoonHead = 'Precision medicine made simpler for you';
    let description = 'Powerful, self-serve product and growth analytics to help you convert, engage, and retain more.';


    return (
        <>
        <Helmet>
            <title>Doctall Pharmacy: Buy Medicines Online at Affordable Prices</title>
            <meta 
            name='description' 
            content='Buy medicines online at discounted prices from our nationwide network of trusted pharmacies. Compare prices, order drugs to your doorstep, and store your prescriptions on our secure cloud storage' 
            />
        </Helmet>
            <ComingSoon
                head={comingSoonHead}
                description={description}
            />
            {/* <ComingSoonDoctallTest
                   head={comingSoonHead}
                description={description}
            /> */}
        </>
    )
}