import React from "react";
import { Grid, Typography, useMediaQuery, Hidden } from "@material-ui/core";
import { useNavigate, useParams ,Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import Share from "./utils/Share";
import './Banner.css'
import "../AboutaUs/AboutUs.scss";

import stars from "../../assets/images/stars.svg";
import diseaseLooper1 from "../../assets/images/diseaseLooper1.svg";
import { Helmet } from "react-helmet";
import { borderRadius } from "@mui/system";

const useStyles = makeStyles((theme) => styles(theme));

const Banner = ({ name, desc, banner, testimonial, title, content }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");

  const { selectedDisease } = useParams();
  const navigate = useNavigate()

  const onBuyTreatment = () => {
    // if (selectedTreatment === null) {
    //   showNotification("error", "Failed !", "Please Select Dosage");
    // } else {
    //   selectedTreatment["finalPrice"] = finalPrice;
    //   selectedTreatment["userSelectedQuantity"] = quantity;
    //   navigate(`/${selectedDisease}/questionnaire`, {
    //     state: {
    //       planDetails: planInfo,
    //       diseaseName: planInfo.diseases_name,
    //       selectedPlan: selectedTreatment,
    //     },
    //   });
    // }
    navigate(`/${selectedDisease}/questionnaire`)
  };


  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={content} />
      </Helmet>
      <div
        //
        style={{
          backgroundImage: matchesMD ? "none" : `url(${diseaseLooper1})`,
          backgroundPosition: "106% 2%",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
          backgroundColor: "#F2F8F9",
         
        }}
      >
        <Grid
          item
          container
          direction="column"
          alignItems={matchesXS ? "center" : undefined}
          className={classes.diseaseBanner}
          style={{
            backgroundImage: matchesMD ? "none" : `url(${banner})`,
          }}
        >
          <Typography
            className={classes.diseaseTitle}
            style={{ paddingBottom: "16px" }}
            align={matchesXS ? "center" : undefined}
          >
            {name}
          </Typography>
          <Typography
            className={classes.diseaseDesc}
            style={{ paddingBottom: "42px" }}
            align={matchesXS ? "center" : undefined}
          >
            {desc}
          </Typography>
        { name === 'Erectile Dysfunction' &&
          <Typography 
          align={matchesXS ? "center" : undefined}
          >
            <div 
            padding={matchesXS ? "40px 50px" : "20px"}
            
            style={{
              padding:"15px 15px",
              lineHeight:"15px",
              backgroundColor:"#fff",
              borderRadius:"20px",
              alignItems:"center",
              display:"inline-block",
              justifyContent:"center",
              boxShadow: "0px 0px 14px rgba(0, 131, 155, 0.15)",
              marginBottom:"40px",
            }}>
              <div>
                <p 
                fontSize={matchesXS ? "20px" : "25px"}
                style={{
                  fontFamily:"Visuelt Pro",
                  fontSize:"18px",
                  color:"#002931",
                  lineHeight:"25px",
                  fontWeight:"300",
                  textAlign:"center",
                }}> Explore our specialized Men's Health <br/> solutions for effective treatment plans
                </p>
              </div><br />
              <div className="newsletter_btn">
                <a 
                style={{
                  display:"flex",
                  justifyContent:"center",
                  textDecoration:"none",
                  color:"white",
                  fontSize:"16px"}}
                // style={{
                //   fontSize:"16px",
                //   padding:"15px 120px",
                //   borderRadius:"8px",
                //   textAlign:"center",
                //   fontFamily:"Visuelt Pro",
                //   fontWeight:"300",
                //   backgroundColor:"#FF5C3C",
                //   boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                //   color:"#fff",
                //   opacity:"0.9",
                //   cursor:"pointer",
                //   textDecoration:"none",
                // }} 
                href="https://broall.com/pages/ed-get-started"
                // onClick={onBuyTreatment}
                target="_blank"
                >
                Discover Now
                </a>
              </div>
            </div>
          </Typography>
        }

{ name === 'Premature Ejaculation' &&
          <Typography 
          align={matchesXS ? "center" : undefined}
          >
            <div 
            padding={matchesXS ? "40px 50px" : "20px"}
            
            style={{
              padding:"15px 15px",
              lineHeight:"15px",
              backgroundColor:"#fff",
              borderRadius:"20px",
              alignItems:"center",
              display:"inline-block",
              justifyContent:"center",
              boxShadow: "0px 0px 14px rgba(0, 131, 155, 0.15)",
              marginBottom:"40px",
            }}>
              <div>
                <p 
                fontSize={matchesXS ? "20px" : "25px"}
                style={{
                  fontFamily:"Visuelt Pro",
                  fontSize:"18px",
                  color:"#002931",
                  lineHeight:"25px",
                  fontWeight:"300",
                  textAlign:"center",
                }}>  Explore our specialized Men's Health <br/> solutions for effective treatment plans
                </p>
              </div><br />
              <div className="newsletter_btn">
                <a 

                style={{
                  display:"flex",
                  justifyContent:"center",
                  textDecoration:"none",
                  color:"white",
                  fontSize:"16px"}}

                
                // style={{
                //   fontSize:"16px",
                //   padding:"15px 120px",
                //   borderRadius:"8px",
                //   textAlign:"center",
                //   fontFamily:"Visuelt Pro",
                //   fontWeight:"300",
                //   backgroundColor:"#FF5C3C",
                //   boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                //   color:"#fff",
                //   opacity:"0.9",
                //   cursor:"pointer",
                //   textDecoration:"none",
                // }}
                href="https://broall.com/pages/pe-get-started"
                // onClick={onBuyTreatment}
                target="_blank"
                >
                 Discover Now
                </a>
              </div>
            </div>
          </Typography>
        }

{ name === 'Hair Loss' &&
          <Typography 
          align={matchesXS ? "center" : undefined}
          >
            <div 
            padding={matchesXS ? "40px 50px" : "20px"}
            
            style={{
              padding:"15px 15px",
              lineHeight:"15px",
              backgroundColor:"#fff",
              borderRadius:"20px",
              alignItems:"center",
              display:"inline-block",
              justifyContent:"center",
              boxShadow: "0px 0px 14px rgba(0, 131, 155, 0.15)",
              marginBottom:"40px",
              
            }}>
              <div>
                <p 
                fontSize={matchesXS ? "20px" : "25px"}
                style={{
                  fontFamily:"Visuelt Pro",
                  fontSize:"18px",
                  color:"#002931",
                  lineHeight:"25px",
                  fontWeight:"300",
                  textAlign:"center",
                }}>  Explore our specialized Men's Health <br/> solutions for effective treatment plans
                </p>
              </div><br />
              <div className="newsletter_btn">
                <a 

                style={{
                  display:"flex",
                  justifyContent:"center",
                  textDecoration:"none",
                  color:"white",
                  fontSize:"16px"}}

                //  padding={matchesXS ? "15px 120px" : "16px 100px"}
                // style={{
                //   fontSize:"16px",
                //   padding:"15px 120px",
                //   borderRadius:"8px",
                //   textAlign:"center",
                //   fontFamily:"Visuelt Pro",
                //   fontWeight:"300",
                //   backgroundColor:"#FF5C3C",
                //   boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                //   color:"#fff",
                //   opacity:"0.9",
                //   cursor:"pointer",
                //   textDecoration:"none",
                // }}
                href="https://broall.com/pages/hl-get-started"
                // onClick={onBuyTreatment}
                target="_blank"
                >
                Discover Now
                </a>
              </div>
            </div>
          </Typography>
        }
          <span style={{ marginBottom: "12px" }}>
            <img src={stars} alt="rating" />
          </span>
          <Typography
            className={classes.diseasebannerTestimonial}
            style={{ paddingBottom: "3px" }}
            align={matchesXS ? "center" : undefined}
          >
            {testimonial}
          </Typography>
          <Typography className={classes.diseasebannerTestiUser}>
            DOCTALL PATIENT
          </Typography>
          <Grid item className={classes.shareContainer}>
            <Share />
          </Grid>
          <Hidden mdUp>
            <img
              src={banner}
              alt="bannerImage"
              width={matchesSM ? "300px" : "360px"}
              style={{  marginTop: "30px" }}
            />
          </Hidden>
        </Grid>
      </div>
    </>
  );
};

export default Banner;
