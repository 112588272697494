
import Wellness from "../../assets/images/Wellness.png";
import specialistcard from "../../assets/images/specialistCard.svg";
import diagnosisCard from "../../assets/images/diagnosisCard.svg";
import WellnessPattern from "../../assets/images/wellnessPattern.svg";
import specialistPattern from "../../assets/images/specialistPattern.svg";
import diagnosisPattern from "../../assets/images/diagnosisPattern.svg";

const MensHelthStyles = (theme) => ({
  MensHelthContainer: {
    padding: "100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },

  },
  CardHeading: {
    fontSize: "20px",
    color: "#002931",
    fontWeight: "500",
    lineHeight: "30px",
    padding: "10px",

  },
  CardSubHeading: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#002931",

  },
  MensHelthMainContainer: {
    padding: "0px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  MensHelthMidContainer: {
    border: "1px solid rgba(174, 215, 223, 0.2);",
    background: "rgba(242, 248, 249, 0.5);",
  },


  Heading: {
    fontSize: "48px",
    fontWeight: "500",
    color: "#002931",
    lineHeight: "60px",
  },
  mensHelthBtn: {
    background: "#FF5C3C",
    padding: "12px 20px",
    color: "#fff",
    fontSize: "16px",
    marginTop: "20px",
  },
  mhSubContainer: {
    padding: "50px 80px",
  

    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },

  MHPackegeContainer: {
    background: "#F2F8F9",
    padding: "100px",
    
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  MHCradMainHeading:{
    fontSize:"36px",
    color:"#002931",
    lineHeight:"44px",
    fontWeight:"bold",

  },

  MhPackegeCard: {
    background: "#fff",
    // margin:"10px",
    padding: "20px 15px",
    border:"none",

  },
  MHCradHeading: {
    padding: "10px",
    background: "#F2F8F9",
    color: "#00839B",
    fontSize: "16px",
    fontWeight: "500",
  },
  MHCradSubHeading: {
    padding: "20px",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#002931",
  },
  MHCardPoints: {

    color: "#00839B",
    fontSize: "12px",
    background: "#F2F8F9",
    padding: "20px",
    fontWeight: "500",
    lineHeight: "17px",
  },
  MHCardInvest: {
    margin: "20px 0px",
    padding: "10px 20px",
    color: "#00839B",
    fontSize: "12px",
    fontWeight: "500",
    background: "#FEF0C7",
  },
  MHCardBiomarkers: {
    margin: "20px 0px",
    padding: "10px 20px",
    color: "#00839B",
    fontSize: "12px",
    fontWeight: "500",
    background: "#D1FADF",
  },
  MHCardTestBtn: {
    background: " #00839B",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "500",
    padding: "12px 20px",
    border: "1px solid #00839B",
    borderRadius: "8px",
  },




  healthyLivingContainer: {
    paddingLeft: "9.5rem",
    paddingTop: "6.8rem",
    paddingBottom: "10.8rem",
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
  },
  wellnessCard: {
    ...theme.typography.imageCard,
    backgroundImage: `url(${Wellness}),url(${WellnessPattern})`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#00839B",
    //opacity: "0.8",
    borderRadius: "10px",
    backgroundBlendMode: "luminosity,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${WellnessPattern})`,
      width: "300px",
    },
  },

  specialistCard: {
    ...theme.typography.imageCard,
    backgroundImage: `url(${specialistcard}),url(${specialistPattern})`,
    background: "#37CC8C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundBlendMode: "luminosity,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${specialistPattern})`,
      width: "300px",
    },
  },
  diagnosisCard: {
    ...theme.typography.imageCard,
    backgroundImage: `url(${diagnosisCard}),url(${diagnosisPattern})`,

    background: "#BF0B02",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundBlendMode: "luminosity,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${diagnosisPattern})`,
      width: "300px",
    },
  },
  livingButton: {
    ...theme.typography.tertiaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#143656",
    position: "absolute",
    bottom: "50px",
    [theme.breakpoints.down("sm")]: {
      bottom: "20px",
    },
  },
  drimage:{
    width:"auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  bookDrBtn:{
    color: "#fff",
     background: "#00839B",
      padding: "15px 30px",
       fontSize: "16px",
    
  },

});

export default MensHelthStyles;
