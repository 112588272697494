import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  CardContent,
  Card,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import CheckIcon from "../../../assets/images/CheckIcon.svg";
import premiumServiceIcon from "../../../assets/images/premiumServiceIcon.svg";
import CallBackPopUp from "../../callbackPopUp/CallBackPopUP";

const useStyles = makeStyles((theme) => styles(theme));

const TreatmentPlan = ({ treatmentPlan }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const [callBackModel, setCallBackModel] = useState(false);

  return (
    <Grid item container>
      <Grid
        item
        container
        className={classes.AvailablePlansContainer}
        justifyContent={matchesXS ? "center" : undefined}
      >
        {treatmentPlan &&
          treatmentPlan.AvailablePlans.map((plan) => (
            <Grid
              item
              style={{
                marginRight: matchesSM ? "0px" : "32px",
                marginBottom: "42px",
              }}
            >
              <Card
                classes={{ root: classes.TreatmentPlanContainer }}
                elevation="false"
              >
                <CardContent
                  classes={{ root: classes.TreatmentPlanCardContent }}
                >
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    style={{
                      background: "rgba(242, 248, 249, 0.5)",
                      border: "1px solid rgba(174, 215, 223, 0.2)",
                      boxSizing: "border-box",
                      borderRadius: "8px 8px 0px 0px",
                      height: "auto",
                      paddingLeft: "32px",
                      paddingRight: "24px",
                    }}
                  >
                    <Grid item style={{ paddingTop: "38px" }}>
                      <Grid item container alignContent="center">
                        <Typography
                          className={classes.TreatmentPlanTitle}
                          style={{ paddingRight: "8px" }}
                        >
                          {plan.title}
                        </Typography>
                        {plan.popular && (
                          <Typography
                            style={{
                              background: "#FFF0ED",
                              borderRadius: "16px",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#FF5C3C",
                              width: "72px",
                              height: "24px",
                              padding: "2px 10px",
                              alignSelf: "center",
                            }}
                          >
                            Popular
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item style={{ paddingTop: "16px" }}>
                      <Grid item container direction="column">
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#667085",
                          }}
                        >
                          Starts from
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "36px",
                            lineHeight: "44px",
                            color: "#101828",
                            letterSpacing: "-0.02em",
                          }}
                        >
                          {plan.price}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    style={{
                      paddingLeft: matchesXS ? "16px" : "42px",
                      paddingTop: "32px",
                    }}
                  >
                    {plan.benefits.map((benefit) => (
                      <Grid
                        item
                        container
                        direction="row"
                        style={{ paddingBottom: "16px" }}
                      >
                        {matchesXS ? null : (
                          <img
                            src={CheckIcon}
                            alt="check-icon"
                            style={{ alignSelf: "flex-start" }}
                          />
                        )}
                        <Typography
                          style={{
                            paddingLeft: "12px",
                            width: matchesXS ? "300px" : "393px",
                          }}
                        >
                          {benefit}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                  <Button
                    variant="contained"
                    className={classes.StartButton}
                    onClick={() => setCallBackModel(!callBackModel)}
                  >
                    Get started
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}

        <CallBackPopUp
          open={callBackModel}
          close={() => setCallBackModel(!callBackModel)}
        />
      </Grid>
      <hr
        style={{
          background: "rgba(174, 215, 223, 0.4)",
          width: "93%",
          marginBottom: "42px",
        }}
      />
      <Grid item container className={classes.premiumServicesContainer}>
        <Grid
          item
          container
          direction="column"
          style={{
            marginLeft: matchesSM ? "24px" : "79px",
            paddingTop: "69px",
            width: matchesSM ? undefined : "608px",
          }}
        >
          <Grid item container style={{ paddingBottom: "26px" }}>
            <img src={premiumServiceIcon} alt="service-icon" />
            <Typography
              style={{
                paddingLeft: "8px",
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "900",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#475467",
                alignSelf: "center",
              }}
            >
              PREMIUM CONCIERGE SERVICES{" "}
            </Typography>
          </Grid>
          <Typography
            style={{
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "30px",
              lineHeight: "38px",
              color: "#002931",
              paddingBottom: "36px",
            }}
          >
            Find out more about our bespoke premium diagnostic services tailored
            for your convenience
          </Typography>
          <Button variant="contained" className={classes.callbackButton}>
            Request a Callback
          </Button>
        </Grid>
      </Grid>
    </Grid>

  );
};

export default TreatmentPlan;
