import React, { useEffect, useState, useRef } from "react";
import "../styles.js";
import { questions } from "../Questions";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import styles from "../styles";
import { makeStyles, useTheme } from "@material-ui/core";
import AlertClose from "../../../assets/images/AlertClose.svg";
import { NeutralComp, YesComp, NoComp, Alert } from "../util/UiComp";
import CallBackPopUp from "../../callbackPopUp/CallBackPopUP";
import { saveQuestionnaire } from "../../../services/category-services/categoryServices";

const useStyles = makeStyles((theme) => styles(theme));
var uuid ;
export const MultiChoice = ({ question, stepUpdate, step, saveData, sav, disease_uuid, disease_name, uuidata, uuidDaata }) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState({
    question: question.title,
    response: "",
  });

  console.log(sav.userResponse.length,uuid, "sdds")
  const saveQuestions = async () => {
    try {
      var payLoad;

        if(sav.userResponse.length <= 0){
          payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };
        }else{
          payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            uuid: uuid,
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };
        }


          let response = await saveQuestionnaire(payLoad);
          if ((response && response.status === 200) || response.status === 201) {
            console.log(response.data.uuid,"Saved");

            uuid = response.data.uuid;
            () => uuidata(uuid)
            // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
          }
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        saveQuestions()
        // try {
        //   var payLoad;
    
        //     if(sav.userResponse.length <= 0){
        //       payLoad = {
        //         // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
        //         disease_uuid: disease_uuid,
        //         disease_name: disease_name,
        //         questionanswer: [sav],
        //       };
        //     }else{
        //       payLoad = {
        //         // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
        //         uuid: uuid,
        //         disease_uuid: disease_uuid,
        //         disease_name: disease_name,
        //         questionanswer: [sav],
        //       };
        //     }
    
    
        //       let response = saveQuestionnaire(payLoad);
        //       if ((response && response.status === 200) || response.status === 201) {
        //         console.log(response.data.uuid,"Saved");
    
        //         uuid = response.data.uuid;
        //         uuidata(uuid)
        //         // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
        //       }
        //     } catch (error) {
        //       console.log(error);
        //     }
        // uuidata(uuid)
      },[uuidata])

  const handleChange = (event) => {
    setSelectedValue({ ...selectedValue, response: event.target.value });
    saveData({ ...selectedValue, response: event.target.value })
    stepUpdate(step + 1);
  };
  const Unchecked = ({ label }) => (
    <Grid className={classes.quesOption}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );
  const Checked = ({ label }) => (
    <Grid className={classes.quesChecked}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "165px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{ paddingBottom: "56px" }}
      >
        {question.title}
      </Typography>

      {question.options.map((option) => (
        <Radio
          checked={selectedValue === option}
          onChange={handleChange}
          value={option}
          name="radio-buttons"
          inputProps={{ "aria-label": option }}
          icon={<Unchecked label={option} />}
          checkedIcon={<Checked label={option} />}
          disableFocusRipple
          disableTouchRipple
          disableRipple
        />
      ))}
    </Grid>
  );
};

export const RadioChoice = ({ question, stepUpdate, step, saveData, callBack, sav, disease_name, disease_uuid, uuidDaata, uuidata }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [callBackModel, setCallBackModel] = useState(false);
  const [seeDoctor, setSeeDoctor] = useState(false);

  const focusPoint = useRef(null);

  const [selectedValue, setSelectedValue] = useState({
    question: question.title,
    value: "",
  });

  console.log(uuidDaata, "uuidDaata1")
if(uuid !== undefined){
  uuid = uuid;
}
else{
  uuid = uuidDaata;
}
  const saveQuestions = async () => {
    try {
          let payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            uuid : uuid,
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };
          let response = await saveQuestionnaire(payLoad);
          if ((response && response.status === 200) || response.status === 201) {
            console.log(response.data.uuid,"Saved");
            uuidata(response.data.uuid)
            uuid = response.data.uuid;
            // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
          }
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        saveQuestions()
        // uuidata(uuid)
      },[])

  const handleChange = (event) => {

    try {
      setValue(event.target.value);
      if (selectedValue.question === "Would you like to talk to a GP?" || selectedValue.question === "Would you like to have a consultation with GP?") {
        if (event.target.value === "Yes") {
          callBack(!callBackModel)
        } else {
          stepUpdate(step + 1);
        }
      } else {
        stepUpdate(step + 1);
      }
    } catch (error) {
      console.log(error)
    }

  };


  const handleValueChange = (e) => {
    setSelectedValue({ ...question, value: e.target.value });
    saveData({ ...question, value: e.target.value })
  };

  return (

    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "104px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{
          paddingBottom: "46px",
          fontSize: "30px",
          lineHeight: "38px",
          width: "80%",
        }}
        align="center"
      >
        {question.title}
      </Typography>
      <Grid item>
        <FormControl>
          <RadioGroup
            aria-labelledby="Impairment"
            name="Impairment"
            value={value}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="Yes"
              control={
                <Radio
                  onChange={handleValueChange}
                  icon={<NeutralComp value="Yes" />}
                  checkedIcon={<YesComp />}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
            />
            <FormControlLabel
              value="No"
              control={
                <Radio
                  onChange={handleValueChange}
                  icon={<NeutralComp value="No" />}
                  checkedIcon={<NoComp />}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {seeDoctor ? (
        <Alert>
          <img src={AlertClose} alt="Close" style={{ paddingTop: "52.51px" }} />
          <Typography
            style={{
              paddingTop: "49px",
              paddingBottom: "8px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#002931",
            }}
          >
            Not suitable for Rx
          </Typography>
          <Typography
            align="center"
            style={{
              width: matchesSM ? "250px" : "421px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#002931",
              paddingBottom: "47px",
            }}
            ref={focusPoint}

          >
            Based on your answer, you are not suitable for Rx. Change your answer
            or kindly click on the button and talk to a General Practioner.
          </Typography>
          <Button className={classes.talkToADoctorButton} onClick={() => setCallBackModel(!callBackModel)}>
            TALK TO A GENERAL PRACTIONER
          </Button>
        </Alert>
      ) : null}

      <CallBackPopUp
        open={callBackModel}
        close={() => setCallBackModel(!callBackModel)}
      />
    </Grid>



  );
};

export const RadioChoiceValidation = ({ ques, stepUpdate, step, saveData, sav, disease_name, disease_uuid, uuidDaata }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [callBackModel, setCallBackModel] = useState(false);

  const [question, setQuestion] = useState({ question: "", value: "" });
  const [seeDoctor, setSeeDoctor] = useState(false);

  const focusPoint = useRef(null);

  const saveQuestions = async () => {
    try {
          let payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            uuid : uuidDaata,
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };
          let response = await saveQuestionnaire(payLoad);
          if ((response && response.status === 200) || response.status === 201) {
            console.log(response.data.uuid,"Saved");
            // uuidata(response.data.uuid)
            // uuid = response.data.uuid;
            // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
          }
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        saveQuestions()
      },[])


  useEffect(() => {
    if (seeDoctor) {
      window.scrollTo(0, focusPoint.current.offsetTop);
    }
  }, [seeDoctor]);


  useEffect(() => {
    setQuestion({ ...question, question: ques.title });
  }, [ques]);

  const handleChange = (event) => {
    setValue(event.target.value);

  };
  const handleValueChange = (e) => {
    setQuestion({ ...question, value: e.target.value });
    if (e.target.value === "Yes") {
      setSeeDoctor(true);
    } else {
      setSeeDoctor(false);
      stepUpdate(step + 1);
      saveData({ ...question, value: e.target.value })
    }
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "104px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{
          paddingBottom: "46px",
          fontSize: "30px",
          lineHeight: "38px",
          width: "80%",
        }}
        align="center"
      >
        {ques.title}
      </Typography>
      <Grid item>
        <FormControl>
          <RadioGroup
            aria-labelledby="Impairment"
            name="Impairment"
            value={value}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="Yes"
              control={
                <Radio
                  onChange={handleValueChange}
                  icon={<NeutralComp value="Yes" />}
                  checkedIcon={<YesComp />}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
            />
            <FormControlLabel
              value="No"
              control={
                <Radio
                  onChange={handleValueChange}
                  icon={<NeutralComp value="No" />}
                  checkedIcon={<NoComp />}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {seeDoctor ? (
        <Alert>
          <img src={AlertClose} alt="Close" style={{ paddingTop: "52.51px" }} />
          <Typography
            style={{
              paddingTop: "49px",
              paddingBottom: "8px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#002931",
            }}
          >
            Not suitable for Rx
          </Typography>
          <Typography
            align="center"
            style={{
              width: matchesSM ? "250px" : "421px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#002931",
              paddingBottom: "47px",
            }}
            ref={focusPoint}

          >
            Based on your answer, you are not suitable for Rx. Change your answer
            or kindly click on the button and talk to a General Practioner.
          </Typography>
          <Button className={classes.talkToADoctorButton} onClick={() => setCallBackModel(!callBackModel)}>
            TALK TO A GENERAL PRACTIONER
          </Button>
        </Alert>
      ) : null}

      <CallBackPopUp
        open={callBackModel}
        close={() => setCallBackModel(!callBackModel)}
      />

    </Grid>
  );
};
