import contactLooper from "../../assets/images/contactLooper.svg";

const ContactStyles = (theme) => ({
  contactHeader: {
    background: "#FFFFFF",
    paddingTop: "9.6rem",
    paddingLeft: "7.8rem",
    backgroundImage: `url(${contactLooper})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 28%",
    height: "40.8rem",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "3.4rem",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "none",
      backgroundRepeat: "none",
      backgroundPosition: "none",
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
      height: "42.4rem",
      paddingTop: "6.4rem",
    },
  },
  contactHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#7F56D9",
    paddingBottom: "12px",
    [theme.breakpoints.down("xs")]: {
      color: "#C53B20",
    },
  },
  contactTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    color: "#101828",
    [theme.breakpoints.down("sm")]: {
      fontSize: "42px",
      lineHeight: "50px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: "36px",
      lineHeight: "44px",
    },
  },
  contactFormContainer: {
    background: "#F2F8F9",
    height: "auto",
    paddingBottom: "9.8rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px",
    },
  },
  contactFormContent: {
    paddingLeft: "10.1rem",
    marginTop: "9.9rem",
    width: "48%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "0px",
    },
  },
  contentHeading: {
    color: "#002931",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    paddingBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
      lineHeight: "44px",
      paddingLeft: "17px",
    },
  },
  contentBody: {
    color: "#002931",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    width: "357px",
    [theme.breakpoints.down("sm")]: {
      width: "264px",
      paddingLeft: "17px",
    },
  },
  contactFormOuter: {
    width: "744px",
    paddingTop: "9.9rem",
    paddingRight: "9.7rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingRight: "0px",
    },
  },
  contactForm: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "10px",
    maxWidth: "647px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderRadius: "0px",
    },
  },
  inputLabel: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#344054",
  },
  inputField: {
    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    padding: "10px 14px",
    height: "44px",
    width: "562px",
    [theme.breakpoints.down("sm")]: {
      width: "450px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "327px",
    },
  },
  inputFieldMultiline: {
    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    padding: "10px 14px",
    width: "562px",
    [theme.breakpoints.down("sm")]: {
      width: "450px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "327px",
    },
  },

  contactButton: {
    ...theme.typography.secondaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "10px 0px",
    marginTop: "24px",
    marginBottom: "60px",
    width: "562px",
    [theme.breakpoints.down("sm")]: {
      width: "450px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "327px",
    },
  },

  formControl: {
    paddingLeft: "42px",
    paddingRight: "42px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  faqContainer: {
    width: "100%",
    height: "auto",
    background: "#F9FAFB",
    paddingLeft: "11.2rem",
    paddingTop: "2.6rem",

    paddingBottom: "2.6rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "16px",
      paddingTop: "25px",
    },
  },
  faqTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineheight: "24px",
    color: "#C53B20",
    margin: "12px 0px",
  },
  faqHeading: {
    // fontFamily: "Visuel Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    color: "#101828",
    margin: "12px 0px",
  },
  faqsubtitle: {
    // fontFamily: "Visuel Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#667085",
    margin: "10px 0px",
  },

  faqMainArea: {
    paddingTop: "3.6rem",
    paddingLeft: "11.2rem",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "25px",
      paddingLeft: "16px",
    },
  },

  faqSubHeadingContainer: {
    width: "44.8rem",
    marginRight: "64px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "16px",
    },
  },
  faqSubHeading: {
    // fontFamily: "Visuel Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineheight: "44px",
    letterSpacing: "-0.02em",
    color: "#101828",
    margin: "12px 0px",
  },

  faqsubtitle2: {
    // fontFamily: "Visuel Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "19px",
    lineHeight: "28px",
    color: "#667085",
    margin: "20px 0px",
  },
  accordian: {
    border: "none",
    borderRadius: "none",
    boxShadow: "none",
    paddingBottom: "32px",
  },
  accordianSummary: {
    padding: "0px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "16px",
    },
  },
  faqQuestion: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#101828",
  },
  accordianAnswer: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#667085",
  },
  faqList: {
    marginRight: "11.4rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "4.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
  },
  inputCheck: {
    width: "20px",
    height: "20px",
    border: "1px solid #d0d5dd",
    borderRadius: "6px",
    WebkitAppearance: "none",
    MozAppearance: "none",
    "&:checked": {
      backgroundColor: "#ff5c3c !important",
      border: "none",
      background:
        '#ff5c3c url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 5px 5px no-repeat',
    },
  },
});

export default ContactStyles;
