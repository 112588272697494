import React from "react";
import {
    Grid,
    Button,
    Typography,
    IconButton,
    useMediaQuery,
    CardContent,
    Card,
    CardActions,
    Hidden,
    FormControl,
    Input,
    Checkbox,
    Box,
    Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header/Header";
import { getCurrentDate } from "../../components/common/utils";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => styles(theme));

const Legal = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
        <Helmet>
            <title>Terms & Conditions - Doctall</title>
            <meta 
            name="description" 
            content="By accessing our website, you are agreeing to be bound by these terms of service, and agree that you are responsible for compliance with any applicable local laws. Read more on this page"
            />
        </Helmet>
            <Header />
            
            
            <Grid container>
                <Grid item container direction="column" className={classes.LegalHeader} align="center" >
                    <Typography className={classes.legalBannerHeading}>{`Current as of ${getCurrentDate()}`}</Typography>
                    <Typography className={classes.legalHeading}>Terms and conditions</Typography>
                    <Typography className={classes.legalSubHeading}>By accessing our website,
                        you are agreeing to be bound by these terms of service,
                        and agree that you are responsible for compliance with any applicable local laws.
                    </Typography>

                </Grid>

                <Grid item container direction="column" className={classes.LegalTextContainer} >
                    <Typography className={classes.legalPara}>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY
                        AND ENTIRELY BEFORE USING THIS WEBSITE.
                        <br /><br />
                        By registering as a user on Doctall.com (below "The Service") and/or by clicking "I agree to the Terms of Use and have read the Privacy Policy"
                         you accept the terms set out below. It is important that you read through and understand them. We may change and/or update these Terms from time to time, and therefore, recommend that you visit and read them regularly. 
                         We will also notify you of changes through Notice to the Service, and your continued use of the Service means that you accept these changes.
                    </Typography><br />
                    <Typography className={classes.legalParaHeading}>1.	About the Service</Typography>
                    <Typography className={classes.legalPara}>
                    1.1 The service Doctall.com is operated by Digicare Hospital & Allied Services Ltd. (hereinafter "Doctall" or "We"). These terms govern your use of Doctall's digital service offering, and certain questions about you as an individual user and as a customer.<br />

                    1.2 Eligible healthcare professionals are affiliated with the Service as a Caregiver ("Caregiver"). Doctall's role is to mediate contact between the Caregiver and you as a customer, and to ensure that the communication between you and the Caregiver through the Service complies with applicable regulations on information management and record keeping in health care. Information about who is the Caregiver in the individual case is shown, for example, when booking, on receipts and in other documentation.<br />

                    1.3 The service is intended for use by persons who are 18 years of age or older. If you are under the age of 18, you must obtain parental or legal guardian consent to enter into an agreement with us.<br />

                    1.4 The service is in no case intended to be used in medical emergencies or other situations in need of urgent medical care - in such cases you should contact your local emergency services as soon as possible.<br />

                    1.5 In order for Doctall to be completely secure in your identity, the Service requires login using your unique personal mobile telephone number. <br />

                    1.6 No narcotic drugs are prescribed through the Service<br />

                    </Typography><br />
                    <Typography className={classes.legalParaHeading}>2. User responsibility</Typography>
                    <Typography className={classes.legalPara}>
                    As a user you are committed to;<br/>
 
                    ●	not use the Service for anything other than medical consultation for yourself as an individual,<br/>
                    ●	immediately turn to local emergency health services if the condition you sought counselling or treatment for suddenly worsens<br/>
                    ●	ensure that your personal mobile phone number used for logging into the Service (and corresponding password) does not fall into the wrong hands, and immediately notify us if you suspect that someone is abusing or may be misusing your login to the Service, and <br/>
                    ●	not to interfere with or attempt to interrupt the Service or otherwise affect its functionality through, for example, the transmission of viruses, hostile software or other malicious code in or to the Service.<br/> 
 

                    </Typography><br />

                    <Typography className={classes.legalParaHeading}>3. Doctall's responsibility</Typography>
                    <Typography className={classes.legalPara}>
                    3.1 The medical responsibility in a consultation case with a patient lies with the individual Caregiver. It is for this reason that the Caregiver is ultimately held accountable as a result of any errors or deficiencies in the treatment and / or counselling in a single case.<br/>

                    3.2 Doctall is obliged to verify that all Caregivers are fully licensed and authorised to practice medicine in their domicile countries. <br/>

                    3.3 Articles, blog posts and other general information published by Doctall complement the medical advice provided by a Caregiver, but are not a substitute for a Caregiver’s diagnosis or treatment. The use of the Service is a complement to standard care, which is intended as a tool for Caregivers to provide effective medical advice to their patients, but not as a substitute for the healthcare provided by doctors or other healthcare providers. and healthcare professionals.<br/>

                    3.4 Doctall strives to maintain a very high availability of the Service, and will take all reasonable steps to achieve this. However, planned and unplanned interruptions in the availability of the Service cannot be completely avoided. Scheduled interruptions shall as far as possible occur at a time when there is a low load on the Service, and shall be notified as far as possible in advance. If a consultation that you have paid for is not available due to a break, you are entitled to free re-booking of the consultation or to a full refund. <br/>

                    </Typography><br />
                    <Typography className={classes.legalParaHeading}>4.Prices, payment etc.</Typography>
                    <Typography className={classes.legalPara}>
                     Prices and fees for consultations through the Service, and any other prices, are shown in the price list published at each time. Payment for consultation is made in advance, and you cannot start a consultation with the Caregiver until payment has been made, except in cases where We have explicitly stated this, for example during campaigns.

                    </Typography><br />

                    <Typography className={classes.legalParaHeading}>5. Termination of Service</Typography>
                    <Typography className={classes.legalPara}>
                    5.1 The agreement on the Service is valid until further notice. Both you and Doctall can terminate the agreement at any time. If you want to stop using the Service, you can contact Doctall to deactivate your account.

                    5.2 If We believe you are abusing the Service, We may, after notifying you by email, suspend you from further use of the Service.




                    </Typography><br />
                    <Typography className={classes.legalParaHeading}>6. Privacy Policy</Typography>
                    <Typography className={classes.legalPara}>
                    DigiCare Hospital & Allied Services Ltd. (hereinafter "Doctall" or "We") will process personal data about you as part of your use of our services and in connection with visits to our website and mobile applications ("the Service"). We believe that personal privacy is important and adhere to applicable data protection laws to protect your personal privacy. This Privacy Policy ("Privacy Policy") is intended to inform you about our processing of your personal data and about your rights. Your personal data is handled in accordance with the EU General Data Protection Regulation ("GDPR"). Please refer to our Privacy Policy Page to read more
                    </Typography><br />
                    
                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

export default Legal;
