import { calculateNewValue } from "@testing-library/user-event/dist/utils";

const styles = (theme) => ({
  quesContainer: {
    width: "auto",
    height: "auto",
  },
  quesHeader: {
    height: "75px",
    width: "100%",
    paddingLeft: "97px",
    paddingTop: "20px",
  },
  quesContent: {
    background: "rgb(247, 251, 248)",
    width: "100%",
    minHeight: "calc(100vh - 75px)",
  },
  questionTitle: {
    fontFamily: "Visuelt Pro",
    // fontStyle: "normal",
    // fontWeight: "100",
    fontSize: "24px",
    lineHeight: "30px",
    color: "#002931",
    textAlign: "center",
    // textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  quesOption: {
    height: "68px",
    width: "504px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    WebkitAppearance: "none",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  quesOptionLabel: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#002931",
  },
  quesChecked: {
    border: "2px solid #005F71",
    height: "68px",
    width: "504px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  email: {
    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    width: "501px",
    height: "44px",
    padding: "10px 14px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "6px",
    color: "#667085",
    "&:focus-visible": {
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
  },
  neutralComp: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    width: "126px",
    height: "68px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  VisitDoctorAlert: {
    background: "#F2F8F9",
    border: "1px solid #D9EDF1",
    boxSizing: "border-box",
    borderRadius: "4px",
    width: "784px",

    paddingBottom: "24px",
    marginTop: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
    },
  },
  talkToADoctorButton: {
    background: "#FFFFFF",
    border: "1px solid #002931",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    padding: "10px 18px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#002931",
    width: "320px",
    [theme.breakpoints.down("sm")]: {
      width: "250px",
    },
  },
  inputCheck: {
    width: "16px",
    height: "16px",
    border: "1px solid #d0d5dd",
    borderRadius: "4px",
    WebkitAppearance: "none",
    MozAppearance: "none",
    cursor: "pointer",
    "&:checked": {
      backgroundColor: "#ff5c3c !important",
      border: "none",
      background:
        '#ff5c3c url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 3px 3px no-repeat',
    },
  },
  inputContainer: {
    width: "688px",
    background: "#F2F8F9",
    border: "1px solid #D9EDF1",
    boxSizing: "border-box",
    borderRadius: "8px",
    paddingTop: "48px",
    paddingBottom: "48px",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
    },
  },
  continueButton: {
    ...theme.typography.secondaryButton,
    width: "688px",
    marginBottom: "169px",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
    },
  },
  deliveryContainer: {
    width: "504px",
    backgroundColor: "#ffffff",
    marginTop: "56px",
    paddingTop: "22px",
    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
  },
  inputFieldMultiline: {
    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    padding: "10px 14px",
    width: "569px",
    height: "128px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#667085",

    marginBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "450px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "327px",
    },

  },
  inputmultilineContainer: {
    width: "733px",
    height: "293px",
    background: "#F2F8F9",
    border: "1px solid #D9EDF1",
    boxSizing: "border-box",
    borderRadius: "4px",
    paddingTop: "52px",
    marginTop: "37px",
    marginBottom: "37px",
    [theme.breakpoints.down("sm")]: {
      width: "360px",
    },
  },
  ProceedButton: {
    ...theme.typography.secondaryButton,
    width: "688px",
    marginBottom: "169px",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
    },
  },

  callbackpopupDialog:{
    overflow:"auto",
    [theme.breakpoints.down("sm")]: {
      overflow:"auto",
    },
  },
  root: {
    width: "90px",
    height: "42px",
    padding: "0px",
  },
  switchBase: {
    color: "#818181",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#00839B"
      }
    }
  },
  thumb: {
    color: "white",
    width: "35px",
    height: "35px",
    margin: "2px",
    background: "linear-gradient(180deg, #FFFFFF 0%, #E8EAEA 100%)",
  },
  track: {
    borderRadius: "20px",
    backgroundColor:"#EFEFEF",
    Offset: "0px, 6px,rgba(0, 0, 0, 0.1)",
    boxShadow: "inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1)",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "15px",
      position: "absolute",
      top: "14px"
    },
    "&:after": {
      content: "'Yes'",
      left: "12px",
      color:"#FFFFFF",
      fontWeight:"500",
      fontFamily:"Visuelt Pro",
    },
    "&:before": {
      content: "'No'",
      right: "12px",
      color:"#002931",
      fontWeight:"500",
      fontFamily:"Visuelt Pro",
    }
  },
  checked: {
    color: "#23bf58 !important",
    transform: "translateX(48px) !important"
  },  

  

  
});

export default styles;
