import React from "react";
import  PropTypes from "prop-types"
import { Helmet } from "react-helmet";

const MetaDecorator = ({title , description}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    )
}

MetaDecorator.prototype = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

// MetaDecorator.defaultProps ={ 
//     childern: null,
//     className: null,
// }

export default MetaDecorator; 
