import contactLooper from "../../../../assets/images/Looperr.png";
import HLMOB from "../../../../assets/images/HLMOB.png";
// import reHairBan from "../../../../assets/images/reHairBan.png";

const CareersStyles = (theme) => ({
    contactHeader: {
        background: "#f2f8f9",
        paddingTop: "6.6rem",
        paddingLeft: "7.8rem",
        backgroundImage: `url(${contactLooper})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "110% 40%",
        height: "50.8rem",
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "3.4rem",
        },
        [theme.breakpoints.down("xs")]: {
          backgroundImage: `url(${HLMOB})`,
          backgroundRepeat: "none",
          backgroundPosition: "100% 100%",
          paddingLeft: "1.6rem",
          paddingRight: "1.6rem",
          height: "85.4rem",
          paddingTop: "1rem",
        },
        [theme.breakpoints.down("md")]: {
        
        //   backgroundImage: `url(${reHairBan})`,
          backgroundRepeat: "none",
          backgroundPosition: "90% 100%",
          // paddingLeft: "1.6rem",
          // paddingRight: "1.6rem",
          // height: "75.4rem",
          paddingTop: "1.4rem",
        },
      },
      careerBannerHeading: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#7F56D9",
        paddingBottom: "12px",
      },
      careerBannerTitle: {
        fontFamily: "Visuelt Pro",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "60px",
        lineHeight: "72px",
        letterSpacing: "-0.02em",
        color: "#002931",
        // textAlign: "center",
        [theme.breakpoints.down("sm")]: {
          fontSize: "42px",
          lineHeight: "50px",
          marginTop: "10px",
        },
    
        [theme.breakpoints.down("xs")]: {
          fontSize: "36px",
          lineHeight: "44px",
          marginTop: "10px",
        },
      },
      careerPara:{
          fontFamily:"Visuelt Pro",
          fontStyle:"normal",
          fontSize: "20px",
          lineHeight:"30px",
          width:"630px",
          height: "90px",
          fontWeight: "400",
          color: "#002931",
          marginTop:"1%",
          marginBottom:"50px",
          // marginBottom:"4%",
          [theme.breakpoints.down("sm")]: {
            lineHeight: "30px",
            width:"350px",
           top: "50px",
          },
      
          [theme.breakpoints.down("xs")]: {
            width:"340px",
          },
          [theme.breakpoints.down("md")]: {
            width:"355px",
          },
        
      },
      testimonial:{
          width: "649px",
          height: "96px",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "32px",
          color: "#00839B",
          fontFamily: "Visuelt Pro",
          fontStyle: "normal",
          [theme.breakpoints.down("xs")]: {
            width:"330px",
            marginTop: "10px",
          },
          [theme.breakpoints.down("md")]: {
            width:"45%",
            marginTop: "10px",
          },
          [theme.breakpoints.down("sm")]: {
            // lineHeight: "30px",
            width:"350px",
            marginTop: "5px"
          //  top: "50px",
          },
    
      },
      star:{
        width: "17px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "-30px",
        },
        // [theme.breakpoints.down("sm")]: {
        //   // lineHeight: "30px",
        //   marginTop: "40px",
        // //  top: "50px",
        // },
        [theme.breakpoints.down("md")]: {
          // width:"50%",
          marginTop: "5px",
        },
    
      },
      doctall:{
        width: "107px",
        height: "18px",
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "18px",
        color: "#004451",
        marginTop: "40px",
        fontFamily: "Visuelt Pro",
        fontStyle: "normal",
        [theme.breakpoints.down("xs")]: {
          marginTop: "0px",
        },
        [theme.breakpoints.down("md")]: {
         
          marginTop: "120px",
        },
      },
     
    
      careerContainer:{
    padding:"50px 100px",
    [theme.breakpoints.down("sm")]: {
        padding:"10px"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "10px",
      },
    
    },
      careerHeading:{
        fontSize:"30px",
        color:"#002931",
        width: "791px",
        height: "76px",
        fontWeight:"500",
        lineHeight:" 38px",
        padding: "10px 10px 0px 10px",
        textAlign: "center",
        marginTop:"1%",
        [theme.breakpoints.down("xs")]: {
          fontSize:"30px",
          width: "350px",
          textAlign: "center",
          marginTop: "-10px",
        },
        // [theme.breakpoints.down("md")]: {
        //   width: "750px",
        // },
        [theme.breakpoints.down("md")]: {
          width: "370px",
          marginBottom: "78px",
        },
    },
    
    img: {
     marginTop: "-40px",
    //  position: "absolute",
     backgroundPosition: "40% 20%",
     [theme.breakpoints.down("xs")]: {
      display: "none",
    
      backgroundImage: `url(${HLMOB})`
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
        },
    },
    
    careerImage:{
      width:"100%",
        [theme.breakpoints.down("sm")]: {
        display:"block",
          },
          [theme.breakpoints.down("xs")]: {
            display:"none",
          },
    },
    careerImageMob:{
      display:"none",
      [theme.breakpoints.down("sm")]: {
        display:"none",
          },
          [theme.breakpoints.down("xs")]: {
            display:"block",
          },
    
    },
    careerTextContainer:{
      padding:"20px 330px",
      [theme.breakpoints.down("sm")]: {
          padding:"10px"
        },
        [theme.breakpoints.down("xs")]: {
          padding: "10px",
        },
    },
    careerCard:{
    border:"1px solid #E4E7EC",
    shadows:"none",
    borderRadius:"16px",
    margin:"20px 0px"
    
    },
    cardHead:{
      fontSize:"20px",
      color:"#101828",
      fontWeight:"500",
    
    },
    cardHeading:{
      fontSize:"18px",
      color:"#101828",
      fontWeight:"500",
      padding:"20px 20px 10px 20px",
    },
    cardPara:{
      fontSize:"16px",
      color:" #667085",
      padding:"20px",
    },

})

export default CareersStyles;