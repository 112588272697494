import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  Hidden,
  CardMedia,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "../styles";
import blog from "../../../assets/images/blog.svg";
import blogImage from "../../../assets/images/blog.svg";
import arrowUpRight from "../../../assets/images/arrowUpRight.svg";
import { _redirect, _redirectToURL } from "../../Redirect/Redirect";
import DoctallLivingImages from "./DoctallLivingImages";
import blogActions from "../../../redux/actions/blogActions";
import { useDispatch, useSelector } from "react-redux";
import DoctallPediaImages from "./DoctallPediaImages";

const useStyles = makeStyles((theme) => styles(theme));

const Blogs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesXL = useMediaQuery("(min-width:1500px)");

  const _blogContent = useSelector((state) => state.blogReducer);

  useEffect(() => {
    //without pagination args and dieases type
    dispatch(blogActions.getLivingBlog("", ""));
    dispatch(blogActions.getPediaBlog("", ""));
  }, []);

  const pediaBlogs = [
    {
      title: "Doctallpedia",
      subtitle:
        "Read the most up-to-date information about 1000+ health conditions. These are what others are reading:",
      link: "",
      blogs: [
        {
          title: "Doctallpedia",
          heading: "How stress affects your body",
          desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
          image: blog,
          link: "",
        },
        {
          title: "DoctallLiving",
          heading: "How stress affects your body",
          desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
          image: blog,
          link: "",
        },
        {
          title: "Doctallpedia",
          heading: "How stress affects your body",
          desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
          image: blog,
          link: "",
        },
      ],
    },
  ];

  const livingBlogs = [
    {
      title: "Doctall Living",
      subtitle:
        "Our team of health experts and doctors provide the latest articles on being and staying healthy",
      link: "",
      blogs: [
        {
          title: "Doctallpedia",
          heading: "How stress affects your body",
          desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
          image: blog,
          link: "",
        },
        {
          title: "DoctallLiving",
          heading: "How stress affects your body",
          desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
          image: blog,
          link: "",
        },
        {
          title: "Doctallpedia",
          heading: "How stress affects your body",
          desc: "How do you create compelling presentations that wow your colleagues and impress your managers?",
          image: blog,
          link: "",
        },
      ],
    },
  ];

  return (
    <>
      <Grid
        container
        item
        style={{ paddingBottom: "100px", justifyContent: "center" }}
      >
        {pediaBlogs.map((blog, index) => (
          <Grid item container key={`${blog}${index}`}>
            <Grid
              item
              container
              justifyContent={matchesSM ? "center" : "space-between"}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "700",
                  textAlign: matchesSM ? "center" : undefined,
                }}
              >
                {blog.title}
              </Typography>
              <Hidden smDown>
                <Button
                  variant="contained"
                  className={classes.viewBlogsButton}
                  onClick={() => _redirect("PEDIA")}
                >
                  View all posts
                </Button>
              </Hidden>
            </Grid>
            <Typography
              variant="body2"
              style={{
                margin: "20px 0px",
                width: matchesMD ? undefined : "93.6rem",
                textAlign: matchesSM ? "center" : undefined,
              }}
            >
              {blog.subtitle}
            </Typography>
            <Grid
              item
              container
              direction={matchesXS ? "column" : undefined}
              alignItems={matchesSM ? "center" : undefined}
              // justifyContent={matchesSM ? "center" : undefined}
              justifyContent={custommatchesXL ? "center" : undefined}
            >
              {_blogContent &&
                _blogContent.pediaData &&
                _blogContent.pediaData.length !== 0 &&
                _blogContent.pediaData.map((item, index) => (
                  <>
                  {console.log(item,"itemitemitem")}
                    {index <= 2 && (
                      <Grid
                        item
                        key={`${item}${index}`}
                        style={{
                          marginLeft: matchesMD ? "0px" : "32px",
                          marginRight: matchesMD
                            ? matchesXS
                              ? "0px"
                              : "32px"
                            : "0px",
                          marginBottom: "2.2rem",
                        }}
                      >
                        <Card
                          classes={{ root: classes.blogCard }}
                          style={{ height: "500px" }}
                        >
                          {/* <CardMedia
                          component="img"
                          alt="blogImage"
                          height="240px"
                          image={blogImage}
                        ></CardMedia> */}
                          <DoctallPediaImages data={item} />

                          <CardContent classes={{ root: classes.cardSetter }}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: "700",
                                color: "#C53B20",
                                marginTop: "32px",
                                textAlign: "left",
                                position: "relative",
                              }}
                            >
                              {blog.title}
                            </Typography>
                            <Grid
                              item
                              container
                              justifyContent="space-between"
                              style={{ margin: "12px 0px" }}
                            >
                              <Typography
                                variant="h4"
                                style={{ width: matchesXS ? "250px" : "300px" }}
                              >
                                {item.title.rendered}
                              </Typography>
                              <img
                                src={arrowUpRight}
                                alt="Arrow Up Right"
                                onClick={() => _redirectToURL(item.link)}
                                style={{ cursor: "pointer" }}
                              />
                            </Grid>
                            <Typography
                              className={classes.cardBody}
                              style={{
                                height: "50px",
                                // position: "absolute",
                              }}
                            >
                              {item.excerpt.rendered
                                .toString()
                                .replace("<p>", "")
                                .replace("</p>", "")
                                .replace("&#8217;", "'")
                                .replace("&#8211;", "-")
                                .replace("&#8211;", "-")
                                .replace("&nbsp;", "")
                                .replace("[&hellip;]", "")}
                            </Typography>
                            <Grid container justifyContent="left">
                              <Button
                                variant="contained"
                                disableRipple
                                className={classes.blogButton}
                                onClick={() => _redirectToURL(item.link)}
                                style={{
                                  bottom: "0px",
                                }}
                              >
                                Read more
                              </Button>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>
          </Grid>
        ))}
        <hr
          style={{
            border: "1px solid rgba(0, 68, 81, 0.2)",
            margin: "48px 0px",
            width: "100%",
            marginRight: "6.3rem",
            marginLeft: matchesSM ? "6.3rem" : "0px",
          }}
        />

        {/* Doctall Living Blogs */}

        {livingBlogs.map((blog, index) => (
          <Grid item container key={`${blog}${index}`}>
            <Grid
              key={`${blog}${index}`}
              item
              container
              direction={matchesSM ? "column" : "row"}
              justifyContent={matchesSM ? "center" : "space-between"}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "700",
                  textAlign: matchesSM ? "center" : undefined,
                }}
              >
                {blog.title}
              </Typography>
              <Hidden smDown>
                <Button
                  variant="contained"
                  className={classes.viewBlogsButton}
                  onClick={() => _redirect("LIVING")}
                >
                  View all posts
                </Button>
              </Hidden>
            </Grid>
            <Typography
              variant="body2"
              style={{
                margin: "20px 0px",
                width: matchesMD ? undefined : "86.6rem",
                textAlign: matchesSM ? "center" : undefined,
              }}
            >
              {blog.subtitle}
            </Typography>
            <Grid
              item
              container
              direction={matchesXS ? "column" : undefined}
              alignItems={matchesSM ? "center" : undefined}
              // justifyContent={matchesSM ? "center" : undefined}
              justifyContent={custommatchesXL ? "center" : undefined}
            >
              {_blogContent &&
                _blogContent.livingData &&
                _blogContent.livingData.length !== 0 &&
                _blogContent.livingData.map((item, index) => (
                  <>
                    {index <= 2 && (
                      <Grid
                        item
                        key={`${item}${index}`}
                        style={{
                          marginLeft: matchesMD ? "0px" : "32px",
                          marginRight: matchesMD
                            ? matchesXS
                              ? "0px"
                              : "32px"
                            : "0px",
                          marginBottom: "2.2rem",
                        }}
                      >
                        <Card classes={{ root: classes.blogCard2 }}>
                          <DoctallLivingImages data={item} />
                          <CardContent classes={{ root: classes.cardSetter }}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: "700",
                                color: "#C53B20",
                                marginTop: "32px",
                                textAlign: "left",
                                position: "relative",
                              }}
                            >
                              {blog.title}
                            </Typography>

                            <Grid
                              item
                              container
                              justifyContent="space-between"
                              style={{ margin: "12px 0px", textAlign: "left" }}
                            >
                              <Typography
                                variant="h4"
                                style={{
                                  width: matchesXS ? "250px" : "300px",
                                  textAlign: "left",
                                }}
                              >
                                {item.title.rendered
                                  .toString()
                                  .replace("<p>", "")
                                  .replace("</p>", "")
                                  .replace("&#8217;", "'")
                                  .replace("&nbsp;", "")
                                  .replace("[&hellip;]", "")
                                  .replace("#038;", "")}
                              </Typography>
                              <img
                                src={arrowUpRight}
                                alt="Arrow Up Right"
                                style={{
                                  alignSelf: "flex-start",
                                  cursor: "pointer",
                                }}
                                onClick={() => _redirectToURL(item.link)}
                              />
                            </Grid>
                            <Typography
                              className={classes.cardBody}
                              style={
                                {
                                  //  position: "absolute",
                                  // height: "auto"
                                }
                              }
                            >
                              {item.excerpt.rendered
                                .toString()
                                .replace("<p>", "")
                                .replace("</p>", "")
                                .replace("&#8217;", "'")
                                .replace("&#8211;", "-")
                                .replace("&#8211;", "-")

                                .replace("&nbsp;", "")
                                .replace("[&hellip;]", "")}
                            </Typography>
                            <Grid container justifyContent="left">
                              <Button
                                variant="contained"
                                disableRipple
                                className={classes.blogButton}
                                onClick={() => _redirectToURL(item.link)}
                                style={{
                                  alignSelf: "left",
                                  //position: "absolute"
                                  bottom: "0px",
                                }}
                              >
                                Read more
                              </Button>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Blogs;
