import React from "react";
import { LinearProgress, Typography, Box } from "@material-ui/core";
import styles from "./styles";
// import Valuelabel from './Valuelabel';
import { makeStyles, useTheme } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import Slider from '@mui/material/Slider';
import './AutoSuggest/ValueLabel.css'

const marks = [
  {
    value: 0,
    label: '',
  },

];

const useStyles = makeStyles((theme) => styles(theme));

const ProgressBar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));


  function valueLabelFormat(value) {
  const units = ['KB', 'MB', 'GB', 'TB'];

  let unitIndex = 0;
  let scaledValue = value;
return `Lasts for ${scaledValue} hours`
  }
  return (
        <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box>
        <LinearProgress
          variant="determinate"
          {...props}
          classes={{
            root: classes.progressBar,
            barColorPrimary: classes.progressColor,
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35, marginLeft: matchesXS ? "10px" : "38px" }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
//     props.Dname === 'card' ? 
//     <Box sx={{ width: 500 }} >
//     <Slider 
//     style={{
//       marginLeft:"110px",
//       color:"#004451",
//       height:"2vh",
//       paddingBottom:'60px',
//      width: matchesXS ?"330px" : "500px",
//     }}
//     className="fluid-img"
//       aria-label="Always visible"
//       value={props.value}
//       getAriaValueText={valueLabelFormat}
//       step={10}
//       marks={marks}
//       valueLabelFormat={valueLabelFormat}
//       valueLabelDisplay="on"
//       // disabled
//     />
//   </Box>
//   :
//     <Box 
//       sx={{ display: "flex", alignItems: "center", justifyContent: "center" ,}} 
//     >
//       <Box >
//         <LinearProgress 
//           variant="determinate"
//           {...props}
//           classes={{
//             root: classes.progressBar,
//             barColorPrimary: classes.progressColor,
//           }}
//         />
//       </Box>
//       {props.Dname == "Erectile Dysfunction" ?
//       <Box  sx={{ minWidth: 35, marginLeft: matchesXS ? "10px" : "38px" }}>
//         <Typography variant="body2" color="text.secondary">{`${Math.round(
//           props.value === 50 ? 2 : 1
//         )}`}</Typography>
//       </Box>
//       :
//     <Box  sx={{ minWidth: 35, marginLeft: matchesXS ? "10px" : "38px" }}>
//         <Typography variant="body2" color="text.secondary">{`${Math.round(
//           props.value
//         )}%`}</Typography>
//       </Box> 
// }
//     <Box  sx={{ minWidth: 35, marginLeft: matchesXS ? "10px" : "38px" }}>
//         <Typography variant="body2" color="text.secondary">{`${Math.round(
//           props.value
//         )}%`}</Typography>
//       </Box> 
//     </Box>
  );
};

const LinearProgressBar = ({ value, diseaseName }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [progress, setProgress] = React.useState();
  const [diseasename, setdiseasename] = React.useState();


  React.useEffect(() => {
    setProgress(value);
    setdiseasename(diseaseName)
  }, [value]);

  return (
    <Box sx={{ width: "100%" }} >
      <ProgressBar value={progress} Dname={diseasename}/>
    </Box>
  );
};

export default LinearProgressBar;
