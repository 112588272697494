import action from "../actions";

const { diagnosticTestAction } = action;

const initialState = {
  loader: false,
  diagnosticTestData: [],
  limit: 10,
  skip: 0,
  count: 0,
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case diagnosticTestAction.GET_DIAGNOSTIC_TEST:
      return {
        ...state,
        loader: false,
      };
    case diagnosticTestAction.GET_DIAGNOSTIC_TEST_SUCCESS:
      return {
        ...state,
        loader: false,
        diagnosticTestData: action.diagnosticTestData,
      };
    default:
      return state;
  }
}
