import react, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  useMediaQuery,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "../styles";
import bullet from "../../../assets/images/Bullet.svg";
import ButtonArrow from "../../../assets/images/ButtonArrow.svg";
import Doctor from "../../../assets/images/doctor.svg";
import EDdiseaseCard from "../../../assets/images/EDdiseaseCard.svg";
import Diabetes from "../../../assets/images/diabetes_temp.png";
import {
  ED_ROUTE_PATH,
  HL_ROUTE_PATH,
  PE_ROUTE_PATH,
  COMING_SOON_ROUTE_PATHE,
  DIABETES_ROUTE_PATH,
  KIDNEY_FAILURES,
  HEART_AND_HYPERTENSION,
  COVID_ROUTE_PATH,
  THYROID_ROUTE_PATH,
  COLD_COUGH_OR_FEVER,
} from "../../common/utils";

const useStyles = makeStyles((theme) => styles(theme));

const BrowseByDisease = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const custommatchesXL = useMediaQuery("(min-width:1500px)");

  const [cardHover, setCardHover] = useState(false);
  const disease = [
    {
      name: "Diabetes",
      symptoms: [
        " Increased thirst",
        " Blurred vision",
        " Frequent urination",
        " Fatigue/feeling worn out",
        " Unexplained weight loss",
        // " Fatigue",
        // "~ Blurred vision",

      ],
      link: DIABETES_ROUTE_PATH,
      class: classes.diseaseCardDD,
    },
    {
      name: "Heart &  Hypertension", 
      symptoms: [
        " Nosebleeds",
        " Changes to your vision",
        " Early morning headaches",
        " Buzzing in the ears",
        // " Unusual fatigue",
      ],
      link: HEART_AND_HYPERTENSION,
      class: classes.diseaseCardHH,
    },
    {
      name: "Cold , Cough  or Fever",
      symptoms: [
        "Sneezing",
        "Sore throat",
        "Faint body aches or a mild headache",
        "Runny or stuffy nose",
        "Cough",
        "Slight fever"

      ],
      link: COLD_COUGH_OR_FEVER,
      class: classes.diseaseCardPE,
    },
    {
      name: "Covid-19",
      symptoms: [
        " Fever",
        " Cough",
        " Body aches",
        " Fatigue",
        " Loss of taste and smell",
        // " Headache",
        // " Aches and pain",
        // " Diarrhoea",
        // " Red or irritated eyes",
        // " Difficulty breathing or shortness of breath",
      ],
      link: COVID_ROUTE_PATH,
      class: classes.diseaseCardCV,
    },
    {
      name: "Kidney Failures",
      symptoms: [
        " Blood in urine or even foamy urine",
        " Swollen hands, ankles, and feet",
        " Trouble sleeping",
        " Muscle cramps",
        " Fatigue",
      ],
      link: KIDNEY_FAILURES,
      class: classes.diseaseCardKF,
    },
    {
      name: "Hair Loss",
      symptoms: [
        // "  Bald Patches",
        "  Bald patches",
        "  Thinning Hair",
        "  Sudden loss of hair",
        // "  Full-body hair loss",
        // "The head also burns and itches"
      ],
      link: HL_ROUTE_PATH,
      class: classes.diseaseCardHL,
    },
    {
      name: "Erectile Dysfunction",
      symptoms: [
        "Weak Erection",
        "Low Libido",
        "Trouble getting an erection",
        // "Fatigue",
        // "Blurred vision",
      ],
      link: ED_ROUTE_PATH,
      class: classes.diseaseCardED,
    },
    {
      name: "Thyroid",
      symptoms: [
        " Intolerance to cold temperatures ",
        " Gaining or losing significant weight",
        " Muscle weakness and tremors",
        " Having dry and coarse hair",
        " Feeling tired ",
        
        
        
        // " Intolerance to cold temparatures",
        // " Feeling sensitive to heat",
        // " Muscle weakness and tremors",
        // " Loosing weight",
      ],
      link: THYROID_ROUTE_PATH,
      class: classes.diseaseCardTH,
    },
    
  ];

  /* const handleHover = (event) => {
    console.log(event);
    const parent = document.getElementByClassName("diseaseCard");
    parent.style.backgroundImage = `url(${Doctor})`;
    parent.style.backgroundPosition = "right bottom";
    parent.style.backgroundRepeat = "no-repeat";
  };

  const handleHoverAway = (event) => {
    event.stopPropagation();
    event.target.style.backgroundImage = null;
    event.target.style.backgroundPosition = null;
    event.target.style.backgroundRepeat = null;
  }; */

  return (
    <>
      <Grid
        item
        container
        direction={matchesXS ? "column" : undefined}
        alignItems={matchesSM ? "center" : undefined}
        // justifyContent={matchesSM ? "center" : undefined}
        justifyContent={custommatchesXL ? "center" : undefined}
      >
        {disease.map((disease, index) => (
          <Link to={disease.link} style={{ textDecoration: 'none' }} key={`${disease}${index}`}>
            <Grid
              item
              style={{
                margin: matchesXS ? "10px" : matchesXL ? "10px" : "10px",
                marginBottom: "24px",
              }}
              xl={3}
            >
              <Card 
                classes={{ root: disease.class }}
              /* onMouseOver={(event) => {
              event.target.id = disease.name;
               handleHover(event);
             }}
             onMouseOut={(event) => {
               event.target.id = disease.name;
               handleHoverAway(event);
             }} */
              >
                <CardContent classes={{ root: classes.diseaseCardContent }}>
                  <Typography variant="h4" gutterBottom >
                    {disease.name}
                  </Typography>

                  <Grid
                    item
                    container
                    direction="column"
                  // style={{ paddingBottom: "3.3rem", paddingTop: "2.6rem" }}
                  >
                    {disease.symptoms.map((symptom, index) => (
                      <Typography
                        key={`${symptom}${index}`}
                        variant="h6"
                        style={{ paddingBottom: "4px", fontWeight: "500" }}
                      >
                        <img src={bullet} alt="list"
                          style={{ paddingBottom: "4px" }}

                        /> &nbsp;

                        {symptom}
                      </Typography>
                    ))}
                  </Grid>
                </CardContent>

                <CardActions disablespacing>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.readMoreButton}
                      disableRipple
                      
                    >
                      Read More
                      <img
                        src={ButtonArrow}
                        alt="Right Arrow"
                        style={{ paddingLeft: "1.2rem" }}
                      />
                    </Button>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          </Link>
        ))}
      </Grid>
    </>
  );
};

export default BrowseByDisease;
