import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { fetchDiagnosticTests } from '../../services/diagnostic-tests/diagnosticTestServices';
import actions from '../actions';

const { diagnosticTestAction } = actions;

export function* fetchDiagnosticTest() {

    yield takeEvery(diagnosticTestAction.GET_DIAGNOSTIC_TEST, function* ({ testType }) {
        try {
            const response = yield fetchDiagnosticTests(testType);
            if (response.status === 200) {
                yield put({
                    type: diagnosticTestAction.GET_DIAGNOSTIC_TEST_SUCCESS,
                    diagnosticTestData: response.data.data,
                    totalCount: response.data.count
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}


export default function* rootSaga() {
    yield all([fork(fetchDiagnosticTest)]);
}