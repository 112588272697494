import { combineReducers } from "redux";
import authReducer from "./authReducer";
import categoryReducer from './categoryReducer'
import treatmentPlanReducer from './treatmentPlanReducer'
import blogReducer from './blogReducer'
import questionsReducer from './questionnaireReducer'
import doctorsReducer from './doctorsReducer'
import locationReducer from './LocationReducer'
import diagnosticsTestReducer from './diagnosticsTestReducer';
import packageReducer from "./packageReducer";
import influencerReducer from './registrationReducer';

const createReducer = asyncReducers =>
    combineReducers({
        authReducer,
        categoryReducer,
        treatmentPlanReducer,
        blogReducer,
        questionsReducer,
        doctorsReducer,
        locationReducer,
        diagnosticsTestReducer,
        packageReducer,
        influencerReducer,
        ...asyncReducers
    });

export default createReducer;