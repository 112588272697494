import React, { useState } from "react";
import {
  Input,
  makeStyles,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import styles from "../styles";
import "../Landing.scss";
import { subscribeToNews } from "../../../services/subscriptions/subscriptionServices";
import { showNotification } from "../../common-notification/CommonNotification";

const useStyles = makeStyles((theme) => styles(theme));

const NewsletterForm = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const [fullname, setFullname] = useState("");

  const onChangeFullname = (e) => {
    const input = e.currentTarget.value;
    if (/^[^!-\/:-@\[-`{-~0-9]+$/.test(input) || input === "") {
      setFullname(input);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { userName, userEmail } = event.target.elements;
      let payLoad = {
        name: userName.value,
        email: userEmail.value,
        type: "doctallweb",
      };
      console.warn(payLoad);
      let response = await subscribeToNews(payLoad);
      if ((response && response.status === 200) || response.status === 201) {
        showNotification(
          "success",
          "Success !!",
          "You have suceesfully subscribed to newsletter"
        );
        event.target.reset();
      }
    } catch (error) {
      if (error && error.response) {
        showNotification("error", "Failed !!", "" + error.response.data);
      }
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ width: "100%", marginTop: matchesMD ? "-40px" : "-20px" }}
      >
        <FormControl variant="standard" fullWidth>
          <label
            htmlFor="name"
            className={classes.inputLabel}
            // style={{paddingtop: "-20px"}}
          >
            Full name*
          </label>
          <Input
            onChange={onChangeFullname}
            value={fullname}
            inputProps={{
              maxLength: 25,
            }}
            type="text"
            required
            name="userName"
            placeholder="Enter your name"
            className={classes.inputField}
            disableUnderline={true}
          />

          <label
            htmlFor="name"
            className={classes.inputLabel}
            style={{ paddingTop: "10px" }}
          >
            Email*
          </label>
          <Input
            name="userEmail"
            type="email"
            required
            placeholder="Enter your email"
            className={classes.inputField}
            disableUnderline={true}
          />
          <Typography
            variant="h6"
            style={{
              textAlign: matchesSM ? "justify" : undefined,
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "20px",
              color: "#667085",
              alignSelf: "stretch",
              marginTop: "24px",
            }}
          >
            *By subscribing, you are agreeing to receive emails from Doctall, in
            line with our Privacy Policy.Your details are safe with us. You can
            unsubscribe at any time.
          </Typography>

          <Button
            variant="contained"
            type="submit"
            className={classes.subscribeButton}
          >
            Subscribe to Newsletter
          </Button>
        </FormControl>
      </form>
    </>
  );
};

export default NewsletterForm;
