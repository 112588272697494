import React from "react";
import { Link } from "react-router-dom";

const Notfound = () => {
    return <div style={{alignItems:"center", marginTop:"15%", textAlign:"center"}}>
    <h1>💩 404 ERROR</h1>
    <h1>PAGE NOT FOUND.</h1>
    <h3>Please <Link to="/">Click Here</Link> for Home page </h3>
    </div>
}
export default Notfound