import React, { useEffect, useState, useRef } from "react";
import "../styles.js";
import { questions } from "../Questions";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  useMediaQuery,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import styles from "../styles";
import { makeStyles, useTheme } from "@material-ui/core";
import AlertClose from "../../../assets/images/AlertClose.svg";
import {
  NeutralComp,
  YesComp,
  NoComp,
  Alert,
  InputDetails,
} from "../util/UiComp";
import CallBackPopUp from "../../callbackPopUp/CallBackPopUP.js";
import { showNotification } from "../../../components/common-notification/CommonNotification.js";
import SingleQuestionInput from "./SingleQuestionInput.js";
import MultipleQuestionsInput from "./MultipleQuestionsInput.js";
import { saveQuestionnaire } from "../../../services/category-services/categoryServices";
const useStyles = makeStyles((theme) => styles(theme));
var uuid ;
console.log(uuid, "uuid")
export const MultiChoice = ({ question, stepUpdate, step, saveData, sav, disease_uuid, disease_name, uuidata }) => {
  const classes = useStyles();
console.log(sav,"MultiChoice")
  const [selectedValue, setSelectedValue] = React.useState({
    question: question.title,
    response: "",
  });

        const saveQuestions = async () => {
          try {
            let payLoad = {
              // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
              disease_uuid: disease_uuid,
              disease_name: disease_name,
              questionanswer: [sav],
            };
            let response = await saveQuestionnaire(payLoad);
            if ((response && response.status === 200) || response.status === 201) {
              console.log(response.data.uuid,"Saved");
  
              uuid = response.data.uuid;
              // uuidata(uuid)
              // return uuidata(uuid)
              // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
            }
            return uuid ;
          } catch (error) {
            console.log(error);
          }
        };
      useEffect(() => {
        saveQuestions()
        // uuidata(uuid)
      },[uuid])
  
  const handleChange = (event) => {
    setSelectedValue({ ...selectedValue, response: event.target.value });
    saveData({ ...selectedValue, response: event.target.value });
    stepUpdate(step + 1);
  };

  const Unchecked = ({ label }) => (
    <Grid className={classes.quesOption}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );
  const Checked = ({ label }) => (
    <Grid className={classes.quesChecked}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "100px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{ paddingBottom: "56px" }}
      >
        {question.title}
      </Typography>

      {question.options.map((option) => (
        // if (options === "None"){

        //   selectedValue === "None"

        // }
        <Radio
          checked={selectedValue === option}
          onChange={handleChange}
          value={option}
          name="radio-buttons"
          inputProps={{ "aria-label": option }}
          icon={<Unchecked label={option} />}
          checkedIcon={<Checked label={option} />}
          disableFocusRipple
          disableTouchRipple
          disableRipple
        />
      ))}
    </Grid>
  );
};

export const MultiChoiceValidation = ({
  question,
  stepUpdate,
  step,
  saveData,
  sav,
  uuidata,
  disease_name,
  disease_uuid,
  uuidDaata
}) => {
  const classes = useStyles();
  console.log(sav,"MultiChoice")
  const [selectedValue, setSelectedValue] = React.useState({
    question: question.title,
    response: "",
  });

  console.log(uuid,uuidDaata,"uuid")
  const saveQuestions = async () => {
    try {
          let payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            uuid : uuid,
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };
          let response = await saveQuestionnaire(payLoad);
          if ((response && response.status === 200) || response.status === 201) {
            console.log(response.data.uuid,"Saved");
            uuid = response.data.uuid;
            // uuidata(uuid)
            // uuidata(response.data.uuid)
            // uuid = response.data.uuid;
            // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
          }
        } catch (error) {
          console.log(error);
        }
      };
console.log(uuid,)
      useEffect(() => {
        saveQuestions()
        // uuidata(uuid)
      },[])
  const handleChange = (event) => {
    setSelectedValue({ ...selectedValue, response: event.target.value });
    saveData({ ...selectedValue, response: event.target.value });
    stepUpdate(step + 1);
  };
  const Unchecked = ({ label }) => (
    <Grid className={classes.quesOption}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );
  const Checked = ({ label }) => (
    <Grid className={classes.quesChecked}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "100px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{ paddingBottom: "56px" }}
      >
        {question.title}
      </Typography>

      {question.options.map((option,index) => (
       
         
        <Radio 
          checked={selectedValue.response === option}   
          onChange={handleChange}
          value={option}
          name="radio-buttons"
          inputProps={{ "aria-label": option }}
          icon={<Unchecked label={option} />}
          checkedIcon={<Checked label={option} />}
          disableFocusRipple
          disableTouchRipple
          disableRipple
        />
      ))}
    </Grid>
  );
};

export const RadioChoiceResponse = ({ ques, stepUpdate, step, saveData, sav, disease_name, disease_uuid, uuidata }) => {
  const theme = useTheme();
  console.log(sav, "MultiChoice")
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [value, setValue] = React.useState("");

  const [question, setQuestion] = useState({
    question: "",
    value: "",
    remark: "",
  });
  const [getResponce, setgetResponse] = useState(false);
  const [userRemark, setUserRemark] = useState("");

  const [alertInput, setAlertInput] = useState("");

  const saveQuestions = async () => {
    try {
          let payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            uuid : uuid,
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };
          let response = await saveQuestionnaire(payLoad);
          if ((response && response.status === 200) || response.status === 201) {
            console.log(response.data.uuid,"Saved");
            uuid = response.data.uuid;
            () => uuidata(response.data.uuid)
            // uuidata(response.data.uuid)
            // uuid = response.data.uuid;
            // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
          }
        } catch (error) {
          console.log(error);
        }
      };
console.log(uuid,)
      useEffect(() => {
        saveQuestions()
        uuidata(uuid)
      },[uuid])

  useEffect(() => {
    setQuestion({ ...question, question: ques.title, remark: userRemark });
  }, [ques]);



  const _Next = () => {
    if (userRemark) {
      setQuestion({ ...question, question: ques.title, remark: userRemark });
      saveData({ ...question, value: "Yes", remark: userRemark });
      stepUpdate(step + 1);
    } else {
      showNotification('error', "Failed", "Please specify what was the nature of the reaction")
    }
  }


  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleValueChange = (e) => {
    if (e.target.value === "Yes") {
      setgetResponse(true);
    } else {
      setgetResponse(false);
      stepUpdate(step + 1);
      saveData({ ...question, value: e.target.value, remark: userRemark });
    }
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "104px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{
          paddingBottom: "46px",
          fontSize: "30px",
          lineHeight: "38px",
          width: "80%",
        }}
        align="center"
      >
        {ques.title}
      </Typography>
      <Grid item>
        <FormControl>
          <RadioGroup
            aria-labelledby="Impairment"
            name="Impairment"
            value={value}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="Yes"
              control={
                <Radio
                  onChange={handleValueChange}
                  icon={<NeutralComp value="Yes" />}
                  checkedIcon={<YesComp />}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
            />
            <FormControlLabel
              value="No"
              control={
                <Radio
                  onChange={handleValueChange}
                  icon={<NeutralComp value="No" />}
                  checkedIcon={<NoComp />}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* <Grid item style={{ width: "114px", marginRight: "39px" }}>
            <button>reset</button>
            </Grid> */}
      {getResponce ? (
        <>
          <InputDetails userResponse={(event) => setUserRemark(event)} />
          <Button className={classes.ProceedButton} onClick={_Next}>Proceed</Button>
        </>
      ) : null}
    </Grid>
  );
};

export const MultiChoiceResponse = ({
  question,
  stepUpdate,
  step,
  saveData,
}) => {
  const classes = useStyles();
  const [getResponse, setgetResponse] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState({
    question: question.title,
    options: [],
  });
  // var selectedvalue = options.slice(0,4)

  const [userSelection, setUserSelection] = React.useState({});

  let newOption = [];

  useEffect(() => {
    question.options.map((option) => {
      newOption.push({ option: option, state: false, details: "" });
      console.log(newOption,"newOption")
    });
    setSelectedValue({
      question: question.title,
      options: newOption,
    });
  }, [question]);




  const _next = () => {
    if (userSelection.value !== undefined || userSelection.remark !== undefined) {
      saveData(userSelection)
      stepUpdate(step + 1);
    } else {
      showNotification(
        "error",
        "Failed",
        "Please make selection and specify what was the nature of the reaction")
    }
  }


  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "100px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{ paddingBottom: "56px" }}
      >
        {question.title}
      </Typography>
      {console.log(selectedValue,"selectedValue")}
      {selectedValue.options.slice(0,4).map((option) => (
        <>

          <SingleQuestionInput
            option={option}
            question={question}
            saveDetails={(data) => setUserSelection(data)}
          />
        </>
      ))}
     
      {
        <>
          <Button className={classes.ProceedButton} onClick={_next}>Proceed</Button>
        </>
      }
    </Grid>
  );
};

export const MedicalConditions = ({
  condition,
  stepUpdate,
  step,
  saveData,
  sav,
  uuidDaata,
  disease_name, 
  disease_uuid,
  uuidata
}) => {
  console.log(uuidata)
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState("");
  const [conValue, setConValue] = React.useState([]);
  const [seeDoctor, setSeeDoctor] = useState(false);
  const [getResponce, setgetResponse] = useState(false);
  const [callBackModel, setCallBackModel] = useState(false);
  const [userSelection, setUserSelection] = React.useState({});
  const [finalResponse, setFinalResponse] = React.useState([]);
  const focusPoint = useRef(null);
console.log(uuidDaata, "uuidDaata2")
  const saveQuestions = async () => {
    try {
          let payLoad = {
            // user_uuid: "_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid",
            uuid : uuid,
            disease_uuid: disease_uuid,
            disease_name: disease_name,
            questionanswer: [sav],
          };
          let response = await saveQuestionnaire(payLoad);
          if ((response && response.status === 200) || response.status === 201) {
            console.log(response.data.uuid,"Saved");
            uuid = response.data.uuid;
            uuidata(uuid)
            // uuidata(response.data.uuid)
            // uuid = response.data.uuid;
            // addFireBaseAnalytics("QUESTIONNAIRE_COMPLETED", '')
          }
        } catch (error) {
          console.log(error);
        }
      };
console.log(uuid,)
      useEffect(() => {
        saveQuestions()
        uuidata(uuid)
      },[uuid])

  useEffect(() => {
    if (seeDoctor) {
      window.scrollTo(0, focusPoint.current.offsetTop);
    }
  }, [seeDoctor]);

  useEffect(() => {
    const conList = [];
    condition.questions.map((ques) => {
      conList.push({ question: ques, value: "" });
    });
    setConValue(conList);
  }, [condition]);



  useEffect(() => {
    try {
      let mFinalList = finalResponse.filter(function (element) {
        return element !== undefined;
      });

      let checkedResponse = mFinalList.filter(function (element) {
        return element.checked === "Yes";
      });

      if (checkedResponse.length > 0) {
        setgetResponse(true)
      } else {
        setgetResponse(false)
      }

      let unCheckedResponse = mFinalList.filter(function (element) {
        return element.checked === "No";
      });

      if (unCheckedResponse.length === condition.questions.length) {
        onSubmit()
      }
    } catch (error) {
      console.log(error)
    }

  }, [finalResponse]);


  const onSubmit = () => {
    let mFinalList = finalResponse.filter(function (element) {
      return element !== undefined;
    });

    try {
      mFinalList.map((item) => {
        let userRes = item.remark;

        if (condition.questions.length !== mFinalList.length) {
          showNotification("error", "Failed", "Please Answer All The Quetions")
          throw new Error("failed")
        }

        if (item.checked === "Yes" && userRes === null || userRes === undefined) {
          showNotification("error", "Failed", "Please specify what was the nature of the reaction for - " + item.question)
          throw new Error("failed")
        }

      })

      saveData(mFinalList)
      stepUpdate(step + 1);
    } catch (error) {
      console.log('error')
    }
  }


  const handleSelection = (event) => {
    if (finalResponse.length >= 1) {
      const indexToUpdate = conValue.findIndex((item) => item.question === event.question);
      const updatedState = [...finalResponse];
      updatedState[indexToUpdate] = event;
      setFinalResponse(updatedState)
    } else {
      let data = [];
      data.push(event)
      setFinalResponse(data)
    }
  }



  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "40px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{ paddingBottom: "46px", fontSize: "30px", lineHeight: "38px" }}
      >
        {condition.title}
      </Typography>
      {conValue.map((ques) => (
        <>
          <MultipleQuestionsInput
            ques={ques}
            saveDetails={(data) => handleSelection(data)}
            condition={condition}
            showProceed={(flag) => setgetResponse(flag)}
          />
        </>
      ))}
      {getResponce ? (
        <>
          <Button className={classes.ProceedButton} onClick={onSubmit}>Proceed</Button>
        </>
      ) : null}

      {seeDoctor ? (
        <Alert>
          <img src={AlertClose} alt="Close" style={{ paddingTop: "52.51px" }} />
          <Typography
            // ref={focusPoint}
            style={{
              paddingTop: "49px",
              paddingBottom: "8px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#002931",
            }}
          >
            Not suitable for treatment
          </Typography>
          <Typography
            align="center"
            style={{
              width: matchesSM ? "250px" : "421px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#002931",
              paddingBottom: "47px",
            }}
            ref={focusPoint}
          >
            Based on your answer, you are not suitable for Rx. Change your
            answer or kindly click on the button and talk to a doctor.
          </Typography>
          <Button
            className={classes.talkToADoctorButton}
            onClick={() => setCallBackModel(!callBackModel)}
          >
            TALK TO A DOCTOR
          </Button>
        </Alert>
      ) : null}

      <CallBackPopUp
        open={callBackModel}
        close={() => setCallBackModel(!callBackModel)}
      />
    </Grid>
  );
};
