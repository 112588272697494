import React from 'react'
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  Box,
  Tab,
} from "@material-ui/core";
import { Tabs , AppBar, Toolbar  } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from "../../../assets/images/doctall_static_logo.svg";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      background: "#ffffff", 
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
     
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)"
    },
  }));

function StaticHeader() {
    const classes = useStyles();
    const navigate = useNavigate();

  return (
    // <div className={classes.root}>
    <React.Fragment>
    <AppBar position="static" 
    style={{background: "#ffffff", boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)" , height: "90px"}} >
      <Toolbar>
        
        <Grid   
        onClick={() => navigate("/")} 
        style={{cursor: "pointer", marginTop: "30px"}}>
        <img src={logo} alt="logo" />
        {/* <span 
        style={{
          fontSize: "12px",
          fontWeight: "400",
          position: "absolute",
          fontFamily: "Visuelt Pro",
          // height: "18px",
          // width: "103px",
          color: "#00839B",
          top: "60px",
          left: "25px",


        }}>
          Get well, live better
          </span> */}
        </Grid>
        
      
        

        {/* <Tabs 
        style={{ marginLeft: "auto" ,color: "#101828"}} 
        >
          <Tab label="Who we are?" 
          onClick={() => navigate("/about-us")} 
          style={{marginLeft: "auto", fontSize: "14px", fontWeight: "500", lineHeight: "20px"}}/>
          <Tab label="FAQs"  
          onClick={() => navigate("/faq")} 
          style={{marginLeft: "40px", fontSize: "14px", fontWeight: "500", lineHeight: "20px"}}  />

        </Tabs> */}
      </Toolbar>
    </AppBar>
    </React.Fragment>
  // </div>
  )
}

export default StaticHeader