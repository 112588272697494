import {
    Grid
} from "@material-ui/core";
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/doctall_logo.png";
import mark from "../../../assets/images/mark.png";
import success from "../../../assets/images/success.png";
import ".././auth.scss";


function PasscodeChanged() {
    const navigate = useNavigate();
    const goForgotPass = () => {
        navigate('/forgot-passcode');
    }
    return (

        <div className='login_page'>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12} >
                    <div className='Login_img_box'>
                        <img src={mark} alt="login_image" className='Login_img' />
                    </div>

                </Grid>
                <Grid item sm={6} xs={12} >
                    <div className='login_container'>
                        <div className='doctall_logo'>
                            <Link to="/">
                                <img src={logo} alt="login_image" />
                            </Link>
                        </div>

                        <div className='form_section'>
                            <div className="passcode_container">
                                <img src={success} alt="success_icon" />
                                <h1 className='Login_heading' style={{ marginTop: "30px" }}>Passcode change</h1>
                                <p className='login_para'>You will be automatically logged out in a few seconds</p>
                            </div>

                            <button type="submit" value="Submit" className='signin_btn' onClick={goForgotPass}>Continue</button>
                        </div>
                    </div>
                </Grid>

            </Grid>
        </div>
    )
}

export default PasscodeChanged