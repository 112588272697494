import Banner from "../../assets/images/Banner.png";
import Banner1 from "../../assets/images/banner_1.svg";
import Banner2 from "../../assets/images/banner_2.svg";
import mobbanner from "../../assets/images/mobbanner.png";
import mobbanner2 from "../../assets/images/mobbanner2.png";
import mobbanner1 from "../../assets/images/mobbanner1.png";
import Banner3 from "../../assets/images/banner_3.svg";
import HBanner from "../../assets/images/HBanner.png";
import Wellness from "../../assets/images/Wellness.png";
import specialistcard from "../../assets/images/specialistCard.svg";
import diagnosisCard from "../../assets/images/diagnosisCard.svg";
import looper3 from "../../assets/images/looper3.svg";
import looper4 from "../../assets/images/looper4.svg";
import iphone from "../../assets/images/iphone.png";
import WellnessPattern from "../../assets/images/wellnessPattern.svg";
import specialistPattern from "../../assets/images/specialistPattern.svg";
import diagnosisPattern from "../../assets/images/diagnosisPattern.svg";
import EDdiseaseCard from "../../assets/images/EDdiseaseCard.svg";
import PECard from "../../assets/images/PECard.png";
import HLCard from "../../assets/images/HairD.png";
import HL from "../../assets/images/HairDise.png";
import EDS from "../../assets/images/EDS.png";
import PEC from "../../assets/images/PEC.png";
import CCF from "../../assets/images/CCF.png";
import Diabetes from "../../assets/images/diabetes_temp.png";
import KFC from "../../assets/images/Kidney.png";
import HHT from "../../assets/images/Hyper.png";
import THY from "../../assets/images/Thyroids.png";
import Covid from "../../assets/images/Covid.png";

const LandingStyle = (theme) => ({
  rowContainer: {
    width: "100%",
    margin: "0px",
  },
  banner: {
    // background: `linear-gradient(98.09deg, rgba(1, 9, 11, 0.7) 41.96%, rgba(0, 26, 30, 0.7) 99.06%),url(${Banner1})`,
    // background: `radial-gradient(85.45% 245.86% at 78.02% 50.08%, #D9EDF1 0%, #92D5E6 100%),url(${Banner1})`,
    backgroundImage: `url(${Banner1})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "100%",
    paddingLeft: "9.6rem",
    paddingTop: "15.4rem",
    paddingBottom: "9.6rem",
    backgroundPosition: "45% 2%",
    backgroundBlendMode: "screen",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "4rem",
      paddingRight: "4rem",
      height: "600px",
      backgroundBlendMode: "screen",
      backgroundPosition: "45% ",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${mobbanner})`,
    },
  },
  banner1: {
    // background: `linear-gradient(98.09deg, rgba(1, 9, 11, 0.7) 41.96%, rgba(0, 26, 30, 0.7) 99.06%),url(${Banner2})`,
    // background: `radial-gradient(85.45% 245.86% at 78.02% 50.08%, #FF8D77 0%, #FF5C3C 100%)`,
    backgroundImage: `url(${HBanner})`,
    // backgroundImage:`url(${Banner2}),,url(${looper3})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "100%",
    paddingLeft: "9.6rem",
    paddingTop: "15.4rem",
    paddingBottom: "9.6rem",
    backgroundBlendMode: "screen",
    backgroundPosition: "45% ",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "4rem",
      paddingRight: "4rem",
      height: "600px",
      backgroundPosition: "45% ",

      backgroundBlendMode: "screen",

      backgroundImage: `url(${mobbanner1})`,

      backgroundRepeat: "no-repeat",
    },
  },
  banner2: {
    // background: `linear-gradient(98.09deg, rgba(1, 9, 11, 0.7) 41.96%, rgba(0, 26, 30, 0.7) 99.06%),url(${Banner3})`,
    backgroundImage: `url(${Banner2})`,
    // background: `radial-gradient(80.63% 131.37% at 75.59% 44.44%, #F2F8F9 0%, #D9F1EA 100%)`,
    // backgroundImage:`url(${Banner3}),,url(${looper3})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "100%",
    paddingLeft: "9.6rem",
    paddingTop: "15.4rem",
    paddingBottom: "9.6rem",
    backgroundBlendMode: "screen",
    backgroundPosition: "45% 2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "4rem",
      paddingRight: "4rem",
      height: "600px",
      backgroundBlendMode: "screen",
      backgroundPosition: "85% 80%",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${mobbanner2})`,
    },
  },
  bannerTestimonial: {
    paddingTop: "20px",
  },

  bannerTestimonialImage: {
    width: "53px",
    height: "53px",
    borderRadius: "164px",
  },
  bannerPrimary: {
    fontFamily: 'Visuelt Pro',
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    position: "absolute",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      lineHeight: "40px",
      top: "36px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      lineHeight: "36px",
      top: "16px",
      lineHeight: "30px",
    },
  },
  testiPrimary: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
  },

  testiSecondary: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    /* identical to box height, or 143% */

    color: "#BCFFEB",
  },
  bookADoctor: {
    ...theme.typography.secondaryButton,
    // background:"#ffffff",
    padding: "12px 20px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "15.6rem",
    marginBottom: "7.27rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-130px",
    },
  },
  bannerArrow: {
    position: "relative",
    marginTop: "5rem",
  },
  bannerArrowPrev: {
    position: "absolute",
    zIndex: theme.zIndex.modal + 1,
    bottom: "10.6rem",
    left: "9.6rem",
    color: "white",
    cursor: "pointer",
  },
  bannerArrowNext: {
    position: "absolute",
    zIndex: theme.zIndex.modal + 1,
    bottom: "10.6rem",
    left: "14.1rem",
    color: "white",
    cursor: "pointer",
  },
  diseaseContainer: {
    width: "100%",
    height: "auto",
    paddingTop: "10.6rem",
    paddingLeft: "4.6rem",

    paddingBottom: "4.4rem",

    padding: "20px 0px",

    [theme.breakpoints.down("lg")]: {
      // paddingLeft: "4.7rem",
      // paddingRight: "4.7rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
    },
    [theme.breakpoints.down("xl")]: {
      // paddingLeft: "5.6rem",
      // paddingRight: "6.6rem",
    },
  },
  subHeading: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#002931",
  },
  diseaseCardED: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${EDS})`,
    backgroundSize: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "110% bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    boxSizing: "border-box",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",
    flexDirection: "column",
    display: "flex",
    position: "relative",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${EDS})`,
      backgroundSize: "50%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "110% bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },

  diseaseCardHL: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${HL})`,
    backgroundSize: "70%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "110% bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",
    flexDirection: "column",
    display: "flex",
    position: "relative",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${HL})`,
      backgroundSize: "70%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "110% bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },

  diseaseCardPE: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${CCF})`,
    backgroundSize: "45%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "110% bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",
    flexDirection: "column",
    display: "flex",
    position: "relative",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${CCF})`,
      backgroundSize: "45%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "110% bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },
  diseaseCardDD: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",
    flexDirection: "column",
    display: "flex",
    position: "relative",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${EDdiseaseCard})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "110% bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },
  diseaseCardDD: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${Diabetes})`,
    backgroundSize: "60%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "110% bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",
    flexDirection: "column",
    display: "flex",
    position: "relative",
    paddingBottom:"30px",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${Diabetes})`,
      backgroundSize: "60%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "110% bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },
  diseaseCardKF: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${KFC})`,
    backgroundSize: "55%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "110% bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",
    flexDirection: "column",
    display: "flex",
    position: "relative",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${KFC})`,
      backgroundSize: "55%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "110% bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },
  diseaseCardHH: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${HHT})`,
    backgroundSize: "55%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "110% bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",
    flexDirection: "column",
    display: "flex",
    position: "relative",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${HHT})`,
      backgroundSize: "55%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "110% bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },
  diseaseCardTH: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${THY})`,
    backgroundSize: "55%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "110% bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",
    flexDirection: "column",
    display: "flex",
    position: "relative",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${THY})`,
      backgroundSize: "55%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "110% bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },
  diseaseCardCV: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    backgroundImage: `URL(${Covid})`,
    backgroundSize: "55%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "110% bottom",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",
    flexDirection: "column",
    display: "flex",
    position: "relative",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${Covid})`,
      backgroundSize: "55%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "110% bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },

  diseaseCardContent: {
    justifyContent: "space-around",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "3rem",
    paddingTop: "3.3rem",
    paddingRight: "2.7rem",
  },
  diseaseCardContent1: {
    backgroundImage: `URL(${PEC})`,
    backgroundSize: "45%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    justifyContent: "space-around",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "3rem",
    paddingTop: "3.3rem",
    alignItems: "flex-end",
  },

  readMoreButton: {
    ...theme.typography.primaryButton,
    border: "1px solid #00839B",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    padding: "8px 14px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    marginLeft: "3rem",
    position: "absolute",
    bottom: "66px",
  },
  healthyLivingContainer: {
    paddingLeft: "9.5rem",
    paddingTop: "6.8rem",
    paddingBottom: "10.8rem",
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
  },
  wellnessCard: {
    ...theme.typography.imageCard,
    width: "400px ",
    backgroundImage: `url(${Wellness}),url(${WellnessPattern})`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#00839B",
    borderRadius: "10px",
    backgroundBlendMode: "normal,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${Wellness}),url(${WellnessPattern})`,
      width: "300px",
    },
  },

  specialistCard: {
    ...theme.typography.imageCard,
    width: "400px ",
    // width:"100%",
    backgroundImage: `url(${specialistcard}),url(${specialistPattern})`,
    background: "#002C19",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundBlendMode: "normal,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${specialistcard}),url(${specialistPattern})`,
      width: "300px",
    },
  },
  diagnosisCard: {
    ...theme.typography.imageCard,
    width: "400px ",
    // width:"90%",
    backgroundImage: `url(${diagnosisCard}),url(${diagnosisPattern})`,
    background: "#BF0B02",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundBlendMode: "normal,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${diagnosisCard}),url(${diagnosisPattern})`,
      width: "300px",
    },
  },
  livingButton: {
    ...theme.typography.tertiaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#143656",
    position: "absolute",
    bottom: "30px",
    [theme.breakpoints.down("sm")]: {
      bottom: "20px",
    },
  },
  popularProductContainer: {
    alignItems: "center",
    // paddingLeft: "9.4rem",
    paddingTop: "6.8rem",
    width: "100%",
    height: "auto",
    backgroundColor: "#F7FBF8",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  viewProductButton: {
    ...theme.typography.secondaryButton,
    width: "168px",
    background: "#FF5C3C",
    border: "1px solid #FF5C3C",
    boxSizing: "border-box",
    marginRight: "9.8rem",
    /* Shadow/xs */

    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  productCard: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "24px",
    paddingBottom: "32px",
    width: "282px",
    height: "410px",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
  },
  testsCard: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "24px",
    paddingBottom: "32px",
    width: "282px",
    height: "auto",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
  },
  titleContainer: {
    background: "#F2F8F9",
    width: "250px",
    height: "auto",
    gridGap: "14px",
    marginBottom: "20px",
  },
  productTitle: {
    ...theme.typography.title,
    color: "#00839B",
  },
  viewTestButton: {
    ...theme.typography.primaryButton,
    width: "250px",
    height: "48px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  appLinkContainer: {
    maxWidth: "1248px",
    height: "auto",
    background: "#005F71",
    // marginLeft:"35px",
    borderRadius: "10px",
    backgroundImage: `url(${iphone}),url(${looper3})`,
    backgroundPosition: "right bottom",
    backgroundRepeat: "no-repeat",

    [theme.breakpoints.down("md")]: {
      width: "80%",
      backgroundImage: `url(${looper3})`,
    },

    [theme.breakpoints.down("sm")]: {
      width: "300px",
      backgroundImage: `url(${looper3})`,
    },
  },
  linkBox: {
    paddingLeft: "10.1rem",
    paddingTop: "4.7rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  StoreMain: {
    display: "flex",

    background: "#FFFFFF",
    /* Gray/100 */

    border: "2px solid #F2F4F7",
    borderRadius: "5.31px",
  },
  blogContainer: {
    paddingLeft: "9.7rem",
    paddingTop: "7.6rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  viewBlogsButton: {
    ...theme.typography.secondaryButton,
    width: "157px",
    padding: "12px 18px",
    background: "#FF5C3C",
    border: "1px solid #FF5C3C",
    boxSizing: "border-box",
    marginRight: "6.3rem",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  blogCard: {
    width: "340px",
    height: "440px",
    boxShadow: "none",
    borderRadius: "0px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      height: "500px",
    },
  },
  blogCard2: {
    width: "340px",
    height: "600px",
    boxShadow: "none",
    borderRadius: "0px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      height: "500px",
    },
  },
  cardSetter: {
    padding: "0px",
  },
  cardBody: {
    ...theme.typography.title,
    fontWeight: "400",
    color: "#002931",
    marginBottom: "32px",
    height: "48px",
    textAlign: "left",
    //width: "384px",
    lineHeight: "24px",
    wordBreak: "break-word",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    MozBoxOrient: "vertical;",
    MsBoxOrient: "vertical",
    BoxOrient: "vertical",
    WebkitLineClamp: "5",
    MozLineClamp: "5",
    MsLineClamp: "5",
    LineClamp: "5",
    overflow: "hidden",
  },
  blogButton: {
    ...theme.typography.primaryButton,
    padding: "12px 20px",
    width: "119px",
    height: "48px",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    //position: "absolute",
    left: "0px",
    bottom: "40px",
  },
  partnerContainer: {
    width: "100%",
    height: "339px",
    background: "#F7FBF8",
    paddingLeft: "64px",
    // paddingTop: "81px",
    paddingRight: "96px",
    // marginBottom:"250px",
    // paddingBottom:"150px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "48px",

      paddingRight: "75px",
    },
  },
  partnerTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#002931",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  partnerInnerContainer: {
    paddingTop: "48px",
    paddingLeft: "16px",
  },
  newsLetterContainer: {
    width: "100%",
    background: "#F2F8F9",
    height: "auto",
    paddingLeft: "101px",
    paddingRight: "96px",
    paddingBottom: "98px",
    backgroundImage: `url(${looper4})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 28%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  newsLetterContent: {
    width: "491px",
    height: "auto",
    paddingTop: "94px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding:"10px",
      // textAlign: "center",
    },
    // [theme.breakpoints.down("xs")]: {
    //   width: "300px",
    //   // textAlign: "center",
    // },
  },

  newLetterSubHeading: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
  },
  newsLetterForm: {
    // width: "647px",
    height: "337px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "10px",
    marginTop: "74px",
    marginLeft: "105px",
    paddingLeft: "42px",
    paddingRight: "42px",
    paddingTop: "54px",
    paddingBottom: "54px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.down("md")]: {
      width: "600px",
      paddingLeft: "36px",
      paddingRight: "36px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "530px",
      paddingLeft: "36px",
      paddingRight: "36px",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "24px",
      paddingRight: "24px",
      marginLeft: "0px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  inputLabel: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    // paddingBottom: "6px",
    fontFamily: "Visuelt Pro",
    marginBottom: "10px",
    /* identical to box height, or 143% */

    /* Gray/700 */

    color: "#344054",
  },
  inputField: {
    background: "#FFFFFF",
    /* Gray/300 */
    width: "100%",
    height: "44px",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "10px 14px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    "&:focus": {
      border: "1px solid #D0D5DD",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  subscribeButton: {
    ...theme.typography.secondaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "10px 0px",
    marginTop: "20px",
    borderRadius: "8px",
    position: "absoute",
    bottom: "10px",
    // backgroundColor:"#C53B20",
  },
  browse_by_disease: {
    padding: "50px 130px",
    background: "#F7FBF8",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
});

export default LandingStyle;
