import * as React from "react";
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";
import { useTheme, useMediaQuery } from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function TimeSloteSelector({ timeSlotes, selectedSlotes }) {
  const theme = useTheme();
  const [slotTime, setSlotTime] = React.useState([]);
  const _userInfo = useSelector((state) => state.authReducer);
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  React.useEffect(() => {
    if (timeSlotes && timeSlotes.length > 0) {
      setSlotTime(timeSlotes[0]._id);
      let sloteInfo = timeSlotes[0];
      let label = moment(sloteInfo.start_time).format("LT");
      let label2 = moment(sloteInfo.start_time).format("DD MMM YYYY");
      let sloteDate = moment(sloteInfo.date).format("ddd  MM/DD");
      let finalLabel = `${label2} | ${label}`;
      let payLoad = {
        label: finalLabel,
        value: sloteInfo._id,
        date: sloteDate,
      };
      selectedSlotes(payLoad);
    }
  }, [timeSlotes]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSlotTime(value);
    let sloteInfo = timeSlotes.find((item) => item._id === value);
    let label = moment(sloteInfo.start_time).format("LT");
    let label2 = moment(sloteInfo.start_time).format("DD MMM YYYY");
    let sloteDate = moment(sloteInfo.date).format("ddd  MM/DD");
    let finalLabel = `${label2} | ${label}`;
    let payLoad = { label: finalLabel, value: value, date: sloteDate };
    selectedSlotes(payLoad);
  };

  return (
    <div>
      {timeSlotes && timeSlotes.length > 0 ? (
        <FormControl
          sx={{ m: 1, width: 300, mt: 3 }}
          md={{ m: 1, width: 600, mt: 3 }}
        >
          <Select
            style={{ marginTop: "-13px", width: matchesXS ? "300px" : "325px" }}
            value={slotTime}
            onChange={handleChange}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
          >
            {timeSlotes &&
              timeSlotes.length > 0 &&
              timeSlotes.map((slot) => (
                <MenuItem key={slot._id} value={slot._id}>
                  {moment(slot.start_time).format("lll")}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <Typography
          style={{
            fontFamily: "visuelt Pro",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#667085",
          }}
        >
          {" "}
          {"No Available Slots"}
        </Typography>
      )}
    </div>
  );
}
