import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  CardContent,
  Card,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import dWQoverviewVector1 from "../../../assets/images/dWQoverviewVector1.png";
import dWQoverviewVector2 from "../../../assets/images/dWQoverviewVector2.svg";
import dWQoverviewVector3 from "../../../assets/images/dWQoverviewVector3.svg";
import dWQoverviewVector4 from "../../../assets/images/dWQoverviewVector4.svg";
import dWQoverviewVector5 from "../../../assets/images/dWQoverviewVector5.svg";
import dWQoverviewVector6 from "../../../assets/images/dWQoverviewVector6.svg";

import easyToBuy from "../../../assets/images/easy-to-buy.svg";

import AppLink from "../../../components/Landing/components/AppLink";
import FAQ from "../../../components/ui/FAQ";
import TreatmentPlan from "./TreatmentPlan";
import SpecialistDoctor from "../SpecialistDoctor";

import TreatmentPlanForm from "./TreatmentPlanForm";
import Blogs from "../Blogs";
import Banner from "../Banner";
import { useDispatch, useSelector } from "react-redux";
import { getDiesesIdForBlog, getSpeciality } from "../utils/diseasesIds";
import blogActions from "../../../redux/actions/blogActions";
import doctorsActions from "../../../redux/actions/doctorsActions";
import CallBackPopUp from "../../callbackPopUp/CallBackPopUP";
import { _redirect } from "../../../components/Redirect/Redirect";
import phoneIcon from "../../../assets/images/phone-icon.svg";
import delivery from "../../../assets/images/deliveryFree.png";
import DoctorSupport from "../../../assets/images/DoctorSupport.png";

const useStyles = makeStyles((theme) => styles(theme));

const DiseaseWithQuestionaire = ({
  disease,
  offerSelected,
  setOfferSelected,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedDisease } = useParams();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const custommatchesXL = useMediaQuery("(min-width:1500px)");
  const [alignment, setAlignment] = React.useState("");
  const [callBackModel, setCallBackModel] = useState(false);
  const [showSymptoms, setShowSymptoms] = useState(false);

  // const [offerSelected, setOfferSelected] = useState(
  //   disease.wellbeing.offers[0].title
  // );

  useEffect(() => {
    setOfferSelected(disease.wellbeing.offers[0].title);
  }, [disease]);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    dispatch(blogActions.getLivingBlog(getDiesesIdForBlog(disease.name), 10));
    let payLoad = getSpeciality(disease.name);
    dispatch(doctorsActions.getSpecialistDoctors(payLoad, ""));
  }, [disease]);

  const [value, setValue] = React.useState();
  const [head, setHead] = useState("v1");

  return (
    <>
      <Grid container direction="column" className={classes.dWQContainer}>
        <Banner
          name={disease.name}
          desc={disease.desc}
          banner={disease.banner}
          title={disease.title}
          content={disease.content}
          testimonial={disease.testimonial}
        />

        <Grid item>
          <Grid
            container
            direction={matchesSM ? "column" : "row"}
            style={{
              borderBottom: "1px solid rgba(174, 215, 223, 0.4)",
              minHeight: matchesSM ? "300px" : "auto",
            }}
          >
            <Grid
              item
              lg={4}
              md={4}
              sm
              container
              justifyContent={matchesXS ? "flex-start" : "center"}
              alignItems="center"
              style={{
                height: "100px",
                paddingLeft: matchesXS ? "15%" : undefined,
              }}
            >
              <img src={delivery} alt="iconDeliver" />
              <Typography className={classes.navLink}>
                Discreet delivery
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm
              container
              justifyContent={matchesXS ? "flex-start" : "center"}
              alignItems="center"
              style={{
                height: "100px",
                paddingLeft: matchesXS ? "15%" : undefined,
              }}
            >
              <img
                src={easyToBuy}
                alt="easyToBuyicon"
                style={{ height: "55px" }}
              />
              <Typography className={classes.navLink}>Easy to buy</Typography>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm
              container
              justifyContent={matchesXS ? "flex-start" : "center"}
              alignItems="center"
              style={{
                height: "100px",
                paddingLeft: matchesXS ? "15%" : undefined,
              }}
            >
              <img src={DoctorSupport} alt="iconClinician" />
              <Typography className={classes.navLink}>
                Doctor support available
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* TreatmentPlan Section */}

        {/* {disease.treatmentPlan && (
          <div id="Treatment_plan">
            <Grid
              item
              container
              className={classes.dWQTreatmentContainer}
              direction="column"
            >
              <Typography align="center" className={classes.dWQTreatmentTitle}>
                {" "}
                {disease.treatmentPlan.title}
              </Typography>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <hr
                  style={{
                    margin: "9px 0px",
                    color: "#FF8D77",
                    borderRadius: "60px",
                    height: "2px",
                    border: "none",
                    width: "142.27px",
                  }}
                />
              </span>
              <Typography
                align="center"
                className={classes.dWQTreatmentSubtitle}
              >
                {disease.treatmentPlan.subTitle}
              </Typography>

              <TreatmentPlanForm
                treatments={disease.treatmentPlan.treatments}
                diseaseName={disease.name}
              />

              <Grid
                container
                className={classes.TreatmentPlanIfNotSure}
                direction={matchesXS ? "column" : undefined}
                alignItems={matchesXS ? "center" : undefined}
              >
                <Grid
                  md={1}
                  style={{ marginBottom: matchesSM ? "20px" : undefined }}
                >
                  <img src={phoneIcon} />
                </Grid>
                <Grid md={8} XS={12}>
                  <Typography
                    className={classes.NotSureTPHeading}
                    align={matchesXS ? "center" : undefined}
                    paddingLeft={matchesXS ? "5px" : undefined}
                    style={{ padding: matchesXS ? "3px" : undefined }}
                  >
                    Not sure which treatment plan is best for you?
                  </Typography>
                  <Typography
                    className={classes.SpeakwithSubHeading}
                    align={matchesSM ? "center" : undefined}
                    style={{ padding: matchesXS ? "4px" : undefined }}
                  >
                    Speak with our medical team to get support and guidance
                  </Typography>
                </Grid>
                <Grid
                  md={3}
                  style={{
                    marginBottom: matchesSM ? "20px" : undefined,
                    marginTop: matchesSM ? "30px" : undefined,
                  }}
                >
                  <button
                    varient="contained"
                    className={classes.SpeakwithCbtn}
                    onClick={() => setCallBackModel(!callBackModel)}
                  >
                    Speak with Customer Service
                  </button>
                </Grid>
              </Grid>

              <CallBackPopUp
                open={callBackModel}
                close={() => setCallBackModel(!callBackModel)}
              />
            </Grid>
          </div>
        )} */}

        {/* end */}
        {disease.process && (
          <Grid item container className={classes.processContainer}>
            <Grid
              md={12}
              item
              container
              direction="column"
              alignItems={matchesSM ? "flex-start" : undefined}
              marginRight={matchesSM ? "20px" : undefined}
            >
              <Typography className={classes.processHeading}>
                {disease.process.title}
              </Typography>
              <hr
                style={{
                  height: "2px",
                  background: "#FF8D77",
                  borderRadius: "60px",
                  width: "100px",
                  border: "none",
                }}
              />
            </Grid>
            <Grid
              item
              container
              className={classes.dWQprocessCardContainer}
              direction={matchesXS ? "column" : undefined}
              alignItems={matchesSM ? "flex-start" : undefined}
              // justifyContent={matchesSM ? "center" : undefined}
              justifyContent={custommatchesXL ? "center" : undefined}
              style={{
                paddingTop: "44px",
              }}
            >
              {disease.process.processes.map((process, index) => (
                <Grid
                  item
                  key={`${process}${index}`}
                  style={{
                    marginRight: matchesXS ? "0px" : "0px",
                    rowGap: "10px",
                  }}
                >
                  <Card classes={{ root: classes.dWQprocessCard }}>
                    <CardContent
                      classes={{ root: classes.dWQprocessCardContent }}
                    >
                      <img src={process.icon} alt="icon" />
                      <Typography className={classes.processcardTitle}>
                        {process.title}
                      </Typography>
                      <Typography className={classes.processcardDesc}>
                        {process.desc}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {disease.overview && (
          <Grid
            item
            container
            direction="column"
            className={classes.dWQoverviewContainer}
          >
            <div style={{ position: "absolute", left: "6.7%", top: "0px" }}>
              <img src={dWQoverviewVector1} alt="vector1" />
            </div>
            <div style={{ position: "absolute", left: "28%", top: "0px" }}>
              <img src={dWQoverviewVector2} alt="vector2" />
            </div>
            <div style={{ position: "absolute", left: "50%", top: "0px" }}>
              <img src={dWQoverviewVector3} alt="vector3" />
            </div>
            <div style={{ position: "absolute", left: "71.6%", top: "0px" }}>
              <img src={dWQoverviewVector4} alt="vector4" />
            </div>
            <div style={{ position: "absolute", left: "93%", top: "0px" }}>
              <img src={dWQoverviewVector5} alt="vector5" />
            </div>
            <div
              style={{
                position: "absolute",
                left: "0px",
                top: "177px",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <img src={dWQoverviewVector6} alt="vector6" />
            </div>

            <Typography className={classes.dWQoverviewHeading}>
              {disease.overview.title}
            </Typography>
            <Grid item container>
              <Grid
                item
                container
                style={{ marginBottom: "32px" }}
                direction={matchesMD ? "column" : "row"}
                justifyContent={custommatchesXL ? "center" : undefined}
              >
                <Grid item>
                  <Grid
                    item
                    className={
                      showSymptoms === false
                        ? classes.dWQoverviewSubTitle1
                        : classes.dWQoverviewSubTitle2
                    }
                    onClick={() => setShowSymptoms(!showSymptoms)}
                  >
                    {disease.overview.subtitle1}
                  </Grid>
                  <Grid
                    item
                    className={
                      showSymptoms === false
                        ? classes.dWQoverviewSubTitle2
                        : classes.dWQoverviewSubTitle1
                    }
                    onClick={() => setShowSymptoms(!showSymptoms)}
                  >
                    {disease.overview.subtitle2}
                  </Grid>
                </Grid>

                <Grid item className={classes.dWQoverviewDesc1}>
                  {showSymptoms === false
                    ? disease.overview.desc1
                    : disease.overview.desc2.map((desc) => (
                        <Grid item className={classes.dWQoverviewDesc1}>
                          {desc}
                        </Grid>
                      ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {disease.wellbeing && (
          <Grid
            item
            container
            direction="column"
            alignItems={matchesSM ? "center" : undefined}
            className={classes.dWqWellbeing}
          >
            <Typography className={classes.dWQWellbeingTitle}>
              {disease.wellbeing.title}
            </Typography>

            <hr
              style={{
                width: "100px",
                height: "2px",
                background: "#FF8D77",
                borderRadius: "60px",
                marginTop: "9px",
                marginBottom: "16px",
                border: "none",
              }}
            />

            <Typography className={classes.dWQWellbeingDesc}>
              {disease.wellbeing.desc}
            </Typography>
            <Grid
              item
              container
              style={{
                paddingBottom: "46px",
                paddingRight: matchesXS ? "16px" : "64px",
              }}
              // justifyContent={matchesXS || matchesMD ? "center" : undefined}
              // alignItems={matchesXS || matchesMD ? "center" : undefined}
            >
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleChange}
                classes={{
                  groupedHorizontal: classes.buttonGroup,
                  groupedVertical: classes.buttonGroupVertical,
                }}
                orientation={matchesSM ? "vertical" : "horizontal"}
                style={{ width: "100%" }}
              >
                {disease.wellbeing.offers.map((offer) => (
                  <ToggleButton
                    value={offer.title}
                    selected={offer.title === offerSelected}
                    className={classes.dWQOfferTitle}
                    style={{
                      marginRight: matchesXS ? "-10px" : "24px",
                      // marginLeft : matchesXS ? "13px" : "24px",
                      marginBottom: "24px",
                    }}
                    disableRipple
                    onClick={() => {
                      setOfferSelected(offer.title);
                      setHead(offer.header);
                    }}
                    md
                  >
                    <img
                      src={offer.icon}
                      alt="icon"
                      style={{ paddingRight: "20px" }}
                    />

                    {offer.title}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid
              item
              justifyContent={custommatchesXL ? "center" : undefined}
              container
            >
              <Grid
                item
                container
                justifyContent="space-around"
                className={classes.planIntroContainer}
              >
                {disease.wellbeing.planIntro.map((intro) => (
                  <Grid
                    item
                    container
                    direction="column"
                    className={classes.plainIntroInd}
                    alignItems={matchesXS ? "center" : undefined}
                    style={{
                      marginBottom: custommatchesMDX ? "24px" : undefined,
                    }}
                  >
                    {intro.header == head ? (
                      <>
                        <img
                          src={intro.icon}
                          alt="thunder-icon"
                          style={{ paddingBottom: "20px" }}
                        />
                        <Typography className={classes.introTitle}>
                          {intro.title}
                        </Typography>

                        <Grid style={{ paddingLeft: "0px" }}>
                          {intro.content.map((content) => (
                            <Typography className={classes.introContent}>
                              {content}
                            </Typography>
                          ))}
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {offerSelected === "Health Checkup Package" ? (
              <TreatmentPlan treatmentPlan={disease.TreatmentPlanSection} />
            ) : offerSelected === "Speciality  Doctor" ? (
              <SpecialistDoctor 
                doctors={disease.specialistDoctors}
                diseaseName={disease.name}
              />
            ) : (
              <Blogs blogs={disease.blogs} diseaseName={disease.name} />
            )}
          </Grid>
        )}
        {/* <Grid
          item
          container
          direction="row"
          justifyContent={custommatchesXL ? "center" : undefined}
          // className={classes.proceedContainer}
        >
          <Grid
            item
            className={classes.dWQhomeCare}
            style={{
              marginLeft: "40px",
              marginRight: custommatchesMDX ? "0px" : "38px",
              marginBottom: custommatchesMDX ? "24px" : "24px",
            }}
          >
            <Typography
              className={classes.homeCareTitle}
              style={{ width: matchesSM ? "250px" : "385px" }}
            >
              {disease.appointment}
            </Typography>
            <Button
              variant="contained"
              className={classes.homeCareButton}
              onClick={() => _redirect("CONSUMER-DASHBOARD")}
            >
              Book an appointment
            </Button>
          </Grid>
          <Grid
            item
            className={classes.dWQpurchase}
            style={{
              marginLeft: "25px",
              marginRight: custommatchesMDX ? "0px" : "38px",
              marginBottom: custommatchesMDX ? "24px" : "24px",
            }}
          >
            <Typography className={classes.homeCareTitle}>
              {disease.purchase}
            </Typography>
            <Button
              variant="contained"
              className={classes.homeCareButton}
              onClick={() => _redirect("DMP")}
            >
              Purchase a Diagnostic Lab Test
            </Button>
          </Grid>
          </Grid> */}
        <Grid
          item
          container
          justifyContent={
            custommatchesXL ? "center" : matchesMD ? "center" : undefined
          }
          alignItems={matchesMD ? "center" : undefined}
          direction={matchesMD ? "column" : "row"}
          style={{ marginTop: "66px" }}
          className={classes.appointContainer}
        >
          <Grid
            item
            className={classes.dWQhomeCare}
            style={{
              marginRight: matchesMD ? "0px" : "48px",
              marginBottom: custommatchesMDX ? "24px" : "24px",
            }}
          >
            <Typography
              className={classes.homeCareTitle}
              style={{ width: matchesSM ? "250px" : "385px" }}
            >
              {disease && disease.appointment}
            </Typography>
            <Button
              variant="contained"
              className={classes.homeCareButton}
              onClick={() => _redirect("CONSUMER-DASHBOARD")}
            >
              Book an appointment
            </Button>
          </Grid>
          <Grid
            item
            className={classes.dWQpurchase}
            style={{
              marginBottom: custommatchesMDX ? "24px" : "24px",
            }}
          >
            <Typography className={classes.homeCareTitle}>
              {disease && disease.purchase}
            </Typography>
            <Button variant="contained" className={classes.homeCareButton}>
              Purchase a Diagnostic Lab Test
            </Button>
          </Grid>
        </Grid>
        {/* <Grid 
            item
            container 
            style={{ paddingBottom: "7.1rem" }}
            direction={custommatchesMDX ? "column" : "row"}
            alignItems={custommatchesMDX ? "center" : undefined}
            justifyContent={custommatchesXL ? "center" : undefined}
          >
            <Grid
              item
              className={classes.dWQhomeCare}
              style={{
                marginRight: custommatchesMDX ? "0px" : "38px",
                marginBottom: custommatchesMDX ? "24px" : "24px",
              }}
            >
              <Typography
                className={classes.homeCareTitle}
                style={{ width: matchesSM ? "250px" : "385px" }}
              >
                {disease.appointment}
              </Typography>
              <Button
                variant="contained"
                className={classes.homeCareButton}
                onClick={() => _redirect("CONSUMER")}
              >
                Book an appointment
              </Button>
            </Grid>
            <Grid item className={classes.dWQpurchase}>
              <Typography className={classes.homeCareTitle}>
                {disease.purchase}
              </Typography>
              <Button variant="contained"
                className={classes.homeCareButton}
                onClick={() => _redirect("DMP")}
              >
                Purchase a Diagnostic Lab Test
              </Button>
            </Grid>
          </Grid> */}
        <Grid
          item
          justifyContent={
            custommatchesXL ? "center" : matchesXS ? "center" : undefined
          }
          style={{
            marginLeft: matchesXS ? "0px" : "92px",
            display: matchesXS ? "flex" : undefined,
            width: matchesXS ? "100%" : undefined,
          }}
        >
          <AppLink />
        </Grid>

        {/* <hr
          style={{
            width: "100%",
            // border: "1px solid #E4E7EC",
            border: "none",
            height: "50px",
            backgroundColor: "rgba(242, 248, 249, 0.5)",
            marginBottom: "30px",
          }}
        /> */}
        {disease.faq && (
          <Grid item container style={{ background: "#FCFCFD" }}>
            <FAQ FAQData={disease.faq} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DiseaseWithQuestionaire;
