import React, { useState, useLayoutEffect, useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import { footerLinks, mobfooterLinks } from "./footerLinks";

import { makeStyles } from "@material-ui/core";
import styles from "./styles";
import "./HeaderFooterStyles.scss";

import doctall_logo_white from "../../../assets/images/doctall_logo_white.svg";
import facebook from "../../../assets/images/facebook.svg";
import instagram from "../../../assets/images/instagram.svg";
import twitter from "../../../assets/images/twitter.svg";
import linkedIn from "../../../assets/images/linkedIn.svg";
import DropdownArrow from "../../../assets/images/DropdownArrow.png";
import AppStoreIcon from "../../../assets/images/AppStoreIcon.svg";
import PlayStoreIcon from "../../../assets/images/PlayStoreIcon.png";
import { _redirect } from "../../Redirect/Redirect";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => styles(theme));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Footer = () => {
  const classes = useStyles();
  const [width] = useWindowSize();
  const [mobileview, setMobileView] = useState(false);

  const [footer, setfooterselection] = useState([]);
  const [update, setupdate] = useState(false);

  useEffect(() => {
    if (width && width < 960) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [width, mobileview]);

  const setfooter = (type) => {
    let arr = footer;
    if (footer.indexOf(type) > -1) {
      arr = footer.filter((e) => e != type);
    } else {
      arr.push(type);
    }
    setfooterselection(arr);
    setupdate(!update);
  };

  return (
    <Grid container className={classes.footerContainer}>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={3}
         >
          <Grid item md={4}>
            <Grid container direction="column" spacing={mobileview ? 2 : 3}>
              <Grid item xs={12}>
                <img src={doctall_logo_white} alt="Logo" />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  fontFamily: "visuelt-regular",
                  fontSize: mobileview ? 16 : 14,
                  lineHeight: "24px",
                  width: mobileview ? "250px" : "384px",
                }}
              >
                Doctall is a full-circle digital healthcare provider offering
                comprehensive primary and secondary medical services accessible
                anywhere, to everyone.
              </Grid>
              <Grid item xs={12}>
                <a
                  style={{
                    fontFamily: "visuelt-regular",
                    fontSize: mobileview ? 16 : 14,
                  }}
                  href="tel:+2349010996000"
                >
                  +234 9010996000

                </a>
                <div>
                  <a
                    style={{
                      fontFamily: "visuelt-regular",
                      fontSize: mobileview ? 16 : 14,
                    }}
                    href="mailto:info@doctall.com"
                  >
                    info@doctall.com
                  </a>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  style={{ maxWidth: "150px" }}
                >
                  <Grid item onClick={()=>_redirect("FB")}>
                  
                    <IconButton classes={{ root: classes.socialIcon }}>
                
                      <img src={facebook} alt="facebook" />
                     
                    </IconButton>
                  
                  </Grid>
                  <Grid item onClick={()=>_redirect("INSTAGRAM")}>
                    <IconButton classes={{ root: classes.socialIcon }}>
                      <img src={instagram} alt="instagram" />
                    </IconButton>
                  </Grid>
                  <Grid item onClick={()=>_redirect("TWITTER")}>
                    <IconButton classes={{ root: classes.socialIcon }}>
                      <img src={twitter} alt="twitter" />
                    </IconButton>
                  </Grid>
                  <Grid item onClick={()=>_redirect("LINKEDIN")}>
                    <IconButton classes={{ root: classes.socialIcon }}>
                      <img src={linkedIn} alt="linkedIn" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  fontFamily: "visuelt-regular",
                  fontSize: mobileview ? 16 : 14,
                  lineHeight: "24px",
                  width: mobileview ? "250px" : "384px",
                }}
              >Block F, Flat 2, LSDPC Luxury Towers,<br />
               18-24 Adeola Odeku Street, Victoria <br />
               Island Lagos.
             </Grid>
        </Grid>
          </Grid>
          
          <Grid item container direction="column" md={8}>
            <Grid item container direction="row">
              {(mobileview ? mobfooterLinks || [] : footerLinks || []).map(
                (f) => {
                  return (
                    <Grid
                      key={f.id}
                      item
                      xs={mobileview ? 6 : undefined}
                      // md={2}
                      style={{
                        padding: mobileview ? "12px" : "0",
                        width: mobileview ? "undefined" : "193px",
                        marginRight: mobileview ? undefined : "4px",
                      }}
                    >
                      {f.subroute && (
                        <Grid
                          container
                          style={{
                            marginBottom: 20,
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            className={classes.title}
                            onClick={() => {
                              setfooter(f.subroute.title);
                            }}
                          >
                            {f.subroute.title}{" "}
                            <img alt="down"
                              className="mob-footer-drop"
                              src={DropdownArrow}
                            />
                          </Grid>
                          {f.subroute.routes && f.subroute.routes.length > 0
                            ? (((mobileview &&
                              footer.indexOf(f.subroute.title) > -1) ||
                              !mobileview) && (
                                <Grid container spacing={2}>
                                  {(f.subroute.routes || []).map((r) => (
                                    <Grid key={r.id} item xs={12}>
                                      <a
                                        target={r.target}
                                        style={{
                                          fontFamily: "visuelt-regular",
                                          fontSize: 16,
                                        }}
                                        href={r.path}
                                      >
                                        {r.label}
                                      </a>
                                    </Grid>
                                  ))}
                                </Grid>
                              )) ||
                            ""
                            : null}
                        </Grid>
                      )}
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          className={classes.title}
                          onClick={() => {
                            setfooter(f?.title);
                          }}
                        >
                          {f.title}{" "}
                          <img
                            className="mob-footer-drop"
                            src={DropdownArrow}
                          />
                        </Grid>
                        {f.routes && f.routes.length > 0
                          ? (((mobileview && footer.indexOf(f.title) > -1) ||
                            !mobileview) && (
                              <Grid container spacing={2}>
                                {(f.routes || []).map((r) => (
                                  <Grid key={r.id} item xs={12}>
                                    <a
                                      target={r.target}
                                      style={{
                                        fontFamily: "visuelt-regular",
                                        fontSize: 16,
                                      }}
                                      href={r.path}
                                    >
                                      {r.label}
                                    </a>


                                  </Grid>
                                ))}
                              </Grid>
                            )) ||
                          ""
                          : null}
                      </Grid>
                    </Grid>
                  );
                }
              )}
            </Grid>
            <Grid item>
              <Grid item xs={12} style={{ paddingTop: "125px" }}>
                <Grid container alignItems="center">
                  <Grid
                  
                    item
                    style={{ marginRight: "50px", marginBottom: "5px" }}
                  >
                    {/* <Helmet>
                      <title>Download the Doctall App to see a doctor online</title>
                      <meta 
                      name="description" 
                      content="See a doctor online; Nigerian, UK, and Indian Doctors. Speak to doctors and specialists across a wide range of specialties. Book an appointment, get downloadable prescriptions and lab test advice, and store your medical records. " />
                    </Helmet> */}
                    <a
                      target="_blank"
                      href="https://apps.apple.com/ng/app/doctall/id1544455716"
                    >
                      <img src={AppStoreIcon} alt="AppStore" />
                    </a>
                  </Grid>
                  <Grid
                    item
                    style={{ marginRight: "50px", marginBottom: "5px" }}
                  >
                    {/* <Helmet>
                      <title>Download the Doctall App to see a doctor online</title>
                      <meta 
                      name="description" 
                      content="See a doctor online; Nigerian, UK, and Indian Doctors. Speak to doctors and specialists across a wide range of specialties. Book an appointment, get downloadable prescriptions and lab test advice, and store your medical records.  " />
                    </Helmet> */}
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.doctall"
                    >
                      <img src={PlayStoreIcon} alt="GooglePlay" />{" "}
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "41px", paddingBottom: "32px" }}>
        <hr />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography
          style={{
            fontFamily: "Inter",
            fontWeight: "normal",
            fontStyle: "normal",
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >{`© Doctall ${new Date().getFullYear()}.  All Rights Reserved`}</Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
