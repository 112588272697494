const questionnaireActions = {

    GET_QUESTIONS: "GET_QUESTIONS",
    GET_QUESTIONS_SUCCESS: "GET_QUESTIONS_SUCCESS",

    SAVE_QUESTIONS_ANSWER: "SAVE_QUESTIONS_ANSWER",
    SAVE_QUESTIONS_ANSWER_SUCCESS: "SAVE_QUESTIONS_ANSWER_SUCCESS",

    getQuestions: (disease_id) => {
        return {
            type: questionnaireActions.GET_QUESTIONS,
            disease_id
        };
    },

    saveAnswers: (payLoad) => {
        return {
            type: questionnaireActions.SAVE_QUESTIONS_ANSWER,
            payLoad
        };
    },
};

export default questionnaireActions;