import homeCareLooper from "../../../assets/images/homeCareLooper.svg";
import purchaseBg from "../../../assets/images/dWQPurchaseBG.svg";
import DoctorAppointment from "../../../assets/images/DoctorAppointment.png";
import bloodTest from "../../../assets/images/bloodTest.svg";
import CallAppointment from "../../../assets/images/Call.png";
// import premiumServices from "../../../assets/images/premiumServices.svg";
import doctallRing from "../../../assets/images/doctallRing.svg";

let premiumServices =
  "https://s3.eu-west-1.amazonaws.com/doctall.storage.com/new-doctall/premiumServices.svg";

const styles = (theme) => ({
  ddcContainer: {
    width: "100%",
  },
  proceedContainer: {
    width: "100%",
    paddingTop: "7.1rem",
    paddingLeft: "7.6rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
    },
  },
  dWQhomeCare: {
    position: "relative",
    display: "flex",
    height: "391px",
    width: "45.5%",
    background: "#004451",
    borderRadius: "10px",
    backgroundImage: `url(${CallAppointment})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    paddingLeft: "5rem",
    paddingTop: "7.45rem",
    paddingRight: "10.6rem",

    [theme.breakpoints.up("xl")]: {
      width: "605px",
    },

    [theme.breakpoints.down("md")]: {
      width: "500px",
      backgroundImage: `url(${homeCareLooper})`,
    },

    [theme.breakpoints.down("sm")]: {
      width: "32.8rem",
      backgroundImage: `url(${homeCareLooper})`,
      // paddingRight: "24px",
      marginLeft: "0px",
      paddingLeft: "24px",
      height: "350px",
    },

    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  homeCareTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#FFFFFF",
    paddingBottom: "8.3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  homeCareButton: {
    ...theme.typography.secondaryButton,
    position: "absolute",
    bottom: "6.6rem",
    fontWeight: "500",
    fontSize: "16px",
    display: "flex",

    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    color: "#FFFFFF",
    // flex: "none",
    // order: "0",
    // flexGrow: "0",
  },
  dWQpurchase: {
    width: "45.5%",

    position: "relative",
    // top: "-12px",
    display: "flex",
    height: "391px",
    background: "#004451",
    borderRadius: "10px",
    backgroundImage: `url(${homeCareLooper}),url(${purchaseBg})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    paddingLeft: "5rem",
    paddingTop: "7.45rem",
    paddingRight: "10.6rem",

    [theme.breakpoints.up("xl")]: {
      width: "605px",
    },

    [theme.breakpoints.down("md")]: {
      width: "500px",
      backgroundImage: `url(${homeCareLooper})`,
    },
    [theme.breakpoints.down("sm")]: {
      width: "32.8rem",
      backgroundImage: `url(${homeCareLooper})`,
      paddingRight: "24px",
      paddingLeft: "24px",
      height: "350px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  symptomsContainer: {
    width: "100%",
    paddingTop: "30px",
    paddingLeft: "100.5px",
    paddingRight: "180.5px",
    paddingBottom: "70px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "40px",
      paddingRight: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    // [theme.breakpoints.down("xs")]: {
    //   padding:"70px",
    //   // width: "300px",
    // },
  },
  sectionHeading: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "38px",
    color: " #002931",
  },
  offersContainer: {
    width: "100%",
    height: "auto",
    background: "rgba(242, 248, 249, 0.5)",
    paddingTop: "30px",
    // paddingBottom: "71px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-100px",
      paddingBottom: "20px",
    },
  },
  sectionSubtitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "32px",
    color: " #002931",
    width: "650px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      textAlign: "center",
    },
  },
  // planIntroContainer: {
  //   background: "#FFFFFF",
  //   borderRadius: "8px",
  //   height: "auto",
  //   maxWidth: "124.7rem",
  //   marginBottom: "32px",
  //   paddingLeft: "6.5rem",
  //   paddingTop: "6rem",
  //   paddingBottom: "6rem",
  //   paddingRight: "6.45rem",
  //   [theme.breakpoints.down("xs")]: {
  //     paddingLeft: "1.6rem",
  //     paddingRight: "1.6rem",
  //   },
  // },
  plainIntroInd: {
    width: "523px",
  },
  introTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#101828",
    paddingBottom: "8px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      padding:"10px",
    },
  },
  introContent: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    // textAlign: "center",
    lineHeight: "24px",
    color: "#667085",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      padding:"12px"
    },
  },
  buttonGroup: {
    minWidth: "129px",
    marginTop: "60px",
    marginBottom: "60px",

    "&:not(:last-child)": {
      borderRadius: "25px",
      marginRight: "64px",
    },
    "&:not(:first-child)": {
      marginLeft: "0px",
      borderRadius: "25px",
    },
  },
  buttonGroupVertical: {
    width: "280px",
    "&:not(:last-child)": {
      borderRadius: "25px",
    },
    "&:not(:first-child)": {
      marginLeft: "0px",
      borderRadius: "25px",
    },
  },

  offerList: {
    width: "72px",
    height: "72px",
    borderRadius: "50px",
    backgroundColor: "#FFFFFF",
    border: "1.69435px solid #6DA5AF",
    boxSizing: "border-box",
    color: "#6DA5AF",

    "&:hover": {
      color: "#002931",
      backgroundColor: "#FFFFFF",
    },

    "&.MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#FFFAF5",
      border: "3px solid #FF8D77",
      color: "#FF8D77",
      width: "104px",
      height: "104px",
    },
  },
  premiumServicesContainer: {
    marginLeft: "48.5px",
    maxWidth: "1150px",
    height: "386px",
    background: "rgba(242, 248, 249, 0.5)",
    border: "1px solid rgba(174, 215, 223, 0.2)",
    boxSizing: "border-box",
    borderRadius: "4px",
    backgroundImage: `url(${premiumServices}),url(${doctallRing})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      marginLeft: "0px",
    },
  },
  callbackButton: {
    ...theme.typography.secondaryButton,
    width: "181px",
  },
  TreatmentPlanIfNotSure: {
    padding: "50px 200px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px",
    },
  },
  NotSureTPHeading: {
    color: " #002931",
    fontSize: "30px",
    lineHeight: "38px",
  },
  SpeakwithSubHeading: {
    fontSize: "18px",
    color: "#002931",
  },
  SpeakwithCbtn: {
    background: "#FF5C3C",
    color: "#fff",
    fontSize: "16px",
    padding: "12px 20px",
    border: "1px solid #FF5C3C",
    borderRadius: "8px",
    fontFamily: "Visuelt Pro",
  },
  appointContainer: {
    paddingLeft: "92px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "60px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
  },
  tPlanBG: {
    backgroundColor: "#FFF0ED",
    height: "662px",
  },
  tPlanContent: {
    paddingLeft: "67px ",
    paddingRight: "64px",
    height: "662px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  tPlanDesc: {
    backgroundColor: "#F2F8F9",
    border: "1px solid #D9EDF1",
    borderRadius: "4px",
    width: "580px",
    height: "113px",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "28px",
    fontStyle: "normal",
    color: "#002931",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
    paddingRight: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "460px",
      textAlign: "center",
      height: "auto",
      padding: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "250px",
      textAlign: "center",
      height: "auto",
      padding: "20px",
    },
  },
  tPlanOptionGroup: {
    "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type)":
      {
        borderRadius: "8px",
      },
    "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type)":
      {
        borderTop: "1px solid #C9DBDF",
        borderRadius: "8px",
      },
    "&.MuiToggleButtonGroup-root .Mui-selected .MuiToggleButtonGroup-grouped:not(:last-of-type)":
      {
        borderRadius: "8px",
      },
    "&.MuiToggleButtonGroup-root .Mui-selected .MuiToggleButtonGroup-grouped:not(:first-of-type)":
      {
        borderTop: "1px solid #C9DBDF",
        borderRadius: "8px",
      },
  },
  tPlanOption: {
    "&.MuiToggleButton-root": {
      border: "1px solid #C9DBDF",
      height: "68px",
      width: "449px",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "28px",
      marginBottom: "10px",
      borderRadius: "8px",
      textTransform: "none",
      fontFamily: "Visuelt Pro",
      color: "#002931",
      [theme.breakpoints.down("sm")]: {
        width: "300px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "250px",
      },
    },
    "&.MuiToggleButton-root.Mui-selected": {
      border: "1px solid #982B16 !important",
      filter: "drop-shadow(0px 2px 4px rgba(11, 59, 60, 0.05))",
      background: "transparent",
    },
  },
  tPlanBG_maybe: {
    backgroundColor: "#FFF0ED",
    height: "1037px",
    [theme.breakpoints.down("sm")]: {
      height: "700px",
    },
  },
  tPlanBG_no: {
    backgroundColor: "#FFF0ED",
    height: "768px",
    [theme.breakpoints.down("sm")]: {
      height: "700px",
    },
  },
  tPlanContent_maybe: {
    paddingLeft: "62px ",
    paddingRight: "45px",
    height: "1037px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "00px",
    },
  },
  screening: {
    width: "604px",
    height: "617px",
    border: "1px solid #E4E7EC",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
    borderRadius: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
  },
  screeningListCont: {
    background: "#F2F8F9",
    height: "404px",
    padding: "24px 24px 36px",
    width: "100%",
  },
  screeningList: {
    marginLeft: "9px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#002931",
    fontFamily: "Visuelt Pro",
  },
  buyButton: {
    ...theme.typography.secondaryButton,
    height: "48px",
    width: "540px",
    color: "#FFFFFF",
    border: "1px solid #FF5C3C",
    borderRadius: "8px",
    fontFamily: "Inter",
    cursor:"pointer"
  },
  formContainer: {
    border: "1px solid #C9DBDF",
    borderRadius: "8px",
    width: "525px !important",
    height: "352px",
    marginTop: "46px",
    padding: "24px",
    "&MuiInputBase-root-MuiInput-root": {
      marginTop: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "460px !important",
      height: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px !important",
      height: "400px",
    },
  },
  inputLabel: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    // paddingBottom: "6px",
    fontFamily: "Visuelt Pro",
    marginBottom: "6px",
    /* identical to box height, or 143% */

    /* Gray/700 */

    color: "#344054",
  },
  inputField: {
    background: "#FFFFFF",
    /* Gray/300 */
    width: "100%",
    height: "44px",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "10px 14px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "0px",
    "&.MuiInput-root": {
      marginTop: "0px",
    },
    "&:focus": {
      border: "1px solid #D0D5DD",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  subscribeButton: {
    ...theme.typography.secondaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "10px 0px",
    marginTop: "32px",
    borderRadius: "8px",

    border: "1px solid #FF5C3C",

    // backgroundColor:"#C53B20",
  },
  joinPopup: {
    "& .MuiDialog-paper": {
      width: "616px !important",
      height: "636px",
      borderRadius: "0px",
      position: "relative",
      overflowY: "visible !important",
      paddingTop: "96px",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "0px",
    },
  },
  doneButton: {
    ...theme.typography.secondaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "10px 0px",
    marginTop: "84px",
    borderRadius: "8px",
    width: "420px",
    border: "1px solid #FF5C3C",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  beneCont: {
    paddingLeft: "80px",
    height: "auto",
    "& .MuiGrid-root": {
      marginTop: "85px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
    },
  },
  beneTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "38px",
    fontFamily: "Visuelt Pro",
  },
  tPlanTableCon: {
    height: "1052px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 5px rgba(11, 59, 60, 0.14)",
    marginTop: "60px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },

    "&.MuiGrid-root": {
      marginTop: "60px",
      width: "95%",
      marginLeft: "40px",
      marginRight: "40px",
      paddingLeft: "32px",
      paddingRight: "32px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "20px",
      },
    },
  },
  theading: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    fontFamily: "Visuelt Pro",
  },
  buyTButton: {
    ...theme.typography.secondaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "10px 16px",
    borderRadius: "8px",
    width: "227.5px",
    height: "48px",
    marginTop: "24px",
    border: "1px solid #FF5C3C",
    cursor: "pointer"
    /*  [theme.breakpoints.down("xs")]: {
        width: "300px",
      }, */
  },
  biomarkerList: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "Visuelt Pro",
    marginBottom: "16px",
    marginLeft: "9px",
  },
  customerCareContent: {
    marginLeft: "32px",
    marginRight: "76px",
    width: "673px",
    [theme.breakpoints.down("xs")]: {
      width: "270px",
      marginLeft: "16px",
      marginRight: "16px",
    },
  },
  customerCareButton: {
    ...theme.typography.secondaryButton,
    height: "48px",
  },
  customerCareTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "38px",
  },
  customerCareSubtitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "32px",
  },
});

export default styles;
