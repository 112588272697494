import React from "react";
import {
    Grid,
    Typography,
    useMediaQuery,

} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import Footer from "../../components/Layout/Footer/Footer";
import Header from "../../components/Layout/Header/Header";
import { getCurrentDate } from "../../components/common/utils";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => styles(theme));

const CancelAndRefund = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
        
            <Header />
           
            <Grid container>
            <Helmet>
            <title>Cancel and Refund Policy - Doctall</title>
            <meta 
            name='description' 
            content=' Make convenient health purchases on Doctall. Our cancel and refund policy gallows you cancel and request a refund. Refunds will be processed within 3-10 working days once the requirements for a refund are met. '
            />
        </Helmet>
                <Grid item container direction="column" className={classes.LegalHeader} align="center" >
                    <Typography className={classes.legalBannerHeading}>{`Current as of ${getCurrentDate()}`}</Typography>
                    <Typography className={classes.legalHeading}>Cancellation And Refund</Typography>

                </Grid>

                <Grid item container direction="column" className={classes.LegalTextContainer} >

                    <Typography className={classes.legalParaHeading}>Home sample collection cancellation</Typography>

                    <Typography className={classes.legalPara} style={{ marginLeft: "25px" }}>

                        <ol>
                            <li>
                            You must cancel your scheduled Home Sample Collection at least 24 hours before the scheduled time. If you fail to do so, a cancellation fee for the Home Sample Collection may be applied.<br />
                            </li>
                            <li>
                                To cancel your order, please contact our customer care team on Phone Number and Email<br />
                                a.  Email: info@doctall.com<br />
                                b.  Phone number: +234 9010996000<br />
                            </li>
                            <li>
                                Refunds will be processed within 3-10 working days once the requirements for a refund are met. However, the client will bear all bank/transaction charges.
                                We will refund you to the payment method used by you to pay.<br />
                            </li>
                            <li>
                                This cancellation right does not apply if:<br />
                                a: The sample has been taken at home or partner laboratory as agreed upon
                            </li>
                        </ol>
                    </Typography>

                    <Typography className={classes.legalParaHeading}>In-person lab sample collection cancellation</Typography>
                    <Typography className={classes.legalPara} style={{ marginLeft: "25px" }}>
                        <ol>
                            <li>
                                You are required to cancel your scheduled In-Person sample collection 24 hours before the scheduled visit to our partner Laboratories <br />
                            </li>
                            <li>
                                To cancel your order, please contact our customer care team on Phone Number and Email<br />
                                a.  Email: info@doctall.com<br />
                                b.  Phone number: +234 9010996000<br />
                            </li>
                            <li>
                                Refunds will be processed within 3-10 working days once the requirements for a refund are met. However, the client will bear all bank/transaction charges.
                                We will refund you to the payment method used by you to pay.<br />
                            </li>
                            <li>
                                This cancellation right does not apply if:<br />
                                a: The sample has been taken at home or partner laboratory as agreed upon
                            </li>
                        </ol>
                    </Typography>

                    <Typography className={classes.legalParaHeading}>Refund eligibility</Typography>
                    <Typography className={classes.legalPara} >
                        As a doctall customer,  you will be eligible for a refund if<br /><br />
                        <ul style={{ marginLeft: "25px" }}>
                            <li >
                                You make double payment/overpayment for a diagnostic product.<br /></li>
                            <li>
                                You cancel a Home Sample Collection or In-Person Sample Collection 24 hours before the scheduled appointment time <br />
                            </li>
                            <li>
                                Partner officials fail to show up for a scheduled Home Sample Collection without prior notice of absenteeism or request to reschedule an appointment.
                            </li>
                            <li>
                                Diagnostic Product purchased is not available at Partner Diagnostic Lab <br />
                            </li>
                            <li>
                                Equipment Downtime at Partner Diagnostic Lab <br />
                            </li>
                            <li>
                                Diagnostic lab partner fails to meet the requirement for conducting sample collection and lab test <br /><br />
                            </li>
                        </ul>
                        You  will not be eligible for a refund if:<br /><br />

                        <ul style={{ marginLeft: "25px" }}>
                            <li>
                                You fail to show up for sample collection appointment at home or partner laboratory as agreed upon<br />
                            </li>
                            <li>
                                You fail to cancel your scheduled Home Sample Collection appointment 24 hours prior to the appointment time and decline to have the appointment rescheduled.  <br />
                            </li>
                            <li>
                                Sample Collection has been completed at home or partner laboratory as agreed upon<br />
                            </li>
                            <li>
                                Failed payment for product and services as a result of error from users financial partner<br />
                            </li>
                        </ul>
                    </Typography>
                    <Typography className={classes.legalParaHeading}>Refund claim</Typography>
                    <Typography className={classes.legalPara}>
                        To request a refund<br /><br />
                        <ol style={{ marginLeft: "25px" }}>
                            <li>
                            Contact our Customer Support team by email or phone number <br />
                        . Email: info@doctall.com<br />
                        . Phone number: +234 9010996000<br />
                            </li>
                            <li>
                        Provide details for your reason for Refund Request and provide the following information: <br />
                        . Valid Means of Identification <br />
                        . Users Doctall account details <br />
                        <div style={{ marginLeft: "25px" }}>
                           . Email, <br />
                           . Phone Number, <br />
                           . Name on Doctall Account<br />
                           </div>
                        . Customer Receipt <br />
                        . Bank Account details matching users identification
                            </li>
                        </ol>
                       
                        
                    </Typography>

                    <Typography className={classes.legalParaHeading}>Policy to withhold all card holder information</Typography>
                    <Typography className={classes.legalPara}>
                        <span style={{ fontWeight: "600" }}>Information that we collect from checkout users </span> <br /><br />
                        When you checkout with Paystack on a merchant’s website, we collect and store your card information, your email address, your mobile phone number, and your billing and shipping address. To ensure your card information is kept safe and secure on our servers, we implement access control measures (physical and virtual), security protocols, policies and standards including the use of encryption and firewall technologies in compliance with the PCI DSS Requirements and we implement periodical security updates to ensure that our security infrastructures are in compliance with reasonable industry standards.<br /><br />
                        We may share your contact information with merchants as part of your purchase details for record purposes. We will not share this information with other third parties except as a necessary part of providing our website and services. We do not share your card information with merchants. Please review your merchant’s privacy policy to understand the privacy policies guiding the merchant you transact with.

                    </Typography>

                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

export default CancelAndRefund;
