import React from 'react';
import Applayout from '../Layout/layout/Applayout';
import {
    Grid,
    Button,
    Typography,
    useMediaQuery,
    Input,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import { styles } from './styles'
// import commingSoonDoctor from '../../assets/images/cs_doctor.svg'
import diseaseLooper1 from "../../assets/images/diseaseLooper1.svg";
import { subscribeDoctallPharmacy } from '../../services/subscriptions/subscriptionServices';
import { showNotification } from '../common-notification/CommonNotification';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => styles(theme));


export default function ComingSoon({ head, description }) {
    const classes = useStyles();
    const theme = useTheme();

    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const { userEmail } = event.target.elements
            let payLoad = {
                'email': userEmail.value,
            }
            let response = await subscribeDoctallPharmacy(payLoad)
            if (response && response.status === 200 || response.status === 201) {
                showNotification('success', "Success !!", "You have suceesfully subscribed to Newsletter")
                event.target.reset();
            }
        } catch (error) {
            if (error && error.response) {
                showNotification('error', "Failed !!", "" + error.response.data)
            }
        }

    }


    return (
        <Applayout>
            <div style={{
                backgroundImage: matchesMD ? "none" : `url(${diseaseLooper1})`,
                backgroundPosition: "106% 2%",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "100%",
                backgroundColor: "#F2F8F9",
            }}>
                <Grid container  className={classes.CSContainer}>

                    <Grid item md={7} xs={12} >
                        <Typography className={classes.comingSoonTitle}>
                            COMING SOON
                        </Typography>
                        <Typography className={classes.comingSoonSubTitle}>
                            {head}
                        </Typography>
                        <Typography className={classes.comingSoonDesc}>
                            {description}
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '3%' }}>

                                <Input
                                    type="email"
                                    required
                                    name='userEmail'
                                    placeholder="Enter your email"
                                    className={classes.inputField}
                                    disableUnderline={true}
                                />

                                <Button variant="contained" type='submit' className={classes.comingSoonButton}>
                                    Get started
                                </Button>

                            </div>
                        </form>

                        <Typography className={classes.comingSoonSmallText}>
                            We care about your data in our  <Link to="/privacy-policy"> <span style={{ color: '#848484', cursor: 'pointer', textDecoration: 'none' }}> privacy policy</span></Link>
                        </Typography>
                    </Grid>
                    <Grid className={classes.comingSoonImage} item md={5} xs={12}>
                        <img style={{ width: '100%' }} src={"https://s3.eu-west-1.amazonaws.com/doctall.storage.com/new-doctall/cs_doctor.svg"} />
                    </Grid>

                </Grid>
            </div>
        </Applayout>
    )
}
