import React from "react";
import RegistrationHeader from "../RegistrationPages/header"
import styles from "../RegistrationPages/Styles";
import { Link } from "react-router-dom";
import featuredicon from "../../../assets/images/featuredicon.png";
import {
    Grid, Input, makeStyles, useTheme, Button, Typography, Select,
    MenuItem, useMediaQuery,Paper, Avatar
} from "@material-ui/core";
import "./influencer.scss";
const useStyles = makeStyles((theme) => styles(theme));

export default function RegistrationSucess () {
    const classes = useStyles();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const custommatchesMDX = useMediaQuery("(max-width:1380px)");
    const custommatchesXL = useMediaQuery("(min-width:1500px)");

    return(
        <>
        <RegistrationHeader />
        <Grid container className={classes.headercontainer}
        >
            <Paper className={classes.Sucesspaper}  elevation={0}>
            <Grid item align="center"
            marginTop= "10px"
            direction="row"
            justifyContent="center"
            alignItems="center">
                <Avatar>
                <img src={featuredicon} alt="sucess_icon" />
                </Avatar>
                <Typography className={classes.headersucess}>
                    Your registration is successful 
                    </Typography>
                <Typography  className={classes.subsucess}>
                    We have sent you an email to confirm your registration
                    </Typography>
            </Grid>
            </Paper>
        </Grid>
        </>
    )
}
