import { usePaystackPayment } from 'react-paystack';


const PaystackHook = (props) => {
    let payStackKey = process.env.REACT_APP_PAYSTACK_KEY

    const config = {
        reference: (new Date()).getTime(),
        email: props.email ? props.email : "",
        amount: props.amt ? props.amt * 100 : 0,
        publicKey: payStackKey,
    };

    return usePaystackPayment(config);
};

export default PaystackHook;