import hDLooper from "../../assets/images/hDLooper.svg";
import FDLooper from "../../assets/images/FDLooper.svg";

const Styles = (theme) => ({
  faqMainArea: {
    paddingTop: "2.6rem",
    paddingLeft: "11.2rem",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
      paddingLeft: "16px",
    },
  },

  faqSubHeadingContainer: {
    width: "44.8rem",
    marginRight: "64px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "16px",
    },
  },
  faqSubHeading: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineheight: "44px",
    letterSpacing: "-0.02em",
    color: "#101828",
    margin: "12px 0px",
  },

  faqsubtitle2: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "19px",
    lineHeight: "28px",
    color: "#667085",
    margin: "20px 0px",
  },
  accordian: {
    border: "none",
    borderRadius: "none",
    boxShadow: "none",
    paddingBottom: "32px",
    background: "#FCFCFD",
  },
  accordianSummary: {
    padding: "0px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "16px",
    },
  },
  faqQuestion: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#101828",
  },
  accordianAnswer: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#667085",
  },
  accordianDetails: {
    padding: "0px",
    paddingRight: "26px",
  },
  faqList: {
    marginRight: "11.4rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "4.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
  },
  progressBar: {
    background: "rgba(0, 68, 81, 0.1)",
    borderRadius: "80px",
    height: "8px",
    width: "576px",
    [theme.breakpoints.down("sm")]: {
      width: "375px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
  },
  progressColor: {
    background: "#004451",
  },
  popover: {
    "& .MuiPaper-root": {
      width: "334px",
      height: "508px",
      background: "#F2F8F9",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16)",
      borderRadius: "12px",
      right: "0px",
      left: "unset",
      bottom: "0px",
      backgroundPosition: "bottom",
      backgroundRepeat: "no-repeat",
      backgroundImage: `URL(${hDLooper})`,
      [theme.breakpoints.down("sm")]: {
        right: "20px",
      },
    },
  },
  popover2: {
    "& .MuiPaper-root": {
      width: "334px",
      height: "461px",
      background: "#F2F8F9",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16)",
      borderRadius: "12px",
      right: "1px",
      left: "unset",
      bottom: "0px",
      backgroundPosition: "bottom",
      backgroundRepeat: "no-repeat",
      backgroundImage: `URL(${FDLooper})`,
      [theme.breakpoints.down("sm")]: {
        right: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        right:"0px",
        width:"200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft:"0px",
      },
    },
  },
  findMoreButton: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    //padding: "12px 11px",
    fontFamily: "Inter",
    width: "155px",
    height: "48px",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    background: "#00839B",
    border: "1px solid #66B5C3",
    /* Shadow/xs */

    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
  },
  couponButton: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    //padding: "12px 11px",
    fontFamily: "Inter",
    width: "183px",
    height: "57px",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    background: "#FF5C3C",
    border: "1px solid #FF5C3C",
    /* Shadow/xs */

    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
  },


});

export default Styles;
