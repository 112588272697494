import {
    Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, Grid, IconButton, makeStyles, Typography, useMediaQuery, useTheme
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import chevronDownPlan from "../../assets/images/chevronDownPlan.svg";
import chevronDownQ from "../../assets/images/chevronDownQ.svg";
import chevronUp from "../../assets/images/chevronUp.svg";
import Info from "../../assets/images/infoIcon.png";
import styles from "../../pages/diseases/DIseaseWithQues/styles";
import { showNotification } from "../common-notification/CommonNotification";
import DrugPopup from "./../../components/popup/MultiDrugDialog";
import BrandInfo from "./BrandInfo";
import MultiBrandInfo from "./MultiBrandInfo";
import TabletsView from "./TabletsView";

const useStyles = makeStyles((theme) => styles(theme));
const ExpandMoreIcon = () => <img src={chevronDownPlan} alt="expand" />;

export default function FullPackageCard(props) {
  const { planInfo } = props;
  let navigate = useNavigate();
  const { selectedDisease } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const [tablet, setTablet] = React.useState("");
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [selected, setSelected] = useState(0);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [uniqueDrug, setUniqueDrugs] = useState(null);
  const [finalPrice, setFinalPrice] = useState(0);
  const [priceToShow, setPriceToShow] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [drugModel, setDrugModel] = useState(false);

  const [dosageTitle, setDosageTitle] = useState("Dosages");

  useEffect(() => {
    let data = [];
    let combinedPrice = 0;
    planInfo.treatment &&
      planInfo.treatment.length > 0 &&
      planInfo.treatment.map((item, index) => {
        data.push(item.drug_name);
        // data.push(planInfo.plan_name);
        combinedPrice = Number(item.price + combinedPrice);
      });

    let _unique = [...new Set(data)];
    setUniqueDrugs(_unique);
    setPriceToShow(planInfo.price);
    setQuantity(1);
  }, [planInfo]);

  useEffect(() => {
    let _price = Number(quantity * priceToShow);
    setPrice(_price);
    setFinalPrice(Number(quantity * priceToShow));
  }, [quantity]);

  const decreseQuantity = () => {
    if (quantity > 1) {
      let _quantity = Number(quantity - 1);
      setQuantity(_quantity);
    } else {
      showNotification("error", "Failed !", "Quantity can not be less than 1");
    }
  };

  const increaseQuantity = () => {
    let allowedQuantity = 0;
    let message = "";
    if (selected !== false) {
      if (planInfo.diseases_name === "Hairloss") {
        allowedQuantity = 3;
        message = `Duration (Months) can not be more than 3`;
      } else {
        allowedQuantity = 10;
        message = `Quantity can not be more than 10`;
      }
      if (quantity >= allowedQuantity) {
        showNotification("error", "Failed !!", " " + message);
      } else {
        let _quantity = Number(quantity + 1);
        setQuantity(_quantity);
      }
    }
  };

  const handleDosageSelection = (event, itemIndex, selectedItem) => {
    let title = planInfo.treatment[itemIndex].qty === "1" ? "Size" : "Dosages";
    setDosageTitle(title);

    if (selected === itemIndex) {
      return false;
    } else {
      setSelected(itemIndex);
      setSelectedTreatment(planInfo.treatment[itemIndex]);
    }
  };

  const onBuyTreatment = () => {
    let data = {
      finalPrice: finalPrice,
      brand_name: "Full Package",
      _id: planInfo._id,
      tpl_uuid: planInfo.uuid,
      userSelectedQuantity: quantity,
      price: priceToShow,
    };

    navigate(`/${selectedDisease}/questionnaire`, {
      state: {
        planDetails: planInfo,
        diseaseName: planInfo.diseases_name,
        selectedPlan: data,
      },
    });
  };

  return (
    <>
      <Card
        classes={{ root: classes.dWQtreatmentCard }}
        style={{
          width:
            selectedDisease === "Premature Ejaculation"
              ? matchesSM
                ? "390px"
                : "814px"
              : null,
        }}
      >
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            background: "#004451",
            height: "auto",
            padding: "21px 24px",
          }}
        >
          <Grid item style={{ maxWidth: "80%" }}>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "24px",
                lineHeight: "32px",
                color: "#FFFFFF",
              }}
            >
              Full Package
            </Typography>

            {/* <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#87BBc1",
              }}
             >
              - Combination of &nbsp;
              {uniqueDrug &&
                uniqueDrug.length > 0 &&
                uniqueDrug.map((drug, index) => (
                  <p style={{ display: "inline" }}>
                    {drug}
                    {index !== uniqueDrug.length - 1 ? " and  " : " "}
                  </p>
                ))}
              -
            </Typography> */}
          </Grid>
          {/* <Grid
            item
            style={{ cursor: "pointer" }}
            onClick={() => setDrugModel(!drugModel)}
          >
            {" "}
            <img src={Info} alt="ellipse" />
          </Grid> */}
        </Grid>

        <CardContent style={{ padding: "0px" }}>
          <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            className={classes.PlanInfoAccordion}
            style={{ marginBottom: "32px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="account"
              id="account"
              style={{ padding: "0px", margin: "0px", minHeight: "auto" }}
            >
              <Grid container justifyContent="space-between">
                <Typography className={classes.InfoHeading}>
                  {expanded ? "Hide package details" : "View Package details"}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item container className={classes.dWQplaInfoDetails}>
                <Grid container>
                  <FormControl
                    style={{
                      display: "flex",
                      width: "100%",
                      marginBottom: "25px",
                      marginTop: "8px",
                      flexDirection: "row",
                    }}
                  >
                    {uniqueDrug &&
                      uniqueDrug.length > 0 &&
                      uniqueDrug.map((drug, index) => (
                        
                        <Grid item xs key={`${drug}${index}`}>
                          {console.log(drug,"drug")}
                          <FormControlLabel
                            style={
                              selected === index
                                ? {
                                    borderColor: "#004451",
                                    backgroundColor: "#D9EDF1",
                                    height: "60px ",
                                  }
                                : {
                                    height: "60px ",
                                  }
                            }
                            classes={{
                              root: classes.optionSelect,
                              label: classes.optionSelectLabel,
                            }}
                            value={drug}
                            checked={selected === index ? true : false}
                            // Changes
                            onChange={(event) => {
                              handleDosageSelection(event, index, tablet);
                            }}
                            control={<Checkbox style={{ display: "none" }} />}
                            label={`${drug}`}
                          />
                        </Grid>
                      ))}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  container
                  direction={matchesXS ? "column" : "row"}
                  justifyContent="space-between"
                  style={{ marginBottom: "8px" }}
                >
                  {uniqueDrug && uniqueDrug.length > 1 ? (
                    <MultiBrandInfo
                      brandInfo={
                        selectedTreatment && selectedTreatment !== null
                          ? selectedTreatment
                          : planInfo.treatment[0]
                      }
                      planInfo={planInfo}
                    />
                  ) : (
                    <BrandInfo planInfo={planInfo} />
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Grid item classes={{ root: classes.dWQplanInfoCardContent }}>
            {/* TabletsView to show tablets and Doses */}

            <TabletsView
              isFullPackage={true}
              type={
                planInfo &&
                planInfo.treatment.length > 0 &&
                planInfo.treatment[0].qty == "1"
                  ? "QTY"
                  : "DOSAGE"
              }
              title={dosageTitle}
              _data={
                selectedTreatment && selectedTreatment !== null
                  ? selectedTreatment
                  : planInfo.treatment[0]
              }
            />

            <Grid
              item
              container
              direction={matchesXS ? "column" : "row"}
              style={{
                marginBottom: "20px",
                width:
                  selectedDisease === "Premature Ejaculation"
                    ? matchesSM
                      ? "330px"
                      : "758px"
                    : null,
                    
              }}
            >
              <Grid
                item
                container
                direction="column"
                style={{
                  width: "135px",
                  marginRight: matchesXS ? "0px" : "20px",
                  marginBottom: matchesXS ? "20px" : "0px",
                }}
              >
                <Typography
                  className={classes.planInfoFormTitle}
                  style={{ paddingBottom: "8px" }}
                >
                  {planInfo.diseases_name === "Hairloss"
                    ? "Duration (Months)"
                    : "Quantity"}
                </Typography>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-evenly"
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #C6E2E7",
                    boxSizing: "border-box",
                    height: "70px",
                    width: "135px",
                  }}
                >
                  <Typography className={classes.planInfoFormTitle}>
                    {quantity}
                  </Typography>
                  <Grid
                    item
                    container
                    direction="column"
                    style={{ width: "9px" }}
                  >
                    <IconButton
                      disableTouchRipple
                      className={classes.iconButton}
                      onClick={increaseQuantity}
                    >
                      <img src={chevronUp} alt="down" />
                    </IconButton>
                    <IconButton
                      disableTouchRipple
                      className={classes.iconButton}
                      onClick={decreseQuantity}
                    >
                      <img src={chevronDownQ} alt="down" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ width: matchesSM ? "150px" : "312px" }}
                xs={8}
                lg={8}
              >
                <Typography
                  className={classes.planInfoFormTitle}
                  style={{ paddingBottom: "8px" }}
                >
                  <span style={{ fontWeight: "700" }}>Price (₦) *</span>{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "#00839B",
                    }}
                  >
                    Starts from
                  </span>
                </Typography>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-evenly"
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #C6E2E7",
                    boxSizing: "border-box",
                    height: "70px",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontFamily: "Visuelt Pro",
                  }}
                >
                  {price}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.dWQtreatmentButton}
                onClick={onBuyTreatment}
                style={{
                  width:
                    selectedDisease === "Premature Ejaculation"
                      ? matchesSM
                        ? "330px"
                        : "758px"
                      : null,
                }}
              >
                Buy Treatment
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <DrugPopup
        closeDialog={() => setDrugModel(!drugModel)}
        drugInfo={planInfo.treatment}
        openDialog={drugModel}
      />
    </>
  );
}
