import axios from "axios";


export const subscribeToNews = async (payLoad) => {
    let response = await axios.post(`/bulknotification/subscribe`, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to create record");
}
export const subscribeDoctallPharmacy = async (payLoad) => {
    let response = await axios.post(`/bulknotification/subscribe`, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to create record");
}



export const getInTouch = async (payLoad) => {
    let response = await axios.post(`/doctallnew/contactus`, payLoad)
    if (response && response.status === 200 || response.status === 201) {
        return response;
    }
    throw new Error("Failed to create record");
}