import * as React from "react";
import { useTheme } from "@material-ui/core";
import { Grid, Typography, Button, useMediaQuery } from "@material-ui/core";
import styles from "./../../pages/diseases/styles";
import { Dialog, makeStyles } from "@material-ui/core";
import close from "../../assets/images/close.png";
import John from "../../assets/images/John.png";
import {
  _redirect,
  _redirectToDoctorProfile,
} from "../../components/Redirect/Redirect";

const useStyles = makeStyles((theme) => styles(theme));

export default function DoctorPopup(props) {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const { doctorInfo } = props;
  const { profile } = doctorInfo ? doctorInfo : {};
  const classes = useStyles();

  return (
    <>
      <div
        className="ContainerWrapper"
        style={{
          width: matchesXS ? "320px" : undefined,
          overflowX: matchesXS ? "hidden" : undefined,
        }}
      >
        <Grid container>
          <Grid
            sm={11}
            md={12}
            xs={11}
            align="center"
            style={{
              paddingTop: "20px",
              position: "relative",
            }}
          >
            <img
              style={{
                height: "158px",
                width: "158px",
                borderRadius: "50%",
                textAlign: "center",
                //paddingTop: "20px",
                //paddingBottom: "16px",
              }}
              src={doctorInfo && doctorInfo.profile_pic}
              alt="doctor"
            />
            <Typography>
              Dr. {""} {doctorInfo && doctorInfo.full_name}
            </Typography>
            <Typography>{doctorInfo && profile.education}</Typography>
            <Typography>
              {doctorInfo && profile.speciality.toString()}
            </Typography>
          </Grid>
          <Grid sm={1} md={1} xs={1} align="center">
            <img
              src={close}
              alt="Close"
              className={classes.Closepopup}
              onClick={() => props.onClose()}
              crossorigin="anonymous"
              style={{
                cursor: "pointer",
                //  marginLeft: matchesXS ? "-10px" : undefined,
              }}
            />
          </Grid>
        </Grid>
        <hr
          style={{
            width: matchesXS ? "300px" : "532px",
            border: "1px solid #E4E7EC",
            margin: "auto",
          }}
        />

        <Grid container>
          <Grid sm={12} md={12} xs={12} align="left">
            <Grid
              style={{
                height: "auto",
                // padding: " 20px",
                margin: "10px 30px 50px 30px",
              }}
            >
              <Typography>{profile.bio}</Typography>
              <Typography style={{ textAlign: "center", marginTop: "5%" }}>
                <iframe
                  width="450"
                  height="240"
                  src={profile.profileVideoUrl && profile.profileVideoUrl[0]}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Typography>

              <Button
                variant="contained"
                className={classes.bookDoctor1}
                onClick={() => _redirect("CONSUMER-FADN")}
                disabled={
                  !(
                    doctorInfo &&
                    doctorInfo.slots &&
                    doctorInfo.slots.length > 0
                  )
                }
              >
                Book Doctor
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
