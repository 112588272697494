import {
    Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, Grid, IconButton, makeStyles, Typography, useMediaQuery, useTheme
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import chevronDownPlan from "../../assets/images/chevronDownPlan.svg";
import chevronDownQ from "../../assets/images/chevronDownQ.svg";
import chevronUp from "../../assets/images/chevronUp.svg";
import Info from "../../assets/images/infoIcon.png";
import styles from "../../pages/diseases/DIseaseWithQues/styles";
import { showNotification } from "../common-notification/CommonNotification";
import DrugPopup from "./../../components/popup/DrugPopup";
import BrandInfo from "./BrandInfo";
import FullPackageCard from "./FullPackageCard";
import MultiBrandInfo from "./MultiBrandInfo";

const useStyles = makeStyles((theme) => styles(theme));
const ExpandMoreIcon = () => <img src={chevronDownPlan} alt="expand" />;

export default function TreatmentPlanCard(props) {
  const { planInfo } = props;
  console.log(planInfo, "planInfo")
  let navigate = useNavigate();
  const { selectedDisease } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const [tablet, setTablet] = React.useState("");
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [selected, setSelected] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [uniqueDrug, setUniqueDrugs] = useState(null);
  const [uniqueBrands, setUniqueBrands] = useState(null);

  const [uniqueDrugsData, setUniqueDrugsData] = useState([]);

  const [finalPrice, setFinalPrice] = useState(0);
  const [priceToShow, setPriceToShow] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const [drugModel, setDrugModel] = useState(false);

  useEffect(() => {
    let data = [];
    let _uniqDrugData = [];
    let _uniqBrandData = [];
    planInfo.treatment &&
      planInfo.treatment.length > 0 &&
      planInfo.treatment.map((item, index) => {
        data.push(planInfo.plan_name);
        // data.push(item.drug_name);
        _uniqBrandData.push(item.brand_name);
        _uniqDrugData.push(item);
        console.log(item,"item")
      });
      

    let _unique = [...new Set(data)];
    let _uniqueValues = [...new Set(_uniqDrugData)];
    console.log(_uniqueValues,"_uniqueValues")
    setUniqueDrugsData(_uniqueValues);
    setUniqueDrugs(_unique);
    setUniqueBrands([...new Set(_uniqBrandData)]);

    if (
      planInfo &&
      planInfo.treatment.length > 0 &&
      planInfo.treatment[0].qty === "1"
    ) {
      setQuantity(1);
      setPrice(planInfo.treatment[0].price);
      setPriceToShow(planInfo.treatment[0].price);
      setSelected(planInfo.treatment[0]);
      setSelectedTreatment(planInfo.treatment[0]);
    }

    if (
      planInfo &&
      planInfo.treatment.length > 0 &&
      planInfo.treatment[0].qty === ""
    ) {
      setQuantity(1);
      setPrice(planInfo.treatment[0].price);
      setPriceToShow(planInfo.treatment[0].price);
      setSelected(0);
      setSelectedTreatment(planInfo.treatment[0]);
    }
  }, [planInfo]);

  useEffect(() => {
    let _price = Number(quantity * priceToShow);
    setPrice(_price);
    setFinalPrice(Number(quantity * priceToShow));
  }, [quantity]);

  const decreseQuantity = () => {
    if (quantity === 1) {
      return false;
    }
    if (quantity > 0) {
      let _quantity = Number(quantity - 1);
      setQuantity(_quantity);
    } else {
      setSelected(false);
      setSelectedTreatment(null);
    }
  };

  const increaseQuantity = () => {
    let allowedQuantity = 0;
    let message = "";
    if (selected !== false) {
      if (planInfo.diseases_name === "Hairloss") {
        allowedQuantity = 3;
        message = `Duration (Months) can not be more than 3`;
      } else {
        allowedQuantity = 10;
        message = `Quantity can not be more than 10`;
      }
      if (quantity >= allowedQuantity) {
        showNotification("error", "Failed !!", " " + message);
      } else {
        let _quantity = Number(quantity + 1);
        setQuantity(_quantity);
      }
    }
  };

  const handleDosageSelection = (event, itemIndex, selectedItem) => {
    setSelected(itemIndex);
    saveSelectedItem(selectedItem);
    setPriceToShow(selectedItem.price);
  };

  const saveSelectedItem = (selectedItem) => {
    let _price = Number(selectedItem.price);
    setPrice(_price);
    setQuantity(1);
    setSelectedTreatment(selectedItem);
  };

  const onBuyTreatment = () => {
    if (selectedTreatment === null) {
      showNotification("error", "Failed !", "Please Select Dosage");
    } else {
      selectedTreatment["finalPrice"] = finalPrice;
      selectedTreatment["userSelectedQuantity"] = quantity;
      navigate(`/${selectedDisease}/questionnaire`, {
        state: {
          planDetails: planInfo,
          diseaseName: planInfo.diseases_name,
          selectedPlan: selectedTreatment,
        },
      });
    }
  };

  return (
    <>
      {planInfo.plan_name === "Full Package" ? (
        <FullPackageCard planInfo={planInfo} />
      ) : (
        <Card
          classes={{ root: classes.dWQtreatmentCard }}
          style={{
            width:
              selectedDisease === "Premature Ejaculation"
                ? matchesSM
                  ? "350px"
                  : "400px"
                : null,
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              background: "#004451",
              height: "auto",
              padding: "21px 24px",
            }}
          >
            <Grid item>
              <Typography className={classes.dWQCardtTitle}>
                {uniqueDrug && uniqueDrug.length === 1 ? (
                 
                  <>
                   {console.log(uniqueDrug,"uniqueDrug")}
                    {/* <Typography
                      style={{
                        paddingBottom: "4px",
                      }}
                      className={classes.treatmentFormTitle}
                    >
                      Drug Name
                    </Typography> */}
                    <Typography
                      style={{
                        fontFamily: "Visuelt Pro",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "24px",
                        lineHeight: "32px",
                        cursor: "pointer",
                      }}
                      // onClick={() => setDrugModel(!drugModel)}
                    >
                      {uniqueDrug[0]}
                      {console.log(uniqueDrug[0], "uniqueDruguniqueDrug")}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      style={{
                        paddingBottom: "8px",
                      }}
                      className={classes.treatmentFormTitle}
                    >
                      Select Drug
                    </Typography>
                    <Grid item container direction="row">
                      <FormControl
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          marginBottom: "25px",
                        }}
                      >
                        {uniqueDrug &&
                          uniqueDrug.length > 0 &&
                          uniqueDrug.map((drug, index) => (
                            <Grid item xs key={`${drug}${index}`}>
                              {console.log(drug,"drug")}
                              <FormControlLabel
                                style={
                                  selected === index
                                    ? {
                                        borderColor: "#004451",
                                        backgroundColor: "#D9EDF1",
                                        color: "black",
                                      }
                                    : { color: "black" }
                                }
                                classes={{
                                  root: classes.optionSelect,
                                  label: classes.optionSelectLabel,
                                }}
                                value={drug}
                                control={
                                  <Checkbox style={{ display: "none" }} />
                                }
                                label={drug}
                                onChange={(event) => {
                                  handleDosageSelection(
                                    event,
                                    index,
                                    uniqueDrugsData[index]
                                  );
                                }}
                              />
                            </Grid>
                          ))}
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Typography>
            </Grid>
            {/* {uniqueDrug && uniqueDrug.length === 1 && (
              <Grid
                item
                style={{ cursor: "pointer" }}
                onClick={() => setDrugModel(!drugModel)}
              >
                {" "}
                <img src={Info} alt="ellipse" />
              </Grid>
            )} */}
          </Grid>

          {/* Unique Brands */}

          <Grid
            item
            justifyContent="space-between"
            alignItems="center"
            style={{
              background: "#004451",
              height: "auto",
              padding: "24px 24px",
            }}
          >
            <Typography className={classes.dWQCardtTitle}>
              {uniqueBrands && uniqueBrands.length > 1 && (
                <>
                  <Typography
                    style={{
                      paddingBottom: "8px",
                    }}
                    className={classes.treatmentFormTitle}
                  >
                    Select Any One Option
                  </Typography>
                  <Grid item container direction="row">
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginBottom: "25px",
                      }}
                    >
                      {uniqueBrands &&
                        uniqueBrands.length > 0 &&
                        uniqueBrands.map((brand, index) => (
                          
                          <Grid item xs key={`${brand}${index}`}>
                            <FormControlLabel
                              style={
                                selected === index
                                  ? {
                                      borderColor: "#004451",
                                      backgroundColor: "#D9EDF1",
                                      color: "black",
                                    }
                                  : { color: "black" }
                              }
                              classes={{
                                root: classes.optionSelectMultiBrand,
                                label: classes.optionSelectLabel,
                              }}
                              value={brand}
                              control={<Checkbox style={{ display: "none" }} />}
                              label={brand}
                              onChange={(event) => {
                                handleDosageSelection(
                                  event,
                                  index,
                                  uniqueDrugsData[index]
                                );
                              }}
                            />
                          </Grid>
                        ))}
                    </FormControl>
                  </Grid>
                </>
              )}
            </Typography>
          </Grid>

          {/* uniqueBrand End */}

          <CardContent classes={{ root: classes.dWQplanInfoCardContent }}>
            <Grid item>
              {uniqueBrands && uniqueBrands.length > 1 ? (
                <MultiBrandInfo
                  brandInfo={
                    selectedTreatment && selectedTreatment !== null
                      ? selectedTreatment
                      : planInfo.treatment[0]
                  }
                  planInfo={planInfo}
                />
              ) : (
                <BrandInfo planInfo={planInfo} />
              )}

              {planInfo &&
              planInfo.treatment.length > 0 &&
              planInfo.treatment[0].qty === "1" ? (
                <>
                  <Typography className={classes.planInfoFormTitle}>
                    Size
                  </Typography>

                  <FormControl
                    style={{
                      display: "flex",
                      width: "100%",
                      marginBottom: "25px",
                      marginTop: "8px",
                      flexDirection: "row",
                    }}
                  >
                    {planInfo.treatment &&
                      planInfo.treatment.length > 0 &&
                      planInfo.treatment.map((dosage, index) => (
                        <Grid item xs key={`${dosage}${index}`}>
                          <FormControlLabel
                            style={{
                              borderColor: "#004451",
                              backgroundColor: "#D9EDF1",
                            }}
                            classes={{
                              root: classes.optionSelect,
                              label: classes.optionSelectLabel,
                            }}
                            value={dosage}
                            control={<Checkbox style={{ display: "none" }} />}
                            checked={selected === index ? true : false}
                            onChange={(event) => {
                              handleDosageSelection(event, index, dosage);
                            }}
                            label={<span>{dosage.ml}</span>}
                          />
                        </Grid>
                      ))}
                  </FormControl>
                </>
              ) : (
                <>
                  <Typography className={classes.planInfoFormTitle}>
                    Choose your dosages
                  </Typography>

                  <FormControl
                    style={{
                      display: "flex",
                      width: "100%",
                      marginBottom: "25px",
                      marginTop: "8px",
                      flexDirection: "row",
                    }}
                  >
                    {(planInfo.treatement &&
                      planInfo.treatement.length > 0 &&
                      planInfo.treatement[0].brand_name == "Cialis") ||
                    (planInfo &&
                      planInfo.treatment[1] &&
                      planInfo.treatment[1].brand_name == "Teva")
                      ? planInfo.treatment &&
                        planInfo.treatment.length > 0 && (
                          // planInfo.treatment.map((dosage, index) => (
                          <Grid
                            item
                            xs
                            key={`${planInfo.treatment}${planInfo.treatment[0]}`}
                          >
                            {console.log(
                              planInfo.treatment,
                              "planInfo.treatment.brand_name"
                            )}
                            <FormControlLabel
                              style={
                                // selected === planInfo.treatment[0] ?

                                {
                                  borderColor: "#004451",
                                  backgroundColor: "#D9EDF1",
                                }
                                // : {}
                              }
                              classes={{
                                root: classes.optionSelect,
                                label: classes.optionSelectLabel,
                              }}
                              value={planInfo.treatment[0]}
                              control={<Checkbox style={{ display: "none" }} />}
                              checked={true}
                              // onChange={(event) => {
                              //   handleDosageSelection(event, index, dosage);
                              // }}
                              label={
                                <span>
                                  {planInfo.treatment[0].doses}
                                  <br />
                                  <span
                                    style={{
                                      fontFamily: "Visuelt Pro",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      fontSize: "12px",
                                      lineHeight: "18px",
                                    }}
                                  >
                                    {planInfo &&
                                      planInfo.treatment &&
                                      planInfo.treatment[0].mg}{" "}
                                    {"mg"}
                                  </span>
                                </span>
                              }
                            />
                          </Grid>
                        )
                      : planInfo.treatment &&
                        planInfo.treatment.length > 0 &&
                        planInfo.treatment.map((dosage, index) => (
                          <Grid item xs key={`${dosage}${index}`}>
                            {console.log(
                              planInfo.treatment,
                              "planInfo.treatment.brand_name"
                            )}
                            <FormControlLabel
                              style={
                                selected === index
                                  ? {
                                      borderColor: "#004451",
                                      backgroundColor: "#D9EDF1",
                                    }
                                  : {}
                              }
                              classes={{
                                root: classes.optionSelect,
                                label: classes.optionSelectLabel,
                              }}
                              value={dosage}
                              control={<Checkbox style={{ display: "none" }} />}
                              checked={selected === index ? true : false}
                              onChange={(event) => {
                                handleDosageSelection(event, index, dosage);
                              }}
                              label={
                                <span>
                                  {dosage.doses}
                                  <br />
                                  <span
                                    style={{
                                      fontFamily: "Visuelt Pro",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      fontSize: "12px",
                                      lineHeight: "18px",
                                    }}
                                  >
                                    {dosage.mg} {"mg"}
                                  </span>
                                </span>
                              }
                            />
                          </Grid>
                        ))}
                    {/* {planInfo.treatment &&
                      planInfo.treatment.length > 0 &&
                      planInfo.treatment.map((dosage, index) => (
                        <Grid item xs key={`${dosage}${index}`}>
                          <FormControlLabel
                            style={
                              selected === index
                                ? {
                                  borderColor: "#004451",
                                  backgroundColor: "#D9EDF1",
                                }
                                :{}
                            }
                            classes={{
                              root: classes.optionSelect,
                              label: classes.optionSelectLabel,
                            }}
                            value={dosage}
                            control={<Checkbox style={{ display: "none" }} />}
                            checked={selected === index ? true : false}
                            onChange={(event) => {
                              handleDosageSelection(event, index, dosage);
                            }}
                            label={
                              <span>
                                {dosage.doses}
                                {console.log(dosage,"dosage")}
                                <br />
                                <span
                                  style={{
                                    fontFamily: "Visuelt Pro",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                  }}
                                >
                                  {dosage.mg} {"mg"}
                                </span>
                              </span>
                            }
                          />
                        </Grid>
                      ))} */}
                  </FormControl>

                  <Typography className={classes.planInfoFormTitle}>
                    Tablets in this package
                  </Typography>

                  <FormControl
                    value={tablet}
                    style={{
                      display: "flex",
                      width: "100%",
                      marginBottom: "25px",
                      marginTop: "8px",
                      flexDirection: "row",
                    }}
                  >
                    {(planInfo.treatment &&
                      planInfo.treatment.length > 0 &&
                      planInfo.treatment[0].brand_name == "Cialis") ||
                    (planInfo &&
                      planInfo.treatment[1] &&
                      planInfo.treatment[1].brand_name == "Teva")
                      ? planInfo.treatment &&
                        planInfo.treatment.length > 0 && (
                          // planInfo.treatment.map((dosage, index) => (
                          <Grid
                            item
                            xs
                            key={`${planInfo.treatment}${planInfo.treatment[0]}`}
                          >
                            {console.log(
                              planInfo.treatment,
                              "planInfo.treatment.brand_name"
                            )}
                            <FormControlLabel
                              style={
                                // selected === planInfo.treatment[0] ?

                                {
                                  borderColor: "#004451",
                                  backgroundColor: "#D9EDF1",
                                }
                                // : {}
                              }
                              classes={{
                                root: classes.optionSelect,
                                label: classes.optionSelectLabel,
                              }}
                              value={planInfo.treatment[0]}
                              control={<Checkbox style={{ display: "none" }} />}
                              checked={true}
                              // onChange={(event) => {
                              //   handleDosageSelection(event, index, dosage);
                              // }}
                              label={planInfo.treatment[0].tabs}
                            />
                            {console.log(
                              planInfo.treatment[0],
                              "planInfo.treatment[0]"
                            )}
                          </Grid>
                        )
                      : planInfo.treatment &&
                        planInfo.treatment.length > 0 &&
                        planInfo.treatment.map((tablet, index) => (
                          <Grid item xs key={`${tablet}${index}`}>
                            <FormControlLabel
                              style={
                                selected === index
                                  ? {
                                      borderColor: "#004451",
                                      backgroundColor: "#D9EDF1",
                                    }
                                  : {}
                              }
                              classes={{
                                root: classes.optionSelect,
                                label: classes.optionSelectLabel,
                              }}
                              value={tablet}
                              checked={selected === index ? true : false}
                              onChange={(event) => {
                                handleDosageSelection(event, index, tablet);
                              }}
                              control={<Checkbox style={{ display: "none" }} />}
                              label={tablet.tabs}
                            />
                          </Grid>
                        ))}
                  </FormControl>
                </>
              )}

              <Grid
                item
                container
                direction={matchesXS ? "column" : "row"}
                style={{ marginBottom: "20px" }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  style={{
                    width: "135px",
                    marginRight: matchesXS ? "0px" : "20px",
                    marginBottom: matchesXS ? "20px" : "0px",
                  }}
                >
                  <Typography
                    className={classes.planInfoFormTitle}
                    style={{ paddingBottom: "8px" }}
                  >
                    {planInfo.diseases_name === "Hairloss"
                      ? "Duration (Months)"
                      : "Quantity"}
                  </Typography>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="space-evenly"
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #C6E2E7",
                      boxSizing: "border-box",
                      height: "70px",
                      width: "135px",
                    }}
                  >
                    <Typography className={classes.planInfoFormTitle}>
                      {quantity}
                    </Typography>
                    <Grid
                      item
                      container
                      direction="column"
                      style={{ width: "9px" }}
                    >
                      <IconButton
                        disableTouchRipple
                        className={classes.iconButton}
                        onClick={increaseQuantity}
                      >
                        <img src={chevronUp} alt="down" />
                      </IconButton>
                      <IconButton
                        disableTouchRipple
                        className={classes.iconButton}
                        onClick={decreseQuantity}
                      >
                        <img src={chevronDownQ} alt="down" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction={matchesXS ? "row" : "column"}
                  style={{ width: "100%" }}
                  xs={8}
                  lg={8}
                  md={12}
                >
                  <Typography
                    className={classes.planInfoFormTitle}
                    style={{ paddingBottom: "8px" }}
                  >
                    <span style={{ fontWeight: "700" }}>Price (₦) *</span>{" "}
                    <span
                      style={{
                        fontStyle: "italic",
                        fontSize: "14px",
                        lineHeight: "18px",
                        color: "#00839B",
                      }}
                    >
                      Starts from
                    </span>
                  </Typography>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="space-evenly"
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #C6E2E7",
                      boxSizing: "border-box",
                      height: "70px",
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Visuelt Pro",
                    }}
                  >
                    {price}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.dWQtreatmentButton}
                  onClick={onBuyTreatment}
                  style={{
                    width:
                      selectedDisease === "Premature Ejaculation"
                        ? matchesXS
                          ? "100%"
                          : "352px"
                        : undefined,
                  }}
                >
                  Buy Treatment
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {/* <DrugPopup
        closeDialog={() => setDrugModel(!drugModel)}
        drugInfo={planInfo.treatment[0]}
        openDialog={drugModel}
      /> */}
    </>

    // <>
    //   {planInfo.plan_name === "Full Package" ? (
    //     <FullPackageCard planInfo={planInfo} />
    //   ) : (
    //     <Card
    //       classes={{ root: classes.dWQtreatmentCard }}
    //       style={{
    //         width:
    //           selectedDisease === "Premature Ejaculation"
    //             ? matchesSM
    //               ? "350px"
    //               : "400px"
    //             : null,
    //       }}
    //     >
    //       <Grid
    //         item
    //         container
    //         justifyContent="space-between"
    //         alignItems="center"
    //         style={{
    //           background: "#004451",
    //           height: "auto",
    //           padding: "21px 24px",
    //         }}
    //       >
    //         <Grid item>
    //           <Typography className={classes.dWQCardtTitle}>
    //             {uniqueDrug && uniqueDrug.length === 1 ? (
    //               <>
    //                 <Typography
    //                   style={{
    //                     paddingBottom: "4px",
    //                   }}
    //                   className={classes.treatmentFormTitle}

    //                 >
    //                   Drug Name
    //                 </Typography>
    //                 <Typography
    //                   style={{
    //                     fontFamily: "Visuelt Pro",
    //                     fontStyle: "normal",
    //                     fontWeight: "500",
    //                     fontSize: "24px",
    //                     lineHeight: "32px",
    //                     cursor: "pointer"
    //                   }}
    //                 >
    //                   {uniqueDrug[0]}
    //                 </Typography>
    //               </>
    //             ) : (
    //               <>
    //                 <Typography
    //                   style={{
    //                     paddingBottom: "8px",
    //                   }}
    //                   className={classes.treatmentFormTitle}
    //                 >
    //                   Select Drug
    //                 </Typography>
    //                 <Grid item container direction="row">
    //                   <FormControl
    //                     style={{
    //                       display: "flex",
    //                       flexDirection: "row",
    //                       width: "100%",
    //                       marginBottom: "25px",
    //                     }}
    //                   >
    //                     {uniqueDrug &&
    //                       uniqueDrug.length > 0 &&
    //                       uniqueDrug.map((drug, index) => (
    //                         <Grid item xs key={`${drug}${index}`}>
    //                           <FormControlLabel
    //                             style={
    //                               selected === index
    //                                 ? {
    //                                   borderColor: "#004451",
    //                                   backgroundColor: "#D9EDF1",
    //                                   color: 'black'
    //                                 }
    //                                 : { color: 'black' }
    //                             }
    //                             classes={{
    //                               root: classes.optionSelect,
    //                               label: classes.optionSelectLabel,
    //                             }}
    //                             value={drug}
    //                             control={
    //                               <Checkbox style={{ display: "none" }} />
    //                             }
    //                             label={drug}
    //                             onChange={(event) => {
    //                               handleDosageSelection(event, index, uniqueDrugsData[index]);
    //                             }}
    //                           />
    //                         </Grid>
    //                       ))}
    //                   </FormControl>
    //                 </Grid>
    //               </>
    //             )}
    //           </Typography>
    //         </Grid>
    //         {uniqueDrug && uniqueDrug.length === 1 && <Grid item style={{ cursor: 'pointer' }} onClick={() => setDrugModel(!drugModel)}>
    //           {" "}
    //           <img src={Info} alt="ellipse" />
    //         </Grid>}
    //       </Grid>

    //       {/* Unique Brands */}

    //       <Grid item
    //         justifyContent="space-between"
    //         alignItems="center"
    //         style={{
    //           background: "#004451",
    //           height: "auto",
    //           padding: "24px 24px",
    //         }}
    //       >
    //         <Typography className={classes.dWQCardtTitle}>
    //           {uniqueBrands && uniqueBrands.length > 1 &&
    //             <>
    //               <Typography
    //                 style={{
    //                   paddingBottom: "8px",
    //                 }}
    //                 className={classes.treatmentFormTitle}
    //               >
    //                 Select Brand Name
    //               </Typography>
    //               <Grid item container direction="row">
    //                 <FormControl
    //                   style={{
    //                     display: "flex",
    //                     flexDirection: "row",
    //                     width: "100%",
    //                     marginBottom: "25px",
    //                   }}
    //                 >
    //                   {uniqueBrands &&
    //                     uniqueBrands.length > 0 &&
    //                     uniqueBrands.map((brand, index) => (
    //                       <Grid item xs key={`${brand}${index}`}>
    //                         <FormControlLabel
    //                           style={
    //                             selected === index
    //                               ? {
    //                                 borderColor: "#004451",
    //                                 backgroundColor: "#D9EDF1",
    //                                 color: 'black'
    //                               }
    //                               : { color: 'black' }
    //                           }
    //                           classes={{
    //                             root: classes.optionSelectMultiBrand,
    //                             label: classes.optionSelectLabel,
    //                           }}
    //                           value={brand}
    //                           control={
    //                             <Checkbox style={{ display: "none" }} />
    //                           }
    //                           label={brand}
    //                           onChange={(event) => {
    //                             handleDosageSelection(event, index, uniqueDrugsData[index]);
    //                           }}
    //                         />
    //                       </Grid>
    //                     ))}
    //                 </FormControl>
    //               </Grid>
    //             </>
    //           }
    //         </Typography>
    //       </Grid>

    //       {/* uniqueBrand End */}

    //       <CardContent classes={{ root: classes.dWQplanInfoCardContent }}>
    //         <Grid item>
    //           {uniqueBrands && uniqueBrands.length > 1 ?
    //             <MultiBrandInfo
    //               brandInfo={selectedTreatment && selectedTreatment !== null
    //                 ? selectedTreatment : planInfo.treatment[0]}
    //               planInfo={planInfo}
    //             /> :
    //             <BrandInfo
    //               planInfo={planInfo}
    //             />
    //           }

    //           {/* TabletsView to show tablets and Doses */}

    //           <TabletsView
    //             isFullPackage={false}
    //             type={planInfo && planInfo.treatment.length > 0
    //               && planInfo.treatment[0].qty === "1" ? "QTY" : "DOSAGE"}
    //             title={planInfo && planInfo.treatment.length > 0
    //               && planInfo.treatment[0].qty === "1" ? "Quantity" : "Dosage"}
    //             _data={selectedTreatment && selectedTreatment !== null
    //               ? selectedTreatment : planInfo.treatment[0]}
    //           />

    //           {/* Quantity Block */}

    //           <Grid
    //             item
    //             container
    //             direction={matchesXS ? "column" : "row"}
    //             style={{ marginBottom: "20px" }}
    //           >
    //             <Grid
    //               item
    //               container
    //               direction="column"
    //               style={{
    //                 width: "135px",
    //                 marginRight: matchesXS ? "0px" : "20px",
    //                 marginBottom: matchesXS ? "20px" : "0px",
    //               }}
    //             >
    //               <Typography
    //                 className={classes.planInfoFormTitle}
    //                 style={{ paddingBottom: "8px" }}
    //               >
    //                 {planInfo.diseases_name === "Hairloss" ? "Duration (Months)" : "Quantity"}
    //               </Typography>
    //               <Grid
    //                 item
    //                 container
    //                 alignItems="center"
    //                 justifyContent="space-evenly"
    //                 style={{
    //                   background: "#FFFFFF",
    //                   border: "1px solid #C6E2E7",
    //                   boxSizing: "border-box",
    //                   height: "70px",
    //                   width: "135px",
    //                 }}
    //               >
    //                 <Typography className={classes.planInfoFormTitle}>
    //                   {quantity}
    //                 </Typography>
    //                 <Grid
    //                   item
    //                   container
    //                   direction="column"
    //                   style={{ width: "9px" }}
    //                 >
    //                   <IconButton
    //                     disableTouchRipple
    //                     className={classes.iconButton}
    //                     onClick={increaseQuantity}
    //                   >
    //                     <img src={chevronUp} alt="down" />
    //                   </IconButton>
    //                   <IconButton
    //                     disableTouchRipple
    //                     className={classes.iconButton}
    //                     onClick={decreseQuantity}
    //                   >
    //                     <img src={chevronDownQ} alt="down" />
    //                   </IconButton>
    //                 </Grid>
    //               </Grid>
    //             </Grid>
    //             <Grid
    //               item
    //               container
    //               direction="column"
    //               style={{ width: "100%" }}
    //               xs={6}
    //             >
    //               <Typography
    //                 className={classes.planInfoFormTitle}
    //                 style={{ paddingBottom: "8px" }}
    //               >
    //                 <span style={{ fontWeight: "700" }}>Price (₦) *</span>{" "}
    //                 <span
    //                   style={{
    //                     fontStyle: "italic",
    //                     fontSize: "14px",
    //                     lineHeight: "18px",
    //                     color: "#00839B",
    //                   }}
    //                 >
    //                   Starts from
    //                 </span>
    //               </Typography>
    //               <Grid
    //                 item
    //                 container
    //                 alignItems="center"
    //                 justifyContent="space-evenly"
    //                 style={{
    //                   background: "#FFFFFF",
    //                   border: "1px solid #C6E2E7",
    //                   boxSizing: "border-box",
    //                   height: "70px",
    //                   fontSize: "14px",
    //                   fontWeight: "600",
    //                   fontFamily: "Visuelt Pro",
    //                 }}
    //               >
    //                 {price}
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //           <Grid item>
    //             <Button
    //               variant="contained"
    //               className={classes.dWQtreatmentButton}
    //               onClick={onBuyTreatment}
    //               style={{
    //                 width:
    //                   selectedDisease === "Premature Ejaculation"
    //                     ? matchesXS
    //                       ? "100%"
    //                       : "352px"
    //                     : undefined,
    //               }}
    //             >
    //               Buy Treatment
    //             </Button>
    //           </Grid>
    //         </Grid>
    //       </CardContent>

    //     </Card>
    //   )}

    //   <DrugPopup
    //     closeDialog={() => setDrugModel(!drugModel)}
    //     drugInfo={planInfo.treatment[0]}
    //     openDialog={drugModel}
    //   />

    // </>
  );
}
