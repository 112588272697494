const authActions = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGOUT: "LOGOUT",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    AUTH_ERROR: "AUTH_ERROR",
    SAVE_REGISTRATION_PAYLOAD: "SAVE_REGISTRATION_PAYLOAD",



    handleLogin: (payLoad) => {
        console.log(payLoad,"payLoad")
        return {
            type: authActions.LOGIN_REQUEST,
            payLoad,
        };
    },

    userLogout: () => {
        return {
            type: authActions.LOGOUT,
        };
    },

    saveRegPayLoad: (payLoad) => {
        return {
            type: authActions.SAVE_REGISTRATION_PAYLOAD,
            payLoad
        };
    }

};

export default authActions;
