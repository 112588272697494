import React, { useState, useEffect } from "react";
import {
  Input,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
} from "@material-ui/core";
import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/actions";
import { showNotification } from "../../components/common-notification/CommonNotification";
import editaddr from "../../assets/images/editaddr.svg";
import infoAlert from "../../assets/images/infoAlert.svg";
import { getShippingCharges } from "../../services/locationsServices/locationServices";
import { getAddressDetails } from "../../services/order-services/orderServices";

import firebase from "firebase/app";
import { getFirebaseAnalyticsInstance } from "../../firebase";

const useStyles = makeStyles((theme) => styles(theme));

const Shipping = ({
  expanded,
  handleChange,
  setShippingDetails,
  handleExpand,
  setShippingCharge,
  questionsSummary,
  disease
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { locationsActions } = actions;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [areaId, setAreaId] = useState();
  const [cityId, setCityId] = useState();
  const [shipmentCharge, setShipmentCharge] = useState();

  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();

  const _userInfo = useSelector((state) => state.authReducer);
console.log(expanded, "expandedexpanded")
//  let id = _userInfo.userInfo.uuid;
  



  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const tooltip =
    "This city and area is from the selection made, please note that, shipping charges varies based on the city and area selection";

  const _locations = useSelector((state) => state.locationReducer);

  useEffect(() => {
    dispatch(locationsActions.getCitys());
  }, []);

  useEffect(() => {
    handleCity(questionsSummary && questionsSummary.shippingAddress.CITY_ID)
  }, [questionsSummary]);


  const handleCity = (event) => {
    dispatch(locationsActions.getAreas(event));
    setCityId(event)
  };

  useEffect(() => {
    fetchShippingCharges()
    fetchAddress()
  }, [areaId])

  useEffect(() => {
    console.log("_userInfo")
   fetchAddress()
  }, [_userInfo])

  const onSelectArea = (event) => {
    setAreaId(event)
  }


  const fetchShippingCharges = async () => {
    try {
      let response = await getShippingCharges(cityId, areaId)
      if (response && (response.status === 200 || response.status === 201)) {
        if (response.data && response.data.length > 0) {
          let mResponse = response.data[0];
          setShippingCharge(mResponse.doctall_selleing_price)
          setShipmentCharge(mResponse.doctall_selleing_price)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAddress = async () => {
    try {
      let response = await getAddressDetails(_userInfo && _userInfo.userInfo && _userInfo.userInfo.uuid, _userInfo && _userInfo.userInfo && _userInfo.userInfo.token)
      if (response && (response.status === 200 || response.status === 201)) {
        if (response.data) { 
          let mResponse = response.data.delivery_address;
          // console.log(response.data.delivery_address)
          // addressLine1
          setAddress1(mResponse.addressLine1)
          setAddress2(mResponse.addressLine2)
          // setShipmentCharge(mResponse.doctall_selleing_price)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let { address2, address1, city, area } = event.target.elements;
    let payLoad = {
      addressLine1: address1.value,
      addressLine2: address2.value,
      city: city.value,
      area: area.value,
      shippingAmount: shipmentCharge
    };
    handleExpand("Payment");
    showNotification(
      "success",
      "Success !",
      "Address Details Saved Successfully.."
    );
    setShippingDetails(payLoad);

       const params = {
            currency: 'NGN',
            value: payableAmount, // Total Revenue
            items: [{ item_id: planInfo.tpl_uuid, item_name: basePlan.plan_name, currency: "NGN", price: planAmount, quantity: finalQuantity, shipping_tier: "Ground", shipping: shippingCharges }]
          };
          getFirebaseAnalyticsInstance().logEvent(firebase.analytics.EventName.ADD_SHIPPING_INFO, params)

    
  };

  return (
    <Accordion
      expanded={expanded === "Shipping"}
      onChange={handleChange("Shipping")}
      className={classes.inputContainer}
      style={{ marginBottom: "32px" }}
    >
      <AccordionSummary
        //expandIcon={<ExpandMoreIcon />}
        aria-controls="account"
        id="account"
        style={{ minHeight: "91px", padding: "0px" }}
      >
        <Grid container justifyContent="space-between">
          <Typography className={classes.summaryHeading}>
            2. {disease !== "Diabetes" ? 'Shipping Address' : 'Home Sample Collection Address'}
          </Typography>
          {expanded === "Payment" ? (
            <Grid
              item
              container
              style={{ width: "63px", height: "20px" }}
              alignItems="center"
            >
              <img src={editaddr} alt="edit" style={{ marginRight: "10px" }} />
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#F04438",
                }}
              >
                Edit
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ height: "auto", padding: "0px" }}>
        <form onSubmit={handleSubmit}>
          <Grid item container direction="column">
            <Grid item>
              <Typography
                align="left"
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#344054",
                  paddingBottom: "6px",
                }}
              >
                Address line 1
              </Typography>

              <Input
                type="text"
                required
                name="address1"
                placeholder="Enter Address line 1"
                className={classes.checkoutInput}
                onChange={(event) => {setAddress1(event.target.value)}}
                disableUnderline={true}
                value={address1}
              />
            </Grid>
            <Grid item style={{ paddingTop: "20px" }}>
              <Typography
                align="left"
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#344054",
                  paddingBottom: "6px",
                }}
              >
                Address line 2
              </Typography>

              <Input
                type="text"
                required
                name="address2"
                placeholder="Enter Address line 2"
                className={classes.checkoutInput}
                onChange={(event) => {setAddress2(event.target.value)}}
                disableUnderline={true}
                value={address2}
              />
            </Grid>
            <Grid item style={{ paddingTop: "20px" }}>
              <Grid container justifyContent="space-between">
                <Typography
                  align="left"
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#344054",
                    paddingBottom: "6px",
                  }}
                >
                  City
                </Typography>
                {disease !== "Diabetes" &&
                <Tooltip
                  title={tooltip}
                  placement="left"
                  arrow
                  classes={{ popper: classes.InfoTooltip }}
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                >
                  <IconButton disableFocusRipple disableRipple>
                    <img src={infoAlert} alt="alert" />
                  </IconButton>
                </Tooltip>
                }
              </Grid>

              <select
                name="city"
                required
                className={classes.email}
                onChange={(e) => handleCity(e.target.value)}
                // value={questionsSummary.shippingAddress.CITY_ID}
                defaultValue={questionsSummary && questionsSummary.shippingAddress.CITY_ID}
              >
                <option value="">Select City</option>
                {_locations &&
                  _locations.citiesData.length > 0 &&
                  _locations.citiesData.map((city) => (
                    <option value={city.uuid}>{city.name}</option>
                  ))}
              </select>
            </Grid>
            <Grid item style={{ paddingTop: "20px" }}>
              <Typography
                align="left"
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#344054",
                  paddingBottom: "6px",
                }}
              >
                Area
              </Typography>

              <select name="area" defaultValue={questionsSummary && questionsSummary.shippingAddress.AREA_ID} required onChange={(e) => onSelectArea(e.target.value)}
                className={classes.email}>
                <option value="">Select Area</option>
                {_locations &&
                  _locations.areasData.length > 0 &&
                  _locations.areasData.map((area) => (
                    <option value={area.uuid}>{area.name}</option>
                  ))}
              </select>
            </Grid>

            <Button
              variant="contained"
              type="submit"
              className={classes.createAccount}
              style={{
                marginBottom: "30px",
              }}
            >
              Save this Address
            </Button>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default Shipping;
