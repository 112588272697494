import React, { useEffect, useState, useRef } from "react";
import "../styles.js";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  Radio,
  Switch ,
} from "@material-ui/core";
import styles from "../styles";
import { makeStyles, useTheme } from "@material-ui/core";
import AlertClose from "../../../assets/images/AlertClose.svg";
import info from "../../../assets/images/info.svg";
import { validateEmail } from "../../../services/doctall-living/staticServices.js";
import actions from '../../../redux/actions';
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "../util/UiComp.js";
import CallBackPopUp from "../../callbackPopUp/CallBackPopUP.js";
import { showNotification } from "../../../components/common-notification/CommonNotification.js";
import { getShippingCharges } from '../../../services/locationsServices/locationServices'

const useStyles = makeStyles((theme) => styles(theme));

export const AgeQuestion = ({
  age,
  handleChange,
  stepUpdate,
  selectedAge,
  HL,
}) => {
  const theme = useTheme();

  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState("");
  const [seeDoctor, setSeeDoctor] = useState(false);
  const [callBackModel, setCallBackModel] = useState(false);

  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const options = HL === true ? age.PEOptions : age.options;
  const focusPoint = useRef(null);


  useEffect(() => {
    if (seeDoctor) {
      window.scrollTo(0, focusPoint.current.offsetTop);
    }
  }, [seeDoctor]);


  useEffect(() => {
    setSelectedValue(selectedAge);
  }, [selectedValue]);


  const handleChangeAge = (event) => {
    let answer = event.target.value.toString().trim();
    if (answer === "I am under 18 years old" || answer === "I am between 70 and above years old") {
      setSeeDoctor(true)
    } else {
      handleChange(event.target.value);
      setSeeDoctor(false)
      stepUpdate(2);
    }
  };


  const Unchecked = ({ label }) => (
    <Grid className={classes.quesOption}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );
  const Checked = ({ label }) => (
    <Grid className={classes.quesChecked}>
      <Typography className={classes.quesOptionLabel}>{label}</Typography>
    </Grid>
  );
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "40px" }}
    >
      <Typography
        className={classes.questionTitle}
        style={{ paddingBottom: "56px" }}
      >
        {age.title}
      </Typography>

      {options.map((option) => (
        // <Switch 
        //   label={option}
        //   checked={selectedValue === option}
        //    onChange={handleChangeAge}
        //    value={option}
        //    name="radio-buttons"
        //  inputProps={{ "aria-label": option }}
        //    icon={<Unchecked label={option} />}
        //    checkedIcon={<Checked label={option} />}
        //    disableFocusRipple
        //    disableTouchRipple
        //    disableRipple 
        //  />
        <Radio
          checked={selectedValue === option}
          onChange={handleChangeAge}
          value={option}
          name="radio-buttons"
          inputProps={{ "aria-label": option }}
          icon={<Unchecked label={option} />}
          checkedIcon={<Checked label={option} />}
          disableFocusRipple
          disableTouchRipple
          disableRipple
        />
      ))}


      {seeDoctor ? (
        <Alert>
          <img src={AlertClose} alt="Close" style={{ paddingTop: "52.51px" }} />
          <Typography
            // ref={focusPoint}
            style={{
              paddingTop: "49px",
              paddingBottom: "8px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#002931",
            }}
          >
            Not suitable for treatment
          </Typography>
          <Typography
            align="center"
            style={{
              width: matchesSM ? "250px" : "421px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#002931",
              paddingBottom: "47px",
            }}
            ref={focusPoint}

          >
            Based on your answer, you are not suitable for Rx. Change your answer
            or kindly click on the button and talk to a doctor.
          </Typography>
          <Button className={classes.talkToADoctorButton} onClick={() => setCallBackModel(!callBackModel)}>
            TALK TO A DOCTOR
          </Button>
        </Alert>
      ) : null}

      <CallBackPopUp
        open={callBackModel}
        close={() => setCallBackModel(!callBackModel)}
      />
    </Grid>
  );
};

export const Mail = ({ mail, handleChange, stepUpdate, userEmail }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const _userInfo = useSelector((state) => state.authReducer);

  // const { email } = _userInfo && _userInfo.userInfo ? _userInfo.userInfo : {};





  const dispatch = useDispatch();
  const { locationsActions } = actions;
  const _locations = useSelector((state) => state.locationReducer);
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState();
  const [shippingCharge, setShippingCharge] = useState(0);
  const [areaId, setAreaId] = useState();
  const [cityId, setCityId] = useState();
  const [areaName, setAreaName] = useState();
  const [cityName, setCityName] = useState();



  useEffect(() => {
    setEmail(userEmail);
  }, [userEmail]);


  useEffect(() => {
    if (_userInfo && _userInfo.userInfo) {
      setEmail(_userInfo.userInfo.email);
    }

  }, [_userInfo]);


  useEffect(() => {
    dispatch(locationsActions.getCitys())
  }, [])

  useEffect(() => {
    fetchShippingCharges()
  }, [areaId])


  const fetchShippingCharges = async () => {
    try {
      let response = await getShippingCharges(cityId, areaId)
      if (response && response.status === 200 || response.status === 201) {
        if (response.data && response.data.length > 0) {
          let mResponse = response.data[0];
          setShippingCharge(mResponse.doctall_selleing_price)
          setAreaName(mResponse.city[0].name);
          setCityName(mResponse.region[0].name);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }


  const onSelectCity = (event) => {
    dispatch(locationsActions.getAreas(event))
    setCityId(event)
  }

  const onSelectArea = (event) => {
    setAreaId(event)
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      showNotification("error", "Failed", "Please Enter Valid Email Address");
    } else {
      let shippingDetails = { "AREA_ID": areaId, "CITY_ID": cityId, "AREA_NAME": areaName, "CITY_NAME": cityName }
      handleChange(email, shippingCharge, shippingDetails);
      stepUpdate(3);
      // e.target.reset();  
    }
  };


  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <form onSubmit={handleSubmit}>
        <Grid
          item
          container
          className={classes.inputContainer}
          style={{ marginTop: "40px" }}
        >
          <Grid container direction="column" alignItems="center">
            <Typography
              className={classes.questionTitle}
              style={{ paddingBottom: "56px" }}
              align={matchesSM ? "center" : undefined}
            >
              {mail.title}
            </Typography>
            <Grid item>
              <Typography
                align="left"
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#344054",
                }}
              >
                Email Address
              </Typography>

              <input
                type="email"
                required
                value={email}
                placeholder="Enter email address"
                className={classes.email}
                onChange={(e) => setEmail(e.target.value)}
                defaultValue={_userInfo && _userInfo.userInfo ? _userInfo.userInfo.email : " "}
              />

            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          className={classes.inputContainer}
          style={{
            marginTop: "28px",
            paddingBottom: "83px",
            marginBottom: "54px",
          }}
        >
          <Grid container direction="column" alignItems="center">
            <Typography
              className={classes.questionTitle}
              style={{ paddingBottom: "8px" }}
            >
              {mail.address.title}
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: " #002931",
                paddingBottom: "56px",
              }}
            >
              {mail.address.subtitle}
            </Typography>
            <Grid item>
              <Typography
                align="left"
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#344054",
                }}
              >
                City
              </Typography>

              <select
                name="city"
                required
                placeholder="Enter email address"
                className={classes.email}
                onChange={(e) => {
                  onSelectCity(e.target.value)
                }}
              >
                <option value="">Select City</option>
                {
                  _locations && _locations.citiesData.length > 0 && _locations.citiesData.map((city) => (
                    <option value={city.uuid}>{city.name}</option>
                  ))
                }
              </select>
            </Grid>
            <Grid item>
              <Typography
                align="left"
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#344054",
                  paddingTop: "20px",
                }}
              >
                Area
              </Typography>

              <select
                name="area"
                required
                placeholder="Enter email address"
                className={classes.email}
                onChange={(e) => onSelectArea(e.target.value)}
              >
                <option value="">Select Area</option>
                {
                  _locations && _locations.areasData.length > 0 && _locations.areasData.map((area) => (
                    <option value={area.uuid}>{area.name}</option>
                  ))
                }
              </select>
            </Grid>
            <Grid
              item
              container
              className={classes.deliveryContainer}
              direction="column"
              alignItems="center"
            >
              <img src={info} alt="info" />
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#002931",
                  paddingTop: "15px",
                  paddingBottom: "8px",
                }}
              >
                {/* to{" "} */}
                Delivery Amount
                {/* <span
                  style={{
                    fontFamily: "Visuelt Pro",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#FF5C3C",
                  }}
                >
                  {cityName} {areaName}
                </span> */}
                {/* &nbsp;is; */}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "32px",
                  color: "#002931",
                  paddingBottom: "20px",
                }}
              >
                ₦ {shippingCharge}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Button variant="contained" className={classes.continueButton} type='submit'>
          {" "}
          Continue to Questionnaire
        </Button>
      </form>
    </Grid>
  );
};
