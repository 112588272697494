import {
  Button,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlogImage from "../../../assets/images/BlogImage.png";
import ButtonArrow from "../../../assets/images/ButtonArrow.svg";
import chevronDown from "../../../assets/images/chevronDown.svg";
import factsLooper from "../../../assets/images/factsLooper.svg";
import styles from "./styles";
// import HLF from "../../../assets/images/HairF.png";
import { useNavigate } from "react-router-dom";
import COD from "../../../assets/images/ColdF.png";
import CVF from "../../../assets/images/COVID-19F.png";
import DIE from "../../../assets/images/DaibeteF.png";
import EDF from "../../../assets/images/EDF.png";
import EVF from "../../../assets/images/energy-and-vigorF.png";
import FTF from "../../../assets/images/fitnessBGF.png";
import HLF from "../../../assets/images/HairFact.png";
import HHF from "../../../assets/images/HeartHypertensionF.png";
import HHGF from "../../../assets/images/HeartWGF.png";
import HFMR from "../../../assets/images/HFMR.png";
import HIVF from "../../../assets/images/HIVF.png";
import HL1 from "../../../assets/images/HL1.png";
import HGF from "../../../assets/images/HormoneWGF.png";
import KFF from "../../../assets/images/KidneyFailuresF.png";
import LIF from "../../../assets/images/LiverBGF.png";
import MLF from "../../../assets/images/MalariaF.png";
import MBR from "../../../assets/images/MBR.png";
import MPF from "../../../assets/images/menopauseF.png";
import NTF from "../../../assets/images/NutritionBGF.png";
import PCW from "../../../assets/images/PCOSBGF.png";
import PEF from "../../../assets/images/PEF.png";
import PCF from "../../../assets/images/PremaritalCheckF.png";
import PRF from "../../../assets/images/ProstateBGF.png";
import SPF from "../../../assets/images/sportBGF.png";
import STF from "../../../assets/images/STIF.png";
import SBF from "../../../assets/images/StressF.png";
import SGF from "../../../assets/images/StressWGF.png";
import THF from "../../../assets/images/thyroidF.png";
import TYP1 from "../../../assets/images/TYP1.png";
import WLF from "../../../assets/images/weightlossBGF.png";
import WBF from "../../../assets/images/WellnessBGF.png";
import WGF from "../../../assets/images/WellnessWGF.png";
import {
  COLD_COUGH_OR_FEVER,
  COVID_ROUTE_PATH,
  DIABETES_ROUTE_PATH,
  ED_ROUTE_PATH,
  ENERGY_AND_VIGOR,
  FITNESS_AND_NDURANCE,
  HEART_AND_HYPERTENSION,
  HEART_AND_HYPERTENSIONW,
  HIV_ROUTE_PATH,
  HL_ROUTE_PATH,
  HORMONE_AND_FERTILITY,
  HORMONE_AND_FERTILITYW,
  KIDNEY_FAILURES,
  LIVER_ROUTE_PATH,
  MALARIA_ROUTE_PATH,
  MENOPAUSE_ROUTE_PATH,
  MOTHER_AND_CHILD,
  NUTRITION_ROUTE_PATH,
  PCOS_ROUTE_PATH,
  PE_ROUTE_PATH,
  PREMARITAL_CHECK,
  PROSTATE_ROUTE_PATH,
  SPORTS_ROUTE_PATH,
  STIs_ROUTE_PATH,
  STRESS_MANAGEMENT,
  STRESS_MANAGEMENTW,
  THYROID_ROUTE_PATH,
  TYPHOID_ROUTE_PATH,
  WEIGHT_MANAGEMENT,
  WELLNESS_ROUTE_PATHE,
  WELLNES_ROUTE_PATHE,
} from "../../common/utils";
const useStyles = makeStyles((theme) => styles(theme));

const DropdownIcon = () => <img src={chevronDown} alt="Down Arrow" />;

const HeaderMenu = () => {
  const classes = useStyles();
  const theme = useTheme();
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorDropdown, setanchorDropdown] = useState(null);
  const [mousein, setMouseIn] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [value, setValue] = useState(0);
  const [currentMenu, setCurrentMenu] = useState("Men’s Health");
  const [currentSubMenu, setCurrentSubMenu] = useState("");
  const [OpenDropDown, setOpenDropdown] = useState(false);
  const [subMenuFacts, setSubMenuFacts] = useState([]);
  const [factsRoutePath, setFactsRouthPath] = useState("");

  const [subMenuItem, setSubMenuItems] = useState([]);
  const [currentBg, setCurrentBG] = useState("");

  const [openSubMenu, setOpenSubMenu] = useState(false);
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMDUP = useMediaQuery(theme.breakpoints.up("md"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXL = useMediaQuery(theme.breakpoints.down("xl"));
  const custommatchesXL = useMediaQuery("(min-width:1500px)");

  const handleClick = (e) => {
    setCurrentMenu(e.target.innerText);
    if (e.target.innerText !== "") {
      setOpenMenu(true);
    }
    setAnchorEl(e.currentTarget);
    e.target.style.fontWeight = "600";
    e.target.style.fontFamily = "Inter";
    setMouseIn(e.target);
    setCurrentSubMenu("");
  };

  console.log(DIABETES_ROUTE_PATH, "DIABETES_ROUTE_PATH");
  console.log(MOTHER_AND_CHILD, "MOTHER_AND_CHILD");
  const handleOnClick = (event) => {
    event.preventDefault();
    console.log(event, " handleOnClick");
    navigate(`${DIABETES_ROUTE_PATH}`);
    // history.push(`${ MOTHER_AND_CHILD}`)
    // setCurrentMenu(event.target.innerText)
    // setCurrentMenu(true)
    // console.log(setCurrentMenu,"setCurrentMenu")

    // if (event.target.innertext === "Diabetes " && "Mother and Child"){
    //   setOpenMenu(true)

    // }
    // setanchorDropdown(false);
    // setOpenDropdown(false);
    // setOpenMenu(false);
    // setMouseIn(false);
    // setSubMenu(false)
  };
  const handleOnClick1 = (event) => {
    event.preventDefault();
    console.log(event, " handleOnClick");
    // history.push(`${DIABETES_ROUTE_PATH}`)
    navigate(`${MOTHER_AND_CHILD}`);
    // setCurrentMenu(event.target.innerText)
    // setCurrentMenu(true)
    // console.log(setCurrentMenu,"setCurrentMenu")

    // if (event.target.innertext === "Diabetes " && "Mother and Child"){
    //   setOpenMenu(true)

    // }
    // setanchorDropdown(false);
    // setOpenDropdown(false);
    // setOpenMenu(false);
    // setMouseIn(false);
    // setSubMenu(false)
  };
  // const handleOnClick = (event) => {
  //   event.preventDefault()
  //   console.log( event," handleOnClick")
  //
  // }

  const handleMenuOpen = (index, event, menuItem) => {
    console.log(menuItem.name, "menuItem.name");
    setCurrentMenu(menuItem.name);
    setAnchorEl(event.currentTarget);
    setValue(index);
    {
      menuItem.name !== "Diabetes" && menuItem.name !== "Mother and Child"
        ? setOpenMenu(true)
        : setOpenMenu(false);
    }
    console.log(openMenu, menuItem.name, "menuItem.name");
    setSubMenuItems(menuItem.options);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
    // window.location.reload(false)
  };
  const handleMenuClose1 = () => {
    setAnchorEl(null);
    setOpenMenu(false);
    window.location.reload(false);
  };

  const getIcon = (menu) => {
    return (
      <div
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setCurrentMenu(menu.name);
          setOpenMenu(!openMenu);
          setCurrentSubMenu("");
        }}
      >
        {" "}
        <DropdownIcon />
      </div>
    );
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleSubmenuOpen = (e) => {
    setCurrentMenu(e.target.innerText);
    setOpenMenu(!openMenu);
    setAnchorEl(e.currentTarget);
  };
  const handleSubmenuClose = (e) => {
    setAnchorEl(null);
    setCurrentMenu("");
    setOpenMenu(false);
    handleCloseDropDown();
  };

  const handleClose = (e) => {
    e.target = mousein;
    e.target.style.fontWeight = "400";
    e.target.style.fontFamily = "Visuelt Pro";
    window.scroll(0, 0);
    setAnchorEl(null);
    setCurrentMenu("");
    setOpenMenu(false);
    setMouseIn(false);
  };

  const handleClickDropdown = (e) => {
    setanchorDropdown(e.currentTarget.innerText);
    if (e.currentTarget.innerText === "") {
      setOpenDropdown(false);
    } else {
      setOpenDropdown(true);
    }
  };

  const handleCloseDropDown = (e) => {
    setanchorDropdown(null);
    setOpenDropdown(false);
    setOpenMenu(false);
    setMouseIn(false);
  };

  const headerMenu = [
    {
      id: 0,
      name: "Men’s Health",
      ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,

      mouseOver: (event) => handleClick(event),
      options: [
        /* "Wellness",
        "Hormone and Fertility",
        "Prostate",
        
        "Pre-Marital Check",
        
        "Heart & Hypertension",
        "Stress Management", */
        {
          name: "Wellness",
          routePath: WELLNESS_ROUTE_PATHE,
          facts: [
            `How are you feeling? We all have times when we wonder if everything is ok with our health - and as men, we're famous for not checking things out until it's too late. so We offer a series of packages that can check a wide range of issues for any man that wants to take control of his health.`,
            // `Men's wellness tests ensure that all your main body organs work correctly and check your hormone levels.`,
          ],
          bg: WBF,
        },
        {
          name: "Hormone & Fertility",
          routePath: HORMONE_AND_FERTILITY,
          facts: [
            `Are you concerned that your hormone levels may be causing some other health condition or illness? Do you want to start a family, but you and your partner are experiencing problems conceiving? These aren't just "women's" issues. Let us help you get some clarity and reassurance with these health packages.`,
            // `We'll run a panel of tests to get details of your hormone levels. Regardless of which package you choose, you'll get answers to your concerns.`,
          ],
          bg: HFMR,
        },
        {
          name: "Prostate",
          routePath: PROSTATE_ROUTE_PATH,
          facts: [
            `The prostate is a small walnut-shaped gland found only in men. It creates a thick fluid that mixes with sperm to create semen`,
            `Prostate cancer is one of the leading cancers in men. Therefore regular checks are recommended - especially once over the age of 50. It also adversely impacts black men, though the reasons for that have not yet been identified. Being obese also increases the risk of developing prostate cancer.`,
            // `Some types of prostate cancer grow slowly while others progress aggressively and may require radiation.`,
          ],
          bg: PRF,
        },
        {
          name: "Hair Loss",
          routePath: HL_ROUTE_PATH,
          facts: [
            `Did you know that we lose between 50-100 hairs a day, usually without worrying the slightest about it? `,
            `Although that is normal and nothing to be concerned about, some hair loss can be more severe and permanent. 

            And no, hair loss does not just affect men, and it can affect generation after generation of the same family.
            `,
          ],
          bg: HLF,
        },
        {
          name: "Premarital Check",
          routePath: PREMARITAL_CHECK,
          facts: [
            `If you are getting married, you may want to make sure that you and your partner are aware of any potential health problems that both of you may not already know of.`,
            `This package of tests provides reassurance and ensures that you are both aware of any potential illnesses that could also impact plans for children. This helps you plan accordingly and have options for your future together.`,
          ],
          bg: PCF,
        },
        {
          name: "Erectile Dysfunction",
          routePath: ED_ROUTE_PATH,
          facts: [
            `Erectile dysfunction (ED) is quite common, typically seen in men over 40.`,
            `It is not unusual at any age to occasionally not be able to achieve an erection. Various reasons can cause this, from stress, anxiety, tiredness, or excessive alcohol intake.`,
            `If it happens often, it is worth speaking to a doctor to investigate the cause and discuss potential treatments to resolve the problem. `,
          ],
          bg: EDF,
        },
        {
          name: "Heart & Hypertension",
          routePath: HEART_AND_HYPERTENSION,
          facts: [
            `High blood pressure is also known as hypertension. `,
            `Blood pressure is the measure of the force created by pushing blood against the walls of your arteries, which are the major vessels moving blood throughout your body.`,
            `The measure is made up of two numbers. The first is the measure of the pressure when your heart beats or contracts (called 'systolic'), the second number (called diastolic) is the pressure when the heart rests between the beats. For example, if your blood pressure is taken - you may be told it is "120 over 80" or 120/80mmHg.`,
            `If your reading is 140/90mmHg or more, you're diagnosed with high blood pressure. Readings between 120/80mmHg - 140/90mmHg mean that you could develop high blood pressure unless you take action to control it.`,
          ],
          bg: HHF,
        },
        {
          name: "Premature Ejaculation",
          routePath: PE_ROUTE_PATH,
          facts: [
            `Like erectile dysfunction, premature ejaculation is experienced by most men occasionally. Research shows that about one in five male adults between 18-59 experience premature ejaculation.`,
            `If you find that more than half of your sexual encounters result in ejaculating sooner than desired, please book an appointment with one of our doctors. They will investigate and offer various treatment options.

            `,
            // `Premature ejaculation often causes stress for couples.`,
            // `Therapy can help most men delay ejaculation.`,
          ],
          bg: PEF,
        },
        {
          name: "Stress Management",
          routePath: STRESS_MANAGEMENT,
          facts: [
            `
            There's no exact medical definition of stress - that's because what causes it and how it affects each person can be different. It can generally occur when events or different situations put us under pressure. It also relates to our reaction to said pressure.
            `,
            // `While stress is a normal part of everyone's life, managing stress effectively is key to living a  healthy life.`,
          ],
          bg: SBF,
        },
      ],
    },
    {
      id: 1,
      name: "Women’s Health",
      ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,

      mouseOver: (event) => handleClick(event),
      options: [
        // "Wellness",
        // "Hormone and Fertility",
        // "PCOS",
        // "Hair Loss",
        // "Pre-Marital Check",
        // "Menopause",
        // "Heart & Hypertension",
        // "Stress Management",
        {
          name: "Wellness",
          routePath: WELLNES_ROUTE_PATHE,
          facts: [
            `How are you feeling today? We all have times when we wonder if everything is ok with our health, so we offer a series of packages that can check out a wide range of issues for any women who wants to take control of their health.`,
            // `Women's wellness tests to ensure all your main body organs are working properly and check your hormone levels.`,
          ],
          bg: WGF,
        },
        {
          name: "Hormone & Fertility",
          routePath: HORMONE_AND_FERTILITYW,
          facts: [
            `Are you concerned that your hormone levels may be causing some other health condition or illness? Do you want to start a family, but you and your partner are experiencing problems conceiving? Let us help you get some clarity and reassurance with these health packages.`,
            // `We'll run a panel of tests to get details of your hormone levels. Regardless of which package you choose, you'll get answers to your concerns.`,
          ],
          bg: HGF,
        },
        {
          name: "PCOS",
          routePath: PCOS_ROUTE_PATH,
          facts: [
            `This condition is usually characterised by irregular periods, hair growth in strange places, weight gain, thinning hair, or hair loss on the head.`,
            `Over half of women affected do not have any symptoms and are diagnosed when they have problems getting pregnant.`,
          ],
          bg: PCW,
        },
        {
          name: "Hair Loss",
          routePath: HL_ROUTE_PATH,
          facts: [
            `Although we lose between 50-100 hair a day which is very normal, some hair loss can be more severe and permanent. `,
            `It affects both men and women and can affect generation after generation of the same family but there is help available.`,
          ],
          bg: HL1,
        },
        {
          name: "Premarital Check",
          routePath: PREMARITAL_CHECK,
          facts: [
            `If you're getting married, you may want to make sure that you and your partner are aware of any potential health problems that both of you may not already know of.`,
            `This package of tests provides reassurance and ensures that you are both aware of any potential illnesses that could also impact plans for children.`,
          ],
          bg: PCF,
        },
        {
          name: "Heart & Hypertension",
          routePath: HEART_AND_HYPERTENSIONW,
          facts: [
            `High blood pressure or hypertension rarely has warning signs or symptoms, but it can lead to heart attacks and strokes when left untreated.`,
            `Your doctor will recommend treatments to bring your blood pressure down depending on your health history.`,
          ],
          bg: HHGF,
        },
        {
          name: "Menopause",
          routePath: MENOPAUSE_ROUTE_PATH,
          facts: [
            `Menopause is a normal part of a woman's life between 45 and 55. When a woman's periods stop - this typically starts with them becoming less frequent over some months or even years before stopping completely.`,
            `There are also occasions when they stop suddenly.`,
          ],
          bg: MPF,
        },
        {
          name: "Stress Management",
          routePath: STRESS_MANAGEMENTW,
          facts: [
            `Keeping stress levels at a minimum can help you live a more balanced life. Stress is an automatic physical, mental, and emotional response to a challenging event.`,
            `While stress is a normal part of everyone's life, managing stress effectively is key to living a  healthy life.`,
          ],
          bg: SGF,
        },
      ],
    },
    {
      id: 2,
      name: "Chronic Conditions",
      ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,

      mouseOver: (event) => handleClick(event),
      options: [
        // "Thyroid",
        // "Diabetes",
        // "PCOS",
        // "Kidney",
        // "Liver",
        // "HIV",
        // "Hair Loss",
        // "Prostate",
        // "Heart & Hypertension",
        // "Stress Management",
        {
          name: "Thyroid",
          routePath: THYROID_ROUTE_PATH,
          facts: [
            `The thyroid is a small gland in your neck that produces hormones that influence most of your metabolic processes, body temperature, and heart rate.`,
            `It is an essential part of your body. There are a variety of illnesses that can happen when it is not functioning as planned.`,
          ],
          bg: THF,
        },
        {
          name: "Diabetes",
          routePath: DIABETES_ROUTE_PATH,
          facts: [
            `While there are different types, diabetes is characterised by high sugar levels in the blood due to insulin malfunction.`,
            `It is a chronic disease, but with lifestyle modifications and medications, diabetes can be properly managed.`,
          ],
          bg: DIE,
        },
        {
          name: "PCOS",
          routePath: PCOS_ROUTE_PATH,
          facts: [
            `This condition is usually characterised by irregular periods, hair growth in strange places, weight gain, thinning hair, or hair loss on the head.`,
            `Over half of women affected do not have any symptoms and are diagnosed when they have problems getting pregnant.`,
          ],
          bg: PCW,
        },
        {
          name: "Kidney Failures",
          routePath: KIDNEY_FAILURES,
          facts: [
            `
            Your kidneys are crucial to keeping you healthy as they remove waste and extra fluid from your body - creating urine that you then pass. They also make hormones that help keep your bones strong and manage your blood pressure. 
            `,
            // `Kidney failure is a condition in which one or both kidneys can no longer work on their own. This condition is also called renal failure.`,
          ],
          bg: KFF,
        },
        {
          name: "Liver",
          routePath: LIVER_ROUTE_PATH,
          facts: [
            `Your liver is a large body organ that sits on the right side of your belly. Its main job is to help you digest food and cleanse your body of toxic substances. Some liver problems can be genetic, while others are caused by issues such as alcohol usage, being overweight, or some viruses.`,
            // `Some liver problems can be genetic, while others are caused by issues such as alcohol usage, being overweight, or some viruses.`,
          ],
          bg: LIF,
        },
        {
          name: "HIV",
          routePath: HIV_ROUTE_PATH,
          facts: [
            `HIV stands for Human Immunodeficiency Virus. This means that the virus weakens the immune system and compromises its ability to fight other illnesses. BUT with effective treatment through daily medication, most people have a normal life and can not pass it on to other people.`,
            // `With effective treatment through daily medication, most people live a normal life and can not pass it on to other people.`,
          ],
          bg: HIVF,
        },

        {
          name: "Hair Loss",
          routePath: HL_ROUTE_PATH,
          facts: [
            `Although we lose between 50-100 hair a day which is very normal, some hair loss can be more severe and permanent. `,
            `It affects both men and women and can affect generation after generation of the same family but there is help available.`,
          ],
          bg: HLF,
        },
        {
          name: "Prostate",
          routePath: PROSTATE_ROUTE_PATH,
          facts: [
            `Prostate cancer is one of the most common cancers in men and rarely has any symptoms until it has grown large enough to pressure the urethra that carries urine from the bladder.`,
            `Some types of prostate cancer grow slowly while others progress aggressively and may require radiation.`,
          ],
          bg: PRF,
        },
        {
          name: "Heart & Hypertension",
          routePath: HEART_AND_HYPERTENSIONW,
          facts: [
            `High blood pressure or hypertension rarely has warning signs or symptoms, but it can lead to heart attacks and strokes when left untreated.`,
            `Your doctor will recommend treatments to bring your blood pressure down depending on your health history.`,
          ],
          bg: HHGF,
        },
        {
          name: "Stress Management",
          routePath: STRESS_MANAGEMENTW,
          facts: [
            `Keeping stress levels at a minimum can help you live a more balanced life. Stress is an automatic physical, mental, and emotional response to a challenging event.`,
            `While stress is a normal part of everyone's life, managing stress effectively is key to living a  healthy life.`,
          ],
          bg: SGF,
        },
      ],
    },
    {
      id: 3,
      name: "Common Condition",
      ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,

      mouseOver: (event) => handleClick(event),
      options: [
        {
          name: "Malaria",
          routePath: MALARIA_ROUTE_PATH,
          facts: [
            `Malaria potentially life-threatening disease is spread to humans by mosquito bites. A single mosquito bite is enough to become infected with malaria.`,
            `The good news is that it is preventable and curable, particularly if treated quickly once symptoms appear.`,
          ],
          bg: MLF,
        },

        {
          name: "Typhoid",
          routePath: TYPHOID_ROUTE_PATH,
          facts: [
            `Typhoid is a life-threatening bacterial infection spread through contaminated food and water. It is highly contagious and can pass from person to person very quickly.`,
            // `Typhoid is treatable with antibiotics and medical care.`,
          ],
          bg: TYP1,
        },

        {
          name: "Cold, Cough or Fever",
          routePath: COLD_COUGH_OR_FEVER,
          facts: [
            `The common cold is caused by a virus that enters your body through the nose or mouth.`,
            `It affects your nose and throat, and while it's not life-threatening, the common cold can make you feel dreadful for up to 10 days.`,
          ],
          bg: COD,
        },
        // {
        //   name: "Thyroid",
        //   routePath: THYROID_ROUTE_PATH,
        //   facts: [
        //     `The thyroid is a small gland in your neck that produces hormones that influence most of your metabolic processes, body temperature, and heart rate.`,
        //     `It is an essential part of your body. There are a variety of illnesses that can happen when it is not functioning as planned.`,
        //   ],
        //   bg: THF,
        // },
        // {
        //   name: "Cold, Cough and Fever",
        //   routePath: COLD_COUGH_OR_FEVER,
        //   facts: [
        //     `Hair may be lost before it’s noticeable.`,
        //     `It’s normal to lose 50-100 strands of hair a day.`,
        //     `Surgical hair restoration is socially acceptable.`,
        //     `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
        //     `Hair loss can be easily treated.`,
        //   ],
        // },
        {
          name: "Erectile Dysfunction",
          routePath: ED_ROUTE_PATH,
          facts: [
            `It is not unusual at any age to occasionally not be able to achieve an erection but Erectile Dysfunction (ED) is typically seen in men over 40.`,
            `Various reasons can cause this, from stress, anxiety, tiredness, or excessive alcohol intake.`,
          ],
          bg: EDF,
        },
        {
          name: "STIs",
          routePath: STIs_ROUTE_PATH,
          facts: [
            `More than 30 different viruses, parasites, and bacteria spread through sexual activity. These sexually transmitted infections (STIs) have specific symptoms and potential treatment routes.`,
            `Though specific symptoms for each STI vary, the majority of them affect the genitals of both genders.`,
          ],
          bg: STF,
        },
        // {
        //   name: "HIV",
        //   routePath: HIV_ROUTE_PATH,
        //   facts: [
        //     `HIV means Human Immunodeficiency Virus. This means that the virus weakens the immune system and compromises its ability to fight other illnesses.`,
        //     `With effective treatment through daily medication, most people live a normal life and can not pass it on to other people.`,
        //   ],
        //   bg: HIVF,
        // },

        {
          name: "Covid-19",
          routePath: COVID_ROUTE_PATH,
          facts: [
            `Coronavirus is an acute infectious illness caused by the Covid-19 (SARS-CoV-2) virus.`,
            `Initially identified in China in late December 2019, the disease is capable of causing mild and severe symptoms and, in some cases, death, especially in aged people or those with underlying health conditions.`,
          ],
          bg: CVF,
        },
      ],
    },
    {
      id: 4,
      name: "Sexual Health",
      ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,

      mouseOver: (event) => handleClick(event),
      options: [
        {
          name: "Erectile Dysfunction",
          routePath: ED_ROUTE_PATH,
          facts: [
            `It is not unusual at any age to occasionally not be able to achieve an erection but Erectile Dysfunction (ED) is typically seen in men over 40.`,
            `Various reasons can cause this, from stress, anxiety, tiredness, or excessive alcohol intake.`,
          ],
          bg: EDF,
        } /*  "STIs", "HIV" */,
        {
          name: "STIs",
          routePath: STIs_ROUTE_PATH,
          facts: [
            `More than 30 different viruses, parasites, and bacteria spread through sexual activity. These sexually transmitted infections (STIs) have specific symptoms and potential treatment routes.`,
            `Though specific symptoms for each STI vary, the majority of them affect the genitals of both genders.`,
          ],
          bg: STF,
        },

        {
          name: "Premature Ejaculation",
          routePath: PE_ROUTE_PATH,
          facts: [
            `Research shows that about one in five male adults between 18-59 experience Premature Ejaculation.`,
            `If you find that more than half of your sexual encounters result in ejaculating sooner than desired, there are solutions to help.`,
          ],
          bg: PEF,
        },

        {
          name: "HIV",
          routePath: HIV_ROUTE_PATH,
          facts: [
            `HIV means Human Immunodeficiency Virus. This means that the virus weakens the immune system and compromises its ability to fight other illnesses.`,
            `With effective treatment through daily medication, most people live a normal life and can not pass it on to other people.`,
          ],
          bg: HIVF,
        },
      ],
    },
    {
      id: 5,
      name: "Lifestyle & Health",
      ariaOwns: anchorEl ? "fullScreenmenu" : undefined,
      ariaPopup: anchorEl ? "true" : undefined,

      mouseOver: (event) => handleClick(event),
      options: [
        // "Wellness",
        // "Nutrition",
        // "Fitness & Endurance",
        // "Sport",
        // "Weight Management",
        // "Energy & Vigor",
        // "Stress Management",
        {
          name: "Wellness",
          routePath: WELLNES_ROUTE_PATHE,
          facts: [
            `We offer a series of packages that check out a wide range of issues for any woman who wants to take control of her health.`,
            `Women's wellness tests to ensure all your main body organs are working properly and check your hormone levels.`,
          ],
          bg: WGF,
        },
        {
          name: "Nutrition",
          routePath: NUTRITION_ROUTE_PATH,
          facts: [
            `It is essential to nourish your body with food and drinks to stay healthy and function properly.`,
            `Nutrition is crucial to your survival because it supplies your body with the macro and micronutrients that fuel your body and keeps you fit and strong.`,
          ],
          bg: NTF,
        },
        {
          name: "Fitness & Endurance",
          routePath: FITNESS_AND_NDURANCE,

          facts: [
            `Your endurance level is the rate at which your lungs, heart, and muscles work together when you are exercising.`,
            `It is essential to know your endurance level and how your cardiorespiratory system functions because it indicates how fit you are.`,
          ],
          bg: FTF,
        },
        {
          name: "Sport",
          routePath: SPORTS_ROUTE_PATH,
          facts: [
            `Staying active with sporting activities has very many health benefits for your body.`,
            `Engaging in sports makes you physically fit and healthy. It helps to mentally and physically challenge you positively.`,
          ],
          bg: SPF,
        },
        {
          name: "Weight Management",
          routePath: WEIGHT_MANAGEMENT,
          facts: [
            `These are the deliberate techniques and psychological processes taken to attain and maintain a certain weight. One of the critical factors of weight management is adjustment and change in lifestyle.`,
            `Eating healthy and exercising are very important to achieving and maintaining the body goal you desire.`,
          ],
          bg: WLF,
        },
        {
          name: "Energy & Vigor",
          routePath: ENERGY_AND_VIGOR,
          facts: [
            `Doctall provides tests tailored to check your energy levels to help you alleviate stress and manage the risk of health challenges such as diabetes, cancer, and heart diseases.`,
            `The package also provides you with energy-boosting vitamins that you need to stay strong, full of energy, and ready to go.`,
          ],
          bg: EVF,
        },
        {
          name: "Stress Management",
          routePath: STRESS_MANAGEMENTW,
          facts: [
            `Keeping stress levels at a minimum can help you live a more balanced life. Stress is an automatic physical, mental, and emotional response to a challenging event.`,
            `While stress is a normal part of everyone's life, managing stress effectively is key to living a  healthy life.`,
          ],
          bg: SGF,
        },
      ],
    },
    //   mouseOver: (event) => handleClick(event),
    //   options: [
    //     // "Wellness",
    //     // "Nutrition",
    //     // "Fitness & Endurance",
    //     // "Sport",
    //     // "Weight Management",
    //    // "Energy & Vigor",
    //   //  "Stress Management",

    //     {
    //       name: "Stress Management",
    //       facts: [
    //         `Hair may be lost before it’s noticeable.`,
    //         `It’s normal to lose 50-100 strands of hair a day.`,
    //         `Surgical hair restoration is socially acceptable.`,
    //         `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
    //         `Hair loss can be easily treated.`,
    //       ],
    //     },
    //     {
    //       name: "Menopause",
    //       facts: [
    //         `Hair may be lost before it’s noticeable.`,
    //         `It’s normal to lose 50-100 strands of hair a day.`,
    //         `Surgical hair restoration is socially acceptable.`,
    //         `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
    //         `Hair loss can be easily treated.`,
    //       ],
    //     },
    //     {
    //     name: "",
    //     facts: [
    //       `Hair may be lost before it’s noticeable.`,
    //       `It’s normal to lose 50-100 strands of hair a day.`,
    //       `Surgical hair restoration is socially acceptable.`,
    //       `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
    //       `Hair loss can be easily treated.`,
    //     ],
    //   },
    //   {
    //     name: "Cold, Cough or Fever",
    //     facts: [
    //       `Hair may be lost before it’s noticeable.`,
    //       `It’s normal to lose 50-100 strands of hair a day.`,
    //       `Surgical hair restoration is socially acceptable.`,
    //       `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
    //       `Hair loss can be easily treated.`,
    //     ],
    //   },
    //   {
    //     name: "Malaria",
    //     facts: [
    //       `Hair may be lost before it’s noticeable.`,
    //       `It’s normal to lose 50-100 strands of hair a day.`,
    //       `Surgical hair restoration is socially acceptable.`,
    //       `Thyroid imbalance and iron deficiency are reversible causes for hair loss`,
    //       `Hair loss can be easily treated.`,
    //     ],
    //   },

    //   ],
    // },
    {
      id: 6,
      name: "Diabetes",
      ariaOwns: undefined, //anchorEl ? "fullScreenmenu" : undefined,
      ariaPopup: undefined, //anchorEl ? "true" : undefined,
      // path : DIABETES_ROUTE_PATH,
      onClick: (event) => handleOnClick(event),
      // {currentMenu}
      // Facts Need To Update
      options: [
        {
          name: "Diabetes",
          routePath: DIABETES_ROUTE_PATH,
          facts: [
            `While there are different types, diabetes is characterised by high sugar levels in the blood due to insulin malfunction.`,
            `It is a chronic disease, but with lifestyle modifications and medications, diabetes can be properly managed.`,
          ],
          bg: DIE,
        },
        // {
        //   name: "Heart & Hypertension",
        //   routePath: HEART_AND_HYPERTENSION,
        //   facts: [
        //     `High blood pressure or hypertension rarely has warning signs or symptoms, but it can lead to heart attacks and strokes when left untreated.`,
        //     `Your doctor will recommend treatments to bring your blood pressure down depending on your health history.`,
        //   ],
        //   bg: HHF,
        // },
        // {
        //   name: "Kidney Failures",
        //   routePath: KIDNEY_FAILURES,
        //   facts: [
        //     `Your kidneys are crucial to keeping you healthy as they remove waste and extra fluid from your body - creating urine that you then pass out.`,
        //     `Kidney failure is a condition in which one or both kidneys can no longer work on their own. This condition is also called renal failure.`,
        //   ],
        //   bg: KFF,
        // },
      ],
    },

    {
      id: 7,
      name: "Mother and Child",
      ariaOwns: undefined, //anchorEl ? "fullScreenmenu" : undefined,
      ariaPopup: undefined, //anchorEl ? "true" : undefined,

      onClick: (event) => handleOnClick1(event),
      options: [
        {
          name: "Mother and Child",
          routePath: MOTHER_AND_CHILD,
          facts: [
            "Doctall offer tests to  keep you aware of what is happening with your child's health as well as your own body as a new mother.",
          ],
          bg: MBR,
        },
      ],
    },
  ];

  const menuBlog = [
    {
      title: "What foods are high in vitamin",
      desc: "How do you create compelling presentations that wow...",
      image: BlogImage,
    },
    {
      title: "What foods are high in vitamin",
      desc: "How do you create compelling presentations that wow...",
      image: BlogImage,
    },
  ];

  useEffect(() => {
    try {
      let _requiredFacts = headerMenu.filter(
        (menu) => menu.name === currentMenu
      );
      let _defaultFacts =
        _requiredFacts &&
        _requiredFacts[0].options &&
        _requiredFacts[0].options[0].facts;
      setSubMenuFacts(_defaultFacts);
      setCurrentSubMenu(_requiredFacts[0].options[0].name);
      setCurrentBG(_requiredFacts[0].options[0].bg);
      setFactsRouthPath(_requiredFacts[0].options[0].routePath);
    } catch (error) {
      console.log(error);
    }
  }, [currentMenu]);

  const handleSubMenus = (option) => {
    setCurrentSubMenu(option.name);
    setSubMenuFacts(option.facts);
    // console.log(option.routePath)
    setCurrentBG(option.bg);
    setFactsRouthPath(option.routePath);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleCloseDropDown);
    return () => window.removeEventListener("scroll", handleCloseDropDown);
  }, []);

  return (
    <React.Fragment>
      {
        matchesMDUP ? (
          <>
            <div
              className={classes.tabContainer}
              onMouseLeave={handleMenuClose}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                className={classes.tabContainer}
                indicatorColor="primary"
              >
                {headerMenu.map((menu, index) => (
                  <>
                    {console.log(menu, "menu")}
                    {console.log(menu.name, "menu.name")}
                    {menu.name !== "Diabetes" &&
                    menu.name !== "Mother and Child" ? (
                      <Tab
                        key={`${menu}${index}`}
                        label={menu.name}
                        component={Link}
                        to=""
                        icon={getIcon(menu)}
                        aria-owns={openMenu ? "menu-list-grow" : undefined}
                        aria-haspopup={"true"}
                        aria-controls={menu.ariaOwns}
                        // aria-owns={menu.ariaOwns}
                        // aria-haspopup={menu.ariaPopup}
                        selected={mousein}
                        onMouseEnter={(e) => handleMenuOpen(index, e, menu)}
                        classes={{
                          wrapper: classes.tabIconWrapper,
                        }}
                        // style={{
                        //   marginRight: custommatchesXL ? "4rem" : undefined,
                        // }}
                      />
                    ) : (
                      <Tab
                        key={`${menu}${index}`}
                        label={menu.name}
                        component={Link}
                        to={
                          menu.name == "Diabetes"
                            ? `/${DIABETES_ROUTE_PATH}`
                            : menu.name == "Mother and Child"
                            ? `/${MOTHER_AND_CHILD}`
                            : " "
                        }
                        classes={{
                          wrapper: classes.tabIconWrapper,
                        }}
                        onMouseEnter={handleMenuClose}
                        // style={{
                        //   marginRight: matchesXL ? "1rem" : undefined,
                        // }}
                      ></Tab>
                    )}
                  </>
                ))}
              </Tabs>

              <Popper
                open={openMenu}
                anchorEl={anchorEl}
                id="menu-list-grow"
                placement="bottom-end"
                style={{
                  marginTop: custommatchesXL || matchesXL ? "230px" : undefined,
                  width: "100%",
                  marginLeft: custommatchesXL || matchesXL ? "-5px" : undefined,
                }}
              >
                <Paper>
                  <MenuList
                    classes={{ paper: classes.menu }}
                    id="fullScreenmenu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom-end",
                      horizontal: "left",
                    }}
                    /* transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }} */
                    disableScrollLock={true}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      onMouseLeave: handleMenuClose,
                      style: {
                        padding: 0,
                      },
                    }}
                    elevation={0}
                    style={{ zIndex: 1302 }}
                    keepMounted
                    PaperProps={{
                      style: {
                        width: "100%",
                        marginTop: "15px",
                        marginRight: "16px",
                      },
                    }}

                    //PopoverClasses={{ paper: classes.popoverPaper }}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item className={classes.menuItemContainer} md>
                        <Typography className={classes.menuHeading}>
                          {currentMenu}
                        </Typography>
                        {subMenuItem &&
                          subMenuItem.length > 0 &&
                          subMenuItem.map((item, index) => (
                            <MenuItem
                              key={`${item}${index}`}
                              className={
                                item.name === currentSubMenu
                                  ? classes.currentSubMenu
                                  : classes.menuItem
                              }
                              component={Link}
                              to={`/${item.routePath}`}
                              disableRipple
                              onMouseOver={() => handleSubMenus(item)}
                              // onMouseLeave={() => setOpenMenu(false)}
                              onClick={handleMenuClose}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                      </Grid>
                      <Grid item md={7}>
                        <Grid
                          className={classes.factsContainer}
                          md
                          container
                          direction="row"
                        >
                          {subMenuFacts && subMenuFacts.length > 0 && (
                            <Grid item md style={{ width: "532px" }}>
                              <Grid item direction="column" container>
                                <Typography
                                  style={{
                                    fontFamily: "Visuelt Pro",
                                    fontWeight: "700",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "#FF5C3C",
                                    paddingTop: "28.7px",
                                    paddingBottom: "14px",
                                  }}
                                >
                                  SUMMARY
                                </Typography>

                                {subMenuFacts &&
                                  subMenuFacts.length > 0 &&
                                  subMenuFacts.map((fact, index) => (
                                    <>
                                      <Grid
                                        item
                                        style={{ width: "474px" }}
                                        key={`${fact}${index}`}
                                      >
                                        <Typography
                                          style={{
                                            fontFamily: "Visuelt Pro",
                                            fontWeight: "normal",
                                            fontStyle: "normal",
                                            fontSize: "18px",
                                            lineHeight: "28px",
                                            color: "#002931",
                                            paddingBottom: "8px",
                                          }}
                                        >
                                          {/* {" "}
                                          <img
                                            src={factsBullet}
                                            alt="list"
                                          />{" "}
                                          &nbsp; */}
                                          {fact}
                                        </Typography>
                                      </Grid>
                                    </>
                                  ))}
                                <Grid align="left">
                                  <Button
                                    variant="contained"
                                    disableRipple
                                    style={{
                                      color: "#fff",
                                      backgroundColor: "#FF5C3C",
                                      padding: "8px 14px",
                                      fontSize: "14px",
                                      borderRadius: "8px",
                                    }}
                                    onClick={() => {
                                      navigate("/" + factsRoutePath);
                                      // setOpenMenu(openMenu);
                                      setAnchorEl(null);
                                      setOpenMenu(false);
                                      {handleMenuClose}
                                    }}
                                    // onClick={handleMenuClose}
                                  >
                                    Read More
                                    <img
                                      src={ButtonArrow}
                                      alt="Right Arrow"
                                      style={{ paddingLeft: "1.2rem" }}
                                    />
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <Grid
                            item
                            style={{
                              backgroundImage: `url(${factsLooper}),url(${currentBg})`,
                              backgroundPosition: "right bottom",
                              backgroundRepeat: "no-repeat",
                              marginRight: "16px",
                              // height: "100%",
                              // width: "200px",
                            }}
                            md
                          ></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MenuList>
                </Paper>
              </Popper>
            </div>
          </>
        ) : null
        /* <Grid container justifyContent="center">
          <Button
            variant="outlined"
            aria-controls={OpenDropDown ? "section_dropdown" : undefined}
            aria-haspopup="true"
            aria-expanded={OpenDropDown ? "true" : undefined}
            onClick={handleClickDropdown}
            className={classes.sectionDropdown}
            disableRipple
          >
            <Grid container justifyContent="space-between">
              Select Section
              <img src={DownArrow} alt="" />
            </Grid>
          </Button>
          <Menu
            classes={{ paper: classes.sectionMenuDropdown }}
            anchorEl={anchorDropdown}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            disableScrollLock={true}
            open={OpenDropDown}
            onClose={handleCloseDropDown}
            MenuListProps={{
              onMouseLeave: handleCloseDropDown,
              style: {
                padding: 0,
              },
            }}
            elevation={0}
            // style={{ zIndex: 1302 }}
            keepMounted
            PaperProps={{ root: classes.sectionMenuDropdown }}
          >
            {headerMenu.map((menu, index) => (
              <Grid container direction="column">
                <MenuItem
                  key={`${menu}${index}`}
                  value={menu.name}
                  className={classes.menuDropdownmob}
                  component={Button}
                  onClick={handleSubmenuOpen}
                  id="submenu"
                >
                  {menu.name}
                </MenuItem>
                {menu.name === currentMenu && openMenu ? (
                  <Grid container>
                    {headerMenu
                      .filter((menu) => menu.name === currentMenu)
                      .map(
                        (menu) =>
                          menu.options &&
                          menu.options.map((option, index) => (
                            <MenuItem
                              key={`${option}${index}`}
                              onClick={handleSubmenuClose}
                              className={classes.submenu}
                              component={Link}
                              to={`/${option.name}`}
                              disableRipple
                            >
                              {option.name}
                            </MenuItem>
                          ))
                      )}
                  </Grid>
                ) : null}
              </Grid>
            ))}
          </Menu>
        </Grid> */
      }
    </React.Fragment>
  );
};

export default HeaderMenu;
