import Doctor from "../../assets/images/doctor.svg";
import Wellness from "../../assets/images/Wellness.png";
import specialistcard from "../../assets/images/specialistCard.svg";
import diagnosisCard from "../../assets/images/diagnosisCard.svg";
import looper3 from "../../assets/images/Looper-3.png";
import looper4 from "../../assets/images/looper4.svg";
import WellnessPattern from "../../assets/images/wellnessPattern.svg";
import specialistPattern from "../../assets/images/specialistPattern.svg";
import diagnosisPattern from "../../assets/images/diagnosisPattern.svg";
// import premiumServices from "../../assets/images/premiumServices.svg";
import doctallRing from "../../assets/images/doctallRing.svg";

let premiumServices =
  "https://s3.eu-west-1.amazonaws.com/doctall.storage.com/new-doctall/premiumServices.svg";

const LandingStyle = (theme) => ({
  dWQContainer: {
    width: "100%",
  },

  premiumServicesContainer: {
    marginLeft: "0px",
    margin: "auto",
    // maxWidth: "1404px",
    height: "386px",
    background: "rgba(242, 248, 249, 0.5)",
    border: "1px solid rgba(174, 215, 223, 0.2)",
    boxSizing: "border-box",
    borderRadius: "4px",
    backgroundImage: `url(${premiumServices}),url(${doctallRing})`,
    backgroundPosition: "bottom right",
    paddingRight: "50px ! important",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      marginLeft: "0px",
    },
  },
  callbackButton: {
    ...theme.typography.secondaryButton,
    width: "151px",
  },
  rowContainer: {
    width: "100%",
    margin: "0px",
  },
  banner: {
    height: "1418px",
    width: "100%",
    paddingTop: "95px",
  },

  bannerPrimary: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#DC6803",
  },
  testiPrimary: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
  },

  testiSecondary: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    /* identical to box height, or 143% */

    color: "#BCFFEB",
  },
  bookADoctor: {
    ...theme.typography.secondaryButton,
    padding: "12px 20px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "10.6rem",
    marginBottom: "7.27rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8.6rem",
    },
  },
  bannerArrow: {
    position: "relative",
    marginTop: "5rem",
  },
  bannerArrowPrev: {
    position: "absolute",
    zIndex: theme.zIndex.modal + 1,
    bottom: "10.6rem",
    left: "9.6rem",
    color: "white",
    cursor: "pointer",
  },
  bannerArrowNext: {
    position: "absolute",
    zIndex: theme.zIndex.modal + 1,
    bottom: "10.6rem",
    left: "14.1rem",
    color: "white",
    cursor: "pointer",
  },
  diseaseContainer: {
    width: "100%",
    height: "auto",
    paddingTop: "10.6rem",
    paddingLeft: "9.6rem",
    paddingBottom: "7rem",
    /* [theme.breakpoints.down("md")]: {
      paddingLeft: "6rem",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingLeft: "0px",
      alignItems: "center",
    },*/
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.6rem",
      paddingRight: "1.6rem",
    },
  },
  subHeading: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#002931",
  },
  diseaseCard: {
    width: "28.2rem",
    height: "32rem",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "4px",
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: "2px solid  #E4E7EC",

    "&:hover": {
      background: "#FFF5F3",
      backgroundImage: `URL(${Doctor})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right bottom",
      border: "1px solid #FFDED8",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
      borderRadius: "4px",
      borderTop: "2px solid  #FF8D77",
      "& $readMoreButton": {
        ...theme.typography.secondaryButton,
        border: "1px solid #FF5C3C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "8px 14px",
      },
    },
  },
  diseaseCardContent: {
    justifyContent: "space-around",
    height: "246px",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "3rem",
    paddingTop: "3.3rem",
    paddingRight: "2.7rem",
  },
  readMoreButton: {
    ...theme.typography.primaryButton,
    border: "1px solid #00839B",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    padding: "8px 14px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    marginLeft: "3rem",
  },
  healthyLivingContainer: {
    paddingLeft: "9.5rem",
    paddingTop: "6.8rem",
    paddingBottom: "10.8rem",
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
  },
  wellnessCard: {
    ...theme.typography.imageCard,
    backgroundImage: `url(${Wellness}),url(${WellnessPattern})`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#00839B",
    //opacity: "0.8",
    borderRadius: "10px",
    backgroundBlendMode: "luminosity,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${WellnessPattern})`,
      width: "300px",
    },
  },

  specialistCard: {
    ...theme.typography.imageCard,
    backgroundImage: `url(${specialistcard}),url(${specialistPattern})`,
    background: "#37CC8C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundBlendMode: "luminosity,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${specialistPattern})`,
      width: "300px",
    },
  },
  diagnosisCard: {
    ...theme.typography.imageCard,
    backgroundImage: `url(${diagnosisCard}),url(${diagnosisPattern})`,

    background: "#BF0B02",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundBlendMode: "luminosity,screen",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${diagnosisPattern})`,
      width: "300px",
    },
  },
  livingButton: {
    ...theme.typography.tertiaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#143656",
    position: "absolute",
    bottom: "50px",
    [theme.breakpoints.down("sm")]: {
      bottom: "20px",
    },
  },
  popularProductContainer: {
    paddingLeft: "9.4rem",
    paddingTop: "6.8rem",
    width: "100%",
    height: "auto",
    backgroundColor: "#F7FBF8",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  viewProductButton: {
    ...theme.typography.secondaryButton,
    width: "198px",
    background: "#FF5C3C",
    border: "1px solid #FF5C3C",
    boxSizing: "border-box",
    // marginRight: "9.8rem",
    /* Shadow/xs */
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  productCard: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "24px",
    paddingBottom: "32px",
    width: "282px",
    height: "410px",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
  },
  titleContainer: {
    background: "#F2F8F9",
    width: "250px",
    height: "auto",
    gridGap: "14px",
    marginBottom: "20px",
  },
  productTitle: {
    ...theme.typography.title,
    color: "#00839B",
  },
  viewTestButton: {
    ...theme.typography.primaryButton,
    width: "250px",
    height: "48px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  appLinkContainer: {
    height: "780px",
    background: "#005F71",
    backgroundImage: `url(${looper3})`,
    backgroundPosition: "right bottom",
    backgroundRepeat: "no-repeat",

    [theme.breakpoints.down("md")]: {
      // width: "80%",
      backgroundImage: `url(${""}),url(${looper3})`,
    },
  },
  linkBox: {
    paddingLeft: "10.1rem",
    paddingTop: "4.7rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  StoreMain: {
    display: "flex",

    background: "#FFFFFF",
    /* Gray/100 */

    border: "2px solid #F2F4F7",
    borderRadius: "5.31px",
  },
  blogContainer: {
    paddingLeft: "9.7rem",
    paddingTop: "7.6rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  viewBlogsButton: {
    ...theme.typography.secondaryButton,
    width: "177px",
    padding: "12px 20px",
    background: "#FF5C3C",
    border: "1px solid #FF5C3C",
    boxSizing: "border-box",
    marginRight: "6.3rem",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  blogCard: {
    width: "384px",
    height: "476px",
    boxShadow: "none",
    borderRadius: "0px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      height: "500px",
    },
  },
  cardSetter: {
    padding: "0px",
  },
  cardBody: {
    ...theme.typography.title,
    fontWeight: "400",
    color: "#002931",
    marginBottom: "32px",
  },
  blogButton: {
    ...theme.typography.primaryButton,
    padding: "12px 20px",
    width: "119px",
    height: "48px",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    position: "absolute",
    left: "0px",
    bottom: "0px",
  },
  partnerContainer: {
    width: "100%",
    height: "339px",
    background: "#F7FBF8",
    paddingLeft: "64px",
    paddingTop: "81px",
    paddingRight: "96px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "48px",

      paddingRight: "75px",
    },
  },
  partnerTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#002931",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  partnerInnerContainer: {
    paddingTop: "48px",
    paddingLeft: "16px",
  },
  newsLetterContainer: {
    width: "100%",
    background: "#F2F8F9",
    height: "auto",
    paddingLeft: "101px",
    paddingRight: "96px",
    paddingBottom: "98px",
    backgroundImage: `url(${looper4})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 28%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  newsLetterContent: {
    width: "491px",
    height: "auto",
    paddingTop: "94px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      textAlign: "center",
    },
  },

  newLetterSubHeading: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
  },
  newsLetterForm: {
    // width: "647px",
    height: "337px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(11, 59, 60, 0.05)",
    borderRadius: "10px",
    marginTop: "74px",
    marginLeft: "105px",
    paddingLeft: "42px",
    paddingRight: "42px",
    paddingTop: "54px",
    paddingBottom: "54px",

    [theme.breakpoints.down("md")]: {
      width: "600px",
      paddingLeft: "36px",
      paddingRight: "36px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "530px",
      paddingLeft: "36px",
      paddingRight: "36px",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      paddingLeft: "24px",
      paddingRight: "24px",
      marginLeft: "0px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  inputLabel: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    paddingBottom: "6px",
    /* identical to box height, or 143% */

    /* Gray/700 */

    color: "#344054",
  },
  inputField: {
    background: "#FFFFFF",
    /* Gray/300 */
    width: "100%",
    height: "44px",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "10px 14px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    "&:focus": {
      border: "1px solid #D0D5DD",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  subscribeButton: {
    ...theme.typography.secondaryButton,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "10px 0px",
    marginTop: "24px",
    // backgroundColor:"#C53B20",
  },
  SliderContainer: {
    width: "100%",
  },
  dWQBanner: {
    paddingTop: "9.4rem",
    paddingLeft: "9.6rem",
    height: "auto",
    paddingBottom: "5.8rem",
    backgroundPosition: "85% -32%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  whoweare: {
    marginTop: "6%",
    color: "#FB6514",
    fontWeight: "600",
    lineHeight: "24px",
  },
  dWQTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "60px",
    lineHeight: "72px",
    letterSspacing: "-0.02em",
    color: "#002931",
    marginTop: "2%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "48px",
      lineHeight: "60px",
    },
  },
  dWQDesc: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "32px",
    color: "#002931",
    width: "470px",
    [theme.breakpoints.down("xs")]: {
      width: "250px",
    },
  },
  sliderControl: {
    width: "90%",
    background: " #005F71",
    borderRadius: "16px",
    height: "94px",
    position: "absolute",
    bottom: "95px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "202px",
    },
  },
  sliderControlContent: {
    paddingTop: "28px",
    paddingRight: "28px",
    paddingLeft: "14%",

    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
  },
  sliderHeading: {
    fontSize: "36px",
    fontWeight: "700",
    lineHeight: "44px",
    textAlign: "center",
    padding: "10px",
  },
  sliderSubHeading: {
    fontSize: "20px",
    margin: "auto",
    color: "#667085",
    lineHeight: "30px",
    fontFamily: "Visuelt Pro",
    padding: "20px 360px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  sliderBelowTextContainer: {
    padding: "0px 200px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },

  AbouUsContainer: {
    background: "rgba(242, 248, 249, 0.5);",

    border: "1px solid rgba(174, 215, 223, 0.2)",

    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      textAlign: "center",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  AboutSectionHeading: {
    paddingBottom: "20px",
    color: "#101828",
    fontSize: "36px",
    fontWeight: "700",
    lineHeight: "44px",
  },

  AboutSectionPara: {
    color: "#667085",
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: "400",
    paddingBottom: "20px",
  },

  mhSubContainer: {
    padding: "100px 190px",
    // background: "rgba(242, 248, 249, 0.5);",

    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },

  aboutSectionBtn: {
    background: "#FF5C3C",
    padding: "12px 20px",
    color: "#fff",
    fontSize: "16px",
    marginTop: "20px",
  },
  aboutSectionimage: {
    paddingTop: "10px",
    height: "auto",
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  bannerSupPara: {
    color: "#667085",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "5px 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },

  appContentContainer: {
    background: "#005F71",
    padding: "100px",
    backgroundImage: `url(${looper3})`,
    backgroundPosition: "right bottom",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 20px",
      backgroundImage: "none",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "40px 20px",
      backgroundImage: "none",
    },
  },

  appContenttext: {
    paddingRight: "400px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
    },

    [theme.breakpoints.down("xs")]: {
      paddingRight: "0px",
    },
  },

  appContentHeadding: {
    color: "#FFFFFF",
    fontSize: "36px",
    fontFamily: "Visuelt Pro",
    fontWeight: "700",
    lineHeight: "44px",
    paddingBottom: "30px",
    width: "330px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
      width: "100%",
    },

    [theme.breakpoints.down("xs")]: {
      paddingRight: "0px",
      width: "100%",
    },
  },
  appcontentSubheadding: {
    color: "#ffff",
    fontSize: "20px",
    fontWeight: "500",
    fontFamily: "Visuelt Pro",
    lineHeight: "30px",
  },
  appContentPara: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    paddingBottom: "10px",
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
      width: "100%",
    },

    [theme.breakpoints.down("xs")]: {
      paddingRight: "0px",
      width: "100%",
    },
  },
  AppContenerImg: {
    // textAlign:"center",
    // alignItems:"center",
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
});

export default LandingStyle;
