import { Button, FormControl, Grid, Input, makeStyles } from "@material-ui/core";
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import backArrow from "../../../assets/images/back_arrow.png";
import logo from "../../../assets/images/doctall_logo.png";
import mark from "../../../assets/images/mark.png";
import passcode from "../../../assets/images/passcode.png";
import { showNotification } from '../../../components/common-notification/CommonNotification';
import styles from "../../../components/Landing/styles";
import { onChangePasscode } from '../../../services/auth-services/authServices';
import "../auth.scss";


const useStyles = makeStyles((theme) => styles(theme));

function CreatNewPasscode() {
    const classes = useStyles();

    const navigate = useNavigate();
    const _userInfo = useSelector((state) => state.authReducer);


    const handleSubmit = async (event) => {
        event.preventDefault();


        const { passcode, confirmPasscode } = event.target.elements;
        try {
            if (passcode.value !== confirmPasscode.value) {
                showNotification("error", "Failed !", "Passcode and Confirmed Passcode must be same")
            } else {
                let _savedData = _userInfo.registrationPayLoad;
                let payLoad = { mobile: _savedData.mobile, password: passcode.value }
                let response = await onChangePasscode(payLoad)
                if (response && response.status === 200 || response.status === 201) {
                    navigate('/reset-password-success');
                }
            }
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                showNotification("error", "Failed !", "" + error.response.data.message)
            }

        }

    }

    return (

        <div className='login_page'>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12} >
                    <div className='Login_img_box'>
                        <img src={mark} alt="login_image" className='Login_img' />
                    </div>

                </Grid>
                <Grid item sm={6} xs={12} >
                    <div className='login_container'>
                        <div className='doctall_logo'>
                            <Link to="/">
                                <img src={logo} alt="login_image" />
                            </Link>
                        </div>


                        <div className='form_section'>
                            <div className="passcode_container">
                                <img src={passcode} alt="success_icon" />
                                <h1 className='Login_heading' style={{ marginTop: "30px" }}>Create new passcode</h1>
                                <p className='login_para'>Choose a passcode you can easily remember</p>
                            </div>


                            <form onSubmit={handleSubmit}>
                                <FormControl variant="standard" fullWidth>
                                    <label htmlFor="name" className={classes.inputLabel}>
                                        Passcode*
                                    </label>
                                    <Input
                                        type="tetx"
                                        required
                                        name='passcode'
                                        className={classes.inputField}
                                        disableUnderline={true}
                                        inputProps={{
                                            maxLength: 4,
                                            minLength: 4
                                        }}
                                    />
                                    <label
                                        htmlFor="confirmPasscode"
                                        className={classes.inputLabel}
                                        style={{ paddingTop: "20px" }}
                                    >
                                        Confirm Passcode*
                                    </label>

                                    <Input
                                        type="number"
                                        required
                                        name='confirmPasscode'
                                        className={classes.inputField}
                                        disableUnderline={true}
                                        inputProps={{
                                            maxLength: 4,
                                            minLength: 4
                                        }}
                                    />
                                    <Button variant="contained" type='submit' className={classes.subscribeButton} style={{ backgroundColor: "#C53B20" }}>
                                        Submit
                                    </Button>
                                </FormControl>
                            </form>
                            <p style={{ color: "#667085", fontSize: "14px", textAlign: "center", marginTop: "20px", fontWeight: "500" }}> <img src={backArrow} /> &nbsp; Back to log in </p>


                        </div>

                    </div>


                </Grid>

            </Grid>
        </div>
    )
}

export default CreatNewPasscode