import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./style";
import appStore from "../../assets/images/App.png";
import playStore from "../../assets/images/Play.png";
import Checkicon from "../../assets/images/Checkicon.png";
import Phone from "../../assets/images/Phone.png";
const useStyles = makeStyles((theme) => styles(theme));

const Appcontent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const custommatchesXL = useMediaQuery("(min-width:1500px)");

  return (
    <>
     

      <Grid
        item
        container
        className={classes.appContentContainer}
      >
        <Grid md={4}>

          <Typography style={{ color: "#E0F2FE", fontSize:"16px", paddingBottom:"10px" }}>Get started on Doctall</Typography>
          <Typography className={classes.appContentHeadding}>Talk to a doctor  in  4 easy steps</Typography>
          <Grid item container>
            <Grid md={1} sm={1} xs={1}><img src={Checkicon} alt="check-icon" /> </Grid>
            <Grid md={11} sm={11} xs={11}>
              <Typography className={classes.appcontentSubheadding} >
                Downlaod app
              </Typography>
              <Typography className={classes.appContentPara}>
                Download the app from Google Play  Store or App Store.
              </Typography>
            </Grid>
            <Grid md={1} sm={1} xs={1}><img src={Checkicon} alt="check-icon" /> </Grid>
            <Grid md={11} sm={11} xs={11}>
              <Typography className={classes.appcontentSubheadding} >
                Sign Up
              </Typography>
              <Typography className={classes.appContentPara}>
                Sign up with your phone number and  unique 4-digit passcode.
              </Typography>
            </Grid>
            <Grid md={1} sm={1} xs={1}><img src={Checkicon} alt="check-icon" /> </Grid>
            <Grid md={11} sm={11} xs={11}>
              <Typography className={classes.appcontentSubheadding} >
                See a doctor
              </Typography>
              <Typography className={classes.appContentPara}>
                See a doctor immediately or book an  appointment for a later time.
              </Typography>
            </Grid>
            <Grid md={1} sm={1} xs={1}><img src={Checkicon} alt="check-icon" /> </Grid>
            <Grid md={11} sm={11} xs={11}>
              <Typography className={classes.appcontentSubheadding} >
                Payment
              </Typography>
              <Typography className={classes.appContentPara}>
                Make payment through any of our  secure  channels where necessary.
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                marginRight: matchesXS ? "10px" : "22px",
                marginBottom: matchesXS ? "24px" : "0px",

              }}
             
            >
               <a
                      target="_blank"
                      href="https://apps.apple.com/ng/app/doctall/id1544455716"
                    >
              <img src={appStore} alt="appStore button" />
              </a>
            </Grid>
            <Grid item >
               <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.doctall"
                    >
              <img src={playStore} alt="playStore button" />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid md={8} align="center">
          <Grid md={6} sm={12} xs={12} align="center">
            <img src={Phone}
              className={classes.AppContenerImg}

            />
          </Grid>
        </Grid>

      </Grid>



    </>
  );
};

export default Appcontent;
