import moment from "moment";
import CryptoJS from "crypto-js";

const SECRET_KEY = ")H@MccQffTjWnZr4u7x!A%CF*F-JaNdRgUkUkUXNh*F-JnaNDOCcTALL";

export const getCurrentDate = () => {
  return moment().format("DD MMM YYYY");
};

export const doEncryption = async (data) => {
  try {
    var ciphertext = await CryptoJS.AES.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();
    let _encryptedData = ciphertext.toString().replaceAll("/", "doctall");
    return _encryptedData;
  } catch (error) {
    console.log(error);
  }
};

export const doEncryptionAndRedirect = async (data) => {
  try {
    var ciphertext = await CryptoJS.AES.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();
    let _encryptedData = ciphertext.toString().replaceAll("/", "doctall");
    // let targetUrl = "http://localhost:3001" + "/book-doctor/" + _encryptedData
    let targetUrl =
      process.env.REACT_APP_CUNSUMER_SITE_URL +
      "/book-doctor/" +
      _encryptedData;
    window.open(targetUrl, "_blank");
    localStorage.clear();
  } catch (error) {
    console.log(error);
  }
};

// Static route paths

export const ED_ROUTE_PATH = "erectile-dysfunction";
export const HL_ROUTE_PATH = "hair-loss";
export const PE_ROUTE_PATH = "premature-ejaculation";
export const WELLNESS_ROUTE_PATHE = "wellness";
export const WELLNES_ROUTE_PATHE = "wellnes";
export const STRESS_MANAGEMENTW = "stress-management";
export const HORMONE_AND_FERTILITYW = "Hormone-and-Fertility-Women";
export const HEART_AND_HYPERTENSIONW = "heart-and-hypertensions";
export const COMING_SOON_ROUTE_PATHE = "doctall-pharmacy";
export const STRESS_MANAGEMENT = "stress-management";
export const HORMONE_AND_FERTILITY = "Hormone-and-Fertility";
export const PROSTATE_ROUTE_PATH = "prostate";
export const PREMARITAL_CHECK = "premarital-check";
export const HEART_AND_HYPERTENSION = "heart-and-hypertension";
export const MENOPAUSE_ROUTE_PATH = "menopause";
export const PCOS_ROUTE_PATH = "pcos";
export const THYROID_ROUTE_PATH = "thyroid";
export const DIABETES_ROUTE_PATH = "diabetes";
export const KIDNEY_FAILURES = "kidney-failures";
export const LIVER_ROUTE_PATH = "liver";
export const STIs_ROUTE_PATH = "STIs";
export const HIV_ROUTE_PATH = "HIV";
export const TYPHOID_ROUTE_PATH = "Typhoid";
export const NUTRITION_ROUTE_PATH = "nutrition";
export const FITNESS_AND_NDURANCE = "fitness-and-endurance";
export const SPORTS_ROUTE_PATH = "sport";
export const WEIGHT_MANAGEMENT = "weight-management";
export const ENERGY_AND_VIGOR = "Energy-and-Vigor";
export const MALARIA_ROUTE_PATH = "malaria";
export const COLD_COUGH_OR_FEVER = "cold-cough-or-fever";
export const COVID_ROUTE_PATH = "covid-19";
export const MOTHER_AND_CHILD = "Mother-and-Child";
//
