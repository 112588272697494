import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import styles from "../DIseaseWithQues/styles";
import ShareArrow from "../../../assets/images/shareArrow.svg";
import whatsappShare from "../../../assets/images/whatsappShare.svg";
import twitterShare from "../../../assets/images/twitterShare.svg";
import facebookShare from "../../../assets/images/facebookShare.svg";
import instagramShare from "../../../assets/images/instagramShare.svg";
import shareClose from "../../../assets/images/shareClose.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
const useStyles = makeStyles((theme) => styles(theme));

const Share = () => {
  const classes = useStyles();
  const [showSocial, setShowSocial] = useState(false);
  const [currentpageurl, setCurrentpageUrl] = useState("");

  useEffect(() => {
    setCurrentpageUrl(window.location.href);
  });
  console.log(currentpageurl, "currentpageurl");

  const _whatsappShare = () => {
    window.open(
      "https://web.whatsapp.com/send?text=" + currentpageurl,
      "_blank"
    );
  };

  const _twitterShare = () => {
    window.open(
      "https://twitter.com/intent/tweet?url=" + currentpageurl,
      "_blank"
    );
  };

  const _fbShare = () => {
    window.open(
      "https://www.facebook.com/sharer.php?href=" + currentpageurl,
      "_blank"
    );
  };

  const _instaShare = () => {
    window.open(
      "https://www.instagram.com/send?text=" + currentpageurl,
      "_blank"
    );
  };

  return (
    <>
      {showSocial ? (
        <Grid
          item
          container
          className={classes.socialIcons}
          justifyContent="space-evenly"
          alignItems="center"
        >
          {/* <IconButton onClick={_whatsappShare}>
            <img src={whatsappShare} alt="whatsapp" />
          </IconButton>
          <IconButton onClick={_twitterShare}>
            <img src={twitterShare} alt="whatsapp" />
          </IconButton> */}
          {/* <WhatsappShareButton> */}
          <IconButton onClick={_whatsappShare}>
            <img src={whatsappShare} alt="whatsapp" />
          </IconButton>
          {/* </WhatsappShareButton> */}
          <TwitterShareButton
            title={"Doctall"}
            url={currentpageurl}
            hashtags={[""]}
          >
            <IconButton>
              <img src={twitterShare} alt="twitter" />
            </IconButton>
          </TwitterShareButton>
          <FacebookShareButton
            url={currentpageurl}
            quote={" "}
            hashtag={""}
            description={"Doctall"}
            className="Demo__some-network__share-button"
          >
            <IconButton>
              <img src={facebookShare} alt="whatsapp" />
            </IconButton>
          </FacebookShareButton>
          {/* <IconButton onClick={_fbShare}>
            <img src={facebookShare} alt="whatsapp" />
          </IconButton> */}
          <IconButton onClick={_instaShare}>
            <img src={instagramShare} alt="whatsapp" />
          </IconButton>
          <IconButton
            style={{
              width: "42px",
              height: "42px",
              background: "#004451",
              borderRadius: "50%",
            }}
            onClick={() => setShowSocial(false)}
          >
            <img src={shareClose} alt="whatsapp" />
          </IconButton>
        </Grid>
      ) : (
        <Grid
          item
          container
          direction="column"
          className={classes.shareIcon}
          justifyContent="center"
          alignItems="center"
          onClick={() => setShowSocial(true)}
        >
          <IconButton>
            <img src={ShareArrow} alt="arrow" />
          </IconButton>
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "12px",
              lineHeight: "16px",
              color: "#FFFFFF",
            }}
          >
            Share
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Share;
