import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  Card,
  Button,
  CardContent,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "../styles";

import hormoneIcon from "../../../assets/images/hormoneIcon.svg";
import { _redirect } from "../../Redirect/Redirect";

const useStyles = makeStyles((theme) => styles(theme));

const PopularTests = ({ _test }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid
      item
      container
      direction={matchesXS ? "column" : undefined}
      alignItems={matchesSM ? "center" : undefined}
      justifyContent={matchesSM ? "center" : undefined}
    >
      <Grid
        item
        key={`${_test}`}
        style={{
          marginRight: matchesXS ? "0px" : "40px",
          marginBottom: "20px",
        }}
      >
        <Card classes={{ root: classes.testsCard }}>
          <CardContent classes={{ root: classes.cardSetter }}>
            <Grid container direction="column" alignItems="center">
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.titleContainer}
                style={{ padding: "16px" }}
              >
                <img src={hormoneIcon} alt="hormoneIcon" />
                <Typography className={classes.productTitle}>
                  {_test && _test.doc && _test.doc.standardisedtestname}
                </Typography>
              </Grid>
              <Grid item align="center" style={{ marginBottom: "20px" }}>
                <Typography variant="body1">

                  {" "}
                </Typography>
              </Grid>
              <Grid
                item
                className={classes.titleContainer}
                style={{ padding: "8px", marginBottom: "10%", textAlign: 'center' }}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: "#00839B",
                    fontWeight: "500",
                    justifyContent: "center",
                  }}
                >
                  {"Other Test names"}
                  <br /><br />
                  {_test && _test.other_name.join(",     ")}
                  {" "}
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: "20px" }}>
                <Typography align="center" variant="body1">
                  Start from
                </Typography>
                <Typography
                  align="center"
                  variant="h5"
                  style={{ fontWeight: "900", color: "#002931" }}
                >
                  ₦ {_test && _test.min.toLocaleString('en-US')}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.viewTestButton}
                  onClick={() => _redirect('DMP')}
                >
                  View Test
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PopularTests;
