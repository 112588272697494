import React from "react";
import "./AboutUs.scss";
import image from "../../assets/images/Image.png";
// import image2 from '../../assets/images/image2.png';
import icon1 from "../../assets/images/Featured icon1.png";
import icon2 from "../../assets/images/Featured icon2.png";
import icon3 from "../../assets/images/Featured icon3.png";
import icon4 from "../../assets/images/Featured icon4.png";
import icon5 from "../../assets/images/Featured icon5.png";
import Olivia from "../../assets/images/Olivia Rhye.png";
import echolab from "../../assets/images/echolab.png";
import union from "../../assets/images/union-diagnostics.png";
import mcure from "../../assets/images/mecure.png";
import synlab from "../../assets/images/synlab.png";
import afri from "../../assets/images/afri.png";
import facebook from "../../assets/images/Face.png";
import rightArrowWhite from "../../assets/images/right_arrow_white.png";
import sandeep from "../../assets/images/Sandeep Chadha.png";
import Srinivas from "../../assets/images/Srinivas.png";
import Joseph from "../../assets/images/Joseph.png";
import Stephen from "../../assets/images/Stephen.png";
import ParentSlider from "../../components/Landing/components/PartnerSlider";
import { Grid, Button, useMediaQuery, useTheme } from "@material-ui/core";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/Linkedin.png";
import web from "../../assets/images/web.png";
import Team from "./Team";
import NewsLetter from "./NewsLetter";
import foundatioLogo from "../../assets/images/Doctall Foundation-logo.png";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import { _redirect } from "../../components/Redirect/Redirect";
import { Helmet } from "react-helmet";
import MetaDecorator from "../diseases/utils/metaDecorator";

function AboutUs() {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const custommatchesMDX = useMediaQuery("(max-width:1380px)");
  const custommatchesXL = useMediaQuery("(min-width:1500px)");
  return (
    <>
      <Header />
      {/* <Helmet>
            <title>About Doctall</title>
            <meta 
            name='description' 
            content='Doctall is a full-circle digital healthcare provider offering comprehensive primary and secondary medical services accessible anywhere, to everyone. Everyone deserves to have a greater expectation for good health, this should not be aspirational. We have made it our mission to transform people’s ability to better manage their well-being and improve their health security'
            />
        </Helmet> */}
      <MetaDecorator
        title="About Doctall"
        description="Doctall is a full-circle digital healthcare provider offering comprehensive primary and secondary medical services accessible anywhere, to everyone. Everyone deserves to have a greater expectation for good health, this should not be aspirational. We have made it our mission to transform people’s ability to better manage their well-being and improve their health security"
      />
      <div className="about_us">
        {/* <BannerCarousel /> */}
        {/* banner_start */}
        <div className="banner">
          <div className="banner_txt">
            <p
              style={{ color: "#FB6514", fontWeight: 600, fontSize: "16px" }}
              className="para"
            >
              About us
            </p>
            <h1
              className="banner_headding"
              style={{
                fontSize: matchesXS ? "36px" : "48px",
                color: matchesXS ? "#101828" : "#fff",
                lineHeight: matchesXS ? "44px" : "60px",
                fontFamily: "Visuelt Pro",
                fontWeight: "700",
                fontStyle: "normal",
                width: matchesXS ? "338px" : "676px",
                height: "143px",
                letterSpacing: "-0.02em",
                order: "1",
              }}
            >
              Empowering you to live a healthier and better life
            </h1>
            <button
              className="partner_button"
              style={{ cursor: "pointer" }}
              onClick={() => _redirect("CONSUMER-DASHBOARD")}
            >
              Book an appointment
            </button>
          </div>
        </div>
        <div
          className="about-first-section"
          style={{
            marginTop: matchesXS ? "0px" : undefined,
            textAlign: matchesXS || matchesMD ? "center" : undefined,
            marginLeft: matchesXS ? "-35px" : undefined,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            className="mainHeading"
            style={{
              width: matchesXS ? "331px" : "682px",
              height: matchesXS ? "114px" : "120px",
              //marginLeft: matchesXS || matchesMD ? undefined : "300px",
            }}
          >
            Leading you to the healthier life you deserve
          </h1>
          <p
            style={{
              paddingTop: "20px",
              width: matchesXS ? "331px" : "682px",
              height: "60px",
              // marginLeft: matchesXS || matchesMD ? undefined : "300px",
            }}
            className="para"
          >
            Doctall is a full circle digital healthcare provider committed to
            delivering quality end-to-end medical care that is accessible to
            all.
          </p>
        </div>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <div
              className="vision_container"
              style={{
                marginTop: matchesXS ? "-30px" : undefined,
                justifyContent: matchesXS ? "left" : undefined,
                marginLeft: matchesXS ? "-35px" : undefined,
              }}
            >
              <h2 className="subheading">Our Vision</h2>
              <p
                style={{
                  fontSize: "18px",
                  width: matchesXS ? "331px" : "384px",
                }}
                className="para"
              >
                At Doctall, we believe that quality healthcare must be
                accessible to everyone, with no exceptions – this is what we
                strive for, what drives our every action and is our vision of a
                healthier future for all
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{ marginTop: matchesXS ? "300px" : undefined }}
          >
            <img src={image} alt="Our Vision" className="vision_img" />
          </Grid>
        </Grid>

        <Grid container>
          {/* <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2 }}>
                        <img src={image2} alt="Our Mission" className='vision_img' />
                    </Grid> */}
          <Grid item xs={12} sm={12} md={6}>
            <div
              className="vision_container"
              style={{
                marginTop: matchesXS ? "-870px" : "-500px",
                textAlign: matchesXS ? "justify" : undefined,
                marginLeft: matchesXS ? "-35px" : undefined,
              }}
            >
              <h2 className="subheading">Our Mission</h2>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  width: matchesXS ? "300px" : "384px",
                }}
                className="para"
              >
                Everyone deserves to have a greater expectation for good health,
                this should not be aspirational.Through technology, knowledge
                sharing, and sheer tenacity we have made it our mission to
                transform people’s ability to better manage their well-being and
                improve their health security
              </p>
            </div>
          </Grid>
        </Grid>

        <div className="about_card_container">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6}>
              <div className="about_card">
                <h1 className="subheading" style={{ fontSize: "38px" }}>
                  WHY?
                </h1>
                <p className="para2">
                  Quality and accessible healthcare is a right for everyone, not
                  a privilege.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="about_card">
                <h1 className="subheading" style={{ fontSize: "38px" }}>
                  HOW?
                </h1>
                <p className="para2">
                  Through our unwavering dedication, our doctors’ invaluable
                  experience, and leveraging of innovative technologies to break
                  down healthcare barriers.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="about_card">
                <h1 className="subheading" style={{ fontSize: "38px" }}>
                  WHAT?
                </h1>
                <p className="para2">
                  Providing healthcare for acute and chronic medical conditions
                  globally, through technology.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="about_card">
                <h1 className="subheading" style={{ fontSize: "38px" }}>
                  WHO?
                </h1>
                <p className="para2">Everyone, one and all.</p>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="value_section">
          <div className="value_container">
            <p
              style={{ color: " #E9D7FE", fontSize: "16px", fontWeight: 600 }}
              className="para"
            >
              Our Values
            </p>
            <h1 className="value_subheading">
              What do we value? All sorts of things! But we particularly pride
              ourselves on:
            </h1>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={4}>
                <div className="value">
                  <img src={icon1} alt="Girl in a jacket" />
                  <h3
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      paddingTop: "10px",
                    }}
                  >
                    Inclusivity
                  </h3>
                  <p
                    style={{
                      color: "#E9D7FE",
                      fontSize: "16px",
                      paddingTop: "10px",
                    }}
                    className="para"
                  >
                    To lead communities, one and all, to better health{" "}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className="value">
                  <img src={icon2} alt="Girl in a jacket" />
                  <h3
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      paddingTop: "10px",
                    }}
                  >
                    Vision{" "}
                  </h3>
                  <p
                    style={{
                      color: "#E9D7FE",
                      fontSize: "16px",
                      paddingTop: "10px",
                    }}
                    className="para"
                  >
                    Be stubborn on vision, but flexible on strategy{" "}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className="value">
                  <img src={icon3} alt="Girl in a jacket" />
                  <h3
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      paddingTop: "10px",
                    }}
                  >
                    Empowerment{" "}
                  </h3>
                  <p
                    style={{
                      color: "#E9D7FE",
                      fontSize: "16px",
                      paddingTop: "10px",
                    }}
                    className="para"
                  >
                    Knowledge is power and access is an opportunity – we level
                    the playing field
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2}></Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className="value">
                  <img src={icon4} alt="Girl in a jacket" />
                  <h3
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      paddingTop: "10px",
                    }}
                  >
                    Empathy
                  </h3>
                  <p
                    style={{
                      color: "#E9D7FE",
                      fontSize: "16px",
                      paddingTop: "10px",
                    }}
                    className="para"
                  >
                    To show empathy and compassion, inspiring hope to those most
                    in need{" "}
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <div className="value">
                  <img src={icon5} alt="Girl in a jacket" />
                  <h3
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      paddingTop: "10px",
                    }}
                  >
                    Commitment
                  </h3>
                  <p
                    style={{
                      color: "#E9D7FE",
                      fontSize: "16px",
                      paddingTop: "10px",
                    }}
                    className="para"
                  >
                    Uncompromising in our dedication to our vision
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="team">
          <div className="team_container">
            <h1 className="meet_subheading">Meet our team</h1>
            <p className="team_para">
              Driven by passion, grit and invaluable experience, our team are
              true believers in convenient, quality healthcare that is
              accessible to all Nigerians.
            </p>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={3}>
                <div className="team_card">
                  <img
                    src={sandeep}
                    alt="Girl in a jacket"
                    style={{ width: "100px" }}
                  />
                  <h3 className="team_name">Sandeep Chadha</h3>
                  <h4 className="team_designation">Founder & CEO</h4>
                  <p className="para">
                    Sandeep is a former investment and private banker, fund
                    manager and senior advisor to numerous corporate, private
                    and governmental organisations.
                  </p>
                </div>
                <div className="team_icon">
                  <img
                    src={twitter}
                    alt="twitter"
                    style={{ padding: " 5px" }}
                    onClick={() => _redirect("TWITTER")}
                  />
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ padding: "5px " }}
                    onClick={() => _redirect("LINKEDIN")}
                  />
                  <img
                    src={facebook}
                    alt="web"
                    style={{ padding: "5px " }}
                    onClick={() => _redirect("FB")}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className="team_card">
                  <img
                    src={Srinivas}
                    alt="Girl in a jacket"
                    style={{ width: "100px" }}
                  />
                  <h3 className="team_name">Elavala Srinivasa Reddy</h3>
                  <h4 className="team_designation">Director and Co-Founder</h4>
                  <p className="para">
                    Elavala is a seasoned senior executive with over 25 years of
                    global achievement across diverse sectors such as secure
                    technologies, agriculture, energy, infrastructure, and
                    hospitality.
                  </p>
                </div>
                <div className="team_icon">
                  <img
                    src={twitter}
                    alt="twitter"
                    style={{ padding: " 5px" }}
                    onClick={() => _redirect("TWITTER")}
                  />
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ padding: "5px " }}
                    onClick={() => _redirect("LINKEDIN")}
                  />
                  <img
                    src={facebook}
                    alt="web"
                    style={{ padding: "5px " }}
                    onClick={() => _redirect("FB")}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className="team_card">
                  <img
                    src={Joseph}
                    alt="Girl in a jacket"
                    style={{ width: "100px" }}
                  />
                  <h3 className="team_name">Aniekan Joseph</h3>
                  <h4 className="team_designation">Chief Commercial Officer</h4>
                  <p className="para">
                    Aniekan is a seasoned business development professional with
                    proven experience in establishing strategic partnerships in
                    healthcare and other industries.{" "}
                  </p>
                </div>
                <div className="team_icon">
                  <img
                    src={twitter}
                    alt="twitter"
                    style={{ padding: " 5px" }}
                    onClick={() => _redirect("TWITTER")}
                  />
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ padding: "5px " }}
                    onClick={() => _redirect("LINKEDIN")}
                  />
                  <img
                    src={facebook}
                    alt="web"
                    style={{ padding: "5px " }}
                    onClick={() => _redirect("FB")}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className="team_card">
                  <img
                    src={Stephen}
                    alt="Girl in a jacket"
                    style={{ width: "100px" }}
                  />
                  <h3 className="team_name">Dr John Stephen</h3>
                  <h4 className="team_designation">Head of Medical Services</h4>
                  <p className="para">
                    Dr Stephen is a senior medical doctor with extensive
                    Nigerian and international medical experience that spans
                    across nine years.{" "}
                  </p>
                </div>
                <div className="team_icon">
                  <img
                    src={twitter}
                    alt="twitter"
                    style={{ padding: " 5px" }}
                    onClick={() => _redirect("TWITTER")}
                  />
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ padding: "5px " }}
                    onClick={() => _redirect("LINKEDIN")}
                  />
                  <img
                    src={facebook}
                    alt="web"
                    style={{ padding: "5px " }}
                    onClick={() => _redirect("FB")}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Team />
        <div
          className="doctall_foundation"
          style={{
            width: matchesXS ? "100%" : undefined,
            height: matchesXS ? "900px" : undefined,
          }}
        >
          <img
            src={foundatioLogo}
            alt="Girl in a jacket"
            style={{ paddingBottom: "20px" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1
              className="foundation_heading"
              style={{ width: matchesXS ? "320px" : "728px" }}
            >
              {" "}
              Leading communities, one and all, to better health
            </h1>
            <p
              className="foundation_para"
              style={{ width: matchesXS ? "320px" : "623px" }}
            >
              The Doctall Foundation exists to bridge the gap between digitally
              excluded communities and the quality healthcare they need.
            </p>
            <p
              className="foundation_para"
              style={{ width: matchesXS ? "320px" : "623px" }}
            >
              By leveraging the technologies of Doctall, the foundation strives
              to travel the last mile to reach those most in need of medical
              attention.
            </p>
          </div>

          <Button
            style={{
              backgroundColor: "#FF5C3C",
              color: "#fff",
              fontSize: "14px",
              padding: "10px 18px",
              marginTop: "20px",
            }}
            variant="contained"
            onClick={() => _redirect("FOUNDATION")}
          >
            {" "}
            Learn More &nbsp; <img src={rightArrowWhite} alt="arrow" />{" "}
          </Button>
        </div>

        <div className="partners">
          <h2 className="our_partner">OUR PARTNERS</h2>
          <p className="our_text">
            To ensure you can access prescription drugs and diagnostic tests at
            discounted prices, we have built partnerships with reputable medical
            pharmaceutical and diagnostic facilities.
          </p>
          <Grid container spacing={0} style={{ marginTop: "5px" }}>
            <ParentSlider />
          </Grid>
        </div>
        <NewsLetter />
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
