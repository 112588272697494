import React, { useState, useEffect } from "react";

import Autosuggest from "react-autosuggest";
import searchIcon from "../../../assets/images/searchIcon.svg";
import "./AutoSuggest.css";

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

const AutoSuggestField = () => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [fetchData, setFetchData] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [suggestionsObject, setSuggestionsObject] = useState({});

  function renderSuggestion(suggestion) {
    return <span>{`${suggestion.name} (${value})`} </span>;
  }

  function getSuggestionValue(suggestion) {
    setSuggestionsObject(suggestion);

    return suggestion.name;
  }

  function highLigtSggestion(event) {
  
  }

  function getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp("^" + escapedValue, "i");

    return fetchData;
  }
  const onChange = (event, { newValue, method }) => {
    setValue(newValue);
    // apiFetch(newValue);
  };

  useEffect(() => {
    setSuggestions(getSuggestions(value));
  }, [fetchData]);
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setFetchData([]);
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Search...",
    value,
    onChange: onChange,
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSearch = (diff) => {
    if (diff) {
      if (allTests && allTests.length > 0) {
        // dispatch(SearchValAll(allTests))
        // dispatch(SearchKeyword(value))

        setValue("");
      } else {
        setToastOpen(true);
        setToastMessage("No search result found");
        setValue("");
      }
    }

    if (suggestions.length > 0) {
      let removedSpace = value.split(" ")[0];
      let data = suggestionsObject;

      data = {};
    }
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div className="searchIcon">
        <img
          src={searchIcon}
          style={{ width: "18px", height: "18px" }}
          alt="search"
        />
      </div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        // onSuggestionHighlighted={highLigtSggestion}
        onSuggestionSelected={(event) => {
          handleSearch();
          setValue("");
        }}
      />
    </div>
  );
};

export default AutoSuggestField;
